import clsx from "clsx";
import React, { Dispatch, FC } from "react";
import { DropdownMenu, Icon } from "@/react/components";
import { twMerge } from "tailwind-merge";

type OptionsType = Array<{ label: string; option: string }>;

interface SelectProps {
  options: OptionsType;
  onSelect: Dispatch<string>;
  activeItem?: string | null;
  showActiveItem?: boolean;
  className?: string;
  placeHolderText?: string;
  open?: boolean;
  fixedHeight?: boolean;
}

const CustomSelect: FC<SelectProps> = ({
  options,
  onSelect,
  activeItem,
  className,
  placeHolderText,
  open,
  fixedHeight = false,
  showActiveItem = true
}) => {
  const activeOption = options.find((o) => o.option === activeItem);
  const selectPlaceHolder = () => {
    if (showActiveItem && activeOption?.label) {
      return activeOption?.label;
    }
    if (placeHolderText) {
      return <span className="atlas-text-neutral-700">{placeHolderText}</span>;
    }
    if (showActiveItem) {
      return options[0]?.label;
    }
  };
  return (
    <div
      data-testid="number-input-dropdown"
      className="atlas-w-full atlas-self-stretch atlas-border-0 atlas-border-neutral-300"
    >
      <DropdownMenu defaultOpen={open}>
        <DropdownMenu.Trigger
          className={twMerge(
            "hover:atlas-bg-neutral-300",
            "atlas-text-left",
            "atlas-rounded-r",
            "atlas-w-full",
            "focus:atlas-outline-none",
            "focus:atlas-border-0",
            "focus-visible:atlas-outline-none",
            "focus-visible:atlas-border-0",
            "atlas-min-h-full",
            "atlas-border-0",
            "atlas-text-base",
            "atlas-py-1.6",
            "atlas-self-stretch",
            "atlas-pr-2",
            "atlas-h-full",
            "atlas-flex",
            "atlas-items-center",
            "atlas-justify-between",
            "atlas-cursor-pointer",
            className
          )}
          data-testid="select-trigger"
        >
          <span
            className="atlas-whitespace-nowrap atlas-w-90 atlas-block atlas-overflow-hidden atlas-overflow-ellipsis atlas-mr-1"
            data-testid="select-active-item"
          >
            {selectPlaceHolder()}
          </span>
          <Icon.ArrowDown />
        </DropdownMenu.Trigger>
        <DropdownMenu.Content
          className={clsx({
            "atlas-z-max atlas-w-48 atlas-min-w-max atlas-overflow-y-scroll": true,
            "atlas-h-96": fixedHeight
          })}
          align="start"
          data-testid="select-dropdown"
        >
          {options.map((o) => (
            <div key={o.option} data-testid="number-input-dropdown-button">
              <DropdownMenu.Item.Link
                className={clsx({ "atlas-bg-primary-25": activeItem === o.option })}
                onClick={() => onSelect(o.option)}
                data-testid="select-option"
                key={o.label}
              >
                {o.label}
              </DropdownMenu.Item.Link>
            </div>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu>
    </div>
  );
};

export { CustomSelect, SelectProps, OptionsType };
