import { Nullable } from "@shared/types/utils";
import { formatNumber } from "@shared/utils/formatting";

export function resetFormatting(input: string | number | undefined) {
  if (input === undefined) {
    return undefined;
  }

  const splitDecimals = String(input).split(".");
  if (splitDecimals.length > 1) {
    if (splitDecimals[1].length >= 2) {
      input = splitDecimals.reduce((prev, newVal) => `${prev}.${newVal.substring(0, 2)}`);
    }
  }

  if (String(input).endsWith(".")) {
    return input;
  }
  return parseFloat(String(input).replace(/,/g, ""));
}

export function formatValue(inputVal: string | number | undefined) {
  if (inputVal === undefined) {
    return;
  }

  const formattedString =
    (typeof inputVal === "number" && inputVal >= 0) || typeof inputVal === "string"
      ? String(inputVal)
      : "";

  const valNoCommas = resetFormatting(inputVal);

  if (valNoCommas === undefined) {
    return undefined;
  }

  if (
    formattedString === "" ||
    formattedString === "-" ||
    (formattedString.slice(0, -1).includes(".") === false && formattedString.endsWith("."))
  ) {
    return formattedString;
  }

  const formattingValue =
    typeof valNoCommas === "string" ? parseFloat(String(valNoCommas)) : valNoCommas;

  return formatNumber({ value: formattingValue, maxDecimals: 2 });
}

export const formatNumberToString = (
  value: Nullable<number>,
  finalPresentation = false
): string => {
  let numberAsString;

  if (value === Infinity || value === null || isNaN(value)) {
    return "";
  }

  if (value % 1 !== 0 && finalPresentation) {
    numberAsString = value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  } else {
    numberAsString = value.toLocaleString(undefined, { maximumFractionDigits: 2 });
  }

  return numberAsString === "0" ? "" : numberAsString;
};

export const formatStringToNumber = (value: string): number => {
  let stringWithoutCommas = value.replace(/,/g, "");

  // Trim to 16 digits if too large
  if (stringWithoutCommas.split(".")[0].length > 16) {
    stringWithoutCommas = stringWithoutCommas.substring(0, 16);
  }

  const stringAsNumber = Number(stringWithoutCommas);

  if (stringAsNumber < 0 || value === "") {
    return 0;
  }

  return isNaN(stringAsNumber) ? 0 : stringAsNumber;
};
