import React from "react";
import { Button, ButtonSizes, ButtonVariants, Icon } from "@/react/components";
import { rootStore } from "@/react/lib/persistence/root_store";
import { observer } from "mobx-react-lite";
import clsx from "clsx";

interface CollapseButtonProps {
  className?: string;
  testId?: string;
}

export const CollapseButton = observer(
  ({ className = "", testId = "collapse-button" }: CollapseButtonProps) => {
    return (
      <Button
        size={ButtonSizes.XXS}
        data-testid={testId}
        variant={ButtonVariants.Circle}
        className={clsx(
          "atlas-p-0 atlas-absolute atlas-top-2 collapse-button-right-offset",
          className
        )}
        onClick={() => rootStore.utilityStore.toggleSpreadTools()}
      >
        {!rootStore.utilityStore.spreadToolsHidden && <Icon.ArrowLeftSFill />}
        {rootStore.utilityStore.spreadToolsHidden && <Icon.ArrowRightSFill />}
      </Button>
    );
  }
);
