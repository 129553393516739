<template>
  <b-card class="sales-section" data-testid="appraisal_section">
    <template #header>
      {{ sectionTitle }}
      <b-button
        :variant="showAnnotations ? 'primary' : 'outline-primary'"
        ref="toggleAnnotationsButton"
        class="annotation-toggle-button"
        :id="`annotation-button-${annotationType}`"
        data-testid="annotation_toggle_button"
        :class="showAnnotations ? 'annotation-enabled' : 'annotation-disabled'"
        @click="toggleSectionAnnotation"
        @mouseleave="cancelTooltipTimeout"
      >
        <b-icon class="annotation-icon" icon="sticky" />
      </b-button>
      <b-tooltip
        :target="`annotation-button-${annotationType}`"
        :show.sync="showAnnotationTooltip"
        triggers="hover"
        :placement="showAnnotations ? 'bottom' : 'top'"
        :delay="{ show: tooltipDelay, hide: 0 }"
      >
        <span v-if="showAnnotations" data-testid="annotation_tooltip">Hide annotation</span>
        <span v-else data-testid="annotation_tooltip">Add annotation</span>
      </b-tooltip>
    </template>
    <div>
      <Transition name="slide">
        <div data-testid="annotation_textarea">
          <Annotations :type="annotationType" v-if="showAnnotations" />
        </div>
      </Transition>
      <slot />
    </div>
  </b-card>
</template>

<script lang="ts">
import { Component, Ref, Prop, Mixins } from "vue-property-decorator";
import EventLoggerMixin from "@/mixins/event_logger_mixin";
import { Annotations } from "@/react/domains/appraisal";

@Component({
  components: {
    Annotations: Annotations as Vue.Component
  }
})
export default class AppraisalSection extends Mixins(EventLoggerMixin) {
  @Prop({ required: true }) readonly sectionTitle!: string;
  @Prop({ required: true }) readonly annotationType!: string;
  @Ref() readonly toggleAnnotationsButton!: HTMLElement;

  private showAnnotations: boolean = false;
  private showAnnotationTooltip: boolean = false;
  private tooltipDelay: number = 1000;
  private tooltipTimeout: any = null;

  public toggleSectionAnnotation() {
    this.showAnnotations = !this.showAnnotations;
    this.showAnnotationTooltip = false;
    this.tooltipTimeout = setTimeout(() => {
      this.showAnnotationTooltip = true;
    }, this.tooltipDelay);
  }

  public cancelTooltipTimeout() {
    clearTimeout(this.tooltipTimeout);
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/landtech-colors";

.card-header {
  display: flex;
}
.annotation-toggle-button.btn {
  height: 38px;
  width: 38px;
  padding: 9px;
  margin-left: auto;
}
.annotation-toggle-button.annotation-enabled:focus:not(:hover) {
  background-color: $ui-interaction-primary;
  border-color: $ui-interaction-primary;
}
.annotation-toggle-button.btn .annotation-icon {
  font-size: 14px;
  vertical-align: initial;
  margin-bottom: 1px;
}
.btn-outline-primary.annotation-disabled {
  border-color: $gray-400;
  box-shadow: none;
}
.annotation-textarea {
  color: $gray-700;
  margin-bottom: 20px;
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease-out;
}
.slide-enter,
.slide-leave-to {
  max-height: 0px;
  margin-bottom: 0px;
}
.slide-enter-to,
.slide-leave {
  max-height: 200px;
  margin-bottom: 20px;
}
</style>
