"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasPlanningSearch = exports.hasOwnershipSearch = exports.hasReportBranding = exports.hasLandFundAccess = exports.hasLandInsightAccess = exports.hasFeature = exports.hasBetaFeature = exports.UserFeatures = void 0;
var UserFeatures;
(function (UserFeatures) {
    UserFeatures["LANDFUND_ACCESS"] = "landFundAccess";
    UserFeatures["LANDFUND_REPORT_BRANDING"] = "landFundReportBranding";
    UserFeatures["LANDFUND_BUILD_COST_DATA"] = "landFundBuildCostData";
    UserFeatures["LI_ACCESS"] = "landInsightAccess";
    UserFeatures["OWNERSHIP_SEARCH"] = "ownershipSearch";
    UserFeatures["PLANNING_SEARCH"] = "planningSearch";
})(UserFeatures = exports.UserFeatures || (exports.UserFeatures = {}));
const hasBetaFeature = (user, betaFeature) => user === null || user === void 0 ? void 0 : user.beta_features.includes(betaFeature);
exports.hasBetaFeature = hasBetaFeature;
const hasFeature = (user, feature) => { var _a; return (_a = user === null || user === void 0 ? void 0 : user.features) === null || _a === void 0 ? void 0 : _a.includes(feature); };
exports.hasFeature = hasFeature;
const hasLandInsightAccess = (user) => (0, exports.hasFeature)(user, UserFeatures.LI_ACCESS);
exports.hasLandInsightAccess = hasLandInsightAccess;
const hasLandFundAccess = (user) => (0, exports.hasFeature)(user, UserFeatures.LANDFUND_ACCESS);
exports.hasLandFundAccess = hasLandFundAccess;
const hasReportBranding = (user) => (0, exports.hasFeature)(user, UserFeatures.LANDFUND_REPORT_BRANDING);
exports.hasReportBranding = hasReportBranding;
const hasOwnershipSearch = (user) => (0, exports.hasFeature)(user, UserFeatures.OWNERSHIP_SEARCH);
exports.hasOwnershipSearch = hasOwnershipSearch;
const hasPlanningSearch = (user) => (0, exports.hasFeature)(user, UserFeatures.PLANNING_SEARCH);
exports.hasPlanningSearch = hasPlanningSearch;
