import {
  calculateGrossLoan,
  calculateInterest,
  calculateLoanTerm,
  loanCost,
  loanFees
} from "@shared/utils/loan";
import { createNewLoan, ValidationOptions, Validator } from "@/react/utils";
import { MAX_MONTH_INPUT } from "@shared/utils/constants";
import { ClientLoan, InterestCalculationType, LoanAction } from "@shared/types/loan";
import { makeAutoObservable } from "mobx";
import { Nullable } from "@shared/types/utils";
import { Calculations } from "@/react/components/Forms/NumberInput/types";
import { ComputableCalculationType } from "@shared/types/computable";
import { RootStore } from "../Root";

export class CurrentLoanStore {
  readonly root: RootStore;

  currentLoan: ClientLoan = createNewLoan();
  isEditing: boolean = false;
  loanPriorToUpdate: Nullable<ClientLoan> = null;
  validator: Validator = new Validator([]);

  constructor(rootStore: RootStore) {
    this.root = rootStore;

    makeAutoObservable(this, { root: false });
    this.buildValidatorOptions();
  }

  /**
   * Function to build the validator with the required validations
   * @param reValidate: Boolean - Whether or not to update the this.currentLoan validations not validate the update
   */
  buildValidatorOptions(reValidate = false) {
    let options: ValidationOptions[] = [
      { name: "name", type: "string" },
      { name: "amount", type: "computable", min: 0, minIncl: true },
      { name: "rate", type: "number", min: 0 },
      { name: "arrangementFee", type: "computable", min: 0, minIncl: true },
      { name: "exitFee", type: "computable", min: 0, minIncl: true }
    ];
    if (!this.hasCashflowInterest) {
      options.push({ name: "term", type: "number", min: 0, max: MAX_MONTH_INPUT });
    }
    this.validator.buildValidations(options);

    if (reValidate) {
      this.validator.validate(this.currentLoan);
    }
  }

  setNewLoan() {
    this.currentLoan = createNewLoan();
    this.buildValidatorOptions();
    this.currentLoan.position = this.root.loanStore.loans.length;
  }

  setLoanPriorToUpdate(loan: ClientLoan) {
    this.loanPriorToUpdate = loan;
  }

  resetLoanPriorToUpdate() {
    this.loanPriorToUpdate = null;
  }

  setCurrentLoan(loan: ClientLoan) {
    this.currentLoan = loan;
  }

  updateCurrentLoan(loanUpdate: Partial<ClientLoan>) {
    this.currentLoan = { ...this.currentLoan, ...loanUpdate };
    this.buildValidatorOptions(!this.validator.allValid && !!loanUpdate.interestCalculation);
    this.validator.validate(loanUpdate);
    if (this.validator.willBeAllValid(this.currentLoan)) {
      this.root.loanStore.updateLoanLocally(this.currentLoan);
    }
  }

  setIsEditing(value: boolean) {
    this.isEditing = value;
  }

  startEditingLoan(loan?: ClientLoan) {
    if (loan) {
      this.setIsEditing(true);
      this.setCurrentLoan(loan);
      this.setLoanPriorToUpdate(loan);
    } else {
      this.setIsEditing(false);
      this.setNewLoan();
      this.root.loanStore.addLoanLocally(this.currentLoan);
    }
    this.root.utilityStore.openLoanModal();
    // We have to remove this synchronicity as the NumberInputs
    // all emit an update when swapping from Editing to Adding and throw off the
    // validity reset
    setTimeout(() => this.resetValidity(), 1);
  }

  finishEditingLoan() {
    this.validate();
    if (!this.validator.allValid) {
      return;
    }
    this.root.loanStore.updateApollo();
    this.root.utilityStore.closeLoanModal();
    this.resetLoanPriorToUpdate();
  }

  cancelEditingLoan() {
    if (this.isEditing) {
      this.root.loanStore.updateLoanLocally(this.loanPriorToUpdate as ClientLoan);
    } else {
      this.root.loanStore.removeLoanLocally(this.currentLoan);
    }
    this.resetLoanPriorToUpdate();
    this.root.utilityStore.closeLoanModal();
  }

  validate() {
    this.validator.validate(this.currentLoan);
  }

  resetValidity() {
    this.validator.resetAll();
  }

  get loanFees() {
    return loanFees(this.currentLoan, this.root.cashflowFinanceStore.financeOutputs.loanOutputs);
  }

  get loanCost() {
    return loanCost(this.currentLoan, this.root.cashflowFinanceStore.financeOutputs.loanOutputs);
  }

  get interest() {
    return calculateInterest(
      this.currentLoan,
      this.root.cashflowFinanceStore.financeOutputs.loanOutputs
    );
  }

  get currentLoanGrossAmount() {
    return calculateGrossLoan(
      this.currentLoan,
      this.root.cashflowFinanceStore.financeOutputs.loanOutputs
    );
  }

  get term() {
    return calculateLoanTerm(
      this.currentLoan,
      this.root.cashflowFinanceStore.financeOutputs.loanOutputs
    );
  }

  get hasCashflowInterest() {
    return this.currentLoan.interestCalculation === InterestCalculationType.DrawnBalanceCashflow;
  }

  get currentLoanIsComplete() {
    const { amount, term, rate } = this.currentLoan;
    return !!(amount.value || amount.calculationBase) && !!term && !!rate;
  }

  get feeCalculationOptions(): Calculations[] {
    // Need to explicitly set this value here
    // to help mobx notice that this getter depends on currentGrossAmount.
    // Seems if a getter return a function, we have to instantiate
    // everything it uses before returning
    const currentGrossAmount = this.currentLoanGrossAmount;
    return [
      {
        type: ComputableCalculationType.PERCENTAGE_OF_LOAN,
        label: "of Loan",
        suffix: "%",
        calculateValueFromBase: (base: number) => (base / 100) * currentGrossAmount,
        calculateBaseFromValue: (value: number) => (value / currentGrossAmount) * 100
      }
    ];
  }

  get percentageOfFundableCosts() {
    return (this.currentLoanGrossAmount / this.root.costStore.totalFundableCost) * 100;
  }

  get percentageOfGdv() {
    return (this.currentLoanGrossAmount / this.root.unitGroupStore.totalSales) * 100;
  }
}
