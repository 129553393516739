import gql from "graphql-tag";
import { MiniAppraisalFragment, MiniDevelopmentFragment } from "@shared/apollo/fragments";

export const CREATE_DEVELOPMENT_MUTATION = gql`
  mutation ($developmentInput: DevelopmentCreateInput) {
    createDevelopment(developmentInput: $developmentInput) {
      ...MiniDevelopment
    }
  }
  ${MiniDevelopmentFragment}
`;

export const DELETE_DEVELOPMENT_MUTATION = gql`
  mutation ($id: ObjectID!) {
    deleteDevelopment(id: $id)
    deleteDevelopmentAppraisals(id: $id)
  }
`;

export const MINI_DEVELOPMENT_BY_ID_QUERY = `
  query ($id: ObjectID!) {
    developmentById(id: $id) {
      ...MiniDevelopment
    }
  }
  ${MiniDevelopmentFragment}
`;

export const DEVELOPMENT_APPRAISALS_QUERY = `
  query ($id: ObjectID!) {
    developmentById(id: $id) {
      _id
      appraisals {
        ...MiniAppraisal
      }
    }
  }
  ${MiniAppraisalFragment}
`;
