import { validatePostcode } from "@shared/utils/validatePostcode";
export enum AvailableCountryCodes {
  UNITED_KINGDOM = "GB"
}

export interface Address {
  addressLine1: string;
  city: string;
  region: string;
  postcode: string;
  countryCode?: AvailableCountryCodes;
}

/**
 * NOTE: This function has been taken from LandInsight and the sites team
 * It has been modified slightly to ensure the functionality we had before changes
 * We have also removed the internationalisation as this is currently not required for LandFund and adds complexity
 */
export const parseUkAddress = (address: string): Address => {
  let addressLine1 = "";
  let city = "";
  let region = "";

  let postcode =
    address.match(/([A-Z][A-HJ-Y]?\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})/i)?.[0] ?? undefined;

  if (!postcode) {
    const code = address.split(",").pop()?.replace(/ /, "");
    const { status } = validatePostcode(code);
    if (status) {
      postcode = code ?? "";
    } else {
      postcode = "";
    }
  }
  const addressWithoutPostcode = address.replace(postcode, "");
  const addressParts = addressWithoutPostcode
    .split(",")
    .map((part) => part.trim())
    .filter((part) => !!part);
  if (addressParts.length <= 3) {
    [addressLine1 = "", city = "", region = ""] = addressParts;
  } else {
    region = addressParts.slice(-1)[0] || "";
    city = addressParts.slice(-2)[0] || "";
    addressLine1 = addressParts.slice(0, -2).join(", ");
  }
  return {
    addressLine1,
    city,
    region,
    postcode,
    countryCode: AvailableCountryCodes.UNITED_KINGDOM
  };
};

export const parseAddress = (address?: string): Address => {
  if (!address) {
    return {
      addressLine1: "",
      city: "",
      region: "",
      postcode: ""
    };
  }
  return parseUkAddress(address);
};
