import { Button, ButtonThemes, Modal } from "@/react/components";
import { useTranslation } from "@/react/lib/hooks";
import { AppraisalIncomeTranslations, AppraisalSingularWords } from "@/react/lib/i18n/locales";
import { rootStore } from "@/react/lib/persistence/root_store";
import { UnitGroupAction } from "@/utils/unit_groups";
import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import {
  AddUnitGroupForm,
  AddUnitsForm,
  EditUnitForm,
  EditUnitGroupForm,
  EditUnitsForm
} from "./forms";

const UnitGroupModalObserved: FC = observer(() => {
  const { t } = useTranslation();
  const handleClose = () => {
    rootStore.utilityStore.closeUnitGroupModal();
    rootStore.currentUnitGroupStore.reset();
  };
  const handleConfirm = () => {
    rootStore.currentUnitGroupStore.validate();
    if (rootStore.currentUnitGroupStore.validator.allValid) {
      rootStore.currentUnitGroupStore.submit();
      rootStore.utilityStore.closeUnitGroupModal();
      rootStore.currentUnitGroupStore.reset();
    }
  };
  const footerContent = () => (
    <>
      <Button theme={ButtonThemes.Secondary} className="atlas-mr-2" onClick={handleClose}>
        <span data-testid="unit-group-modal-cancel">
          {t(AppraisalSingularWords.Appraisal_Cancel)}
        </span>
      </Button>
      <Button theme={ButtonThemes.Primary} onClick={handleConfirm}>
        <span data-testid="unit-group-modal-save">{t(AppraisalSingularWords.Appraisal_Save)}</span>
      </Button>
    </>
  );

  const title = () => {
    switch (rootStore.currentUnitGroupStore.unitGroupAction) {
      case UnitGroupAction.ADDING_UNIT_GROUPS:
        return t(AppraisalIncomeTranslations.AppraisalIncome_AddUnitGroup);
      case UnitGroupAction.ADDING_UNITS:
        return t(AppraisalIncomeTranslations.AppraisalIncome_AddUnits);
      case UnitGroupAction.EDITING_UNIT_GROUP:
        return t(AppraisalIncomeTranslations.AppraisalIncome_EditUnitGroup);
      case UnitGroupAction.EDITING_UNIT:
        return t(AppraisalIncomeTranslations.AppraisalIncome_EditUnit);
      case UnitGroupAction.EDITING_UNITS:
        return t(AppraisalIncomeTranslations.AppraisalIncome_EditUnits);
    }
  };
  const form = () => {
    switch (rootStore.currentUnitGroupStore.unitGroupAction) {
      case UnitGroupAction.ADDING_UNIT_GROUPS:
        return <AddUnitGroupForm />;
      case UnitGroupAction.ADDING_UNITS:
        return <AddUnitsForm />;
      case UnitGroupAction.EDITING_UNIT_GROUP:
        return <EditUnitGroupForm />;
      case UnitGroupAction.EDITING_UNIT:
        return <EditUnitForm />;
      case UnitGroupAction.EDITING_UNITS:
        return <EditUnitsForm />;
    }
  };

  return (
    <>
      {!!rootStore.utilityStore.unitGroupModalOpen && (
        <Modal
          data-testid="UnitGroupModal"
          title={title()}
          defaultOpen={true}
          onClose={handleClose}
          className="md:atlas-max-w-xl"
          footerClassNames="atlas-justify-end atlas-text-right"
          footerContent={footerContent()}
        >
          <div className="atlas-w-full atlas-p-4">{form()}</div>
        </Modal>
      )}
    </>
  );
});

export const UnitGroupModal = () => {
  return <UnitGroupModalObserved />;
};
