import React, { FC, PropsWithChildren } from "react";
import * as TabsPrimitive from "@radix-ui/react-tabs";

interface TabListProps {
  className?: string;
}

const TabsList: FC<PropsWithChildren<TabListProps>> = ({ className = "", children }) => {
  return <TabsPrimitive.List className={className}>{children}</TabsPrimitive.List>;
};

export { TabsList };
