import { Checkbox, CheckboxState } from "@/react/components";
import clsx from "clsx";
import React, { Dispatch, useEffect, useState } from "react";
import { TableOptions, useTable } from "react-table";
import "./SelectableTable.css";
import { SelectableTableRow, SelectedRowsInfoHeader } from "./components";

interface SelectableTableCustomProps {
  tableId: string;
  hasFooter: boolean;
  onDeleteSelected: Dispatch<string[]>;
}

export function SelectableTable<Opts extends { _id: string }>({
  columns,
  data,
  tableId,
  hasFooter,
  onDeleteSelected
}: SelectableTableCustomProps & TableOptions<Opts>) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, footerGroups } =
    useTable({ columns, data });

  const [selectedRowIds, setSelectedRowIds] = useState<Array<string>>([]);

  const handleSelected = (id: string) => {
    setSelectedRowIds((prevSelectedRowsIds) => [...prevSelectedRowsIds, id]);
  };

  const handleDeselected = (id: string) => {
    setSelectedRowIds((prevSelectedRowsIds) =>
      prevSelectedRowsIds.filter((selectedRowId) => selectedRowId !== id)
    );
  };

  useEffect(() => {
    setSelectedRowIds((prevSelectedRowsIds) =>
      prevSelectedRowsIds.filter((id) => rows.map((row) => row.original._id).includes(id))
    );
  }, [data]);

  const selectAllStatus = () => {
    if (selectedRowIds.length === rows.length) {
      return CheckboxState.CHECKED;
    }
    if (selectedRowIds.length) {
      return CheckboxState.INDETERMINATE;
    }
    return CheckboxState.EMPTY;
  };

  const onChangeSelectAll = (value: boolean) => {
    if (value) {
      setSelectedRowIds(rows.map((row) => row.original._id));
    } else {
      setSelectedRowIds([]);
    }
  };

  const tdClasses = "atlas-px-3 atlas-py-2";

  function alignmentClasses(index: number, headersLength: number): Array<Record<string, boolean>> {
    return [
      { "": index === 0 },
      { "atlas-text-left": index > 0 && index < headersLength - 2 },
      { "atlas-text-right": index >= headersLength - 2 }
    ];
  }

  return (
    <div className="atlas-w-full atlas-overflow-x-auto atlas-text-neutral-800">
      {!!selectedRowIds.length && (
        <table>
          <thead>
            <SelectedRowsInfoHeader
              tableId={tableId}
              selectedRowIds={selectedRowIds}
              selectAllStatus={selectAllStatus()}
              onChangeSelectAll={onChangeSelectAll}
              onDeleteSelected={() => onDeleteSelected(selectedRowIds)}
            />
          </thead>
        </table>
      )}
      <table
        {...getTableProps()}
        className="atlas-w-full atlas-border-collapse"
        data-testid="selectable-table"
      >
        <thead
          className={clsx({ "atlas-collapse": !!selectedRowIds.length })}
          data-testid="table-header"
        >
          {headerGroups.map(
            (headerGroup) =>
              headerGroup.headers && (
                <tr {...headerGroup.getHeaderGroupProps()} className="atalas-w-full">
                  <th
                    className={clsx(
                      "atlas-bg-neutral-100 atlas-border-0 atlas-px-3 atlas-py-2 atlas-w-8"
                    )}
                    role="columnheader"
                  >
                    <Checkbox
                      id={"selectAll_" + tableId}
                      onChange={onChangeSelectAll}
                      value={selectAllStatus()}
                    />
                  </th>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps()}
                      className={clsx(
                        "atlas-bg-neutral-100 atlas-self-stretch",
                        { "atlas-w-1/12": index === headerGroup.headers.length - 1 },
                        tdClasses,
                        ...alignmentClasses(index, headerGroup.headers.length)
                      )}
                      role="columnheader"
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              )
          )}
        </thead>
        <tbody {...getTableBodyProps()} data-testid="table-body">
          {rows.map((row) => {
            prepareRow(row);
            return (
              <SelectableTableRow<Opts>
                key={row.original._id}
                row={row}
                tdClassName={tdClasses}
                selectedRowIds={selectedRowIds}
                onSelected={handleSelected}
                onDeselected={handleDeselected}
              />
            );
          })}
        </tbody>
        <tfoot>
          {footerGroups.map((group) => (
            <tr
              {...group.getFooterGroupProps()}
              className={clsx({
                "atlas-border-0 atlas-border-t atlas-border-solid atlas-border-neutral-500":
                  hasFooter
              })}
            >
              <td></td>
              {group.headers.map((column, index) => (
                <td
                  {...column.getFooterProps()}
                  className={clsx(tdClasses, ...alignmentClasses(index, group.headers.length))}
                >
                  {column.render("Footer")}
                </td>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
    </div>
  );
}
