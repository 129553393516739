import { IAppraisalCalculations } from "@shared/types/appraisalCalculations";
import { RootStore } from "../lib/persistence/root_store";

// Map what we have in the stores to what the server is expecting
export const convertStoresToAppraisalCalculations = (root: RootStore): IAppraisalCalculations => {
  return {
    unitSetting: root.userStore.areaUnit,
    profit: root.costStore.profit,
    profitOnGDV: root.costStore.profitOnGDV,
    profitOnCost: root.costStore.profitOnCost,
    developerProfit: root.equityFundingStore.developerProfit,
    equityTotalAmount: root.equityFundingStore.totalEquityAmount,
    totalEquityInterest: root.equityFundingStore.totalEquityInterest,
    equityProfitShare: root.equityFundingStore.totalProfitShared,
    totalEquityFunding: root.equityFundingStore.totalEquityAmount,
    developerEquity: root.equityFundingStore.developerEquity,
    totalNIA: root.unitGroupStore.convertedTotalNIA,
    totalGIA: root.unitGroupStore.convertedTotalGIA,
    salesPerAreaUnit: root.unitGroupStore.salesPerAreaUnit,
    buildCostWithContingencyPerAreaUnit: root.buildPhaseStore.buildCostWithContingencyPerAreaUnit,
    landToSales: root.unitGroupStore.landToSales,
    netDevelopmentValue: root.unitGroupStore.netDevelopmentValue,
    totalCostsWithContingency: root.costStore.totalCostsWithContingency,
    totalCostExFunding: root.costStore.totalCostExFunding,
    totalFundableCost: root.costStore.totalFundableCost,
    totalFundingCosts: root.loanStore.totalFundingCosts,
    totalLandCosts: root.costStore.totalLandCosts,
    buildCosts: root.costStore.buildCosts,
    professionalFees: root.costStore.professionalFees,
    otherCosts: root.costStore.otherCosts,
    landFees: root.costStore.landFees,
    customCosts: root.costStore.customCosts,
    totalBuildCostsWithoutContingency: root.costStore.totalBuildCostWithoutContingency,
    totalBuildCostsWithContingency: root.buildPhaseStore.totalBuildCostWithContingency,
    totalBuildContingency: root.buildPhaseStore.totalBuildContingency,
    totalProfessionalFees: root.costStore.totalProfessionalFees,
    totalOtherCosts: root.costStore.totalOtherCosts,
    totalLandFees: root.costStore.totalLandFees,
    totalCalculatedLandFees: root.costStore.totalCalculatedLandFees,
    totalSalesFees: root.unitGroupStore.totalSalesFees,
    totalDevelopmentCost: root.costStore.totalDevelopmentCost,
    totalGrossLoan: root.loanStore.totalGrossLoan,
    totalNetBorrowing: root.loanStore.totalNetBorrowing,
    equityContribution: root.loanStore.equityContribution,
    totalSales: root.unitGroupStore.totalSales
  };
};
