var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('b-card',{staticClass:"development-card",attrs:{"data-intercom-target":"development_card"}},[_c('div',{staticClass:"development-card-content"},[_c('div',{staticClass:"development-header flex-row"},[(_vm.development.title)?_c('h2',[_vm._v(_vm._s(_vm.development.title))]):_c('h2',{staticClass:"font-italic text-muted"},[_vm._v("Untitled Development")]),_vm._v(" "),_c('div',{staticClass:"pull-right d-flex align-items-center"},[(_vm.active)?_c('OptionsMenu',{attrs:{"objectId":_vm.development._id,"objectType":"Development"},on:{"delete":() => {
                _vm.developmentsStore.deleteDevelopment(_vm.development._id);
                _vm.logEvent('Development Deleted', { development_id: _vm.development._id });
              },"archive":() => {
                _vm.developmentsStore.toggleArchiveDevelopment(_vm.development._id);
                _vm.logEvent('Development Archived', {
                  development_id: _vm.development._id
                });
              }}}):_c('OptionsMenu',{attrs:{"objectId":_vm.development._id,"objectType":"Development"},on:{"delete":() => {
                _vm.developmentsStore.deleteDevelopment(_vm.development._id);
                _vm.logEvent('Development Deleted', { development_id: _vm.development._id });
              },"restore":() => {
                _vm.developmentsStore.toggleArchiveDevelopment(_vm.development._id);
                _vm.logEvent('Development Restored', {
                  development_id: _vm.development._id
                });
              }}})],1)]),_vm._v(" "),_c('div',{staticClass:"development-body"},[_c('p',[_vm._v(_vm._s(_vm.development.description))])]),_vm._v(" "),_c('div',{staticClass:"development-footer flex-row"},[(_vm.miniSite)?_c('span',{staticClass:"footer-text address-tag"},[(_vm.miniSite.address)?_c('span',[_c('b-icon',{attrs:{"icon":"geo-alt"}}),_vm._v("\n            "+_vm._s(_vm.miniSite.address)+"\n          ")],1):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"footer-text pull-right"},[_c('span',[_vm._v(_vm._s(_vm.pluralise("Appraisal", _vm.development.appraisalCount || 0)))])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }