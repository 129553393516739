import React, { FC } from "react";
import { observer } from "mobx-react-lite";
import { Select } from "@/react/components";
import { rootStore } from "@/react/lib/persistence/root_store";
import { useTranslation } from "@/react/lib/hooks";
import { CILMessaging } from "@/react/lib/i18n/locales";
import { logEvent } from "@/react/utils";

const AuthoritySelectObservable: FC = observer(() => {
  const { t } = useTranslation();
  async function selectAuthority(gssCode: string) {
    await rootStore.CILStore.selectAuthority(gssCode);
    logEvent("CIL Local Authority Selected", {
      appraisal_id: rootStore.appraisalStore.appraisalId,
      development_id: rootStore.appraisalStore.developmentId,
      CIL_local_authority: rootStore.CILStore.selectedAuthority?.name,
      linked_to_site: rootStore.developmentsStore.isDevelopmentLinkedToSite(
        rootStore.appraisalStore.developmentId
      )
    });
  }
  return (
    <Select
      value={rootStore.CILStore.selectedAuthority?.gss_code}
      options={rootStore.CILStore.authorities.map((authority) => ({
        label: authority.name,
        option: authority.gss_code
      }))}
      testId="authority-select"
      label={t(CILMessaging.Authority_Select_Title)}
      placeHolderText={t(CILMessaging.Authority_Select_Placeholder)}
      onChange={(value) => selectAuthority(value as string)}
      fixedHeight={true}
      selectClassName="atlas-bg-transparent"
    />
  );
});

export const AuthoritySelect: FC = () => <AuthoritySelectObservable />;
