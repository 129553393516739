import {
  AppraisalAnnotationType,
  ClientAppraisalAnnotation,
  ClientAppraisalAnnotations
} from "@shared/types/appraisalAnnotation";
import { makeAutoObservable } from "mobx";
import { debounce } from "lodash";
import { updateAppraisalAnnotation } from "@/react/lib/persistence/apollo";
import { buildInitialAnnotations } from "./AppraisalAnnotationsStoreUtil";
import { RootStore } from "../Root";

export class AppraisalAnnotationStore {
  readonly root: RootStore;
  annotations: ClientAppraisalAnnotations = buildInitialAnnotations();

  debounceAnnotationUpdate = debounce(
    (type: AppraisalAnnotationType, annotation: ClientAppraisalAnnotation) =>
      this.updateApollo(type, annotation),
    600
  );

  constructor(rootStore: RootStore) {
    this.root = rootStore;
    makeAutoObservable(this, { root: false });
  }

  async updateApollo(type: AppraisalAnnotationType, update: ClientAppraisalAnnotation) {
    const currentAppraisal = this.root.getCurrentAppraisal();

    if (currentAppraisal._id) {
      try {
        await updateAppraisalAnnotation(currentAppraisal._id, {
          _id: currentAppraisal.appraisalAnnotations[type]._id,
          appraisalAnnotationType: type,
          text: update.text
        });
      } catch (e) {
        this.annotations[type] = currentAppraisal.appraisalAnnotations[type];
      }
    }
  }

  setAnnotation(type: AppraisalAnnotationType, annotation: ClientAppraisalAnnotation | undefined) {
    if (annotation) {
      this.annotations[type] = annotation;
      this.debounceAnnotationUpdate(type, annotation);
    }
  }

  setupAnnotations(annotations: ClientAppraisalAnnotations) {
    if (annotations) {
      this.annotations = annotations;
    }
  }
}
