"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RESIDUAL_LAND_CALCULATIONS = exports.PropertyUpdatedSeparately = void 0;
var PropertyUpdatedSeparately;
(function (PropertyUpdatedSeparately) {
    PropertyUpdatedSeparately["appraisalAnnotation"] = "appraisalAnnotation";
})(PropertyUpdatedSeparately = exports.PropertyUpdatedSeparately || (exports.PropertyUpdatedSeparately = {}));
exports.RESIDUAL_LAND_CALCULATIONS = [
    "Profit on GDV",
    "Profit on Cost",
    "Total Profit"
];
