<template>
  <div class="content templates">
    <div class="template-list">
      <div class="templates-header-row flex-row">
        <h1 class="h4 title">Templates</h1>
        <VersionAlert class="flex-grow-1" />
      </div>
      <div v-if="templateStore.templates" class="template-card-row flex-row">
        <b-table-simple
          hover
          primary-key="_id"
          table-variant="white"
          selected-variant="primary"
          fixed
        >
          <b-thead head-variant="landTech">
            <b-tr>
              <b-th>Title</b-th>
              <b-th>Description</b-th>
              <b-th></b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="template in templateStore.templates"
              :key="template._id"
              :class="{
                'cursor-pointer': true,
                'b-table-row-selected table-primary': selectedTemplateId === template._id
              }"
              @click="onSelect(template)"
            >
              <b-td v-if="template.title">{{ template.title }}</b-td>
              <b-td v-else class="font-italic text-muted">Untitled Template</b-td>
              <b-td>
                <div class="text-overflow-ellipsis">
                  <div :id="'template-desc-' + template._id">
                    {{ template.description }}
                  </div>
                  <b-tooltip
                    :target="'template-desc-' + template._id"
                    :title="template.description"
                  />
                </div>
              </b-td>
              <b-td>
                <div class="text-right">
                  <OptionsMenu
                    class="pull-right"
                    :objectId="template._id"
                    objectType="Template"
                    @delete="deleteTemplate"
                    @duplicate="
                      () => {
                        templateStore.duplicateTemplate(template._id);
                        logEvent('Template Duplicated', {
                          old_template_id: template._id
                        });
                      }
                    "
                    :setPopperOpts="false"
                    width="100px"
                  />
                </div>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <b-button
          variant="primary"
          @click.prevent="
            () => {
              templateStore.createTemplate();
              logEvent('Template Created');
            }
          "
        >
          <span>Add New Template</span>
        </b-button>
      </div>
    </div>
    <b-sidebar
      class="template-edit-panel"
      :visible="selectedTemplateId !== null || sidebarVisible"
      right
      z-index="1"
      shadow
      title="Edit Template"
      no-close-on-esc
      :no-header="sidebarVisible"
      :no-header-close="sidebarVisible"
      bg-variant="white"
      :backdrop="selectedTemplateId !== null && !sidebarVisible"
      @hidden="selectedTemplateId = null"
      width="700px"
    >
      <div v-if="selectedTemplateId && !appraisalStore.isSetup">
        <b-progress :value="100" variant="primary" :animated="true" class="mt-3" />
      </div>
      <div v-else-if="selectedTemplateId">
        <AppraisalDetails />

        <IncomeSection />

        <BuildSection />

        <b-card header="Professional Fees">
          <CostList
            costType="professional-fee"
            :calculationOptions="calculationsStore.percentageCalculationOptions"
            :costs="costStore.professionalFees"
          />
        </b-card>

        <b-card header="Other Costs">
          <CostList
            costType="other-cost"
            :calculationOptions="calculationsStore.otherCostsCalculationOptions"
            :costs="costStore.otherCosts"
          />
        </b-card>

        <CostModal />

        <FundingSection />

        <ResidualLandValue />
      </div>
      <div v-else class="flex-row height-100">
        <div class="empty-state">
          <h5><b-icon icon="pencil" />Edit Template</h5>
          <p>Select a template from the list to edit</p>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>

<script lang="ts">
import { Mixins, Component } from "vue-property-decorator";
import EventLoggerMixin from "@/mixins/event_logger_mixin";
import AppraisalDetails from "@/components/AppraisalDetails.vue";
import OptionsMenu from "@/components/OptionsMenu.vue";
import { IncomeSection } from "@/react/layouts/AppraisalPage/Income/IncomeSection";
import BuildSection from "@/components/BuildSection/BuildSection.vue";
import FundingSection from "@/components/FundingSection/FundingSection.vue";
import { ResidualLandValue } from "@/react/domains/appraisal";
import VersionAlert from "@/components/VersionAlert.vue";
import ResizeMixin from "@/mixins/resize_mixin";
import { Nullable } from "@shared/types/utils";
import { ClientMiniTemplate } from "@shared/types/appraisal";
import { Observer } from "mobx-vue";
import { CostList } from "@/react/domains/appraisal/Costs/CostList/CostList";
import { CostModal } from "@/react/domains/appraisal/Costs/CostModal/CostModal";
import { rootStore } from "@/react/lib/persistence/root_store";

@Observer
@Component({
  components: {
    AppraisalDetails,
    OptionsMenu,
    CostList: CostList as Vue.Component,
    BuildSection,
    FundingSection,
    ResidualLandValue: ResidualLandValue as Vue.Component,
    IncomeSection: IncomeSection as Vue.Component,
    CostModal: CostModal as Vue.Component,
    VersionAlert
  }
})
export default class TemplatesPage extends Mixins(EventLoggerMixin, ResizeMixin) {
  public costStore = rootStore.costStore;
  public calculationsStore = rootStore.calculationsStore;
  public templateStore = rootStore.templateStore;
  public appraisalStore = rootStore.appraisalStore;
  public selectedTemplateId: Nullable<string> = null;

  mounted() {
    this.logEvent("Templates Page Viewed");
    this.templateStore.setupTemplateStore();
  }

  public onSelect(item: ClientMiniTemplate) {
    if (this.selectedTemplateId === item._id) {
      this.selectedTemplateId = null;
    } else {
      this.selectedTemplateId = item._id;
      rootStore.loadAppraisal(this.selectedTemplateId);
      this.logEvent("Template Viewed", { template_id: this.selectedTemplateId });
    }
  }

  public deleteTemplate(id: string) {
    if (this.selectedTemplateId === id) {
      this.selectedTemplateId = null;
    }
    this.templateStore.deleteTemplate(id);
    this.logEvent("Template Deleted", { template_id: id });
  }
}
</script>

<style lang="scss">
.template-edit-panel {
  .b-sidebar {
    top: 48px;
    height: calc(100vh - 48px);
    padding: 0;

    .b-sidebar-body {
      padding: 1.5rem 1.25rem 0 1.25rem;
    }
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/scss/landtech-colors";

.templates {
  padding: 1.25rem;
  padding-top: 1.625rem;
}

.template-list {
  padding: 0;
}

@media (min-width: 1200px) {
  .template-list {
    padding-right: 700px;
  }
}

.template-card-row {
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 1.625rem;
}
.templates-header-row {
  flex-wrap: wrap;
  align-items: center;

  .title {
    font-weight: bold;
  }
}
.templates-header-end {
  align-items: center;
}

.height-100 {
  height: 100%;
}

.template-edit-panel {
  .card {
    margin-bottom: 1.25rem;
  }
}

.table {
  filter: drop-shadow(0px 2px 2px $landfund-box-shadow-colour);
}

.main-panel > .content {
  min-height: calc(100vh - 48px);
}
</style>
