import { FULL_SITE } from "@shared/apollo/queries";
import { landInsightApolloClient } from "../clients";
import gql from "graphql-tag";
import { logError } from "@/react/utils";
import { ClientSite } from "@shared/types/site";

export const getSite = async (id: ClientSite["_id"]): Promise<ClientSite> => {
  const { data, errors } = await landInsightApolloClient.query({
    query: gql`
      ${FULL_SITE}
    `,
    variables: { _id: id }
  });
  if (errors) {
    for (let err of errors) {
      logError(err.message);
    }
    throw new Error(`Error retrieving site`);
  }
  return data.site;
};
