import clsx from "clsx";
import React, { FC, PropsWithChildren } from "react";

interface CashflowRowProps {
  totalRow?: boolean;
  testId?: string;
}

export const CashflowRow: FC<PropsWithChildren<CashflowRowProps>> = ({
  totalRow,
  testId,
  children
}) => {
  return (
    <tr data-testid={testId} className={clsx({ "total-row": totalRow })}>
      {children}
    </tr>
  );
};
