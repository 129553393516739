<template>
  <div>
    <div class="flex-column sensitivity">
      <VersionAlert class="mb-4" />
      <SensitivityAnalysis />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import SensitivityAnalysis from "@/components/SensitivityAnalysis.vue";
import VersionAlert from "@/components/VersionAlert.vue";

@Component({
  components: {
    SensitivityAnalysis,
    VersionAlert
  }
})
export default class SensitivityPage extends Vue {
}
</script>

<style lang="scss" scoped>
.sensitivity {
  padding: 1.25rem;
  padding-top: 1.625rem;
}
</style>
