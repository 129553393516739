"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.interestCalculationTypeDisplay = exports.getMonthlyRate = exports.calculateLoanTerm = exports.calculateNonCashflowGrossLoan = exports.calculateGrossLoan = exports.exitFee = exports.arrangementFee = exports.nonCashflowLoanFees = exports.loanFees = exports.calculateNonCashflowInterestPercentage = exports.calculateNonCashflowInterest = exports.calculateCashflowInterest = exports.calculateInterest = exports.nonCashflowLoanCost = exports.loanCost = void 0;
const loan_1 = require("../types/loan");
const scurve_1 = require("./scurve");
const loanCost = (loan, loanOutputs) => {
    const interest = (0, exports.calculateInterest)(loan, loanOutputs);
    return interest + (0, exports.loanFees)(loan, loanOutputs, interest);
};
exports.loanCost = loanCost;
const nonCashflowLoanCost = (loan) => {
    const interest = (0, exports.calculateNonCashflowInterest)(loan);
    return interest + (0, exports.nonCashflowLoanFees)(loan, interest);
};
exports.nonCashflowLoanCost = nonCashflowLoanCost;
const calculateInterest = (loan, loanOutputs) => {
    let interest = 0;
    if (loan.interestCalculation === loan_1.InterestCalculationType.DrawnBalanceCashflow) {
        interest = (0, exports.calculateCashflowInterest)(loan, loanOutputs);
    }
    else {
        interest = (0, exports.calculateNonCashflowInterest)(loan);
    }
    return parseFloat(interest.toFixed(2));
};
exports.calculateInterest = calculateInterest;
const calculateCashflowInterest = (loan, loanOutputs) => {
    var _a;
    const loanOutput = loanOutputs.find((output) => output.loan._id === loan._id);
    return (_a = loanOutput === null || loanOutput === void 0 ? void 0 : loanOutput.totalInterest) !== null && _a !== void 0 ? _a : 0;
};
exports.calculateCashflowInterest = calculateCashflowInterest;
const calculateNonCashflowInterest = (loan) => {
    const interestPercentage = (0, exports.calculateNonCashflowInterestPercentage)(loan);
    return interestPercentage * loan.amount.value;
};
exports.calculateNonCashflowInterest = calculateNonCashflowInterest;
const calculateNonCashflowInterestPercentage = (loan) => {
    let interestPercentage = 0;
    const monthlyRate = (0, exports.getMonthlyRate)(loan);
    for (let currentMonth = 1; currentMonth <= loan.term; currentMonth++) {
        const k = 3;
        const x0 = loan.term / 2;
        let balancePercentage = loan.interestCalculation === loan_1.InterestCalculationType.FullLoan
            ? 1
            : (0, scurve_1.sCurve)(currentMonth, x0, k);
        if (loan.interestCharging === loan_1.InterestChargingType.RolledUp) {
            balancePercentage += interestPercentage;
        }
        interestPercentage += balancePercentage * (monthlyRate / 100);
    }
    if (loan.interestCharging === loan_1.InterestChargingType.Retained) {
        interestPercentage += interestPercentage * loan.term * (monthlyRate / 100);
    }
    return interestPercentage;
};
exports.calculateNonCashflowInterestPercentage = calculateNonCashflowInterestPercentage;
const loanFees = (loan, loanOutputs, interest) => {
    const grossLoan = (0, exports.calculateGrossLoan)(loan, loanOutputs, interest);
    return (0, exports.arrangementFee)(loan, grossLoan) + (0, exports.exitFee)(loan, grossLoan);
};
exports.loanFees = loanFees;
const nonCashflowLoanFees = (loan, interest) => {
    const grossLoan = (0, exports.calculateNonCashflowGrossLoan)(loan, interest);
    return (0, exports.arrangementFee)(loan, grossLoan) + (0, exports.exitFee)(loan, grossLoan);
};
exports.nonCashflowLoanFees = nonCashflowLoanFees;
const arrangementFee = (loan, grossLoan) => {
    return loan.arrangementFee.calculate
        ? (loan.arrangementFee.calculationBase / 100) * grossLoan
        : loan.arrangementFee.value;
};
exports.arrangementFee = arrangementFee;
const exitFee = (loan, grossLoan) => {
    return loan.exitFee.calculate
        ? (loan.exitFee.calculationBase / 100) * grossLoan
        : loan.exitFee.value;
};
exports.exitFee = exitFee;
const calculateGrossLoan = (loan, loanOutputs, interest = (0, exports.calculateInterest)(loan, loanOutputs)) => {
    if (loan.interestCharging === loan_1.InterestChargingType.Serviced) {
        return loan.amount.value;
    }
    else {
        return loan.amount.value + interest;
    }
};
exports.calculateGrossLoan = calculateGrossLoan;
const calculateNonCashflowGrossLoan = (loan, interest = (0, exports.calculateNonCashflowInterest)(loan)) => {
    if (loan.interestCalculation === loan_1.InterestCalculationType.DrawnBalanceCashflow) {
        throw new Error("Cashflow interest loan provided for calculateNonCashflowGrossLoan");
    }
    if (loan.interestCharging === loan_1.InterestChargingType.Serviced) {
        return loan.amount.value;
    }
    else {
        return loan.amount.value + interest;
    }
};
exports.calculateNonCashflowGrossLoan = calculateNonCashflowGrossLoan;
const calculateLoanTerm = (loan, loanOutputs) => {
    const currentLoanOutput = loanOutputs.find((loanOutput) => loanOutput.loan._id === loan._id);
    if (!!currentLoanOutput &&
        loan.interestCalculation === loan_1.InterestCalculationType.DrawnBalanceCashflow) {
        const firstBalanceIndex = currentLoanOutput.balances.findIndex((balance) => !!balance);
        const lastBalanceIndex = currentLoanOutput.balances.length -
            currentLoanOutput.balances
                .slice()
                .reverse()
                .findIndex((balance) => !!balance);
        return firstBalanceIndex === -1 ? 0 : lastBalanceIndex - firstBalanceIndex;
    }
    else {
        return loan.term;
    }
};
exports.calculateLoanTerm = calculateLoanTerm;
const getMonthlyRate = (loan) => loan.rate / (loan.rateIsMonthly ? 1 : 12);
exports.getMonthlyRate = getMonthlyRate;
const interestCalculationTypeDisplay = (calculationType) => {
    if (calculationType === loan_1.InterestCalculationType.DrawnBalance) {
        return "Drawn balance (S-curve)";
    }
    if (calculationType === loan_1.InterestCalculationType.DrawnBalanceCashflow) {
        return "Drawn balance (cashflow)";
    }
    if (calculationType === loan_1.InterestCalculationType.FullLoan) {
        return "Full loan";
    }
    throw new Error("InterestCalculationType does not exist");
};
exports.interestCalculationTypeDisplay = interestCalculationTypeDisplay;
