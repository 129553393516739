import {
  Button,
  ButtonSizes,
  Confirm,
  CostInput,
  Draggable,
  Icon,
  Tooltip
} from "@/react/components";
import { ClientCost } from "@shared/types/computable";
import { Nullable } from "@shared/types/utils";
import React, { useState } from "react";
import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { CostType } from "@shared/types/costs";
import { useTranslation } from "@/react/lib/hooks";
import { AppraisalCostsTranslations } from "@/react/lib/i18n/locales";
import { Calculations } from "@/react/components/Forms/NumberInput/types";
import { rootStore } from "@/react/lib/persistence/root_store";
import clsx from "clsx";
import { reorderDraggable } from "@/react/utils/dragging";
import { logEvent } from "@/react/utils";

interface CostProps {
  calculationOptions: Calculations[];
}

interface CostListProps extends CostProps {
  buildPhaseId?: Nullable<string>;
  costs: ClientCost[];
  costType: CostType;
}

interface CostListElementProps extends CostProps {
  cost: ClientCost;
  onDelete: () => void;
}

const CostListElement = ({ cost, calculationOptions, onDelete }: CostListElementProps) => {
  const { t } = useTranslation();
  const { attributes, listeners, transform, setNodeRef, isDragging } = useSortable({
    id: cost._id
  });

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`
      }
    : undefined;

  const handleDelete = () => {
    rootStore.costStore.setCostToDeleteId(cost._id);
    onDelete();
  };

  return (
    <>
      <div
        ref={setNodeRef}
        style={style}
        className={clsx("atlas-flex atlas-mb-2 atlas-items-end atlas-justify-between", {
          "atlas-cursor-grabbing": isDragging
        })}
        data-testid={"cost-list-element-" + cost._id}
      >
        <div className="atlas-h-10 atlas-flex atlas-items-center atlas-justify-center atlas-mb-2">
          <Draggable.Handle {...attributes} {...listeners} isDragging={isDragging} />
        </div>
        <CostInput
          className="atlas-flex-grow atlas-mr-2"
          inputClassName={clsx({ "atlas-cursor-grabbing": isDragging })}
          calculationsSelectClassName={clsx({ "atlas-cursor-grabbing": isDragging })}
          id={cost._id}
          cost={cost}
          calculations={calculationOptions}
        />
        <div className="atlas-h-10 atlas-flex atlas-items-center atlas-justify-center atlas-m-2">
          <Tooltip
            content={t(AppraisalCostsTranslations.AppraisalCosts_DeleteCost)}
            side="top"
            className="atlas-appearance-none atlas-border-none atlas-bg-transparent atlas-rounded-full atlas-p-0 atlas-line-clamp-none atlas-leading-none"
            onClick={handleDelete}
            aria-label={t(AppraisalCostsTranslations.AppraisalCosts_DeleteCost)}
          >
            <span data-testid={`delete-cost-${cost._id}`}>
              <Icon.IndeterminateCircleLine className="atlas-h-6 atlas-w-6 atlas-text-neutral-800" />
            </span>
          </Tooltip>
        </div>
      </div>
    </>
  );
};

export const CostList = ({ costs, buildPhaseId, calculationOptions, costType }: CostListProps) => {
  const { t } = useTranslation();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  return (
    <>
      <div className="atlas-flex atlas-flex-col" data-testid="cost-list">
        {!!costs.length && (
          <Draggable
            onDragEnd={reorderDraggable(costs, (reorderedCosts: ClientCost[]) =>
              rootStore.costStore.reOrderCosts(reorderedCosts)
            )}
          >
            <SortableContext items={costs.map((cost) => cost._id)}>
              {costs.map((cost) => (
                <CostListElement
                  cost={cost}
                  calculationOptions={calculationOptions}
                  key={cost._id}
                  onDelete={() => setShowConfirmDelete(true)}
                />
              ))}
            </SortableContext>
          </Draggable>
        )}
        {!costs.length && (
          <div>
            <p>{t(AppraisalCostsTranslations.AppraisalCosts_NoCosts)}</p>
          </div>
        )}
        <Button
          onClick={() =>
            rootStore.currentCostStore.startAddingCost(calculationOptions, costType, buildPhaseId)
          }
          className="atlas-mt-3 atlas-mx-2"
          size={ButtonSizes.LG}
          data-testid="add-cost-button"
        >
          {t(AppraisalCostsTranslations.AppraisalCosts_AddCost)}
        </Button>
      </div>
      <Confirm
        onConfirm={async () => {
          logEvent("Cost Deleted", {
            deleted_cost_id: rootStore.costStore.costToDeleteId ?? undefined
          });
          rootStore.costStore.deleteCost();

          setShowConfirmDelete(false);
        }}
        onCancel={() => {
          rootStore.costStore.clearCostToDeleteId();
          setShowConfirmDelete(false);
        }}
        modalOpen={showConfirmDelete}
        title={t(AppraisalCostsTranslations.AppraisalCosts_PleaseConfirm)}
        description={t(AppraisalCostsTranslations.AppraisalCosts_DeleteCostConfirm)}
        confirmButtonText={t(AppraisalCostsTranslations.AppraisalCosts_Delete)}
      />
    </>
  );
};
