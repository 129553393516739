import { debounce } from "lodash";
import React, { Dispatch, useCallback, useEffect, useState } from "react";
import { Input, InputProps } from "../Input";
import { formatNumberToString, formatStringToNumber } from "@/react/utils/formatting";

interface DebounceOptions {
  component: React.ComponentType<InputProps>;
  className: string;
  formatter: (val: number, formatting?: boolean) => string;
  parser: (val: string) => number;
  delay?: number;
}

export const withDebounce = ({
  component: InputComponent,
  parser,
  formatter,
  className,
  delay
}: DebounceOptions) => {
  return (
    props: Omit<InputProps, "onChange" | "value"> & { onChange: Dispatch<number>; value: number }
  ) => {
    const delayTime = delay ?? 500;
    const value = props.value;
    const onChange = props.onChange;
    const [inputContent, setInputContent] = useState(value);
    const [isTrailingDecimal, setIsTrailingDecimal] = useState(false);
    const [hasStartedTyping, setHasStartedTyping] = useState(false);

    const emit = useCallback(
      debounce((val: number) => {
        onChange(val);
      }, delayTime),
      [onChange]
    );

    useEffect(() => {
      if (formatter(value) !== formatter(inputContent)) {
        setInputContent(value);
        setHasStartedTyping(false);
      }
    }, [value]);

    const handleInputChange = (newValue: string) => {
      setHasStartedTyping(true);
      setIsTrailingDecimal(newValue.endsWith("."));
      setInputContent(parser(newValue));
      emit(parser(newValue));
    };

    return (
      <InputComponent
        className={className}
        {...{
          ...props,
          onChange: handleInputChange,
          value: formatter(inputContent, !hasStartedTyping) + (isTrailingDecimal ? "." : ""),
          trackCursor: true
        }}
      />
    );
  };
};

export const DebouncedInput = withDebounce({
  component: Input,
  className: "",
  formatter: formatNumberToString,
  parser: formatStringToNumber
});
