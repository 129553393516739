import { Component, Vue } from "vue-property-decorator";
import { EventData, logEvent } from "@/react/utils";

@Component
class EventLoggerMixin extends Vue {
  public logEvent(name: string, properties?: EventData) {
    logEvent(name, properties);
  }
}

export default EventLoggerMixin;
