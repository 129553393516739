import {
  createDevelopment as createDevelopmentApollo,
  deleteDevelopment as deleteDevelopmentApollo,
  updateDevelopment as updateDevelopmentApollo,
  getDevelopments,
  getMiniSites
} from "@/react/lib/persistence/apollo";
import { ClientMiniSite } from "@shared/types/site";
import _ from "lodash";
import { ClientDevelopment, ClientMiniDevelopment } from "@shared/types/development";
import { Nullable } from "@shared/types/utils";
import ObjectID from "bson-objectid";
import { makeAutoObservable } from "mobx";
import {
  hasSiteTitleThatMatchesSearchTerm,
  hasTitleThatMatchesSearchTerm
} from "./DevelopmentsStoreUtil";

export class DevelopmentsStore {
  developments: ClientMiniDevelopment[] = [];
  searchTerm: string = "";
  sites: ClientMiniSite[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  async setupDevelopments() {
    if (!this.developments.length) {
      const developments = await getDevelopments();
      this.setDevelopments(developments);
    }
  }

  setDevelopments(value: ClientMiniDevelopment[]) {
    this.developments = value;
  }

  async setupMiniSites() {
    const batches: Array<Array<ClientMiniDevelopment["_id"]>> = _.chunk(
      this.miniSiteIdsAttachedToDevelopments,
      50
    );
    for (const batch of batches) {
      if (batch) {
        let miniSites = await getMiniSites(batch);
        miniSites = miniSites.filter((site: ClientMiniDevelopment) => site);
        this.addSites(miniSites);
      }
    }
  }

  addSites(value: ClientMiniSite[]) {
    this.sites = [...this.sites, ...value];
  }

  setSearchTerm(value: string) {
    this.searchTerm = value;
  }

  async updateMiniSites() {
    // Remove any miniSites that are no longer attached to a development
    this.sites = this.sites.filter((site) =>
      this.miniSiteIdsAttachedToDevelopments.includes(site._id)
    );
    // Get mini site ids of sites we don't currently have in the store, but are attached to a development
    const missingSiteIds = this.miniSiteIdsAttachedToDevelopments.filter(
      (siteId) => !this.miniSiteIdsInTheStore.includes(siteId)
    );
    // Retrieve the missing sites from apollo
    let miniSites: ClientMiniSite[] = [];
    if (missingSiteIds.length) {
      miniSites = await getMiniSites(missingSiteIds);
    }
    miniSites = miniSites.filter((site: ClientMiniSite) => site);
    this.addSites(miniSites);
  }

  async resync() {
    await this.setupDevelopments();
    await this.updateMiniSites();
  }

  async getOrCreateDevelopmentForSite(
    siteId: ClientMiniSite["_id"]
  ): Promise<Nullable<{ development: ClientMiniDevelopment; created: boolean }>> {
    const developmentBySite = this.developments.find((dev) => dev._site === siteId);
    if (developmentBySite) {
      return { development: developmentBySite, created: false };
    }
    return await this.createDevelopmentFromSiteId(siteId);
  }

  getDevelopmentById(developmentId: string) {
    const developmentById = this.developments.find((dev) => dev._id === developmentId);
    return developmentById ? { ...developmentById } : null;
  }

  isDevelopmentLinkedToSite(developmentId: string) {
    return !!this.developments.find((development) => development._id === developmentId)?._site;
  }

  async createDevelopmentFromSiteId(
    siteId: ClientMiniSite["_id"]
  ): Promise<Nullable<{ development: ClientMiniDevelopment; created: boolean }>> {
    const [newMiniSite] = await getMiniSites([siteId]);
    if (!newMiniSite) {
      return null;
    }
    this.sites = [...this.sites, newMiniSite];
    const newDevelopment = await this.createDevelopment(true, {
      _site: siteId,
      title: newMiniSite.title
    });
    return newDevelopment ? { development: newDevelopment, created: true } : null;
  }

  get miniSiteIdsAttachedToDevelopments(): Array<ClientMiniSite["_id"]> {
    const siteIds = _.uniq(this.developments.map((dev) => dev._site).filter((siteId) => siteId));
    return siteIds as Array<ClientMiniSite["_id"]>;
  }

  get miniSiteIdsInTheStore(): Array<ClientMiniSite["_id"]> {
    return _.uniq(this.sites.map((site) => site._id).filter((siteId) => siteId));
  }

  async createDevelopment(
    auto: boolean,
    partialDevelopment?: Partial<Omit<ClientMiniDevelopment, "_id">>
  ): Promise<ClientMiniDevelopment> {
    const newDevelopment: ClientMiniDevelopment = {
      _id: new ObjectID().toHexString(),
      title: "",
      description: "",
      notes: "",
      _site: null,
      ...partialDevelopment
    };
    this.developments.push(newDevelopment);
    await createDevelopmentApollo(newDevelopment);

    return newDevelopment;
  }

  async deleteDevelopment(developmentId: ClientMiniDevelopment["_id"]) {
    this.developments = this.developments.filter((c) => c._id !== developmentId);
    await deleteDevelopmentApollo(developmentId);
  }

  async toggleArchiveDevelopment(developmentId: ClientMiniDevelopment["_id"]) {
    let developmentToArchive = this.developments.find(
      (development) => development._id === developmentId
    );
    if (developmentToArchive) {
      developmentToArchive.archived = !developmentToArchive.archived;
      await updateDevelopmentApollo(
        { archived: developmentToArchive.archived },
        developmentToArchive._id
      );
    }
  }

  get developmentTabs(): Array<{ title: string; developments: ClientMiniDevelopment[] }> {
    return [
      {
        title: "Active",
        developments: this.activeDevelopments
      },
      {
        title: "Archived",
        developments: this.archivedDevelopments
      }
    ];
  }

  get filteredBySearchDevelopments() {
    return this.searchTerm
      ? this.developments.filter(
          (development) =>
            hasTitleThatMatchesSearchTerm(development.title ?? "", this.searchTerm) ||
            hasSiteTitleThatMatchesSearchTerm(development, this.sites, this.searchTerm)
        )
      : this.developments;
  }

  get activeDevelopments(): ClientMiniDevelopment[] {
    return this.filteredBySearchDevelopments.filter((development) => !development.archived);
  }

  get archivedDevelopments(): ClientMiniDevelopment[] {
    return this.filteredBySearchDevelopments.filter((development) => development.archived);
  }

  updateDevelopmentLocally(developmentId: string, developmentUpdate: Partial<ClientDevelopment>) {
    const developmentToUpdateIndex = this.developments.findIndex(
      (development) => development._id === developmentId
    );
    const updatedDevelopment = {
      ...this.developments[developmentToUpdateIndex],
      ...developmentUpdate
    };
    this.developments.splice(developmentToUpdateIndex, 1, updatedDevelopment);
  }
}
