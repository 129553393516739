import React, { PropsWithChildren } from "react";
import { TableSpacer } from "./TableSpacer";
import { rootStore } from "@/react/lib/persistence/root_store";
import clsx from "clsx";
import { CollapseButton } from "./CollapseButton";
import { observer } from "mobx-react-lite";

import "./CashflowTableSection.css";
import { useTranslation } from "@/react/lib/hooks";
import { CashflowTableTranslations } from "@/react/lib/i18n/locales";

interface TableSectionProps {
  title?: string;
  financeSection?: boolean;
  testId?: string;
}

export const TableSection = observer(
  ({ title, children, financeSection, testId }: PropsWithChildren<TableSectionProps>) => {
    const { t } = useTranslation();
    const headerStylings =
      "atlas-border-neutral-500 atlas-border-solid atlas-border-0 atlas-border-t atlas-bg-blue-50 atlas-font-bold";
    return (
      <>
        <TableSpacer />
        {title && (
          <thead>
            <tr className="atlas-sticky atlas-top-0 atlas-z-max heading">
              <th
                data-testid="title-header"
                className={clsx("fixed-column atlas-border-l", headerStylings)}
              >
                <div className="atlas-w-64 atlas-text-left atlas-p-2">{title}</div>
              </th>
              <th
                data-testid="value-header"
                className={clsx("fixed-column", headerStylings, {
                  "atlas-border-r": rootStore.utilityStore.spreadToolsHidden
                })}
              >
                <div className="atlas-w-36 atlas-text-right atlas-p-2 atlas-pr-5 atlas-relative">
                  {!financeSection && t(CashflowTableTranslations.CashflowTable_Value)}
                </div>
                {rootStore.utilityStore.spreadToolsHidden && (
                  <CollapseButton testId="value-collapse-button" />
                )}
              </th>
              <th
                data-testid="time-frame-header"
                className={clsx("fixed-column", headerStylings, {
                  " atlas-hidden": rootStore.utilityStore.spreadToolsHidden
                })}
              >
                <div className="atlas-w-48 atlas-text-center atlas-p-2">
                  {!financeSection && t(CashflowTableTranslations.CashflowTable_TimeFrame)}
                </div>
              </th>
              <th
                data-testid="curve-header"
                className={clsx("fixed-column atlas-border-r atlas-relative", headerStylings, {
                  " atlas-hidden": rootStore.utilityStore.spreadToolsHidden
                })}
              >
                <div className="atlas-w-24 atlas-text-center atlas-p-2">
                  {!financeSection && t(CashflowTableTranslations.CashflowTable_Curve)}
                </div>
                {!rootStore.utilityStore.spreadToolsHidden && (
                  <CollapseButton testId="curve-collapse-button" />
                )}
              </th>
              {[...Array(rootStore.cashflowStore.numMonths).keys()].map((val) => (
                <th
                  data-testid="month-header"
                  className={clsx(headerStylings, {
                    "atlas-border-r": val === rootStore.cashflowStore.numMonths - 1
                  })}
                  key={`${title}-${val}`}
                >
                  <div className="atlas-w-36 atlas-text-right atlas-p-2">
                    {t(CashflowTableTranslations.CashflowTable_Month)} {val + 1}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody data-testid={testId}>{children}</tbody>
      </>
    );
  }
);
