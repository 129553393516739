import { ClientBuildPhase } from "@shared/types/buildPhase";
import { UnitSetting } from "@shared/types/user";
import { areaUtil } from "@shared/utils/area";

export const convertBuildPhaseFromMetric = (
  buildPhase: ClientBuildPhase,
  unitSetting: UnitSetting
) => ({
  ...buildPhase,
  assignedNIA: Math.round(areaUtil.convertSmallArea(buildPhase.assignedNIA, unitSetting)),
  assignedGIA: {
    ...buildPhase.assignedGIA,
    value: Math.round(areaUtil.convertSmallArea(buildPhase.assignedGIA.value, unitSetting))
  }
});

export const convertBuildPhaseUpdateToMetric = (
  buildPhaseUpdate: Partial<ClientBuildPhase>,
  unitSetting: UnitSetting
) => {
  if ("assignedNIA" in buildPhaseUpdate && typeof buildPhaseUpdate.assignedNIA === "number") {
    buildPhaseUpdate.assignedNIA = areaUtil.convertSmallAreaBack(
      buildPhaseUpdate.assignedNIA,
      unitSetting
    );
  }

  if ("assignedGIA" in buildPhaseUpdate && buildPhaseUpdate.assignedGIA) {
    buildPhaseUpdate.assignedGIA = {
      ...buildPhaseUpdate.assignedGIA,
      value: areaUtil.convertSmallAreaBack(buildPhaseUpdate.assignedGIA.value, unitSetting)
    };
  }

  return buildPhaseUpdate;
};
