import { ClientBaseCost, ClientCost, ComputableCalculationType } from "@shared/types/computable";
import { CostType } from "@shared/types/costs";
import ObjectID from "bson-objectid";

export const createNewCost = (args?: Partial<ClientBaseCost>): ClientBaseCost => ({
  __typename: "BaseCost",
  position: 0,
  value: 0,
  calculate: true,
  calculationType: ComputableCalculationType.PERCENTAGE_OF_COSTS,
  calculationBase: 0,
  ...args
});

export const createNewClientCost = (args?: Partial<ClientCost>): ClientCost => ({
  ...createNewCost(),
  __typename: undefined,
  description: "",
  _build_phase: null,
  type: CostType.OtherCost,
  _id: new ObjectID().toHexString(),
  ...args
});

export const costDependsOnLand = (cost: ClientCost): boolean => {
  return cost.calculate && cost.calculationType === "percentage-of-land";
};

export const costDependsOnStampDuty = (cost: ClientCost): boolean => {
  return cost.calculate && cost.calculationType === "stamp-duty-bands";
};
