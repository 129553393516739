import { Accordion } from "@/react/components/Accordion";
import { ClientUnitGroup } from "@shared/types/unitGroup";
import React, { FC, useState } from "react";
import { formatAmount, formatPercentage, pluralise } from "@shared/utils/formatting";
import { UnitGroupTypesInstance } from "@/utils/unit_groups";
import { rootStore } from "@/react/lib/persistence/root_store";
import { IncomeUnitsTable } from "./IncomeUnitsTable";
import { logEvent } from "@/react/utils/logging";
import { Confirm, Draggable } from "@/react/components";
import { useSortable } from "@dnd-kit/sortable";
import clsx from "clsx";
import { totalUnitGroupValue, totalUnitGroupYield } from "@shared/utils/unit_groups";
import { useTranslation } from "@/react/lib/hooks";
import { IncomeTranslations } from "@/react/lib/i18n/locales";
import OptionsMenu from "@/react/components/DropdownMenu/OptionsMenu";

interface IncomeAccordionItemProps {
  unitGroup: ClientUnitGroup;
}

const IncomeAccordionItem: FC<IncomeAccordionItemProps> = ({ unitGroup }) => {
  const { t } = useTranslation();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  function unitType() {
    return (
      UnitGroupTypesInstance[unitGroup.unitType].alternativeDisplayName ??
      UnitGroupTypesInstance[unitGroup.unitType].displayName
    ).toLowerCase();
  }

  const optionsMenu = [
    {
      label: t(IncomeTranslations.Add),
      actionHandler: () => rootStore.currentUnitGroupStore.startAddingUnits(unitGroup._id)
    },
    {
      label: t(IncomeTranslations.Edit),
      actionHandler: () => rootStore.currentUnitGroupStore.startEditingUnitGroup(unitGroup._id)
    },
    {
      label: t(IncomeTranslations.Delete),
      actionHandler: () => {
        setShowConfirmDelete(true);
      }
    }
  ];

  const { attributes, listeners, transform, setNodeRef, isDragging } = useSortable({
    id: unitGroup._id
  });

  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : ""
  };

  return (
    <>
      <div
        ref={setNodeRef}
        data-testid="income-accordion-item"
        style={style}
        className={clsx(
          "atlas-p-3 atlas-border atlas-border-solid atlas-border-neutral-400 atlas-bg-white atlas-relative",
          "atlas--my-px",
          {
            "atlas-cursor-grabbing atlas-z-max": isDragging
          }
        )}
      >
        <Accordion.Item value={unitGroup._id}>
          <Accordion.Header
            handle={
              <div
                className="atlas-h-10 atlas-flex atlas-items-center atlas-justify-center"
                data-testid="income-accordion-item-drag-handle"
              >
                <Draggable.Handle {...attributes} {...listeners} isDragging={isDragging} />
              </div>
            }
            action={
              <OptionsMenu
                options={optionsMenu}
                _id={unitGroup._id}
                tooltip={t(IncomeTranslations.OptionsMenuTooltip)}
              />
            }
          >
            <span className="atlas-flex atlas-justify-between atlas-font-bold atlas-text-neutral-800">
              <span className="atlas-flex atlas-flex-col atlas-items-start">
                <span data-testid="income-accordion-item-description">{unitGroup.description}</span>
                <span
                  className="atlas-text-neutral-700 atlas-text-xs atlas-text-left"
                  data-testid="income-accordion-item-unit-amount"
                >
                  {pluralise(
                    unitType(),
                    rootStore.unitGroupStore.unitsCountByUnitGroupId.get(unitGroup._id) ?? 0
                  )}
                </span>
              </span>
              <span className="atlas-flex atlas-items-center atlas-text-right">
                {unitGroup.unitType === "Commercial Unit" && (
                  <span className="atlas-mr-2" data-testid="income-accordion-item-yield">
                    {t(IncomeTranslations.Yield)}:{" "}
                    {formatPercentage(totalUnitGroupYield(unitGroup))}
                  </span>
                )}
                <span className="atlas-mr-2" data-testid="income-accordion-item-sales-value">
                  {t(IncomeTranslations.GrossSalesValue)}:{" "}
                  {formatAmount(totalUnitGroupValue(unitGroup))}
                </span>
              </span>
            </span>
          </Accordion.Header>
          <Accordion.Body>
            <div className="atlas-mt-3">
              {!!rootStore.unitGroupStore.unitsCountByUnitGroupId.get(unitGroup._id) && (
                <IncomeUnitsTable unitGroupId={unitGroup._id} />
              )}
              {!rootStore.unitGroupStore.unitsCountByUnitGroupId.get(unitGroup._id) && (
                <p className="atlas-text-center" data-testid="income-accordion-item-no-units">
                  {t(IncomeTranslations.NoUnits)}
                </p>
              )}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </div>
      <Confirm
        onConfirm={() => {
          rootStore.unitGroupStore.deleteUnitGroup(unitGroup._id);
          logEvent("Unit Group Deleted", { is_template: rootStore.appraisalStore.isTemplate });
          setShowConfirmDelete(false);
        }}
        onCancel={() => {
          setShowConfirmDelete(false);
        }}
        modalOpen={showConfirmDelete}
        title={t(IncomeTranslations.ConfirmDeleteTitle)}
        description={t(IncomeTranslations.ConfirmDeleteContent)}
        confirmButtonText={t(IncomeTranslations.ConfirmDeleteConfirm)}
      />
    </>
  );
};

export { IncomeAccordionItem };
