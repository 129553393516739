<template>
  <div>
    <b-card class="development-card" data-intercom-target="development_card">
      <div class="development-card-content">
        <div class="development-header flex-row">
          <h2 v-if="development.title">{{ development.title }}</h2>
          <h2 v-else class="font-italic text-muted">Untitled Development</h2>
          <div class="pull-right d-flex align-items-center">
            <OptionsMenu
              v-if="active"
              :objectId="development._id"
              objectType="Development"
              @delete="
                () => {
                  developmentsStore.deleteDevelopment(development._id);
                  logEvent('Development Deleted', { development_id: development._id });
                }
              "
              @archive="
                () => {
                  developmentsStore.toggleArchiveDevelopment(development._id);
                  logEvent('Development Archived', {
                    development_id: development._id
                  });
                }
              "
            />

            <OptionsMenu
              v-else
              :objectId="development._id"
              objectType="Development"
              @delete="
                () => {
                  developmentsStore.deleteDevelopment(development._id);
                  logEvent('Development Deleted', { development_id: development._id });
                }
              "
              @restore="
                () => {
                  developmentsStore.toggleArchiveDevelopment(development._id);
                  logEvent('Development Restored', {
                    development_id: development._id
                  });
                }
              "
            />
          </div>
        </div>
        <div class="development-body">
          <p>{{ development.description }}</p>
        </div>
        <div class="development-footer flex-row">
          <span class="footer-text address-tag" v-if="miniSite">
            <span v-if="miniSite.address">
              <b-icon icon="geo-alt" />
              {{ miniSite.address }}
            </span>
          </span>
          <div class="footer-text pull-right">
            <span>{{ pluralise("Appraisal", development.appraisalCount || 0) }}</span>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script lang="ts">
import OptionsMenu from "@/components/OptionsMenu.vue";
import EventLoggerMixin from "@/mixins/event_logger_mixin";
import { ClientMiniDevelopment } from "@shared/types/development";
import { ClientMiniSite } from "@shared/types/site";
import { Nullable } from "@shared/types/utils";
import { pluralise } from "@shared/utils/formatting";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { rootStore } from "@/react/lib/persistence/root_store";

@Component({
  components: {
    OptionsMenu
  }
})
export default class DevelopmentCard extends Mixins(EventLoggerMixin) {
  @Prop({ required: true }) readonly development!: ClientMiniDevelopment;
  @Prop({ required: true }) readonly miniSite!: Nullable<ClientMiniSite>;
  @Prop({ required: true }) readonly active!: boolean;

  public pluralise = pluralise;
  public developmentsStore = rootStore.developmentsStore;
}
</script>

<style lang="scss">
.development-header {
  .b-menu {
    margin: 0;
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/scss/landtech-colors";

.development-card {
  width: 100%;
  height: 164px;
  border-left: 1px solid $neutral-white;
  display: flex;
  margin-bottom: 1.5rem;
  box-shadow: 0px 0px 10px $landfund-box-shadow-colour;

  &:hover {
    border: 1px solid $ui-interaction-hover;
    cursor: pointer;
  }

  .card-body {
    display: flex;
  }

  .development-header {
    margin-bottom: 14px;
    h2 {
      margin: 0;
      margin-top: 5px;
      font-size: 20px;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:hover {
        color: $ui-interaction-hover;
      }
    }

    .btn {
      margin: 0;
    }
  }

  .development-body {
    flex: 1;
    p {
      margin-bottom: 0;
      font-size: 12px;
      color: $neutral-800;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .development-card-content {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .development-footer {
    height: 25px;
  }
}

.footer-text {
  font-size: 12px;
  color: $neutral-900;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.address-tag {
  width: 65%;
}
</style>
