import "./CashflowTable.css";
import React from "react";
import {
  FinanceSection,
  IncomeSection,
  LandAcquisitionSection,
  BuildSection,
  ProfessionalFeesSection,
  TotalsSection,
  OtherCostsSection
} from "../CashflowSection";
import clsx from "clsx";

export const CashflowTable = () => {
  return (
    <div className="atlas-relative">
      <table
        id="cashflow-table"
        data-testid="cashflow-table"
        className={clsx(
          "cashflow-table",
          "atlas-w-full atlas-overflow-scroll atlas-block",
          "atlas-flex-nowrap atlas-border-separate"
        )}
      >
        <IncomeSection />
        <LandAcquisitionSection />
        <BuildSection />
        <ProfessionalFeesSection />
        <OtherCostsSection />
        <TotalsSection />
        <FinanceSection />
      </table>
    </div>
  );
};
