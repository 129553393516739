import { ClientLoan, InterestCalculationType, InterestChargingType } from "@shared/types/loan";
import ObjectID from "bson-objectid";
import { createNewCost } from "./cost";
import { ComputableCalculationType } from "@shared/types/computable";

export const createNewLoan = (): ClientLoan => ({
  _id: new ObjectID().toHexString(),
  __typename: "Loan",
  position: 0,
  name: "",
  amount: {
    __typename: "BaseComputable",
    value: 0,
    calculate: false,
    calculationType: ComputableCalculationType.PERCENTAGE_OF_COSTS,
    calculationBase: 0
  },
  term: 0,
  rate: 0,
  rateIsMonthly: false,
  arrangementFee: createNewCost({
    calculationType: ComputableCalculationType.PERCENTAGE_OF_LOAN,
    calculationBase: 1
  }),
  exitFee: createNewCost({
    calculationType: ComputableCalculationType.PERCENTAGE_OF_LOAN,
    calculationBase: 1
  }),
  interestCalculation: InterestCalculationType.DrawnBalanceCashflow,
  interestCharging: InterestChargingType.RolledUp
});

export const getTotalNetBorrowingFromLoans = (loans: ClientLoan[]) =>
  loans.reduce((sum: number, loan: ClientLoan) => sum + loan.amount.value || 0, 0);
