import {
  discountedResiBands,
  nonResiFreeholdBands,
  nonResiLeaseholdBands,
  resiBands
} from "@/data/stamp_duty_bands";
import { updateAppraisal } from "@/react/lib/persistence/apollo/mutations";
import { calculateStampDuty } from "@/utils/stamp_duty";
import { ClientAppraisal } from "@shared/types/appraisal";
import { BuildQuality } from "@shared/types/buildCost";
import { ClientCost } from "@shared/types/computable";
import { StampDutyBand } from "@shared/types/stampDutyBand";
import { debounce, isEqual, toUpper } from "lodash";
import { makeAutoObservable } from "mobx";
import { RootStore } from "../Root";

export class AppraisalStore {
  readonly root: RootStore;

  isSetup: boolean = false;
  appraisalId: string = "";
  isTemplate: boolean = false;
  contingency: number = 0;
  purchasePrice: number = 0;
  isResidential: boolean = false;
  isIndividual: boolean = false;
  isAdditional: boolean = false;
  isFreehold: boolean = false;
  developmentId: string = "";
  title: string = "";
  description: string = "";
  quality: BuildQuality = toUpper(BuildQuality.MEDIUM) as BuildQuality;

  debounceAppraisalUpdate = debounce(() => this.updateAppraisal(), 600);
  appraisalUpdate: Partial<ClientAppraisal> = {};

  constructor(rootStore: RootStore) {
    this.root = rootStore;

    makeAutoObservable(this, { root: false });
  }

  completeInitialSetup() {
    this.isSetup = true;
  }

  restartInitialSetup() {
    this.isSetup = false;
  }

  setupAppraisalValues(appraisal: ClientAppraisal) {
    if (appraisal._id !== this.appraisalId) {
      this.appraisalId = appraisal._id;
      this.isTemplate = appraisal.isTemplate ?? false;
      this.title = appraisal.title ?? "";
      this.description = appraisal.description ?? "";
      this.contingency = appraisal.contingency ?? 0;
      this.purchasePrice = appraisal.purchasePrice ?? 0;
      this.isAdditional = appraisal.isAdditional ?? false;
      this.isFreehold = appraisal.isFreehold ?? false;
      this.isIndividual = appraisal.isIndividual ?? false;
      this.isResidential = appraisal.isResidential ?? false;
      this.developmentId = appraisal._development ?? "";
      if (appraisal.quality) {
        this.quality = appraisal.quality;
      }
    }
  }

  resetAppraisalId() {
    this.appraisalId = "";
  }

  setTitle(value?: string) {
    this.title = value ?? "";
    this.appraisalUpdate = { ...this.appraisalUpdate, title: value };
    this.debounceAppraisalUpdate();
  }

  setDescription(value?: string) {
    this.description = value ?? "";
    this.appraisalUpdate = { ...this.appraisalUpdate, description: value };
    this.debounceAppraisalUpdate();
  }

  setQuality(value: BuildQuality) {
    this.quality = value;
    this.appraisalUpdate = { ...this.appraisalUpdate, quality: value };
    this.debounceAppraisalUpdate();
  }

  setContingency(value?: string) {
    this.contingency = Number(value);
    this.appraisalUpdate = { ...this.appraisalUpdate, contingency: this.contingency };
    this.debounceAppraisalUpdate();
  }

  setPurchasePrice(value?: number) {
    this.purchasePrice = value ?? 0;
    this.updateStampDutyFromPurchasePrice();
    this.appraisalUpdate = { ...this.appraisalUpdate, purchasePrice: this.purchasePrice };
    this.debounceAppraisalUpdate();
  }

  setIsResidential(value?: boolean) {
    this.isResidential = value ?? false;
    this.updateStampDutyFromCalculation();
    this.appraisalUpdate = { ...this.appraisalUpdate, isResidential: this.isResidential };
    this.debounceAppraisalUpdate();
  }

  setIsIndividual(value?: boolean) {
    this.isIndividual = value ?? false;
    this.updateStampDutyFromCalculation();
    this.appraisalUpdate = { ...this.appraisalUpdate, isIndividual: this.isIndividual };
    this.debounceAppraisalUpdate();
  }

  setIsAdditional(value?: boolean) {
    this.isAdditional = value ?? false;
    this.updateStampDutyFromCalculation();
    this.appraisalUpdate = { ...this.appraisalUpdate, isAdditional: this.isAdditional };
    this.debounceAppraisalUpdate();
  }

  setIsFreehold(value?: boolean) {
    this.isFreehold = value ?? false;
    this.updateStampDutyFromCalculation();
    this.appraisalUpdate = { ...this.appraisalUpdate, isFreehold: this.isFreehold };
    this.debounceAppraisalUpdate();
  }

  setDevelopmentId(value?: string) {
    this.developmentId = value ?? "";
    this.appraisalUpdate = { ...this.appraisalUpdate, _development: this.developmentId };
    this.debounceAppraisalUpdate();
  }

  public updateStampDutyFromCalculation() {
    if (!this.root.costStore.stampDuty) {
      return;
    }
    const newStampDuty: ClientCost = {
      ...this.root.costStore.stampDuty,
      calculate: true,
      value: this.computedStampDuty
    };
    if (!isEqual(newStampDuty, this.root.costStore.stampDuty)) {
      this.root.costStore.updateCost(newStampDuty);
    }
  }

  public updateStampDutyFromPurchasePrice() {
    if (this.root.costStore.stampDuty?.calculate) {
      this.updateStampDutyFromCalculation();
    }
  }

  get stampDutyBands(): StampDutyBand[] {
    if (this.isResidential && this.isIndividual) {
      return this.isAdditional ? resiBands : discountedResiBands;
    } else if (this.isResidential) {
      return resiBands;
    } else {
      return this.isFreehold ? nonResiFreeholdBands : nonResiLeaseholdBands;
    }
  }

  get netPurchasePrice(): number {
    return this.purchasePrice - (this.root.costStore.deposit?.value ?? 0);
  }

  async updateAppraisal() {
    const currentAppraisal = this.root.getCurrentAppraisal();
    // Need to move this dependency to the backend
    try {
      await updateAppraisal(this.appraisalId, this.appraisalUpdate);
      if (this.isTemplate && (this.appraisalUpdate.title || this.appraisalUpdate.description)) {
        this.root.templateStore.setupTemplateStore();
      }
      this.appraisalUpdate = {};
    } catch {
      this.setupAppraisalValues(currentAppraisal);
    }
  }

  get computedStampDuty() {
    return calculateStampDuty(this.purchasePrice ?? 0, this.stampDutyBands);
  }
}
