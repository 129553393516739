import { calculateEquityInterest } from "@/react/lib/persistence/root_store/EquityFunding/EquityFundingStoreUtil";
import {
  CashflowEquityOutput,
  CashflowFinanceOutputs,
  CashflowLoanOutput
} from "@shared/types/cashflow";
import { ClientEquityFunding } from "@shared/types/equity";
import { ClientLoan, InterestCalculationType, InterestChargingType } from "@shared/types/loan";
import {
  arrangementFee,
  calculateGrossLoan,
  exitFee,
  getMonthlyRate,
  loanFees
} from "@shared/utils/loan";
import { sum } from "lodash";

export const getFinanceOutputs = (
  monthlyNets: number[],
  loans: ClientLoan[],
  equityFundingSources: ClientEquityFunding[],
  maxEquity: number
): CashflowFinanceOutputs => {
  const loanOutputs: CashflowLoanOutput[] = loans.map((loan) => ({
    loan,
    balances: monthlyNets.map(() => 0),
    interestAccrued: monthlyNets.map(() => 0),
    interestPaid: monthlyNets.map(() => 0),
    totalInterest: 0,
    feesAccrued: monthlyNets.map(() => 0),
    feesPaid: monthlyNets.map(() => 0),
    accumulatedInterest: 0
  }));
  const equityOutputs = equityFundingSources.map((equity) => ({
    equity,
    balances: monthlyNets.map(() => 0),
    interestPaid: monthlyNets.map(() => 0),
    providerProfitShare: monthlyNets.map(() => 0)
  }));
  const developerEquity = monthlyNets.map(() => 0);

  if (!monthlyNets.length) {
    return { loanOutputs, equityOutputs, developerEquity };
  }

  for (const [monthIndex, monthlyNet] of monthlyNets.entries()) {
    const previousMonthEquity = monthIndex === 0 ? 0 : developerEquity[monthIndex - 1];
    let currentNet = monthlyNet;

    setInterestForMonth(loanOutputs, monthIndex);

    if (currentNet <= 0) {
      currentNet = drawFromEquityAndReturnNewNet(
        developerEquity,
        monthIndex,
        maxEquity,
        previousMonthEquity,
        currentNet
      );
      currentNet = drawFromEquityFundingAndReturnNewNet(equityOutputs, monthIndex, currentNet);

      for (const loanOutput of loanOutputs.slice().reverse()) {
        currentNet = drawFromLoanAndReturnNewNet(loanOutput, monthIndex, currentNet);
        rollUpInterestIfNeeded(loanOutput, monthIndex);
      }
    } else {
      for (const loanOutput of loanOutputs) {
        currentNet = payBackLoanAndReturnNewNet(loanOutput, monthIndex, currentNet);
      }

      currentNet = payBackEquityFundingAndReturnNewNet(equityOutputs, monthIndex, currentNet);

      developerEquity[monthIndex] = previousMonthEquity + currentNet;
    }
  }

  calculateTotalInterest(loanOutputs);
  payLoanFees(loanOutputs, developerEquity, maxEquity);
  let totalProfitShare = 0;
  for (const equityOutput of equityOutputs) {
    const profitSharedAmount =
      (equityOutput.equity.providerProfitShare / 100) * developerEquity[monthlyNets.length - 1];
    equityOutput.providerProfitShare.splice(-1, 1, profitSharedAmount);
    totalProfitShare += profitSharedAmount;
  }
  developerEquity[monthlyNets.length - 1] -= totalProfitShare;
  return { loanOutputs, equityOutputs, developerEquity };
};

const setInterestForMonth = (loanOutputs: CashflowLoanOutput[], monthIndex: number) => {
  for (const loanOutput of loanOutputs) {
    const previousMonthBalance = monthIndex === 0 ? 0 : loanOutput.balances[monthIndex - 1];
    if (previousMonthBalance === 0) {
      continue;
    }
    const monthlyRate = getMonthlyRate(loanOutput.loan);
    const fullAmountOrDrawnBalance =
      loanOutput.loan.interestCalculation === InterestCalculationType.FullLoan
        ? getFullLoanAmount(loanOutput)
        : getDrawnBalance(loanOutput);
    const interestForCurrentMonth = (fullAmountOrDrawnBalance * monthlyRate) / 100;
    loanOutput.interestAccrued[monthIndex] = interestForCurrentMonth;

    if (loanOutput.loan.interestCharging === InterestChargingType.Retained) {
      setRetainedInterestForMonth(loanOutput, monthIndex);
    } else if (loanOutput.loan.interestCharging === InterestChargingType.RolledUp) {
      loanOutput.accumulatedInterest += interestForCurrentMonth;
    } else if (loanOutput.loan.interestCharging === InterestChargingType.Serviced) {
      loanOutput.interestPaid[monthIndex] = interestForCurrentMonth;
    }
  }
};

const getFullLoanAmount = (loanOutput: CashflowLoanOutput) => {
  if (loanOutput.loan.interestCharging === InterestChargingType.RolledUp) {
    return loanOutput.loan.amount.value + loanOutput.accumulatedInterest;
  } else {
    return loanOutput.loan.amount.value;
  }
};

const calculateTotalInterest = (loanOutputs: CashflowLoanOutput[]) => {
  loanOutputs.forEach(
    (loanOutput) =>
      (loanOutput.totalInterest = loanOutput.interestAccrued.reduce((acc, val) => acc + val, 0))
  );
};

const getDrawnBalance = (loanOutput: CashflowLoanOutput) => {
  const lastDrawnBalance = loanOutput.balances
    .slice()
    .reverse()
    .find((x) => !!x);
  if (!lastDrawnBalance) {
    return 0;
  } else if (loanOutput.loan.interestCharging === InterestChargingType.Retained) {
    return lastDrawnBalance - loanOutput.accumulatedInterest;
  } else {
    return lastDrawnBalance;
  }
};

const setRetainedInterestForMonth = (loanOutput: CashflowLoanOutput, monthIndex: number) => {
  const monthlyRate = getMonthlyRate(loanOutput.loan);
  const firstDrawMonthIndex = loanOutput.balances.findIndex((x) => !!x);
  const nMonths = monthIndex - firstDrawMonthIndex;
  const retainedInterest = loanOutput.interestAccrued.reduce((acc, x) => acc + x, 0);
  const interestOnInterest = (retainedInterest * nMonths * monthlyRate) / 100;
  const accumulatedRetainedInterestDiff =
    retainedInterest + interestOnInterest - loanOutput.accumulatedInterest;
  loanOutput.accumulatedInterest += accumulatedRetainedInterestDiff;
  for (let i = firstDrawMonthIndex; i < monthIndex; i++) {
    loanOutput.balances[i] += accumulatedRetainedInterestDiff;
  }
};

const drawFromEquityAndReturnNewNet = (
  developerEquity: number[],
  monthIndex: number,
  maxEquity: number,
  previousMonthEquity: number,
  currentNet: number
) => {
  const nonUtilisedEquity = maxEquity + previousMonthEquity;
  if (-currentNet < nonUtilisedEquity) {
    developerEquity[monthIndex] = previousMonthEquity + currentNet;
    return 0;
  } else {
    developerEquity[monthIndex] = previousMonthEquity - nonUtilisedEquity;
    return currentNet + nonUtilisedEquity;
  }
};

const drawFromLoanAndReturnNewNet = (
  loanOutput: CashflowLoanOutput,
  monthIndex: number,
  currentNet: number
) => {
  const previousMonthBalance = monthIndex === 0 ? 0 : loanOutput.balances[monthIndex - 1];
  let nonUtilisedLoan = getFullLoanAmount(loanOutput) - previousMonthBalance;
  if (loanOutput.loan.interestCharging === InterestChargingType.Retained) {
    nonUtilisedLoan += loanOutput.accumulatedInterest;
  }
  const canDrawRestFromCurrentLoan = -currentNet <= nonUtilisedLoan;
  if (canDrawRestFromCurrentLoan) {
    loanOutput.balances[monthIndex] = previousMonthBalance - currentNet;
    return 0;
  } else {
    const maximisedLoan = loanOutput.loan.amount.value + loanOutput.accumulatedInterest;
    loanOutput.balances[monthIndex] = maximisedLoan;
    return currentNet + nonUtilisedLoan;
  }
};

const rollUpInterestIfNeeded = (loanOutput: CashflowLoanOutput, monthIndex: number) => {
  if (loanOutput.loan.interestCharging === InterestChargingType.RolledUp) {
    loanOutput.balances[monthIndex] += loanOutput.interestAccrued[monthIndex];
  }
};

const payBackLoanAndReturnNewNet = (
  loanOutput: CashflowLoanOutput,
  monthIndex: number,
  currentNet: number
) => {
  const previousMonthBalance = monthIndex === 0 ? 0 : loanOutput.balances[monthIndex - 1];
  const rolledUpInterest =
    loanOutput.loan.interestCharging === InterestChargingType.RolledUp
      ? loanOutput.interestAccrued[monthIndex]
      : 0;
  const canPayBackFullBalancePlusInterest = currentNet > previousMonthBalance + rolledUpInterest;
  const canPayBackFullBalance = currentNet > previousMonthBalance;
  if (canPayBackFullBalancePlusInterest) {
    setFinalRetainedInterest(loanOutput);
    if (loanOutput.loan.interestCharging === InterestChargingType.RolledUp) {
      loanOutput.interestPaid[monthIndex] = rolledUpInterest;
    }
    return currentNet - previousMonthBalance - rolledUpInterest;
  } else if (canPayBackFullBalance) {
    if (loanOutput.loan.interestCharging === InterestChargingType.RolledUp) {
      loanOutput.interestPaid[monthIndex] = currentNet - previousMonthBalance;
    }
    loanOutput.balances[monthIndex] = previousMonthBalance + rolledUpInterest - currentNet;
    return 0;
  } else {
    loanOutput.balances[monthIndex] = previousMonthBalance - currentNet;
    if (loanOutput.loan.interestCharging === InterestChargingType.RolledUp) {
      loanOutput.balances[monthIndex] += loanOutput.interestAccrued[monthIndex];
    }
    return 0;
  }
};

const setFinalRetainedInterest = (loanOutput: CashflowLoanOutput) => {
  if (loanOutput.loan.interestCharging !== InterestChargingType.Retained) {
    return;
  }
  const startMonthIndex = loanOutput.balances.findIndex((x) => !!x);
  loanOutput.interestAccrued = loanOutput.interestAccrued.map((x, i) =>
    i === startMonthIndex ? loanOutput.accumulatedInterest : 0
  );
};

const payLoanFees = (
  loanOutputs: CashflowLoanOutput[],
  developerEquity: number[],
  maxEquity: number
) => {
  const hasBeenUtilised = (loanOutput: CashflowLoanOutput) =>
    loanOutput.balances.some((b) => b !== 0);

  const totalFees = loanOutputs
    .filter(hasBeenUtilised)
    .reduce(
      (total, loanOutput) =>
        total + loanFees(loanOutput.loan, loanOutputs, loanOutput.totalInterest),
      0
    );

  // Find month with equity enough to cover fees from all utilised loans
  const indexOfFirstMonthWithEquityForFees =
    developerEquity.length -
    developerEquity
      .slice()
      .reverse()
      .findIndex((eq) => {
        const nonUtilisedEquity = maxEquity + eq;
        return nonUtilisedEquity < totalFees;
      });

  if (indexOfFirstMonthWithEquityForFees < developerEquity.length) {
    for (const loanOutput of loanOutputs.filter(hasBeenUtilised)) {
      const firstDrawMonthIndex = loanOutput.balances.findIndex((x) => !!x);
      const grossLoan = calculateGrossLoan(loanOutput.loan, loanOutputs, loanOutput.totalInterest);
      loanOutput.feesAccrued[firstDrawMonthIndex] = arrangementFee(loanOutput.loan, grossLoan);
      loanOutput.feesAccrued[indexOfFirstMonthWithEquityForFees] = exitFee(
        loanOutput.loan,
        grossLoan
      );
      loanOutput.feesPaid[indexOfFirstMonthWithEquityForFees] = loanFees(
        loanOutput.loan,
        loanOutputs,
        loanOutput.totalInterest
      );
    }
    for (let i = indexOfFirstMonthWithEquityForFees; i < developerEquity.length; i++) {
      developerEquity[i] -= totalFees;
    }
  }
};

const drawFromEquityFundingAndReturnNewNet = (
  equityOutputs: CashflowEquityOutput[],
  monthIndex: number,
  initialNet: number
) => {
  let currentNet = initialNet;
  for (const equityOutput of equityOutputs.slice().reverse()) {
    const previousBalance = monthIndex === 0 ? 0 : equityOutput.balances[monthIndex - 1];
    const remainingEquityFunding = equityOutput.equity.totalAmount - previousBalance;
    const equityDrawn = Math.min(Math.abs(currentNet), remainingEquityFunding);

    equityOutput.balances[monthIndex] = previousBalance + equityDrawn;
    currentNet += equityDrawn;
  }
  return currentNet;
};

const payBackEquityInterestAndReturnNewNet = (
  equityOutput: CashflowEquityOutput,
  monthIndex: number,
  initialNet: number
) => {
  let currentNet = initialNet;
  const totalInterestOwed = calculateEquityInterest(equityOutput.equity as ClientEquityFunding);
  const totalInterestRepaid =
    monthIndex === 0 ? 0 : sum(equityOutput.interestPaid.slice(0, monthIndex));
  const interestRemaining = totalInterestOwed - totalInterestRepaid;
  const interestDiff = currentNet - interestRemaining;
  equityOutput.interestPaid[monthIndex] = Math.min(
    interestRemaining,
    Math.abs(interestRemaining + interestDiff)
  );
  currentNet = Math.max(interestDiff, 0);

  return currentNet;
};

const payBackEquityFundingAndReturnNewNet = (
  equityOutputs: CashflowEquityOutput[],
  monthIndex: number,
  initialNet: number
) => {
  let currentNet = initialNet;
  for (const equityOutput of equityOutputs) {
    const previousBalance = monthIndex === 0 ? 0 : equityOutput.balances[monthIndex - 1];
    const balanceDiff = currentNet - previousBalance;
    const currentBalance = Math.max(-balanceDiff, 0);
    equityOutput.balances[monthIndex] = currentBalance;
    currentNet = Math.max(0, balanceDiff);
    const isBalanceSettled =
      currentBalance === 0 && equityOutput.balances.slice(0, monthIndex).some((b) => b > 0);
    if (currentNet > 0 && isBalanceSettled) {
      currentNet = payBackEquityInterestAndReturnNewNet(equityOutput, monthIndex, currentNet);
    }
  }
  return currentNet;
};
