<template>
  <div v-if="userStore.user">
    <NavBar data-testid="nav_bar" />
    <AccountSidebar data-testid="account_sidebar" />
    <div class="wrapper">
      <div class="main-panel" data-testid="main_panel">
        <DashboardContent />
      </div>
    </div>
    <SpecsLogger />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import DashboardContent from "./Content.vue";
import NavBar from "@/components/NavBar.vue";
import AccountSidebar from "@/components/AccountSidebar.vue";
import SpecsLogger from "@/components/SpecsLogger.vue";
import { rootStore } from "@/react/lib/persistence/root_store";
import { bootIntercomDeferred } from "@/utils/intercom";
import { redirectToLogin } from "@/utils/login_redirect";
import { Observer } from "mobx-vue";

@Observer
@Component({
  components: {
    DashboardContent,
    NavBar,
    SpecsLogger,
    AccountSidebar
  }
})
export default class DashboardLayout extends Vue {
  public userStore = rootStore.userStore;

  async mounted() {
    await rootStore.initialise();
    if (rootStore.userStore.user) {
      this.setupIntercom();
      this.setupHotjar();
    } else {
      redirectToLogin(this.$router);
    }
  }

  public setupIntercom() {
    const {
      _id,
      email,
      firstname,
      surname,
      telephone,
      intercom_hash,
      licence_summary,
      _organisation
    } = rootStore.userStore.user || {};

    if (intercom_hash && LANDFUND_CONFIG.INTERCOM_APP_ID) {
      bootIntercomDeferred({
        email,
        licence_summary,
        name: [firstname, surname].join(" "),
        user_id: _id,
        user_hash: intercom_hash,
        phone: telephone,
        app_id: LANDFUND_CONFIG.INTERCOM_APP_ID,
        hide_default_launcher: true,
        org: _organisation?.name
      });
    }
  }

  public setupHotjar() {
    if (rootStore.userStore.user) {
      this.ensureHj();
      window.hj("identify", rootStore.userStore.user._id, {
        user_id: rootStore.userStore.user._id,
        is_customer: !rootStore.userStore.hasLandTechEmail
      });
    }
  }

  public ensureHj() {
    // https://help.hotjar.com/hc/en-us/articles/360033640653-Identify-API-Reference#best-practices
    if (!window.hj) {
      window.hj = function () {
        const q = (window.hj.q = window.hj.q || []);
        q.push(arguments);
      };
    }
  }
}
</script>
