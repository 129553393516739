import { ClientSiteSearchResult } from "@shared/types/site";
import gql from "graphql-tag";
import { SITE_SUGGESTIONS_QUERY } from "../gql";
import { logError } from "@/react/utils";
import { landFundApolloClient } from "../clients";

export const getSiteSuggestions = async (
  searchString: string
): Promise<ClientSiteSearchResult[]> => {
  const { data, errors } = await landFundApolloClient.query({
    query: gql`
      ${SITE_SUGGESTIONS_QUERY}
    `,
    variables: {
      searchString
    }
  });
  if (errors) {
    for (let err of errors) {
      logError(err.message);
    }
    throw new Error(`Error retrieving site suggestions`);
  }
  return data.siteSuggestions;
};
