import { FULL_APPRAISAL_BY_ID } from "@shared/apollo/queries";
import { ClientAppraisal, ClientMiniAppraisal } from "@shared/types/appraisal";
import gql from "graphql-tag";
import { landFundApolloClient } from "@/react/lib/persistence/apollo/clients";
import { ClientDevelopment } from "@shared/types/development";
import { DEVELOPMENT_APPRAISALS_QUERY } from "../gql";

export const getCachedAppraisal = (appraisalId: ClientAppraisal["_id"]): ClientAppraisal => {
  const data = landFundApolloClient.readQuery<{ appraisalById: ClientAppraisal }>({
    query: gql`
      ${FULL_APPRAISAL_BY_ID}
    `,
    variables: {
      appraisalId
    }
  });
  if (data?.appraisalById) {
    return data.appraisalById;
  } else {
    throw new Error(`Could not find local appraisal with id ${appraisalId}`);
  }
};

export const getRemoteAppraisal = async (
  appraisalId: ClientAppraisal["_id"]
): Promise<ClientAppraisal> => {
  const { data } = await landFundApolloClient.query({
    query: gql`
      ${FULL_APPRAISAL_BY_ID}
    `,
    variables: {
      appraisalId
    }
  });
  return data.appraisalById;
};

export const getAppraisalsForDevelopment = async (
  developmentId: ClientDevelopment["_id"]
): Promise<ClientMiniAppraisal[]> => {
  const { data } = await landFundApolloClient.query({
    query: gql`
      ${DEVELOPMENT_APPRAISALS_QUERY}
    `,
    variables: {
      id: developmentId
    }
  });
  return data.developmentById.appraisals;
};
