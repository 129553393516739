import { EditableLabel } from "@/react/components/Forms/EditableLabel";
import { rootStore } from "@/react/lib/persistence/root_store";
import { ClientBaseComputable, ClientCost } from "@shared/types/computable";
import React, { Dispatch } from "react";
import { ComputableInput } from "../ComputableInput";
import { ComputableInputProps } from "../ComputableInput/ComputableInput";

export interface CostInputProps
  extends Omit<ComputableInputProps, "computable" | "onChange" | "label"> {
  cost: ClientCost;
  onChange?: Dispatch<ClientCost>;
}

export const CostInput = ({
  id,
  cost,
  onChange = (val) => rootStore.costStore.updateCost(val),
  ...props
}: CostInputProps) => {
  const updateDescription = (newLabel: string) => {
    onChange({
      ...cost,
      description: newLabel
    });
  };

  return (
    <ComputableInput
      id={cost._id}
      testId="cost-input"
      label={
        <EditableLabel
          label={cost.description}
          attachedId={`${id}-value`}
          onChange={updateDescription}
          className="atlas-w-full"
        />
      }
      computable={cost}
      onChange={(val: ClientBaseComputable) => {
        onChange(val as ClientCost);
      }}
      {...props}
    />
  );
};
