import { ClientMiniAppraisal, ClientMiniTemplate } from "@shared/types/appraisal";
import { ClientDevelopment } from "@shared/types/development";
import { makeAutoObservable } from "mobx";
import { Nullable } from "@shared/types/utils";
import ObjectID from "bson-objectid";
import { RootStore } from "../Root";
import {
  getAppraisalsForDevelopment,
  createAppraisal as createAppraisalApollo,
  deleteAppraisal as deleteAppraisalApollo,
  duplicateAppraisal as duplicateAppraisalApollo,
  updateMiniAppraisal as updateMiniAppraisalApollo
} from "@/react/lib/persistence/apollo";

export class MiniAppraisalStore {
  root: RootStore;
  appraisals: ClientMiniAppraisal[] = [];
  isLoading: boolean = false;

  constructor(root: RootStore) {
    this.root = root;
    makeAutoObservable(this, { root: false });
  }

  async setupMiniAppraisalStore(developmentId: ClientDevelopment["_id"]) {
    this.setIsLoading(true);
    this.setAppraisals([]);
    const appraisals = await getAppraisalsForDevelopment(developmentId);
    this.setAppraisals(appraisals);
    this.setIsLoading(false);
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setAppraisals(value: ClientMiniAppraisal[]) {
    this.appraisals = value;
  }

  public async createAppraisal(template: Nullable<ClientMiniTemplate> = null) {
    const currentDevelopment = this.root.currentDevelopmentStore.development;
    if (!currentDevelopment) {
      throw new Error("There isn't a development in the CurrentDevelopmentStore");
    }
    const appraisalBackup = [...this.appraisals];
    try {
      const newAppraisal = {
        _id: new ObjectID().toHexString(),
        _development: currentDevelopment._id,
        isTemplate: false,
        title: template ? "Created from " + template.title : "",
        description: currentDevelopment?.description,
        archived: false
      };

      this.appraisals.push(newAppraisal);
      await createAppraisalApollo(newAppraisal._id, currentDevelopment, template?._id ?? null);

      this.root.developmentsStore.updateDevelopmentLocally(currentDevelopment._id, {
        appraisalCount: this.appraisalCount
      });
      return newAppraisal;
    } catch {
      this.setAppraisals(appraisalBackup);
    }
  }

  async deleteAppraisal(id: string) {
    const currentDevelopment = this.root.currentDevelopmentStore.development;
    if (!currentDevelopment) {
      throw new Error("There isn't a development in the CurrentDevelopmentStore");
    }

    const appraisalBackup = [...this.appraisals];
    try {
      this.appraisals = this.appraisals.filter((appraisal) => appraisal._id !== id);
      await deleteAppraisalApollo(id, currentDevelopment._id);
      this.root.developmentsStore.updateDevelopmentLocally(currentDevelopment._id, {
        appraisalCount: this.appraisalCount
      });
    } catch {
      this.setAppraisals(appraisalBackup);
    }
  }

  async duplicateAppraisal(id: string) {
    const existingAppraisal = this.appraisals.find((appraisal) => appraisal._id === id);
    if (!existingAppraisal) {
      throw new Error("Appraisal id not found!");
    }
    const currentDevelopment = this.root.currentDevelopmentStore.development;
    if (!currentDevelopment) {
      throw new Error("No development in the CurrentDevelopment store!");
    }
    const appraisalBackup = [...this.appraisals];
    try {
      const newAppraisal = {
        ...existingAppraisal,
        title: existingAppraisal.title + " COPY",
        _id: new ObjectID().toHexString()
      };
      this.appraisals.push(newAppraisal);
      await duplicateAppraisalApollo(
        id,
        newAppraisal._id,
        newAppraisal.title,
        currentDevelopment._id
      );
      this.root.developmentsStore.updateDevelopmentLocally(currentDevelopment._id, {
        appraisalCount: this.appraisalCount
      });
    } catch {
      this.setAppraisals(appraisalBackup);
    }
  }

  async archiveAppraisal(id: ClientMiniAppraisal["_id"]) {
    await this.updateAppraisal(id, { archived: true });
  }

  async restoreAppraisal(id: ClientMiniAppraisal["_id"]) {
    await this.updateAppraisal(id, { archived: false });
  }

  async updateAppraisal(
    id: ClientMiniAppraisal["_id"],
    appraisalUpdate: Partial<ClientMiniAppraisal>
  ) {
    const appraisalIndex = this.appraisals.findIndex((appraisal) => appraisal._id === id);
    if (appraisalIndex === -1) {
      throw new Error("Could not find appraisal id!");
    }
    const appraisalBackup = [...this.appraisals];
    try {
      const updatedAppraisal = {
        ...this.appraisals[appraisalIndex],
        ...appraisalUpdate
      };
      this.appraisals.splice(appraisalIndex, 1, updatedAppraisal);
      await updateMiniAppraisalApollo(id, appraisalUpdate);
      return updatedAppraisal;
    } catch {
      this.setAppraisals(appraisalBackup);
    }
  }

  get archivedAppraisals() {
    return this.appraisals.filter((appraisal) => appraisal.archived);
  }

  get activeAppraisals() {
    return this.appraisals.filter((appraisal) => !appraisal.archived);
  }

  get appraisalTabs(): Array<{ title: string; appraisals: ClientMiniAppraisal[] }> {
    return [
      {
        title: "Active",
        appraisals: this.activeAppraisals
      },
      {
        title: "Archived",
        appraisals: this.archivedAppraisals
      }
    ];
  }

  get appraisalCount() {
    return this.appraisals.length;
  }
}
