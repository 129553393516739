import React, { useMemo, useState } from "react";
import { Input } from "@/react/components";
import { NumberInputCalculation } from "./components/CalculationField";
import { InternalValueObject, NumberInputProps, ValueObject } from "./types";
import clsx from "clsx";
import { isEqual } from "lodash";
import { formatValue, resetFormatting } from "@/react/utils/formatting";

const NumberInput = (props: NumberInputProps) => <NumberInputInner {...props} />;

const NumberInputInner = ({
  id,
  label,
  value,
  onChange = () => null,
  calculations,
  hasCalculationLabelAsSuffix = true,
  showActiveOption = true,
  size,
  testId = "number-input",
  showFalsyDefaultValue,
  ...rest
}: NumberInputProps) => {
  const hasCalculations = useMemo(() => !!calculations && calculations.length > 0, [calculations]);

  const [editingCalculationField, updateEditingCalculationField] = useState<boolean>(
    value.calculate
  );
  const [userHasTyped, updateUserHasTyped] = useState(false);

  function calculationUpdate(val: Partial<InternalValueObject>) {
    if (val.hasOwnProperty("value")) {
      val.value = resetFormatting(val.value);
    }
    if (val.hasOwnProperty("calculationBase")) {
      val.calculationBase = resetFormatting(val.calculationBase);
    }
    if (val.calculate !== editingCalculationField) {
      val.calculate = editingCalculationField;
    }
    const updatedValue = { ...value, ...(val as ValueObject) };
    if (!isEqual({ ...value }, updatedValue)) {
      onChange(updatedValue);
    }
  }

  const hideValue = !userHasTyped && !showFalsyDefaultValue && !value.value;

  return (
    <div className="atlas-flex atlas-gap-3" data-testid={testId}>
      <div className={clsx("atlas-w-full", { "sm:atlas-w-1/2": hasCalculations })}>
        <Input
          data-testid={`${testId}-input`}
          id={id}
          label={label}
          {...rest}
          onKeyDown={() => {
            updateUserHasTyped(true);
          }}
          value={hideValue ? "" : formatValue(value.value)}
          onChange={(v) => calculationUpdate({ value: v })}
          trackCursor={true}
          onFocus={() => {
            updateEditingCalculationField(false);
          }}
        />
      </div>
      {!!hasCalculations && (
        <NumberInputCalculation
          id={`${id}-calculation`}
          type="text"
          value={value}
          hasCalculationLabelAsSuffix={hasCalculationLabelAsSuffix}
          showActiveOption={showActiveOption}
          editingCalculationField={editingCalculationField}
          updateInputValue={(v) => calculationUpdate({ ...v })}
          onFocus={() => {
            updateEditingCalculationField(true);
          }}
          size={size}
          disabled={rest.disabled}
          calculations={calculations}
          formatter={formatValue}
          clearFormatting={resetFormatting}
        />
      )}
    </div>
  );
};

export { NumberInput, NumberInputInner };
