import { debounce } from "lodash";
import React, { Dispatch, useCallback, useState } from "react";

interface EditableLabelProps {
  label: string | undefined;
  onChange: Dispatch<string>;
  attachedId: string;
  className: string;
}

export const EditableLabel = (props: EditableLabelProps) => <EditableLabelInternal {...props} />;

const EditableLabelInternal = ({ attachedId, onChange, label, className }: EditableLabelProps) => {
  const [labelContent, setLabelContent] = useState(label);

  const emit = useCallback(
    debounce((val: string) => {
      onChange(val);
    }, 500),
    [onChange]
  );

  const onInput = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setLabelContent(evt.target.value);
    emit(evt.target.value);
  };

  return (
    <input
      id={`${attachedId}-label`}
      className={`${className} atlas-overflow-ellipsis atlas-mb-0 atlas-px-2 atlas-py-1
          atlas-cursor-text atlas-border-2 atlas-border-transparent
          atlas-rounded atlas-border-solid hover:atlas-border-dotted
          hover:atlas-border-neutral-400`}
      type="text"
      data-testid="editable-label"
      value={labelContent}
      onChange={onInput}
      aria-label={`Edit label for ${labelContent}`}
    />
  );
};
