import { Button, ButtonSizes, ButtonThemes } from "@/react/components";
import { useTranslation } from "@/react/lib/hooks";
import { CashflowTableTranslations } from "@/react/lib/i18n/locales";
import { rootStore } from "@/react/lib/persistence/root_store";
import { rowTotal } from "@/react/utils/lineItem";
import { ClientLineItemExtended } from "@shared/types/cashflow";
import React from "react";

interface TimeFrameButtonProps {
  lineItem: ClientLineItemExtended;
}

export const TimeFrameButton = ({ lineItem }: TimeFrameButtonProps) => {
  const { t } = useTranslation();
  const currentLineItemStore = rootStore.currentLineItemStore;
  let timeFrameText;
  if (rowTotal(lineItem.cells) === 0) {
    timeFrameText = t(CashflowTableTranslations.CashflowTable_Set);
  } else if (lineItem.startIndex === lineItem.endIndex) {
    timeFrameText = `${t(CashflowTableTranslations.CashflowTable_Month)} ${
      lineItem.startIndex + 1
    }`;
  } else {
    timeFrameText = `${t(CashflowTableTranslations.CashflowTable_Month)} ${
      lineItem.startIndex + 1
    } - ${t(CashflowTableTranslations.CashflowTable_Month)} ${lineItem.endIndex + 1}`;
  }

  return (
    <Button
      theme={ButtonThemes.Outline}
      size={ButtonSizes.SM}
      onClick={() => currentLineItemStore.startEditTimeframe(lineItem)}
      data-testid="time-frame-button"
    >
      {timeFrameText}
    </Button>
  );
};
