import { MAX_MONTH_INPUT } from "@shared/utils/constants";
import {
  AppraisalAnnotations,
  AppraisalCostsTranslations,
  AppraisalDetailsTranslations,
  AppraisalEquityFundingTranslations,
  AppraisalFundingTranslations,
  AppraisalIncomeTranslations,
  AppraisalSectionsTranslations,
  AppraisalSingularWords,
  AppraisalIncomeUnitsTranslations,
  AppraisalActionsTranslations
} from "../enums";

export const AppraisalMessages = {
  [AppraisalSingularWords.Appraisal_Add]: "Add",
  [AppraisalSingularWords.Appraisal_Edit]: "Edit",
  [AppraisalSingularWords.Appraisal_Save]: "Save",
  [AppraisalSingularWords.Appraisal_Cancel]: "Cancel",
  [AppraisalSingularWords.Appraisal_Close]: "Close",
  [AppraisalSingularWords.Appraisal_Fees]: "Fees",
  [AppraisalSingularWords.Appraisal_Interest]: "Interest",
  [AppraisalSingularWords.Appraisal_Months]: "months",
  [AppraisalDetailsTranslations.AppraisalDetails_AppraisalDetails]: "Appraisal Details",
  [AppraisalDetailsTranslations.AppraisalDetails_TemplateDetails]: "Template Details",
  [AppraisalDetailsTranslations.AppraisalDetails_UntitledAppraisal]: "Untitled Appraisal",
  [AppraisalDetailsTranslations.AppraisalDetails_UntitledTemplate]: "Untitled Template",
  [AppraisalDetailsTranslations.AppraisalDetails_Title]: "Title",
  [AppraisalDetailsTranslations.AppraisalDetails_Description]: "Description",
  [AppraisalAnnotations.AppraisalAnnotations_Annotations]: "Annotations",
  [AppraisalAnnotations.AppraisalAnnotations_AnnotationsPlaceholder]:
    "Annotations (max 500 characters)",
  [AppraisalCostsTranslations.AppraisalCosts_NoCosts]: "No costs have been added",
  [AppraisalCostsTranslations.AppraisalCosts_AddNewCost]: "Add New Cost",
  [AppraisalCostsTranslations.AppraisalCosts_AddCost]: "Add Cost",
  [AppraisalCostsTranslations.AppraisalCosts_CostDescription]: "Cost Description",
  [AppraisalCostsTranslations.AppraisalCosts_CostValue]: "Value",
  [AppraisalCostsTranslations.AppraisalCosts_PleaseFillIn]: "Please fill in",
  [AppraisalCostsTranslations.AppraisalCosts_ValueMustBeGreaterThanZero]:
    "Value must be greater than zero",
  [AppraisalCostsTranslations.AppraisalCosts_PleaseConfirm]: "Please Confirm",
  [AppraisalCostsTranslations.AppraisalCosts_DeleteCostConfirm]: "Delete this cost?",
  [AppraisalCostsTranslations.AppraisalCosts_DeleteCost]: "Delete Cost",
  [AppraisalCostsTranslations.AppraisalCosts_Delete]: "Delete",
  [AppraisalSectionsTranslations.AppraisalSections_ProfesionalFeesTitle]: "Professional Fees",
  [AppraisalSectionsTranslations.AppraisalSections_OtherCostsTitle]: "Other Costs",
  [AppraisalSectionsTranslations.AppraisalSections_CheckCilCharges]: "Check CIL charges",
  [AppraisalIncomeUnitsTranslations.AppraisalIncomeUnits_TableBedroomsCount]: "Bedrooms",
  [AppraisalIncomeUnitsTranslations.AppraisalIncomeUnits_TableUnitName]: "Name",
  [AppraisalIncomeUnitsTranslations.AppraisalIncomeUnits_TableAreaNIA]: "Area (NIA)",
  [AppraisalIncomeUnitsTranslations.AppraisalIncomeUnits_TableSalesValue]: "Sales Value",
  [AppraisalIncomeUnitsTranslations.AppraisalIncomeUnits_TablePrice]: "£",
  [AppraisalIncomeUnitsTranslations.AppraisalIncomeUnits_TableUnit]: "Unit",
  [AppraisalIncomeUnitsTranslations.AppraisalIncomeUnits_TableRent]: "Rent",
  [AppraisalIncomeUnitsTranslations.AppraisalIncomeUnits_TableAnnualIncome]: "Annual Income",
  [AppraisalIncomeUnitsTranslations.AppraisalIncomeUnits_TableYield]: "Yield",
  [AppraisalIncomeUnitsTranslations.AppraisalIncomeUnits_TableIncomePerArea]: "Income",
  [AppraisalIncomeUnitsTranslations.AppraisalIncomeUnits_TableVoidPeriod]: "Void Period",
  [AppraisalIncomeUnitsTranslations.AppraisalIncomeUnits_TableRentFree]: "Rent Free",
  [AppraisalIncomeUnitsTranslations.AppraisalIncomeUnits_DeleteUnitConfirm]: "Delete this unit?",
  [AppraisalIncomeUnitsTranslations.AppraisalIncomeUnits_DeleteUnitsConfirm]: "Delete these units?",
  [AppraisalIncomeUnitsTranslations.AppraisalIncomeUnits_DeleteUnit]: "Delete unit",
  [AppraisalIncomeUnitsTranslations.AppraisalIncomeUnits_EditUnit]: "Edit Unit",
  [AppraisalIncomeUnitsTranslations.AppraisalEquityFunding_DuplicateUnit]: "Duplicate Unit",
  [AppraisalIncomeTranslations.AppraisalIncome_AddUnitGroup]: "Add Unit Group",
  [AppraisalIncomeTranslations.AppraisalIncome_AddUnits]: "Add Units to Unit Group",
  [AppraisalIncomeTranslations.AppraisalIncome_EditUnitGroup]: "Edit Unit Group",
  [AppraisalIncomeTranslations.AppraisalIncome_EditUnit]: "Edit Unit",
  [AppraisalIncomeTranslations.AppraisalIncome_EditUnits]: "Edit Units",
  [AppraisalIncomeTranslations.AppraisalIncome_UnitGroupDescription]: "Unit Group Name",
  [AppraisalIncomeTranslations.AppraisalIncome_UnitGroupDescriptionError]: "Please fill in",
  [AppraisalIncomeTranslations.AppraisalIncome_UnitCount]: "Number of Units",
  [AppraisalIncomeTranslations.AppraisalIncome_UnitCountError]:
    "Total number of units must be between 1 and 100",
  [AppraisalIncomeTranslations.AppraisalIncome_UnitBedroomsCount]: "Number of Bedrooms",
  [AppraisalIncomeTranslations.AppraisalIncome_UnitBedroomsCountError]:
    "Number of bedrooms must be greater than or equal to 0",
  [AppraisalIncomeTranslations.AppraisalIncome_UnitArea]: "Unit Area (NIA)",
  [AppraisalIncomeTranslations.AppraisalIncome_UnitAreaError]: "Value must be greater than 0",
  [AppraisalIncomeTranslations.AppraisalIncome_PercentGIA]: "GIA",
  [AppraisalIncomeTranslations.AppraisalIncome_PercentGIAError]:
    "GIA must be greater than or equal to 100%",
  [AppraisalIncomeTranslations.AppraisalIncome_InvestmentYield]: "Gross Investment Yield",
  [AppraisalIncomeTranslations.AppraisalIncome_InvestmentYieldError]:
    "Gross Investment Yield must be greater than 0",
  [AppraisalIncomeTranslations.AppraisalIncome_RentFreePeriod]: "Rent Free Period",
  [AppraisalIncomeTranslations.AppraisalIncome_RentFreePeriodError]:
    "Value must be greater than or equal to 0",
  [AppraisalIncomeTranslations.AppraisalIncome_VoidPeriod]: "Void Period",
  [AppraisalIncomeTranslations.AppraisalIncome_VoidPeriodError]:
    "Value must be greater than or equal to 0",
  [AppraisalIncomeTranslations.AppraisalIncome_MonthlyRent]: "Rent Value (per month)",
  [AppraisalIncomeTranslations.AppraisalIncome_MonthlyRentError]:
    "Rent Value must be greater than 0",
  [AppraisalIncomeTranslations.AppraisalIncome_TotalNIA]: "Total NIA",
  [AppraisalIncomeTranslations.AppraisalIncome_GrossSalesValue]: "Gross Sales Value (per unit)",
  [AppraisalIncomeTranslations.AppraisalIncome_SalesValue]: "Sales Value",
  [AppraisalIncomeTranslations.AppraisalIncome_SalesValuePerUnit]: "Sales Value (per unit)",
  [AppraisalIncomeTranslations.AppraisalIncome_SalesValueError]: "Value must be greater than 0",
  [AppraisalIncomeTranslations.AppraisalIncome_SalesFee]: "Sales Fee",
  [AppraisalIncomeTranslations.AppraisalIncome_SalesFeePerUnit]: "Sales Fee (per unit)",
  [AppraisalIncomeTranslations.AppraisalIncome_SalesFeeError]: "Value must be greater than 0",
  [AppraisalIncomeTranslations.AppraisalIncome_OfSales]: "of Sales",
  [AppraisalIncomeTranslations.AppraisalIncome_LegalFees]: "Legal Fees",
  [AppraisalIncomeTranslations.AppraisalIncome_LegalFeesPerUnit]: "Legal Fees (per unit)",
  [AppraisalIncomeTranslations.AppraisalIncome_LegalFeesError]: "Value must be greater than 0",
  [AppraisalIncomeTranslations.AppraisalIncome_AnnualRent]: "Annual Gross Income",
  [AppraisalIncomeTranslations.AppraisalIncome_AnnualRentPerUnit]: "Annual Gross Income (per unit)",
  [AppraisalIncomeTranslations.AppraisalIncome_AnnualRentError]:
    "Annual Gross Income must be greater than 0",
  [AppraisalIncomeTranslations.AppraisalIncome_IncomePer]: "Income per",
  [AppraisalIncomeTranslations.AppraisalIncome_OtherIncome]: "Value per unit",
  [AppraisalIncomeTranslations.AppraisalIncome_OtherIncomeTotal]: "Total value",
  [AppraisalIncomeTranslations.AppraisalIncome_OtherIncomeError]: "Value must be greater than 0",
  [AppraisalEquityFundingTranslations.AppraisalEquityFunding_TableEquityName]: "Equity Name",
  [AppraisalEquityFundingTranslations.AppraisalEquityFunding_TableTotalAmount]: "Total Amount",
  [AppraisalEquityFundingTranslations.AppraisalEquityFunding_TableCouponRate]: "Coupon Rate",
  [AppraisalEquityFundingTranslations.AppraisalEquityFunding_ProviderProfitShare]: "PPS",
  [AppraisalEquityFundingTranslations.AppraisalEquityFunding_ProviderProfitShareLabel]:
    "Provider's Profit Share",
  [AppraisalEquityFundingTranslations.AppraisalEquityFunding_ProviderProfitShare_Tooltip]:
    "Enter the percentage of profit that will be shared with the provider based on the equity finance model",
  [AppraisalEquityFundingTranslations.AppraisalEquityFunding_ProviderProfitShare_ErrorMessage]:
    "The percentage must be between 0 and 100",
  [AppraisalEquityFundingTranslations.AppraisalEquityFunding_TableTerm]: "Term",
  [AppraisalEquityFundingTranslations.AppraisalEquityFunding_TableDeveloperEquity]:
    "Developer Equity",
  [AppraisalEquityFundingTranslations.AppraisalEquityFunding_TableDeveloperEquityTooltip]:
    "This is calculated by deducting the Equity Financing from the Total Equity which is seen in the funding table above.",
  [AppraisalEquityFundingTranslations.AppraisalEquityFunding_EquityCost]: "Equity Cost",
  [AppraisalEquityFundingTranslations.AppraisalEquityFunding_AddEquity]: "Add Equity",
  [AppraisalEquityFundingTranslations.AppraisalEquityFunding_EditEquity]: "Edit Equity",
  [AppraisalEquityFundingTranslations.AppraisalEquityFunding_DuplicateEquity]: "Duplicate Equity",
  [AppraisalEquityFundingTranslations.AppraisalEquityFunding_DeleteEquity]: "Delete Equity",
  [AppraisalEquityFundingTranslations.AppraisalEquityFunding_DeleteEquityConfirm]:
    "Delete this equity?",
  [AppraisalEquityFundingTranslations.AppraisalEquityFunding_EquityName]: "Name",
  [AppraisalEquityFundingTranslations.AppraisalEquityFunding_Equity_NotBlank_ErrorMessage]:
    "Please fill in",
  [AppraisalEquityFundingTranslations.AppraisalEquityFunding_TotalAmount]: "Total Amount",
  [AppraisalEquityFundingTranslations.AppraisalEquityFunding_TotalAmount_ErrorMessage]:
    "Total Amount must be greater than 0",
  [AppraisalEquityFundingTranslations.AppraisalEquityFunding_CouponRate]: "Rate",
  [AppraisalEquityFundingTranslations.AppraisalEquityFunding_CouponRate_ErrorMessage]:
    "Rate must be greater than 0",
  [AppraisalEquityFundingTranslations.AppraisalEquityFunding_CouponRate_Tooltip]:
    "Specify the interest rate associated with the equity finance model. This rate determines the cost of borrowing funds through equity financing and is rolled up, meaning that it is compounded over time until the funds are repaid.",
  [AppraisalEquityFundingTranslations.AppraisalEquityFunding_EquityTerm]: "Term",
  [AppraisalEquityFundingTranslations.AppraisalEquityFunding_EquityTerm_ErrorMessage]: `Term must be between 0 and ${MAX_MONTH_INPUT}`,
  [AppraisalFundingTranslations.AppraisalFunding_Funding]: "Funding",
  [AppraisalFundingTranslations.AppraisalFunding_CloseModal]: "Close Modal",
  [AppraisalFundingTranslations.AppraisalFunding_DragHandle]: "Drag Handle",
  [AppraisalFundingTranslations.AppraisalFunding_HideAnnotations]: "Hide Annotations",
  [AppraisalFundingTranslations.AppraisalFunding_ShowAnnotations]: "Show Annotations",
  [AppraisalFundingTranslations.AppraisalFunding_PleaseConfirm]: "Please Confirm",
  [AppraisalFundingTranslations.AppraisalFunding_DeleteLoanConfirm]: "Delete this loan?",
  [AppraisalFundingTranslations.AppraisalFunding_Delete]: "Delete",
  [AppraisalFundingTranslations.AppraisalFunding_AddLoan]: "Add Loan",
  [AppraisalFundingTranslations.AppraisalFunding_EditLoan]: "Edit Loan",
  [AppraisalFundingTranslations.AppraisalFunding_DeleteLoan]: "Delete Loan",
  [AppraisalFundingTranslations.AppraisalFunding_AddEquity]: "Add Equity",
  [AppraisalFundingTranslations.AppraisalFunding_EditEquity]: "Edit Equity",
  [AppraisalFundingTranslations.AppraisalFunding_DeleteEquity]: "Delete Equity",
  [AppraisalFundingTranslations.AppraisalFunding_LoanName]: "Loan Name",
  [AppraisalFundingTranslations.AppraisalFunding_Term]: "Term",
  [AppraisalFundingTranslations.AppraisalFunding_Term_Tooltip]:
    "When 'Drawn balance (cashflow)' is selected as the Interest Calculation, the term is automatically calculated based on how income and costs are spread in the cashflow. You can then alter the term indirectly from the Cashflow tab, e.g. by changing the length of your build or the rate of sales.",
  [AppraisalFundingTranslations.AppraisalFunding_Term_ErrorMessage]: `Term must be between 0 and ${MAX_MONTH_INPUT}`,
  [AppraisalFundingTranslations.AppraisalFunding_TableGrossAmount]: "Gross Amount",
  [AppraisalFundingTranslations.AppraisalFunding_TableCost]: "Cost",
  [AppraisalFundingTranslations.AppraisalFunding_TableActions]: "Actions",
  [AppraisalFundingTranslations.AppraisalFunding_TableTotalEquity]: "Total Equity",
  [AppraisalFundingTranslations.AppraisalFunding_Loan_AddNewLoan]: "Add New Loan",
  [AppraisalFundingTranslations.AppraisalFunding_Loan_EditLoan]: "Edit Loan",
  [AppraisalFundingTranslations.AppraisalFunding_Loan_ArrangementFee]: "Arrangement Fee",
  [AppraisalFundingTranslations.AppraisalFunding_Loan_ArrangementFee_ErrorMessage]:
    "Arrangement Fee must be greater than or equal to 0",
  [AppraisalFundingTranslations.AppraisalFunding_Loan_NotBlank_ErrorMessage]: "Please fill in",
  [AppraisalFundingTranslations.AppraisalFunding_Loan_InterestRate]: "Interest rate",
  [AppraisalFundingTranslations.AppraisalFunding_Loan_InterestRate_ErrorMessage]:
    "Interest Rate must be greater than 0",
  [AppraisalFundingTranslations.AppraisalFunding_Loan_InterestRateType]: "Loan Interest Rate Type",
  [AppraisalFundingTranslations.AppraisalFunding_Loan_NetLoan]: "Net Loan",
  [AppraisalFundingTranslations.AppraisalFunding_Loan_NetLoan_ErrorMessage]:
    "Net Loan must be greater than 0",
  [AppraisalFundingTranslations.AppraisalFunding_Loan_InterestCalculation]: "Interest Calculation",
  [AppraisalFundingTranslations.AppraisalFunding_Loan_InterestCalculation_TooltipDescription]:
    "Drawn balance (S-curve) - Interest is based on drawn balance according to a standard S-curve, Drawn balance (cashflow) - Interest is pulled into the appraisal from the cashflow, Full loan - Interest is simply calculated based on the full loan amount for the whole term",
  [AppraisalFundingTranslations.AppraisalFunding_Loan_InterestCalculation_Tooltip]: `
    <strong>Drawn balance (S-curve)</strong> - Interest is based on drawn balance according to a standard S-curve<br />
    <strong>Drawn balance (cashflow)</strong> - Interest is pulled into the appraisal from the cashflow<br />
    <strong>Full loan</strong> - Interest is simply calculated based on the full loan amount for the whole term<br />`,
  [AppraisalFundingTranslations.AppraisalFunding_Loan_InterestCharging]: "Interest Charging",
  [AppraisalFundingTranslations.AppraisalFunding_Loan_InterestCharging_TooltipDescription]:
    "Rolled Up - Interest is added onto the balance for each month, Retained - Interest is calculated and retained from the gross loan at the start of the development, Serviced - Interest is paid every month throughout the development",
  [AppraisalFundingTranslations.AppraisalFunding_Loan_InterestCharging_Tooltip]: `
    <strong>Rolled Up</strong> - Interest is added onto the balance for each month<br />
    <strong>Retained</strong> - Interest is calculated and retained from the gross loan at the start of the development<br />
    <strong>Serviced</strong> - Interest is paid every month throughout the development`,
  [AppraisalFundingTranslations.AppraisalFunding_Loan_ExitFee]: "Exit Fee",
  [AppraisalFundingTranslations.AppraisalFunding_Loan_ExitFee_ErrorMessage]:
    "Exit Fee must be greater than or equal to 0",
  [AppraisalFundingTranslations.AppraisalFunding_Loan_TotalCost]: "Total Cost",
  [AppraisalFundingTranslations.AppraisalFunding_Loan_GrossLoan]: "Gross Loan",
  [AppraisalFundingTranslations.AppraisalFunding_Loan_CostPercent]: "% of Costs",
  [AppraisalFundingTranslations.AppraisalFunding_Loan_GDVPercent]: "% of GDV",
  [AppraisalActionsTranslations.AppraisalIncome_EditUnits_Button]: "Edit",
  [AppraisalActionsTranslations.AppraisalIncome_EditUnits_Tooltip]: "Edit Selected Units",
  [AppraisalActionsTranslations.AppraisalIncome_DeleteUnits_Button]: "Delete",
  [AppraisalActionsTranslations.AppraisalIncome_DeleteUnits_Tooltip]: "Delete Selected Units"
};
