import { Card } from "@/react/components/Cards";
import { useWindowResize } from "@/react/utils";
import React from "react";
import { FundingPageCard, FundingPageForm } from "./components";

const FundingPage = () => {
  const isLargeScreen = useWindowResize(1024)[1];
  return (
    <div className="atlas-max-w-screen-2xl atlas-mx-auto sm:atlas-px-5 atlas-mt-6 atlas-mb-0 sm:atlas-mb-5">
      <Card className="atlas-rounded-none sm:atlas-rounded atlas-border atlas-border-solid atlas-border-neutral-300">
        <div className="atlas-pt-0 atlas-flex atlas-w-full atlas-p-5">
          <div className="atlas-w-full lg:atlas-w-4/6">
            <FundingPageCard>{!isLargeScreen && <FundingPageForm />}</FundingPageCard>
          </div>
          {!!isLargeScreen && (
            <div className="atlas-w-full lg:atlas-w-2/6">
              <FundingPageForm />
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

export { FundingPage };
