import gql from "graphql-tag";
import { logError } from "@/react/utils";
import { landFundApolloClient } from "../clients";
import { SITE_CONSTRAINTS } from "@shared/apollo/queries";
import { ISiteConstraints } from "@shared/types/siteConstraints";

export const getSiteConstraints = async (
  type: string,
  coordinates: object
): Promise<ISiteConstraints> => {
  const { data, errors } = await landFundApolloClient.query({
    query: gql`
      ${SITE_CONSTRAINTS}
    `,
    variables: {
      geometry: { type, coordinates }
    }
  });
  if (errors) {
    for (let err of errors) {
      logError(err.message);
    }
    throw new Error(`Error retrieving site constraints`);
  }
  return data.siteConstraints;
};
