<template>
  <div class="include-logo-checkbox">
    <b-form-checkbox
      :checked="userStore.useLogo"
      @input="updateUseLogo"
      :disabled="disable"
      data-testid="include_logo_checkbox"
    >
      <span>Add my logo to appraisal reports</span>
    </b-form-checkbox>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import { AccountErrorText } from "@shared/types/account";
import { Observer } from "mobx-vue";
import { rootStore } from "@/react/lib/persistence/root_store";

@Observer
@Component({})
export default class IncludeLogoCheckbox extends Vue {
  @Prop({ default: false }) readonly disable!: boolean;

  public userStore = rootStore.userStore;

  public async updateUseLogo(newValue: boolean) {
    if (rootStore.userStore.useLogo === newValue) {
      return;
    }
    try {
      this.clearMessages();
      await rootStore.userStore.updateUserPreferencesAndRefreshReport({ use_logo: newValue });
    } catch {
      this.$emit("error", AccountErrorText.FailedUpdatingSetting);
    }
  }

  private clearMessages(): void {
    this.$emit("clearErrors");
  }
}
</script>

<style lang="scss" scoped>
.include-logo-checkbox {
  margin-top: 0.5rem;
  line-height: 1.5rem;
}
</style>
