import React, { Dispatch, FC, useMemo } from "react";
import { Select } from "@/react/components";
import { InputSuffix } from "../../../Input/Input";
import clsx from "clsx";

export interface CalculationSelectProps {
  options: Array<{ label: string; option: string }>;
  onSelect: Dispatch<string | undefined>;
  calculationsSelectClassName?: string;
  hasSuffix?: boolean;
  activeItem: string | null;
  showActiveOption?: boolean;
}

const CalculationsSelect: FC<CalculationSelectProps> = ({
  options,
  onSelect,
  activeItem,
  hasSuffix,
  showActiveOption = true,
  calculationsSelectClassName
}) => {
  const activeOption = useMemo(() => {
    return options.find((o) => o.option === activeItem) ?? options[0];
  }, [activeItem]);
  return (
    <>
      {(options.length > 1 || (options.length === 1 && hasSuffix)) && (
        <div
          data-testid="number-input-dropdown"
          className={clsx(
            "atlas-flex",
            { "atlas-w-1/2": showActiveOption },
            { "atlas-w-1/5": !showActiveOption && options.length > 1 }
          )}
        >
          {options.length > 1 && (
            <Select
              testId="calculation-dropdown"
              options={options}
              value={activeItem}
              onChange={onSelect}
              showActiveItem={showActiveOption}
              selectClassName={clsx("atlas-bg-neutral-100", calculationsSelectClassName)}
              className={clsx(
                "atlas-mb-0 atlas-border-0 atlas-border-l-0 atlas-border-neutral-300 atlas-rounded-l-none focus-within:atlas-ring-offset-3 atlas-h-full"
              )}
            />
          )}
          {options.length === 1 && hasSuffix && showActiveOption && (
            <InputSuffix className="atlas-w-full" testId="calculation-suffix">
              {activeOption?.label}
            </InputSuffix>
          )}
        </div>
      )}
    </>
  );
};

export { CalculationsSelect };
