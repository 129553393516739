"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LineItemDescription = exports.LineItemType = exports.CurveType = void 0;
var CurveType;
(function (CurveType) {
    CurveType["Line"] = "Line";
    CurveType["SCurve"] = "SCurve";
    CurveType["Custom"] = "Custom";
})(CurveType = exports.CurveType || (exports.CurveType = {}));
var LineItemType;
(function (LineItemType) {
    LineItemType["UnitGroup"] = "UnitGroup";
    LineItemType["Contingency"] = "Contingency";
    LineItemType["OtherCost"] = "OtherCost";
    LineItemType["ProfessionalFee"] = "ProfessionalFee";
    LineItemType["BuildPhase"] = "BuildPhase";
    LineItemType["Loan"] = "Loan";
    LineItemType["Land"] = "Land";
})(LineItemType = exports.LineItemType || (exports.LineItemType = {}));
var LineItemDescription;
(function (LineItemDescription) {
    LineItemDescription["NetPurchasePrice"] = "Net Purchase Price";
    LineItemDescription["Deposit"] = "Deposit";
})(LineItemDescription = exports.LineItemDescription || (exports.LineItemDescription = {}));
