import { IncomeTranslations } from "../enums";

export const IncomeSection = {
  [IncomeTranslations.AddUnitGroup]: "Add Unit Group",
  [IncomeTranslations.Income]: "Income",
  [IncomeTranslations.NoUnits]: "No units in this group",
  [IncomeTranslations.NoUnitGroups]: "No unit groups have been added",
  [IncomeTranslations.Yield]: "Yield",
  [IncomeTranslations.GrossSalesValue]: "Gross Sales Value",
  [IncomeTranslations.OptionsMenuTooltip]: "Unit Group Options",
  [IncomeTranslations.ConfirmDeleteTitle]: "Please Confirm",
  [IncomeTranslations.ConfirmDeleteContent]: "Delete this unit group forever?",
  [IncomeTranslations.ConfirmDeleteConfirm]: "Delete",
  [IncomeTranslations.Add]: "Add",
  [IncomeTranslations.Edit]: "Edit",
  [IncomeTranslations.Delete]: "Delete"
};
