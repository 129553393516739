import { Button, ButtonThemes, Input, Modal, NumberInput } from "@/react/components";
import { useTranslation } from "@/react/lib/hooks";
import {
  AppraisalCostsTranslations,
  AppraisalSingularWords
} from "@/react/lib/i18n/locales/enums/appraisals";
import { rootStore } from "@/react/lib/persistence/root_store";
import { logEvent } from "@/react/utils";
import { ClientCost } from "@shared/types/computable";
import { observer } from "mobx-react-lite";
import React, { FC } from "react";

const CostModal: FC = () => {
  return <CostModalObserved />;
};

const CostModalObserved: FC = observer(() => {
  const { t } = useTranslation();
  function handleClose() {
    rootStore.currentCostStore.cancelAddingCost();
  }

  async function handleConfirm() {
    if (await rootStore.currentCostStore.submitCurrentCost()) {
      // have to wait for a response as can submit with invalid validation
      logEvent("Cost Created");
    }
  }

  const footerButtons = (
    <>
      <Button theme={ButtonThemes.Secondary} className="atlas-mr-2" onClick={handleClose}>
        <span data-testid="cancelCost">{t(AppraisalSingularWords.Appraisal_Cancel)}</span>
      </Button>
      <Button theme={ButtonThemes.Primary} onClick={handleConfirm}>
        <span data-testid="saveCost">{t(AppraisalSingularWords.Appraisal_Add)}</span>
      </Button>
    </>
  );

  return (
    <>
      {!!rootStore.utilityStore.costModalOpen && (
        <Modal
          data-testid="costModal"
          defaultOpen={true}
          onClose={handleClose}
          title={t(AppraisalCostsTranslations.AppraisalCosts_AddNewCost)}
          className="md:atlas-max-w-xl"
          footerClassNames="atlas-justify-end atlas-text-right"
          footerContent={footerButtons}
        >
          <div className="atlas-w-3/5 atlas-m-auto atlas-py-6">
            <div className="atlas-mb-4">
              <Input
                id="cost_description"
                className="atlas-w-full"
                label={t(AppraisalCostsTranslations.AppraisalCosts_CostDescription)}
                value={rootStore.currentCostStore.cost.description ?? ""}
                data-testid="cost-description"
                onChange={(value) =>
                  rootStore.currentCostStore.updateCost({
                    description: String(value)
                  })
                }
                error={{
                  display: rootStore.currentCostStore.validator.state["description"] === false,
                  message: t(AppraisalCostsTranslations.AppraisalCosts_PleaseFillIn)
                }}
              />
            </div>
            <NumberInput
              id={
                "createCost-" +
                rootStore.currentCostStore.cost.type +
                "-" +
                rootStore.currentCostStore.cost._build_phase
              }
              value={rootStore.currentCostStore.cost}
              prefix="£"
              label={t(AppraisalCostsTranslations.AppraisalCosts_CostValue)}
              onChange={(value) => rootStore.currentCostStore.updateCost(value as ClientCost)}
              showActiveOption={false}
              error={{
                display: rootStore.currentCostStore.validator.state["value"] === false,
                message: t(AppraisalCostsTranslations.AppraisalCosts_ValueMustBeGreaterThanZero)
              }}
              calculations={rootStore.currentCostStore.calculationOptions}
              showFalsyDefaultValue
            />
          </div>
        </Modal>
      )}
    </>
  );
});

export { CostModal };
