import { makeAutoObservable } from "mobx";
import { ClientBuildPhase } from "@shared/types/buildPhase";
import { formatAmount } from "@shared/utils/formatting";
import { RootStore } from "../Root";
import {
  addBuildPhase,
  deleteBuildPhase,
  updateBuildPhase
} from "../../apollo/mutations/BuildPhase";
import { debounce, orderBy } from "lodash";
import {
  convertBuildPhaseFromMetric,
  convertBuildPhaseUpdateToMetric
} from "./BuildPhaseStoreUtil";
import { ComputableCalculationType } from "@shared/types/computable";
import { ClientUnitGroup } from "@shared/types/unitGroup";
import { createDefaultBuildPhase, rebuildAutoBuildPhase } from "@/react/utils/build_phase";

export class BuildPhaseStore {
  readonly root: RootStore;

  buildPhases: ClientBuildPhase[] = [];
  buildPhaseUpdateQueue: ClientBuildPhase[] = [];

  debounceBuildPhaseUpdates = debounce(() => this.updateApollo(), 400);

  constructor(rootStore: RootStore) {
    this.root = rootStore;

    makeAutoObservable(this, { root: false });
  }

  setupBuildPhases(buildPhases?: ClientBuildPhase[]) {
    this.buildPhases = buildPhases ?? [];
  }

  async addBuildPhase() {
    const currentAppraisal = this.root.getCurrentAppraisal();
    // Need to move this dependency to the backend
    try {
      const buildPhase = createDefaultBuildPhase(this.buildPhases.length);
      this.buildPhases.push(buildPhase);
      await addBuildPhase(this.root.appraisalStore.appraisalId, buildPhase);
      await this.root.cashflowStore.createLineItemFromBuildPhase(buildPhase);
    } catch {
      this.setupBuildPhases(currentAppraisal.buildPhases);
    }
  }

  updateBuildPhase(
    buildPhaseId: ClientBuildPhase["_id"],
    buildPhaseUpdate: Partial<ClientBuildPhase>,
    unitsRemoved: boolean = false
  ) {
    const index = this.buildPhases.findIndex((bp) => bp._id === buildPhaseId);
    if (index === -1) {
      throw new Error("buildPhase not found");
    }

    buildPhaseUpdate = convertBuildPhaseUpdateToMetric(
      buildPhaseUpdate,
      this.root.userStore.areaUnit
    );

    let updatedBuildPhase = { ...this.buildPhases[index], ...buildPhaseUpdate };

    if ("assignedUnitGroups" in buildPhaseUpdate || unitsRemoved) {
      updatedBuildPhase = rebuildAutoBuildPhase(
        updatedBuildPhase,
        this.root.unitGroupStore.unitGroups
      );
    }

    if (
      "assignedGIA" in buildPhaseUpdate ||
      "assignedUnitGroups" in buildPhaseUpdate ||
      unitsRemoved
    ) {
      this.root.costStore.recalculateCosts(
        ComputableCalculationType.PER_AREA_UNIT,
        updatedBuildPhase
      );
    }

    if (
      "numberOfUnits" in buildPhaseUpdate ||
      "assignedUnitGroups" in buildPhaseUpdate ||
      unitsRemoved
    ) {
      this.root.costStore.recalculateCosts(
        ComputableCalculationType.PER_BUILD_PHASE_UNIT,
        updatedBuildPhase
      );
    }

    this.buildPhases.splice(index, 1, updatedBuildPhase);

    this.addToBuildPhaseUpdateQueue(updatedBuildPhase);
    this.debounceBuildPhaseUpdates();
  }

  addToBuildPhaseUpdateQueue(buildPhase: ClientBuildPhase) {
    const index = this.buildPhaseUpdateQueue.findIndex(
      (buildPhaseUpdate) => buildPhaseUpdate._id === buildPhase._id
    );
    if (index > -1) {
      this.buildPhaseUpdateQueue[index] = buildPhase;
    } else {
      this.buildPhaseUpdateQueue.push(buildPhase);
    }
  }

  async updateApollo() {
    const currentAppraisal = this.root.getCurrentAppraisal();

    try {
      await Promise.all(
        this.buildPhaseUpdateQueue.map((buildPhase) => {
          updateBuildPhase(this.root.appraisalStore.appraisalId, buildPhase);
        })
      );
      this.buildPhaseUpdateQueue = [];
    } catch {
      this.setupBuildPhases(currentAppraisal.buildPhases);
    }
  }

  async deleteBuildPhase(deletedId: string) {
    const currentAppraisal = this.root.getCurrentAppraisal();
    try {
      this.buildPhases = this.buildPhases.filter((buildPhase) => buildPhase._id !== deletedId);
      await deleteBuildPhase(this.root.appraisalStore.appraisalId, deletedId);
      this.root.costStore.deleteCostsForBuildPhaseLocally(deletedId);
      // Need to move some of these dependencies to the backend
      await this.root.cashflowStore.deleteLineItem(deletedId);
    } catch {
      this.setupBuildPhases(currentAppraisal.buildPhases);
    }
  }

  rebuildBuildPhaseAfterUnitGroupChange(unitGroupId: ClientUnitGroup["_id"]) {
    this.buildPhases.forEach((buildPhase) => {
      if (buildPhase.assignedUnitGroups.includes(unitGroupId)) {
        this.updateBuildPhase(buildPhase._id, {}, true);
      }
    });
  }

  removeAssignedUnitGroupFromBuildPhase(unitGroupId: ClientUnitGroup["_id"]) {
    this.buildPhases.forEach((buildPhase) => {
      if (buildPhase.assignedUnitGroups.includes(unitGroupId)) {
        const assignedUnitGroups = buildPhase.assignedUnitGroups.filter((id) => id !== unitGroupId);
        this.updateBuildPhase(buildPhase._id, { assignedUnitGroups });
      }
    });
  }

  reorderBuildPhases(reorderedBuildPhases: ClientBuildPhase[]) {
    reorderedBuildPhases.forEach((buildPhase, index) => {
      const position = index;
      if (buildPhase.position !== position) {
        this.updateBuildPhase(buildPhase._id, {
          position
        });
      }
    });
  }

  get convertedBuildPhases() {
    const convert = (buildPhase: ClientBuildPhase) =>
      convertBuildPhaseFromMetric(buildPhase, this.root.userStore.areaUnit);
    return orderBy(this.buildPhases.map(convert), "position");
  }

  get totalBuildCostWithContingency(): number {
    const contingencyMultiplier = this.root.appraisalStore.contingency
      ? (this.root.appraisalStore.contingency + 100) / 100
      : 1;
    return this.root.costStore.totalBuildCostWithoutContingency * contingencyMultiplier;
  }

  get totalBuildContingency(): number {
    return (
      this.totalBuildCostWithContingency - this.root.costStore.totalBuildCostWithoutContingency
    );
  }

  get formattedTotalBuildContingency(): string {
    return formatAmount(this.totalBuildContingency);
  }

  get buildCostWithContingencyPerAreaUnit(): number {
    return this.root.unitGroupStore.convertedTotalGIA
      ? Math.round(this.totalBuildCostWithContingency / this.root.unitGroupStore.convertedTotalGIA)
      : 0;
  }

  get buildCostWithoutContingencyPerAreaUnit(): number {
    return this.root.unitGroupStore.convertedTotalGIA
      ? Math.round(
          this.root.costStore.totalBuildCostWithoutContingency /
            this.root.unitGroupStore.convertedTotalGIA
        )
      : 0;
  }
}
