import { BvModal } from "bootstrap-vue";
export default {
  async showDeleteDialog(bvModal: BvModal, message: string, onConfirm: any, onCancel: any) {
    const value = await bvModal.msgBoxConfirm(message, {
      title: "Please Confirm",
      okTitle: "Delete",
      okVariant: "danger",
      cancelTitle: "Cancel",
      centered: true
    });
    if (value) {
      onConfirm();
    } else {
      onCancel();
    }
  }
};
