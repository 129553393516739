import { FundingPageTranslations, FundingPageList, FundingPageTabs } from "../enums";

export const FundingPageMessages = {
  [FundingPageTranslations.GetStarted]: "Get started",
  [FundingPageTranslations.Company]: "Company",
  [FundingPageTranslations.Name]: "Name",
  [FundingPageTranslations.PhoneNumber]: "Phone number",
  [FundingPageTranslations.Email]: "Email address",
  [FundingPageTranslations.RequestCallback]: "Request a callback",
  [FundingPageTranslations.TermsAndConditions]: `
    By clicking this button you agree to share your personal details with us. You may
    request an amendment to your data or a request of deletion which will be complied with
    within 30 days. For more information, see our
    <a
      href="https://land.tech/legal/#privacy-policy"
      target="_blank"
      class="atlas-text-white atlas-text-center atlas-underline hover:atlas-text-neutral-200"
    >
      Privacy Policy.
    </a>
  `,
  [FundingPageTranslations.ContactDirectly]: "You can also contact us directly",
  [FundingPageTranslations.BlankInput]: "Please fill in",
  [FundingPageTranslations.EmailInvalid]: "Invalid email address",
  [FundingPageTranslations.NumberInvalid]: "Please enter a valid number",
  [FundingPageTranslations.FormSubmitted_ThankYou]:
    "Thank you for your details, one of our funding experts will contact you shortly!",
  [FundingPageTranslations.FundingOptions]: "Discover funding options",
  [FundingPageTranslations.LeaveDetails]: "Leave your contact details and we`ll get back to you",
  [FundingPageTranslations.FundingPage_FindRightLender]: "Find the right lender with us",
  [FundingPageTabs.FundingPage_SeniorDebt]: "Senior Debt",
  [FundingPageTabs.FundingPage_SeniorDebt_1]: "Rates from 7.25%",
  [FundingPageTabs.FundingPage_SeniorDebt_2]: "Up to 70% LTGDV",
  [FundingPageTabs.FundingPage_SeniorDebt_3]: "Up to 90% LTC",
  [FundingPageTabs.FundingPage_Mezzanine]: "Mezzanine",
  [FundingPageTabs.FundingPage_Equity]: "Equity",
  [FundingPageTabs.FundingPage_MezzRates]: "Rates from 15% PA",
  [FundingPageTabs.FundingPage_UpTo80]: "Up to 80% LTGDV",
  [FundingPageTabs.FundingPage_UpTo98]: "Up to 98% LTC",
  [FundingPageTabs.FundingPage_UpTo100]: "Up to 100% LTC",
  [FundingPageTabs.FundingPage_Profit]: "Profit shares typically 60/40 in developer's favour",
  [FundingPageTabs.FundingPage_Bridging]: "Bridging",
  [FundingPageTabs.FundingPage_Bridging_Rates]: "Rates from 0.55% PM",
  [FundingPageTabs.FundingPage_Bridging_LTV]: "80% LTV available",
  [FundingPageTabs.FundingPage_Bridging_NET]:
    "75% NET toward purchase + 100% of build costs for refurbs",
  [FundingPageList.FundingPage_HowItWork]: "How does it work?",
  [FundingPageList.FundingPage_Interested]:
    "Let us know you're interested by sending us your contact details via the form",
  [FundingPageTranslations.FundingPage_TakeALook]: "Take a look at the funding options we offer:",
  [FundingPageList.FundingPage_Callback]:
    "We will give you a call back to go through the project in greater detail to understand what you are looking for",
  [FundingPageList.FundingPage_Match]: "We will match you with the best rates possible"
};
