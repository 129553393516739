import React from "react";
import { TableSection } from "../CashflowTableSection";
import { rootStore } from "@/react/lib/persistence/root_store";
import { LineItemRow } from "../../LineItemRow";
import { observer } from "mobx-react-lite";
import { FinanceRow } from "../../FinanceRow";
import { CashflowTableTranslations } from "@/react/lib/i18n/locales";
import { useTranslation } from "@/react/lib/hooks";

export const OtherCostsSection = observer(() => {
  const { t } = useTranslation();
  const cashflowStore = rootStore.cashflowStore;
  const costStore = rootStore.costStore;
  return (
    <TableSection title="Other Costs" testId="other-costs-section">
      {cashflowStore.otherCostLineItems.map((val) => (
        <LineItemRow lineItem={val} key={val._id} testId="other-cost-line-item" />
      ))}
      <FinanceRow
        testId="other-costs-totals"
        totalRow
        title={t(CashflowTableTranslations.CashflowTable_Total)}
        valueCell={costStore.totalOtherCosts}
        monthCells={cashflowStore.otherCostsColumnTotals}
        bottomBorder
      />
    </TableSection>
  );
});
