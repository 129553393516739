import React, { Fragment } from "react";
import { TableSection } from "../CashflowTableSection";
import { rootStore } from "@/react/lib/persistence/root_store";
import { observer } from "mobx-react-lite";
import { FinanceRow } from "../../FinanceRow";
import { useTranslation } from "@/react/lib/hooks";
import { CashflowTableTranslations } from "@/react/lib/i18n/locales";

export const FinanceSection = observer(() => {
  const cashflowStore = rootStore.cashflowStore;
  const loanStore = rootStore.loanStore;
  const cashflowFinanceStore = rootStore.cashflowFinanceStore;
  const equityFundingStore = rootStore.equityFundingStore;
  const { t } = useTranslation();
  return (
    <TableSection title="Finance" financeSection testId="finance-section">
      {cashflowFinanceStore.financeOutputs.loanOutputs.map((loanOutput) => {
        return (
          <Fragment key={`${loanOutput.loan._id}`}>
            <FinanceRow
              testId="loan-title-row"
              totalRow
              title={loanOutput.loan.name}
              valueCell={loanOutput.loan.amount.value}
              monthCells={Array(cashflowStore.numMonths + 2).fill(null)}
            />
            <FinanceRow
              testId="loan-balance-row"
              title={t(CashflowTableTranslations.CashflowTable_Balance)}
              monthCells={loanOutput.balances}
            />
            <FinanceRow
              testId="interest-accrued-row"
              title={t(CashflowTableTranslations.CashflowTable_InterestAccrued)}
              valueCell={loanOutput.totalInterest}
              monthCells={loanOutput.interestAccrued}
            />
            <FinanceRow
              testId="interest-paid-row"
              title={t(CashflowTableTranslations.CashflowTable_InterestPaid)}
              monthCells={loanOutput.interestPaid}
            />
            <FinanceRow
              testId="fees-accrued-row"
              title={t(CashflowTableTranslations.CashflowTable_FeesAccrued)}
              valueCell={loanOutput.feesAccrued.reduce((tot, v) => tot + v, 0)}
              monthCells={loanOutput.feesAccrued}
            />
            <FinanceRow
              testId="fees-paid-row"
              title={t(CashflowTableTranslations.CashflowTable_FeesPaid)}
              monthCells={loanOutput.feesPaid}
            />
          </Fragment>
        );
      })}
      {cashflowFinanceStore.financeOutputs.equityOutputs.map((equityOutput) => (
        <Fragment key={`${equityOutput.equity._id}`}>
          <FinanceRow
            testId="equity-title-row"
            totalRow
            title={equityOutput.equity.description}
            valueCell={equityOutput.equity.totalAmount}
            monthCells={Array(cashflowStore.numMonths + 2).fill(null)}
          />
          <FinanceRow
            testId="equity-balance-row"
            title={t(CashflowTableTranslations.CashflowTable_Balance)}
            monthCells={equityOutput.balances}
          />
          <FinanceRow
            testId="equity-interest-paid-row"
            title={`${t(CashflowTableTranslations.CashflowTable_InterestPaidRate)} ${
              equityOutput.equity.couponRate
            }%)`}
            monthCells={equityOutput.interestPaid}
          />
          <FinanceRow
            testId="pps-row"
            title={`${t(CashflowTableTranslations.CashflowTable_PPS)}${
              equityOutput.equity.providerProfitShare
            }%)`}
            monthCells={equityOutput.providerProfitShare}
          />
        </Fragment>
      ))}
      <FinanceRow
        testId="total-row"
        title={t(CashflowTableTranslations.CashflowTable_DeveloperEquity)}
        totalRow
        bottomBorder={!loanStore.hasServicedInterest}
        valueCell={equityFundingStore.developerEquity}
        monthCells={cashflowFinanceStore.financeOutputs.developerEquity}
      />
      {loanStore.hasServicedInterest && (
        <FinanceRow
          testId="serviced-interest-totals"
          title={t(CashflowTableTranslations.CashflowTable_LessServicedInterest)}
          totalRow
          bottomBorder
          monthCells={[
            ...Array(cashflowStore.numMonths - 1).fill(null),
            cashflowStore.monthlyNets.length
              ? cashflowFinanceStore.finalEquityMinusServicedInterest
              : null
          ]}
        />
      )}
    </TableSection>
  );
});
