import { MINI_SITES } from "@shared/apollo/queries";
import { landInsightApolloClient } from "../clients";
import gql from "graphql-tag";
import { logError } from "@/react/utils";
import { ClientMiniDevelopment } from "@shared/types/development";
import { ClientMiniSite } from "@shared/types/site";

export const getMiniSites = async (
  ids: Array<ClientMiniDevelopment["_id"]>
): Promise<ClientMiniSite[]> => {
  const { data, errors } = await landInsightApolloClient.query({
    query: gql`
      ${MINI_SITES}
    `,
    variables: { ids }
  });
  if (errors) {
    for (let err of errors) {
      logError(err.message);
    }
    throw new Error(`Error retrieving mini sites`);
  }
  return data.sitesByIds;
};
