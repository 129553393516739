import { Icon, Tooltip, TooltipThemes } from "@/react/components";
import { ClientLineItemExtended, LineItemType } from "@shared/types/cashflow";
import { formatNumber } from "@shared/utils/formatting";
import clsx from "clsx";
import React from "react";

export interface DescriptionCellProps {
  lineItem: ClientLineItemExtended;
}

export const DescriptionCell = ({ lineItem }: DescriptionCellProps) => {
  const isOverAssigned = lineItem.valueRemaining < 0;

  const isUnderAssigned = lineItem.valueRemaining > 0;

  const tooltipTheme = () => {
    if (isOverAssigned) {
      return TooltipThemes.DANGER;
    }
    if (isUnderAssigned) {
      return TooltipThemes.WARNING;
    }
    return TooltipThemes.DEFAULT;
  };

  const warningText = () => {
    const lineItemType = lineItem.type === LineItemType.UnitGroup ? "sales" : "cost";
    const formattedValue = formatNumber({ value: Math.abs(lineItem.valueRemaining) });
    if (isOverAssigned) {
      return `Assigned ${lineItemType} is £${formattedValue} above appraisal value`;
    } else if (isUnderAssigned) {
      return `Assigned ${lineItemType} is £${formattedValue} below appraisal value`;
    } else {
      return "";
    }
  };

  return (
    <Tooltip content={warningText()} asChild={true} theme={tooltipTheme()}>
      <div
        id={`description-${lineItem._id}`}
        data-testid="description-cell"
        className={clsx(
          "atlas-py-3 atlas-px-2 atlas-content-center atlas-flex atlas-flex-wrap",
          "atlas-flex-row atlas-items-center",
          "atlas-h-full",
          { "atlas-bg-red-50": isOverAssigned },
          { "atlas-bg-orange-50": isUnderAssigned }
        )}
      >
        <div className="atlas-flex-col atlas-flex-1 atlas-overflow-hidden atlas-overflow-ellipsis">
          {lineItem.description}
        </div>
        {(isOverAssigned || isUnderAssigned) && (
          <Icon.AlertLine
            data-testid={
              isOverAssigned ? "over-assigned-warning-icon" : "under-assigned-warning-icon"
            }
            className={clsx(
              "atlas-flex-none atlas-flex-col atlas-text-red-600 atlas-mt-0.5 atlas-flex-shrink-0 atlas-ml-auto",
              { "atlas-text-red-600": isOverAssigned },
              { "atlas-text-orange-600": isUnderAssigned }
            )}
          />
        )}
      </div>
    </Tooltip>
  );
};
