import { ClientBuildPhase } from "@shared/types/buildPhase";
import { ClientCost, ComputableCalculationType } from "@shared/types/computable";
import { CostType } from "@shared/types/costs";
import { Nullable } from "@shared/types/utils";
import { debounce, orderBy } from "lodash";
import { makeAutoObservable } from "mobx";
import {
  createCost,
  deleteCost as deleteCostMutation,
  updateCosts
} from "@/react/lib/persistence/apollo/mutations/Costs";
import { RootStore } from "../Root";
import {
  convertCostFromMetric,
  convertCostToMetric,
  noRecalculationNeededForCost
} from "./CostStoreUtil";

export class CostStore {
  readonly root: RootStore;

  metricCosts: ClientCost[] = [];
  costEditsToPersist: ClientCost[] = [];
  costToDeleteId: Nullable<ClientCost["_id"]> = null;

  debouncedCostsUpdate = debounce(() => {
    this.updateApollo();
  }, 200);

  constructor(rootStore: RootStore) {
    this.root = rootStore;

    makeAutoObservable(this, { root: false });
  }

  setupCosts(metricCosts?: ClientCost[]) {
    this.metricCosts = metricCosts ?? [];
  }
  setCostToDeleteId(id: ClientCost["_id"]) {
    this.costToDeleteId = id;
  }

  clearCostToDeleteId() {
    this.costToDeleteId = null;
  }

  get convertedCosts() {
    // Positions can't be trusted, there are a lot of costs in the db that all have position 0
    const convert = (cost: ClientCost) => convertCostFromMetric(cost, this.root.userStore.areaUnit);
    return orderBy(this.metricCosts?.map(convert) ?? [], "position");
  }

  async createCost(metricOrImperialCost: ClientCost) {
    const metricCost = convertCostToMetric(metricOrImperialCost, this.root.userStore.areaUnit);
    this.metricCosts.push(metricCost);
    await this.updateApollo(metricCost);

    if (metricCost) {
      if (metricCost.type !== CostType.Construction) {
        await this.root.cashflowStore.createLineItemFromCost(metricCost);
      } else {
        // As a buildPhase lineItem for this cost will have already been created,
        // we just need to check for re-automation
        await this.root.cashflowStore.checkForAutomation(metricCost);
      }
    }
  }

  updateCost(metricOrImperialCost: ClientCost) {
    const metricCost = convertCostToMetric(metricOrImperialCost, this.root.userStore.areaUnit);
    const index = this.metricCosts.findIndex((cost) => cost._id === metricCost._id);
    const deleteCount = index === -1 ? 0 : 1;
    this.metricCosts.splice(index, deleteCount, metricCost);
    this.trackEditForDebounce(metricCost);
    this.debouncedCostsUpdate();
  }

  async deleteCost() {
    const costToDelete = this.metricCosts.find((cost) => cost._id === this.costToDeleteId);

    if (!costToDelete) {
      throw new Error("Cannot find cost to delete");
    }

    const { costs } = this.root.getCurrentAppraisal();
    this.metricCosts = this.metricCosts.filter((c) => c._id !== this.costToDeleteId);

    try {
      await deleteCostMutation(this.root.appraisalStore.appraisalId, costToDelete);
      await this.root.cashflowStore.deleteLineItem(costToDelete._id);
    } catch {
      this.metricCosts = costs ?? [];
    }

    this.clearCostToDeleteId();
  }

  deleteCostsForBuildPhaseLocally(buildPhaseId: ClientBuildPhase["_id"]) {
    this.metricCosts = this.metricCosts.filter((c) => c._build_phase !== buildPhaseId);
  }

  async reOrderCosts(costs: ClientCost[]) {
    const updatedCosts: ClientCost[] = costs.map((cost, index) => {
      return cost.position !== index ? { ...cost, position: index } : cost;
    });
    this.metricCosts = this.metricCosts.map((cost) => {
      const reorderedIndex = updatedCosts.findIndex((reorderCost) => reorderCost._id === cost._id);
      if (reorderedIndex > -1) {
        const updatedCost = { ...cost, position: updatedCosts[reorderedIndex].position };

        this.trackEditForDebounce(updatedCost);
        return updatedCost;
      }
      return cost;
    });

    await this.updateApollo();
  }

  trackEditForDebounce(costUpdate: ClientCost) {
    const costIndex = this.costEditsToPersist.findIndex((cost) => cost._id === costUpdate._id);
    if (costIndex >= 0) {
      Object.assign(this.costEditsToPersist[costIndex], costUpdate);
    } else {
      this.costEditsToPersist.push(costUpdate);
    }
  }

  async updateApollo(metricCost?: ClientCost): Promise<void> {
    const { costs } = this.root.getCurrentAppraisal();
    try {
      if (metricCost) {
        await createCost(this.root.appraisalStore.appraisalId, metricCost);
      } else {
        await updateCosts(this.root.appraisalStore.appraisalId, this.costEditsToPersist);
        await Promise.all(
          this.costEditsToPersist.map((cost) => {
            this.root.cashflowStore.checkForAutomation(cost);
          })
        );
        this.costEditsToPersist = [];
      }
    } catch {
      this.metricCosts = costs ?? [];
    }
  }

  recalculateCosts(calculationType: ComputableCalculationType, buildPhase?: ClientBuildPhase) {
    let changeOccurred = false;

    const updatedCosts = this.metricCosts.map((cost) => {
      if (noRecalculationNeededForCost(cost, calculationType, buildPhase)) {
        return cost;
      }

      let update: Partial<ClientCost>;

      if (cost.calculate) {
        update = {
          value: buildPhase
            ? this.root.calculationsStore.calculateValueFromBaseAndBuildPhaseMetric[
                calculationType
              ]!(cost.calculationBase, buildPhase)
            : this.root.calculationsStore.calculateValueFromBaseMetric[calculationType]!(
                cost.calculationBase
              )
        };
      } else {
        update = {
          calculationBase: buildPhase
            ? this.root.calculationsStore.calculateBaseFromValueAndBuildPhaseMetric[
                calculationType
              ]!(cost.value, buildPhase)
            : this.root.calculationsStore.calculateBaseFromValueMetric[calculationType]!(cost.value)
        };
      }

      const newCost = {
        ...cost,
        ...update
      };

      this.costEditsToPersist.push(newCost);
      changeOccurred = true;
      return newCost;
    });

    if (changeOccurred) {
      this.metricCosts = updatedCosts;
      this.debouncedCostsUpdate();
    }
  }

  get costsForBuildPhase(): (buildPhaseId: ClientBuildPhase["_id"]) => ClientCost[] {
    const convertedCosts = this.convertedCosts;
    return (buildPhaseId: ClientBuildPhase["_id"]) =>
      convertedCosts.filter((cost) => cost._build_phase === buildPhaseId);
  }

  get buildPhaseTotalCost(): (buildPhaseId: ClientBuildPhase["_id"]) => number {
    const costsForBuildPhase = this.costsForBuildPhase;
    return (buildPhaseId: ClientBuildPhase["_id"]) =>
      costsForBuildPhase(buildPhaseId).reduce((sum, cost) => sum + cost.value, 0);
  }

  get costsByType(): (costType: CostType) => ClientCost[] {
    const convertedCosts = this.convertedCosts;
    return (costType: CostType) => convertedCosts.filter((cost) => cost.type === costType);
  }

  get landFees(): ClientCost[] {
    return this.convertedCosts.filter((cost: ClientCost) => cost.type === CostType.LandFee);
  }

  get totalLandFees(): number {
    return this.landFees.reduce((sum: number, cost: ClientCost) => sum + (cost.value || 0), 0);
  }

  get totalLandCosts(): number {
    return [
      this.root.appraisalStore.purchasePrice,
      this.stampDuty?.value ?? 0,
      this.totalLandFees
    ].reduce((sum: number, value) => sum + (value || 0), 0);
  }

  get professionalFees(): ClientCost[] {
    return this.convertedCosts.filter((cost) => cost.type === CostType.ProfessionalFee);
  }

  get totalProfessionalFees(): number {
    return this.professionalFees.reduce(
      (sum: number, cost: ClientCost) => sum + (cost.value || 0),
      0
    );
  }

  get otherCosts(): ClientCost[] {
    return this.convertedCosts.filter((cost) => cost.type === CostType.OtherCost);
  }

  get buildCosts(): ClientCost[] {
    return this.convertedCosts.filter((cost) => cost.type === CostType.Construction);
  }

  get stampDuty(): ClientCost | undefined {
    return this.convertedCosts.find((cost) => cost.type === CostType.StampDuty);
  }

  get deposit(): ClientCost | undefined {
    return this.root.costStore.metricCosts.find((cost) => cost.type === CostType.Deposit);
  }

  get totalBuildCostWithoutContingency(): number {
    return this.buildCosts.reduce((sum, buildCost) => sum + buildCost.value, 0);
  }

  get totalOtherCosts(): number {
    return this.otherCosts.reduce((sum: number, cost: ClientCost) => sum + (cost.value || 0), 0);
  }

  get customCosts(): ClientCost[] {
    return [...this.buildCosts, ...this.professionalFees, ...this.otherCosts, ...this.landFees];
  }

  get totalDevelopmentCost(): number {
    return [
      this.root.buildPhaseStore.totalBuildCostWithContingency,
      this.totalProfessionalFees,
      this.totalOtherCosts
    ].reduce((x, sum) => sum + x, 0);
  }

  get totalCostExFunding(): number {
    return [
      this.root.unitGroupStore.totalSalesFees,
      this.totalLandCosts,
      this.root.buildPhaseStore.totalBuildCostWithContingency,
      this.totalProfessionalFees,
      this.totalOtherCosts
    ].reduce((sum: number, x: number) => sum + (x || 0), 0);
  }

  get totalCostsWithContingency(): number {
    return [
      this.totalProfessionalFees,
      this.totalOtherCosts,
      this.totalLandCosts,
      this.root.unitGroupStore.totalSalesFees,
      this.root.loanStore.totalFundingCosts,
      this.root.equityFundingStore.totalEquityInterest,
      this.root.buildPhaseStore.totalBuildCostWithContingency
    ].reduce((sum: number, x: number) => sum + (x || 0), 0);
  }

  get totalFundableCost(): number {
    return [
      this.totalLandCosts,
      this.totalProfessionalFees,
      this.totalOtherCosts,
      this.root.buildPhaseStore.totalBuildCostWithContingency
    ].reduce((sum: number, x: number) => sum + (x || 0), 0);
  }

  get profit(): number {
    return this.root.unitGroupStore.totalSales - this.totalCostsWithContingency;
  }

  get profitOnGDV(): number {
    return (this.profit / this.root.unitGroupStore.totalSales) * 100;
  }

  get profitOnCost(): number {
    return (this.profit / this.totalCostsWithContingency) * 100;
  }

  get returnOnEquity(): number {
    return (
      (this.root.equityFundingStore.developerProfit /
        this.root.equityFundingStore.developerEquity) *
      100
    );
  }

  get totalCalculatedLandFees(): number {
    return [...this.professionalFees, ...this.otherCosts].reduce(
      (sum, x) =>
        sum + ((x.calculate && x.calculationType === "percentage-of-land" && x.value) || 0),
      0
    );
  }
}
