import React, { PropsWithChildren } from "react";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from "./components";
import { DropdownContext, DropdownProvider } from "./DropdownMenuProvider";

export type DropdownMenuProps = PropsWithChildren<DropdownMenuPrimitive.DropdownMenuProps>;

export function DropdownMenu({ children, ...rest }: DropdownMenuProps) {
  return (
    <DropdownProvider>
      <DropdownContext.Consumer>
        {({ toggleOpen }) => {
          return (
            <DropdownMenuPrimitive.Root onOpenChange={() => toggleOpen()} {...rest}>
              {children}
            </DropdownMenuPrimitive.Root>
          );
        }}
      </DropdownContext.Consumer>
    </DropdownProvider>
  );
}

DropdownMenu.Trigger = DropdownMenuTrigger;
DropdownMenu.Content = DropdownMenuContent;
DropdownMenu.Item = DropdownMenuItem;
DropdownMenu.RadioGroup = DropdownMenuPrimitive.RadioGroup;
DropdownMenu.Separator = DropdownMenuSeparator;

export default DropdownMenu;
