import React, { PropsWithChildren } from "react";
import * as TabsPrimitive from "@radix-ui/react-tabs";
import { TabsList, TabsContent, TabsTrigger } from "./components";
import { TabsProvider, TabsContext } from "@/react/components/Tabs/TabsProvider";

function Tabs({ children, ...rest }: PropsWithChildren<TabsPrimitive.TabsProps>) {
  return (
    <TabsProvider defaultValue={rest.defaultValue}>
      <TabsContext.Consumer>
        {() => {
          return <TabsPrimitive.Root {...rest}>{children}</TabsPrimitive.Root>;
        }}
      </TabsContext.Consumer>
    </TabsProvider>
  );
}

Tabs.Trigger = TabsTrigger;
Tabs.List = TabsList;
Tabs.Content = TabsContent;

export { Tabs };
