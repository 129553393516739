import {
  accountsServiceClient,
  landFundApolloClient
} from "@/react/lib/persistence/apollo/clients";
import { USER, USER_PREFERENCES } from "@shared/apollo/queries";
import { ClientUser, ClientUserPreferences } from "@shared/types/user";
import gql from "graphql-tag";

export const getUser = async (): Promise<ClientUser> => {
  const { data } = await accountsServiceClient.query({
    query: gql`
      ${USER}
    `
  });

  return data.user;
};

export const getUserPreferences = async (): Promise<ClientUserPreferences> => {
  const { data } = await landFundApolloClient.query({
    query: gql`
      ${USER_PREFERENCES}
    `
  });

  return data.userPreferences;
};
