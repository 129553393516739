import { inputStateNumber, inputStateString } from "@/utils/validation";
import { IBaseComputable } from "@shared/types/computable";
import { isEqual } from "lodash";
import { makeAutoObservable } from "mobx";

type StateName = string;
type StateValue = number | string | IBaseComputable;
type StateIsValid = false | null;
export interface ValidationOptions {
  name: StateName;
  type: "string" | "number" | "computable";
  minIncl?: boolean;
  maxIncl?: boolean;
  min?: number;
  max?: number;
  isOptional?: boolean;
  requiredBy?: StateName;
}
type ValidationFunction = (value: StateValue) => StateIsValid;

const maxValidator = (max: number, maxIncl = true, isOptional = false) => {
  return ((value: number) => {
    if (isOptional && isNaN(value)) {
      return null;
    }
    return inputStateNumber(value, max, maxIncl, false);
  }) as ValidationFunction;
};

const minValidator = (min: number, minIncl = false, isOptional = false) => {
  return ((value: number) => {
    if (isOptional && isNaN(value)) {
      return null;
    }
    return inputStateNumber(value, min, minIncl);
  }) as ValidationFunction;
};

const minMaxValidator = (
  min: number,
  max: number,
  minIncl = false,
  maxIncl = true,
  isOptional = false
) => {
  return ((value: number) =>
    (inputStateNumber(value, min, minIncl) === null &&
      inputStateNumber(value, max, maxIncl, false) === null) ||
    (isOptional && isNaN(value))
      ? null
      : false) as ValidationFunction;
};

/**
 * This class is a helper class to try and extract away some of the boiler plate we have with validation.
 * You construct it with some ValidationOptions, that tells it the type of value that's being validated, its name in its parent's object,
 * and what are the constraints of it being valid. The Validator class then stores this in two mappings, state maps the Validator's
 * current "validity" and validations to map the functions that determine whether a state's value is valid.
 * It's hard not to think of V for Vendetta with all of these Vs. (V)
 */
export class Validator {
  public state: Record<StateName, StateIsValid> = {};
  private validations: Record<StateName, ValidationFunction> = {};
  private validatorOptions: ValidationOptions[] = [];
  constructor(validationOptions: ValidationOptions[]) {
    makeAutoObservable(this);
    // Iterate through all of the validation options and create state and validation functions for them
    this.buildValidations(validationOptions);
  }

  /**
   * Validates an object against the validators stored and updates the state accordingly.
   * If any keys aren't being validated by this validator it will only validate what it's tracking.
   * @param toValidate any object, preferably a partial of the object this validator is for
   */
  validate(toValidate: Record<StateName, any>) {
    for (const [stateName, stateValue] of Object.entries(toValidate)) {
      if (!(stateName in this.state)) {
        continue;
      }
      this.state[stateName] = this.validations[stateName](stateValue);
    }
  }

  /**
   * Validates an object against the validators stored and returns if all is valid without affecting the validator state.
   * If any keys aren't being validated by this validator it will only validate what it's tracking.
   * @param toValidate any object, preferably a partial of the object this validator is for
   * @returns a boolean
   */
  willBeAllValid(toValidate: Record<StateName, any>): boolean {
    for (const [stateName, stateValue] of Object.entries(toValidate)) {
      if (!(stateName in this.state)) {
        continue;
      }

      if (this.validations[stateName](stateValue) !== null) {
        return false;
      }
    }
    return true;
  }

  resetAll() {
    for (const stateName of Object.keys(this.state)) {
      this.state[stateName] = null;
    }
  }
  /**
   * Rebuild the validation options.
   * Will only update if the new options do not match the old
   * @param validationOptions: ValidationOptions[]
   */
  buildValidations(validationOptions: ValidationOptions[]) {
    if (!isEqual(this.validatorOptions, validationOptions)) {
      this.validatorOptions = validationOptions;

      this.state = {};
      validationOptions.forEach((validationOption) => {
        this.state[validationOption.name] = null;

        // Strings
        if (validationOption.type === "string") {
          this.validations[validationOption.name] = inputStateString as ValidationFunction;
          return;
        }

        // Numbers and Computables
        if (validationOption.min !== undefined && validationOption.max !== undefined) {
          this.validations[validationOption.name] = minMaxValidator(
            validationOption.min,
            validationOption.max,
            validationOption.minIncl,
            validationOption.maxIncl,
            validationOption.isOptional
          );
        } else if (validationOption.min !== undefined) {
          this.validations[validationOption.name] = minValidator(
            validationOption.min,
            validationOption.minIncl,
            validationOption.isOptional
          );
        } else if (validationOption.max !== undefined) {
          this.validations[validationOption.name] = maxValidator(
            validationOption.max,
            validationOption.maxIncl,
            validationOption.isOptional
          );
        } else {
          this.validations[validationOption.name] = () => null;
        }

        // If it's a computable create a wrapper function to extract the value from the computable and feed
        // it into the validator set above
        if (validationOption.type === "computable") {
          const existingFunction = this.validations[validationOption.name];
          this.validations[validationOption.name] = ((computable: IBaseComputable) =>
            existingFunction(computable.value)) as ValidationFunction;
        }
      });
    }
  }

  get allValid() {
    for (const stateName of Object.keys(this.state)) {
      if (this.state[stateName] !== null) {
        return false;
      }
    }
    return true;
  }
}
