import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import { Alert, AlertType, Icon } from "@/react/components";
import { useTranslation } from "@/react/lib/hooks";
import { CILMessaging } from "@/react/lib/i18n/locales";

const UnitSettingWarningObservable: FC = observer(() => {
  const { t } = useTranslation();
  return (
    <div data-testid="unit-setting-warning" className="atlas-w-3/4 atlas-mx-auto atlas-my-6">
      <Alert type={AlertType.Warning}>
        <div className="atlas-flex">
          <Icon.AlertLine data-testid="warning-icon" className="atlas-w-12 atlas-mr-4" />
          <span data-testid="warning-message" className="atlas-text-xs">
            {t(CILMessaging.Warning_Text)}
          </span>
        </div>
      </Alert>
    </div>
  );
});

export const UnitSettingWarning: FC = () => <UnitSettingWarningObservable />;
