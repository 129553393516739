"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatAmountWithBrackets = exports.formatPercentage = exports.formatSmallArea = exports.formatAmount = exports.formatNumber = exports.pluralise = void 0;
const area_1 = require("./area");
const pluralise = (word, n) => `${n} ${word}${n === 1 ? "" : "s"}`;
exports.pluralise = pluralise;
const formatNumber = ({ value = 0, maxDecimals = 0, prefix = "", suffix = "" }) => {
    let formattedValue;
    if (value === null || value === undefined || isNaN(value)) {
        formattedValue = "";
    }
    else {
        formattedValue = parseFloat(value.toFixed(maxDecimals))
            .toString()
            .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    }
    return [prefix, formattedValue, suffix].filter(Boolean).join(" ");
};
exports.formatNumber = formatNumber;
const formatAmount = (amount, maxDecimals = 0) => {
    return (0, exports.formatNumber)({ maxDecimals, value: amount, prefix: "£" });
};
exports.formatAmount = formatAmount;
const formatSmallArea = function (area, areaUnit) {
    return (0, exports.formatNumber)({
        value: area_1.areaUtil.convertSmallArea(area, areaUnit),
        suffix: area_1.areaUtil.getSmallAreaString(areaUnit)
    });
};
exports.formatSmallArea = formatSmallArea;
const formatPercentage = (value) => {
    return (0, exports.formatNumber)({ value, maxDecimals: 2, suffix: "%" });
};
exports.formatPercentage = formatPercentage;
const formatAmountWithBrackets = (value) => {
    if (value < 0) {
        return `(${(0, exports.formatAmount)(value * -1)})`;
    }
    else {
        return (0, exports.formatAmount)(value);
    }
};
exports.formatAmountWithBrackets = formatAmountWithBrackets;
