import React, { Dispatch, FC, useMemo } from "react";
import { Section } from "@/react/components/Section/Section";
import { useTranslation } from "@/react/lib/hooks";
import { ResidualLandValueTranslations } from "@/react/lib/i18n/locales/en-GB";
import { Select } from "@/react/components/Forms/Selects/Select";
import { NumberOutput } from "@/react/components/Outputs";
import { ResidualLandCalculation, RESIDUAL_LAND_CALCULATIONS } from "@shared/types/appraisal";
import { OptionsType } from "@/react/components/Forms/Selects/Select/components/CustomSelect";
import { ResidualLandValueStore } from "@/react/lib/persistence/root_store/ResidualLandValue";
import { DebouncedInput } from "@/react/components/Forms/DebouncedInput/DebouncedInput";

type ResidualLandValueProps = Pick<
  ResidualLandValueStore,
  | "residualLandTarget"
  | "hasResidualError"
  | "errorOutput"
  | "residualPurchasePrice"
  | "targetPrefix"
  | "targetSuffix"
  | "residualLandCalculation"
> & {
  setResidualLandTarget: Dispatch<number | undefined>;
  setResidualLandCalculation: Dispatch<ResidualLandCalculation>;
};

const ResidualLandValuePresentation: FC<ResidualLandValueProps> = ({
  residualLandTarget,
  setResidualLandTarget,
  hasResidualError,
  errorOutput,
  residualPurchasePrice,
  setResidualLandCalculation,
  targetPrefix,
  targetSuffix,
  residualLandCalculation
}) => {
  const { t } = useTranslation();

  const residualLandValueMetricOptions: OptionsType = useMemo(
    () =>
      RESIDUAL_LAND_CALCULATIONS.map((item) => ({
        option: item as string,
        label: `${item} (${
          item.toString() === t(ResidualLandValueTranslations.ResidualLandValue_TotalProfit)
            ? "£"
            : "%"
        })`
      })),
    []
  );

  return (
    <Section
      className="atlas-w-100 atlas-flex atlas-flex-row atlas-border-solid atlas-border atlas-border-neutral-400 atlas-mb-6"
      data-intercom-target="residual_land_value"
    >
      <Section.Header className={"atlas-border-bottom-section"}>
        <h3 className="atlas-text-2xl atlas-my-0 atlas-font-extrabold">
          {t(ResidualLandValueTranslations.ResidualLandValue_ResidualLandValue)}
        </h3>
      </Section.Header>
      <Section.Body className="atlas-m-0 atlas-p-0 atlas-flex atlas-flex-col">
        <NumberOutput
          className={"atlas-total"}
          label={t(ResidualLandValueTranslations.ResidualLandValue_ResidualPurchasePrice)}
          value={residualPurchasePrice}
          hasError={hasResidualError}
          errorOutput={errorOutput}
          errorTooltip={t(ResidualLandValueTranslations.ResidualLandValue_ResidualLandValueError)}
          prefix="£"
        />
        <div className={"atlas-flex atlas-flex-row atlas-justify-between"}>
          <div className={"atlas-w-1/2"} data-testid="metric-options-select">
            <Select
              className={"atlas-w-full"}
              label={t(ResidualLandValueTranslations.ResidualLandValue_Metric)}
              id="metric"
              selectClassName="atlas-bg-white"
              onChange={(e) => setResidualLandCalculation(e as ResidualLandCalculation)}
              value={residualLandCalculation as string}
              options={residualLandValueMetricOptions}
            />
          </div>
          <div className={"atlas-w-1/2 atlas-ml-5"}>
            <DebouncedInput
              label={t(ResidualLandValueTranslations.ResidualLandValue_Target)}
              value={residualLandTarget}
              onChange={(val) => setResidualLandTarget(val)}
              id="target"
              prefix={targetPrefix}
              suffix={targetSuffix}
            />
          </div>
        </div>
      </Section.Body>
    </Section>
  );
};

export { ResidualLandValuePresentation };
