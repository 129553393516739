import { ClientMiniDevelopment } from "@shared/types/development";
import { makeAutoObservable } from "mobx";
import {
  updateDevelopment as updateDevelopmentApollo,
  getSite as getSiteApollo,
  getDevelopment as getDevelopmentApollo
} from "../../apollo";
import { ClientSite } from "@shared/types/site";
import { parseUkAddress } from "@/react/utils/addressParser";
import { validatePostcode } from "@shared/utils/validatePostcode";
import { RootStore } from "../Root";
import { debounce } from "lodash";
import MapUtil from "@/map/utils";
import centroid from "@turf/centroid";
import area from "@turf/area";
import { convertBigArea, getSiteGeoJSON } from "./CurrentDevelopmentStoreUtil";
import bbox from "@turf/bbox";
import { getBoundsZoomLevel } from "@shared/utils/bounds_zoom_level";
import { Nullable } from "@shared/types/utils";

export class CurrentDevelopmentStore {
  readonly root: RootStore;
  developmentUpdate: Partial<ClientMiniDevelopment> = {};
  debounceDevelopmentUpdate = debounce(() => this.updateDevelopment(), 600);
  postcodeValid: boolean | null = null;
  postcodePartial: boolean | undefined;
  development: ClientMiniDevelopment | null = null;
  site: ClientSite | null = null;
  isSiteLoading: boolean = false;
  isDevelopmentLoading: boolean = false;

  constructor(rootStore: RootStore) {
    this.root = rootStore;
    makeAutoObservable(this, { root: false });
  }

  // If the user has been via the developmentsPage then this store will have already been setup using the developmentsStore.
  // However if the user goes direct to the appraisal page then this won't have happened, therefore we will have to retrieve it.
  async setupCurrentDevelopmentStore(developmentId: ClientMiniDevelopment["_id"]) {
    this.startLoadingDevelopment();
    if (!this.development) {
      const development = await getDevelopmentApollo(developmentId);
      this.setDevelopment(development);
    }
    this.finishLoadingDevelopment();
  }

  async setDevelopment(value: ClientMiniDevelopment | null) {
    this.development = value;
    if (this.development?._site) {
      this.getLinkedSiteData(this.development?._site);
    }
    if (this.development?.postcode) {
      this.updatePostcode(this.development.postcode);
    }
    if (value) {
      await this.root.miniAppraisalStore.setupMiniAppraisalStore(value._id);
    }
  }

  updatePostcode(postcode: string | undefined | null) {
    if (postcode) {
      const { status: isValid, partial } = validatePostcode(postcode);
      this.postcodeValid = isValid;
      this.postcodePartial = partial;

      if (isValid) {
        if (this.development && !this.sitePostcode) {
          this.development.postcode = postcode;
        }
      } else {
        this.postcodeValid = false;
        postcode = null;
      }
    } else {
      postcode = null;
      this.postcodeValid = null;
    }

    if (this.development && !this.sitePostcode) {
      this.setDevelopmentUpdate({ postcode });
    }
  }

  unlinkSite() {
    this.setSite(null);
    this.setDevelopmentUpdate({ _site: null });
    this.root.siteSuggestionStore.reset();
  }

  setDevelopmentUpdate(developmentUpdate: Partial<ClientMiniDevelopment>) {
    if (!this.development) {
      throw new Error("There's no development loaded in the currentDevelopment store!");
    }
    this.developmentUpdate = { ...this.developmentUpdate, ...developmentUpdate };
    this.development = {
      ...this.development,
      ...developmentUpdate
    };

    this.root.developmentsStore.updateDevelopmentLocally(this.development?._id, developmentUpdate);

    if (developmentUpdate) {
      this.debounceDevelopmentUpdate();
    }
    if (developmentUpdate._site) {
      this.getLinkedSiteData(developmentUpdate._site);
      this.root.developmentsStore.updateMiniSites();
    }
  }

  async updateDevelopment() {
    if (!this.development) {
      throw new Error("There is no current development!");
    }
    const developmentBackup = { ...this.development };
    try {
      await updateDevelopmentApollo(this.developmentUpdate, this.development._id);
    } catch {
      this.development = developmentBackup;
    }
    this.developmentUpdate = {};
  }

  async getLinkedSiteData(siteId: string) {
    this.site = null;
    this.startLoadingSite();
    try {
      const siteForCurrentDevelopment = await getSiteApollo(siteId);
      this.setSite(siteForCurrentDevelopment);
      this.root.siteConstraintStore.getSiteConstraints();
    } catch (e) {
      this.setSite(null);
      this.root.siteConstraintStore.setSiteConstraints(null);
    }
    this.finishLoadingSite();
  }

  setSite(value: ClientSite | null) {
    this.site = value;
  }

  startLoadingSite() {
    this.isSiteLoading = true;
  }

  finishLoadingSite() {
    this.isSiteLoading = false;
  }

  closeModal() {
    this.development = null;
    this.site = null;
    this.root.utilityStore.closeDevelopmentModal();
  }

  showDevelopment(development: ClientMiniDevelopment) {
    this.setDevelopment(development);
    this.root.utilityStore.openDevelopmentModal();
  }

  startLoadingDevelopment() {
    this.isDevelopmentLoading = true;
  }

  finishLoadingDevelopment() {
    this.isDevelopmentLoading = false;
  }

  get description() {
    return this.development?.description ?? "";
  }

  get notes() {
    return this.development?.notes ?? "";
  }

  get title() {
    return this.development?.title ?? "";
  }

  get developmentPostcode() {
    if (this.development) {
      const code = this.development.postcode;
      const { status: isValid } = validatePostcode(code);

      if (isValid) {
        return code;
      }
    }
    return null;
  }

  get sitePostcode() {
    if (this.site) {
      const { postcode } = parseUkAddress(this.site?.address);

      if (postcode) {
        return postcode;
      }
    }
    return null;
  }

  get currentPostcode() {
    if (this.sitePostcode) {
      return this.sitePostcode;
    }
    if (this.developmentPostcode) {
      return this.developmentPostcode;
    }
    return null;
  }

  get isDevelopmentLinkedToSite(): boolean {
    return !!this.development?._site;
  }

  get gMapsSiteBounds() {
    return this.site?.geometry && MapUtil.geoJsonPolyToGoogleBounds(this.site.geometry);
  }

  get sitesGeo() {
    return this.site && { features: [getSiteGeoJSON(this.site)] };
  }

  get siteCentroid() {
    if (this.site) {
      const centroidFeature = centroid(this.site.geometry);
      const [lng, lat] = centroidFeature.geometry.coordinates;
      return { lat, lng };
    } else {
      return null;
    }
  }

  get siteArea() {
    if (!this.site?.geometry) {
      return null;
    }
    let sqm = area(this.site.geometry);
    return convertBigArea(sqm, this.root.userStore.areaUnit);
  }

  get siteZoomLevel() {
    if (this.site?.geometry) {
      const boundingBox = bbox(this.site?.geometry);
      return getBoundsZoomLevel(boundingBox, this.root.siteConstraintStore.mapDimensions) - 1;
    } else {
      return 0;
    }
  }
}
