import { render, staticRenderFns } from "./BuildPhaseAccordionItem.vue?vue&type=template&id=e6c5aea6"
import script from "./BuildPhaseAccordionItem.vue?vue&type=script&lang=ts"
export * from "./BuildPhaseAccordionItem.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports