import { costDependsOnLand, costDependsOnStampDuty } from "@/react/utils";
import {
  ClientLineItem,
  ClientLineItemExtended,
  LineItemType,
  OverallBuildStage
} from "@shared/types/cashflow";
import { ClientCost } from "@shared/types/computable";
import { cloneDeep } from "lodash";
import {
  calculateCurve,
  columnTotal,
  contingencyForColumn,
  isAutomatedCurveType,
  lineItemMonthsNotSet,
  salesCostColTotal,
  getDefaultIndexes
} from "../Cashflow/CashflowStoreUtil";
import { UnitGroupStore } from "../UnitGroup";
import { CashflowStore } from "../Cashflow";

const lineItemTypesDependantOnPurchasePrice: LineItemType[] = [
  LineItemType.ProfessionalFee,
  LineItemType.OtherCost,
  LineItemType.Land
];

/**
 * This function goes through all the lineItems, checks which ones depend on purchase price, re-automates them
 * if needed and then returns what the monthly nets will look like given this automation.
 * @param purchasePrice
 * @param stampDuty
 * @returns monthlyNets
 */
export const automatedMonthlyNetsGivenPurchasePrice = (
  purchasePrice: number,
  stampDuty: number,
  allCosts: ClientCost[],
  unitGroupStore: UnitGroupStore,
  cashflowStore: CashflowStore,
  deposit?: ClientCost
): number[] => {
  const recalculatedLineItems = cashflowStore.extendedLineItems.map((lineItem) =>
    dependantOnPurchasePrice(lineItem, allCosts)
      ? reAutomateLineItemWithPurchasePrice(
          lineItem,
          purchasePrice,
          stampDuty,
          allCosts,
          cashflowStore.numMonths,
          cashflowStore.currentOverallBuildStage,
          deposit
        )
      : lineItem
  );

  return recalculateMonthlyNets(recalculatedLineItems, cashflowStore.numMonths, unitGroupStore);
};

/**
 * This function will return what an automated lineItem will look like with a given purchasePrice and stampDuty.
 * @param lineItem
 * @param purchasePrice
 * @param stampDuty
 * @returns ClientLineItemExtended
 */
const reAutomateLineItemWithPurchasePrice = (
  lineItem: ClientLineItemExtended,
  purchasePrice: number,
  stampDuty: number,
  allCosts: ClientCost[],
  numMonths: number,
  currentOverallBuildStage: OverallBuildStage,
  deposit?: ClientCost
): ClientLineItemExtended => {
  const cost = allCosts.find((c) => c._id === lineItem._linkedId);
  let lineItemClone = cloneDeep(lineItem);
  let newValue: number;
  if (cost) {
    if (costDependsOnLand(cost)) {
      newValue = (cost.calculationBase / 100) * purchasePrice;
    } else if (costDependsOnStampDuty(cost)) {
      newValue = stampDuty;
    } else {
      throw new Error("This lineItem should not be re-automated: wrong calculation type");
    }
  } else if (isNetPurchasePriceLineItem(lineItemClone)) {
    // Net Purchase Price doesn't have a linked cost
    newValue = calculateNetPurchasePrice(purchasePrice, deposit);
  } else {
    throw new Error("This lineItem should not be re-automated: wrong lineItem type");
  }
  if (lineItemMonthsNotSet(lineItemClone)) {
    lineItemClone = {
      ...lineItemClone,
      ...getDefaultIndexes(lineItem, currentOverallBuildStage)
    };
  }
  lineItemClone.cells = calculateCurve({ ...lineItemClone, value: newValue }, numMonths);

  return lineItemClone;
};

const dependantOnPurchasePrice = (
  lineItem: ClientLineItemExtended,
  allCosts: ClientCost[]
): boolean =>
  isAutomatedCurveType(lineItem) &&
  possiblyDependantOnPurchasePrice(lineItem) &&
  linkedCostDependantOnPurchasePrice(lineItem, allCosts);

const possiblyDependantOnPurchasePrice = (lineItem: ClientLineItem) =>
  lineItemTypesDependantOnPurchasePrice.includes(lineItem.type);

const linkedCostDependantOnPurchasePrice = (
  lineItem: ClientLineItemExtended,
  allCosts: ClientCost[]
) => {
  if (!possiblyDependantOnPurchasePrice(lineItem)) {
    throw new Error("This lineItem can't possibly be dependant on purchase price");
  }
  const cost = allCosts.find((c) => c._id === lineItem._linkedId);

  if (cost) {
    return costDependsOnLand(cost) || costDependsOnStampDuty(cost);
  } else {
    return isNetPurchasePriceLineItem(lineItem);
  }
};

const calculateNetPurchasePrice = (purchasePrice: number, deposit?: ClientCost): number => {
  let depositValue: number;
  if (deposit) {
    if (deposit.calculate) {
      depositValue = (deposit.calculationBase / 100) * purchasePrice;
    } else {
      depositValue = deposit.value;
    }
  } else {
    depositValue = 0;
  }

  return purchasePrice - depositValue;
};

const isNetPurchasePriceLineItem = (lineItem: ClientLineItemExtended) =>
  lineItem.type === LineItemType.Land && lineItem.description === "Net Purchase Price";

const recalculateMonthlyNets = (
  allLineItems: ClientLineItemExtended[],
  numMonths: number,
  unitGroupStore: UnitGroupStore
): number[] => {
  const professionalFeeLineItems = allLineItems.filter(
    (lineItem) => lineItem.type === LineItemType.ProfessionalFee
  );

  const otherCostLineItems = allLineItems.filter(
    (lineItem) => lineItem.type === LineItemType.OtherCost
  );

  const buildLineItems = allLineItems.filter(
    (lineItem) => lineItem.type === LineItemType.BuildPhase
  );

  const salesLineItems = allLineItems.filter(
    (lineItem) => lineItem.type === LineItemType.UnitGroup
  );

  const landLineItems = allLineItems.filter((lineItem) => lineItem.type === LineItemType.Land);

  const contingencyLineItem = allLineItems.find(
    (lineItem) => lineItem.type === LineItemType.Contingency
  );

  const salesGrossTotals = [...Array(numMonths).keys()].map((index) =>
    columnTotal(index, salesLineItems)
  );

  const salesCosts = [...Array(numMonths).keys()].map((index) =>
    salesCostColTotal(index, salesLineItems, unitGroupStore)
  );

  const professionalCosts = [...Array(numMonths).keys()].map((index) =>
    columnTotal(index, professionalFeeLineItems)
  );

  const otherCosts = [...Array(numMonths).keys()].map((index) =>
    columnTotal(index, otherCostLineItems)
  );

  const buildCosts = [...Array(numMonths).keys()].map(
    (index) => columnTotal(index, buildLineItems) + contingencyForColumn(index, contingencyLineItem)
  );

  const landCosts = [...Array(numMonths).keys()].map((index) => columnTotal(index, landLineItems));

  const totalCosts = [...Array(numMonths).keys()].map(
    (index) =>
      professionalCosts[index] +
      otherCosts[index] +
      buildCosts[index] +
      salesCosts[index] +
      landCosts[index]
  );

  return [...Array(numMonths).keys()].map((index) => salesGrossTotals[index] - totalCosts[index]);
};
