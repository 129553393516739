import { rootStore } from "@/react/lib/persistence/root_store";
import { UnitGroupExitType, UnitGroupType } from "@/utils/unit_groups";
import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import {
  AnnualRentInput,
  ExitTypeSelect,
  GrossSalesValueOutput,
  InputRow,
  InvestmentYieldInput,
  LegalFeesInput,
  MonthlyRentInput,
  OtherIncomeInput,
  PercentGIAInput,
  RentFreePeriodInput,
  SalesFeeInput,
  SalesValueInput,
  TotalNIAOutput,
  UnitAreaInput,
  UnitBedsCountInput,
  UnitCountInput,
  UnitGroupDescriptionInput,
  UnitTypeSelect,
  VoidPeriodInput
} from "./inputs";

const ResiSubForm: FC = observer(() => {
  if (rootStore.currentUnitGroupStore.exitType === UnitGroupExitType.SALE) {
    return (
      <>
        <SalesValueInput />
        <SalesFeeInput />
        <LegalFeesInput />
      </>
    );
  } else {
    return (
      <>
        <InputRow>
          <MonthlyRentInput />
          <InvestmentYieldInput />
        </InputRow>
        <InputRow>
          <GrossSalesValueOutput />
        </InputRow>
      </>
    );
  }
});

const EditCommercialSubForm: FC = observer(() => (
  <>
    <InputRow>
      <UnitAreaInput />
    </InputRow>
    <AnnualRentInput />
    <InputRow>
      <InvestmentYieldInput />
      <GrossSalesValueOutput />
    </InputRow>
    <InputRow>
      <VoidPeriodInput />
      <RentFreePeriodInput />
    </InputRow>
    <LegalFeesInput />
  </>
));

const UnitTypeSection: FC = observer(() => {
  switch (rootStore.currentUnitGroupStore.unitType) {
    case UnitGroupType.FLAT:
    case UnitGroupType.HOUSE:
      return (
        <>
          <InputRow>
            <UnitBedsCountInput />
            <UnitAreaInput />
          </InputRow>
          <InputRow>
            <PercentGIAInput />
            <TotalNIAOutput className="atlas-w-1/2" />
          </InputRow>
          <ExitTypeSelect />
          <ResiSubForm />
        </>
      );
    case UnitGroupType.COMMERCIAL_UNIT:
      return (
        <>
          <InputRow>
            <UnitAreaInput />
            <PercentGIAInput />
          </InputRow>
          <InputRow>
            <TotalNIAOutput className="atlas-w-full" />
          </InputRow>
          <AnnualRentInput />
          <InputRow>
            <InvestmentYieldInput />
            <GrossSalesValueOutput />
          </InputRow>
          <InputRow>
            <VoidPeriodInput />
            <RentFreePeriodInput />
          </InputRow>
          <LegalFeesInput />
        </>
      );
    case UnitGroupType.OTHER_INCOME:
      return <OtherIncomeInput />;
  }
});

export const AddUnitGroupForm: FC = observer(() => {
  return (
    <>
      <UnitGroupDescriptionInput />
      <InputRow>
        <UnitCountInput />
        <UnitTypeSelect />
      </InputRow>
      <UnitTypeSection />
    </>
  );
});

export const EditUnitGroupForm: FC = observer(() => (
  <>
    <UnitGroupDescriptionInput />
    {!rootStore.currentUnitGroupStore.isOtherIncomeUnit && (
      <InputRow>
        <PercentGIAInput />
      </InputRow>
    )}
  </>
));

export const EditUnitForm: FC = observer(() => {
  switch (rootStore.currentUnitGroupStore.unitType) {
    case UnitGroupType.HOUSE:
    case UnitGroupType.FLAT:
      return (
        <>
          <InputRow>
            <UnitBedsCountInput />
            <UnitAreaInput />
          </InputRow>
          <ResiSubForm />
        </>
      );
    case UnitGroupType.COMMERCIAL_UNIT:
      return <EditCommercialSubForm />;
    case UnitGroupType.OTHER_INCOME:
      return <OtherIncomeInput />;
  }
});

export const EditUnitsForm: FC = observer(() => {
  return (
    <>
      <p data-testid="units-selected-output">
        {rootStore.currentUnitGroupStore.unitIds.length} units selected
      </p>
      <EditUnitForm />
    </>
  );
});

export const AddUnitsForm: FC = observer(() => {
  switch (rootStore.currentUnitGroupStore.unitType) {
    case UnitGroupType.FLAT:
    case UnitGroupType.HOUSE:
      return (
        <>
          <InputRow>
            <UnitCountInput />
            <UnitBedsCountInput />
          </InputRow>
          <InputRow>
            <UnitAreaInput />
          </InputRow>
          <ResiSubForm />
        </>
      );
    case UnitGroupType.COMMERCIAL_UNIT:
      return (
        <>
          <InputRow>
            <UnitCountInput />
          </InputRow>
          <EditCommercialSubForm />
        </>
      );
    case UnitGroupType.OTHER_INCOME:
      return (
        <>
          <InputRow>
            <UnitCountInput />
          </InputRow>
          <OtherIncomeInput />
        </>
      );
  }
});
