import { ClientAppraisal } from "@shared/types/appraisal";
import { ComputableCalculationType } from "@shared/types/computable";
import { reaction } from "mobx";
import { sanitiseInput } from "@/react/lib/persistence/apollo";
import { getCachedAppraisal, getRemoteAppraisal } from "@/react/lib/persistence/apollo/queries";
import { cache } from "@/react/lib/persistence/apollo/clients";
import { AppraisalStore } from "../Appraisal";
import { AppraisalAnnotationStore } from "../AppraisalAnnotations";
import { BuildCostStore } from "../BuildCost";
import { BuildPhaseStore } from "../BuildPhase";
import { CalculationsStore } from "../Calculations";
import { CashflowStore } from "../Cashflow";
import { CashflowFinanceStore } from "../CashflowFinance";
import { CostStore } from "../Cost";
import { CurrentCostStore } from "../CurrentCost";
import { CurrentLineItemStore } from "../CurrentLineItem";
import { CurrentLoanStore } from "../CurrentLoan";
import { CurrentDevelopmentStore } from "../Development";
import { LoanStore } from "../Loan";
import { ResidualLandValueStore } from "../ResidualLandValue";
import { UnitGroupStore } from "../UnitGroup";
import { UserStore } from "../User";
import { EquityFundingStore } from "../EquityFunding/EquityFundingStore";
import { CurrentEquityFundingStore } from "../CurrentEquityFunding/CurrentEquityFundingStore";
import { DevelopmentsStore } from "../Developments/DevelopmentsStore";
import { CILStore } from "../CIL/CILStore";
import { CurrentUnitGroupStore } from "../CurrentUnitGroup";
import { TemplateStore } from "../Template/TemplateStore";
import { UtilityStore } from "../Utility/UtilityStore";
import { ReportStore } from "../Report/ReportStore";
import { MiniAppraisalStore } from "../MiniAppraisal/MiniAppraisalStore";
import { SiteConstraintStore } from "../SiteConstraint/SiteConstraintStore";
import { SiteSuggestionStore } from "../SiteSuggestion/SiteSuggestionStore";

export class RootStore {
  appraisalAnnotationStore: AppraisalAnnotationStore;
  appraisalStore: AppraisalStore;
  buildCostStore: BuildCostStore;
  buildPhaseStore: BuildPhaseStore;
  calculationsStore: CalculationsStore;
  cashflowFinanceStore: CashflowFinanceStore;
  cashflowStore: CashflowStore;
  costStore: CostStore;
  currentLineItemStore: CurrentLineItemStore;
  currentLoanStore: CurrentLoanStore;
  developmentsStore: DevelopmentsStore;
  currentDevelopmentStore: CurrentDevelopmentStore;
  loanStore: LoanStore;
  residualLandValueStore: ResidualLandValueStore;
  unitGroupStore: UnitGroupStore;
  userStore: UserStore;
  currentCostStore: CurrentCostStore;
  equityFundingStore: EquityFundingStore;
  currentEquityFundingStore: CurrentEquityFundingStore;
  CILStore: CILStore;
  currentUnitGroupStore: CurrentUnitGroupStore;
  templateStore: TemplateStore;
  utilityStore: UtilityStore;
  siteConstraintStore: SiteConstraintStore;
  reportStore: ReportStore;
  miniAppraisalStore: MiniAppraisalStore;
  siteSuggestionStore: SiteSuggestionStore;

  constructor() {
    this.userStore = new UserStore(this);
    this.appraisalAnnotationStore = new AppraisalAnnotationStore(this);
    this.appraisalStore = new AppraisalStore(this);
    this.buildCostStore = new BuildCostStore(this);
    this.buildPhaseStore = new BuildPhaseStore(this);
    this.calculationsStore = new CalculationsStore(this);
    this.cashflowFinanceStore = new CashflowFinanceStore(this);
    this.cashflowStore = new CashflowStore(this);
    this.costStore = new CostStore(this);
    this.currentLineItemStore = new CurrentLineItemStore(this);
    this.currentLoanStore = new CurrentLoanStore(this);
    this.developmentsStore = new DevelopmentsStore();
    this.currentDevelopmentStore = new CurrentDevelopmentStore(this);
    this.loanStore = new LoanStore(this);
    this.residualLandValueStore = new ResidualLandValueStore(this);
    this.unitGroupStore = new UnitGroupStore(this);
    this.currentCostStore = new CurrentCostStore(this);
    this.equityFundingStore = new EquityFundingStore(this);
    this.currentEquityFundingStore = new CurrentEquityFundingStore(this);
    this.CILStore = new CILStore();
    this.currentUnitGroupStore = new CurrentUnitGroupStore(this);
    this.utilityStore = new UtilityStore();
    this.siteConstraintStore = new SiteConstraintStore(this);
    this.reportStore = new ReportStore(this);
    this.templateStore = new TemplateStore(this);
    this.miniAppraisalStore = new MiniAppraisalStore(this);
    this.siteSuggestionStore = new SiteSuggestionStore(this);
  }

  async initialise() {
    await Promise.all([this.CILStore.initialise(), this.userStore.initialise()]);
  }

  async resetCache() {
    await cache.reset();
  }

  async loadAppraisal(appraisalId: ClientAppraisal["_id"]) {
    this.appraisalStore.restartInitialSetup();
    const appraisal = await getRemoteAppraisal(appraisalId);
    this.updateAppraisalStores(appraisal);
  }

  updateAppraisalStores(appraisal: ClientAppraisal) {
    // If we're swapping appraisal we need to reset the stores
    if (this.appraisalStore.appraisalId !== appraisal._id) {
      this.appraisalStore.setupAppraisalValues(appraisal);
      this.unitGroupStore.setUnitGroups(appraisal.unitGroups);
      this.buildPhaseStore.setupBuildPhases(appraisal.buildPhases);
      this.equityFundingStore.setupEquity(appraisal.equityFundingSources);
      this.costStore.setupCosts(appraisal.costs);
      this.unitGroupStore.setUnitGroups(appraisal.unitGroups);
      this.loanStore.setLoans(appraisal.loans);
      this.cashflowStore.setupCashflow(appraisal.cashflow);
      this.residualLandValueStore.setupResidualLandValue(appraisal);
      this.appraisalAnnotationStore.setupAnnotations(sanitiseInput(appraisal.appraisalAnnotations));
      this.currentUnitGroupStore.reset();
      this.utilityStore.reset();
    }

    this.appraisalStore.completeInitialSetup();
  }

  getCurrentAppraisal() {
    return getCachedAppraisal(this.appraisalStore.appraisalId);
  }

  setupReactions() {
    // Calculated loans will be a percentage of either totalSales or totalCosts, therefore when either of these change we'll have to recalculate them to reflect this
    reaction(
      () => this.costStore.totalFundableCost,
      () => {
        if (this.appraisalStore.isSetup) {
          this.loanStore.recalculateLoans();
        }
      }
    );
    reaction(
      () => this.unitGroupStore.totalSales,
      () => {
        if (this.appraisalStore.isSetup) {
          this.loanStore.recalculateLoans();
          this.costStore.recalculateCosts(ComputableCalculationType.PERCENTAGE_OF_GDV);
        }
      }
    );
    // Retained Loans fees are dependant on the gross loan, i.e. including a loan's interest therefore we need to recalculate fees when the Cashflow interest changes
    reaction(
      () => this.cashflowFinanceStore.totalCashflowInterestOfLoans,
      () => {
        if (this.appraisalStore.isSetup) {
          this.loanStore.checkLoanFeesForRecalculation();
        }
      }
    );
    reaction(
      () => this.appraisalStore.netPurchasePrice,
      () => {
        if (this.appraisalStore.isSetup) {
          this.cashflowStore.checkLandNetPriceAutomation();
        }
      }
    );
    reaction(
      () => this.buildPhaseStore.totalBuildContingency,
      () => {
        if (this.appraisalStore.isSetup) {
          this.cashflowStore.checkContingencyLineItemForAutomation();
        }
      }
    );
    reaction(
      () => this.appraisalStore.purchasePrice,
      () => {
        if (this.appraisalStore.isSetup) {
          this.costStore.recalculateCosts(ComputableCalculationType.PERCENTAGE_OF_LAND);
        }
      }
    );
    reaction(
      () => this.unitGroupStore.totalUnitsCount,
      () => {
        if (this.appraisalStore.isSetup) {
          this.costStore.recalculateCosts(ComputableCalculationType.PER_UNIT);
        }
      }
    );
    reaction(
      () => this.costStore.totalBuildCostWithoutContingency,
      () => {
        if (this.appraisalStore.isSetup) {
          this.costStore.recalculateCosts(ComputableCalculationType.PERCENTAGE_OF_CONSTRUCTION);
        }
      }
    );
    reaction(
      () => this.unitGroupStore.metricTotalGIA,
      () => {
        if (this.appraisalStore.isSetup) {
          this.buildCostStore.debounceGetBuildCost();
          this.costStore.recalculateCosts(ComputableCalculationType.PER_AREA_UNIT);
        }
      }
    );
    reaction(
      () => this.appraisalStore.quality,
      () => {
        if (this.appraisalStore.isSetup) {
          this.buildCostStore.debounceGetBuildCost();
        }
      }
    );
  }
}

export const rootStore = new RootStore();
