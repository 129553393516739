import { ClientSiteSearchResult } from "@shared/types/site";
import { makeAutoObservable } from "mobx";
import { RootStore } from "../Root";
import { hasLandInsightAccess } from "@shared/utils/authorisation";
import { getSiteSuggestions } from "@/react/lib/persistence/apollo";
import { debounce } from "lodash";

export class SiteSuggestionStore {
  readonly root: RootStore;
  siteSuggestions: ClientSiteSearchResult[] = [];
  siteSearchString = "";
  debounceGetSiteSuggestions = debounce(() => this.updateSiteSuggestions(), 800);

  constructor(root: RootStore) {
    this.root = root;
    makeAutoObservable(this, { root: false });
  }

  setSiteSuggestions(val: ClientSiteSearchResult[]) {
    this.siteSuggestions = val;
  }

  async updateSiteSuggestions() {
    if (!hasLandInsightAccess(this.root.userStore.user)) {
      return;
    }

    try {
      this.setSiteSuggestions(await getSiteSuggestions(this.siteSearchString));
    } catch {
      this.reset();
    }
  }

  updateSiteSearchString(value: string) {
    this.siteSearchString = value;
    if (!value.length) {
      this.setSiteSuggestions([]);
      return;
    }
    const exactMatch = this.siteSuggestions.find((site) => site.title === this.siteSearchString);
    if (exactMatch) {
      this.root.currentDevelopmentStore.setDevelopmentUpdate({ _site: exactMatch._id });
      this.reset();
    } else {
      this.debounceGetSiteSuggestions();
    }
  }

  reset() {
    this.siteSearchString = "";
    this.siteSuggestions = [];
  }
}
