import React, { ChangeEventHandler, Dispatch, useCallback, useState } from "react";
import clsx from "clsx";
import { InputError } from "../types";
import { Icon } from "../../Icons";
import { debounce } from "lodash";

export type TextareaProps = Omit<React.HTMLAttributes<HTMLTextAreaElement>, "onChange"> & {
  value?: string;
  label: string;
  onChange?: Dispatch<string | undefined>;
  error?: InputError;
  labelVisible?: boolean;
  disabled?: boolean;
  id: string;
  className?: string;
  maxLength?: number;
  rows?: number;
  delay?: number;
};

export const Textarea = ({
  id,
  value,
  onChange = () => null,
  label,
  labelVisible = true,
  error,
  className,
  rows = 2,
  delay = 500,
  ...props
}: TextareaProps) => {
  const [content, setContent] = useState(value);

  const emit = useCallback(
    debounce((val: string) => {
      onChange(val);
    }, delay),
    [onChange]
  );

  const changeContent: ChangeEventHandler<HTMLTextAreaElement> = ({ target }) => {
    setContent(target.value);
    emit(target.value);
  };

  return (
    <label htmlFor={id} className={className}>
      <span className={clsx({ "atlas-sr-only": !labelVisible }, "atlas-mb-2 atlas-block")}>
        {label}
      </span>
      <div
        className={clsx(
          "atlas-flex atlas-border atlas-rounded atlas-w-auto atlas-border-solid atlas-border-neutral-400 focus-within:atlas-ring-2 focus-within:atlas-ring-offset-3 focus-within:atlas-ring-primary-600 atlas-items-center atlas-relative",
          [
            !!error?.display
              ? "atlas-border-red-600 atlas-text-red-600 atlas-bg-red-50"
              : "atlas-text-neutral-800"
          ],
          [props.disabled ? "disabled:atlas-bg-neutral-100" : "atlas-bg-white"]
        )}
      >
        <textarea
          value={content}
          className={clsx(
            "atlas-w-full atlas-appearance-none atlas-border-0 focus:atlas-border-none  focus-visible:atlas-border-primary-600 focus:atlas-outline-none atlas-bg-transparent atlas-p-2",
            [!!error?.display ? "atlas-text-red-600 atlas-bg-red-50" : "atlas-text-neutral-800"]
          )}
          data-testid="textarea-input"
          onChange={changeContent}
          rows={rows}
          {...props}
        />
        {!!error?.display && (
          <div className={clsx("atlas-pl-0 atlas-absolute atlas-right-2 atlas-top-2")}>
            <Icon.InformationLine className="atlas-w-4" />
          </div>
        )}
      </div>
      {!!error?.display && (
        <div className="atlas-text-xs atlas-mt-2 atlas-text-red-600" data-testid="input-error">
          {error.message}
        </div>
      )}
    </label>
  );
};
