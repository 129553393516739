import queryString from "query-string";
import { each, set, get, PropertyPath } from "lodash";

type ValueTypes = string | number | object | boolean;
type TypeOptions = "string" | "int" | "number" | "float" | "object" | "boolean" | "coordinate";

export function serializeToParameterValue(
  value: ValueTypes,
  type: TypeOptions = typeof value as TypeOptions
) {
  if (value == null) {
    return value;
  }
  if (
    type === "string" ||
    type === "int" ||
    type === "float" ||
    type === "number" ||
    type === "boolean"
  ) {
    return value.toString();
  } else if (type === "coordinate") {
    return encodeURIComponent((value as any[]).join(","));
  } else {
    return encodeURIComponent(JSON.stringify(value));
  }
}

export function deserializeFromParamaterValue(
  val: ValueTypes,
  type: TypeOptions = typeof val as TypeOptions
) {
  if (val === null || val === undefined) {
    return;
  }

  try {
    if (type === "int" || type === "number") {
      return parseInt(val.toString(), 10);
    } else if (type === "float") {
      return parseFloat(val.toString());
    } else if (type === "boolean") {
      return val === "true";
    } else if (type === "object") {
      return JSON.parse(decodeURIComponent(val.toString()));
    } else if (type === "coordinate") {
      let parsedVal = JSON.parse(`[${decodeURIComponent(val.toString())}]`);
      return parsedVal.length === 2 ? parsedVal : null;
    } else {
      return val;
    }
  } catch (ex) {
    // Do nothing
  }
}

export function urlParamComputed(dataStoreName: PropertyPath, type: TypeOptions = "string") {
  return {
    get() {
      return serializeToParameterValue(get(this, dataStoreName), type);
    },
    set(val: ValueTypes) {
      let parsedVal = deserializeFromParamaterValue(val, type);
      set(this, dataStoreName, parsedVal);
    }
  };
}

export function overwriteQueryValues(
  queryValuesObj: any,
  {
    urlPathName = window.location.pathname,
    urlHash = window.location.hash,
    urlQuery = window.location.search
  } = {}
) {
  let parsedQs = queryString.parse(urlQuery);
  let newParsedQs = Object.assign({}, parsedQs, queryValuesObj);
  each(queryValuesObj, (val, key) => {
    if (!val) {
      delete newParsedQs[key];
    }
  });

  let qs = queryString.stringify(newParsedQs);
  let url = urlPathName;
  if (qs) {
    url += `?${queryString.stringify(newParsedQs)}`;
  }
  url += urlHash;

  return url;
}
