<template>
  <AppraisalSection
    sectionTitle="Build"
    :annotationType="AppraisalAnnotationType[AppraisalAnnotationType.build]"
  >
    <template v-if="BuildPhaseStore.convertedBuildPhases.length">
      <b-list-group>
        <draggable
          :value="BuildPhaseStore.convertedBuildPhases"
          @input="(buildPhases) => BuildPhaseStore.reorderBuildPhases(buildPhases)"
          ghost-class="ghost"
          handle=".handle"
        >
          <b-list-group-item
            v-for="buildPhase in BuildPhaseStore.convertedBuildPhases"
            :key="buildPhase._id"
          >
            <BuildPhaseAccordionItem
              :buildPhase="buildPhase"
              :buildPhaseHasBeenAdded="buildPhaseHasBeenAdded"
              data-testid="build_phase_accordion_item"
            />
          </b-list-group-item>
        </draggable>
      </b-list-group>
      <BuildCost v-if="!AppraisalStore.isTemplate" />
      <Contingency />
    </template>
    <p v-else>No build phases have been added</p>

    <AddBuildPhaseButton @buildPhaseAdded="buildPhaseHasBeenAdded = true" />
  </AppraisalSection>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import draggable from "vuedraggable";
import AppraisalSection from "@/components/AppraisalSection.vue";
import AddBuildPhaseButton from "@/components/BuildSection/AddBuildPhaseButton.vue";
import BuildPhaseAccordionItem from "./BuildPhaseAccordionItem.vue";
import Contingency from "./Contingency.vue";
import { AppraisalAnnotationType } from "@shared/types/appraisalAnnotation";
import { BuildCost } from "@/react/domains/appraisal/Build";
import { rootStore } from "@/react/lib/persistence/root_store";
import { Observer } from "mobx-vue";

@Observer
@Component({
  components: {
    draggable,
    AppraisalSection,
    AddBuildPhaseButton,
    BuildPhaseAccordionItem,
    Contingency,
    BuildCost: BuildCost as Vue.Component
  }
})
export default class BuildSection extends Vue {
  private buildPhaseHasBeenAdded: boolean = false;
  private BuildPhaseStore = rootStore.buildPhaseStore;
  private UnitGroupStore = rootStore.unitGroupStore;
  private AppraisalStore = rootStore.appraisalStore;
  private AppraisalAnnotationType = AppraisalAnnotationType;
}
</script>
