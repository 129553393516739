<template>
  <b-form-group
    class="build-phase-unit-groups"
    label="Select unit group(s) to apply units/NIA from"
  >
    <UnitGroupDropdown
      :build-phase-id="buildPhaseId"
      :assigned-unit-groups="assignedUnitGroups"
      :is-custom="isCustom"
      v-slot="{ customId }"
      data-testid="build_phase_unit_group_dropdown"
    >
      <b-form-checkbox
        v-for="unitGroup in UnitGroupStore.orderedUnitGroups"
        :key="unitGroup._id.toString()"
        :value="unitGroup._id"
      >
        {{ getUnitGroupDescription(unitGroup) }}
      </b-form-checkbox>
      <b-form-checkbox :value="customId">Custom</b-form-checkbox>
    </UnitGroupDropdown>
  </b-form-group>
</template>

<script lang="ts">
import UnitGroupDropdown from "@/components/BuildSection/UnitGroupDropdown.vue";
import { rootStore } from "@/react/lib/persistence/root_store";
import { totalUnitGroupArea } from "@/utils/unit_groups";
import { ClientBuildPhase } from "@shared/types/buildPhase";
import { ClientUnitGroup } from "@shared/types/unitGroup";
import { areaUtil } from "@shared/utils/area";
import { formatNumber, pluralise } from "@shared/utils/formatting";
import { Observer } from "mobx-vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Observer
@Component({ components: { UnitGroupDropdown } })
export default class BuildPhaseUnitGroups extends Vue {
  @Prop({ required: true }) readonly buildPhaseId!: ClientBuildPhase["_id"];
  @Prop({ required: true }) readonly assignedUnitGroups!: Array<ClientUnitGroup["_id"]>;
  @Prop({ required: true }) readonly isCustom!: ClientBuildPhase;

  private UserStore = rootStore.userStore;
  private UnitGroupStore = rootStore.unitGroupStore;

  public getUnitGroupDescription(unitGroup: ClientUnitGroup) {
    return `${unitGroup.description} (${pluralise(
      "unit",
      unitGroup.units?.length || 0
    )} - ${this.convertAndFormatSmallArea(totalUnitGroupArea(unitGroup))})`;
  }
  public convertAndFormatSmallArea(area: number) {
    return formatNumber({
      value: areaUtil.convertSmallArea(area, this.UserStore.areaUnit),
      suffix: this.UserStore.minorAreaUnitDisplay
    });
  }
}
</script>

<style lang="scss" scoped>
.build-phase-unit-groups {
  .custom-control {
    white-space: nowrap;
    width: 100%;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    margin-left: 0.75rem;
    font-size: 1rem !important;
  }
  .custom-control-label {
    padding-top: 0.2rem;
  }
}
</style>
