import gql from "graphql-tag";
import {
  ADD_BUILD_PHASE_MUTATION,
  DELETE_BUILD_PHASE_MUTATION,
  UPDATE_BUILD_PHASE_MUTATION
} from "../gql";
import { landFundApolloClient } from "../clients";
import { sanitiseInput, updateAppraisalCache } from "../utils";
import { omit } from "lodash";
import { ClientBuildPhase } from "@shared/types/buildPhase";
import { getCachedAppraisal } from "../queries";

export const addBuildPhase = async (
  appraisalId: string,
  buildPhase: ClientBuildPhase
): Promise<ClientBuildPhase | null | undefined> => {
  const { data: createBuildPhaseResponse } = await landFundApolloClient.mutate({
    mutation: gql`
      ${ADD_BUILD_PHASE_MUTATION}
    `,
    variables: {
      appraisalId,
      buildPhase: sanitiseInput(omit(buildPhase, "costs"), false)
    },
    update: (store, { data }) => {
      const cachedAppraisal = getCachedAppraisal(appraisalId);
      cachedAppraisal.buildPhases?.push(data.createBuildPhase);
      updateAppraisalCache(store, cachedAppraisal);
    }
  });

  return createBuildPhaseResponse?.createBuildPhase;
};

export const updateBuildPhase = async (appraisalId: string, buildPhase: ClientBuildPhase) => {
  const { data: updateBuildPhaseResponse } = await landFundApolloClient.mutate({
    mutation: gql`
      ${UPDATE_BUILD_PHASE_MUTATION}
    `,
    variables: {
      appraisalId,
      buildPhaseUpdate: sanitiseInput(omit(buildPhase, "costs"), false)
    }
  });
  return updateBuildPhaseResponse?.updateBuildPhase;
};

export const deleteBuildPhase = async (appraisalId: string, id: string) => {
  const { data: deleteBuildPhaseResponse } = await landFundApolloClient.mutate({
    mutation: gql`
      ${DELETE_BUILD_PHASE_MUTATION}
    `,
    variables: { appraisalId, id },
    update: (store, { data }) => {
      const cachedAppraisal = getCachedAppraisal(appraisalId);
      cachedAppraisal.buildPhases = cachedAppraisal.buildPhases?.filter(
        (bp) => bp._id !== data.deleteBuildPhase
      );
      updateAppraisalCache(store, cachedAppraisal);
    }
  });
  return deleteBuildPhaseResponse?.deleteBuildPhase;
};
