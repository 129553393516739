import { ValidationOptions, Validator } from "@/react/utils";
import { UnitGroupAction, UnitGroupExitType, UnitGroupType } from "@/utils/unit_groups";
import { getMergedUnit } from "@/utils/units";
import {
  ClientBaseComputable,
  ClientBaseCost,
  ComputableCalculationType
} from "@shared/types/computable";
import { ClientUnit } from "@shared/types/unit";
import { ClientUnitGroup } from "@shared/types/unitGroup";
import { Nullable } from "@shared/types/utils";
import { areaUtil } from "@shared/utils/area";
import { MAX_NO_UNITS } from "@shared/utils/constants";
import {
  yieldBasedUnitValueAfterDeduction,
  yieldBasedUnitValueBeforeDeduction
} from "@shared/utils/unit_groups";
import { pick } from "lodash";
import { makeAutoObservable } from "mobx";
import { RootStore } from "../Root";
import {
  CurrentUnitGroupFields,
  getAddingUnitGroupValidationOptions,
  getUnitValidationFields,
  validations
} from "./CurrentUnitGroupStoreUtil";

export class CurrentUnitGroupStore implements CurrentUnitGroupFields {
  readonly root: RootStore;

  description: string = "";
  unitGroupId: Nullable<string> = null;
  unitIds: Array<string> = [];
  percentGIA: number = 100;
  unitCount: number = 0;
  unitType: UnitGroupType = UnitGroupType.FLAT;
  exitType: UnitGroupExitType = UnitGroupExitType.SALE;
  area: number = 0;
  beds: number = 0;
  investmentYield: number = 5;
  voidPeriod: number = 0;
  rentFreePeriod: number = 0;
  annualRentIncome: ClientBaseComputable = {
    __typename: "BaseComputable",
    value: 0,
    calculate: false,
    calculationType: ComputableCalculationType.PER_AREA_UNIT,
    calculationBase: 0
  };
  salesValue: ClientBaseComputable = {
    __typename: "BaseComputable",
    value: 0,
    calculate: false,
    calculationType: ComputableCalculationType.PER_AREA_UNIT,
    calculationBase: 0
  };
  lettingFee: ClientBaseComputable = {
    __typename: "BaseComputable",
    value: 0,
    calculate: true,
    calculationType: ComputableCalculationType.PERCENTAGE_OF_RENT,
    calculationBase: 10
  };
  salesFee: ClientBaseCost = {
    __typename: "BaseCost",
    position: 0,
    value: 0,
    calculate: true,
    calculationType: ComputableCalculationType.PERCENTAGE_OF_SALES,
    calculationBase: 1.5
  };
  legalFees: ClientBaseCost = {
    __typename: "BaseCost",
    position: 0,
    value: 0,
    calculate: true,
    calculationType: ComputableCalculationType.PERCENTAGE_OF_SALES,
    calculationBase: 0.5
  };

  unitGroupAction: UnitGroupAction;

  validator: Validator;

  constructor(rootStore: RootStore) {
    this.root = rootStore;
    this.unitGroupAction = UnitGroupAction.ADDING_UNIT_GROUPS;

    this.validator = new Validator([]);

    makeAutoObservable(this, { root: false });
  }

  reset() {
    this.unitGroupId = null;
    this.unitIds = [];
    this.description = "";
    this.unitCount = 0;
    this.percentGIA = 100;
    this.unitType = UnitGroupType.FLAT;
    this.exitType = UnitGroupExitType.SALE;
    this.area = 0;
    this.beds = 0;
    this.investmentYield = 5;
    this.voidPeriod = 0;
    this.rentFreePeriod = 0;
    Object.assign(this.annualRentIncome, {
      value: 0,
      calculate: false,
      calculationType: ComputableCalculationType.PER_AREA_UNIT,
      calculationBase: 0
    });
    Object.assign(this.salesValue, {
      value: 0,
      calculate: false,
      calculationType: ComputableCalculationType.PER_AREA_UNIT,
      calculationBase: 0
    });
    Object.assign(this.salesFee, {
      position: 0,
      value: 0,
      calculate: true,
      calculationType: ComputableCalculationType.PERCENTAGE_OF_SALES,
      calculationBase: 1.5
    });
    Object.assign(this.lettingFee, {
      value: 0,
      calculate: true,
      calculationType: ComputableCalculationType.PERCENTAGE_OF_RENT,
      calculationBase: 10
    });
    Object.assign(this.legalFees, {
      position: 0,
      value: 0,
      calculate: true,
      calculationType: ComputableCalculationType.PERCENTAGE_OF_SALES,
      calculationBase: 0.5
    });
  }

  async submit() {
    switch (this.unitGroupAction) {
      case UnitGroupAction.EDITING_UNIT_GROUP:
        await this.editUnitGroup();
        break;
      case UnitGroupAction.ADDING_UNIT_GROUPS:
        await this.addUnitGroup();
        break;
      case UnitGroupAction.ADDING_UNITS:
        await this.addUnits();
        break;
      case UnitGroupAction.EDITING_UNIT:
      case UnitGroupAction.EDITING_UNITS:
        await this.editUnits();
        break;
    }
  }

  updateValidator() {
    let options: ValidationOptions[] = [];
    switch (this.unitGroupAction) {
      case UnitGroupAction.EDITING_UNIT_GROUP:
        options = [
          { name: "description", type: "string" },
          { name: "percentGIA", type: "number", min: 0, minIncl: false }
        ];
        break;
      case UnitGroupAction.ADDING_UNIT_GROUPS:
        options = getAddingUnitGroupValidationOptions(this.unitType, this.exitType);
        break;
      case UnitGroupAction.ADDING_UNITS: {
        options = Object.values(
          pick(validations, getUnitValidationFields(this.unitType, this.exitType))
        );
        const unitGroup = this.root.unitGroupStore.unitGroups.find(
          (ug) => ug._id === this.unitGroupId
        ) as ClientUnitGroup;
        const existingUnitCount = unitGroup.units?.length ?? 0;
        options.push({ ...validations.unitCount, max: MAX_NO_UNITS - existingUnitCount });
        break;
      }
      case UnitGroupAction.EDITING_UNIT:
      case UnitGroupAction.EDITING_UNITS:
        options = Object.values(
          pick(validations, getUnitValidationFields(this.unitType, this.exitType))
        );
    }
    this.validator = new Validator(options);
  }

  validate() {
    const fieldNames: Array<keyof CurrentUnitGroupStore> = [
      "description",
      "unitCount",
      "exitType",
      "unitType",
      "area",
      "beds",
      "percentGIA",
      "investmentYield",
      "voidPeriod",
      "rentFreePeriod",
      "annualRentIncome",
      "salesValue",
      "salesFee",
      "lettingFee",
      "legalFees"
    ];
    const fields = pick(this, fieldNames);
    this.validator.validate(fields);
  }

  setDescription(value: string) {
    this.description = value;
    this.validator.validate({ description: this.description });
  }

  setPercentGIA(value: number) {
    this.percentGIA = value;
    this.validator.validate({ percentGIA: this.percentGIA });
  }

  setUnitCount(value: number) {
    this.unitCount = value;
    if (
      this.unitGroupAction === UnitGroupAction.ADDING_UNIT_GROUPS &&
      this.unitType === UnitGroupType.OTHER_INCOME
    ) {
      this.updateSalesValueFromUnitCount();
    }
    this.validator.validate({ unitCount: this.unitCount });
  }

  setUnitType(value: UnitGroupType) {
    if (value === UnitGroupType.OTHER_INCOME) {
      const updatedBase = this.unitCount > 0 ? this.salesValue.value / this.unitCount : 0;
      this.setSalesValue({
        ...this.salesValue,
        calculationType: ComputableCalculationType.PER_UNIT,
        calculationBase: updatedBase,
        calculate: false
      });
    } else if (this.unitType === UnitGroupType.OTHER_INCOME) {
      const updatedBase = this.convertedArea > 0 ? this.salesValue.value / this.convertedArea : 0;
      this.setSalesValue({
        ...this.salesValue,
        calculationType: ComputableCalculationType.PER_AREA_UNIT,
        calculationBase: updatedBase,
        calculate: false
      });
    }
    this.unitType = value;
    this.updateValidator();
  }

  setExitType(value: UnitGroupExitType) {
    this.exitType = value;
    this.updateValidator();
  }

  setArea(value: number) {
    this.area = areaUtil.convertSmallAreaBack(value, this.root.userStore.areaUnit);
    if (this.area > 0) {
      if (this.isResidentialUnitForSale) {
        this.updateSalesValueFromArea();
      }
      if (this.isCommercialUnit) {
        this.updateAnnualRentIncomeFromArea();
      }
    }
    this.validator.validate({ area: this.area });
  }

  setBeds(value: number) {
    this.beds = value;
    this.validator.validate({ beds: this.beds });
  }

  setInvestmentYield(value: number) {
    this.investmentYield = value;
    this.validator.validate({ investmentYield: this.investmentYield });
  }

  setVoidPeriod(value: number) {
    this.voidPeriod = value;
    this.validator.validate({ voidPeriod: this.voidPeriod });
  }

  setRentFreePeriod(value: number) {
    this.rentFreePeriod = value;
    this.validator.validate({ rentFreePeriod: this.rentFreePeriod });
  }

  setAnnualRentIncome(update: ClientBaseComputable) {
    const { value, calculationBase, calculate } = update;
    Object.assign(this.annualRentIncome, { value, calculationBase, calculate });
    this.validator.validate({ annualRentIncome: { ...this.annualRentIncome } });
  }

  setMonthlyRentIncome(value: number) {
    const newAnnualRent = value * 12;
    const rentPerUnitArea = this.area > 0 ? newAnnualRent / this.area : 0;
    this.setAnnualRentIncome({
      ...this.annualRentIncome,
      value: newAnnualRent,
      calculationBase: rentPerUnitArea
    });
  }

  setSalesValue(update: ClientBaseComputable) {
    Object.assign(this.salesValue, update);
    if (this.isResidentialUnitForSale) {
      this.updateSalesFeeFromValue();
      this.updateLegalFeesFromSalesValue();
    }
    if (this.isCommercialUnit) {
      this.updateLegalFeesFromSalesValue();
    }
    this.validator.validate({ salesValue: { ...this.salesValue } });
  }

  updateSalesValueVal(val: number) {
    this.setSalesValue({
      value: val,
      calculationBase: this.area > 0 ? val / this.area : 0,
      calculate: false,
      calculationType: ComputableCalculationType.PER_AREA_UNIT
    });
  }

  updateSalesValueBase(base: number) {
    this.setSalesValue({
      calculationBase: areaUtil.convertPerSmallAreaUnitBack(base, this.root.userStore.areaUnit),
      value: base * this.convertedArea,
      calculate: true,
      calculationType: ComputableCalculationType.PER_AREA_UNIT
    });
  }

  setLettingFee(update: ClientBaseComputable) {
    const { value, calculationBase, calculate } = update;
    Object.assign(this.lettingFee, { value, calculationBase, calculate });
    this.validator.validate({ lettingFee: { ...this.lettingFee } });
  }

  setSalesFee(update: ClientBaseCost) {
    const { value, calculationBase, calculate } = update;
    Object.assign(this.salesFee, { value, calculationBase, calculate });
    this.validator.validate({ salesFee: { ...this.salesFee } });
  }

  updateSalesFeeVal(value: number) {
    this.setSalesFee({
      ...this.salesFee,
      value,
      calculationBase: (value / this.salesValue.value) * 100,
      calculate: false
    });
  }

  updateSalesFeeBase(base: number) {
    this.setSalesFee({
      ...this.salesFee,
      calculationBase: base,
      value: (base / 100) * this.salesValue.value,
      calculate: true
    });
  }

  setLegalFees(update: ClientBaseCost) {
    const { value, calculationBase, calculate } = update;
    Object.assign(this.legalFees, { value, calculationBase, calculate });
    this.validator.validate({ legalFees: { ...this.legalFees } });
  }

  updateLegalFeesVal(value: number) {
    this.setLegalFees({
      ...this.legalFees,
      value,
      calculationBase: (value / this.salesValue.value) * 100,
      calculate: false
    });
  }

  updateLegalFeesBase(base: number) {
    this.setLegalFees({
      ...this.legalFees,
      calculationBase: base,
      value: (base / 100) * this.salesValue.value,
      calculate: true
    });
  }

  updateSalesValueFromArea() {
    if (this.salesValue.calculate && this.salesValue.calculationBase > 0) {
      const newValue = this.salesValue.calculationBase * this.area;
      this.setSalesValue({ ...this.salesValue, value: newValue });
    }
    if (!this.salesValue.calculate && this.salesValue.value > 0) {
      const newBase = this.salesValue.value / this.area;
      this.setSalesValue({ ...this.salesValue, calculationBase: newBase });
    }
  }

  updateSalesValueFromUnitCount() {
    if (this.salesValue.calculate && this.salesValue.calculationBase > 0 && this.unitCount > 0) {
      const newValue = this.salesValue.calculationBase / this.unitCount;
      this.setSalesValue({ ...this.salesValue, value: newValue });
    }
    if (!this.salesValue.calculate && this.salesValue.value > 0) {
      const newBase = this.salesValue.value * this.unitCount;
      this.setSalesValue({ ...this.salesValue, calculationBase: newBase });
    }
  }

  updateAnnualRentIncomeFromArea() {
    if (this.annualRentIncome.calculate && this.annualRentIncome.calculationBase > 0) {
      const newValue = this.annualRentIncome.calculationBase * this.area;
      this.setAnnualRentIncome({ ...this.annualRentIncome, value: newValue });
    }
    if (!this.annualRentIncome.calculate && this.annualRentIncome.value > 0) {
      const newBase = this.annualRentIncome.value / this.area;
      this.setAnnualRentIncome({ ...this.annualRentIncome, calculationBase: newBase });
    }
  }

  updateSalesFeeFromValue() {
    const percentage = this.salesFee.calculationBase;
    if (percentage > 0) {
      const newFee = this.salesValue.value * (percentage / 100);
      this.setSalesFee({ ...this.salesFee, value: newFee });
    }
  }

  updateLegalFeesFromSalesValue() {
    const percentage = this.legalFees.calculationBase;
    if (percentage > 0) {
      const newFee = this.salesValue.value * (percentage / 100);
      this.setLegalFees({ ...this.legalFees, value: newFee });
    }
  }

  get convertedArea() {
    return Math.round(areaUtil.convertSmallArea(this.area, this.root.userStore.areaUnit));
  }

  get convertedAnnualRentIncome(): ClientBaseComputable {
    return {
      ...this.annualRentIncome,
      calculationBase: this.annualRentIncome.value / this.convertedArea
    };
  }

  get monthlyRentIncome() {
    return this.annualRentIncome.value / 12;
  }

  get convertedSalesValue(): ClientBaseComputable {
    return {
      ...this.salesValue,
      calculationBase: areaUtil.convertPerSmallAreaUnit(
        this.salesValue.calculationBase,
        this.root.userStore.areaUnit
      )
    };
  }

  get yieldBasedValue(): number {
    return this.isCommercialUnit
      ? yieldBasedUnitValueAfterDeduction(this)
      : yieldBasedUnitValueBeforeDeduction(this);
  }

  get convertedUnitGroupNIA(): number {
    return this.unitCount * this.convertedArea;
  }

  async duplicateUnit(unitGroupId: ClientUnitGroup["_id"], unitId: ClientUnit["_id"]) {
    const unitGroup = this.root.unitGroupStore.unitGroups.find((u) => u._id === unitGroupId);
    if (unitGroup === undefined) {
      return new Error(`Problem editing unit: unit group with id ${unitGroupId} not found`);
    }
    const unit = unitGroup.units?.find((u) => u._id === unitId);
    if (unit === undefined) {
      return new Error(`Problem editing unit: unit with id ${unitId} not found`);
    }
    const newUnit: ClientUnit = { ...unit, _id: "" };
    await this.root.unitGroupStore.addUnits(newUnit, unitGroupId, 1);
  }

  startEditingUnitGroup(unitGroupId: string) {
    const unitGroup = this.root.unitGroupStore.unitGroups.find((u) => u._id === unitGroupId);
    if (unitGroup === undefined) {
      return new Error(`Problem editing unit group: unit group with id ${unitGroupId} not found`);
    }

    const { _id, description, percentGIA } = unitGroup;
    this.unitGroupId = _id;
    this.description = description;
    this.percentGIA = percentGIA;

    this.unitGroupAction = UnitGroupAction.EDITING_UNIT_GROUP;
    this.updateValidator();
    this.root.utilityStore.openUnitGroupModal();
  }

  startAddingUnitGroup() {
    this.unitGroupAction = UnitGroupAction.ADDING_UNIT_GROUPS;
    this.unitGroupId = null;
    this.updateValidator();
    this.root.utilityStore.openUnitGroupModal();
  }

  startAddingUnits(unitGroupId: string) {
    const unitGroup = this.root.unitGroupStore.unitGroups.find((u) => u._id === unitGroupId);
    if (unitGroup === undefined) {
      return new Error(`Problem adding units: unit group with id ${unitGroupId} not found`);
    }
    const { unitType, exitType } = unitGroup;
    this.unitGroupAction = UnitGroupAction.ADDING_UNITS;
    Object.assign(this, { unitGroupId, unitType, exitType });
    this.updateValidator();
    this.root.utilityStore.openUnitGroupModal();
  }

  startEditingUnit(unitGroupId: ClientUnitGroup["_id"], unitId: ClientUnit["_id"]) {
    const unitGroup = this.root.unitGroupStore.unitGroups.find((u) => u._id === unitGroupId);
    if (unitGroup === undefined) {
      return new Error(`Problem editing unit: unit group with id ${unitGroupId} not found`);
    }
    const unit = unitGroup.units?.find((u) => u._id === unitId);
    if (unit === undefined) {
      return new Error(`Problem editing unit: unit with id ${unitId} not found`);
    }
    this.unitGroupAction = UnitGroupAction.EDITING_UNIT;
    const { unitType, exitType } = unitGroup;
    const {
      beds,
      area,
      annualRentIncome,
      investmentYield,
      rentFreePeriod,
      voidPeriod,
      salesValue,
      salesFee,
      legalFees
    } = unit;
    Object.assign(this, {
      unitGroupId,
      unitIds: [unitId],
      unitType,
      exitType,
      beds,
      area,
      investmentYield,
      rentFreePeriod,
      voidPeriod
    });
    this.setAnnualRentIncome(annualRentIncome);
    this.setSalesFee(salesFee);
    this.setSalesValue(salesValue);
    this.setLegalFees(legalFees);
    this.updateValidator();
    this.root.utilityStore.openUnitGroupModal();
  }

  startEditingUnits(unitGroupId: ClientUnitGroup["_id"], unitIds: Array<ClientUnit["_id"]>) {
    const unitGroup = this.root.unitGroupStore.unitGroups.find((u) => u._id === unitGroupId);
    if (unitGroup === undefined) {
      return new Error(`Problem editing unit: unit group with id ${unitGroupId} not found`);
    }

    const units: Array<ClientUnit> = [];
    for (let unitId of unitIds) {
      const unit = unitGroup.units?.find((u) => u._id === unitId);
      if (unit === undefined) {
        return new Error(`Problem editing unit: unit with id ${unitId} not found`);
      }
      units.push(unit);
    }

    this.unitGroupAction = UnitGroupAction.EDITING_UNITS;
    const { unitType, exitType } = unitGroup;
    const {
      _id,
      __typename,
      annualRentIncome,
      legalFees,
      lettingFee,
      salesFee,
      salesValue,
      ...unitFields
    } = getMergedUnit(units);
    Object.assign(this, {
      unitGroupId,
      unitIds,
      unitType,
      exitType,
      ...unitFields
    });
    this.setAnnualRentIncome(annualRentIncome);
    this.setLegalFees(legalFees);
    this.setLettingFee(lettingFee);
    this.setSalesFee(salesFee);
    this.setSalesValue(salesValue);

    this.updateValidator();
    this.root.utilityStore.openUnitGroupModal();
  }

  private async addUnitGroup() {
    this.validate();
    if (!this.validator.allValid) {
      return;
    }

    const newUnitGroup: ClientUnitGroup = {
      __typename: "UnitGroup",
      description: this.description,
      percentGIA: this.percentGIA,
      unitType: this.unitType,
      exitType: this.exitType,
      units: [],
      _id: "",
      position: 0
    };
    const newUnit: ClientUnit = {
      __typename: "Unit",
      area: this.area,
      beds: this.beds,
      investmentYield: this.investmentYield,
      voidPeriod: this.voidPeriod,
      rentFreePeriod: this.rentFreePeriod,
      annualRentIncome: { ...this.annualRentIncome },
      salesValue: { ...this.salesValue },
      salesFee: { ...this.salesFee },
      legalFees: { ...this.legalFees },
      lettingFee: { ...this.lettingFee },
      _id: ""
    };

    await this.root.unitGroupStore.addUnitGroup(newUnitGroup, newUnit, this.unitCount);
  }

  private async editUnitGroup() {
    const update = { description: this.description, percentGIA: this.percentGIA };
    this.validate();
    if (!this.validator.allValid) {
      return;
    }
    await this.root.unitGroupStore.updateUnitGroup(update, this.unitGroupId as string);
  }

  private async addUnits() {
    const fieldNames = [...getUnitValidationFields(this.unitType, this.exitType), "unitCount"];

    const fields = pick(this, fieldNames);

    this.validator.validate(fields);
    if (!this.validator.allValid) {
      return;
    }

    const newUnit: ClientUnit = {
      _id: "",
      __typename: "Unit",
      beds: this.beds,
      area: this.area,
      investmentYield: this.investmentYield,
      voidPeriod: this.voidPeriod,
      rentFreePeriod: this.rentFreePeriod,
      annualRentIncome: { ...this.annualRentIncome },
      lettingFee: { ...this.lettingFee },
      salesFee: { ...this.salesFee },
      legalFees: { ...this.legalFees },
      salesValue: { ...this.salesValue }
    };
    await this.root.unitGroupStore.addUnits(newUnit, this.unitGroupId as string, this.unitCount);
  }

  private async editUnits() {
    this.validate();
    if (!this.validator.allValid) {
      return;
    }

    const update: Partial<ClientUnit> = {
      beds: this.beds,
      area: this.area,
      investmentYield: this.investmentYield,
      voidPeriod: this.voidPeriod,
      rentFreePeriod: this.rentFreePeriod,
      annualRentIncome: { ...this.annualRentIncome },
      lettingFee: { ...this.lettingFee },
      salesFee: { ...this.salesFee },
      legalFees: { ...this.legalFees },
      salesValue: { ...this.salesValue }
    };

    await this.root.unitGroupStore.updateUnits(this.unitGroupId as string, this.unitIds, update);
  }

  get isAddingUnitGroup() {
    return this.unitGroupAction === UnitGroupAction.ADDING_UNIT_GROUPS;
  }
  get isAddingUnits() {
    return this.unitGroupAction === UnitGroupAction.ADDING_UNITS;
  }
  get isAdding() {
    return [UnitGroupAction.ADDING_UNITS, UnitGroupAction.ADDING_UNIT_GROUPS].includes(
      this.unitGroupAction
    );
  }
  get isEditingUnitGroup() {
    return this.unitGroupAction === UnitGroupAction.EDITING_UNIT_GROUP;
  }
  get isEditingUnit() {
    return this.unitGroupAction === UnitGroupAction.EDITING_UNIT;
  }
  get isEditingUnits() {
    return this.unitGroupAction === UnitGroupAction.EDITING_UNITS;
  }
  get hasRentalIncome(): boolean {
    return this.isCommercialUnit || this.isRentedResidentialUnit;
  }
  get isRentedResidentialUnit(): boolean {
    return this.isResidentialUnit && this.exitType === UnitGroupExitType.RENT;
  }
  get isResidentialUnitForSale(): boolean {
    return this.isResidentialUnit && this.exitType === UnitGroupExitType.SALE;
  }
  get isCommercialUnit(): boolean {
    return this.unitType === UnitGroupType.COMMERCIAL_UNIT;
  }
  get isResidentialUnit(): boolean {
    return this.unitType === UnitGroupType.FLAT || this.unitType === UnitGroupType.HOUSE;
  }
  get isOtherIncomeUnit(): boolean {
    return this.unitType === UnitGroupType.OTHER_INCOME;
  }
}
