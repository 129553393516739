import { Button, ButtonThemes, ButtonVariants, Icon, Section } from "@/react/components";
import { Annotations } from "@/react/domains/appraisal";
import { useTranslation } from "@/react/lib/hooks";
import {
  AppraisalFundingTranslations,
  AppraisalSectionsTranslations
} from "@/react/lib/i18n/locales";
import { rootStore } from "@/react/lib/persistence/root_store";
import { logEvent } from "@/react/utils/logging";
import { AppraisalAnnotationType } from "@shared/types/appraisalAnnotation";
import clsx from "clsx";
import React, { FC, PropsWithChildren, ReactNode, useState } from "react";

type AppraisalSectionProps = PropsWithChildren<{
  headerChildren?: ReactNode;
  type: AppraisalAnnotationType;
  title: string;
  testId?: string;
}>;

const AppraisalSection: FC<AppraisalSectionProps> = ({
  headerChildren,
  children,
  type,
  title,
  testId = "appraisal-section"
}) => {
  const [showAnnotations, updateShowAnnotations] = useState(false);
  const { t } = useTranslation();
  function setShowAnnotations() {
    updateShowAnnotations(!showAnnotations);
  }
  function toggleCILBar() {
    rootStore.utilityStore.toggleCILBar();
    if (rootStore.utilityStore.CILBarOpen) {
      logEvent("CIL Charges Clicked", {
        appraisal_id: rootStore.appraisalStore.appraisalId,
        development_id: rootStore.appraisalStore.developmentId
      });
    }
  }

  return (
    <Section className="atlas-mb-5 atlas-w-full" testId={testId}>
      <Section.Header>
        <h2 className="atlas-text-2xl atlas-my-0 atlas-font-extrabold">{title}</h2>
        <div className="atlas-flex atlas-gap-4">
          {headerChildren}
          {type === AppraisalAnnotationType.otherCosts && (
            <Button
              data-testid="check-cil-charges-button"
              onClick={toggleCILBar}
              theme={ButtonThemes.Borderless}
              aria-label={t(AppraisalSectionsTranslations.AppraisalSections_CheckCilCharges)}
            >
              {t(AppraisalSectionsTranslations.AppraisalSections_CheckCilCharges)}
            </Button>
          )}
          <Button
            data-testid="annotations-trigger"
            theme={showAnnotations ? ButtonThemes.Primary : ButtonThemes.OutlinePrimary}
            variant={ButtonVariants.SQ}
            className="atlas-px-1"
            onClick={setShowAnnotations}
            aria-label={
              showAnnotations
                ? t(AppraisalFundingTranslations.AppraisalFunding_HideAnnotations)
                : t(AppraisalFundingTranslations.AppraisalFunding_ShowAnnotations)
            }
          >
            <Icon.StickyNoteLine className={clsx("atlas-h-4 atlas-w-4 atlas-fill-current")} />
          </Button>
        </div>
      </Section.Header>
      <Section.Body className="atlas-py-5">
        <div className="atlas-w-full">
          {showAnnotations && <Annotations type={type} />}
          {children}
        </div>
      </Section.Body>
    </Section>
  );
};

export { AppraisalSection, AppraisalSectionProps };
