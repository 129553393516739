import { updateLoans } from "@/react/lib/persistence/apollo";
import { getTotalNetBorrowingFromLoans } from "@/react/utils";
import { calculateGrossLoan, calculateInterest, loanCost } from "@shared/utils/loan";
import { ClientLoan, InterestCalculationType, InterestChargingType } from "@shared/types/loan";
import { orderBy } from "lodash";
import { makeAutoObservable } from "mobx";
import {
  ensureFloatsAreParsed,
  hasCashflowInterestLoan,
  recalculateLoanFees
} from "./LoanStoreUtils";
import { RootStore } from "../Root";

export class LoanStore {
  readonly root: RootStore;
  loans: ClientLoan[] = [];
  loanToDeleteId: string = "";

  constructor(rootStore: RootStore) {
    this.root = rootStore;

    makeAutoObservable(this, { root: false });
  }

  async updateApollo() {
    const currentAppraisal = this.root.getCurrentAppraisal();
    try {
      await updateLoans(this.root.appraisalStore.appraisalId, ensureFloatsAreParsed(this.loans));
    } catch (e) {
      this.loans = currentAppraisal.loans;
    }
  }

  setLoans(loans?: ClientLoan[]) {
    this.loans = loans ?? [];
  }

  async reorderLoans(loans: ClientLoan[]) {
    this.loans = loans.map((loan, index) => {
      return { ...loan, position: index };
    });
    await this.updateApollo();
  }

  async deleteLoan() {
    this.loans = this.loans.filter((loan: ClientLoan) => loan._id !== this.loanToDeleteId);
    await this.updateApollo();
  }

  addLoanLocally(loan: ClientLoan) {
    this.loans.push(loan);
  }

  updateLoanLocally(updatedLoan: ClientLoan) {
    const updatedIndex = this.loans.findIndex((loan) => loan._id === updatedLoan._id);
    this.loans[updatedIndex] = updatedLoan;
  }

  removeLoanLocally(loanToDelete: ClientLoan) {
    this.loans = this.loans.filter((loan: ClientLoan) => {
      return loan._id !== loanToDelete._id;
    });
  }

  setLoanToDeleteId(value: string) {
    this.loanToDeleteId = value;
  }

  clearLoanToDeleteId() {
    this.loanToDeleteId = "";
  }

  /**
   * The loans will have to recalculate their values and hence their fees when the totalSales changes or the totalCost change.
   * When Cashflow interest changes is accommodated for by checkLoanFeesForRecalculation.
   */
  async recalculateLoans() {
    this.loans = this.loans.map((updatedLoan) => {
      const calculationReference =
        updatedLoan.amount.calculationType === "percentage-of-gdv"
          ? this.root.unitGroupStore.totalSales
          : this.root.costStore.totalFundableCost;
      if (updatedLoan.amount.calculate) {
        updatedLoan.amount.value =
          (updatedLoan.amount.calculationBase / 100) * calculationReference;
        recalculateLoanFees(updatedLoan, this.root.cashflowFinanceStore.financeOutputs.loanOutputs);
      } else {
        const percentage = (updatedLoan.amount.value / calculationReference) * 100;
        updatedLoan.amount.calculationBase = parseFloat(percentage.toFixed(2));
      }
      return updatedLoan;
    });
    await this.updateApollo();
  }

  /**
   * If a loan has the cashflow interest type then it is required to recalculate the fees whenever the cashflow changes, as the fees depend on the gross loan
   * which in turn depends on the loanOutputs (which changes a loan's interest). This function does this.
   */
  async checkLoanFeesForRecalculation(
    loanOutputs = this.root.cashflowFinanceStore.financeOutputs.loanOutputs
  ) {
    let loansChanged = false;
    const updatedLoans = this.loans.map((updatedLoan) => {
      if (
        updatedLoan.interestCalculation === InterestCalculationType.DrawnBalanceCashflow &&
        updatedLoan.interestCharging === InterestChargingType.Retained
      ) {
        loansChanged = true;
        recalculateLoanFees(updatedLoan, loanOutputs);
        return updatedLoan;
      } else {
        return updatedLoan;
      }
    });
    if (loansChanged) {
      this.loans = updatedLoans;
      await this.updateApollo();
    }
  }

  get orderedLoans(): ClientLoan[] {
    return orderBy(this.loans, "position");
  }

  get totalNetBorrowing() {
    return getTotalNetBorrowingFromLoans(this.loans);
  }

  get totalInterest() {
    return this.loans.reduce(
      (sum: number, loan: ClientLoan) =>
        sum + calculateInterest(loan, this.root.cashflowFinanceStore.financeOutputs.loanOutputs) ||
        0,
      0
    );
  }

  get totalGrossLoan() {
    return this.loans.reduce(
      (sum: number, loan: ClientLoan) =>
        sum + calculateGrossLoan(loan, this.root.cashflowFinanceStore.financeOutputs.loanOutputs),
      0
    );
  }

  get totalFundingCosts(): number {
    return this.loans.reduce(
      (sum: number, loan: ClientLoan) =>
        sum + (loanCost(loan, this.root.cashflowFinanceStore.financeOutputs.loanOutputs) || 0),
      0
    );
  }

  get equityContribution() {
    const equity = this.root.costStore.totalFundableCost - this.totalNetBorrowing;
    return equity > 0 ? equity : 0;
  }

  get hasServicedInterest() {
    return this.loans.some((loan) => loan.interestCharging === InterestChargingType.Serviced);
  }

  get hasCashflowInterestLoan() {
    return hasCashflowInterestLoan(this.loans);
  }
}
