<template>
  <b-card
    header="Site Map"
    v-if="
      currentDevelopmentStore.isSiteLoading ||
      (!currentDevelopmentStore.isSiteLoading && currentDevelopmentStore.site)
    "
  >
    <b-skeleton-wrapper :loading="currentDevelopmentStore.isSiteLoading">
      <template #loading>
        <div>
          <div class="flex-row align-items-center mb-3">
            <b-skeleton width="35%" height="27px" />
            <b-skeleton class="ml-auto" type="button" height="38px" width="205px" />
          </div>
          <b-skeleton-img no-aspect height="400px" width="100%" />
        </div>
      </template>
      <div v-if="currentDevelopmentStore.site">
        <div class="flex-row align-items-center mb-3">
          <b-icon class="mr-2" icon="geo-alt" />
          <span>{{
            currentDevelopmentStore.site.address || currentDevelopmentStore.site.title
          }}</span>
          <SiteLink class="ml-auto" :site-id="currentDevelopmentStore.site._id" />
        </div>
        <google-map
          v-if="currentDevelopmentStore.gMapsSiteBounds"
          :bounds="currentDevelopmentStore.gMapsSiteBounds"
        >
          <mapped-sites
            v-if="currentDevelopmentStore.sitesGeo"
            slot-scope="mapProps"
            :map="mapProps.map"
            :sites-geo="currentDevelopmentStore.sitesGeo"
            :clickable="false"
          />
        </google-map>
      </div>
    </b-skeleton-wrapper>
  </b-card>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import GoogleMap from "@/map/google_map.vue";
import MappedSites from "@/map/sites.vue";
import SiteLink from "@/components/SiteLink.vue";
import { rootStore } from "@/react/lib/persistence/root_store";
import { Observer } from "mobx-vue";

@Observer
@Component({
  components: {
    GoogleMap,
    MappedSites,
    SiteLink
  }
})
export default class SiteMap extends Vue {
  currentDevelopmentStore = rootStore.currentDevelopmentStore;
}
</script>

<style lang="scss" scoped>
.google-map-container {
  height: 400px;
  width: 100%;
}
</style>
