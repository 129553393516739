var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"content templates"},[_c('div',{staticClass:"template-list"},[_c('div',{staticClass:"templates-header-row flex-row"},[_c('h1',{staticClass:"h4 title"},[_vm._v("Templates")]),_vm._v(" "),_c('VersionAlert',{staticClass:"flex-grow-1"})],1),_vm._v(" "),(_vm.templateStore.templates)?_c('div',{staticClass:"template-card-row flex-row"},[_c('b-table-simple',{attrs:{"hover":"","primary-key":"_id","table-variant":"white","selected-variant":"primary","fixed":""}},[_c('b-thead',{attrs:{"head-variant":"landTech"}},[_c('b-tr',[_c('b-th',[_vm._v("Title")]),_vm._v(" "),_c('b-th',[_vm._v("Description")]),_vm._v(" "),_c('b-th')],1)],1),_vm._v(" "),_c('b-tbody',_vm._l((_vm.templateStore.templates),function(template){return _c('b-tr',{key:template._id,class:{
              'cursor-pointer': true,
              'b-table-row-selected table-primary': _vm.selectedTemplateId === template._id
            },on:{"click":function($event){return _vm.onSelect(template)}}},[(template.title)?_c('b-td',[_vm._v(_vm._s(template.title))]):_c('b-td',{staticClass:"font-italic text-muted"},[_vm._v("Untitled Template")]),_vm._v(" "),_c('b-td',[_c('div',{staticClass:"text-overflow-ellipsis"},[_c('div',{attrs:{"id":'template-desc-' + template._id}},[_vm._v("\n                  "+_vm._s(template.description)+"\n                ")]),_vm._v(" "),_c('b-tooltip',{attrs:{"target":'template-desc-' + template._id,"title":template.description}})],1)]),_vm._v(" "),_c('b-td',[_c('div',{staticClass:"text-right"},[_c('OptionsMenu',{staticClass:"pull-right",attrs:{"objectId":template._id,"objectType":"Template","setPopperOpts":false,"width":"100px"},on:{"delete":_vm.deleteTemplate,"duplicate":() => {
                      _vm.templateStore.duplicateTemplate(template._id);
                      _vm.logEvent('Template Duplicated', {
                        old_template_id: template._id
                      });
                    }}})],1)])],1)}),1)],1),_vm._v(" "),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return (() => {
            _vm.templateStore.createTemplate();
            _vm.logEvent('Template Created');
          }).apply(null, arguments)}}},[_c('span',[_vm._v("Add New Template")])])],1):_vm._e()]),_vm._v(" "),_c('b-sidebar',{staticClass:"template-edit-panel",attrs:{"visible":_vm.selectedTemplateId !== null || _vm.sidebarVisible,"right":"","z-index":"1","shadow":"","title":"Edit Template","no-close-on-esc":"","no-header":_vm.sidebarVisible,"no-header-close":_vm.sidebarVisible,"bg-variant":"white","backdrop":_vm.selectedTemplateId !== null && !_vm.sidebarVisible,"width":"700px"},on:{"hidden":function($event){_vm.selectedTemplateId = null}}},[(_vm.selectedTemplateId && !_vm.appraisalStore.isSetup)?_c('div',[_c('b-progress',{staticClass:"mt-3",attrs:{"value":100,"variant":"primary","animated":true}})],1):(_vm.selectedTemplateId)?_c('div',[_c('AppraisalDetails'),_vm._v(" "),_c('IncomeSection'),_vm._v(" "),_c('BuildSection'),_vm._v(" "),_c('b-card',{attrs:{"header":"Professional Fees"}},[_c('CostList',{attrs:{"costType":"professional-fee","calculationOptions":_vm.calculationsStore.percentageCalculationOptions,"costs":_vm.costStore.professionalFees}})],1),_vm._v(" "),_c('b-card',{attrs:{"header":"Other Costs"}},[_c('CostList',{attrs:{"costType":"other-cost","calculationOptions":_vm.calculationsStore.otherCostsCalculationOptions,"costs":_vm.costStore.otherCosts}})],1),_vm._v(" "),_c('CostModal'),_vm._v(" "),_c('FundingSection'),_vm._v(" "),_c('ResidualLandValue')],1):_c('div',{staticClass:"flex-row height-100"},[_c('div',{staticClass:"empty-state"},[_c('h5',[_c('b-icon',{attrs:{"icon":"pencil"}}),_vm._v("Edit Template")],1),_vm._v(" "),_c('p',[_vm._v("Select a template from the list to edit")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }