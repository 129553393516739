export enum AppraisalSingularWords {
  Appraisal_Add = "Domains.Appraisals.Appraisal.Add",
  Appraisal_Edit = "Domains.Appraisals.Appraisal.Edit",
  Appraisal_Save = "Domains.Appraisals.Appraisal.Save",
  Appraisal_Cancel = "Domains.Appraisals.Appraisal.Cancel",
  Appraisal_Close = "Domains.Appraisals.Appraisal.Close",
  Appraisal_Fees = "Domains.Appraisals.Appraisal.Fees",
  Appraisal_Interest = "Domains.Appraisals.Appraisal.Interest",
  Appraisal_Months = "Domains.Appraisals.Appraisal.Months"
}

export enum AppraisalDetailsTranslations {
  AppraisalDetails_AppraisalDetails = "Domains.Appraisals.AppraisalDetails.AppraisalDetails",
  AppraisalDetails_TemplateDetails = "Domains.Appraisals.AppraisalDetails.TemplateDetails",
  AppraisalDetails_UntitledAppraisal = "Domains.Appraisals.AppraisalDetails.UntitledAppraisal",
  AppraisalDetails_UntitledTemplate = "Domains.Appraisals.AppraisalDetails.UntitledTemplate",
  AppraisalDetails_Title = "Domains.Appraisals.AppraisalDetails.Title",
  AppraisalDetails_Description = "Domains.Appraisals.AppraisalDetails.Description"
}

export enum AppraisalCostsTranslations {
  AppraisalCosts_NoCosts = "Domains.Appraisals.Costs.NoCosts",
  AppraisalCosts_AddNewCost = "Domains.Appraisals.Costs.AddNewCost",
  AppraisalCosts_AddCost = "Domains.Appraisals.Costs.AddCost",
  AppraisalCosts_CostDescription = "Domains.Appraisals.Costs.CostDescription",
  AppraisalCosts_CostValue = "Domains.Appraisals.Costs.CostValue",
  AppraisalCosts_PleaseFillIn = "Domains.Appraisals.Costs.PleaseFillIn",
  AppraisalCosts_ValueMustBeGreaterThanZero = "Domains.Appraisals.Costs.ValueMustBeGreaterThanZero",
  AppraisalCosts_PleaseConfirm = "Domains.Appraisals.Costs.PleaseConfirm",
  AppraisalCosts_DeleteCostConfirm = "Domains.Appraisals.Costs.DeleteCostConfirm",
  AppraisalCosts_DeleteCost = "Domains.Appraisals.Costs.DeleteCost",
  AppraisalCosts_Delete = "Domains.Appraisals.Costs.Delete"
}

export enum AppraisalSectionsTranslations {
  AppraisalSections_ProfesionalFeesTitle = "Domains.Appraisals.Sections.ProfesionalFeesTitle",
  AppraisalSections_OtherCostsTitle = "Domains.Appraisals.Sections.OtherCostsTitle",
  AppraisalSections_CheckCilCharges = "Domains.Appraisals.Sections.CheckCilCharges"
}

export enum AppraisalIncomeUnitsTranslations {
  AppraisalIncomeUnits_TableBedroomsCount = "Domains.Appraisals.IncomeUnits.TableBedroomsCount",
  AppraisalIncomeUnits_TableUnitName = "Domains.Appraisals.IncomeUnits.TableUnitName",
  AppraisalIncomeUnits_TableAreaNIA = "Domains.Appraisals.IncomeUnits.TableAreaNIA",
  AppraisalIncomeUnits_TableSalesValue = "Domains.Appraisals.IncomeUnits.TableSalesValue",
  AppraisalIncomeUnits_TablePrice = "Domains.Appraisals.IncomeUnits.TablePrice",
  AppraisalIncomeUnits_TableUnit = "Domains.Appraisals.IncomeUnits.TableUnit",
  AppraisalIncomeUnits_TableRent = "Domains.Appraisals.IncomeUnits.TableRent",
  AppraisalIncomeUnits_TableAnnualIncome = "Domains.Appraisals.IncomeUnits.TableAnnualIncome",
  AppraisalIncomeUnits_TableYield = "Domains.Appraisals.IncomeUnits.TableYield",
  AppraisalIncomeUnits_TableIncomePerArea = "Domains.Appraisals.IncomeUnits.TableIncomePerArea",
  AppraisalIncomeUnits_TableVoidPeriod = "Domains.Appraisals.IncomeUnits.TableVoidPeriod",
  AppraisalIncomeUnits_TableRentFree = "Domains.Appraisals.IncomeUnits.TableRentFree",
  AppraisalIncomeUnits_DeleteUnitConfirm = "Domains.Appraisals.IncomeUnits.DeleteUnitConfirmMessage",
  AppraisalIncomeUnits_DeleteUnitsConfirm = "Domains.Appraisals.IncomeUnits.DeleteUnitsConfirmMessage",
  AppraisalIncomeUnits_DeleteUnit = "Domains.Appraisals.IncomeUnits.DeleteUnitMessage",
  AppraisalIncomeUnits_EditUnit = "Domains.Appraisals.IncomeUnits.EditUnitMessage",
  AppraisalEquityFunding_DuplicateUnit = "Domains.Appraisals.IncomeUnits.DuplicateUnitMessage"
}

export enum AppraisalIncomeTranslations {
  AppraisalIncome_AddUnitGroup = "Domains.Appraisals.Income.AddUnitGroup",
  AppraisalIncome_AddUnits = "Domains.Appraisals.Income.AddUnits",
  AppraisalIncome_EditUnitGroup = "Domains.Appraisals.Income.EditUnitGroup",
  AppraisalIncome_EditUnit = "Domains.Appraisals.Income.EditUnit",
  AppraisalIncome_EditUnits = "Domains.Appraisals.Income.EditUnits",
  AppraisalIncome_UnitGroupDescription = "Domains.Appraisals.Income.UnitGroupDescription",
  AppraisalIncome_UnitGroupDescriptionError = "Domains.Appraisals.Income.UnitGroupDescriptionError",
  AppraisalIncome_UnitCount = "Domains.Appraisals.Income.UnitCount",
  AppraisalIncome_UnitCountError = "Domains.Appraisals.Income.UnitCountError",
  AppraisalIncome_UnitBedroomsCount = "Domains.Appraisals.Income.UnitBedroomsCount",
  AppraisalIncome_UnitBedroomsCountError = "Domains.Appraisals.Income.UnitBedroomsCountError",
  AppraisalIncome_UnitArea = "Domains.Appraisals.Income.UnitArea",
  AppraisalIncome_UnitAreaError = "Domains.Appraisals.Income.UnitAreaError",
  AppraisalIncome_PercentGIA = "Domains.Appraisals.Income.PercentGIA",
  AppraisalIncome_PercentGIAError = "Domains.Appraisals.Income.PercentGIAError",
  AppraisalIncome_InvestmentYield = "Domains.Appraisals.Income.InvestmentYield",
  AppraisalIncome_InvestmentYieldError = "Domains.Appraisals.Income.InvestmentYieldError",
  AppraisalIncome_RentFreePeriod = "Domains.Appraisals.Income.RentFreePeriod",
  AppraisalIncome_RentFreePeriodError = "Domains.Appraisals.Income.RentFreePeriodError",
  AppraisalIncome_VoidPeriod = "Domains.Appraisals.Income.VoidPeriod",
  AppraisalIncome_VoidPeriodError = "Domains.Appraisals.Income.VoidPeriodError",
  AppraisalIncome_MonthlyRent = "Domains.Appraisals.Income.MonthlyRent",
  AppraisalIncome_MonthlyRentError = "Domains.Appraisals.Income.MonthlyRentError",
  AppraisalIncome_TotalNIA = "Domains.Appraisals.Income.TotalNIA",
  AppraisalIncome_GrossSalesValue = "Domains.Appraisals.Income.GrossSalesValue",
  AppraisalIncome_SalesValue = "Domains.Appraisals.Income.SalesValue",
  AppraisalIncome_SalesValuePerUnit = "Domains.Appraisals.Income.SalesValuePerUnit",
  AppraisalIncome_SalesValueError = "Domains.Appraisals.Income.SalesValueError",
  AppraisalIncome_SalesFee = "Domains.Appraisals.Income.SalesFee",
  AppraisalIncome_SalesFeePerUnit = "Domains.Appraisals.Income.SalesFeePerUnit",
  AppraisalIncome_SalesFeeError = "Domains.Appraisals.Income.SalesFeeError",
  AppraisalIncome_OfSales = "Domains.Appraisals.Income.OfSales",
  AppraisalIncome_LegalFees = "Domains.Appraisals.Income.LegalFees",
  AppraisalIncome_LegalFeesPerUnit = "Domains.Appraisals.Income.LegalFeesPerUnit",
  AppraisalIncome_LegalFeesError = "Domains.Appraisals.Income.LegalFeesError",
  AppraisalIncome_AnnualRent = "Domains.Appraisals.Income.AnnualRent",
  AppraisalIncome_AnnualRentPerUnit = "Domains.Appraisals.Income.AnnualRentPerUnit",
  AppraisalIncome_AnnualRentError = "Domains.Appraisals.Income.AnnualRentError",
  AppraisalIncome_IncomePer = "Domains.Appraisals.Income.IncomePer",
  AppraisalIncome_OtherIncome = "Domains.Appraisals.Income.OtherIncome",
  AppraisalIncome_OtherIncomeTotal = "Domains.Appraisals.Income.OtherIncomeTotal",
  AppraisalIncome_OtherIncomeError = "Domains.Appraisals.Income.OtherIncomeError"
}

export enum AppraisalEquityFundingTranslations {
  AppraisalEquityFunding_EquityCost = "Domains.Appraisals.EquityFunding.EquityCost",
  AppraisalEquityFunding_AddEquity = "Domains.Appraisals.EquityFunding.AddEquityName",
  AppraisalEquityFunding_EditEquity = "Domains.Appraisals.EquityFunding.EditEquityName",
  AppraisalEquityFunding_DuplicateEquity = "Domains.Appraisals.EquityFunding.DuplicateEquityName",
  AppraisalEquityFunding_DeleteEquity = "Domains.Appraisals.EquityFunding.DeleteEquityName",
  AppraisalEquityFunding_DeleteEquityConfirm = "Domains.Appraisals.EquityFunding.DeleteEquityConfirmMessage",
  AppraisalEquityFunding_EquityName = "Domains.Appraisals.EquityFunding.EquityName",
  AppraisalEquityFunding_Equity_NotBlank_ErrorMessage = "Domains.Appraisals.EquityFunding.ErrorMessage",
  AppraisalEquityFunding_TotalAmount = "Domains.Appraisals.EquityFunding.TotalAmount",
  AppraisalEquityFunding_TotalAmount_ErrorMessage = "Domains.Appraisals.EquityFunding.TotalAmountErrorMessage",
  AppraisalEquityFunding_CouponRate = "Domains.Appraisals.EquityFunding.CouponRate",
  AppraisalEquityFunding_CouponRate_Tooltip = "Domains.Appraisals.EquityFunding.CouponRateTooltip",
  AppraisalEquityFunding_CouponRate_ErrorMessage = "Domains.Appraisals.EquityFunding.CouponRateErrorMessage",
  AppraisalEquityFunding_EquityTerm = "Domains.Appraisals.EquityFunding.EquityTerm",
  AppraisalEquityFunding_EquityTerm_ErrorMessage = "Domains.Appraisals.EquityFunding.EquityTermErrorMessage",
  AppraisalEquityFunding_TableEquityName = "Domains.Appraisals.EquityFunding.TableEquityName",
  AppraisalEquityFunding_TableTotalAmount = "Domains.Appraisals.EquityFunding.TableTotalAmount",
  AppraisalEquityFunding_TableCouponRate = "Domains.Appraisals.EquityFunding.TableCouponRate",
  AppraisalEquityFunding_ProviderProfitShare = "Domains.Appraisals.EquityFunding.TableProviderProfitShare",
  AppraisalEquityFunding_ProviderProfitShareLabel = "Domains.Appraisals.EquityFunding.TableProviderProfitShareLabel",
  AppraisalEquityFunding_ProviderProfitShare_Tooltip = "Domains.Appraisals.EquityFunding.ProviderProfitShareTooltip",
  AppraisalEquityFunding_ProviderProfitShare_ErrorMessage = "Domains.Appraisals.EquityFunding.ProviderProfitShareErrorMessage",
  AppraisalEquityFunding_TableTerm = "Domains.Appraisals.EquityFunding.TableTerm",
  AppraisalEquityFunding_TableDeveloperEquity = "Domains.Appraisals.EquityFunding.TableDeveloperEquity",
  AppraisalEquityFunding_TableDeveloperEquityTooltip = "Domains.Appraisals.EquityFunding.TableDeveloperEquityTooltip"
}

export enum AppraisalFundingTranslations {
  AppraisalFunding_Funding = "Domains.Appraisals.Funding.Funding",
  AppraisalFunding_CloseModal = "Domains.Appraisals.Funding.CloseModal",
  AppraisalFunding_DragHandle = "Domains.Appraisals.Funding.DragHandle",
  AppraisalFunding_HideAnnotations = "Domains.Appraisals.Funding.HideAnnotations",
  AppraisalFunding_ShowAnnotations = "Domains.Appraisals.Funding.ShowAnnotations",
  AppraisalFunding_PleaseConfirm = "Domains.Appraisals.Funding.PleaseConfirm",
  AppraisalFunding_DeleteLoanConfirm = "Domains.Appraisals.Funding.DeleteLoanConfirm",
  AppraisalFunding_Delete = "Domains.Appraisals.Funding.Delete",
  AppraisalFunding_AddLoan = "Domains.Appraisals.Funding.AddLoan",
  AppraisalFunding_EditLoan = "Domains.Appraisals.Funding.EditLoan",
  AppraisalFunding_DeleteLoan = "Domains.Appraisals.Funding.DeleteLoan",
  AppraisalFunding_AddEquity = "Domains.Appraisals.Funding.AddEquity",
  AppraisalFunding_EditEquity = "Domains.Appraisals.Funding.EditEquity",
  AppraisalFunding_DeleteEquity = "Domains.Appraisals.Funding.DeleteEquity",
  AppraisalFunding_LoanName = "Domains.Appraisals.Funding.LoanName",
  AppraisalFunding_GrossAmount = "Domains.Appraisals.Funding.GrossAmount",
  AppraisalFunding_Term = "Domains.Appraisals.Funding.Term",
  AppraisalFunding_Term_Tooltip = "Domains.Appraisals.Funding.Term_Tooltip",
  AppraisalFunding_Term_ErrorMessage = "Domains.Appraisals.Funding.TableTerm_ErrorMessage",
  AppraisalFunding_TableGrossAmount = "Domains.Appraisals.Funding.TableGrossAmount",
  AppraisalFunding_TableCost = "Domains.Appraisals.Funding.TableCost",
  AppraisalFunding_TableActions = "Domains.Appraisals.Funding.TableActions",
  AppraisalFunding_TableTotalEquity = "Domains.Appraisals.Funding.TableDeveloperEquity",
  AppraisalFunding_Loan_AddNewLoan = "Domains.Appraisals.Funding.Loan_AddNewLoan",
  AppraisalFunding_Loan_EditLoan = "Domains.Appraisals.Funding.Loan_EditLoan",
  AppraisalFunding_Loan_ArrangementFee = "Domains.Appraisals.Funding.Loan_ArrangementFee",
  AppraisalFunding_Loan_ArrangementFee_ErrorMessage = "Domains.Appraisals.Funding.Loan_ArrangementFee_ErrorMessage",
  AppraisalFunding_Loan_NotBlank_ErrorMessage = "Domains.Appraisals.Funding.Loan_NotBlank_ErrorMessage",
  AppraisalFunding_Loan_InterestRate = "Domains.Appraisals.Funding.Loan_InterestRate",
  AppraisalFunding_Loan_InterestRate_ErrorMessage = "Domains.Appraisals.Funding.Loan_InterestRate_ErrorMessage",
  AppraisalFunding_Loan_NetLoan = "Domains.Appraisals.Funding.Loan_NetLoan",
  AppraisalFunding_Loan_NetLoan_ErrorMessage = "Domains.Appraisals.Funding.Loan_NetLoan_ErrorMessage",
  AppraisalFunding_Loan_InterestRateType = "Domains.Appraisals.Funding.Loan_InterestRateType",
  AppraisalFunding_Loan_InterestCalculation = "Domains.Appraisals.Funding.Loan_InterestCalculation",
  AppraisalFunding_Loan_InterestCalculation_TooltipDescription = "Domains.Appraisals.Funding.Loan_InterestCalculation_TooltipDescription",
  AppraisalFunding_Loan_InterestCalculation_Tooltip = "Domains.Appraisals.Funding.Loan_InterestCalculation_Tooltip",
  AppraisalFunding_Loan_InterestCharging = "Domains.Appraisals.Funding.Loan_InterestCharging",
  AppraisalFunding_Loan_InterestCharging_TooltipDescription = "Domains.Appraisals.Funding.Loan_InterestCharging_TooltipDescription",
  AppraisalFunding_Loan_InterestCharging_Tooltip = "Domains.Appraisals.Funding.Loan_InterestCharging_Tooltip",
  AppraisalFunding_Loan_ExitFee = "Domains.Appraisals.Funding.Loan_ExitFee",
  AppraisalFunding_Loan_ExitFee_ErrorMessage = "Domains.Appraisals.Funding.Loan_ExitFee_ErrorMessage",
  AppraisalFunding_Loan_TotalCost = "Domains.Appraisals.Funding.Loan_TotalCost",
  AppraisalFunding_Loan_GrossLoan = "Domains.Appraisals.Funding.Loan_GrossLoan",
  AppraisalFunding_Loan_CostPercent = "Domains.Appraisals.Funding.Loan_CostPercent",
  AppraisalFunding_Loan_GDVPercent = "Domains.Appraisals.Funding.Loan_GDVPercent"
}

export enum AppraisalActionsTranslations {
  AppraisalIncome_EditUnits_Button = "Domains.Appraisals.Income.Units_EditButton",
  AppraisalIncome_EditUnits_Tooltip = "Domains.Appraisals.Income.Units_EditTooltip",
  AppraisalIncome_DeleteUnits_Button = "Domains.Appraisals.Income.Units_DeleteButton",
  AppraisalIncome_DeleteUnits_Tooltip = "Domains.Appraisals.Income.Units_DeleteTooltip"
}

export enum AppraisalAnnotations {
  AppraisalAnnotations_Annotations = "Domains.Appraisals.Annotations",
  AppraisalAnnotations_AnnotationsPlaceholder = "Domains.Appraisals.AnnotationsPlaceholder"
}

export enum CILMessaging {
  CilCharges_Title = "Domains.CILMessages.CilChargesTitle",
  Authority_Select_Title = "Domains.CILMessages.SelectTitle",
  Authority_Select_Placeholder = "Domains.CILMessages.SelectPlaceholder",
  Status = "Domains.CILMessages.Status",
  Date = "Domains.CILMessages.Date",
  Zones = "Domains.CILMessages.Zones",
  Mayoral = "Domains.CILMessages.Mayoral",
  Residential_Charges = "Domains.CILMessages.ResidentialCharges",
  Retail = "Domains.CILMessages.Retail",
  Other = "Domains.CILMessages.Other",
  View_Website = "Domains.CILMessages.ViewWebsite",
  Disclaimer = "Domains.CILMessages.Disclaimer",
  Disclaimer_Text = "Domains.CILMessages.DisclaimerText",
  StatusUnknown_Alert_Text = "Domains.CILMessages.StatusUnknownAlertText",
  StatusNone_Alert_Text = "Domains.CILMessages.StatusNoneAlertText",
  StatusInDevelopment_Alert_Text = "Domains.CILMessages.StatusInDevelopmentAlertText",
  Warning_Text = "Domains.CILMessages.WarningText",
  FaqArticle_Title = "Domains.CILMessages.FaqArticleTitle"
}
