export enum IncomeTranslations {
  NoUnits = "Income.NoUnits",
  NoUnitGroups = "Income.NoUnitGroups",
  Yield = "Income.Yield",
  GrossSalesValue = "Income.GrossSalesValue",
  OptionsMenuTooltip = "Income.OptionsMenuToolip",
  ConfirmDeleteTitle = "Income.ConfirmDeleteTitle",
  ConfirmDeleteContent = "Income.ConfirmDeleteContent",
  ConfirmDeleteConfirm = "Income.ConfirmDeleteConfirm",
  AddUnitGroup = "Income.AddUnitGroup",
  Income = "Income.Income",
  Add = "Income.Add",
  Edit = "Income.Edit",
  Delete = "Income.Delete"
}
