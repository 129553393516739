import { Draggable } from "@/react/components";
import clsx from "clsx";
import React, { MutableRefObject, useMemo } from "react";
import { Row } from "react-table";

export const StaticTableRow = <Opts extends { _id: string; draggable: boolean }>({
  row,
  tdClassName,
  parent
}: {
  row: Row<Opts> | undefined | null;
  tdClassName: string;
  parent: MutableRefObject<HTMLTableRowElement | null>;
}) => {
  const widths = useMemo(() => {
    if (parent.current) {
      const parentEl = parent.current;
      const colwidths: number[] = [];

      parentEl.querySelectorAll("th").forEach((item) => {
        colwidths.push(item.clientWidth);
      });

      return colwidths;
    }
    return [];
  }, [parent.current]);
  return (
    <table className="atlas-w-full atlas-border-collapse">
      <tbody>
        <tr {...row?.getRowProps()}>
          {row?.cells.map((cell, i) => {
            if (i === 0) {
              return (
                <td
                  {...cell.getCellProps()}
                  className={clsx("atlas-w-auto atlas-bg-neutral-100", tdClassName)}
                  style={{ minWidth: widths[i] + "px" }}
                >
                  <span className=" atlas-flex atlas-items-center ">
                    {row.original.draggable && <Draggable.Handle isDragging={true} />}
                    <span>{cell.render("Cell")}</span>
                  </span>
                </td>
              );
            }
            return (
              <td
                {...cell.getCellProps()}
                className={clsx(
                  "atlas-bg-neutral-100",
                  { "atlas-text-left": i < row?.cells.length - 2 },
                  { "atlas-text-right": i >= row?.cells.length - 2 },
                  tdClassName
                )}
                style={{ minWidth: widths[i] + "px" }}
              >
                {cell.render("Cell")}
              </td>
            );
          })}
        </tr>
      </tbody>
    </table>
  );
};
