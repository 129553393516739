export enum FundingPageTranslations {
  GetStarted = "FundingPage.Form.GetStarted",
  Company = "FundingPage.Form.Company",
  Name = "FundingPage.Form.Name",
  PhoneNumber = "FundingPage.Form.PhoneNumber",
  Email = "FundingPage.Form.Email",
  RequestCallback = "FundingPage.Form.RequestCallback",
  TermsAndConditions = "FundingPage.Form.TermsAndConditions",
  ContactDirectly = "FundingPage.Form.ContactDirectly",
  ChatWithUs = "FundingPage.Form.ChatWithUs",
  BlankInput = "FundingPage.Form.BlankInput",
  EmailInvalid = "FundingPage.Form.EmailInvalid",
  NumberInvalid = "FundingPage.Form.NumberInvalid",
  FormSubmitted_ThankYou = "FundingPage.Form.Submitted.ThankYou",
  FundingOptions = "FundingPage.Modal.FundingOptions",
  LeaveDetails = "FundingPage.Form.Title.LeaveDetails",
  FundingPage_FindRightLender = "Domains.FundingPage.Card.FundingPage_FindRightLender",
  FundingPage_TakeALook = "Domains.FundingPage.Card.FundingPage_TakeALook"
}

export enum FundingPageTabs {
  FundingPage_SeniorDebt = "Domains.FundingPage.Card.SeniorDebt",
  FundingPage_SeniorDebt_1 = "Domains.FundingPage.Card.SeniorDebt_1",
  FundingPage_SeniorDebt_2 = "Domains.FundingPage.Card.SeniorDebt_2",
  FundingPage_SeniorDebt_3 = "Domains.FundingPage.Card.SeniorDebt_3",
  FundingPage_Mezzanine = "Domains.FundingPage.Card.Mezzanine",
  FundingPage_Equity = "Domains.FundingPage.Card.Equity",
  FundingPage_MezzRates = "Domains.FundingPage.Card.MezzRates",
  FundingPage_UpTo80 = "Domains.FundingPage.Card.UpTo80",
  FundingPage_UpTo98 = "Domains.FundingPage.Card.UpTo98",
  FundingPage_UpTo100 = "Domains.FundingPage.Card.UpTo100",
  FundingPage_Profit = "Domains.FundingPage.Card.Profit",
  FundingPage_Bridging = "Domains.FundingPage.Card.Bridging",
  FundingPage_Bridging_Rates = "Domains.FundingPage.Card.Bridging_Rates",
  FundingPage_Bridging_LTV = "Domains.FundingPage.Card.Bridging_LTV",
  FundingPage_Bridging_NET = "Domains.FundingPage.Card.Bridging_NET"
}

export enum FundingPageList {
  FundingPage_HowItWork = "Domains.FundingPage.Card.HowItWork",
  FundingPage_Interested = "Domains.FundingPage.Card.Interested",
  FundingPage_Callback = "Domains.FundingPage.Card.Callback",
  FundingPage_Match = "Domains.FundingPage.Card.Match"
}
