import { getFinanceOutputs } from "@/utils/cashflow_finance";
import { calculateAnnualIRR } from "@/utils/irr_calculation";
import { InterestChargingType } from "@shared/types/loan";
import { makeAutoObservable } from "mobx";
import { RootStore } from "../Root";

export class CashflowFinanceStore {
  readonly root: RootStore;

  constructor(rootStore: RootStore) {
    this.root = rootStore;
    makeAutoObservable(this, { root: false });
  }

  get annualGearedIRR(): number {
    return calculateAnnualIRR(this.equityNets);
  }

  get formattedAnnualGearedIRR(): string {
    return isNaN(this.annualGearedIRR) ? "N/A" : `${this.annualGearedIRR}%`;
  }

  get annualUngearedIRR(): number {
    return calculateAnnualIRR(this.root.cashflowStore.monthlyNets);
  }

  get formattedAnnualUngearedIRR(): string {
    return isNaN(this.annualUngearedIRR) ? "N/A" : `${this.annualUngearedIRR}%`;
  }

  get financeOutputs() {
    return getFinanceOutputs(
      this.root.cashflowStore.monthlyNets,
      this.root.loanStore.loans,
      this.root.equityFundingStore.equityFundingSources,
      this.root.equityFundingStore.developerEquity
    );
  }

  get equityNets() {
    const cumulativeEquityBalances = this.financeOutputs.developerEquity;

    return cumulativeEquityBalances.map((equityBalance, index) =>
      index === 0 ? equityBalance : equityBalance - cumulativeEquityBalances[index - 1]
    );
  }

  get finalEquityMinusServicedInterest() {
    return this.financeOutputs.developerEquity.slice(-1)[0] - this.totalServicedInterest;
  }

  get totalServicedInterest() {
    return this.financeOutputs.loanOutputs.reduce(
      (total, loanOutput) =>
        total +
        (loanOutput.loan.interestCharging === InterestChargingType.Serviced
          ? loanOutput.interestAccrued.reduce((tot, v) => tot + v, 0)
          : 0),
      0
    );
  }

  get totalCashflowInterestOfLoans() {
    return this.financeOutputs.loanOutputs.reduce(
      (total, loanOutput) => total + loanOutput.totalInterest,
      0
    );
  }
}
