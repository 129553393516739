import { SideBar } from "@/react/components/SideBar";
import { useTranslation } from "@/react/lib/hooks";
import { CILMessaging } from "@/react/lib/i18n/locales";
import { rootStore } from "@/react/lib/persistence/root_store";
import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import { CILSection } from "./CILSection";
import { Icon } from "@/react/components";

const CIL_FAQ_URL =
  "https://support.land.tech/en/articles/8460204-understanding-the-cil-charges-summary-in-landfund-s-appraisal-tool";

const CILSidebarObservable: FC = observer(() => {
  const { t } = useTranslation();

  const CILChargesHeader = (
    <>
      <div data-testid="cil-sidebar-title">{t(CILMessaging.CilCharges_Title)}</div>
      <div>
        <a
          href={CIL_FAQ_URL}
          target="_blank"
          data-testid="faq-link"
          className="atlas-mr-1"
          title={t(CILMessaging.FaqArticle_Title)}
        >
          <Icon.QuestionLine className="atlas-cursor-pointer atlas-text-neutral-800 hover:atlas-text-primary-900" />
        </a>
        <a onClick={() => rootStore.utilityStore.closeCILBar()} data-testid="close-cross">
          <Icon.CloseLine className="atlas-cursor-pointer atlas-text-neutral-800 hover:atlas-text-primary-900" />
        </a>
      </div>
    </>
  );

  return (
    <SideBar
      header={CILChargesHeader}
      isVisible={rootStore.utilityStore.CILBarOpen}
      testId="cil-sidebar"
    >
      <CILSection />
    </SideBar>
  );
});

export const CILSidebar: FC = () => <CILSidebarObservable />;
