<template>
  <div class="number-output">
    <label>
      {{ label }}
      <span v-if="secondaryLabel" class="font-weight-light">
        <br />
        {{ secondaryLabel }}
      </span>
    </label>
    <label class="ml-auto" :class="{ danger }">
      <span>{{ errorOutput || formattedValue }}</span>
    </label>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class NumberOutput extends Vue {
  @Prop({ default: "" }) readonly label!: string;
  @Prop({ default: "" }) readonly secondaryLabel!: string;
  @Prop({ default: "" }) readonly errorOutput!: string;
  @Prop() readonly value!: number;
  @Prop() readonly prefix!: string;
  @Prop() readonly suffix!: string;
  @Prop({ default: false }) readonly danger!: boolean;
  @Prop({ default: 0 }) readonly decimals!: number;

  public formatValue(value: number): string {
    if (value === null || value === undefined || isNaN(value)) {
      return "-";
    } else {
      return value.toFixed(this.decimals).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    }
  }

  get formattedValue() {
    return [this.prefix, this.formatValue(this.value), this.suffix].filter(Boolean).join(" ");
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/landtech-colors";

.number-output {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.5rem;

  label {
    margin: 0;

    &:not(.danger) {
      color: $neutral-900;
    }
  }
}
</style>
