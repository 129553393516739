import { ClientBuildPhase } from "@shared/types/buildPhase";
import { ClientUnit } from "@shared/types/unit";
import { ClientUnitGroup } from "@shared/types/unitGroup";
import ObjectID from "bson-objectid";

export enum UnitGroupAction {
  ADDING_UNIT_GROUPS,
  ADDING_UNITS,
  EDITING_UNIT_GROUP,
  EDITING_UNITS,
  EDITING_UNIT
}

export enum UnitGroupExitType {
  RENT = "Rent",
  SALE = "Sale"
}

export enum UnitGroupType {
  FLAT = "Flat",
  HOUSE = "House",
  COMMERCIAL_UNIT = "Commercial Unit",
  OTHER_INCOME = "Other Income"
}

export interface UnitGroupTypes {
  value: string;
  displayName: string;
  alternativeDisplayName?: string;
}

export const UnitGroupTypesInstance: { [key: string]: UnitGroupTypes } = {
  [UnitGroupType.FLAT]: {
    value: UnitGroupType.FLAT,
    displayName: "Flat"
  },
  [UnitGroupType.HOUSE]: {
    value: UnitGroupType.HOUSE,
    displayName: "House"
  },
  [UnitGroupType.COMMERCIAL_UNIT]: {
    value: UnitGroupType.COMMERCIAL_UNIT,
    displayName: "Commercial Unit"
  },
  [UnitGroupType.OTHER_INCOME]: {
    value: UnitGroupType.OTHER_INCOME,
    displayName: "Other Income",
    alternativeDisplayName: "Unit"
  }
};

export const initUnitGroup: ClientUnitGroup = {
  _id: new ObjectID().toHexString(),
  __typename: "UnitGroup",
  unitType: "Flat",
  position: 0,
  description: "",
  units: [],
  percentGIA: 100,
  exitType: "Sale"
};

export const totalAreaFromUnitGroup = (unitGroup: ClientUnitGroup): number =>
  unitGroup.units
    ?.map((unit: ClientUnit) => unit.area)
    .reduce((prev: number, next: number) => prev + next) ?? 0;

export const totalAreaFromUnitGroups = (unitGroups: ClientUnitGroup[]): number =>
  unitGroups.reduce(
    (sum: number, unitGroup: ClientUnitGroup) => sum + totalUnitGroupArea(unitGroup),
    0
  );

export const totalNumberOfUnits = (unitGroups: ClientUnitGroup[]): number =>
  unitGroups.reduce(
    (count: number, unitGroup: ClientUnitGroup) => count + (unitGroup.units?.length || 0),
    0
  );

export const totalUnitGroupArea = (unitGroup: ClientUnitGroup): number =>
  unitGroup.units?.reduce((sum: number, unit: ClientUnit) => sum + unit.area, 0) ?? 0;

export const unitGroupsForBuildPhase = (
  buildPhase: ClientBuildPhase,
  unitGroups: ClientUnitGroup[]
): ClientUnitGroup[] =>
  unitGroups.filter((unitGroup) => buildPhase.assignedUnitGroups.includes(unitGroup._id));
