import { Tooltip } from "@/react/components";
import { formatAmountWithBrackets } from "@shared/utils/formatting";
import clsx from "clsx";
import React, { FC } from "react";

interface CellValue {
  value: number;
  bold?: boolean;
}

export const CellValue: FC<CellValue> = ({ value, bold = false }) => {
  const content = formatAmountWithBrackets(value);
  return (
    <Tooltip content={content} asChild>
      <div
        className={clsx(
          "atlas-px-4 atlas-py-2 atlas-overflow-hidden atlas-overflow-ellipsis atlas-w-full",
          {
            "atlas-font-bold": bold
          }
        )}
      >
        {content}
      </div>
    </Tooltip>
  );
};
