import { Alert, AlertType, ProgressBar } from "@/react/components";
import { useTranslation } from "@/react/lib/hooks";
import { CILMessaging } from "@/react/lib/i18n/locales";
import { rootStore } from "@/react/lib/persistence/root_store";
import { observer } from "mobx-react-lite";
import React, { FC } from "react";
import { AuthoritySelect } from "./AuthoritySelect";
import { CILData } from "./CILData";
import { UnitSettingWarning } from "./UnitSettingWarning";

const CILSectionObservable: FC = observer(() => {
  const { t } = useTranslation();

  const showUnitSettingWarning =
    rootStore.userStore.areaUnit === "imperial" && rootStore.CILStore.hasCILData;

  const showStatusAlert = !!rootStore.CILStore.currentCILRecord && !rootStore.CILStore.hasCILData;

  return (
    <div className="atlas-w-full">
      <AuthoritySelect />
      {showUnitSettingWarning && <UnitSettingWarning />}
      {showStatusAlert && (
        <div className="atlas-m-6">
          <Alert type={AlertType.Info}>
            {rootStore.CILStore.currentCILRecord?.status === "Unknown" &&
              t(CILMessaging.StatusUnknown_Alert_Text)}
            {rootStore.CILStore.currentCILRecord?.status === "None" &&
              t(CILMessaging.StatusNone_Alert_Text)}
            {rootStore.CILStore.currentCILRecord?.status === "In development" &&
              t(CILMessaging.StatusInDevelopment_Alert_Text)}
          </Alert>
        </div>
      )}
      {rootStore.CILStore.isLoading && <ProgressBar className="atlas-my-4" />}
      {!rootStore.CILStore.isLoading && !!rootStore.CILStore.currentCILRecord && <CILData />}
    </div>
  );
});

export const CILSection: FC = () => <CILSectionObservable />;
