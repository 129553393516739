import { ClientEquityFunding } from "@shared/types/equity";

export const calculateEquityInterest: (equityFunding: ClientEquityFunding) => number = (
  equityFunding: ClientEquityFunding
) => {
  const monthlyRate = equityFunding.couponRate / 12;
  const multiplier = 1 + monthlyRate / 100;
  const fraction = Math.pow(multiplier, equityFunding.term) - 1;
  const couponRateCost = equityFunding.totalAmount * fraction;

  return couponRateCost;
};
