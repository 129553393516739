import { landFundApolloClient } from "@/react/lib/persistence/apollo";
import { AUTHORITIES, CIL } from "@shared/apollo/queries";
import { IAuthority, ICILRecord } from "@shared/types/CILData";
import gql from "graphql-tag";

export const getCILForAuthority = async (gssCode: IAuthority["gss_code"]): Promise<ICILRecord> => {
  const { data } = await landFundApolloClient.query({
    query: gql`
      ${CIL}
    `,
    variables: { gss_code: gssCode }
  });
  return data.cil;
};

export const getAuthorities = async (): Promise<IAuthority[]> => {
  const { data } = await landFundApolloClient.query({
    query: gql`
      ${AUTHORITIES}
    `
  });
  return data.authorities;
};
