import { StampDutyBand } from "@shared/types/stampDutyBand";

export const calculateStampDuty = (
  purchasePrice: number,
  stampDutyBands: StampDutyBand[]
): number => {
  if (purchasePrice <= 0) {
    return 0;
  }
  let paidStampDuty = 0;
  for (const [i, currentBand] of stampDutyBands.entries()) {
    const previousBand = stampDutyBands[i - 1];
    const lowerBoundary = previousBand?.upperBoundary ?? 0;
    const upperBoundary = currentBand.upperBoundary ?? Infinity;
    const priceWithinCurrentBand = Math.min(purchasePrice, upperBoundary) - lowerBoundary;
    const stampDutyForCurrentBand = priceWithinCurrentBand * currentBand.rate;
    paidStampDuty += stampDutyForCurrentBand;
    if (purchasePrice < upperBoundary) {
      break;
    }
  }
  return paidStampDuty;
};
