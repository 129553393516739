"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBoundsZoomLevel = void 0;
//https://stackoverflow.com/a/13274361 adjusted for not using googles objects
const getBoundsZoomLevel = (bounds, dimension) => {
    const WORLD_DIM = { height: 256, width: 256 };
    const ZOOM_MAX = 21;
    function latRad(lat) {
        const sin = Math.sin((lat * Math.PI) / 180);
        const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }
    function zoom(mapPx, worldPx, fraction) {
        return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }
    const ne = [bounds[3], bounds[2]];
    const sw = [bounds[1], bounds[0]];
    const latFraction = (latRad(ne[0]) - latRad(sw[0])) / Math.PI;
    const lngDiff = ne[1] - sw[1];
    const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;
    const latZoom = zoom(dimension.height, WORLD_DIM.height, latFraction);
    const lngZoom = zoom(dimension.width, WORLD_DIM.width, lngFraction);
    return Math.min(latZoom, lngZoom, ZOOM_MAX);
};
exports.getBoundsZoomLevel = getBoundsZoomLevel;
