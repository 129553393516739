<template>
  <b-dropdown
    id="global-options"
    text=""
    toggle-class="text-left pl-0 pl-sm-2"
    :class="isMobile ? `w-100 mb-2 border-top pt-2` : `ml-2`"
    size="sm"
    variant="white"
    menu-class="w-150"
    right
    :block="isMobile"
  >
    <template #button-content>
      <b-avatar
        class="mr-2 font-weight-bold d-none d-sm-inline-block"
        :variant="userStore.avatarColourVariant"
        :text="userStore.userInitials"
        size="32px"
        data-testid="avatar"
      />
      <span v-show="isMobile">{{ userStore.userName }}</span>
    </template>
    <b-dropdown-item class="normal-font-weight" v-b-toggle.account-sidebar>
      My Account
    </b-dropdown-item>
    <b-dropdown-item class="normal-font-weight" @click="logout">Logout</b-dropdown-item>
  </b-dropdown>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { Observer } from "mobx-vue";
import { rootStore } from "@/react/lib/persistence/root_store";

@Observer
@Component({})
export default class AccountMenu extends Vue {
  @Prop({ required: true }) readonly isMobile!: boolean;

  userStore = rootStore.userStore;

  public async logout() {
    rootStore.resetCache();
    window.location.href = LANDFUND_CONFIG.LOGOUT_PATH;
  }
}
</script>
