"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.areaUtil = void 0;
/**
 * Convert from metric to current Unit Setting
 */
const converter = (unitSetting = "metric", small = true) => {
    if (unitSetting === "metric") {
        if (small) {
            return (x) => x;
        }
        else {
            return (x) => x / 10000;
        }
    }
    else {
        if (small) {
            return (x) => x * 10.763914692;
        }
        else {
            return (x) => (x * 78125) / 316160658;
        }
    }
};
exports.areaUtil = {
    getSmallAreaString: (unitSetting = "metric", longForm = false) => {
        if (unitSetting === "metric") {
            return longForm ? "square meters" : "m²";
        }
        else {
            return longForm ? "square feet" : "ft²";
        }
    },
    getBigAreaString: (unitSetting = "metric", longForm = false) => {
        if (unitSetting === "metric") {
            return longForm ? "hectares" : "ha";
        }
        else {
            return longForm ? "acres" : "ac";
        }
    },
    convertSmallArea: (x, unitSetting) => {
        const convert = converter(unitSetting, true);
        return convert(x);
    },
    /**
     * Convert to metric from current unit setting
     */
    convertSmallAreaBack(x, unitSetting) {
        return x / exports.areaUtil.convertSmallArea(1, unitSetting);
    },
    convertBigArea: (x, unitSetting) => {
        const convert = converter(unitSetting, false);
        return convert(x);
    },
    convertPerSmallAreaUnit: (x, unitSetting) => {
        return x / exports.areaUtil.convertSmallArea(1, unitSetting);
    },
    convertPerSmallAreaUnitBack: (x, unitSetting) => {
        return x / exports.areaUtil.convertPerSmallAreaUnit(1, unitSetting);
    }
};
