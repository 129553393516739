import clsx from "clsx";
import React, { useRef, useEffect } from "react";
import { InputError } from "../../types";
import "./checkbox.css";

export enum CheckboxState {
  CHECKED = "checked",
  INDETERMINATE = "indeterminate",
  EMPTY = "empty"
}

export interface CheckboxProps {
  id: string;
  label?: string;
  value?: CheckboxState;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  error?: InputError;
}

export const Checkbox = ({
  id,
  label,
  value,
  disabled = false,
  onChange,
  error
}: CheckboxProps) => {
  const checkboxRef = useRef<HTMLInputElement>(null);

  const handleChange = () => {
    onChange(value !== CheckboxState.CHECKED);
  };

  useEffect(() => {
    if (checkboxRef.current) {
      if (value === CheckboxState.CHECKED) {
        checkboxRef.current.checked = true;
        checkboxRef.current.indeterminate = false;
      } else if (value === CheckboxState.EMPTY) {
        checkboxRef.current.checked = false;
        checkboxRef.current.indeterminate = false;
      } else if (value === CheckboxState.INDETERMINATE) {
        checkboxRef.current.checked = false;
        checkboxRef.current.indeterminate = true;
      }
    }
  }, [value]);

  return (
    <>
      <label htmlFor={id} className="atlas-m-0 atlas-flex atlas-cursor-pointer">
        <span className="atlas-checkbox-wrapper atlas-items-center">
          <input
            id={id}
            ref={checkboxRef}
            disabled={disabled}
            type="checkbox"
            onChange={handleChange}
            data-testid="checkbox"
            aria-label={label ?? "checkbox"}
            className={clsx("atlas-checkbox-input atlas-bg-white", {
              "atlas-checkbox-input-error": error?.display
            })}
          />
          <span />
        </span>
        {!!label && <span className="atlas-ml-2">{label}</span>}
      </label>
      {error?.display && <span className="atlas-text-red-600 atlas-mt-2">{error?.message}</span>}
    </>
  );
};
