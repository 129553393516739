import { Validator } from "@/react/utils";
import { ClientEquityFunding } from "@shared/types/equity";
import { makeAutoObservable } from "mobx";
import { RootStore } from "../Root";
import { createNewEquityFunding } from "./CurrentEquityFundingStoreUtil";
import { MAX_MONTH_INPUT } from "@shared/utils/constants";

export class CurrentEquityFundingStore {
  readonly root: RootStore;
  equityFunding: ClientEquityFunding = createNewEquityFunding();
  submitAttempted: boolean = false;
  isEditing: boolean = false;
  validator: Validator = new Validator([
    { name: "description", type: "string" },
    { name: "totalAmount", type: "number", min: 0 },
    {
      name: "providerProfitShare",
      type: "number",
      min: 0,
      minIncl: true,
      max: 100,
      isOptional: true
    },
    { name: "term", type: "number", min: 0, minIncl: true, max: MAX_MONTH_INPUT, isOptional: true },
    { name: "couponRate", type: "number", min: 0, minIncl: true, isOptional: true }
  ]);

  constructor(rootStore: RootStore) {
    this.root = rootStore;
    makeAutoObservable(this, { root: false });
  }

  startAddingEquity() {
    this.equityFunding.position = this.root.equityFundingStore.equityFundingLength;
    this.isEditing = false;
    this.root.utilityStore.openEquityModal();
  }

  startEditingEquity(equityFundingToEdit: ClientEquityFunding) {
    this.equityFunding = equityFundingToEdit;
    this.isEditing = true;
    this.root.utilityStore.openEquityModal();
    setTimeout(() => this.resetValidity(), 1);
  }

  submitEquityFunding() {
    this.validate();
    if (!this.validator.allValid) {
      return;
    }

    const zeroIfIsNaN = (x: number) => (isNaN(x) ? 0 : x);
    this.equityFunding.providerProfitShare = zeroIfIsNaN(this.equityFunding.providerProfitShare);
    this.equityFunding.couponRate = zeroIfIsNaN(this.equityFunding.couponRate);
    this.equityFunding.term = zeroIfIsNaN(this.equityFunding.term);

    if (this.isEditing) {
      this.root.equityFundingStore.updateEquityFunding(this.equityFunding._id, this.equityFunding);
    } else {
      this.root.equityFundingStore.addEquityFunding(this.equityFunding);
    }
    this.resetStore();
  }

  cancelEquityFundingUpdate() {
    this.resetStore();
  }

  updateEquity(equityFundingUpdate: Partial<ClientEquityFunding>) {
    this.equityFunding = { ...this.equityFunding, ...equityFundingUpdate };
    this.validator.validate(equityFundingUpdate);
  }

  validate() {
    this.validator.validate(this.equityFunding);
  }

  resetValidity() {
    this.validator.resetAll();
  }

  resetStore() {
    this.equityFunding = createNewEquityFunding();
    this.resetValidity();
    this.root.utilityStore.closeEquityModal();
  }

  get isProfitShareBelow100() {
    return (
      100 >= this.root.equityFundingStore.totalProfitShared + this.equityFunding.providerProfitShare
    );
  }
}
