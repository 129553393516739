import React from "react";
import { TableSection } from "../CashflowTableSection";
import { rootStore } from "@/react/lib/persistence/root_store";
import { LineItemRow } from "../../LineItemRow";
import { observer } from "mobx-react-lite";
import { FinanceRow } from "../../FinanceRow";
import { useTranslation } from "@/react/lib/hooks";
import { CashflowTableTranslations } from "@/react/lib/i18n/locales";

export const ProfessionalFeesSection = observer(() => {
  const { t } = useTranslation();

  const cashflowStore = rootStore.cashflowStore;
  const costStore = rootStore.costStore;
  return (
    <TableSection title="Professional Fees" testId="professional-fees-section">
      {cashflowStore.professionalFeeLineItems.map((val) => (
        <LineItemRow lineItem={val} key={val._id} testId="professional-fees-line-item" />
      ))}
      <FinanceRow
        testId="professional-fees-totals"
        totalRow
        title={t(CashflowTableTranslations.CashflowTable_Total)}
        valueCell={costStore.totalProfessionalFees}
        monthCells={cashflowStore.professionalFeesColumnTotals}
        bottomBorder
      />
    </TableSection>
  );
});
