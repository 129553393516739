import React, {createContext, PropsWithChildren, useState, Dispatch, SetStateAction, useMemo} from "react";

interface TabsContextType {
  activeTab: string;
  setActiveTab: Dispatch<SetStateAction<string>>;
}

export const TabsContext = createContext<TabsContextType>({
  activeTab: "",
  setActiveTab: () => {}
});

interface TabsProviderProps {
  defaultValue: string | undefined;
}
export const TabsProvider = ({ children, defaultValue }: PropsWithChildren<TabsProviderProps>) => {
  const [activeTab, setActiveTab] = useState(defaultValue || "");
  const value = useMemo(() => ({ activeTab, setActiveTab }), [activeTab]);

  return (
    <TabsContext.Provider value={value}>{children}</TabsContext.Provider>
  );
};
