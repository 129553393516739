import React from "react";
import { CashflowRow, CashflowCell, CashflowCellType, CellValue } from "../CashflowRow";
import clsx from "clsx";

interface FinanceRowProps {
  title: string;
  valueCell?: number | null;
  timeFrameCell?: number | null;
  curveCell?: number | null;
  monthCells: Array<null | number>;
  testId?: string;
  bottomBorder?: boolean;
  topBorder?: boolean;
  totalRow?: boolean;
}

export const FinanceRow = ({
  title,
  valueCell = null,
  timeFrameCell = null,
  curveCell = null,
  monthCells,
  testId,
  totalRow = false,
  topBorder = false,
  bottomBorder = false
}: FinanceRowProps) => {
  const borderProps = { bottomBorder, topBorder };
  let month = 0;
  return (
    <CashflowRow totalRow={totalRow} testId={testId}>
      <CashflowCell
        {...borderProps}
        cellType={CashflowCellType.TITLE}
        className={clsx("atlas-px-2 atlas-py-2 atlas-whitespace-nowrap", {
          "atlas-font-bold": totalRow
        })}
      >
        {title}
      </CashflowCell>

      <CashflowCell {...borderProps} cellType={CashflowCellType.VALUE}>
        {valueCell !== null && <CellValue bold={totalRow} value={valueCell} />}
      </CashflowCell>

      <CashflowCell {...borderProps} cellType={CashflowCellType.TIME_FRAME}>
        {timeFrameCell !== null && <CellValue bold={totalRow} value={timeFrameCell} />}
      </CashflowCell>

      <CashflowCell {...borderProps} cellType={CashflowCellType.CURVE}>
        {curveCell !== null && <CellValue bold={totalRow} value={curveCell} />}
      </CashflowCell>

      {...monthCells.map((monthValue) => (
        <CashflowCell
          {...borderProps}
          cellType={CashflowCellType.MONTH}
          monthNumber={++month}
          key={month}
        >
          {monthValue !== null && <CellValue bold={totalRow} value={monthValue} />}
        </CashflowCell>
      ))}
    </CashflowRow>
  );
};
