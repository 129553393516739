<template>
  <AppraisalDetailsComponent />
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { AppraisalDetails as AppraisalDetailsComponent } from "@/react/domains/appraisal/AppraisalDetails";

@Component({
  components: {
    AppraisalDetailsComponent: AppraisalDetailsComponent as Vue.Component
  }
})
export default class AppraisalDetails extends Vue {}
</script>

<style scoped>
div {
  width: 100%;
  max-width: 992px;
}
</style>
