import { StampDutyBand } from "@shared/types/stampDutyBand";

export const resiBands: StampDutyBand[] = [
  {
    upperBoundary: 250000,
    rate: 0.03
  },
  {
    upperBoundary: 925000,
    rate: 0.08
  },
  {
    upperBoundary: 1500000,
    rate: 0.13
  },
  {
    upperBoundary: null,
    rate: 0.15
  }
];

export const discountedResiBands: StampDutyBand[] = resiBands.map((b) => ({
  ...b,
  rate: b.rate - 0.03
}));

export const nonResiFreeholdBands: StampDutyBand[] = [
  {
    upperBoundary: 150000,
    rate: 0
  },
  {
    upperBoundary: 250000,
    rate: 0.02
  },
  {
    upperBoundary: null,
    rate: 0.05
  }
];

export const nonResiLeaseholdBands: StampDutyBand[] = [
  {
    upperBoundary: 150000,
    rate: 0
  },
  {
    upperBoundary: 5000000,
    rate: 0.01
  },
  {
    upperBoundary: null,
    rate: 0.02
  }
];
