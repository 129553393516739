import { BuildCost } from "../enums/buildCost";

export const BuildCostMessages = {
  [BuildCost.Average]: "Average",
  [BuildCost.Check_Average]: "Check the average",
  [BuildCost.Cost_Average]: " quality build cost for a {totalArea} site in",
  [BuildCost.Info_Tooltip]: `
    The displayed build costs are based on industry averages and represent the average cost of
    construction for the size and location of your site.
    <a
      href="https://support.land.tech/en/articles/7872962-calculate-build-costs-with-landfund"
      target="_blank"
    >
      Learn more
    </a>
  `,
  [BuildCost.Input_Error]: "Please enter a valid postcode",
  [BuildCost.Input_Error_Partial]: "Please enter a full UK postcode",
  [BuildCost.Min_GIA]: "Minimum GIA of {minArea} required"
};
