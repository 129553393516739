import { Checkbox, CheckboxState, Icon, Tooltip } from "@/react/components";
import { useTranslation } from "@/react/lib/hooks";
import { AppraisalActionsTranslations } from "@/react/lib/i18n/locales";
import { rootStore } from "@/react/lib/persistence/root_store";
import clsx from "clsx";
import React, { FC } from "react";

export interface SelectedRowsInfoHeaderProps {
  tableId: string;
  selectedRowIds: Array<string>;
  selectAllStatus: CheckboxState;
  onDeleteSelected: (selectedRowIds: Array<string>) => void;
  onChangeSelectAll: (checked: boolean) => void;
}

export const SelectedRowsInfoHeader: FC<SelectedRowsInfoHeaderProps> = ({
  tableId,
  selectAllStatus,
  selectedRowIds,
  onDeleteSelected,
  onChangeSelectAll
}: SelectedRowsInfoHeaderProps) => {
  const { t } = useTranslation();

  return (
    <tr className="atlas-w-full" data-testid="selected-rows-info-header">
      <th
        className={clsx("atlas-bg-neutral-100 empty:atlas-hidden atlas-px-3 atlas-py-2 atlas-w-8")}
        role="columnheader"
      >
        <Checkbox
          id={"partialSelectAll_" + tableId}
          onChange={onChangeSelectAll}
          value={selectAllStatus}
        />
      </th>
      <th
        className={clsx("atlas-bg-neutral-100 empty:atlas-hidden atlas-px-3 atlas-py-2")}
        role="columnheader"
      >
        <span>
          {selectedRowIds.length} {selectedRowIds.length === 1 ? "unit" : "units"} selected
        </span>
      </th>
      <th className={clsx("atlas-bg-neutral-100 atlas-px-3 atlas-py-2")}></th>
      <th className={clsx("atlas-bg-neutral-100 atlas-px-3 atlas-py-2")}></th>
      <th
        className={clsx("atlas-bg-neutral-100 atlas-items-end atlas-px-3 atlas-py-2")}
        role="columnheader"
        data-testid="bulk-edit-rows"
      >
        <Tooltip
          content={t(AppraisalActionsTranslations.AppraisalIncome_EditUnits_Tooltip)}
          side="bottom"
          onClick={() => {
            rootStore.currentUnitGroupStore.startEditingUnits(tableId, selectedRowIds);
          }}
          className="atlas-appearance-none atlas-border-none atlas-bg-transparent atlas-rounded-full atlas-p-0 atlas-line-clamp-none atlas-leading-none atlas-flex atlas-items-center atlas-mr-0 atlas-ml-auto atlas-text-neutral-800 atlas-font-bold"
          aria-label={t(AppraisalActionsTranslations.AppraisalIncome_EditUnits_Tooltip)}
        >
          <Icon.PencilLine className="atlas-mr-2" />
          <span>{t(AppraisalActionsTranslations.AppraisalIncome_EditUnits_Button)}</span>
        </Tooltip>
      </th>
      <th
        className={clsx("atlas-bg-neutral-100 atlas-px-3 atlas-py-2 atlas-items-end atlas-w-1/12")}
        role="columnheader"
        data-testid="bulk-delete-rows"
      >
        <Tooltip
          content={t(AppraisalActionsTranslations.AppraisalIncome_DeleteUnits_Tooltip)}
          side="bottom"
          onClick={() => onDeleteSelected(selectedRowIds)}
          className="atlas-appearance-none atlas-border-none atlas-bg-transparent atlas-rounded-full atlas-p-0 atlas-line-clamp-none atlas-leading-none atlas-flex atlas-items-center atlas-mr-0 atlas-ml-auto atlas-text-neutral-800 atlas-font-bold"
          aria-label={t(AppraisalActionsTranslations.AppraisalIncome_DeleteUnits_Tooltip)}
        >
          <Icon.DeleteBin className="atlas-mr-2" />
          <span>{t(AppraisalActionsTranslations.AppraisalIncome_DeleteUnits_Button)}</span>
        </Tooltip>
      </th>
    </tr>
  );
};
