<template>
  <div class="cashflow-page">
    <div ref="cashflowWindow" class="fullscreen" :class="{ full: isFullscreen }">
      <VersionAlert class="mb-4" />
      <b-card class="cashflow" data-intercom-target="cashflow">
        <template #header>
          <div class="flex-row">
            <span>Cashflow</span>
            <div class="pull-right">
              <div class="irr-headers d-inline mr-2">
                Ungeared IRR:
                <span class="font-weight-normal">
                  {{ CashflowFinanceStore.formattedAnnualUngearedIRR }}
                </span>
              </div>
              <div class="irr-headers d-inline mr-2">
                Geared IRR:
                <span class="font-weight-normal">
                  {{ CashflowFinanceStore.formattedAnnualGearedIRR }}
                </span>
              </div>
              <b-button
                @click="exportCashflow"
                variant="outline-secondary"
                v-b-tooltip.hover
                title="Export to Excel"
              >
                <b-icon icon="lt-download"></b-icon>
              </b-button>
              <b-button
                @click="isFullscreen = !isFullscreen"
                variant="outline-secondary"
                v-b-tooltip.hover
                :title="isFullscreen ? 'Exit Fullscreen' : 'Fullscreen'"
              >
                <b-icon :icon="isFullscreen ? 'fullscreen-exit' : 'fullscreen'"></b-icon>
              </b-button>
            </div>
          </div>
        </template>
        <CashflowTable />
        <LineItemModal />
      </b-card>
    </div>
  </div>
</template>

<script lang="ts">
import VersionAlert from "@/components/VersionAlert.vue";
import EventLoggerMixin from "@/mixins/event_logger_mixin";
import { CashflowTable } from "@/react/domains/cashflow/CashflowTable";
import { LineItemModal } from "@/react/domains/cashflow/LineItemModal";
import { rootStore } from "@/react/lib/persistence/root_store";
import { Observer } from "mobx-vue";
import { Component, Mixins, Ref } from "vue-property-decorator";

@Observer
@Component({
  components: {
    VersionAlert,
    LineItemModal: LineItemModal as Vue.Component,
    CashflowTable: CashflowTable as Vue.Component
  }
})
export default class Cashflow extends Mixins(EventLoggerMixin) {
  @Ref() readonly cashflowWindow!: Element;

  private isFullscreen = false;
  private spreadToolsVisible = true;

  private colHovered = false;

  private CashflowFinanceStore = rootStore.cashflowFinanceStore;
  private CashflowStore = rootStore.cashflowStore;
  private CurrentLineItemStore = rootStore.currentLineItemStore;
  private CostStore = rootStore.costStore;
  private BuildPhaseStore = rootStore.buildPhaseStore;
  private AppraisalStore = rootStore.appraisalStore;

  private hasAddedShowSpreadToolsClickEvents = false;

  exportCashflow() {
    this.CashflowStore.exportCashflowToExcel();
    this.logEvent("Cashflow Exported", {
      appraisal_id: this.AppraisalStore.appraisalId,
      development_id: this.AppraisalStore.developmentId
    });
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/lf-theme";
.cashflow-page {
  padding: 1.25rem;
  padding-top: 1.625rem;
  max-height: calc(100vh - 98px);
}

.cashflow-header {
  text-align: center;
  font-size: 0.875rem;

  th {
    background-color: $neutral-white;
  }
}

.cashflow-table {
  max-height: calc(100vh - 98px - 65px - 5rem);
  margin-bottom: 0;
}

.fullscreen.full .cashflow-table {
  max-height: calc(100vh - 65px - 4rem);
}
</style>

<style lang="scss">
@import "@/assets/scss/lf-theme";
</style>
