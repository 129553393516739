import { CostFragment } from "@shared/apollo/fragments";
import gql from "graphql-tag";

export const CREATE_COST_MUTATION = gql`
  mutation createCost($appraisalId: ObjectID!, $cost: CostInput!) {
    createCost(appraisalId: $appraisalId, cost: $cost) {
      ...Cost
    }
  }
  ${CostFragment}
`;

export const UPDATE_COSTS_MUTATION = gql`
  mutation updateCosts($appraisalId: ObjectID!, $costs: [CostInput!]!) {
    updateCosts(appraisalId: $appraisalId, costs: $costs) {
      ...Cost
    }
  }
  ${CostFragment}
`;

export const DELETE_COST_MUTATION = gql`
  mutation deleteCost($appraisalId: ObjectID!, $id: ObjectID!) {
    deleteCost(appraisalId: $appraisalId, id: $id)
  }
`;
