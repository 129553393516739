import { addUnitGroup, deleteUnitGroup, updateUnitGroup } from "@/react/lib/persistence/apollo";
import { ClientUnit } from "@shared/types/unit";
import { ClientUnitGroup } from "@shared/types/unitGroup";
import { totalUnitGroupValue } from "@shared/utils/unit_groups";
import { FetchResult } from "apollo-link";
import ObjectID from "bson-objectid";

export enum UnitGroupOperation {
  ADD = "ADD",
  UPDATE = "UPDATE",
  DELETE = "DELETE"
}
type UnitGroupMutation = (appraisalId: string) => Promise<FetchResult>;

export const unitGroupMutation = (
  operation: UnitGroupOperation,
  unitGroup: ClientUnitGroup
): UnitGroupMutation => {
  switch (operation) {
    case UnitGroupOperation.ADD:
      return (id: string) => addUnitGroup(unitGroup, id);
    case UnitGroupOperation.UPDATE:
      return (id: string) => updateUnitGroup(unitGroup, id);
    case UnitGroupOperation.DELETE:
      return (id: string) => deleteUnitGroup(unitGroup, id);
    default:
      return () => Promise.reject(new Error(`Failed to find mutation for ${operation}`));
  }
};

export const totalValueFromUnitGroups = (unitGroups: ClientUnitGroup[]): number =>
  unitGroups.reduce(
    (sum: number, unitGroup: ClientUnitGroup) => sum + totalUnitGroupValue(unitGroup),
    0
  );

export const generateUnits = (unit: ClientUnit, unitQuantity: number = 1): ClientUnit[] => {
  return Array(unitQuantity)
    .fill(unit)
    .map((u) => ({ ...u, _id: new ObjectID().toHexString() }));
};
