import { makeAutoObservable } from "mobx";
import { IAuthority, ICILRecord } from "@shared/types/CILData";
import { getAuthorities, getCILForAuthority } from "@/react/lib/persistence/apollo";
import { Nullable } from "@shared/types/utils";

export class CILStore {
  currentCILRecord: Nullable<ICILRecord> = null;
  selectedAuthority: Nullable<IAuthority> = null;
  authorities: IAuthority[] = [];
  isLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  async initialise() {
    const data = await getAuthorities();
    this.setAuthorities(data);
  }

  async selectAuthority(gssCode: string) {
    const authority = this.authorities.find((a) => a.gss_code === gssCode);
    if (!authority) {
      throw new Error("Authority not recognised!");
    }
    this.setCILLoading(true);
    this.setCurrentCILRecord(null);
    this.selectedAuthority = authority;
    const data = await getCILForAuthority(gssCode);
    this.setCurrentCILRecord(data);
    this.setCILLoading(false);
  }

  setCILLoading(val: boolean) {
    this.isLoading = val;
  }

  setCurrentCILRecord(val: Nullable<ICILRecord>) {
    this.currentCILRecord = val;
  }

  setAuthorities(val: IAuthority[]) {
    this.authorities = val;
  }

  get hasMayoralCIL(): boolean {
    return (Number(this.currentCILRecord?.mayoral_cil_rates) || 0) > 0;
  }

  get hasCILData(): boolean {
    return (
      !!this.currentCILRecord &&
      !["Unknown", "None", "In development"].includes(this.currentCILRecord.status ?? "")
    );
  }
}
