import { MINI_TEMPLATES } from "@shared/apollo/queries";
import { landFundApolloClient } from "../clients";
import gql from "graphql-tag";
import { logError } from "@/react/utils";
import { ClientMiniTemplate } from "@shared/types/appraisal";

export const getMiniTemplates = async (): Promise<ClientMiniTemplate[]> => {
  const { data, errors } = await landFundApolloClient.query({
    query: gql`
      ${MINI_TEMPLATES}
    `
  });
  if (errors) {
    for (let err of errors) {
      logError(err.message);
    }
    throw new Error(`Error retrieving mini templates`);
  }
  return data.templates;
};
