import React, { FC, PropsWithChildren, ReactNode } from "react";
import { Section } from "../Section";
import clsx from "clsx";
import "./SideBar.css";

type SideBarProps = PropsWithChildren<{
  header?: string | ReactNode;
  testId?: string;
  isVisible?: boolean;
}>;

const SideBar: FC<SideBarProps> = ({ header, testId = "sidebar", children, isVisible = true }) => {
  return (
    <Section
      className={clsx({
        "atlas-absolute atlas-overflow-y-auto atlas-top-0 atlas-bottom-0 atlas-z-10 atlas-transition-all atlas-duration-300 sidebar-width":
          true,
        "sidebar-visible": isVisible,
        "sidebar-hidden": !isVisible
      })}
      data-testid={testId}
    >
      <Section.Header
        className={
          "atlas-border-t-0 atlas-border-l-0 atlas-border-r-0 atlas-border-solid atlas-border atlas-border-neutral-400 atlas-font-bold atlas-text-xl"
        }
      >
        {header}
      </Section.Header>
      <Section.Body className="atlas-m-0 atlas-flex atlas-flex-col atlas-pt-5">
        {children}
      </Section.Body>
    </Section>
  );
};

export { SideBar, SideBarProps };
