<template>
  <div>
    <VersionAlert class="mb-3" />
    <Funding />
  </div>
</template>

<script lang="ts">
import EventLoggerMixin from "@/mixins/event_logger_mixin";
import { Component, Mixins } from "vue-property-decorator";
import VersionAlert from "@/components/VersionAlert.vue";
import { FundingPage as Funding } from "@/react/domains/funding";
import { logEvent } from "@/react/utils";

@Component({
  components: {
    VersionAlert,
    Funding: Funding as Vue.Component
  }
})
export default class FundingPage extends Mixins(EventLoggerMixin) {
  mounted() {
    logEvent("Funding Page Viewed");
  }
}
</script>
