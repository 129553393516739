<template>
  <div class="google-map-container" ref="container">
    <div ref="map" class="google-map"></div>
    <slot v-if="status === 'map-ready'" :map="googleMap"></slot>
  </div>
</template>
<!--
Basic use is as follows:

  <google-map
    :zoom="12"
    :heading="0"
    :center.sync="mapCenter"
    :map-type.sync="mapType"
    @click="onMapClick" />

Note that you can supply values to init/set the map, but you can also
use .sync to read the current state of the map.  It is up to you what
you do for each property.

-->
<script>
import { remove } from "lodash";
// We keep a free-store of size=1, that contains a dom element and its map.
// This means we can render a map fast next time the google-map element is mounted.
// Note that the supplied element is replaced with the dedicated map element.
let freeMap = null;
const aquireMap = (el, opts) => {
  let ret;
  if (freeMap) {
    ret = freeMap;
    freeMap = null;
    ret.map.setOptions(opts); // note that this presumably doesnt apply defaults for missing options
  } else {
    ret = {
      originalDiv: el,
      div: document.createElement("div"),
      map: null,
      idlePromise: null
    };
    ret.div.className = el.className;
    ret.map = new google.maps.Map(ret.div, opts);
    ret.idlePromise = new Promise((res) => google.maps.event.addListenerOnce(ret.map, "idle", res));
  }
  el.parentElement.replaceChild(ret.div, el);
  google.maps.event.trigger(ret.map, "resize"); // tell google about the div's new home
  return ret;
};
const releaseMap = (map) => {
  freeMap = map;
  map.div.parentElement.replaceChild(map.originalDiv, map.div);
};

window.gMapInstances = window.gMapInstances || [];

export default {
  props: {
    // these values reflect what the parent has set
    // they can have .sync applied so that the parent sees the current state of the map
    zoom: { default: 12 },
    center: { default: null }, // "{lat,lng}"
    bounds: { default: null },
    mapType: { default: "satellite" },
    heading: { default: null },
    projection: { default: null },
    isStreetView: { default: false },
    mapTypeControl: { default: false },
    scrollwheel: { default: false }
  },
  data: () => ({
    initialized: false,
    status: null,
    googleMap: null,
    // these reflect the true current state of the map
    live_zoom: 12,
    live_center: null, // "{lat,lng}"
    live_bounds: null,
    live_mapType: "satellite",
    live_heading: null,
    live_projection: null,
    live_isStreetView: false
  }),
  mounted() {
    (this.center || this.bounds) && this.initMap();
  },
  beforeDestroyed() {
    this.$emit("destroyed", this.googleMap);
    google.maps.event.clearInstanceListeners(this.googleMap);
    releaseMap(this._aquiredMap); // IMPORTANT: need to do this before destory is called or vue is over-enthusiatic
    window.gMapInstances = remove(window.gMapInstances, this.googleMap);
    this.googleMap = null;
    this.status = null;
  },
  methods: {
    initMap() {
      const opts = {
        tilt: 0,
        gestureHandling: "greedy",
        scaleControl: true,
        styles: [
          {
            // with an enterprise Google API key some POI's seem to be disabled by default, but we want them shown
            // https://developers.google.com/maps/documentation/javascript/style-reference
            featureType: "poi",
            stylers: [{ visibility: "on" }]
          }
        ],
        fullscreenControl: false,
        mapTypeControl: this.mapTypeControl,
        mapTypeControlOptions: {
          position: google.maps.ControlPosition.TOP_LEFT
        },
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_BOTTOM
        },
        scrollwheel: this.scrollwheel,
        streetViewControl: true,
        streetViewControlOptions: {
          position: google.maps.ControlPosition.RIGHT_BOTTOM
        },
        rotateControl: true,
        rotateControlOptions: {
          position: google.maps.ControlPosition.RIGHT_BOTTOM
        },
        controlSize: 25,
        center: this.bounds ? this.bounds.getCenter() : this.center // required
      };
      this.mapType && (opts.mapTypeId = this.mapType);
      this.zoom && (opts.zoom = this.zoom);
      // TODO: add other opts

      this.status = "loading-map";

      // using our map free-store is a bit fiddly...
      this._aquiredMap = aquireMap(this.$refs.map, opts); // create or re-use div with google map
      this.googleMap = this._aquiredMap.map;
      window.gMapInstances.push(this.googleMap);

      // cannot supply bounds to constructor, so instead we call fitbounds
      this.bounds && this.googleMap.fitBounds(this.bounds);
      this._aquiredMap.idlePromise.then(() => (this.status = "map-ready")); // for re-use of old map, this is already resolved
      // get initial values and register change listeners using the offical google api methods...
      this.live_zoom = this.googleMap.getZoom();
      this.live_center = this.googleMap.getCenter();
      this.live_bounds = this.googleMap.getBounds();
      this.live_mapType = this.googleMap.getMapTypeId();
      this.live_heading = this.googleMap.getHeading();
      this.live_projection = this.googleMap.getProjection();
      this.live_isStreetView = this.googleMap.getStreetView().getVisible();
      google.maps.event.addListener(
        this.googleMap,
        "zoom_changed",
        () => (this.live_zoom = this.googleMap.getZoom())
      );
      google.maps.event.addListener(
        this.googleMap,
        "center_changed",
        () => (this.live_center = this.googleMap.getCenter())
      );
      google.maps.event.addListener(
        this.googleMap,
        "bounds_changed",
        () => (this.live_bounds = this.googleMap.getBounds())
      );
      google.maps.event.addListener(
        this.googleMap,
        "maptypeid_changed",
        () => (this.live_mapType = this.googleMap.getMapTypeId())
      );
      google.maps.event.addListener(
        this.googleMap,
        "resize",
        () => (this.live_bounds = this.googleMap.getBounds())
      );
      google.maps.event.addListener(
        this.googleMap,
        "heading_changed",
        () => (this.live_heading = this.googleMap.getHeading())
      );
      google.maps.event.addListener(
        this.googleMap,
        "projection_changed",
        () => (this.live_projection = this.googleMap.getProjection())
      );
      google.maps.event.addListener(
        this.googleMap.getStreetView(),
        "visible_changed",
        () => (this.live_isStreetView = this.googleMap.getStreetView().getVisible())
      );

      google.maps.event.addListener(this.googleMap, "click", (e) => this.$emit("click", e));
      google.maps.event.addListener(this.googleMap, "mousemove", (e) => this.$emit("mousemove", e));

      this.initialized = true;
    },
    emitStatus() {
      if (this.initialized && this.status === "map-ready") {
        this.$emit("ready");
      }
    }
  },
  watch: {
    initialized(v) {
      if (v) {
        this.$emit("initialized", this.googleMap);
      }
      this.emitStatus();
    },
    status(v) {
      this.emitStatus();
    },
    // watch for internal changes, and enable use of .sync on non-prefix props...
    live_zoom(v) {
      this.$emit("update:zoom", v);
    },
    live_center(v) {
      this.$emit("update:center", v);
    },
    live_bounds(v) {
      this.$emit("update:bounds", v);
    },
    live_mapType(v) {
      this.$emit("update:mapType", v);
    },
    live_heading(v) {
      this.$emit("update:heading", v);
    },
    live_projection(v) {
      this.$emit("update:projection", v);
    },
    live_isStreetView(v) {
      this.$emit("update:isStreetView", v);
    },

    // watch for parent making changes, and apply them to the map,
    // which in turn will trigger an internal change
    zoom(v) {
      v && this.googleMap && this.googleMap.setZoom(v);
    },
    center(v) {
      v && !this.googleMap && this.initMap();
      v && this.googleMap && this.googleMap.setCenter(v);
    },
    bounds(v) {
      v && !this.googleMap && this.initMap();
      v && this.googleMap && this.googleMap.fitBounds(v);
    },
    mapType(v) {
      v && this.googleMap && this.googleMap.setMapTypeId(v);
    },
    heading(v) {
      v && this.googleMap && this.googleMap.setHeading(v);
    },
    projection(v) {
      v && this.googleMap && this.googleMap.setProjection(v);
    },
    isStreetView() {
      /* TODO: implement setting of streetview */
    }
  }
};
</script>

<style lang="scss">
.google-map-container {
  display: flex;
  .google-map {
    flex-grow: 1;
  }
}
</style>
