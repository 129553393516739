import { Nullable } from "@shared/types/utils";
import { makeAutoObservable } from "mobx";
import { FloodRisk, ISiteConstraints } from "@shared/types/siteConstraints";
import { getSiteConstraints } from "@/react/lib/persistence/apollo";
import { RootStore } from "../Root";

export class SiteConstraintStore {
  root: RootStore;
  siteConstraints: Nullable<ISiteConstraints> = null;
  mapDimensions = {
    height: 212,
    width: 308
  };

  constructor(root: RootStore) {
    this.root = root;
    makeAutoObservable(this, { root: false });
  }

  setSiteConstraints(value: Nullable<ISiteConstraints>) {
    this.siteConstraints = value;
  }

  async getSiteConstraints() {
    if (!this.root.currentDevelopmentStore.site) {
      return new Error("There is no site loaded!");
    }
    try {
      const { type, coordinates } = this.root.currentDevelopmentStore.site.geometry;
      const siteConstraints = await getSiteConstraints(type, coordinates);
      this.setSiteConstraints(siteConstraints);
    } catch {
      this.setSiteConstraints(null);
    }
  }

  get floodRisk() {
    const riskLevel = this.siteConstraints?.flood_risk ?? "NONE";
    const title = FloodRisk[riskLevel];
    switch (riskLevel) {
      case "HIGH":
        return {
          title,
          class: "high-risk"
        };
      case "MEDIUM":
        return {
          title,
          class: "medium-risk"
        };
      case "LOW":
      case "VERY_LOW":
      case "NONE":
      default:
        return {
          title,
          class: "no-risk"
        };
    }
  }

  get hasProtectedLandRisk() {
    return (
      this.siteConstraints?.greenbelt ||
      this.siteConstraints?.aonb ||
      this.siteConstraints?.common_land
    );
  }

  get mapDimensionStyle() {
    return {
      height: `${this.mapDimensions.height}px`,
      width: `${this.mapDimensions.width}px`
    };
  }

  get hasNearbyListedBuildings() {
    return !!this.siteConstraints?.listed_buildings;
  }
}
