import { FinancialSummaryPresentation } from "@/react/domains/appraisal/FinancialSummary/";
import { rootStore } from "@/react/lib/persistence/root_store";
import { observer } from "mobx-react-lite";
import React, { FC, useMemo } from "react";

const FinancialSummary: FC = () => <ObseveredFinancialSummary />;

const ObseveredFinancialSummary: FC = observer(() => {
  const unitGroupStore = useMemo(
    () => rootStore.unitGroupStore,
    [
      rootStore.unitGroupStore.totalSales,
      rootStore.unitGroupStore.totalSalesFees,
      rootStore.unitGroupStore.convertedTotalNIA,
      rootStore.unitGroupStore.hasTooLowGIA,
      rootStore.unitGroupStore.convertedTotalGIA,
      rootStore.unitGroupStore.salesPerAreaUnit
    ]
  );
  const costStore = useMemo(
    () => rootStore.costStore,
    [
      rootStore.costStore.totalLandCosts,
      rootStore.costStore.totalProfessionalFees,
      rootStore.costStore.totalOtherCosts,
      rootStore.costStore.profit,
      rootStore.costStore.totalCostsWithContingency,
      rootStore.costStore.profitOnGDV,
      rootStore.costStore.profitOnCost
    ]
  );
  const buildPhaseStore = useMemo(
    () => rootStore.buildPhaseStore,
    [
      rootStore.buildPhaseStore.totalBuildCostWithContingency,
      rootStore.buildPhaseStore.buildCostWithContingencyPerAreaUnit
    ]
  );
  const loanStore = useMemo(() => rootStore.loanStore, [rootStore.loanStore.totalFundingCosts]);
  const equityFundingStore = useMemo(
    () => rootStore.equityFundingStore,
    [rootStore.equityFundingStore.equityFundingLength, rootStore.equityFundingStore.developerProfit]
  );
  const unit = useMemo(() => rootStore.userStore.areaUnit, [rootStore.userStore.areaUnit]);

  return (
    <FinancialSummaryPresentation
      unitGroupStore={unitGroupStore}
      costStore={costStore}
      buildPhaseStore={buildPhaseStore}
      loanStore={loanStore}
      equityFundingStore={equityFundingStore}
      unit={unit}
    />
  );
});

export { FinancialSummary };
