import { ClientUnitGroup } from "@shared/types/unitGroup";
import { landFundApolloClient } from "../clients";
import gql from "graphql-tag";
import { ADD_UNIT_GROUP, DELETE_UNIT_GROUP, UPDATE_UNIT_GROUP } from "../gql/unitGroup";
import { sanitiseInput, updateAppraisalCache } from "../utils";
import { getCachedAppraisal } from "../queries";

export const addUnitGroup = async (unitGroup: ClientUnitGroup, appraisalId: string) => {
  return landFundApolloClient.mutate({
    mutation: gql`
      ${ADD_UNIT_GROUP}
    `,
    variables: {
      unitGroup: sanitiseInput(unitGroup, false),
      appraisalId
    },
    update: (store, { data }) => {
      let cachedAppraisal = getCachedAppraisal(appraisalId);
      cachedAppraisal.unitGroups?.push(data.createUnitGroup);
      updateAppraisalCache(store, cachedAppraisal);
    }
  });
};

export const updateUnitGroup = async (unitGroup: ClientUnitGroup, appraisalId: string) => {
  return landFundApolloClient.mutate({
    mutation: gql`
      ${UPDATE_UNIT_GROUP}
    `,
    variables: {
      unitGroup: sanitiseInput(unitGroup, false),
      appraisalId
    }
  });
};

export const deleteUnitGroup = async (unitGroup: ClientUnitGroup, appraisalId: string) => {
  return landFundApolloClient.mutate({
    mutation: gql`
      ${DELETE_UNIT_GROUP}
    `,
    variables: {
      id: unitGroup._id,
      appraisalId
    },
    update: (store) => {
      const cachedAppraisal = getCachedAppraisal(appraisalId);
      cachedAppraisal.unitGroups = cachedAppraisal.unitGroups?.filter(
        (ug) => ug._id !== unitGroup._id
      );
      updateAppraisalCache(store, cachedAppraisal);
    }
  });
};
