import clsx from "clsx";
import React, {
  Dispatch,
  FC,
  PropsWithChildren,
  ReactNode,
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { Icon } from "../../Icons";
import { InputError } from "../types";
import { InfoTooltipContextType, Tooltip, TooltipThemes } from "@/react/components";
import { InfoTooltip } from "../../Tooltip";
import { formatNumberToString, formatStringToNumber } from "@/react/utils/formatting";

export enum Sizes {
  SM = "sm",
  MD = "md",
  LG = "lg"
}

export type InputOnChangeValueType = number | string | undefined;

export type InputProps = {
  className?: string;
  prefix?: ReactNode;
  suffix?: ReactNode;
  label?: ReactNode;
  labelVisible?: boolean;
  id: string;
  value?: string | number;
  size?: Sizes;
  type?: React.HTMLInputTypeAttribute;
  onChange?: Dispatch<string>;
  error?: InputError;
  disabled?: boolean;
  inputClassName?: string;
  rounded?: boolean;
  trackCursor?: boolean; // When using a formatter that adds commas to a number the cursor gets lost on change. Setting this to true will track the cursor between formats
  testId?: string;
} & Omit<React.HTMLAttributes<HTMLInputElement>, "onChange">;

export const InfoTooltipContext = createContext<InfoTooltipContextType | null>(null);

const TooltipWrapper = ({
  children: tooltipChildren,
  tooltip
}: PropsWithChildren<{ tooltip?: string }>) => (
  <Tooltip content={tooltip ?? ""} theme={TooltipThemes.DANGER}>
    {tooltipChildren}
  </Tooltip>
);

const InputWrapper: FC<
  PropsWithChildren<{
    label: ReactNode;
    className?: string;
    labelVisible?: boolean;
    tooltip?: string;
  }>
> = ({ label, children, className, labelVisible, tooltip }) => {
  const Wrapper = tooltip ? TooltipWrapper : React.Fragment;
  const infoTooltipContext = useContext(InfoTooltipContext) as InfoTooltipContextType;
  const infoTooltip = infoTooltipContext?.description ?? "";
  const WrapperProps: Record<string, string> = {};
  if (tooltip) {
    WrapperProps.tooltip = tooltip;
  }
  if (label) {
    return (
      <Wrapper {...WrapperProps}>
        <label className={className}>
          <div className="atlas-flex">
            <span
              className={clsx(
                "atlas-mb-2",
                "atlas-h-6",
                "atlas-whitespace-nowrap",
                { "atlas-sr-only": !labelVisible },
                { "atlas-block": !infoTooltipContext }
              )}
            >
              {label}
            </span>
            {infoTooltipContext && <InfoTooltip content={infoTooltip} />}
          </div>
          {children}
        </label>
      </Wrapper>
    );
  } else {
    return (
      <Wrapper {...WrapperProps}>
        <label className={className}>{children}</label>
      </Wrapper>
    );
  }
};

const Input = forwardRef<any, PropsWithChildren<InputProps>>(
  (
    {
      id,
      prefix,
      suffix,
      label,
      onChange = () => null,
      error = { message: "", display: false, tooltip: false },
      labelVisible = true,
      size = Sizes.MD,
      children,
      className,
      inputClassName = "atlas-w-full",
      testId = "input",
      rounded = false,
      trackCursor = false,
      value,
      ...rest
    },
    ref
  ) => {
    const lgClasses = { y: "atlas-py-3", x: "atlas-px-4" };
    const smClasses = { y: "atlas-py-1", x: "atlas-px-2" };

    let sizeClasses = { y: "atlas-py-2", x: "atlas-px-3" };
    if (size === Sizes.LG) {
      sizeClasses = lgClasses;
    }
    if (size === Sizes.SM) {
      sizeClasses = smClasses;
    }

    // TODO it makes more sense for the Input component to just handle formatNumberToString/formatStringToNumber and not require a formatter to be provided.
    // That way we can use the Input without worrying if the formatting is consistent +1
    // It will also allow us to remove the trackCursor boolean and make DebouncedInput only handle debouncing
    if (rounded && value) {
      if (typeof value === "number") {
        value = Math.round(value);
      }
      if (typeof value === "string") {
        value = formatNumberToString(Math.round(formatStringToNumber(value)));
      }
    }

    const InputComponent = trackCursor ? ControlledInput : UnControlledInput;

    return (
      <InputWrapper
        label={label}
        className={className}
        labelVisible={labelVisible}
        tooltip={error.tooltip && error.display ? error.message : undefined}
      >
        <div className="atlas-flex focus-within:atlas-ring-2 focus-within:atlas-ring-offset-3 focus-within:atlas-ring-primary-600 atlas-rounded atlas-items-stretch">
          <div
            className={clsx(
              "atlas-flex atlas-border atlas-border-solid atlas-border-neutral-400 atlas-items-center atlas-whitespace-nowrap atlas-flex-grow",
              [
                error.display ? "atlas-border-red-600 atlas-text-red-600" : "atlas-text-neutral-800"
              ],
              [rest.disabled ? "atlas-bg-neutral-100" : "atlas-bg-white"],
              [children ? "atlas-rounded-l atlas-w-1/2" : "atlas-rounded atlas-w-full"]
            )}
          >
            {!!prefix && <div className={clsx(sizeClasses.x, "atlas-pr-0")}>{prefix}</div>}
            <InputComponent
              id={id}
              onChange={(evt) => onChange(evt.currentTarget.value)}
              data-testid={testId}
              onKeyDown={(evt) => rounded && evt.key === "." && evt.preventDefault()}
              className={clsx(
                "atlas-bg-transparent atlas-flex-shrink atlas-appearance-none atlas-border-0 atlas-shadow-none",
                "focus:atlas-outline-none atlas-placeholder-neutral-700  atlas-rounded atlas-h-full atlas-w-full",
                "atlas-text-inherit atlas-box-border atlas-font-sans",
                sizeClasses.x,
                sizeClasses.y,
                [error.display ? "atlas-text-red-600" : "atlas-text-neutral-800"],
                inputClassName
              )}
              aria-label={`${label ?? id} input`}
              value={value}
              ref={ref}
              {...rest}
            />
            {!!suffix && <div className={clsx(sizeClasses.x, "atlas-pl-0")}>{suffix}</div>}
            {error.display && (
              <div className={clsx(sizeClasses.x, "atlas-pl-0")}>
                <Icon.InformationLine className="atlas-w-4" />
              </div>
            )}
          </div>
          {children}
        </div>
        {error.display && !error.tooltip && (
          <div className="atlas-text-xs atlas-mt-2 atlas-text-red-600" data-testid="input-error">
            {error.message}
          </div>
        )}
      </InputWrapper>
    );
  }
);

const ControlledInput: React.FC<React.ComponentProps<"input">> = (props) => {
  const { value, onChange, ...rest } = props;
  const [cursor, setCursor] = useState<number | null>(null);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    ref.current?.setSelectionRange(cursor, cursor);
  }, [ref, cursor, value]);

  // This function ensure that cursor position isn't lost during formatting
  // When a comma is added or removed the cursor needs to jump forward or backwards an extra step
  // TODO: There is still an issue with backspacing or deleting next to a comma
  // https://www.notion.so/landtech/LandFund-Dynamic-Input-Formatting-Issues-Notes-fbb9fc9a5e84439aa21e6985b53b3dfa
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let cursorPosition = e.target.selectionStart;
    const newValue = e.target.value;
    const newValueWithoutDecimal = e.target.value.split(".")[0];
    if (value && cursorPosition && newValueWithoutDecimal.length % 4 === 0) {
      setCursor(
        value.toString().length <= newValue.length ? cursorPosition + 1 : cursorPosition - 1
      );
    } else {
      setCursor(e.target.selectionStart);
    }
    onChange?.(e);
  };

  return <input ref={ref} value={value} onChange={handleChange} {...rest} />;
};

const UnControlledInput = forwardRef((props, ref: React.ForwardedRef<HTMLInputElement>) => {
  return <input {...props} ref={ref} />;
});

const InputSuffix: FC<PropsWithChildren<{ className?: string; testId?: string }>> = ({
  children,
  className = "atlas-w-1/2",
  testId = "input-suffix"
}) => (
  <div
    className={clsx(
      "focus:atlas-outline-none",
      "focus:atlas-border-0",
      "focus-visible:atlas-outline-none",
      "focus-visible:atlas-border-0",
      "atlas-min-h-full",
      "atlas-bg-neutral-100",
      "atlas-text-base",
      "atlas-py-1",
      "atlas-px-1",
      "atlas-flex",
      "atlas-items-center",
      "atlas-text-neutral-800",
      "atlas-border-l-0 atlas-border atlas-border-neutral-300 atlas-border-solid",
      "atlas-rounded-r",
      className
    )}
    data-testid={testId}
  >
    <span className="atlas-w-full atlas-block">{children}</span>
  </div>
);

export { Input, InputSuffix, ControlledInput };
