import {
  findEndIndex,
  findStartIndex,
  initLineItemExtended,
  rowTotalRemaining
} from "@/react/utils/lineItem";
import { exportToExcel } from "@/utils/cashflow_export";
import {
  ClientLineItem,
  ClientLineItemExtended,
  CurveType,
  LineItemDescription,
  LineItemType,
  OverallBuildStage
} from "@shared/types/cashflow";
import { CostType } from "@shared/types/costs";
import { ClientUnit } from "@shared/types/unit";
import { ClientUnitGroup } from "@shared/types/unitGroup";
import { Nullable } from "@shared/types/utils";
import { totalUnitGroupSalesFees, totalUnitGroupValue } from "@shared/utils/unit_groups";
import { formatNumber, pluralise } from "@shared/utils/formatting";
import { sCurve } from "@shared/utils/scurve";
import { cloneDeep, orderBy } from "lodash";
import { AppraisalStore } from "../Appraisal/AppraisalStore";
import { BuildPhaseStore } from "../BuildPhase/BuildPhaseStore";
import { CashflowFinanceStore } from "../CashflowFinance/CashflowFinanceStore";
import { CostStore } from "../Cost/CostStore";
import {
  calculateWithLength,
  calculateWithRate
} from "../CurrentLineItem/CurrentLineItemStoreUtil";
import { UnitGroupStore } from "../UnitGroup/UnitGroupStore";

export const calculateUnitsPerCell = (
  length: number,
  units: ClientUnit[],
  lineItem: ClientLineItemExtended,
  numMonths: number
) => {
  let unitsCount = units.length;
  let lengthCeil = Math.ceil(length);
  let cells = new Array(Math.max(numMonths, lineItem.startIndex + lengthCeil)).fill(0);
  let rate = Math.ceil(lineItem.rate);
  let unitsPerCell = [...cells];

  if (lengthCeil > 1) {
    if (lengthCeil % unitsCount === 0) {
      let posCount = 0;
      for (let i = lineItem.startIndex; i < lineItem.startIndex + lengthCeil; i++) {
        unitsPerCell[i] = Math.min(posCount + rate, units.length) - posCount;
        posCount++;
      }
    } else {
      let unitsUsed = 0;
      let i = 0;
      for (i = lineItem.startIndex; unitsUsed < unitsCount; unitsUsed += rate) {
        unitsPerCell[i] = Math.min(unitsUsed + rate, units.length) - unitsUsed;
        i++;
      }
    }
  } else {
    if (lengthCeil === 1) {
      unitsPerCell[lineItem.startIndex] = unitsCount;
    }
  }

  lineItem.units = unitsPerCell;
};

export const extendedCells = (cells: number[], numMonths: number) => {
  const extraMonths = numMonths - cells.length;
  if (extraMonths <= 0) {
    return cells;
  }
  return cells.concat(new Array(extraMonths).fill(0));
};

export const exportCashflowToExcel = async (
  cashflowFinanceStore: CashflowFinanceStore,
  appraisalStore: AppraisalStore
) => {
  const tableSelect = document.getElementById("cashflow-table") as HTMLTableElement;
  if (tableSelect) {
    let blobXlsx;
    blobXlsx = await exportToExcel(
      tableSelect,
      cashflowFinanceStore.annualUngearedIRR,
      cashflowFinanceStore.annualGearedIRR
    );
    const downloadLink = document.createElement("a");
    const url = URL.createObjectURL(blobXlsx);
    downloadLink.download = `${appraisalStore.title} - LandFund Cashflow.xlsx`;
    downloadLink.href = url;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(url);
  }
};

export const columnTotal = (index: number, lineItems: ClientLineItemExtended[]) => {
  return lineItems.reduce((sum, row) => sum + row.cells[index], 0);
};

export const contingencyForColumn = (
  index: number,
  contingencyLineItem?: Nullable<ClientLineItemExtended>
) => {
  return contingencyLineItem?.cells[index] ?? 0;
};

export const salesCostColTotal = (
  index: number,
  salesLineItems: ClientLineItemExtended[],
  unitGroupStore: UnitGroupStore
): number => {
  return salesLineItems.reduce((sum, row) => {
    const unitGroup = unitGroupStore.unitGroups.find((ug) => ug._id === row._linkedId);
    if (row.cells[index] === 0) {
      return sum + 0;
    } else {
      const totalFees = unitGroup ? totalUnitGroupSalesFees(unitGroup) : 0;
      const columnFeePercentage: number = row.cells[index] / row.value;
      const columnFees = totalFees * columnFeePercentage;
      return sum + columnFees;
    }
  }, 0);
};

export const costTypeToLineType = (costType: string) => {
  if (costType === CostType.ProfessionalFee) {
    return LineItemType.ProfessionalFee;
  } else if (costType === CostType.OtherCost) {
    return LineItemType.OtherCost;
  } else if (
    [CostType.LandFee, CostType.StampDuty, CostType.Deposit].includes(costType as CostType)
  ) {
    return LineItemType.Land;
  } else if (costType === CostType.Construction) {
    return LineItemType.BuildPhase;
  } else {
    throw new Error("Can't find corresponding line item for unsupported cost type " + costType);
  }
};

export const getExtendedUnitGroupLineItems = (
  lineItems: ClientLineItem[],
  unitGroupStore: UnitGroupStore,
  numMonths: number
): ClientLineItemExtended[] => {
  const extendedLineItems = lineItems
    .filter((lineItem) => lineItem.type === LineItemType.UnitGroup)
    .map((lineItem) =>
      extendedUnitGroupLineItem(lineItem as ClientLineItemExtended, unitGroupStore, numMonths)
    )
    .filter((lineItem) => lineItem !== null);

  return orderBy(extendedLineItems, "position") as ClientLineItemExtended[];
};

export const getExtendedBuildPhaseLineItems = (
  lineItems: ClientLineItem[],
  costStore: CostStore,
  buildPhaseStore: BuildPhaseStore,
  numMonths: number
): ClientLineItemExtended[] => {
  const extendedLineItems = lineItems
    .filter((lineItem) => lineItem.type === LineItemType.BuildPhase)
    .map((lineItem) =>
      extendedBuildPhaseLineItem(
        lineItem as ClientLineItemExtended,
        buildPhaseStore,
        costStore,
        numMonths
      )
    )
    .filter((lineItem) => lineItem !== null);

  return orderBy(extendedLineItems, "position") as ClientLineItemExtended[];
};

export const getExtendedContingencyLineItems = (
  lineItems: ClientLineItem[],
  buildPhaseStore: BuildPhaseStore,
  numBuildPhaseItems: number,
  numMonths: number
): ClientLineItemExtended[] => {
  const extendedLineItems = lineItems
    .filter((lineItem) => lineItem.type === LineItemType.Contingency)
    .map((lineItem) =>
      extendedContingencyLineItem(
        lineItem as ClientLineItemExtended,
        buildPhaseStore,
        numBuildPhaseItems,
        numMonths
      )
    )
    .filter((lineItem) => lineItem !== null);

  return orderBy(extendedLineItems, "position") as ClientLineItemExtended[];
};

export const getExtendedProfFeesLineItems = (
  lineItems: ClientLineItem[],
  costStore: CostStore,
  numMonths: number
): ClientLineItemExtended[] => {
  const extendedLineItems = lineItems
    .filter((lineItem) => lineItem.type === LineItemType.ProfessionalFee)
    .map((lineItem) =>
      extendedCostLineItem(lineItem as ClientLineItemExtended, costStore, numMonths)
    )
    .filter((lineItem) => lineItem !== null);

  return orderBy(extendedLineItems, "position") as ClientLineItemExtended[];
};
export const getExtendedOtherCostLineItems = (
  lineItems: ClientLineItem[],
  costStore: CostStore,
  numMonths: number
): ClientLineItemExtended[] => {
  const extendedLineItems = lineItems
    .filter((lineItem) => lineItem.type === LineItemType.OtherCost)
    .map((lineItem) =>
      extendedCostLineItem(lineItem as ClientLineItemExtended, costStore, numMonths)
    )
    .filter((lineItem) => lineItem !== null);

  return orderBy(extendedLineItems, "position") as ClientLineItemExtended[];
};

export const getExtendedLandLineItems = (
  lineItems: ClientLineItem[],
  costStore: CostStore,
  appraisalStore: AppraisalStore,
  numMonths: number
): ClientLineItemExtended[] => {
  const extendedLineItems = lineItems
    .filter((lineItem) => lineItem.type === LineItemType.Land)
    .map((lineItem) => extendedLandLineItem(lineItem, costStore, appraisalStore, numMonths))
    .filter((lineItem) => lineItem !== null);

  return orderBy(extendedLineItems, "position") as ClientLineItemExtended[];
};

export const extendLineItem = (
  lineItem: ClientLineItem,
  unitGroupStore: UnitGroupStore,
  costStore: CostStore,
  appraisalStore: AppraisalStore,
  buildPhaseStore: BuildPhaseStore,
  numBuildPhaseItems: number,
  numMonths: number
): Nullable<ClientLineItemExtended> => {
  switch (lineItem.type) {
    case LineItemType.UnitGroup:
      return extendedUnitGroupLineItem(lineItem, unitGroupStore, numMonths);
    case LineItemType.BuildPhase:
      return extendedBuildPhaseLineItem(lineItem, buildPhaseStore, costStore, numMonths);
    case LineItemType.Contingency:
      return extendedContingencyLineItem(lineItem, buildPhaseStore, numBuildPhaseItems, numMonths);
    case LineItemType.ProfessionalFee:
    case LineItemType.OtherCost:
      return extendedCostLineItem(lineItem, costStore, numMonths);
    case LineItemType.Land:
      return extendedLandLineItem(lineItem, costStore, appraisalStore, numMonths);

    default:
      throw new Error(`Unexpected line item type ${lineItem.type}`);
  }
};

const extendedLineItemBase = (
  lineItem: ClientLineItem,
  numMonths: number
): ClientLineItemExtended =>
  cloneDeep({
    ...initLineItemExtended,
    ...lineItem,
    startIndex: findStartIndex(lineItem.cells),
    endIndex: findEndIndex(lineItem.cells),
    cells: extendedCells(lineItem.cells, numMonths)
  });

const extendedUnitGroupLineItem = (
  lineItem: ClientLineItem,
  unitGroupStore: UnitGroupStore,
  numMonths: number
): Nullable<ClientLineItemExtended> => {
  const unitGroup = unitGroupStore.unitGroups.find((ug) => ug._id === lineItem._linkedId);

  if (!unitGroup) {
    return null;
  }

  const extendedLineItem: ClientLineItemExtended = extendedLineItemBase(lineItem, numMonths);

  const nUnitsString = pluralise("unit", unitGroup.units?.length || 0);
  const unitsCount = unitGroup.units?.length || 0;
  const length = extendedLineItem.endIndex - extendedLineItem.startIndex + 1;

  extendedLineItem.value = totalUnitGroupValue(unitGroup);
  return {
    ...extendedLineItem,
    description: `${unitGroup.description} (${nUnitsString})`,
    position: unitGroup.position,
    rate: unitsCount / length,
    valueRemaining: rowTotalRemaining(extendedLineItem)
  };
};

const extendedBuildPhaseLineItem = (
  lineItem: ClientLineItem,
  buildPhaseStore: BuildPhaseStore,
  costStore: CostStore,
  numMonths: number
): Nullable<ClientLineItemExtended> => {
  const buildPhase = buildPhaseStore.buildPhases.find((bp) => bp._id === lineItem._linkedId);

  if (!buildPhase || !lineItem._linkedId) {
    return null;
  }

  const extendedLineItem = extendedLineItemBase(lineItem, numMonths);

  extendedLineItem.value = costStore.buildPhaseTotalCost(lineItem._linkedId);
  return {
    ...extendedLineItem,
    description: buildPhase.description,
    position: buildPhase.position,
    valueRemaining: rowTotalRemaining(extendedLineItem)
  };
};

const extendedContingencyLineItem = (
  lineItem: ClientLineItem,
  buildPhaseStore: BuildPhaseStore,
  numBuildPhaseItems: number,
  numMonths: number
): ClientLineItemExtended => {
  const extendedLineItem = extendedLineItemBase(lineItem, numMonths);
  extendedLineItem.value = buildPhaseStore.totalBuildContingency;
  return {
    ...extendedLineItem,
    description: "Contingency",
    position: numBuildPhaseItems,
    valueRemaining: rowTotalRemaining(extendedLineItem)
  };
};

const extendedCostLineItem = (
  lineItem: ClientLineItem,
  costStore: CostStore,
  numMonths: number
): Nullable<ClientLineItemExtended> => {
  const cost = costStore.metricCosts.find((c) => c._id === lineItem._linkedId);

  if (!cost) {
    return null;
  }

  const extendedLineItem = extendedLineItemBase(lineItem, numMonths);

  extendedLineItem.value = cost.value;
  return {
    ...extendedLineItem,
    description: cost.description || "",
    position: cost.position,
    valueRemaining: rowTotalRemaining(extendedLineItem)
  };
};

const extendedLandLineItem = (
  lineItem: ClientLineItem,
  costStore: CostStore,
  appraisalStore: AppraisalStore,
  numMonths: number
): ClientLineItemExtended => {
  const extendedLineItem = extendedLineItemBase(lineItem, numMonths);
  const cost = costStore.metricCosts.find((c) => c._id === extendedLineItem._linkedId);

  if (!cost) {
    extendedLineItem.position = 1;
    extendedLineItem.description = LineItemDescription.NetPurchasePrice;
    extendedLineItem.value = appraisalStore.purchasePrice - (costStore.deposit?.value || 0);
  } else if (cost.type === CostType.Deposit) {
    extendedLineItem.position = 0;
    extendedLineItem.description = `Deposit (${formatNumber({
      value: cost.calculationBase,
      maxDecimals: 2
    })} %)`;
    extendedLineItem.value = cost.value;
  } else if (cost.type === CostType.StampDuty) {
    extendedLineItem.position = 2;
    extendedLineItem.description = "Stamp Duty";
    extendedLineItem.value = cost.value;
  } else {
    extendedLineItem.position += cost.position + 3;
    extendedLineItem.description = cost.description || "";
    extendedLineItem.value = cost.value;
  }

  return {
    ...extendedLineItem,
    valueRemaining: rowTotalRemaining(extendedLineItem)
  };
};

export const spreadUnitGroupLineItem = (
  lineItem: ClientLineItemExtended,
  unitGroupStore: UnitGroupStore,
  numMonths: number
) => {
  const unitGroup: ClientUnitGroup | undefined = unitGroupStore.unitGroups.find(
    (ug) => ug._id === lineItem._linkedId
  );

  if (!unitGroup) {
    throw new Error("Can't find a UnitGroup linked to this lineItem");
  }

  let unitsCount = unitGroup.units?.length || 0;
  let length = 0;

  if (lineItem.endIndex > -1) {
    // Use length to calculate rate
    length = lineItem.endIndex - lineItem.startIndex + 1;
    calculateWithLength(length, unitGroup.units || [], lineItem, numMonths);
  } else {
    length = unitsCount / lineItem.rate;
    calculateWithRate(length, unitGroup.units || [], lineItem, numMonths);
  }
};

export const calculateCurve = (lineItem: ClientLineItemExtended, numMonths: number): number[] => {
  let length = lineItem.endIndex - lineItem.startIndex + 1;
  let cells = new Array(Math.max(numMonths, lineItem.startIndex + length)).fill(0);

  if (length === 1) {
    cells[lineItem.startIndex] = lineItem.value;
    return cells;
  } else if (length === 2) {
    cells[lineItem.startIndex] = lineItem.value / 2;
    cells[lineItem.startIndex + 1] = lineItem.value / 2;
    return cells;
  }

  switch (lineItem.curveType) {
    case CurveType.Line:
      cells.fill(lineItem.value / length, lineItem.startIndex, lineItem.endIndex + 1);
      break;

    case CurveType.SCurve:
      let value = 0;
      let prevCells = 0;
      let steepnessValue = steepness(length);
      for (let i = 0; i < length; i++) {
        let sCurveValue = sCurve(i, (length - 2) / 2, steepnessValue);
        value = sCurveValue;

        let prevCell =
          lineItem.startIndex + i - 1 > 0 ? cells[lineItem.startIndex + i - 1] : cells[0];
        prevCells += prevCell;

        cells[lineItem.startIndex + i] = value * lineItem.value - prevCells;
      }

      let total = cells.reduce((a, b) => a + b);
      let remaining = lineItem.value - total;
      cells[lineItem.endIndex] += remaining;
      break;

    case CurveType.Custom:
      return lineItem.cells;
  }
  return cells;
};

export const steepness = (length: number): number => {
  let mid = length / 4;
  return length / (length * mid - 1) + 0.5;
};

export const isAutomatedCurveType = (extendedLineItem: ClientLineItem) =>
  [CurveType.Line, CurveType.SCurve].includes(extendedLineItem.curveType);

export const BUILD_PHASE_LINE_ITEM_DEFAULT_START = 3;
export const BUILD_PHASE_LINE_ITEM_DEFAULT_END = 14;

export const getDefaultIndexes = (
  lineItem: ClientLineItemExtended,
  currentOverallBuildStage: OverallBuildStage
): { startIndex: number; endIndex: number } => {
  switch (lineItem.type) {
    case LineItemType.ProfessionalFee:
      return {
        startIndex: currentOverallBuildStage.startIndex,
        endIndex: currentOverallBuildStage.endIndex
      };
    case LineItemType.OtherCost:
      return {
        startIndex: 2,
        endIndex: 2
      };
    case LineItemType.BuildPhase:
      return {
        startIndex: BUILD_PHASE_LINE_ITEM_DEFAULT_START,
        endIndex: BUILD_PHASE_LINE_ITEM_DEFAULT_END
      };
    case LineItemType.Contingency:
      return {
        startIndex: currentOverallBuildStage.middleOfBuildPhaseIndex,
        endIndex: currentOverallBuildStage.endIndex
      };
    case LineItemType.Land:
      if (lineItem.description === LineItemDescription.Deposit) {
        return {
          startIndex: 0,
          endIndex: 0
        };
      }
      return {
        startIndex: 1,
        endIndex: 1
      };
    case LineItemType.UnitGroup: {
      const salesStartIndex = currentOverallBuildStage.endIndex + 1;
      return {
        startIndex: salesStartIndex,
        endIndex: salesStartIndex + 5
      };
    }
    default:
      throw new Error("no defaults are specified for LineItems of this type");
  }
};
export const getDefaultCurveType = (lineItemType: LineItemType): CurveType => {
  switch (lineItemType) {
    case LineItemType.ProfessionalFee:
      return CurveType.Line;
    case LineItemType.OtherCost:
      return CurveType.Line;
    case LineItemType.BuildPhase:
      return CurveType.SCurve;
    case LineItemType.Contingency:
      return CurveType.Line;
    case LineItemType.Land:
      return CurveType.Line;
    case LineItemType.UnitGroup:
      return CurveType.Line;
    default:
      throw new Error("no default curve type are specified for LineItems of this type");
  }
};

export const lineItemMonthsNotSet = (lineItem: ClientLineItemExtended) => {
  return lineItem.startIndex < 0 || lineItem.endIndex < 0;
};
