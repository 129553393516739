import { Confirm, RowActions, SelectableTable } from "@/react/components";
import { useTranslation } from "@/react/lib/hooks";
import {
  AppraisalFundingTranslations,
  AppraisalIncomeUnitsTranslations
} from "@/react/lib/i18n/locales";
import { rootStore } from "@/react/lib/persistence/root_store";
import { logEvent } from "@/react/utils";
import { UnitGroupExitType, UnitGroupType } from "@/utils/unit_groups";
import { ClientUnit } from "@shared/types/unit";
import { ClientUnitGroup } from "@shared/types/unitGroup";
import { totalUnitGroupYield } from "@shared/utils/unit_groups";
import { areaUtil } from "@shared/utils/area";
import { MAX_NO_UNITS } from "@shared/utils/constants";
import {
  formatAmount,
  formatPercentage,
  formatSmallArea,
  pluralise
} from "@shared/utils/formatting";
import { observer } from "mobx-react-lite";
import React, { FC, useMemo, useState } from "react";
import { Column } from "react-table";
import { IncomeUnitsTableDataObject, IncomeUnitsTableProps } from "../types";

const IncomeUnitsTable: FC<IncomeUnitsTableProps> = (props: IncomeUnitsTableProps) => (
  <div data-testid="unit-table">
    <IncomeUnitsTableObservable {...props} />
  </div>
);

const IncomeUnitsTableObservable: FC<IncomeUnitsTableProps> = observer(
  ({ unitGroupId }: IncomeUnitsTableProps) => {
    const { t } = useTranslation();

    const [isConfirmDeleteOpened, setIsConfirmDeleteOpened] = useState(false);
    const [unitsToBeDeleted, setUnitsToBeDeleted] = useState<Array<string>>([]);

    const onDeleteSelected = (selectedUnitsIds: Array<string>) => {
      setUnitsToBeDeleted(selectedUnitsIds);
      setIsConfirmDeleteOpened(true);
    };

    const tableActionsColumn: Column<IncomeUnitsTableDataObject> = {
      id: "action",
      Header: (
        <span
          aria-label={t(AppraisalFundingTranslations.AppraisalFunding_TableActions)}
          role="toolbar"
          data-testid="actions-column"
        />
      ),
      accessor: "actionsElement",
      Footer: ""
    };

    const residentialUnitsColumns: Array<Column<IncomeUnitsTableDataObject>> = [
      {
        id: "bedroomsCount",
        Header: t(AppraisalIncomeUnitsTranslations.AppraisalIncomeUnits_TableBedroomsCount),
        accessor: "bedroomsCount",
        Footer: (
          <strong className="atlas-font-bold" data-testid="bedroomsCount">
            {(rootStore.unitGroupStore.unitsByUnitGroupId.get(unitGroupId) ?? []).reduce(
              (sum: number, incomeUnit: ClientUnit) => sum + incomeUnit.beds,
              0
            )}
          </strong>
        )
      },
      {
        id: "areaNIA",
        Header: t(AppraisalIncomeUnitsTranslations.AppraisalIncomeUnits_TableAreaNIA),
        accessor: "areaNIA",
        Footer: (
          <strong className="atlas-font-bold" data-testid="areaNIA">
            {formatSmallArea(
              (rootStore.unitGroupStore.unitsByUnitGroupId.get(unitGroupId) ?? []).reduce(
                (sum: number, incomeUnit: ClientUnit) => sum + incomeUnit.area,
                0
              ),
              rootStore.userStore.areaUnit
            )}
          </strong>
        )
      }
    ];

    const rentalResidentialUnitsColumns: Array<Column<IncomeUnitsTableDataObject>> = [
      ...residentialUnitsColumns,
      {
        id: "rent",
        Header: t(AppraisalIncomeUnitsTranslations.AppraisalIncomeUnits_TableRent),
        accessor: "rent",
        Footer: ""
      },
      {
        id: "annualIncome",
        Header: t(AppraisalIncomeUnitsTranslations.AppraisalIncomeUnits_TableAnnualIncome),
        accessor: "annualIncome",
        Footer: (
          <strong className="atlas-font-bold" data-testid="annualIncome">
            {formatAmount(
              (rootStore.unitGroupStore.unitsByUnitGroupId.get(unitGroupId) ?? []).reduce(
                (sum: number, incomeUnit: ClientUnit) => sum + incomeUnit.annualRentIncome.value,
                0
              )
            )}
          </strong>
        )
      },
      {
        id: "investmentYieldPercentage",
        Header: t(AppraisalIncomeUnitsTranslations.AppraisalIncomeUnits_TableYield),
        accessor: "investmentYieldPercentage",
        Footer: (
          <strong className="atlas-font-bold" data-testid="annualIncome">
            {formatPercentage(
              totalUnitGroupYield(
                rootStore.unitGroupStore.unitGroups.find(
                  (ug) => ug._id === unitGroupId
                ) as ClientUnitGroup
              )
            )}
          </strong>
        )
      },
      tableActionsColumn
    ];

    const salesResidentialUnitsColumns: Array<Column<IncomeUnitsTableDataObject>> = [
      ...residentialUnitsColumns,
      {
        id: "salesValueAmount",
        Header: t(AppraisalIncomeUnitsTranslations.AppraisalIncomeUnits_TableSalesValue),
        accessor: "salesValueAmount",
        Footer: (
          <strong className="atlas-font-bold" data-testid="salesValue">
            {formatAmount(
              (rootStore.unitGroupStore.unitsByUnitGroupId.get(unitGroupId) ?? []).reduce(
                (sum: number, incomeUnit: ClientUnit) => sum + incomeUnit.salesValue.value,
                0
              )
            )}
          </strong>
        )
      },
      {
        id: "price",
        Header:
          t(AppraisalIncomeUnitsTranslations.AppraisalIncomeUnits_TablePrice) +
          "/" +
          areaUtil.getSmallAreaString(rootStore.userStore.areaUnit),
        accessor: "price",
        Footer: ""
      },
      tableActionsColumn
    ];

    const commercialUnitsColumns: Array<Column<IncomeUnitsTableDataObject>> = [
      {
        id: "areaNIA",
        Header: t(AppraisalIncomeUnitsTranslations.AppraisalIncomeUnits_TableAreaNIA),
        accessor: "areaNIA",
        Footer: (
          <strong className="atlas-font-bold" data-testid="areaNIA">
            {formatSmallArea(
              (rootStore.unitGroupStore.unitsByUnitGroupId.get(unitGroupId) ?? []).reduce(
                (sum: number, incomeUnit: ClientUnit) => sum + incomeUnit.area,
                0
              ),
              rootStore.userStore.areaUnit
            )}
          </strong>
        )
      },
      {
        id: "incomePerArea",
        Header:
          t(AppraisalIncomeUnitsTranslations.AppraisalIncomeUnits_TableIncomePerArea) +
          "/" +
          areaUtil.getSmallAreaString(rootStore.userStore.areaUnit),
        accessor: "incomePerArea",
        Footer: ""
      },
      {
        id: "annualIncome",
        Header: t(AppraisalIncomeUnitsTranslations.AppraisalIncomeUnits_TableAnnualIncome),
        accessor: "annualIncome",
        Footer: (
          <strong className="atlas-font-bold" data-testid="annualIncome">
            {formatAmount(
              (rootStore.unitGroupStore.unitsByUnitGroupId.get(unitGroupId) ?? []).reduce(
                (sum: number, incomeUnit: ClientUnit) => sum + incomeUnit.annualRentIncome.value,
                0
              )
            )}
          </strong>
        )
      },
      {
        id: "voidPeriod",
        Header: t(AppraisalIncomeUnitsTranslations.AppraisalIncomeUnits_TableVoidPeriod),
        accessor: "voidPeriod",
        Footer: ""
      },
      {
        id: "rentFreePeriod",
        Header: t(AppraisalIncomeUnitsTranslations.AppraisalIncomeUnits_TableRentFree),
        accessor: "rentFreePeriod",
        Footer: ""
      },
      {
        id: "investmentYieldPercentage",
        Header: t(AppraisalIncomeUnitsTranslations.AppraisalIncomeUnits_TableYield),
        accessor: "investmentYieldPercentage",
        Footer: ""
      },
      tableActionsColumn
    ];

    const otherIncomeUnitsColumns: Array<Column<IncomeUnitsTableDataObject>> = [
      {
        id: "unitName",
        Header: t(AppraisalIncomeUnitsTranslations.AppraisalIncomeUnits_TableUnitName),
        accessor: "unitName",
        Footer: ""
      },
      {
        id: "pricePerUnit",
        Header:
          t(AppraisalIncomeUnitsTranslations.AppraisalIncomeUnits_TablePrice) +
          "/" +
          t(AppraisalIncomeUnitsTranslations.AppraisalIncomeUnits_TableUnit),
        accessor: "pricePerUnit",
        Footer: ""
      },
      tableActionsColumn
    ];

    const data: IncomeUnitsTableDataObject[] = useMemo(() => {
      const incomeUnitsRows = (
        rootStore.unitGroupStore.unitsByUnitGroupId.get(unitGroupId) ?? []
      ).map((incomeUnit: ClientUnit, index: number) => {
        return {
          _id: incomeUnit._id,
          unitName:
            t(AppraisalIncomeUnitsTranslations.AppraisalIncomeUnits_TableUnit) + " " + (index + 1),
          bedroomsCount: incomeUnit.beds,
          areaNIA: formatSmallArea(incomeUnit.area, rootStore.userStore.areaUnit),
          salesValueAmount: formatAmount(incomeUnit.salesValue.value),
          rent: formatAmount(incomeUnit.annualRentIncome.value / 12),
          annualIncome: formatAmount(incomeUnit.annualRentIncome.value),
          incomePerArea: formatAmount(incomeUnit.annualRentIncome.value / incomeUnit.area),
          rentFreePeriod: pluralise("month", incomeUnit.rentFreePeriod),
          voidPeriod: pluralise("month", incomeUnit.voidPeriod),
          investmentYieldPercentage: incomeUnit.investmentYield + "%",
          pricePerUnit: formatAmount(incomeUnit.salesValue.value),
          price: formatAmount(
            incomeUnit.salesValue.value /
              incomeUnit.area /
              areaUtil.convertSmallArea(1, rootStore.userStore.areaUnit)
          ),
          actionsElement: (
            <RowActions
              editTitle={t(AppraisalIncomeUnitsTranslations.AppraisalIncomeUnits_EditUnit)}
              deleteTitle={t(AppraisalIncomeUnitsTranslations.AppraisalIncomeUnits_DeleteUnit)}
              duplicateTitle={t(
                AppraisalIncomeUnitsTranslations.AppraisalEquityFunding_DuplicateUnit
              )}
              canDuplicate={
                (rootStore.unitGroupStore.unitsCountByUnitGroupId.get(unitGroupId) ?? 0) <
                MAX_NO_UNITS
              }
              onDeleteClick={(event) => {
                event.stopPropagation();
                onDeleteSelected([incomeUnit._id]);
              }}
              onEditClick={(event) => {
                event.stopPropagation();
                rootStore.currentUnitGroupStore.startEditingUnit(unitGroupId, incomeUnit._id);
              }}
              onDuplicateClick={(event) => {
                event.stopPropagation();
                rootStore.currentUnitGroupStore.duplicateUnit(unitGroupId, incomeUnit._id);
              }}
            />
          )
        };
      });

      return incomeUnitsRows;
    }, [
      rootStore.unitGroupStore.unitsByUnitGroupId.get(unitGroupId),
      rootStore.userStore.areaUnit
    ]);

    let incomeUnitsTableColumns: Array<Column<IncomeUnitsTableDataObject>> = [];
    const currentUnitGroup = rootStore.unitGroupStore.unitGroups.find((u) => u._id === unitGroupId);

    switch (currentUnitGroup?.unitType) {
      case UnitGroupType.FLAT:
      case UnitGroupType.HOUSE:
        incomeUnitsTableColumns =
          currentUnitGroup?.exitType === UnitGroupExitType.RENT
            ? rentalResidentialUnitsColumns
            : salesResidentialUnitsColumns;
        break;
      case UnitGroupType.COMMERCIAL_UNIT:
        incomeUnitsTableColumns = commercialUnitsColumns;
        break;
      case UnitGroupType.OTHER_INCOME:
        incomeUnitsTableColumns = otherIncomeUnitsColumns;
        break;
    }

    return (
      <>
        <SelectableTable<IncomeUnitsTableDataObject>
          columns={incomeUnitsTableColumns}
          data={data}
          tableId={unitGroupId}
          hasFooter={currentUnitGroup?.unitType !== UnitGroupType.OTHER_INCOME}
          onDeleteSelected={onDeleteSelected}
        />
        <Confirm
          onConfirm={() => {
            logEvent(pluralise("Unit", unitsToBeDeleted.length) + " Removed", {
              appraisal_id: rootStore.getCurrentAppraisal()?._id ?? "",
              development_id: rootStore.currentDevelopmentStore.development?._id
            });
            rootStore.unitGroupStore.deleteUnits(unitGroupId, unitsToBeDeleted);
            setIsConfirmDeleteOpened(false);
          }}
          onCancel={() => {
            setUnitsToBeDeleted([]);
            setIsConfirmDeleteOpened(false);
          }}
          title={t(AppraisalFundingTranslations.AppraisalFunding_PleaseConfirm)}
          description={
            unitsToBeDeleted.length > 1
              ? t(AppraisalIncomeUnitsTranslations.AppraisalIncomeUnits_DeleteUnitsConfirm)
              : t(AppraisalIncomeUnitsTranslations.AppraisalIncomeUnits_DeleteUnitConfirm)
          }
          modalOpen={isConfirmDeleteOpened}
          confirmButtonText={t(AppraisalFundingTranslations.AppraisalFunding_Delete)}
        />
      </>
    );
  }
);

export { IncomeUnitsTable };
