import { Calculations } from "@/react/components/Forms/NumberInput/types";
import { buildPhaseNumberOfUnits } from "@/react/utils/build_phase";
import { ClientBuildPhase } from "@shared/types/buildPhase";
import { ComputableCalculationType } from "@shared/types/computable";
import { areaUtil } from "@shared/utils/area";
import { makeAutoObservable } from "mobx";
import { RootStore } from "../Root";

export class CalculationsStore {
  readonly root: RootStore;

  constructor(rootStore: RootStore) {
    this.root = rootStore;

    makeAutoObservable(this, { root: false });
  }

  get stampDutyCalculationOptions() {
    const computedStampDuty = this.root.appraisalStore.computedStampDuty;
    return [
      {
        type: "stamp-duty-bands",
        calculateValueFromBase: () => computedStampDuty
      }
    ];
  }

  get percentageOfLandCalculationOption(): Calculations[] {
    const purchasePrice = this.root.appraisalStore.purchasePrice;
    return [
      {
        type: ComputableCalculationType.PERCENTAGE_OF_LAND,
        label: "of Purchase Price",
        suffix: "%",
        calculateValueFromBase: (base: number) => (base / 100) * purchasePrice,
        calculateBaseFromValue: (value: number) => (value / purchasePrice) * 100
      }
    ];
  }

  get percentageOfConstructionCalculationOption(): Calculations[] {
    const totalBuildCostWithoutContingency = this.root.costStore.totalBuildCostWithoutContingency;
    return [
      {
        type: ComputableCalculationType.PERCENTAGE_OF_CONSTRUCTION,
        label: "of Construction",
        suffix: "%",
        calculateValueFromBase: (base: number) => (base / 100) * totalBuildCostWithoutContingency,
        calculateBaseFromValue: (value: number) => (value / totalBuildCostWithoutContingency) * 100
      }
    ];
  }

  get percentageOfGDVCalculationOption(): Calculations[] {
    const totalSales = this.root.unitGroupStore.totalSales;
    return [
      {
        type: ComputableCalculationType.PERCENTAGE_OF_GDV,
        label: "of GDV",
        suffix: "%",
        calculateValueFromBase: (base: number) => (base / 100) * totalSales,
        calculateBaseFromValue: (value: number) => (value / totalSales) * 100
      }
    ];
  }

  get perResidentialGIACalculationOption(): Calculations[] {
    const area = areaUtil.getSmallAreaString(this.root.userStore.areaUnit);
    const residentialGIA = areaUtil.convertSmallArea(
      this.root.unitGroupStore.residentialGIA,
      this.root.userStore.areaUnit
    );
    return [
      {
        type: ComputableCalculationType.PER_AREA_UNIT,
        label: `£/${area} (resi GIA)`,
        prefix: "£",
        calculateValueFromBase: (base: number) => base * residentialGIA,
        calculateBaseFromValue: (value: number) => value / residentialGIA
      }
    ];
  }

  get percentageOfCosts(): Calculations[] {
    const totalCost = this.root.costStore.totalFundableCost;
    return [
      {
        type: ComputableCalculationType.PERCENTAGE_OF_COSTS,
        label: "of Costs",
        suffix: "%",
        calculateValueFromBase: (base: number) => (base / 100) * totalCost,
        calculateBaseFromValue: (value: number) => (value / totalCost) * 100
      }
    ];
  }

  get percentageCalculationOptions(): Calculations[] {
    return [
      ...this.percentageOfConstructionCalculationOption,
      ...this.percentageOfGDVCalculationOption,
      ...this.percentageOfLandCalculationOption,
      ...this.costPerUnitCalculationOption
    ];
  }

  get otherCostsCalculationOptions() {
    return [
      ...this.percentageOfConstructionCalculationOption,
      ...this.percentageOfGDVCalculationOption,
      ...this.percentageOfLandCalculationOption,
      ...this.perResidentialGIACalculationOption,
      ...this.costPerUnitCalculationOption
    ];
  }

  get loanAmountCalculationOptions(): Calculations[] {
    return [...this.percentageOfGDVCalculationOption, ...this.percentageOfCosts];
  }

  get perBuildPhaseGIACalculationOption() {
    const unit = this.root.userStore.areaUnit;
    const area = areaUtil.getSmallAreaString(unit);
    return (buildPhase: ClientBuildPhase) => {
      const GIA = buildPhase.assignedGIA?.value || 0;
      return [
        {
          type: ComputableCalculationType.PER_AREA_UNIT,
          label: `£/${area}`,
          prefix: "£",
          calculateValueFromBase: (base: number) => base * GIA,
          calculateBaseFromValue: (value: number) => value / GIA
        }
      ];
    };
  }

  get perBuildPhaseNIACalculationOption() {
    return (buildPhase: ClientBuildPhase) => {
      return [
        {
          type: ComputableCalculationType.PERCENTAGE_OF_NIA,
          label: "GIA % of NIA",
          suffix: "%",
          calculateValueFromBase: (base: number) => (base / 100) * buildPhase.assignedNIA || 0,
          calculateBaseFromValue: (value: number) => (value / buildPhase.assignedNIA || 0) * 100
        }
      ];
    };
  }

  get costPerBuildPhaseUnitCalculationOption() {
    return (buildPhase: ClientBuildPhase) => {
      const units = buildPhaseNumberOfUnits(buildPhase, this.root.unitGroupStore.unitGroups);
      return [
        {
          type: ComputableCalculationType.PER_BUILD_PHASE_UNIT,
          label: `£/unit`,
          prefix: "£",
          calculateValueFromBase: (base: number) => base * units,
          calculateBaseFromValue: (value: number) => value / units
        }
      ];
    };
  }

  get costPerUnitCalculationOption() {
    const units = this.root.unitGroupStore.totalUnitsCount;
    return [
      {
        type: ComputableCalculationType.PER_UNIT,
        label: `£/unit`,
        prefix: "£",
        calculateValueFromBase: (base: number) => base * units,
        calculateBaseFromValue: (value: number) => value / units
      }
    ];
  }

  get calculateValueFromBaseMetric(): Partial<
    Record<ComputableCalculationType, (base: number) => number>
  > {
    return {
      [ComputableCalculationType.PERCENTAGE_OF_LAND]: (base: number) =>
        (base / 100) * this.root.appraisalStore.purchasePrice,
      [ComputableCalculationType.PERCENTAGE_OF_CONSTRUCTION]: (base: number) =>
        (base / 100) * this.root.costStore.totalBuildCostWithoutContingency,
      [ComputableCalculationType.PERCENTAGE_OF_GDV]: (base: number) =>
        (base / 100) * this.root.unitGroupStore.totalSales,
      [ComputableCalculationType.PER_AREA_UNIT]: (base: number) =>
        base * this.root.unitGroupStore.residentialGIA,
      [ComputableCalculationType.PER_UNIT]: (base: number) =>
        base * this.root.unitGroupStore.totalUnitsCount
    };
  }

  get calculateValueFromBaseAndBuildPhaseMetric(): Partial<
    Record<ComputableCalculationType, (base: number, buildPhase: ClientBuildPhase) => number>
  > {
    return {
      [ComputableCalculationType.PER_AREA_UNIT]: (base: number, buildPhase: ClientBuildPhase) =>
        base * buildPhase.assignedGIA?.value,
      [ComputableCalculationType.PER_BUILD_PHASE_UNIT]: (
        base: number,
        buildPhase: ClientBuildPhase
      ) => base * buildPhaseNumberOfUnits(buildPhase, this.root.unitGroupStore.unitGroups)
    };
  }

  get calculateBaseFromValueMetric(): Partial<
    Record<ComputableCalculationType, (base: number) => number>
  > {
    return {
      [ComputableCalculationType.PERCENTAGE_OF_LAND]: (value: number) =>
        (value / this.root.appraisalStore.purchasePrice) * 100,
      [ComputableCalculationType.PERCENTAGE_OF_CONSTRUCTION]: (value: number) =>
        (value / this.root.costStore.totalBuildCostWithoutContingency) * 100,
      [ComputableCalculationType.PERCENTAGE_OF_GDV]: (value: number) =>
        (value / this.root.unitGroupStore.totalSales) * 100,
      [ComputableCalculationType.PER_AREA_UNIT]: (value: number) =>
        value / this.root.unitGroupStore.residentialGIA,
      [ComputableCalculationType.PER_UNIT]: (base: number) =>
        base / this.root.unitGroupStore.totalUnitsCount
    };
  }

  get calculateBaseFromValueAndBuildPhaseMetric(): Partial<
    Record<ComputableCalculationType, (value: number, buildPhase: ClientBuildPhase) => number>
  > {
    return {
      [ComputableCalculationType.PER_AREA_UNIT]: (value: number, buildPhase: ClientBuildPhase) =>
        value / buildPhase.assignedGIA?.value,
      [ComputableCalculationType.PER_BUILD_PHASE_UNIT]: (
        value: number,
        buildPhase: ClientBuildPhase
      ) => value / buildPhaseNumberOfUnits(buildPhase, this.root.unitGroupStore.unitGroups)
    };
  }
}
