<template>
  <div class="unit-group-dropdown">
    <span class="dropdown-text">{{ dropdownText }}</span>
    <b-dropdown variant="white" right>
      <div class="dropdown-wrapper">
        <b-form-checkbox-group
          v-model="selection"
          size="lg"
          data-testid="build_phase_unit_group_checkbox"
        >
          <slot :custom-id="customId" data-testid="test" />
        </b-form-checkbox-group>
      </div>
    </b-dropdown>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import { ClientBuildPhase } from "@shared/types/buildPhase";
import { pluralise } from "@shared/utils/formatting";
import { rootStore } from "@/react/lib/persistence/root_store";
import { ClientUnitGroup } from "@shared/types/unitGroup";
import { Observer } from "mobx-vue";

@Observer
@Component({})
export default class UnitGroupDropdown extends Vue {
  @Prop({ required: true }) readonly buildPhaseId!: ClientBuildPhase["_id"];
  @Prop({ required: true }) readonly assignedUnitGroups!: Array<ClientUnitGroup["_id"]>;
  @Prop({ required: true }) readonly isCustom!: ClientBuildPhase;

  private readonly customId: string = "custom";
  private BuildPhaseStore = rootStore.buildPhaseStore;

  get selection(): string[] {
    if (this.isCustom) {
      return [this.customId];
    } else {
      return this.assignedUnitGroups;
    }
  }

  set selection(v: string[]) {
    const swappingToCustom = v[v.length - 1] === this.customId;
    this.BuildPhaseStore.updateBuildPhase(this.buildPhaseId, {
      assignedUnitGroups: swappingToCustom ? [] : v.filter((id) => id !== this.customId),
      isCustom: swappingToCustom
    });
  }

  get dropdownText() {
    if (this.isCustom) {
      return "Custom";
    } else if (this.assignedUnitGroups.length) {
      return `${pluralise("Group", this.assignedUnitGroups.length)} Selected`;
    } else {
      return "Select Unit Groups";
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/landtech-colors";

/* Hide the popper when the reference is hidden */
[x-out-of-boundaries] {
  visibility: hidden;
  pointer-events: none;
}

.unit-group-dropdown {
  border-radius: 0.25rem;
  align-items: center;
  display: flex;
  font-weight: bold;

  .dropdown-wrapper {
    max-height: 50vh;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .dropdown-text {
    width: 248px;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    border: 1px solid $divider;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .dropdown-toggle {
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -1px;
    border: 1px solid $divider;
  }
}
</style>
