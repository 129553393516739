import { ClientSite } from "@shared/types/site";
import { UnitSetting } from "@shared/types/user";

export const convertBigArea = (x: number, unitSetting: UnitSetting) => {
  return unitSetting === "metric" ? x * 0.0001 : x * 0.000247105;
};

export const getSiteGeoJSON = (site: ClientSite) => {
  return {
    type: "Feature",
    id: site._id,
    geometry: site.geometry,
    properties: {}
  };
};
