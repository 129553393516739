import React from "react";
import { Tooltip, TooltipProps } from "./Tooltip";
import { Icon } from "../Icons";

type InfoTooltipProps = Omit<TooltipProps, "children">;

const InfoTooltip = ({ content, ...props }: InfoTooltipProps) => {
  return (
    <Tooltip content={content} className="atlas-leading-none atlas-h-6 atlas-mx-2" {...props}>
      <Icon.InformationLine className="atlas-w-5 atlas-h-5" />
    </Tooltip>
  );
};

export { InfoTooltip };
