import React from "react";
import { TableSection } from "../CashflowTableSection";
import { rootStore } from "@/react/lib/persistence/root_store";
import { observer } from "mobx-react-lite";
import { FinanceRow } from "../../FinanceRow";
import { CashflowTableTranslations } from "@/react/lib/i18n/locales";
import { useTranslation } from "@/react/lib/hooks";

export const TotalsSection = observer(() => {
  const { t } = useTranslation();
  const cashflowStore = rootStore.cashflowStore;
  const costStore = rootStore.costStore;
  return (
    <TableSection testId="totals-section">
      <FinanceRow
        totalRow
        testId="gross-revenues"
        topBorder
        title={t(CashflowTableTranslations.CashflowTable_GrossRevenue)}
        valueCell={cashflowStore.salesGrossRevenueRowTotal}
        monthCells={cashflowStore.salesGrossRevenueColTotals}
      />
      <FinanceRow
        testId="total-costs"
        totalRow
        title={t(CashflowTableTranslations.CashflowTable_TotalCosts)}
        valueCell={costStore.totalCostExFunding}
        monthCells={cashflowStore.totalCostsColTotals}
      />
      <FinanceRow
        testId="monthly-nets"
        title={t(CashflowTableTranslations.CashflowTable_MonthlyNet)}
        totalRow
        bottomBorder
        valueCell={cashflowStore.monthlyNetAggregatedTotal}
        monthCells={cashflowStore.monthlyNets}
      />
    </TableSection>
  );
});
