import { CILMessaging } from "../enums";

export const CILMessages = {
  [CILMessaging.Authority_Select_Title]: "Check the CIL charges for",
  [CILMessaging.Authority_Select_Placeholder]: "Select local authority",
  [CILMessaging.Status]: "Status",
  [CILMessaging.Date]: "Date",
  [CILMessaging.Zones]: "Zones",
  [CILMessaging.Mayoral]: "Mayoral CIL",
  [CILMessaging.Residential_Charges]: "Residential Charges",
  [CILMessaging.Retail]: "Retail / Commercial Charges",
  [CILMessaging.Other]: "Other Charges",
  [CILMessaging.View_Website]: "View local authority CIL website",
  [CILMessaging.Disclaimer]: "Disclaimer",
  [CILMessaging.Disclaimer_Text]:
    "Annual charge updates may not reflect recent changes. CIL charges can vary due to inflation. Check council pages for indexation on older rates.",
  [CILMessaging.StatusUnknown_Alert_Text]:
    "No CIL policy is currently in place for this authority and no timetable has been set for future adoption.",
  [CILMessaging.StatusNone_Alert_Text]: "No CIL plans in progress.",
  [CILMessaging.StatusInDevelopment_Alert_Text]:
    "CIL in preparation. Has not progressed to Stage 1 yet.",
  [CILMessaging.Warning_Text]: `
    Before adding CIL charges to your appraisal,
    select 'm²' as your measurement choice in the top right corner,
    as the charge summary is provided in £/m².
  `,
  [CILMessaging.CilCharges_Title]: "CIL Charges",
  [CILMessaging.FaqArticle_Title]: "FAQ article"
};
