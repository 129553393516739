import { ClientCost, ComputableCalculationType } from "@shared/types/computable";
import { areaUtil } from "@shared/utils/area";
import { ClientBuildPhase } from "@shared/types/buildPhase";
import { UnitSetting } from "@shared/types/user";

function convertCostToMetric(cost: ClientCost, unit: UnitSetting): ClientCost {
  return {
    ...cost,
    calculationBase:
      cost.calculationType === "per-area-unit"
        ? areaUtil.convertSmallArea(cost.calculationBase, unit)
        : cost.calculationBase
  };
}

function convertCostFromMetric(cost: ClientCost, unit: UnitSetting): ClientCost {
  return {
    ...cost,
    calculationBase:
      cost.calculationType === "per-area-unit"
        ? areaUtil.convertSmallAreaBack(cost.calculationBase, unit)
        : cost.calculationBase
  };
}

/**
 * This function has to have these slightly strange booleans as build costs can have a per_area_unit calculationType
 * related to their buildPhase while other costs can have a per_area_unit calculationType related to GIA.
 * Therefore we have to make sure we are not recalculating the wrong type of cost.
 */
function noRecalculationNeededForCost(
  cost: ClientCost,
  calculationType: ComputableCalculationType,
  buildPhase?: ClientBuildPhase
) {
  return (
    cost.calculationType !== calculationType ||
    (buildPhase && cost._build_phase !== buildPhase._id) || // we don't want to recalculate other buildPhases costs
    (!buildPhase && cost._build_phase) // if we're not recalculating build costs, leave non-build Costs
  );
}

export { convertCostToMetric, convertCostFromMetric, noRecalculationNeededForCost };
