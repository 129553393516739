<template>
  <p class="error-message m-0 mt-2 pl-1">
    <template v-if="errorMessage">
      <small> <b-icon icon="exclamation-circle" class="mr-1" />{{ errorMessage }} </small>
    </template>
  </p>
</template>

<script lang="ts">
import { Vue, Prop, Component } from "vue-property-decorator";
import { Nullable } from "@shared/types/utils";
import { AccountErrorText } from "@shared/types/account";

@Component({})
export default class AccountErrors extends Vue {
  @Prop({ required: true }) readonly errorMessage!: Nullable<AccountErrorText>;
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/landtech-colors";

.error-message {
  color: $error-1;
  height: 20px;
}
</style>
