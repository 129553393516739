<template>
  <b-sidebar
    id="account-sidebar"
    right
    backdrop
    bg-variant="white"
    shadow
    width="25%"
    no-header
    z-index="10"
    @change="() => utilityStore.toggleAccountSideBar()"
    @shown="logEvent('Account Sidebar Viewed', { page: $route.path.split('/')[1] })"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center border-bottom px-4 py-3">
        <h1 class="account-sidebar-heading pb-0 mb-0"><strong>My Account</strong></h1>
        <b-btn pill variant="outline-light" class="close">
          <b-icon-x id="account-sidebar-close" @click="hide" />
        </b-btn>
      </div>
      <div
        class="
          account-sidebar-section
          d-flex
          flex-column
          align-items-start
          pt-3
          pb-1
          px-4
          border-bottom
        "
      >
        <h2 class="account-sidebar-section-heading"><strong>My Details</strong></h2>
        <p class="mb-1"><strong>Name</strong></p>
        <p class="mb-4">{{ userStore.userName }}</p>
        <p class="mb-1"><strong>Email</strong></p>
        <p class="mb-4">{{ userStore.userEmail }}</p>
      </div>
      <div
        v-if="hasReportBranding(userStore.user)"
        class="d-flex flex-column align-items-start py-3 px-4 account-sidebar-section"
      >
        <h2 class="account-sidebar-section-heading"><strong>Company</strong></h2>
        <p class="mb-0">Upload your company logo</p>
        <AccountErrors :errorMessage="accountError" />
        <div class="account-sidebar-logo-container">
          <LogoDisplay
            v-if="existingLogo"
            @noImage="existingLogo = false"
            @imageDeleted="existingLogo = false"
            @error="(message) => (accountError = message)"
            @clearErrors="accountError = null"
          />
          <LogoUploader
            v-else
            @imageUploaded="existingLogo = true"
            @error="(message) => (accountError = message)"
            @clearErrors="accountError = null"
          />
        </div>
        <IncludeLogoCheckbox
          :disable="!existingLogo"
          class="mt-4"
          @error="(message) => (accountError = message)"
          @clearErrors="accountError = null"
        />
      </div>
    </template>
  </b-sidebar>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import LogoUploader from "./LogoUploader.vue";
import LogoDisplay from "./LogoDisplay.vue";
import IncludeLogoCheckbox from "./IncludeLogoCheckbox.vue";
import AccountErrors from "./AccountErrors.vue";
import { AccountErrorText } from "@shared/types/account";
import { Nullable } from "@shared/types/utils";
import { hasReportBranding } from "@shared/utils/authorisation";
import EventLoggerMixin from "@/mixins/event_logger_mixin";
import { Observer } from "mobx-vue";
import { rootStore } from "@/react/lib/persistence/root_store";

@Observer
@Component({
  components: {
    LogoUploader,
    LogoDisplay,
    IncludeLogoCheckbox,
    AccountErrors
  }
})
export default class AccountSidebar extends Mixins(EventLoggerMixin) {
  utilityStore = rootStore.utilityStore;
  userStore = rootStore.userStore;
  public hasReportBranding = hasReportBranding;
  public existingLogo: boolean = true;
  public accountError: Nullable<AccountErrorText> = null;
}
</script>

<style lang="scss">
@import "@/assets/scss/landtech-colors";

#account-sidebar {
  margin-top: 3rem !important;
  min-width: 320px;
  max-width: 450px;
  padding: 0;

  #account-sidebar-close {
    color: $neutral-900;
  }

  .account-sidebar-heading {
    font-size: 24px;
    line-height: 32px;
  }

  .account-sidebar-section-heading {
    font-size: 18px;
    line-height: 21px;
  }

  .account-sidebar-section {
    line-height: 19px;
  }

  .account-sidebar-logo-container {
    position: relative;
    height: 150px;
    width: 100%;
  }
}
</style>
