"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRentPerMonth = exports.totalBedrooms = exports.totalUnitGroupArea = exports.totalUnitGroupSalesFees = exports.totalUnitGroupSalesValue = exports.totalUnitGroupValue = exports.yieldBasedUnitValueAfterDeduction = exports.yieldBasedUnitValueBeforeDeduction = exports.totalUnitGroupIncome = exports.totalUnitGroupYield = void 0;
const totalUnitGroupYield = (unitGroup) => ((0, exports.totalUnitGroupIncome)(unitGroup) / totalYieldBasedValueBeforeDeduction(unitGroup)) * 100;
exports.totalUnitGroupYield = totalUnitGroupYield;
const totalUnitGroupIncome = (unitGroup) => unitGroup.units.reduce((sum, unit) => sum + unit.annualRentIncome.value, 0);
exports.totalUnitGroupIncome = totalUnitGroupIncome;
const totalYieldBasedValueBeforeDeduction = (unitGroup) => unitGroup.units.reduce((sum, unit) => sum + (0, exports.yieldBasedUnitValueBeforeDeduction)(unit), 0);
const yieldBasedUnitValueBeforeDeduction = (unit) => (unit.annualRentIncome.value / unit.investmentYield) * 100;
exports.yieldBasedUnitValueBeforeDeduction = yieldBasedUnitValueBeforeDeduction;
const yieldBasedUnitValueAfterDeduction = (unit) => (unit.annualRentIncome.value / unit.investmentYield) * 100 -
    (0, exports.getRentPerMonth)(unit) * unit.rentFreePeriod;
exports.yieldBasedUnitValueAfterDeduction = yieldBasedUnitValueAfterDeduction;
const totalUnitGroupValue = (unitGroup) => {
    const isCommercialUnitGroup = unitGroup.unitType === "Commercial Unit";
    const isRentedResidentialUnitGroup = (unitGroup.unitType === "Flat" || unitGroup.unitType === "House") &&
        unitGroup.exitType === "Rent";
    const calculateValueBasedOnYield = isCommercialUnitGroup || isRentedResidentialUnitGroup;
    const valueDeduction = isCommercialUnitGroup ? totalRentFreeValueDeduction(unitGroup) : 0;
    return calculateValueBasedOnYield
        ? totalYieldBasedValueBeforeDeduction(unitGroup) - valueDeduction
        : (0, exports.totalUnitGroupSalesValue)(unitGroup);
};
exports.totalUnitGroupValue = totalUnitGroupValue;
const totalRentFreeValueDeduction = (unitGroup) => unitGroup.units.reduce((sum, unit) => sum + (0, exports.getRentPerMonth)(unit) * unit.rentFreePeriod, 0);
const totalUnitGroupSalesValue = (unitGroup) => unitGroup.units.reduce((sum, unit) => sum + unit.salesValue.value, 0);
exports.totalUnitGroupSalesValue = totalUnitGroupSalesValue;
const totalUnitGroupSalesFees = (unitGroup) => unitGroup.units.reduce((sum, unit) => {
    if (unitGroup.unitType === "Commercial Unit") {
        return sum + unit.legalFees.value;
    }
    else if (unitGroup.exitType === "Sale") {
        return sum + unit.salesFee.value + unit.legalFees.value;
    }
    else {
        return sum;
    }
}, 0);
exports.totalUnitGroupSalesFees = totalUnitGroupSalesFees;
const totalUnitGroupArea = (unitGroup) => unitGroup.units.reduce((sum, unit) => sum + unit.area, 0);
exports.totalUnitGroupArea = totalUnitGroupArea;
const totalBedrooms = (unitGroup) => unitGroup.units.reduce((sum, unit) => sum + unit.beds, 0);
exports.totalBedrooms = totalBedrooms;
const getRentPerMonth = (unit) => unit.annualRentIncome.value / 12;
exports.getRentPerMonth = getRentPerMonth;
