import React, { FC, PropsWithChildren, useMemo, useRef } from "react";
import { Tabs, Icon } from "@/react/components";
import { useTranslation } from "@/react/lib/hooks";
import {
  FundingPageList,
  FundingPageTabs,
  FundingPageTranslations
} from "@/react/lib/i18n/locales";
import "./Triangle.css";
import clsx from "clsx";
import { Interweave } from "interweave";
import { useWindowResize } from "@/react/utils";

interface TabInformation {
  label: string;
  content: string[];
}

interface Triangle {
  transparent?: boolean;
  turn?: "x" | "y" | "xy";
}

interface Features {
  content: string;
  triangles: Triangle[];
}

const FundingPageCard: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();
  const overflowParent = useRef<HTMLDivElement | null>(null);
  const isLargeScreen = useWindowResize(768)[1];
  const lenderMatchTabsInformation = useMemo(
    (): TabInformation[] => [
      {
        label: FundingPageTabs.FundingPage_SeniorDebt,
        content: [
          FundingPageTabs.FundingPage_SeniorDebt_1,
          FundingPageTabs.FundingPage_SeniorDebt_2,
          FundingPageTabs.FundingPage_SeniorDebt_3
        ]
      },
      {
        label: FundingPageTabs.FundingPage_Mezzanine,
        content: [
          FundingPageTabs.FundingPage_UpTo80,
          FundingPageTabs.FundingPage_UpTo98,
          FundingPageTabs.FundingPage_MezzRates
        ]
      },
      {
        label: FundingPageTabs.FundingPage_Equity,
        content: [FundingPageTabs.FundingPage_UpTo100, FundingPageTabs.FundingPage_Profit]
      },
      {
        label: FundingPageTabs.FundingPage_Bridging,
        content: [
          FundingPageTabs.FundingPage_Bridging_Rates,
          FundingPageTabs.FundingPage_Bridging_LTV,
          FundingPageTabs.FundingPage_Bridging_NET
        ]
      }
    ],
    []
  );

  const lenderMatchFeatures = useMemo(
    (): Features[] => [
      {
        content: FundingPageList.FundingPage_Interested,
        triangles: [{ turn: "xy" }, { turn: "x", transparent: true }]
      },
      {
        content: FundingPageList.FundingPage_Callback,
        triangles: [{ turn: "y" }, { transparent: true }]
      },
      {
        content: FundingPageList.FundingPage_Match,
        triangles: [{ transparent: true }, { turn: "y" }]
      }
    ],
    []
  );

  return (
    <div data-testid="lenderMatchCard">
      <div>
        <h1 className={"atlas-my-16 atlas-font-bold atlas-text-center"}>
          {t(FundingPageTranslations.FundingPage_FindRightLender)}
        </h1>
        <h2 className={"atlas-font-bold atlas-text-center atlas-mb-6 atlas-text-xl"}>
          {t(FundingPageTranslations.FundingPage_TakeALook)}
        </h2>
        <Tabs defaultValue="tab-0">
          <div
            className="atlas-overflow-x-auto atlas-w-full atlas-pb-2 atlas-max-w-lg atlas-mx-auto webkit-scrollbar:atlas-hidden"
            ref={overflowParent}
          >
            <Tabs.List className="atlas-flex md:atlas-w-full md:atlas-justify-between md:atlas-border-solid md:atlas-border-0 md:atlas-border-b-2 atlas-border-neutral-300">
              {lenderMatchTabsInformation.map((tab, index) => (
                <Tabs.Trigger
                  className="md:atlas--mb-2px atlas-outline-none sm:atlas-text-neutral-900 atlas-border atlas-border-solid sm:atlas-border-0 atlas-rounded-lg atlas-px-6 atlas-py-1 hover:atlas-border-primary-500 hover:atlas-text-primary-500  atlas-no-underline hover:atlas-no-underline focus:atlas-no-underline atlas-border-neutral-300 atlas-mr-2 sm:atlas-rounded-none sm:atlas-mr-0 sm:atlas-border-b-2 sm:atlas-text-xl sm:atlas-py-2 sm:atlas-px-4 atlas-whitespace-nowrap"
                  activeClassName="atlas-text-white focus:atlas-text-white sm:focus:atlas-text-primary-500 atlas-bg-primary-500 sm:atlas-bg-transparent sm:atlas-text-primary-500 atlas-border-primary-500 "
                  value={`tab-${index}`}
                  key={`tab-${tab.label}`}
                  onClick={(_, evt) => {
                    if (overflowParent.current) {
                      overflowParent.current.scrollTo({
                        left:
                          evt.currentTarget.getBoundingClientRect().x -
                          evt.currentTarget.clientWidth -
                          overflowParent.current.getBoundingClientRect().x
                      });
                    }
                  }}
                >
                  {t(tab.label)}
                </Tabs.Trigger>
              ))}
            </Tabs.List>
          </div>
          {lenderMatchTabsInformation.map((tab, index) => (
            <Tabs.Content value={`tab-${index}`} key={`tab-${tab.label}`}>
              <div className="sm:atlas-flex sm:atlas-gap-6 md:atlas-gap-12 atlas-justify-start sm:atlas-justify-center atlas-max-w-3xl atlas-mx-auto atlas-my-10">
                {tab.content.map((item) => (
                  <p
                    className="atlas-flex atlas-justify-start sm:atlas-justify-center atlas-min-w-min"
                    key={item}
                  >
                    <span className="atlas-min-w-min atlas-mr-2">
                      <Icon.StarFill className="atlas-text-primary-500" />
                    </span>
                    <Interweave content={t(item)} />
                  </p>
                ))}
              </div>
            </Tabs.Content>
          ))}
        </Tabs>
      </div>
      {children}
      <div>
        <h2 className="atlas-font-bold atlas-text-center atlas-mt-6 sm:atlas-mt-16 atlas-text-2xl">
          {t(FundingPageList.FundingPage_HowItWork)}
        </h2>
        <div className="atlas-flex atlas-flex-col lg:atlas-flex-row atlas-text-center md:atlas-mt-12">
          {lenderMatchFeatures.map((item, index) => (
            <div
              className="atlas-px-12 atlas-w-full md:atlas-w-4/12 atlas-flex atlas-flex-col atlas-justify-between"
              key={`feature-${item.content}`}
            >
              <div>
                <h3 className="atlas-text-primary-500 atlas-font-bold atlas-text-6xl atlas-mb-3">
                  {index + 1}
                </h3>
                <p>{t(item.content)}</p>
              </div>

              {!!isLargeScreen && (
                <div className="atlas-flex atlas-justify-center atlas-mt-20">
                  {item.triangles.map((triangle) => (
                    <Triangle
                      transparent={!!triangle.transparent}
                      turn={triangle.turn}
                      key={`triangle-${triangle.turn}`}
                    />
                  ))}
                </div>
              )}
            </div>
          ))}
          {!isLargeScreen && (
            <div className="atlas-flex atlas-justify-between atlas-mt-6">
              <Triangle turn="xy" transparent={false} />
              <Triangle turn="x" transparent={true} />
              <Triangle turn="y" transparent={true} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Triangle: FC<Triangle> = ({ transparent = false, turn }) => {
  const classes = clsx(
    "atlas-triangle-parent atlas-transform atlas-w-0 atlas-h-0 atlas-border-solid  atlas-border-l-emerald-50 atlas-relative atlas-mx-1",
    { "atlas-triangle-turn-x": turn === "x" },
    { "atlas-triangle-turn-y": turn === "y" },
    { "atlas-triangle-turn-xy": turn === "xy" }
  );
  return (
    <div className={classes}>
      <div
        className={clsx(
          "atlas-triangle-child atlas-w-0 atlas-h-0 atlas-border-solid atlas-border-transparent atlas-absolute",
          { "atlas-triangle-transparent": transparent }
        )}
      ></div>
    </div>
  );
};

export { FundingPageCard };
