import gql from "graphql-tag";
import { UnitGroupFragment } from "@shared/apollo/fragments";

export const ADD_UNIT_GROUP = gql`
  mutation ($appraisalId: ObjectID!, $unitGroup: UnitGroupInput!) {
    createUnitGroup(appraisalId: $appraisalId, unitGroup: $unitGroup) {
      ...UnitGroup
    }
  }
  ${UnitGroupFragment}
`;

export const UPDATE_UNIT_GROUP = gql`
  mutation ($appraisalId: ObjectID!, $unitGroup: UnitGroupInput!) {
    updateUnitGroup(appraisalId: $appraisalId, unitGroup: $unitGroup) {
      ...UnitGroup
    }
  }
  ${UnitGroupFragment}
`;

export const DELETE_UNIT_GROUP = gql`
  mutation ($appraisalId: ObjectID!, $id: ObjectID!) {
    deleteUnitGroup(appraisalId: $appraisalId, id: $id)
  }
`;
