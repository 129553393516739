import clsx from "clsx";
import React, { FC, PropsWithChildren } from "react";

interface Props {
  className?: string;
}

export const CardBase: FC<PropsWithChildren<Props>> = ({ className, children }) => {
  return (
    <div
      className={clsx(
        "atlas-flex atlas-flex-col atlas-bg-white atlas-rounded atlas-shadow-small active:atlas-bg-gray-50",
        className
      )}
    >
      {children}
    </div>
  );
};
const CardHeader: FC<PropsWithChildren<Props>> = ({ children, className }) => (
  <div
    className={clsx(
      "atlas-px-4 atlas-py-3 atlas-flex atlas-justify-between atlas-items-center",
      className
    )}
  >
    {children}
  </div>
);

const CardBody: FC<PropsWithChildren<Props>> = ({ children, className }) => (
  <div
    className={clsx(
      "atlas-flex atlas-flex-row atlas-w-auto atlas-px-4 atlas-py-2 atlas-mb-6 atlas-text-neutral-800",
      className
    )}
  >
    {children}
  </div>
);

const CardFooter: FC<PropsWithChildren<Props>> = ({ children, className }) => (
  <div className={clsx("atlas-px-4 atlas-py-2 atlas-text-neutral-900", className)}>{children}</div>
);

export const Card = Object.assign(CardBase, {
  Header: CardHeader,
  Body: CardBody,
  Footer: CardFooter
});
