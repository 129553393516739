function buildInitialAnnotations() {
  const initialObj = { text: "", _id: "" };
  return {
    income: initialObj,
    build: initialObj,
    professionalFees: initialObj,
    otherCosts: initialObj,
    funding: initialObj,
    landAcquisition: initialObj
  };
}

export { buildInitialAnnotations };
