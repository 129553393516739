import { ClientEquityFunding } from "@shared/types/equity";
import ObjectID from "bson-objectid";

export const createNewEquityFunding: () => ClientEquityFunding = () => ({
  __typename: "EquityFunding",
  description: "",
  _id: new ObjectID().toHexString(),
  position: 0,
  totalAmount: 0,
  term: 0,
  couponRate: 0,
  providerProfitShare: 0
});
