import {
  ClientBaseComputable,
  ClientBaseCost,
  ComputableCalculationType
} from "@shared/types/computable";
import { ClientUnit } from "@shared/types/unit";
import ObjectID from "bson-objectid";
import { cloneDeep } from "lodash";

export const initCost: ClientBaseCost = {
  __typename: "BaseCost",
  position: 0,
  value: 0,
  calculate: true,
  calculationType: ComputableCalculationType.PERCENTAGE_OF_SALES,
  calculationBase: 1
};

export const initUnit: ClientUnit = {
  _id: new ObjectID().toHexString(),
  __typename: "Unit",
  area: 0,
  beds: 0,
  investmentYield: 5,
  annualRentIncome: {
    __typename: "BaseComputable",
    value: 0,
    calculate: false,
    calculationType: ComputableCalculationType.PER_AREA_UNIT,
    calculationBase: 0
  },
  salesValue: {
    __typename: "BaseComputable",
    value: 0,
    calculate: false,
    calculationType: ComputableCalculationType.PER_AREA_UNIT,
    calculationBase: 0
  },
  salesFee: {
    ...initCost,
    calculationBase: 1.5
  },
  legalFees: {
    ...initCost,
    calculationBase: 0.5
  },
  voidPeriod: 0,
  rentFreePeriod: 0,
  lettingFee: {
    __typename: "BaseComputable",
    value: 0,
    calculate: true,
    calculationType: ComputableCalculationType.PERCENTAGE_OF_RENT,
    calculationBase: 10
  }
};

const zeroComputable = <T extends ClientBaseComputable | ClientBaseCost>(computable: T): T => ({
  ...computable,
  value: 0,
  calculationBase: 0
});

const blankUnit: ClientUnit = {
  ...initUnit,
  investmentYield: 0,
  annualRentIncome: zeroComputable(initUnit.annualRentIncome),
  salesValue: zeroComputable(initUnit.salesValue),
  salesFee: zeroComputable(initUnit.salesFee),
  legalFees: zeroComputable(initUnit.legalFees),
  lettingFee: zeroComputable(initUnit.lettingFee)
};

const setDifferingValuesToDefaults = <T extends { [key: string]: any }>(
  objects: T[],
  defaultObject: T
) => {
  const firstObject = objects[0];
  const clone = cloneDeep(firstObject);
  for (const [key, value] of Object.entries(firstObject)) {
    if (!!value && typeof value === "object") {
      const nestedObjects = objects.map((o) => o[key]);
      (clone as any)[key] = setDifferingValuesToDefaults(nestedObjects, defaultObject[key]);
    } else if (key !== "id") {
      const values = objects.map((o) => o[key]);
      if (!values.every((v) => v === firstObject[key])) {
        (clone as any)[key] = defaultObject[key];
      }
    }
  }
  return clone;
};

export const getMergedUnit = (units: ClientUnit[]): ClientUnit => {
  if (!units.length) {
    return blankUnit;
  }

  return setDifferingValuesToDefaults(units, blankUnit);
};
