import { Checkbox, CheckboxState } from "@/react/components";
import clsx from "clsx";
import React, { Dispatch } from "react";
import { Row } from "react-table";

export const SelectableTableRow = <Opts extends { _id: string }>({
  row,
  tdClassName,
  selectedRowIds,
  onSelected,
  onDeselected
}: {
  row: Row<Opts>;
  tdClassName: string;
  selectedRowIds: Array<string>;
  onSelected: Dispatch<string>;
  onDeselected: Dispatch<string>;
}) => {
  const thisRowIsSelected = selectedRowIds.some((selectedRow) => selectedRow === row.original._id);
  const someRowIsSelected = !!selectedRowIds.length;

  const toggleSelectRow = () => {
    if (thisRowIsSelected) {
      onDeselected(row.original._id);
    } else {
      onSelected(row.original._id);
    }
  };

  return (
    <tr
      {...row.getRowProps()}
      className={clsx("atlas-group atlas-cursor-pointer")}
      data-testid="selectable-table-row"
      onClick={toggleSelectRow}
    >
      <td
        className={clsx("group-hover:atlas-bg-blue-25", tdClassName)}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event.preventDefault();
        }}
      >
        <Checkbox
          id={row.original._id}
          value={thisRowIsSelected ? CheckboxState.CHECKED : CheckboxState.EMPTY}
          onChange={toggleSelectRow}
        />
      </td>
      {row.cells.map((cell, i: number) => {
        if (i === 0) {
          return (
            <td
              {...cell.getCellProps()}
              className={clsx("atlas-w-auto", "group-hover:atlas-bg-blue-25", tdClassName)}
            >
              <span className="atlas-w-auto atlas-flex atlas-items-center">
                <span>{cell.render("Cell")}</span>
              </span>
            </td>
          );
        }
        return (
          <td
            {...cell.getCellProps()}
            className={clsx(
              "group-hover:atlas-bg-blue-25 atlas-text-left",
              { "atlas-text-left": i < row.cells.length - 2 },
              { "atlas-text-right": i >= row.cells.length - 2 },
              { "atlas-hide-content": i === row.cells.length - 1 && someRowIsSelected },
              tdClassName
            )}
          >
            {cell.render("Cell")}
          </td>
        );
      })}
    </tr>
  );
};
