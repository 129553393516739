import clsx from "clsx";
import React from "react";

interface TableSpacerProps {
  className?: string;
}

export const TableSpacer = ({ className }: TableSpacerProps) => {
  return (
    <thead>
      <tr className={clsx("atlas-h-6", className)} />
    </thead>
  );
};
