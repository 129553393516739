import { updateAppraisal } from "@/react/lib/persistence/apollo";
import { costDependsOnLand, getTotalNetBorrowingFromLoans } from "@/react/utils";
import { RESIDUAL_LAND_VALUE_MAX_ITERATIONS } from "@shared/utils/constants";
import { calculateStampDuty } from "@/utils/stamp_duty";
import { ClientAppraisal, ResidualLandCalculation } from "@shared/types/appraisal";
import { ClientCost } from "@shared/types/computable";
import { Nullable } from "@shared/types/utils";
import { debounce } from "lodash";
import { makeAutoObservable } from "mobx";
import {
  getLandDependentCosts,
  getLoansFromTotalFundableCost,
  getTargetProfit,
  getTotalFundingCost
} from "./ResidualLandValueStoreUtil";
import { CostType } from "@shared/types/costs";
import { RootStore } from "../Root";

export class ResidualLandValueStore {
  readonly root: RootStore;
  residualLandCalculation: Nullable<ResidualLandCalculation> = null;
  residualLandTarget: number = 0;

  private debounceAppraisalUpdate = debounce(
    (update: Partial<ClientAppraisal>) => this.updateApollo(update),
    800
  );

  constructor(rootStore: RootStore) {
    this.root = rootStore;
    makeAutoObservable(this, { root: false });
  }

  setupResidualLandValue(appraisal: ClientAppraisal) {
    if (appraisal.residualLandCalculation !== this.residualLandCalculation) {
      this.residualLandCalculation = appraisal.residualLandCalculation ?? null;
    }
    if (appraisal.residualLandTarget !== this.residualLandTarget) {
      this.residualLandTarget = appraisal.residualLandTarget ?? 0;
    }
  }

  setResidualLandCalculation(value?: ResidualLandCalculation) {
    if (this.residualLandCalculation !== value) {
      this.residualLandCalculation = value ?? null;
      this.updateApollo({ residualLandCalculation: value });
    }
  }

  setResidualLandTarget(value?: number) {
    if (this.residualLandTarget !== value) {
      this.residualLandTarget = value ?? 0;
      this.debounceAppraisalUpdate({ residualLandTarget: value });
    }
  }

  async updateApollo(appraisalUpdate: Partial<ClientAppraisal>) {
    try {
      await updateAppraisal(this.root.appraisalStore.appraisalId, appraisalUpdate);
    } catch {
      const currentAppraisal = this.root.getCurrentAppraisal();
      this.residualLandCalculation = currentAppraisal.residualLandCalculation ?? "Profit on GDV";
      this.residualLandTarget = currentAppraisal.residualLandTarget ?? 0;
    }
  }

  get hasResidualError(): boolean {
    return this.residualPurchasePrice === undefined;
  }

  get errorOutput(): string {
    return this.hasResidualError ? "Negative residual value" : "";
  }

  get targetPrefix(): string {
    if (this.residualLandCalculation === "Total Profit") {
      return "£";
    } else {
      return "";
    }
  }

  get targetSuffix(): string {
    if (this.residualLandCalculation === "Total Profit") {
      return "";
    } else {
      return "%";
    }
  }

  get residualPurchasePrice(): number | undefined {
    const totalSales = this.root.unitGroupStore.totalSales;
    const totalSalesFees = this.root.unitGroupStore.totalSalesFees;

    const totalFixedCost = this.totalFixedCostExSalesFees + totalSalesFees;
    const initialMinPurchasePrice = -totalFixedCost;
    let minPurchasePrice = initialMinPurchasePrice;
    let maxPurchasePrice = totalSales - totalFixedCost;
    let currentGuess = maxPurchasePrice / 2;

    // Iteratively find residual through binary search
    for (let i = 0; i < RESIDUAL_LAND_VALUE_MAX_ITERATIONS; i++) {
      currentGuess = parseFloat(currentGuess.toFixed(2));

      const stampDutyIsFixed = !this.root.costStore.stampDuty?.calculate;
      const stampDuty = stampDutyIsFixed
        ? this.root.costStore.stampDuty?.value ?? 0
        : calculateStampDuty(currentGuess, this.root.appraisalStore.stampDutyBands);
      const landDependentCosts = getLandDependentCosts(this.customCosts, currentGuess);
      const totalLandCost = currentGuess + stampDuty + landDependentCosts;
      const totalFundableCost = totalLandCost + this.totalFixedCostExSalesFees;
      const guessedLoans = getLoansFromTotalFundableCost(
        this.root.loanStore.loans,
        totalFundableCost
      );
      const totalNetBorrowing = getTotalNetBorrowingFromLoans(guessedLoans);
      let maxDeveloperEquity =
        totalFundableCost - totalNetBorrowing - this.root.equityFundingStore.totalEquityAmount;
      maxDeveloperEquity = maxDeveloperEquity > 0 ? maxDeveloperEquity : 0;

      const totalFundingCost = getTotalFundingCost({
        currentGuess,
        stampDuty,
        maxEquity: maxDeveloperEquity,
        loans: guessedLoans,
        equityFunding: this.root.equityFundingStore.equityFundingSources,
        allCosts: this.root.costStore.metricCosts,
        cashflowStore: this.root.cashflowStore,
        unitGroupStore: this.root.unitGroupStore,
        isCashflowInterestLoan: this.root.loanStore.hasCashflowInterestLoan,
        deposit: this.root.costStore.deposit
      });

      const totalEquityCost = this.root.equityFundingStore.totalEquityInterest;

      const totalCost = totalFundableCost + totalFundingCost + totalEquityCost + totalSalesFees;

      const targetProfit = getTargetProfit(
        this.residualLandTarget,
        this.residualLandCalculation,
        totalCost,
        this.root.unitGroupStore.totalSales
      );

      const currentProfit = totalSales - totalCost;
      const profitDiff = parseFloat((currentProfit - targetProfit).toFixed(2));
      if (currentGuess === initialMinPurchasePrice && profitDiff < 0) {
        // Residual is more negative than the assumed minimum
        return undefined;
      } else if (profitDiff === 0) {
        return currentGuess;
      } else if (parseFloat((maxPurchasePrice - minPurchasePrice).toFixed(2)) === 0.01) {
        return minPurchasePrice;
      } else if (profitDiff > 0) {
        minPurchasePrice = currentGuess;
        currentGuess += Math.max((maxPurchasePrice - currentGuess) / 2, 0.01);
      } else if (profitDiff < -0) {
        maxPurchasePrice = currentGuess;
        currentGuess -= Math.max((currentGuess - minPurchasePrice) / 2, 0.01);
      }
    }

    return undefined;
  }

  get totalFixedCostExSalesFees(): number {
    return (
      this.customCosts
        .filter((cost) => !costDependsOnLand(cost))
        .reduce((sum, cost) => sum + cost.value, 0) +
      this.root.buildPhaseStore.totalBuildCostWithContingency
    );
  }

  get customCosts(): ClientCost[] {
    const customCostTypes = [CostType.ProfessionalFee, CostType.OtherCost, CostType.LandFee];
    return this.root.costStore.metricCosts.filter((cost) =>
      customCostTypes.includes(cost.type as CostType)
    );
  }
}
