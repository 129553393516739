import { rootStore } from "@/react/lib/persistence/root_store";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { FC, PropsWithChildren } from "react";

interface CashflowCellProps {
  bottomBorder?: boolean;
  topBorder?: boolean;
  className?: string;
  monthNumber?: number;
  cellType: CashflowCellType;
}

export enum CashflowCellType {
  TITLE,
  VALUE,
  TIME_FRAME,
  CURVE,
  MONTH
}

export const CashflowCell: FC<PropsWithChildren<CashflowCellProps>> = observer(
  ({ topBorder = false, bottomBorder = false, monthNumber = 0, children, cellType, className }) => {
    const rightBorder =
      (CashflowCellType.CURVE === cellType && !rootStore.utilityStore.spreadToolsHidden) ||
      (CashflowCellType.VALUE === cellType && rootStore.utilityStore.spreadToolsHidden) ||
      monthNumber === rootStore.cashflowStore.numMonths;
    return (
      // The column widths are set in the CashflowTableSection th
      // The max widths here just keep the cells in check, gotta love tables
      <td
        className={clsx(
          className,
          "atlas-border-neutral-500 atlas-border-solid atlas-border-0",
          "atlas-p-0",
          "atlas-overflow-hidden atlas-overflow-ellipsis",
          "atlas-bg-white",
          "atlas-whitespace-nowrap",
          {
            "fixed-column": [
              CashflowCellType.TITLE,
              CashflowCellType.VALUE,
              CashflowCellType.TIME_FRAME,
              CashflowCellType.CURVE
            ].includes(cellType),
            "atlas-hidden":
              [CashflowCellType.TIME_FRAME, CashflowCellType.CURVE].includes(cellType) &&
              rootStore.utilityStore.spreadToolsHidden,
            "atlas-border-l atlas-text-left atlas-max-w-64": CashflowCellType.TITLE === cellType,
            "atlas-text-right atlas-max-w-32": CashflowCellType.VALUE === cellType,
            "atlas-text-center atlas-max-w-48": CashflowCellType.TIME_FRAME === cellType,
            "atlas-text-center atlas-max-w-24": CashflowCellType.CURVE === cellType,
            "atlas-text-right atlas-max-w-36": CashflowCellType.MONTH === cellType,
            "atlas-border-r": rightBorder,
            "atlas-border-b": bottomBorder,
            "atlas-border-t": topBorder
          }
        )}
      >
        {children}
      </td>
    );
  }
);
