import { Icon, Tooltip } from "@/react/components";
import { rootStore } from "@/react/lib/persistence/root_store";
import clsx from "clsx";
import React, { FC, ReactElement, useMemo } from "react";
import { twMerge } from "tailwind-merge";

interface NumberOutputType {
  label?: string | ReactElement;
  secondaryLabel?: string;
  hasError?: boolean;
  errorOutput?: string;
  errorTooltip?: string;
  value?: number;
  prefix?: string;
  suffix?: string;
  danger?: boolean;
  decimals?: number;
  className?: string;
}

const NumberOutput: FC<NumberOutputType> = ({
  label = "",
  secondaryLabel = "",
  hasError = false,
  errorOutput = "",
  errorTooltip = "",
  className = "",
  value,
  prefix,
  suffix,
  decimals = 0
}) => {
  const formatValue: string = useMemo(
    () => (value !== 0 && !!value ? rootStore.userStore.numberFormat(value, decimals) : "-"),
    [value, decimals]
  );

  const formattedValue = useMemo(
    () => [prefix, formatValue, suffix].filter(Boolean).join(" "),
    [prefix, formatValue, suffix]
  );

  const valueIsNegative = !!value && value < 0;
  const showOutputInRed = valueIsNegative || hasError;

  return (
    <>
      <div
        className={twMerge(
          clsx(
            "atlas-flex atlas-justify-between atlas-w-full atlas-text-neutral-900 atlas-mb-1",
            className
          )
        )}
        data-testid={"number-output"}
      >
        <span className="atlas-mb-0">
          <>
            {label}
            {!!secondaryLabel && (
              <span className="font-weight-light">
                <>
                  <br />
                  {secondaryLabel}
                </>
              </span>
            )}
          </>
        </span>
        <span className={clsx("atlas-mb-0", { "atlas-pr-1 atlas-text-red-600": showOutputInRed })}>
          {hasError ? (
            <Tooltip content={errorTooltip} asChild={true}>
              <span>
                {errorOutput}
                <Icon.InformationLine className="atlas-w-5 atlas-h-5 atlas-pl-0.5 atlas-pb-0.5" />
              </span>
            </Tooltip>
          ) : (
            formattedValue
          )}
        </span>
      </div>
    </>
  );
};

export { NumberOutput };
