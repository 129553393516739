import { Icon } from "@/react/components";
import { useTranslation } from "@/react/lib/hooks";
import { CILMessaging } from "@/react/lib/i18n/locales";
import { rootStore } from "@/react/lib/persistence/root_store";
import { logEvent } from "@/react/utils/logging";
import { observer } from "mobx-react-lite";
import React, { FC } from "react";

const CILDataObservable: FC = observer(() => {
  const { t } = useTranslation();

  function logCILUrlAction() {
    logEvent("CIL Info Page Clicked", {
      appraisal_id: rootStore.appraisalStore.appraisalId,
      development_id: rootStore.appraisalStore.developmentId,
      CIL_local_authority: rootStore.CILStore.selectedAuthority?.name,
      linked_to_site: rootStore.developmentsStore.isDevelopmentLinkedToSite(
        rootStore.appraisalStore.developmentId
      )
    });
  }

  return (
    <div data-testid="cil-data">
      <div className="atlas-flex atlas-justify-between atlas-mt-4 atlas-pb-1">
        <span>{t(CILMessaging.Status)}</span>
        <span data-testid="cil-status">{rootStore.CILStore.currentCILRecord?.status}</span>
      </div>
      {rootStore.CILStore.hasCILData && (
        <>
          <div className="atlas-flex atlas-justify-between atlas-py-1">
            <span>{t(CILMessaging.Date)}</span>
            <span data-testid="cil-date">{rootStore.CILStore.currentCILRecord?.date}</span>
          </div>
          <div className="atlas-flex atlas-justify-between atlas-py-1">
            <span>{t(CILMessaging.Zones)}</span>
            <span data-testid="cil-zone">{rootStore.CILStore.currentCILRecord?.zones}</span>
          </div>
          {rootStore.CILStore.hasMayoralCIL && (
            <div className="atlas-flex atlas-justify-between atlas-py-1">
              <span>{t(CILMessaging.Mayoral)}</span>
              <span data-testid="mayoral-zone">
                Zone {rootStore.CILStore.currentCILRecord?.mayoral_cil_zone}
              </span>
              <span data-testid="mayoral-rate">
                {rootStore.CILStore.currentCILRecord?.mayoral_cil_rates} £/m²
              </span>
            </div>
          )}
          <div className="atlas-py-3">
            <span className="atlas-font-bold">{t(CILMessaging.Residential_Charges)}</span>
            <p data-testid="cil-residential" className="atlas-m-0">
              {rootStore.CILStore.currentCILRecord?.residential_charges}
            </p>
          </div>
          <div className="atlas-py-3">
            <span className="atlas-font-bold">{t(CILMessaging.Retail)}</span>
            <p data-testid="cil-commercial" className="atlas-m-0">
              {rootStore.CILStore.currentCILRecord?.retail_commercial_charges}
            </p>
          </div>
          <div className="atlas-py-3">
            <span className="atlas-font-bold">{t(CILMessaging.Other)}</span>
            <p data-testid="cil-other" className="atlas-m-0">
              {rootStore.CILStore.currentCILRecord?.others}
            </p>
          </div>
          {!!rootStore.CILStore.currentCILRecord?.url && (
            <div className="atlas-py-3">
              <a
                data-testid="cil-url"
                href={rootStore.CILStore.currentCILRecord?.url}
                target="_blank"
                onClick={logCILUrlAction}
              >
                {t(CILMessaging.View_Website)}
                <Icon.ExternalLinkLine className="atlas-mb-1 atlas-p-0.5" />
              </a>
            </div>
          )}
          <div data-testid="cil-disclaimer" className="atlas-pt-3 atlas-mb-8 atlas-text-sm">
            <span className="atlas-font-bold">{t(CILMessaging.Disclaimer)}</span>
            <p className="atlas-m-0">{t(CILMessaging.Disclaimer_Text)}</p>
          </div>
        </>
      )}
    </div>
  );
});

export const CILData: FC = () => <CILDataObservable />;
