import { Button, ButtonSizes } from "@/react/components";
import { Funding } from "@/react/domains/appraisal";
import { EquityFundingTable, EquityModal } from "@/react/domains/appraisal/Funding";
import { LoanModal } from "@/react/domains/appraisal/Funding/components/LoanModal";
import { useTranslation } from "@/react/lib/hooks";
import { AppraisalFundingTranslations } from "@/react/lib/i18n/locales";
import { rootStore } from "@/react/lib/persistence/root_store";
import { logEvent } from "@/react/utils";
import { AppraisalAnnotationType } from "@shared/types/appraisalAnnotation";
import React, { FC } from "react";
import { AppraisalSection } from "../AppraisalSection";

const FundingSection: FC = () => {
  const { t } = useTranslation();

  return (
    <AppraisalSection
      type={AppraisalAnnotationType.funding}
      title={t(AppraisalFundingTranslations.AppraisalFunding_Funding)}
      testId="funding-section"
    >
      <Funding />

      <div className="atlas-text-center">
        <Button
          onClick={() => rootStore.currentLoanStore.startEditingLoan()}
          className="atlas-mt-3 atlas-mx-2"
          size={ButtonSizes.LG}
          data-testid="loanModalTrigger"
        >
          {t(AppraisalFundingTranslations.AppraisalFunding_AddLoan)}
        </Button>
      </div>
      <LoanModal />

      <div className="atlas-mt-4">
        <EquityFundingTable />
        <div className="atlas-text-center">
          <Button
            onClick={() => {
              logEvent("Add Equity Clicked", {
                appraisal_id: rootStore.getCurrentAppraisal()._id,
                development_id: rootStore.currentDevelopmentStore.development?._id
              });
              rootStore.currentEquityFundingStore.startAddingEquity();
            }}
            className="atlas-mt-3 atlas-mx-2"
            size={ButtonSizes.LG}
            data-testid="equityModalTrigger"
          >
            {t(AppraisalFundingTranslations.AppraisalFunding_AddEquity)}
          </Button>
        </div>
        <EquityModal />
      </div>
    </AppraisalSection>
  );
};

export { FundingSection };
