import { Input, InputSuffix, Select, UnitSettingToggle } from "@/react/components";
import { DebouncedInput } from "@/react/components/Forms/DebouncedInput/DebouncedInput";
import { useTranslation } from "@/react/lib/hooks";
import { AppraisalIncomeTranslations, AppraisalSingularWords } from "@/react/lib/i18n/locales";
import { rootStore } from "@/react/lib/persistence/root_store";
import { formatNumberToString } from "@/react/utils/formatting";
import {
  UnitGroupAction,
  UnitGroupExitType,
  UnitGroupType,
  UnitGroupTypesInstance
} from "@/utils/unit_groups";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { FC, ReactNode } from "react";

export const InputRow: FC<{ children?: ReactNode; className?: string }> = ({
  children,
  className
}) => (
  <div className={clsx("atlas-w-full atlas-flex atlas-gap-3 atlas-mb-4", className)}>
    {children}
  </div>
);

export const UnitGroupDescriptionInput: FC = observer(() => {
  const { t } = useTranslation();
  return (
    <Input
      id="unit-group-name-input"
      testId="unit-group-name"
      className="atlas-w-full atlas-mb-4"
      value={rootStore.currentUnitGroupStore.description}
      onChange={(v: string) => rootStore.currentUnitGroupStore.setDescription(v)}
      label={t(AppraisalIncomeTranslations.AppraisalIncome_UnitGroupDescription)}
      error={{
        message: t(AppraisalIncomeTranslations.AppraisalIncome_UnitGroupDescriptionError),
        display: rootStore.currentUnitGroupStore.validator.state["description"] === false
      }}
    />
  );
});

export const UnitCountInput: FC = observer(() => {
  const { t } = useTranslation();
  return (
    <DebouncedInput
      id="unit-count-input"
      testId="unit-group-unit-count"
      className="atlas-w-1/2"
      value={rootStore.currentUnitGroupStore.unitCount}
      onChange={(v) => rootStore.currentUnitGroupStore.setUnitCount(v)}
      label={t(AppraisalIncomeTranslations.AppraisalIncome_UnitCount)}
      error={{
        message: t(AppraisalIncomeTranslations.AppraisalIncome_UnitCountError),
        display: rootStore.currentUnitGroupStore.validator.state["unitCount"] === false
      }}
    />
  );
});

export const UnitBedsCountInput: FC = observer(() => {
  const { t } = useTranslation();
  return (
    <DebouncedInput
      id="unit-beds-input"
      testId="unit-group-unit-beds"
      className="atlas-w-1/2"
      value={rootStore.currentUnitGroupStore.beds}
      onChange={(v) => rootStore.currentUnitGroupStore.setBeds(v)}
      label={t(AppraisalIncomeTranslations.AppraisalIncome_UnitBedroomsCount)}
      error={{
        message: t(AppraisalIncomeTranslations.AppraisalIncome_UnitBedroomsCountError),
        display: rootStore.currentUnitGroupStore.validator.state["beds"] === false
      }}
    />
  );
});

export const UnitAreaInput = observer(() => {
  const { t } = useTranslation();
  return (
    <div className="atlas-flex atlas-w-1/2">
      <DebouncedInput
        id="unit-area-input"
        testId="unit-group-unit-area"
        value={rootStore.currentUnitGroupStore.convertedArea}
        onChange={(v) => rootStore.currentUnitGroupStore.setArea(v)}
        label={t(AppraisalIncomeTranslations.AppraisalIncome_UnitArea)}
        suffix={rootStore.userStore.minorAreaUnitDisplay}
        error={{
          message: t(AppraisalIncomeTranslations.AppraisalIncome_UnitAreaError),
          display: rootStore.currentUnitGroupStore.validator.state["area"] === false
        }}
      />
      <div className="atlas-flex atlas-items-start atlas-ml-1 atlas-pt-9 ">
        <UnitSettingToggle />
      </div>
    </div>
  );
});

export const PercentGIAInput: FC = observer(() => {
  const { t } = useTranslation();
  return (
    <DebouncedInput
      id="unit-group-gia-input"
      testId="unit-group-gia"
      className="atlas-w-1/2"
      value={rootStore.currentUnitGroupStore.percentGIA}
      suffix="%"
      onChange={(v) => rootStore.currentUnitGroupStore.setPercentGIA(v)}
      label={t(AppraisalIncomeTranslations.AppraisalIncome_PercentGIA)}
      error={{
        message: t(AppraisalIncomeTranslations.AppraisalIncome_PercentGIAError),
        display: rootStore.currentUnitGroupStore.validator.state["percentGIA"] === false
      }}
    />
  );
});

export const InvestmentYieldInput = observer(() => {
  const { t } = useTranslation();
  return (
    <DebouncedInput
      id="unit-investment-yield-input"
      testId="unit-group-unit-investment-yield"
      className="atlas-w-1/2"
      value={rootStore.currentUnitGroupStore.investmentYield}
      suffix="%"
      onChange={(v) => rootStore.currentUnitGroupStore.setInvestmentYield(v)}
      label={t(AppraisalIncomeTranslations.AppraisalIncome_InvestmentYield)}
      error={{
        message: t(AppraisalIncomeTranslations.AppraisalIncome_InvestmentYieldError),
        display: rootStore.currentUnitGroupStore.validator.state["investmentYield"] === false
      }}
    />
  );
});

export const RentFreePeriodInput = observer(() => {
  const { t } = useTranslation();
  return (
    <DebouncedInput
      id="unit-rent-free-period-input"
      testId="unit-group-unit-rent-free-period"
      className="atlas-w-1/2"
      value={rootStore.currentUnitGroupStore.rentFreePeriod}
      onChange={(v) => rootStore.currentUnitGroupStore.setRentFreePeriod(v)}
      label={t(AppraisalIncomeTranslations.AppraisalIncome_RentFreePeriod)}
      error={{
        message: t(AppraisalIncomeTranslations.AppraisalIncome_RentFreePeriodError),
        display: rootStore.currentUnitGroupStore.validator.state["rentFreePeriod"] === false
      }}
    >
      <InputSuffix>{t(AppraisalSingularWords.Appraisal_Months)}</InputSuffix>
    </DebouncedInput>
  );
});

export const VoidPeriodInput = observer(() => {
  const { t } = useTranslation();
  return (
    <DebouncedInput
      id="unit-void-period-input"
      testId="unit-group-unit-void-period"
      className="atlas-w-1/2"
      value={rootStore.currentUnitGroupStore.voidPeriod}
      onChange={(v) => rootStore.currentUnitGroupStore.setVoidPeriod(v)}
      label={t(AppraisalIncomeTranslations.AppraisalIncome_VoidPeriod)}
      error={{
        message: t(AppraisalIncomeTranslations.AppraisalIncome_VoidPeriodError),
        display: rootStore.currentUnitGroupStore.validator.state["voidPeriod"] === false
      }}
    >
      <InputSuffix>{t(AppraisalSingularWords.Appraisal_Months)}</InputSuffix>
    </DebouncedInput>
  );
});

export const MonthlyRentInput = observer(() => {
  const { t } = useTranslation();
  return (
    <DebouncedInput
      id="unit-monthly-rent-input"
      testId="unit-group-unit-monthly-rent"
      className="atlas-w-1/2"
      prefix="£"
      value={rootStore.currentUnitGroupStore.monthlyRentIncome}
      onChange={(v: number) => rootStore.currentUnitGroupStore.setMonthlyRentIncome(v)}
      label={t(AppraisalIncomeTranslations.AppraisalIncome_MonthlyRent)}
      error={{
        message: t(AppraisalIncomeTranslations.AppraisalIncome_MonthlyRentError),
        display: rootStore.currentUnitGroupStore.validator.state["annualRentIncome"] === false
      }}
    />
  );
});

export const TotalNIAOutput: FC<{ className?: string }> = observer(({ className }) => {
  const { t } = useTranslation();
  return (
    <Input
      id="unit-group-total-nia"
      testId="total-nia"
      trackCursor={true}
      className={className}
      disabled={true}
      inputClassName="atlas-text-right"
      value={formatNumberToString(rootStore.currentUnitGroupStore.convertedUnitGroupNIA)}
      label={t(AppraisalIncomeTranslations.AppraisalIncome_TotalNIA)}
      suffix={rootStore.userStore.minorAreaUnitDisplay}
    />
  );
});

export const GrossSalesValueOutput = observer(() => {
  const { t } = useTranslation();
  return (
    <Input
      id="gross-sales-value-per-unit"
      testId="gross-sales-value"
      disabled={true}
      className="atlas-w-1/2"
      trackCursor={true}
      inputClassName="atlas-text-right"
      value={formatNumberToString(rootStore.currentUnitGroupStore.yieldBasedValue)}
      label={t(AppraisalIncomeTranslations.AppraisalIncome_GrossSalesValue)}
      prefix="£"
    />
  );
});

const unitTypeOptions = Object.values(UnitGroupTypesInstance).map(({ value, displayName }) => ({
  option: value,
  label: displayName
}));

export const UnitTypeSelect = observer(() => (
  <Select
    label="Unit Type"
    testId="unit-type-select"
    selectClassName="atlas-bg-transparent"
    value={rootStore.currentUnitGroupStore.unitType}
    onChange={(v) =>
      typeof v === "string" && rootStore.currentUnitGroupStore.setUnitType(v as UnitGroupType)
    }
    options={unitTypeOptions}
    showActiveItem={true}
    width="atlas-w-1/2"
  />
));

const exitTypeOptions = [
  { option: UnitGroupExitType.SALE, label: "Sale" },
  { option: UnitGroupExitType.RENT, label: "Rent" }
];

export const ExitTypeSelect = observer(() => (
  <Select
    className="atlas-mb-4"
    testId="exit-type-select"
    selectClassName="atlas-bg-transparent"
    label="Exit Type"
    value={rootStore.currentUnitGroupStore.exitType}
    onChange={(v) =>
      typeof v === "string" && rootStore.currentUnitGroupStore.setExitType(v as UnitGroupExitType)
    }
    options={exitTypeOptions}
    showActiveItem={true}
  />
));

export const SalesValueInput: FC = observer(() => {
  const onChangeValue = (val: number) => rootStore.currentUnitGroupStore.updateSalesValueVal(val);
  const onChangeBase = (base: number) => rootStore.currentUnitGroupStore.updateSalesValueBase(base);
  const { t } = useTranslation();
  return (
    <InputRow>
      <DebouncedInput
        id="unit-sales-value-input"
        testId="unit-sales-value"
        className="atlas-w-1/2"
        label={
          rootStore.currentUnitGroupStore.unitGroupAction !== UnitGroupAction.EDITING_UNIT
            ? t(AppraisalIncomeTranslations.AppraisalIncome_SalesValue)
            : t(AppraisalIncomeTranslations.AppraisalIncome_SalesValuePerUnit)
        }
        value={rootStore.currentUnitGroupStore.convertedSalesValue.value}
        onChange={onChangeValue}
        prefix="£"
        error={{
          message: t(AppraisalIncomeTranslations.AppraisalIncome_SalesValueError),
          display: rootStore.currentUnitGroupStore.validator.state["salesValue"] === false
        }}
      />
      <DebouncedInput
        id="unit-sales-value-base"
        testId="unit-sales-value-base-input"
        className="atlas-w-1/2 atlas-pt-8"
        value={rootStore.currentUnitGroupStore.convertedSalesValue.calculationBase}
        prefix="£"
        onChange={onChangeBase}
      >
        <InputSuffix>£/{rootStore.userStore.minorAreaUnitDisplay}</InputSuffix>
      </DebouncedInput>
    </InputRow>
  );
});

export const SalesFeeInput = observer(() => {
  const onChangeValue = (val: number) => rootStore.currentUnitGroupStore.updateSalesFeeVal(val);
  const onChangeBase = (base: number) => rootStore.currentUnitGroupStore.updateSalesFeeBase(base);
  const { t } = useTranslation();
  return (
    <InputRow>
      <DebouncedInput
        id="unit-sales-fee-input"
        testId="unit-sales-fee"
        className="atlas-w-1/2"
        label={
          !rootStore.currentUnitGroupStore.isEditingUnit
            ? t(AppraisalIncomeTranslations.AppraisalIncome_SalesFeePerUnit)
            : t(AppraisalIncomeTranslations.AppraisalIncome_SalesFee)
        }
        value={rootStore.currentUnitGroupStore.salesFee.value}
        onChange={onChangeValue}
        prefix="£"
        error={{
          message: t(AppraisalIncomeTranslations.AppraisalIncome_SalesFeeError),
          display: rootStore.currentUnitGroupStore.validator.state["salesFee"] === false
        }}
      />
      <DebouncedInput
        id="unit-sales-fee-base"
        testId="unit-sales-fee-base-input"
        className="atlas-w-1/2 atlas-pt-8"
        value={rootStore.currentUnitGroupStore.salesFee.calculationBase}
        suffix="%"
        onChange={onChangeBase}
      >
        <InputSuffix>{t(AppraisalIncomeTranslations.AppraisalIncome_OfSales)}</InputSuffix>
      </DebouncedInput>
    </InputRow>
  );
});

export const LegalFeesInput = observer(() => {
  const onChangeValue = (val: number) => rootStore.currentUnitGroupStore.updateLegalFeesVal(val);
  const onChangeBase = (base: number) => rootStore.currentUnitGroupStore.updateLegalFeesBase(base);
  const { t } = useTranslation();
  return (
    <InputRow>
      <DebouncedInput
        id="unit-legal-fees-input"
        testId="unit-legal-fees"
        className="atlas-w-1/2"
        label={
          !rootStore.currentUnitGroupStore.isEditingUnit
            ? t(AppraisalIncomeTranslations.AppraisalIncome_LegalFeesPerUnit)
            : t(AppraisalIncomeTranslations.AppraisalIncome_LegalFees)
        }
        value={rootStore.currentUnitGroupStore.legalFees.value}
        onChange={onChangeValue}
        prefix="£"
        error={{
          message: t(AppraisalIncomeTranslations.AppraisalIncome_LegalFeesError),
          display: rootStore.currentUnitGroupStore.validator.state["legalFees"] === false
        }}
      />
      <DebouncedInput
        id="unit-legal-fees-base"
        testId="unit-legal-fees-base-input"
        className="atlas-w-1/2 atlas-pt-8"
        value={rootStore.currentUnitGroupStore.legalFees.calculationBase}
        suffix="%"
        onChange={onChangeBase}
      >
        <InputSuffix>{t(AppraisalIncomeTranslations.AppraisalIncome_OfSales)}</InputSuffix>
      </DebouncedInput>
    </InputRow>
  );
});

export const AnnualRentInput = observer(() => {
  const calculationType = rootStore.currentUnitGroupStore.annualRentIncome.calculationType;
  const onChangeValue = (val: number) => {
    rootStore.currentUnitGroupStore.setAnnualRentIncome({
      calculationType,
      value: val,
      calculationBase: val / rootStore.currentUnitGroupStore.convertedArea,
      calculate: false
    });
  };
  const onChangeBase = (base: number) => {
    rootStore.currentUnitGroupStore.setAnnualRentIncome({
      calculationType,
      calculationBase: base,
      value: base * rootStore.currentUnitGroupStore.convertedArea,
      calculate: true
    });
  };
  const { t } = useTranslation();
  return (
    <InputRow>
      <DebouncedInput
        id="unit-annual-rent-income"
        testId="unit-annual-rent-income-input"
        className="atlas-w-1/2"
        label={
          rootStore.currentUnitGroupStore.unitGroupAction === UnitGroupAction.EDITING_UNIT
            ? t(AppraisalIncomeTranslations.AppraisalIncome_AnnualRent)
            : t(AppraisalIncomeTranslations.AppraisalIncome_AnnualRentPerUnit)
        }
        value={rootStore.currentUnitGroupStore.convertedAnnualRentIncome.value}
        onChange={onChangeValue}
        prefix="£"
        error={{
          message: t(AppraisalIncomeTranslations.AppraisalIncome_AnnualRentError),
          display: rootStore.currentUnitGroupStore.validator.state["annualRentIncome"] === false
        }}
      />
      <DebouncedInput
        id="unit-annual-rent-income-base"
        testId="unit-annual-rent-income-base-input"
        className="atlas-w-1/2"
        value={rootStore.currentUnitGroupStore.convertedAnnualRentIncome.calculationBase}
        label={`${t(AppraisalIncomeTranslations.AppraisalIncome_IncomePer)} ${
          rootStore.userStore.minorAreaUnitDisplay
        }`}
        prefix="£"
        onChange={onChangeBase}
      />
    </InputRow>
  );
});

export const OtherIncomeInput = observer(() => {
  const onChangeValue = (val: number) => {
    rootStore.currentUnitGroupStore.setSalesValue({
      ...rootStore.currentUnitGroupStore.salesValue,
      value: val
    });
  };
  const onChangeTotal = (total: number) => {
    if (rootStore.currentUnitGroupStore.unitCount > 0) {
      rootStore.currentUnitGroupStore.setSalesValue({
        ...rootStore.currentUnitGroupStore.salesValue,
        value: total / rootStore.currentUnitGroupStore.unitCount
      });
    }
  };
  const { t } = useTranslation();
  return (
    <InputRow>
      <DebouncedInput
        id="unit-other-income-input"
        testId="unit-other-income"
        className="atlas-w-1/2"
        label={t(AppraisalIncomeTranslations.AppraisalIncome_OtherIncome)}
        value={rootStore.currentUnitGroupStore.salesValue.value}
        onChange={onChangeValue}
        prefix="£"
        error={{
          message: t(AppraisalIncomeTranslations.AppraisalIncome_OtherIncomeError),
          display: rootStore.currentUnitGroupStore.validator.state["salesValue"] === false
        }}
      />
      {rootStore.currentUnitGroupStore.isAdding && (
        <DebouncedInput
          id="unit-other-income-base"
          testId="unit-other-income-base-input"
          className="atlas-w-1/2"
          value={
            rootStore.currentUnitGroupStore.salesValue.value *
            rootStore.currentUnitGroupStore.unitCount
          }
          label={t(AppraisalIncomeTranslations.AppraisalIncome_OtherIncomeTotal)}
          prefix="£"
          onChange={onChangeTotal}
        />
      )}
    </InputRow>
  );
});
