<template>
  <div>
    <b-dropdown
      size="sm"
      variant="clear"
      toggle-class="text-decoration-none px-1"
      no-caret
      right
      v-b-tooltip.hover
      :title="tooltipText"
      boundary="scrollParent"
      :popper-opts="popperOpts"
      data-intercom-target="options_menu"
    >
      <template #button-content @click.prevent.stop="toggleOptions">
        <b-icon :icon="icon"></b-icon><span class="sr-only">{{ tooltipText }}</span>
      </template>
      <b-dropdown-item-button v-if="this.$listeners.template" @click.stop="templatePressed">
        <span>Save as Template</span>
      </b-dropdown-item-button>
      <b-dropdown-item-button v-if="this.$listeners.duplicate" @click.stop="duplicatePressed">
        <span>Duplicate</span>
      </b-dropdown-item-button>
      <b-dropdown-item-button v-if="this.$listeners.add" @click.stop="addPressed">
        <span>Add</span>
      </b-dropdown-item-button>
      <b-dropdown-item-button v-if="this.$listeners.edit" @click.stop="editPressed">
        <span>Edit</span>
      </b-dropdown-item-button>
      <b-dropdown-item-button v-if="this.$listeners.archive" @click.stop="archivePressed">
        <span>Archive</span>
      </b-dropdown-item-button>
      <b-dropdown-item-button v-if="this.$listeners.restore" @click.stop="restorePressed">
        <span>Restore</span>
      </b-dropdown-item-button>
      <b-dropdown-item-button v-if="this.$listeners.delete" @click.stop="deletePressed">
        <span>Delete</span>
      </b-dropdown-item-button>
      <b-dropdown-item-button v-if="this.$listeners.hideTools" @click.stop="hideToolsPressed">
        <span>Hide Spread Tools</span>
      </b-dropdown-item-button>
    </b-dropdown>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Nullable } from "@shared/types/utils";

@Component({})
export default class OptionsMenu extends Vue {
  @Prop({ required: true }) readonly objectId!: string;
  @Prop({ required: true }) readonly objectType!: string;
  @Prop({ default: true }) readonly setPopperOpts!: boolean;
  @Prop({ default: false }) readonly hasVerticalDots!: boolean;

  private objectToAction: Nullable<string> = null;
  private toggleOptionsState = false;
  private eventType = "";
  private dialogText = "";
  private confirmType = "primary";
  private cancelText = "Cancel";
  private confirmText = "Confirm";

  get icon() {
    return this.hasVerticalDots ? "three-dots-vertical" : "three-dots";
  }

  get popperOpts() {
    if (this.setPopperOpts) {
      return { positionFixed: true };
    } else {
      return {};
    }
  }

  get tooltipText() {
    return this.objectType + " Options";
  }

  public onConfirm() {
    this.$emit(this.eventType, this.objectToAction);
    this.objectToAction = null;
  }

  public onCancel() {
    this.objectToAction = null;
  }

  public restorePressed() {
    this.eventType = "restore";
    this.dialogText = `Restore this ${this.objectType.toLowerCase()}?`;
    this.objectToAction = this.objectId;
    this.confirmText = "Restore";
    this.confirmType = "primary";

    this.showDialog();
  }

  public archivePressed() {
    this.eventType = "archive";
    this.dialogText = `Archive this ${this.objectType.toLowerCase()}?`;
    this.objectToAction = this.objectId;
    this.confirmText = "Archive";
    this.confirmType = "primary";

    this.showDialog();
  }

  public deletePressed() {
    this.eventType = "delete";
    this.dialogText = `Delete this ${this.objectType.toLowerCase()} forever?`;
    this.objectToAction = this.objectId;
    this.confirmText = "Delete";
    this.confirmType = "danger";

    this.showDialog();
  }

  public duplicatePressed() {
    this.eventType = "duplicate";
    this.dialogText = `Duplicate this ${this.objectType.toLowerCase()}?`;
    this.objectToAction = this.objectId;
    this.confirmText = "Duplicate";
    this.confirmType = "primary";

    this.showDialog();
  }

  public templatePressed() {
    this.eventType = "template";
    this.dialogText = `Create template from this ${this.objectType.toLowerCase()}?`;
    this.objectToAction = this.objectId;
    this.confirmText = "Create Template";
    this.confirmType = "primary";

    this.showDialog();
  }

  public addPressed() {
    this.eventType = "add";
    this.$emit(this.eventType, this.objectToAction);
  }

  public editPressed() {
    this.eventType = "edit";
    this.$emit(this.eventType, this.objectToAction);
  }

  public hideToolsPressed() {
    this.eventType = "hideTools";
    this.$emit(this.eventType, this.objectToAction);
  }

  public async showDialog() {
    const value = await this.$bvModal.msgBoxConfirm(this.dialogText, {
      title: "Please Confirm",
      okTitle: this.confirmText,
      okVariant: this.confirmType,
      cancelTitle: this.cancelText,
      centered: true
    });

    if (value) {
      this.onConfirm();
    } else {
      this.onCancel();
    }
  }

  public toggleOptions() {
    this.toggleOptionsState = !this.toggleOptionsState;
  }
}
</script>
