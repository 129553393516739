import { captureException } from "@/utils/monitoring";
import axios from "axios";

export type EventData = Record<string, string | boolean | number | undefined>;

export const logEvent = (name: string, properties?: EventData) => {
  if (process.env.NODE_ENV === "development") {
    // tslint:disable-next-line:no-console
    console.log("[TRACK HTTP]", name, properties);
  } else {
    axios.post(`${LANDFUND_CONFIG.ACCOUNTS_SERVICE_PRIVATE_API_URL}/event`, {
      name,
      properties,
      source: "LandFund"
    });
  }
};

export const logError = (err: Error | string, options?: object) => {
  // tslint:disable-next-line:no-console
  console.error(err);
  captureException(err, { ...options, type: "error" });
};

export const logWarning = (warning: string, options?: object) => {
  // tslint:disable-next-line:no-console
  console.warn(warning);
  captureException(warning, { ...options, type: "warning" });
};
