<template>
  <div v-if="isAppVersionProblem" class="d-flex justify-content-around align-items-center">
    <b-alert show variant="warning" class="version-alert">
      <b-icon class="warning-icon" icon="lt-warning"></b-icon>
      <span class="alert-message">A newer version of LandFund is available.</span>
      <b-button class="refresh-button" variant="outline-warning" @click="onRefreshButtonClick">
        Refresh
      </b-button>
    </b-alert>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Timeout } from "@shared/types/timeout";

@Component({})
export default class VersionAlert extends Vue {
  private isAppVersionProblem = false;
  private versionCheckInterval?: Timeout;

  checkVersions() {
    this.isAppVersionProblem = LANDFUND_CONFIG.APP_VERSION !== LANDFUND_CONFIG.SERVER_VERSION;
  }

  mounted() {
    this.checkVersions();
    this.versionCheckInterval = setInterval(() => {
      this.checkVersions();
    }, 1000);
  }

  unmounted() {
    clearInterval(this.versionCheckInterval as Timeout);
  }

  onRefreshButtonClick() {
    window.location.reload();
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/landtech-colors";
.alert.version-alert {
  background: $orange-50;
  border: 1px solid $orange-300;
  box-sizing: border-box;
  border-radius: 4px;

  color: $landfund-version-alert-text;

  font-size: 12px;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 0;
  padding: 8px;
  width: 372px;
  .warning-icon {
    color: $orange-300;
    margin-right: 8px;
  }

  .refresh-button {
    font-weight: 400;
    font-size: 12px;
  }
}
</style>
