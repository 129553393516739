"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ComputableCalculationType = void 0;
var ComputableCalculationType;
(function (ComputableCalculationType) {
    ComputableCalculationType["PER_UNIT"] = "per-unit-of-all-units";
    ComputableCalculationType["PER_BUILD_PHASE_UNIT"] = "per-unit";
    ComputableCalculationType["PER_AREA_UNIT"] = "per-area-unit";
    ComputableCalculationType["PERCENTAGE_OF_SALES"] = "percentage-of-sales";
    ComputableCalculationType["PERCENTAGE_OF_RENT"] = "percentage-of-rent";
    ComputableCalculationType["PERCENTAGE_OF_COSTS"] = "percentage-of-costs";
    ComputableCalculationType["PERCENTAGE_OF_LOAN"] = "percentage-of-loan";
    ComputableCalculationType["PERCENTAGE_OF_NIA"] = "percentage-of-NIA";
    ComputableCalculationType["PERCENTAGE_OF_LAND"] = "percentage-of-land";
    ComputableCalculationType["PERCENTAGE_OF_GDV"] = "percentage-of-gdv";
    ComputableCalculationType["PERCENTAGE_OF_CONSTRUCTION"] = "percentage-of-construction";
    ComputableCalculationType["STAMP_DUTY_BANDS"] = "stamp-duty-bands";
})(ComputableCalculationType = exports.ComputableCalculationType || (exports.ComputableCalculationType = {}));
