import { initLineItem } from "@/react/utils/lineItem";
import {
  CREATE_LINE_ITEM_MUTATION,
  DELETE_LINE_ITEM_MUTATION,
  UPDATE_LINE_ITEM_MUTATION
} from "@shared/apollo/mutations";
import { ClientLineItem, ClientLineItemExtended } from "@shared/types/cashflow";
import gql from "graphql-tag";
import { pick } from "lodash";
import { landFundApolloClient } from "../clients";
import { sanitiseInput, updateAppraisalCache } from "../utils";
import { getCachedAppraisal } from "../queries";

export const createLineItem = async (appraisalId: string, lineItem: ClientLineItem) => {
  return landFundApolloClient.mutate({
    mutation: gql`
      ${CREATE_LINE_ITEM_MUTATION}
    `,
    variables: { appraisalId, lineItem },
    update: (store, { data }) => {
      const cachedAppraisal = getCachedAppraisal(appraisalId);
      cachedAppraisal.cashflow.lineItems.push(data.createLineItem);
      updateAppraisalCache(store, cachedAppraisal);
    }
  });
};

export const deleteLineItem = async (appraisalId: string, linkedId: string) => {
  return landFundApolloClient.mutate({
    mutation: gql`
      ${DELETE_LINE_ITEM_MUTATION}
    `,
    variables: { appraisalId, linkedId },
    update: (store) => {
      const cachedAppraisal = getCachedAppraisal(appraisalId);
      cachedAppraisal.cashflow.lineItems = cachedAppraisal.cashflow.lineItems?.filter(
        (lineItem) => lineItem._linkedId !== linkedId
      );
      updateAppraisalCache(store, cachedAppraisal);
    }
  });
};

export const updateLineItem = async (appraisalId: string, lineItem: ClientLineItemExtended) => {
  return landFundApolloClient.mutate({
    mutation: gql`
      ${UPDATE_LINE_ITEM_MUTATION}
    `,
    variables: {
      appraisalId,
      lineItemUpdate: sanitiseInput(pick(lineItem, Object.keys(initLineItem)), false)
    }
  });
};
