import { costDependsOnLand } from "@/react/utils";
import { getFinanceOutputs } from "@/utils/cashflow_finance";
import { ClientAppraisal, ResidualLandCalculation } from "@shared/types/appraisal";
import { CashflowLoanOutput } from "@shared/types/cashflow";
import { ClientCost } from "@shared/types/computable";
import { ClientLoan } from "@shared/types/loan";
import { Nullable } from "@shared/types/utils";
import { loanCost, nonCashflowLoanCost } from "@shared/utils/loan";
import { cloneDeep } from "lodash";
import { UnitGroupStore } from "../UnitGroup";
import { automatedMonthlyNetsGivenPurchasePrice } from "./ResidualCashflowUtil";
import { ClientEquityFunding } from "@shared/types/equity";
import { CashflowStore } from "../Cashflow";

export const getLandDependentCosts = (customCosts: ClientCost[], purchasePrice: number): number => {
  return customCosts
    .filter((cost) => costDependsOnLand(cost))
    .reduce((sum, cost) => sum + (cost.calculationBase / 100) * purchasePrice, 0);
};

interface ITotalFundingCostOptions {
  currentGuess: number;
  stampDuty: number;
  maxEquity: number;
  loans: ClientLoan[];
  equityFunding: ClientEquityFunding[];
  allCosts: ClientCost[];
  unitGroupStore: UnitGroupStore;
  isCashflowInterestLoan: boolean;
  cashflowStore: CashflowStore;
  deposit?: ClientCost;
}

export const getTotalFundingCost = ({
  currentGuess,
  stampDuty,
  maxEquity,
  loans,
  equityFunding,
  allCosts,
  deposit,
  unitGroupStore,
  cashflowStore,
  isCashflowInterestLoan
}: ITotalFundingCostOptions) => {
  if (isCashflowInterestLoan) {
    // If there is a loan's interest that is dependant on the Cashflow, we have to see what the interest would be
    // when we take into account the parts of the Cashflow that are automated, as some lineItems are themselves dependant on purchase price
    const monthlyNets: number[] = automatedMonthlyNetsGivenPurchasePrice(
      currentGuess,
      stampDuty,
      allCosts,
      unitGroupStore,
      cashflowStore,
      deposit
    );
    const { loanOutputs } = getFinanceOutputs(monthlyNets, loans, equityFunding, maxEquity);
    return getTotalFundingCostFromLoans(loans, loanOutputs);
  } else {
    // If there are no loans with the cashflow interest type then we don't need to bother calculating the loanOutputs
    // and get to take the easy path
    return getTotalFundingCostFromLoansNonCashflow(loans);
  }
};

export const getTotalFundingCostFromLoans = (
  loans: ClientLoan[],
  loanOutputs: CashflowLoanOutput[]
): number => {
  return loans.reduce((sum, loan) => sum + loanCost(loan, loanOutputs), 0);
};

export const getTotalFundingCostFromLoansNonCashflow = (loans: ClientLoan[]): number => {
  return loans.reduce((sum, loan) => sum + nonCashflowLoanCost(loan), 0);
};

export const getLoansFromTotalFundableCost = (loans: ClientLoan[], totalFundableCost: number) => {
  return loans.map((loan: ClientLoan) => {
    const clonedLoan = cloneDeep(loan);
    const amount = loanDependsOnCosts(loan)
      ? loan.amount.value
      : (loan.amount.calculationBase / 100) * totalFundableCost;
    clonedLoan.amount.value = amount;
    return clonedLoan;
  });
};

export const loanDependsOnCosts = (loan: ClientLoan): boolean => {
  return !(loan.amount.calculate && loan.amount.calculationType === "percentage-of-costs");
};

export const getTargetProfit = (
  residualLandTarget: number,
  residualLandCalculation: Nullable<ResidualLandCalculation>,
  totalCost: number,
  totalSales: number
): number => {
  if (!residualLandTarget) {
    return 0;
  } else if (residualLandCalculation === "Profit on GDV") {
    return (residualLandTarget / 100) * totalSales;
  } else if (residualLandCalculation === "Profit on Cost") {
    return (residualLandTarget / 100) * totalCost;
  } else if (residualLandCalculation === "Total Profit") {
    return residualLandTarget;
  }
  return 0;
};
