import React, { FC, PropsWithChildren, useContext } from "react";
import * as TabsPrimitive from "@radix-ui/react-tabs";
import { TabsContext } from "@/react/components/Tabs/TabsProvider";
import clsx from "clsx";

interface TabsTriggerProps {
  value: string;
  className?: string;
  activeClassName?: string;
  onClick?: (v: string, evt: React.MouseEvent<HTMLAnchorElement>) => void;
}

const TabsTrigger: FC<PropsWithChildren<TabsTriggerProps>> = ({
  value,
  className = "",
  activeClassName = "",
  children,
  onClick = () => {}
}) => {
  const context = useContext(TabsContext);

  return (
    <TabsPrimitive.Trigger asChild={true} value={value}>
      <a
        href="#"
        onClick={(evt) => {
          evt.preventDefault();
          context.setActiveTab(value);
          onClick(value, evt);
        }}
        className={clsx(
          "atlas-appearance-none",
          className,
          context.activeTab === value ? activeClassName : "atlas-bg-transparent"
        )}
        data-testid={"tabs-trigger"}
      >
        {children}
      </a>
    </TabsPrimitive.Trigger>
  );
};

export { TabsTrigger };
