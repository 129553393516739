<template>
  <div>
    <Funding />
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import EventLoggerMixin from "@/mixins/event_logger_mixin";
import { FundingSection as Funding } from "@/react/layouts/AppraisalPage";
@Component({
  components: {
    Funding: Funding as Vue.Component
  }
})
export default class FundingSection extends Mixins(EventLoggerMixin) {}
</script>
