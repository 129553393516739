<template>
  <b-link class="site-link text-nowrap" :href="href" target="_blank" :disabled="!siteId">
    <span>Open in LandInsight</span>
    <b-icon icon="box-arrow-up-right" class="ml-1" />
  </b-link>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

const LANDINSIGHT_APP_URL = LANDFUND_CONFIG.LANDINSIGHT_APP_URL;

@Component({})
export default class SiteLink extends Vue {
  @Prop({ default: "" }) readonly siteId!: string;

  get href() {
    return `${LANDINSIGHT_APP_URL}/sites?card_page=summary&site_id=${this.siteId}`;
  }
}
</script>

<style lang="scss" scoped>
.site-link {
  font-size: 14px;
}
</style>
