<template>
  <div>
    <BuildPhaseAccordionHeader
      :buildPhaseHasBeenAdded="buildPhaseHasBeenAdded"
      :buildPhaseId="buildPhase._id"
      :description="buildPhase.description"
    />
    <b-collapse
      :id="'accordion-' + buildPhase._id"
      accordion="buildPhase-accordion"
      role="tabpanel"
      class="px-4 py-3"
    >
      <div>
        <h6 class="font-weight-bold">Assign Units/Area</h6>
        <BuildPhaseUnitGroups
          :build-phase-id="buildPhase._id"
          :assigned-unit-groups="buildPhase.assignedUnitGroups"
          :is-custom="buildPhase.isCustom"
        />
        <BuildPhaseData :buildPhase="buildPhase" />
      </div>
      <div>
        <div class="font-weight-bold mb-2">Costs</div>
        <CostList
          costType="construction"
          :buildPhaseId="buildPhase._id"
          :costs="CostStore.costsForBuildPhase(buildPhase._id)"
          :calculationOptions="[
            ...CalculationsStore.perBuildPhaseGIACalculationOption(buildPhase),
            ...CalculationsStore.costPerBuildPhaseUnitCalculationOption(buildPhase)
          ]"
        />
      </div>
    </b-collapse>
  </div>
</template>

<script lang="ts">
import { CostList } from "@/react/domains/appraisal/Costs/CostList/CostList";
import { rootStore } from "@/react/lib/persistence/root_store";
import { ClientBuildPhase } from "@shared/types/buildPhase";
import { Observer } from "mobx-vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import BuildPhaseAccordionHeader from "./BuildPhaseAccordionHeader.vue";
import BuildPhaseData from "./BuildPhaseData.vue";
import BuildPhaseUnitGroups from "./BuildPhaseUnitGroups.vue";

@Observer
@Component({
  components: {
    BuildPhaseAccordionHeader,
    BuildPhaseData,
    BuildPhaseUnitGroups,
    CostList: CostList as Vue.Component
  }
})
export default class BuildPhaseAccordionItem extends Vue {
  @Prop({ required: true }) readonly buildPhase!: ClientBuildPhase;
  @Prop({ required: true }) readonly buildPhaseHasBeenAdded!: boolean;

  private CostStore = rootStore.costStore;
  private CalculationsStore = rootStore.calculationsStore;
}
</script>
