<template>
  <b-card
    class="sensitivity-analysis"
    header="Sensitivity Analysis"
    data-intercom-target="sensitivity_analysis"
  >
    <div>
      <div class="">
        <b-form-group>
          <label>Profit Calculation</label>
          <b-form-select v-model="calculationType" id="calcType">
            <b-form-select-option
              v-for="(item, index) in profitOptions"
              :key="index"
              :value="item.value"
            >
              {{ item.text }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
      </div>
      <div class="flex-row table">
        <div class="text-vertical text-table-heading">Build Costs</div>
        <b-table-simple hover responsive borderless>
          <b-tr>
            <b-th colSpan="2"></b-th>
            <b-th class="text-table-heading" :colSpan="percentages.length">GDV</b-th>
          </b-tr>
          <b-tr class="percentRow">
            <b-th class="totalsCol" colSpan="2"></b-th>
            <b-th v-for="(item, index) in percentages" :key="index" scope="col"> {{ item }}% </b-th>
          </b-tr>
          <b-tr class="totalsRow">
            <b-th class="totalsCol" colSpan="2"></b-th>
            <b-th v-for="(item, index) in percentages" :key="index" scope="col">
              {{ calculateGDVPercentageChangeTotal(item) }}
            </b-th>
          </b-tr>
          <b-tr v-for="(yCost, yIndex) in percentages.slice().reverse()" :key="yIndex">
            <b-th scope="row">{{ yCost }}%</b-th>
            <b-th class="totalsCol" scope="row">
              {{ calculateBuildCostPercentageChangeTotal(yCost) }}
            </b-th>
            <b-td
              v-for="(xGDV, xIndex) in percentages"
              :key="xIndex"
              :style="{ backgroundColor: calculateCellBackground(calculateCell(xGDV, yCost)) }"
            >
              {{ calculateCellFormatted(xGDV, yCost) }}
            </b-td>
          </b-tr>
        </b-table-simple>
      </div>
    </div>
  </b-card>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { formatNumber, formatAmount } from "@shared/utils/formatting";
import { hsv2rgb } from "@shared/utils/hsv2rgb";
import { Observer } from "mobx-vue";
import { rootStore } from "@/react/lib/persistence/root_store";

@Observer
@Component({})
export default class SensitivityAnalysis extends Vue {
  public formatNumber = formatNumber;
  public formatAmount = formatAmount;
  public hsv2rgb = hsv2rgb;

  private percentages: number[] = [-20, -15, -10, -5, 0, 5, 10, 15, 20];
  private calculationType: string = "TotalProfit";
  private profitOptions = [
    { value: "ProfitOnGDV", text: "Profit on GDV (%)" },
    { value: "ProfitOnCost", text: "Profit on Cost (%)" },
    { value: "TotalProfit", text: "Total Profit" }
  ];

  public calculateBuildCostPercentageChangeTotal(percentage: number) {
    let valueToSubtract =
      (rootStore.buildPhaseStore.totalBuildCostWithContingency * percentage) / 100;
    return formatNumber({
      value: (rootStore.buildPhaseStore.totalBuildCostWithContingency + valueToSubtract) / 1000000,
      maxDecimals: 2,
      prefix: "£",
      suffix: "m"
    });
  }
  public calculateGDVPercentageChangeTotal(percentage: number) {
    let valueToSubtract = (rootStore.unitGroupStore.totalSales * percentage) / 100;
    return formatNumber({
      value: (rootStore.unitGroupStore.totalSales + valueToSubtract) / 1000000,
      maxDecimals: 2,
      prefix: "£",
      suffix: "m"
    });
  }
  public calculateCell(gdvPercentage: number, costPercentage: number) {
    let salesValueToSubtract = (rootStore.unitGroupStore.totalSales * gdvPercentage) / 100;
    let costValueToSubtract =
      (rootStore.buildPhaseStore.totalBuildCostWithContingency * costPercentage) / 100;
    let profitTotal =
      rootStore.unitGroupStore.totalSales +
      salesValueToSubtract -
      (rootStore.costStore.totalCostsWithContingency + costValueToSubtract);

    switch (this.calculationType) {
      case "ProfitOnGDV":
        return profitTotal / (rootStore.unitGroupStore.totalSales + salesValueToSubtract);

      case "ProfitOnCost":
        return (
          rootStore.costStore.totalCostsWithContingency + costValueToSubtract &&
          profitTotal / (rootStore.costStore.totalCostsWithContingency + costValueToSubtract)
        );

      case "TotalProfit":
        return profitTotal;

      default:
        return 0;
    }
  }
  public calculateCellFormatted(gdvPercentage: number, costPercentage: number) {
    let val = this.calculateCell(gdvPercentage, costPercentage);
    switch (this.calculationType) {
      case "ProfitOnGDV":
      case "ProfitOnCost":
        return formatNumber({
          value: val * 100,
          maxDecimals: 2,
          prefix: "",
          suffix: "%"
        });
      case "TotalProfit":
        return formatNumber({
          value: val / 1000000,
          maxDecimals: 2,
          prefix: "£",
          suffix: "m"
        });

      default:
        return formatAmount(0);
    }
  }
  public calculateCellBackground(val: number) {
    let valNormalised = this.normalise(val, this.maxCell, this.minCell) * 100;
    let h = Math.floor((valNormalised * 120) / 100);
    let s = Math.abs(valNormalised - 50) / 100;
    let v = 1;
    return hsv2rgb(h, s, v);
  }
  public normalise(val: number, max: number, min: number) {
    return (val - min) / (max - min);
  }

  get maxCell() {
    let max = Number.MIN_VALUE;

    this.percentages.forEach((px) => {
      this.percentages.forEach((py) => {
        let val = this.calculateCell(px, py);
        max = val > max ? val : max;
      });
    });
    return max;
  }
  get minCell() {
    let min = Number.MAX_VALUE;

    this.percentages.forEach((px) => {
      this.percentages.forEach((py) => {
        let val = this.calculateCell(px, py);
        min = val < min ? val : min;
      });
    });
    return min;
  }
}
</script>

<style lang="scss">
.sensitivity-analysis {
  .b-th .b-th-label {
    padding: 0px 12px;
  }
  .b-tr .b-td:last-child .b-td-container {
    padding: 0 !important;
    display: block !important;
    text-align: center !important;
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/scss/landtech-colors";
.sensitivity-analysis {
  width: 100% !important;
  max-width: 1440px;

  .table,
  .table-responsive {
    margin-bottom: 0;
  }

  th,
  td {
    text-align: center;
    white-space: nowrap;
    padding: 0.25rem;
    font-size: 0.75rem;
  }

  @media (min-width: 992px) {
    th,
    td {
      padding: 0.5rem;
    }
    .text-table-heading,
    th,
    td {
      font-size: 1rem;
    }
  }

  @media (min-width: 1200px) {
    th,
    td {
      padding: 0.75rem;
    }
  }

  .text-table-heading {
    font-size: 0.875rem;
    font-weight: bold;
    line-height: 1.5rem;
    text-align: center;
  }

  .text-vertical {
    text-orientation: mixed;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
  }

  tr td {
    border: unset;
  }

  .totalsRow {
    border-bottom: 2px solid grey;
  }

  .totalsCol {
    border-right: 2px solid grey;
  }

  .totalsRow th[colSpan="1"] {
    border: unset;
  }
}
</style>
