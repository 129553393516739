import { ClientAppraisal, ClientMiniAppraisal } from "@shared/types/appraisal";
import { getCachedAppraisal } from "../queries";
import { isEqual } from "lodash";
import { landFundApolloClient } from "../clients";
import { ClientDevelopment } from "@shared/types/development";
import { Nullable } from "@shared/types/utils";
import gql from "graphql-tag";
import {
  CREATE_APPRAISAL_MUTATION,
  CREATE_TEMPLATE_FROM_APPRAISAL_MUTATION,
  DELETE_APPRAISAL_MUTATION,
  DUPLICATE_APPRAISAL_MUTATION,
  getUpdateAppraisalMutation
} from "@shared/apollo/mutations";
import { sanitiseInput } from "../utils";
import { MINI_TEMPLATES } from "@shared/apollo/queries";
import { DEVELOPMENT_APPRAISALS_QUERY } from "../gql";

export const createAppraisal = async (
  appraisalId: ClientAppraisal["_id"],
  development: ClientDevelopment,
  templateId: Nullable<string>
) => {
  const { data: response } = await landFundApolloClient.mutate({
    mutation: gql`
      ${CREATE_APPRAISAL_MUTATION}
    `,
    variables: {
      appraisalId,
      templateId,
      developmentId: development._id,
      description: development.description
    },
    update: (store, { data: update }) => {
      const query = gql`
        ${DEVELOPMENT_APPRAISALS_QUERY}
      `;
      const variables = { id: development._id };
      const localCache: any = store.readQuery({ query, variables });
      localCache.developmentById.appraisals.push(update.createAppraisal);
      store.writeQuery({ query, variables, data: localCache });
    }
  });
  return response.createAppraisal;
};

export const deleteAppraisal = async (id: string, developmentId: string) => {
  const { data } = await landFundApolloClient.mutate({
    mutation: gql`
      ${DELETE_APPRAISAL_MUTATION}
    `,
    variables: { id },
    update: (store) => {
      const query = gql`
        ${DEVELOPMENT_APPRAISALS_QUERY}
      `;
      const variables = { id: developmentId };
      const localCache: any = store.readQuery({ query, variables });
      localCache.developmentById.appraisals = localCache.developmentById.appraisals.filter(
        (a: ClientAppraisal) => a._id !== id
      );
      store.writeQuery({ query, variables, data: localCache });
    }
  });

  return data.deleteAppraisal;
};

export const duplicateAppraisal = async (
  oldId: string,
  newId: string,
  title: string,
  developmentId: string
) => {
  const { data: response } = await landFundApolloClient.mutate({
    mutation: gql`
      ${DUPLICATE_APPRAISAL_MUTATION}
    `,
    variables: {
      oldId,
      newId,
      title,
      developmentId
    },
    update: (store, { data: update }) => {
      const query = gql`
        ${DEVELOPMENT_APPRAISALS_QUERY}
      `;
      const variables = { id: developmentId };
      const localCache: any = store.readQuery({ query, variables });
      localCache.developmentById.appraisals.push(update.duplicateAppraisal);
      store.writeQuery({ query, variables, data: localCache });
    }
  });
  return response?.duplicateAppraisal;
};

export const templateAppraisal = async (
  appraisalId: ClientAppraisal["_id"],
  newTemplateId: ClientAppraisal["_id"],
  newTemplateTitle: string
) => {
  const { data: response } = await landFundApolloClient.mutate({
    mutation: gql`
      ${CREATE_TEMPLATE_FROM_APPRAISAL_MUTATION}
    `,
    variables: {
      appraisalId,
      newTemplateId,
      newTemplateTitle
    },
    update: (store, { data: { createTemplateFromAppraisal } }) => {
      const query = gql`
        ${MINI_TEMPLATES}
      `;
      const data: any = store.readQuery({ query });
      data.templates.push(createTemplateFromAppraisal);
      store.writeQuery({ query, data });
    }
  });
  return response.createTemplateFromAppraisal;
};

export const updateMiniAppraisal = async (
  appraisalId: string,
  update: Partial<ClientMiniAppraisal>
) => {
  const appraisalUpdate = sanitiseInput(update);
  const returnKeys = Object.keys(update);
  const { data: response } = await landFundApolloClient.mutate({
    mutation: gql`
      ${getUpdateAppraisalMutation(returnKeys)}
    `,
    variables: {
      appraisalUpdate,
      appraisalId
    }
  });

  return response?.updateAppraisal;
};

export const updateAppraisal = async (appraisalId: string, update: Partial<ClientAppraisal>) => {
  const appraisalUpdate = sanitiseInput(update);
  const returnKeys = Object.keys(update);
  await landFundApolloClient.mutate({
    mutation: gql`
      ${getUpdateAppraisalMutation(returnKeys)}
    `,
    variables: {
      appraisalUpdate,
      appraisalId
    }
  });
};
