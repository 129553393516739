import { landFundApolloClient } from "@/react/lib/persistence/apollo";
import { logError } from "@/react/utils";
import { BuildQuality, IBuildCost } from "@shared/types/buildCost";
import gql from "graphql-tag";

export const getBuildCost = async (
  internalArea: number,
  postcode: string,
  quality: BuildQuality = BuildQuality.MEDIUM
): Promise<IBuildCost | Error> => {
  const { data, errors } = await landFundApolloClient.query({
    query: gql`
      query BuildCost($internalArea: Float!, $postcode: String!, $quality: BuildQuality) {
        buildCost(internalArea: $internalArea, postcode: $postcode, quality: $quality) {
          postcode
          total_cost
          cost_per_sqf
          quality
          internal_area
        }
      }
    `,
    variables: {
      internalArea,
      postcode,
      quality
    }
  });
  if (errors) {
    logError("BUILD COST ERROR");
    for (let err of errors) {
      logError(err.message);
    }
    return new Error(errors[0].message);
  }
  return data.buildCost;
};
