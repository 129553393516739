import { Icon, Tooltip } from "@/react/components";
import { Accordion } from "@/react/components/Accordion";
import { NumberOutput } from "@/react/components/Outputs/NumberOutput";
import { Section } from "@/react/components/Section/Section";
import { useTranslation } from "@/react/lib/hooks";
import { AppraisalEquityFundingTranslations } from "@/react/lib/i18n/locales";
import { AppraisalFinancialSummaryTranslations } from "@/react/lib/i18n/locales/en-GB";
import { rootStore } from "@/react/lib/persistence/root_store";
import { logEvent } from "@/react/utils";
import { UnitSetting } from "@shared/types/user";
import { areaUtil } from "@shared/utils/area";
import React, { FC, useState } from "react";

interface FinancialSummaryProps {
  unitGroupStore: typeof rootStore.unitGroupStore;
  costStore: typeof rootStore.costStore;
  buildPhaseStore: typeof rootStore.buildPhaseStore;
  loanStore: typeof rootStore.loanStore;
  equityFundingStore: typeof rootStore.equityFundingStore;
  unit: UnitSetting;
}

const FinancialSummaryPresentation: FC<FinancialSummaryProps> = ({
  unitGroupStore = rootStore.unitGroupStore,
  costStore = rootStore.costStore,
  buildPhaseStore = rootStore.buildPhaseStore,
  loanStore = rootStore.loanStore,
  equityFundingStore = rootStore.equityFundingStore,
  unit
}) => {
  const [isAccordionOpened, setIsAccordionOpened] = useState("1");
  const { t } = useTranslation();

  const developerProfit = () => {
    if (equityFundingStore.equityFundingLength === 0) {
      return null;
    }
    return (
      <NumberOutput
        label={t(AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_DeveloperProfit)}
        value={equityFundingStore.developerProfit}
        prefix="£"
      />
    );
  };

  return (
    <Section className="atlas-w-100 atlas-flex atlas-flex-row atlas-border-solid atlas-border atlas-border-neutral-400 atlas-mb-6">
      <Section.Header
        className={
          "atlas-border-t-0 atlas-border-l-0 atlas-border-r-0 atlas-border-solid atlas-border atlas-border-neutral-400"
        }
      >
        <h3 className="atlas-text-2xl atlas-my-0 atlas-font-extrabold">
          {t(AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_FinancialSummary)}
        </h3>
      </Section.Header>
      <Section.Body className="atlas-m-0 atlas-p-0 atlas-flex atlas-flex-col atlas-pt-1">
        <div
          className={
            "atlas-mt-3 atlas-border-t-0 atlas-border-l-0 atlas-border-r-0 atlas-border-solid atlas-border atlas-border-neutral-400"
          }
        >
          <NumberOutput
            className={"atlas-font-bold atlas-mb-3"}
            label={t(AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_GDV)}
            value={unitGroupStore.totalSales}
            prefix="£"
          />
        </div>
        <div
          className={
            "atlas-my-2 atlas-border-t-0 atlas-border-l-0 atlas-border-r-0 atlas-border-solid atlas-border atlas-border-neutral-400"
          }
        >
          <Accordion defaultValue={"1"} onChange={setIsAccordionOpened}>
            <Accordion.Item value={"1"}>
              <Accordion.Body>
                <div className={"atlas-bg-gradient-to-r atlas-from-cyan-500 atlas-to-blue-500"}>
                  <NumberOutput
                    label={t(
                      AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_LandAcquisitionCost
                    )}
                    value={costStore.totalLandCosts}
                    prefix="£"
                  />
                  <NumberOutput
                    label={t(
                      AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_BuildCost
                    )}
                    value={buildPhaseStore.totalBuildCostWithContingency}
                    prefix="£"
                  />
                  <NumberOutput
                    label={t(
                      AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_ProfessionalFees
                    )}
                    value={costStore.totalProfessionalFees}
                    prefix="£"
                  />
                  <NumberOutput
                    label={t(
                      AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_OtherCosts
                    )}
                    value={costStore.totalOtherCosts}
                    prefix="£"
                  />
                  {!!unitGroupStore.totalSalesFees && (
                    <NumberOutput
                      label={t(
                        AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_SalesFees
                      )}
                      value={unitGroupStore.totalSalesFees}
                      prefix="£"
                    />
                  )}
                  <NumberOutput
                    label={t(
                      AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_FundingCost
                    )}
                    value={loanStore.totalFundingCosts}
                    prefix="£"
                  />
                  {equityFundingStore.totalEquityInterest !== 0 && (
                    <NumberOutput
                      label={t(
                        AppraisalEquityFundingTranslations.AppraisalEquityFunding_EquityCost
                      )}
                      value={equityFundingStore.totalEquityInterest}
                      prefix="£"
                    />
                  )}
                </div>
                <div className={"accordion-dashed-line"}></div>
              </Accordion.Body>
              <Accordion.Header>
                <NumberOutput
                  className={"atlas-font-bold"}
                  label={t(
                    AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_TotalCost
                  )}
                  value={costStore.totalCostsWithContingency}
                  prefix="£"
                />
                <Tooltip
                  asChild={true}
                  content={t(
                    AppraisalFinancialSummaryTranslations[
                      isAccordionOpened
                        ? "AppraisalFinancialSummary_ClickToHideCosts"
                        : "AppraisalFinancialSummary_ClickToShowMoreCosts"
                    ]
                  )}
                  side="top"
                  className={"atlas-w-full"}
                >
                  <div className={"atlas-flex atlas-justify-center"}>
                    <span className="accordion-arrow-down">
                      <Icon.ArrowDown />
                    </span>
                    <span className="accordion-arrow-up">
                      <Icon.ArrowUp />
                    </span>
                  </div>
                </Tooltip>
              </Accordion.Header>
            </Accordion.Item>
          </Accordion>
        </div>
        <div
          className={
            "atlas-my-2 atlas-border-t-0 atlas-border-l-0 atlas-border-r-0 atlas-border-solid atlas-border atlas-border-neutral-400"
          }
        >
          <NumberOutput
            className={"atlas-font-bold"}
            label={t(AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_Profit)}
            value={costStore.profit}
            prefix="£"
          />
          <NumberOutput
            label={t(AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_ProfitOnGDV)}
            value={costStore.profitOnGDV}
            suffix="%"
          />
          <NumberOutput
            label={t(AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_ProfitOnCost)}
            value={costStore.profitOnCost}
            suffix="%"
          />
          {developerProfit()}
          {equityFundingStore.developerEquity > 0 && (
            <NumberOutput
              label={
                <Tooltip
                  content={t(
                    AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_ROETooltip
                  )}
                  onOpenChange={(open) => open && logEvent("RoE Tooltip Opened")}
                >
                  <>
                    {t(AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_ROE)}
                    <Icon.InformationLine className="atlas-w-5 atlas-h-5 atlas-pl-0.5 atlas-pb-1" />
                  </>
                </Tooltip>
              }
              value={costStore.returnOnEquity}
              suffix="%"
            />
          )}
        </div>
        <div>
          <NumberOutput
            className={"atlas-font-bold"}
            label={t(AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_TotalNIA)}
            value={unitGroupStore.convertedTotalNIA ?? 0}
            suffix={areaUtil.getSmallAreaString(unit)}
          />
          <NumberOutput
            className={"atlas-font-bold"}
            label={t(AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_TotalGIA)}
            value={unitGroupStore.convertedTotalGIA ?? 0}
            suffix={areaUtil.getSmallAreaString(unit)}
          />
          <NumberOutput
            className={"atlas-font-bold"}
            label={`${t(AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_Sales)}
                         / ${areaUtil.getSmallAreaString(unit)}`}
            value={unitGroupStore.salesPerAreaUnit}
            suffix={`£/${areaUtil.getSmallAreaString(unit)}`}
          />
          <NumberOutput
            className={"atlas-font-bold"}
            label={`${t(AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_BuildCost)}
                         / ${areaUtil.getSmallAreaString(unit)}`}
            secondaryLabel={t(
              AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_InclContingency
            )}
            value={buildPhaseStore.buildCostWithContingencyPerAreaUnit}
            suffix={`£/${areaUtil.getSmallAreaString(unit)}`}
          />
        </div>
      </Section.Body>
    </Section>
  );
};

export { FinancialSummaryPresentation };
