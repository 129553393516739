import { rootStore } from "../persistence/root_store";

export const useTranslation = () => {
  const t = (key: string, values?: Record<string, string | number>): string => {
    return rootStore.userStore.i18n.formatMessage({ id: key }, values);
  };

  const checkKey = (key: string) => {
    return !!rootStore.userStore.i18n.messages[key];
  };

  return { t, checkKey };
};
