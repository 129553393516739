import { BuildPhaseFragment, BuildPhaseInputFragment } from "@shared/apollo/fragments";

export const ADD_BUILD_PHASE_MUTATION = `
  mutation ($appraisalId: ObjectID!, $buildPhase: BuildPhaseInput!) {
    createBuildPhase(appraisalId: $appraisalId, buildPhase: $buildPhase) {
      ...BuildPhase
    }
  }
  ${BuildPhaseFragment}
`;

export const UPDATE_BUILD_PHASE_MUTATION = `
mutation ($appraisalId: ObjectID!, $buildPhaseUpdate: BuildPhaseInput!) {
  updateBuildPhase(
    appraisalId: $appraisalId
    buildPhaseUpdate: $buildPhaseUpdate
  ) {
    ...BuildPhaseInput
  }
}
${BuildPhaseInputFragment}
`;

export const DELETE_BUILD_PHASE_MUTATION = `
mutation($appraisalId: ObjectID!, $id: ObjectID!) {
  deleteBuildPhase(appraisalId: $appraisalId, id: $id)
}
`;
