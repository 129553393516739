import React, { createContext, PropsWithChildren, useState } from "react";

export const DropdownContext = createContext({
  isOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleOpen: () => {}
});

export const DropdownProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <DropdownContext.Provider value={{ isOpen, toggleOpen }}>{children}</DropdownContext.Provider>
  );
};
