import { ClientUnit } from "@shared/types/unit";
import { findEndIndex } from "@/react/utils/lineItem";
import { ClientLineItemExtended } from "@shared/types/cashflow";

export const calculateSalesValueForMonth = (
  unitIndexToStartFrom: number,
  units: ClientUnit[],
  rate: number
): number => {
  let value = 0;
  let startIndex = unitIndexToStartFrom;
  let endIndex = Math.min(startIndex + rate, units.length);
  for (let i = startIndex; i < endIndex; i++) {
    value += units[i].salesValue.value;
  }

  return value;
};

export const calculateWithLength = (
  length: number,
  units: ClientUnit[],
  lineItem: ClientLineItemExtended,
  numMonths: number
) => {
  let unitsCount = units.length;
  let lengthCeil = Math.ceil(length);
  let cells = new Array(Math.max(numMonths, lineItem.startIndex + lengthCeil)).fill(0);
  let unitsPerCell = [...cells];

  let unitsLeft = unitsCount;
  let monthsLeft = lengthCeil;
  let unitsUsed = 0;
  for (let i = lineItem.startIndex; i < lineItem.startIndex + lengthCeil; i++) {
    let unitsInThisCell = Math.ceil(unitsLeft / monthsLeft);
    cells[i] = calculateSalesValueForMonth(unitsUsed, units, unitsInThisCell);
    unitsPerCell[i] = unitsInThisCell;
    unitsLeft -= unitsInThisCell;
    unitsUsed += unitsInThisCell;
    monthsLeft--;
  }
  lineItem.cells = cells;
  lineItem.units = unitsPerCell;
  lineItem.endIndex = findEndIndex(lineItem.cells);
};

export const calculateWithRate = (
  length: number,
  units: ClientUnit[],
  lineItem: ClientLineItemExtended,
  numMonths: number
) => {
  let unitsCount = units.length;
  let lengthCeil = Math.ceil(length);
  let cells = new Array(Math.max(numMonths, lineItem.startIndex + lengthCeil)).fill(0);
  let rate = Math.ceil(lineItem.rate);
  let unitsPerCell = [...cells];

  if (lengthCeil > 1) {
    if (lengthCeil % unitsCount === 0) {
      let posCount = 0;
      for (let i = lineItem.startIndex; i < lineItem.startIndex + lengthCeil; i++) {
        cells[i] = calculateSalesValueForMonth(posCount, units, rate);
        unitsPerCell[i] = Math.min(posCount + rate, units.length) - posCount;
        posCount++;
      }
    } else {
      let unitsUsed = 0;
      let i = 0;
      for (i = lineItem.startIndex; unitsUsed < unitsCount; unitsUsed += rate) {
        cells[i] = calculateSalesValueForMonth(unitsUsed, units, rate);
        unitsPerCell[i] = Math.min(unitsUsed + rate, units.length) - unitsUsed;
        i++;
      }
    }
  } else {
    if (lengthCeil === 1) {
      cells[lineItem.startIndex] = lineItem.value;
      unitsPerCell[lineItem.startIndex] = unitsCount;
    }
  }
  lineItem.cells = cells;
  lineItem.units = unitsPerCell;
  lineItem.endIndex = findEndIndex(lineItem.cells);
};
