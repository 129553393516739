import { Component, Vue } from "vue-property-decorator";
@Component
class ResizeMixin extends Vue {
  private screenWidth = window.innerWidth;

  get sidebarVisible(): boolean {
    return this.screenWidth >= 1200;
  }

  public handleResize() {
    this.screenWidth = window.innerWidth;
    if (
      this.sidebarVisible &&
      document.getElementById("appraisal-sidebar") &&
      document.getElementById("appraisal-sidebar")!.style.display !== "flex"
    ) {
      document.getElementById("appraisal-sidebar")!.style.display = "flex";
    }
  }

  mounted() {
    window.addEventListener("resize", this.handleResize);
  }
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  }
}
export default ResizeMixin;
