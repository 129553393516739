import { makeAutoObservable } from "mobx";
import { getBuildCost } from "../../apollo/queries";
import { debounce, isEqual } from "lodash";
import { BuildQuality, IBuildCost } from "@shared/types/buildCost";
import { UserFeatures } from "@shared/utils/authorisation";
import { RootStore } from "../Root";
import { PROPERTY_DATA_MIN_GIA } from "@shared/utils/constants";

export class BuildCostStore {
  readonly root: RootStore;

  buildCost: IBuildCost | null = null;
  debounceGetBuildCost = debounce(() => this.updateBuildCost(), 200);
  errorMessage = "";
  hasFetchedBuildCosts: boolean = false;

  constructor(rootStore: RootStore) {
    this.root = rootStore;
    makeAutoObservable(this, { root: false });
  }

  get defaultBuildCost(): IBuildCost {
    return {
      postcode: "",
      total_cost: 0,
      cost_per_sqf: 0,
      quality: BuildQuality.MEDIUM,
      internal_area: "0"
    };
  }

  get totalCost() {
    return this.buildCost?.total_cost ?? this.defaultBuildCost.total_cost;
  }

  get costPerAreaUnit() {
    return this.totalCost / this.root.unitGroupStore.convertedTotalGIA;
  }

  setBuildCost(buildCost: IBuildCost) {
    if (!isEqual(this.buildCost, buildCost)) {
      this.buildCost = buildCost;
    }
  }
  updateHasFetchedBuildCosts(hasFetched: boolean) {
    if (this.hasFetchedBuildCosts !== hasFetched) {
      this.hasFetchedBuildCosts = hasFetched;
    }
  }

  async updateBuildCost() {
    if (
      this.root.userStore.hasFeature(UserFeatures.LANDFUND_BUILD_COST_DATA) &&
      this.root.currentDevelopmentStore.currentPostcode &&
      this.root.unitGroupStore.imperialTotalGIA > 0
    ) {
      const totalGIA = this.root.unitGroupStore.imperialTotalGIA;
      if (totalGIA >= PROPERTY_DATA_MIN_GIA) {
        const response = await getBuildCost(
          Number(totalGIA.toFixed(0)),
          this.root.currentDevelopmentStore.currentPostcode,
          this.root.appraisalStore.quality
        );
        if (response instanceof Error) {
          this.errorMessage = response.message;
        } else {
          this.updateHasFetchedBuildCosts(true);
          this.setBuildCost(response);
        }
      } else {
        this.setBuildCost(this.defaultBuildCost);
      }
    }
  }
}
