import React from "react";
import { TableSection } from "../CashflowTableSection";
import { rootStore } from "@/react/lib/persistence/root_store";
import { LineItemRow } from "../../LineItemRow";
import { observer } from "mobx-react-lite";
import { FinanceRow } from "../../FinanceRow";
import { useTranslation } from "@/react/lib/hooks";
import { CashflowTableTranslations } from "@/react/lib/i18n/locales";

export const IncomeSection = observer(() => {
  const cashflowStore = rootStore.cashflowStore;
  const { t } = useTranslation();
  return (
    <TableSection title="Income" testId="income-section">
      {cashflowStore.salesLineItems.map((val) => {
        return <LineItemRow key={val._id} lineItem={val} testId="income-line-item" />;
      })}
      <FinanceRow
        testId="income-gross-revenue"
        totalRow
        title={t(CashflowTableTranslations.CashflowTable_GrossRevenue)}
        valueCell={cashflowStore.salesGrossRevenueRowTotal}
        monthCells={cashflowStore.salesGrossRevenueColTotals}
      />
      <FinanceRow
        testId="income-cost-of-sales"
        totalRow
        title={t(CashflowTableTranslations.CashflowTable_CostOfSales)}
        valueCell={cashflowStore.salesCostRowTotal}
        monthCells={cashflowStore.salesCostColTotals}
      />
      <FinanceRow
        testId="income-net-revenue"
        totalRow
        title={t(CashflowTableTranslations.CashflowTable_NetRevenue)}
        valueCell={cashflowStore.salesNetRevenueRowTotal}
        monthCells={cashflowStore.salesNetRevenueColTotals}
        bottomBorder
      />
    </TableSection>
  );
});
