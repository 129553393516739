import { ClientCost } from "@shared/types/computable";
import { landFundApolloClient } from "../clients";
import { CREATE_COST_MUTATION, DELETE_COST_MUTATION, UPDATE_COSTS_MUTATION } from "../gql/costs";
import { sanitiseInput, updateAppraisalCache } from "../utils";
import { getCachedAppraisal } from "../queries";

export const createCost = async (appraisalId: string, cost: ClientCost) => {
  return await landFundApolloClient.mutate({
    mutation: CREATE_COST_MUTATION,
    variables: { appraisalId, cost },
    update: (store, { data }) => {
      const cachedAppraisal = getCachedAppraisal(appraisalId);
      cachedAppraisal.costs?.push(data.createCost);
      updateAppraisalCache(store, cachedAppraisal);
    }
  });
};

export const updateCosts = async (appraisalId: string, costsToUpdate: ClientCost[]) => {
  await landFundApolloClient.mutate({
    mutation: UPDATE_COSTS_MUTATION,
    variables: {
      appraisalId,
      costs: costsToUpdate.map((cost) => sanitiseInput(cost, false))
    }
  });
};

export const deleteCost = async (appraisalId: string, costToDelete: ClientCost) => {
  await landFundApolloClient.mutate({
    mutation: DELETE_COST_MUTATION,
    variables: { appraisalId, id: costToDelete._id },
    update: (store, { data }) => {
      const cachedAppraisal = getCachedAppraisal(appraisalId);
      cachedAppraisal.costs = cachedAppraisal.costs?.filter((cost) => cost._id !== data.deleteCost);
      updateAppraisalCache(store, cachedAppraisal);
    }
  });
};
