<template>
  <div class="flex-row w-100">
    <div v-if="buildPhase.isCustom" class="flex-row mr-3 w-100">
      <NumberInput
        label="Units"
        :field="{ value: buildPhase.numberOfUnits }"
        @update="
          ({ value }) => BuildPhaseStore.updateBuildPhase(buildPhase._id, { numberOfUnits: value })
        "
        class="mr-3"
        style="max-width: 180px"
        data-testid="build_phase_units_input"
      />
      <NumberInput
        :label="'NIA (' + formatSmallArea(UnitGroupStore.convertedRemainingNIA) + ' remaining)'"
        :suffix="UserStore.minorAreaUnitDisplay"
        :state="UnitGroupStore.hasOverAssignedNIA ? false : null"
        invalid-feedback="Total assigned NIA is greater than sold NIA"
        :field="{ value: buildPhase.assignedNIA }"
        @update="
          ({ value }) => BuildPhaseStore.updateBuildPhase(buildPhase._id, { assignedNIA: value })
        "
        data-testid="build_phase_NIA_input"
      />
    </div>

    <div v-else class="flex-row mr-3 w-100">
      <NumberInput
        label="Units"
        :field="{ value: buildPhase.numberOfUnits }"
        disabled
        class="mr-3"
        style="max-width: 180px"
        data-testid="build_phase_units_disabled_input"
      />
      <NumberInput
        label="NIA"
        :suffix="UserStore.minorAreaUnitDisplay"
        :state="UnitGroupStore.hasOverAssignedNIA ? false : null"
        invalid-feedback="Total assigned NIA is greater than sold NIA"
        :field="{ value: buildPhase.assignedNIA }"
        data-testid="build_phase_NIA_disabled_input"
      />
    </div>
    <NumberInput
      :disabled="!buildPhase.isCustom"
      label="GIA"
      :suffix="UserStore.minorAreaUnitDisplay"
      :calculation-options="CalculationsStore.perBuildPhaseNIACalculationOption(buildPhase)"
      :field="buildPhase.assignedGIA"
      @update="(assignedGIA) => BuildPhaseStore.updateBuildPhase(buildPhase._id, { assignedGIA })"
      data-testid="build_phase_GIA_input"
    />
  </div>
</template>

<script lang="ts">
import NumberInput from "@/components/NumberInput.vue";
import { rootStore } from "@/react/lib/persistence/root_store";
import { totalUnitGroupArea } from "@/utils/unit_groups";
import { ClientBuildPhase } from "@shared/types/buildPhase";
import { ClientUnitGroup } from "@shared/types/unitGroup";
import { formatNumber, pluralise } from "@shared/utils/formatting";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Observer } from "mobx-vue";

@Observer
@Component({ components: { NumberInput } })
export default class BuildPhaseData extends Vue {
  @Prop({ required: true }) readonly buildPhase!: ClientBuildPhase;

  private UserStore = rootStore.userStore;
  private CalculationsStore = rootStore.calculationsStore;
  private BuildPhaseStore = rootStore.buildPhaseStore;
  private UnitGroupStore = rootStore.unitGroupStore;

  public formatSmallArea(area: number) {
    return formatNumber({
      value: area,
      suffix: this.UserStore.minorAreaUnitDisplay
    });
  }

  public getUnitGroupDescription(unitGroup: ClientUnitGroup) {
    return `${unitGroup.description} (${pluralise(
      "unit",
      unitGroup.units?.length || 0
    )} - ${this.formatSmallArea(totalUnitGroupArea(unitGroup))})`;
  }
}
</script>
