import { DevelopmentTranslation } from "../enums";

export const DevelopmentTranslations: { [key in DevelopmentTranslation]: string } = {
  [DevelopmentTranslation.EnquireAboutFunding]: "Enquire About Funding",
  [DevelopmentTranslation.EnquireAboutFundingTooltip]:
    "We can offer funding from 8.95% fixed. Click to get a free consultation with one of our funding experts to discuss available options for senior debt, mezzanine and equity finance (up to 100%).",
  [DevelopmentTranslation.FundingEnquirySent]: "Funding enquiry sent",
  [DevelopmentTranslation.FundingEnquirySentTooltip]:
    "Thank you! A member of the LandFund team will reach out to you within 24h."
};
