import React, { useCallback, useState } from "react";

import { Sizes, Tooltip, TooltipThemes } from "@/react/components";
import { DebouncedInput } from "@/react/components/Forms/DebouncedInput/DebouncedInput";
import { rootStore } from "@/react/lib/persistence/root_store";
import { logEvent } from "@/react/utils";
import { calculateStartEndIndexes } from "@/react/utils/lineItem";
import { ClientLineItemExtended, CurveType, LineItemType } from "@shared/types/cashflow";
import { formatAmount, pluralise } from "@shared/utils/formatting";
import { observer } from "mobx-react-lite";

export interface InputCellProps {
  lineItem: ClientLineItemExtended;
  cellIndex: number;
}

export const InputCell = (props: InputCellProps) => <InputCellObserved {...props} />;

const InputCellObserved = observer(({ lineItem, cellIndex }: InputCellProps) => {
  const cashflowStore = rootStore.cashflowStore;
  const appraisalStore = rootStore.appraisalStore;

  const isOverAssigned = lineItem.valueRemaining < 0;
  const isUnderAssigned = lineItem.valueRemaining > 0;

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const tooltipTheme = () => {
    if (isOverAssigned) {
      return TooltipThemes.DANGER;
    }
    if (isUnderAssigned) {
      return TooltipThemes.WARNING;
    }
    return TooltipThemes.DEFAULT;
  };

  const tooltipText = (() => {
    if (lineItem.type !== LineItemType.UnitGroup || lineItem.curveType === CurveType.Custom) {
      const value = lineItem.valueRemaining;
      if (value >= 0) {
        return `${formatAmount(value)} left`;
      } else {
        return `${formatAmount(value * -1)} over`;
      }
    }
    if (lineItem.type === LineItemType.UnitGroup) {
      return pluralise("Unit", lineItem.units[cellIndex]);
    }
    return "";
  })();

  const isTooltipDisabled =
    lineItem.curveType === CurveType.Custom
      ? false
      : lineItem.units.length !== lineItem.cells.length || lineItem.units[cellIndex] === 0;

  const onChange = (value: string | number) => {
    lineItem.curveType = CurveType.Custom;
    let text = value.toString().replace(/[^\d.-]/g, "");
    let val = parseFloat(text);

    let cells = [...lineItem.cells];
    cells[cellIndex] = val || 0;
    lineItem.cells = cells;

    calculateStartEndIndexes(lineItem);

    cashflowStore.setSpreadOrCustomUpdate(lineItem);
    logEvent("Cashflow Line Item Updated", {
      appraisal_id: appraisalStore.appraisalId,
      development_id: appraisalStore.developmentId,
      line_item_type: lineItem.type,
      line_item_curve: lineItem.curveType,
      completed: cashflowStore.isComplete,
      is_auto: false,
      sales_rate_spread: !!lineItem.rate
    });
  };

  const onInputFocus = useCallback(() => {
    setIsTooltipOpen(true);
  }, []);

  const onInputBlur = useCallback(() => {
    setIsTooltipOpen(false);
  }, []);

  return (
    <div className="atlas-flex atlas-flex-col atlas-px-2 atlas-py-1">
      <Tooltip
        open={isTooltipOpen}
        content={tooltipText}
        theme={tooltipTheme()}
        disabled={isTooltipDisabled}
      >
        <span className="atlas-h-0 atlas-hidden" />
      </Tooltip>
      <DebouncedInput
        inputClassName="atlas-text-right"
        className="atlas-mb-0"
        id={`input-${lineItem._id}-${cellIndex}`}
        testId="input-cell"
        size={Sizes.MD}
        value={lineItem.cells[cellIndex]}
        prefix={lineItem.cells[cellIndex] >= 0.5 ? "£" : ""}
        onChange={onChange}
        onFocus={onInputFocus}
        onBlur={onInputBlur}
        rounded
      />
    </div>
  );
});
