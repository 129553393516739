"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UploadConfig = exports.AllowedFileExtensions = exports.AllowedMimeTypes = exports.AccountErrorText = void 0;
var AccountErrorText;
(function (AccountErrorText) {
    AccountErrorText["FileFailedToUpload"] = "Your file failed to upload. Try again.";
    AccountErrorText["FileFailedToDelete"] = "There was a problem deleting your file.";
    AccountErrorText["FailedUpdatingSetting"] = "There was a problem updating settings.";
})(AccountErrorText = exports.AccountErrorText || (exports.AccountErrorText = {}));
var AllowedMimeTypes;
(function (AllowedMimeTypes) {
    AllowedMimeTypes["JPEG"] = "image/jpeg";
    AllowedMimeTypes["PNG"] = "image/png";
})(AllowedMimeTypes = exports.AllowedMimeTypes || (exports.AllowedMimeTypes = {}));
var AllowedFileExtensions;
(function (AllowedFileExtensions) {
    AllowedFileExtensions["JPEG"] = "jpeg";
    AllowedFileExtensions["JPG"] = "jpg";
    AllowedFileExtensions["PNG"] = "png";
})(AllowedFileExtensions = exports.AllowedFileExtensions || (exports.AllowedFileExtensions = {}));
var UploadConfig;
(function (UploadConfig) {
    UploadConfig[UploadConfig["UploadFileSize"] = 153600] = "UploadFileSize";
})(UploadConfig = exports.UploadConfig || (exports.UploadConfig = {}));
