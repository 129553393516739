import React, { FC } from "react";
import { observer } from "mobx-react-lite";
import { ResidualLandValuePresentation } from "@/react/domains/appraisal/ResidualLandValue";
import { rootStore } from "@/react/lib/persistence/root_store";
import { logEvent } from "@/react/utils";

const ResidualLandValue: FC = () => <ObservedResidualLandValue />;

const ObservedResidualLandValue: FC = observer(() => {
  return (
    <ResidualLandValuePresentation
      residualLandTarget={rootStore.residualLandValueStore.residualLandTarget}
      setResidualLandTarget={(val) => {
        rootStore.residualLandValueStore.setResidualLandTarget(val);
        logEvent("Residual Land Value Target Updated", {
          metric: rootStore.residualLandValueStore.residualLandCalculation ?? undefined,
          target: val
        });
      }}
      hasResidualError={rootStore.residualLandValueStore.hasResidualError}
      errorOutput={rootStore.residualLandValueStore.errorOutput}
      residualPurchasePrice={rootStore.residualLandValueStore.residualPurchasePrice}
      setResidualLandCalculation={(val) => {
        rootStore.residualLandValueStore.setResidualLandCalculation(val);
        logEvent("Residual Land Value Calculation Updated", {
          metric: val,
          target: rootStore.residualLandValueStore.residualLandTarget
        });
      }}
      targetPrefix={rootStore.residualLandValueStore.targetPrefix}
      targetSuffix={rootStore.residualLandValueStore.targetSuffix}
      residualLandCalculation={rootStore.residualLandValueStore.residualLandCalculation}
    />
  );
});

export { ResidualLandValue };
