"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoanAction = exports.InterestChargingType = exports.InterestCalculationType = exports.LoanInterestType = void 0;
var LoanInterestType;
(function (LoanInterestType) {
    LoanInterestType["Annually"] = "Per year";
    LoanInterestType["Monthly"] = "Per month";
})(LoanInterestType = exports.LoanInterestType || (exports.LoanInterestType = {}));
var InterestCalculationType;
(function (InterestCalculationType) {
    InterestCalculationType["DrawnBalance"] = "DrawnBalance";
    InterestCalculationType["DrawnBalanceCashflow"] = "DrawnBalanceCashflow";
    InterestCalculationType["FullLoan"] = "FullLoan";
})(InterestCalculationType = exports.InterestCalculationType || (exports.InterestCalculationType = {}));
var InterestChargingType;
(function (InterestChargingType) {
    InterestChargingType["RolledUp"] = "RolledUp";
    InterestChargingType["Retained"] = "Retained";
    InterestChargingType["Serviced"] = "Serviced";
})(InterestChargingType = exports.InterestChargingType || (exports.InterestChargingType = {}));
var LoanAction;
(function (LoanAction) {
    LoanAction["Created"] = "Loan Created";
    LoanAction["Updated"] = "Loan Updated";
    LoanAction["Deleted"] = "Loan Deleted";
    LoanAction["Reordered"] = "Loans Reordered";
})(LoanAction = exports.LoanAction || (exports.LoanAction = {}));
