import { ClientLineItemExtended } from "@shared/types/cashflow";
import React from "react";
import { CurveCell } from "./CurveCell";
import { TimeFrameButton } from "./TimeFrameButton";
import { InputCell } from "./InputCell";
import { DescriptionCell } from "./DescriptionCell";
import { CashflowRow, CashflowCell, CashflowCellType, CellValue } from "../CashflowRow";

interface LineItemRowProps {
  lineItem: ClientLineItemExtended;
  testId?: string;
}

export const LineItemRow = ({ lineItem, testId }: LineItemRowProps) => {
  let month = 0;
  return (
    <CashflowRow testId={testId}>
      <CashflowCell cellType={CashflowCellType.TITLE}>
        <DescriptionCell lineItem={lineItem} />
      </CashflowCell>

      <CashflowCell cellType={CashflowCellType.VALUE}>
        <CellValue value={lineItem.value} />
      </CashflowCell>

      <CashflowCell cellType={CashflowCellType.TIME_FRAME}>
        <TimeFrameButton lineItem={lineItem} />
      </CashflowCell>

      <CashflowCell cellType={CashflowCellType.CURVE}>
        <CurveCell lineItem={lineItem} />
      </CashflowCell>

      {...lineItem.cells.map((_, cellIndex) => (
        <CashflowCell
          cellType={CashflowCellType.MONTH}
          monthNumber={++month}
          key={`month-${month}`}
        >
          <InputCell lineItem={lineItem} cellIndex={cellIndex} />
        </CashflowCell>
      ))}
    </CashflowRow>
  );
};
