import React, { ReactNode, Ref } from "react";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";

export interface DropdownMenuTriggerProps {
  children: ReactNode;
  asChild?: boolean;
  className?: string;
  triggerRef?: Ref<HTMLButtonElement>;
}

export function DropdownMenuTrigger({
  children,
  asChild = false,
  className,
  triggerRef,
  ...rest
}: Readonly<DropdownMenuTriggerProps>) {
  return (
    <DropdownMenuPrimitive.Trigger
      {...rest}
      ref={triggerRef}
      className={className}
      asChild={asChild}
    >
      {children}
    </DropdownMenuPrimitive.Trigger>
  );
}

export default DropdownMenuTrigger;
