import {
  Button,
  ButtonThemes,
  InfoTooltip,
  Input,
  InputSuffix,
  Modal,
  NumberInput,
  Select
} from "@/react/components";
import { ValueObject } from "@/react/components/Forms/NumberInput/types";
import { NumberOutput } from "@/react/components/Outputs";
import { useTranslation } from "@/react/lib/hooks";
import { AppraisalFundingTranslations, AppraisalSingularWords } from "@/react/lib/i18n/locales";
import { rootStore } from "@/react/lib/persistence/root_store";
import { logEvent } from "@/react/utils";
import { mapEnum } from "@/react/utils/enums";
import {
  ClientLoan,
  InterestCalculationType,
  InterestChargingType,
  LoanAction,
  LoanInterestType
} from "@shared/types/loan";
import { interestCalculationTypeDisplay } from "@shared/utils/loan";
import { sentenceCase } from "change-case";
import { observer } from "mobx-react-lite";
import React, { FC } from "react";

const LoanModal: FC = () => {
  return <LoanModalObserved />;
};

const LoanModalObserved: FC = observer(() => {
  const { t } = useTranslation();
  function handleClose() {
    rootStore.currentLoanStore.cancelEditingLoan();
  }
  function handleConfirm() {
    const action = rootStore.currentLoanStore.isEditing ? LoanAction.Updated : LoanAction.Created;
    logEvent(action, {
      interest_calculation: rootStore.currentLoanStore.currentLoan.interestCalculation,
      interest_charging: rootStore.currentLoanStore.currentLoan.interestCharging,
      is_template: rootStore.appraisalStore.isTemplate
    });
    rootStore.currentLoanStore.finishEditingLoan();
  }

  return (
    <>
      {!!rootStore.utilityStore.loanModalOpen && (
        <Modal
          data-testid="LoanModal"
          defaultOpen={true}
          title={
            rootStore.currentLoanStore.isEditing
              ? t(AppraisalFundingTranslations.AppraisalFunding_Loan_EditLoan)
              : t(AppraisalFundingTranslations.AppraisalFunding_Loan_AddNewLoan)
          }
          onClose={handleClose}
          className="md:atlas-max-w-xl"
          footerClassNames="atlas-justify-end atlas-text-right"
          footerContent={
            <>
              <Button theme={ButtonThemes.Secondary} className="atlas-mr-2" onClick={handleClose}>
                <span data-testid="cancelLoan">{t(AppraisalSingularWords.Appraisal_Cancel)}</span>
              </Button>
              <Button theme={ButtonThemes.Primary} onClick={handleConfirm}>
                <span data-testid="saveLoan">
                  {rootStore.currentLoanStore.isEditing
                    ? t(AppraisalSingularWords.Appraisal_Save)
                    : t(AppraisalSingularWords.Appraisal_Add)}
                </span>
              </Button>
            </>
          }
        >
          <div className="atlas-w-full atlas-p-4" data-testid="loanModal">
            {rootStore.currentLoanStore.validator.state["name"] === false}
            <Input
              id="loanName"
              data-testid="loanName"
              label={t(AppraisalFundingTranslations.AppraisalFunding_LoanName)}
              className="atlas-w-full atlas-mb-4"
              error={{
                display: rootStore.currentLoanStore.validator.state["name"] === false,
                message: t(AppraisalFundingTranslations.AppraisalFunding_Loan_NotBlank_ErrorMessage)
              }}
              value={rootStore.currentLoanStore.currentLoan.name}
              onChange={(value) =>
                rootStore.currentLoanStore.updateCurrentLoan({
                  name: String(value)
                })
              }
            />
            <label htmlFor={"NetLoan"}>
              {t(AppraisalFundingTranslations.AppraisalFunding_Loan_NetLoan)}
            </label>
            <NumberInput
              id="NetLoan"
              testId="netLoan"
              className="atlas-w-full atlas-mb-4"
              error={{
                display: rootStore.currentLoanStore.validator.state["amount"] === false,
                message: t(AppraisalFundingTranslations.AppraisalFunding_Loan_NetLoan_ErrorMessage)
              }}
              value={rootStore.currentLoanStore.currentLoan.amount}
              onChange={(value) =>
                rootStore.currentLoanStore.updateCurrentLoan({
                  amount: value as ClientLoan["amount"]
                })
              }
              prefix="£"
              calculations={rootStore.calculationsStore.loanAmountCalculationOptions}
            />
            <div className="atlas-flex atlas-gap-3">
              <div className="atlas-w-1/2">
                <NumberInput
                  id="loanTerm"
                  testId="loanTerm"
                  label={
                    <>
                      {t(AppraisalFundingTranslations.AppraisalFunding_Term)}
                      {rootStore.currentLoanStore.hasCashflowInterest && (
                        <InfoTooltip
                          content={t(AppraisalFundingTranslations.AppraisalFunding_Term_Tooltip)}
                        />
                      )}
                    </>
                  }
                  className="atlas-w-full atlas-mb-4"
                  error={{
                    display: rootStore.currentLoanStore.validator.state["term"] === false,
                    message: t(AppraisalFundingTranslations.AppraisalFunding_Term_ErrorMessage)
                  }}
                  value={{ value: rootStore.currentLoanStore.term } as ValueObject}
                  disabled={rootStore.currentLoanStore.hasCashflowInterest}
                  onChange={(value) =>
                    rootStore.currentLoanStore.updateCurrentLoan({ term: value.value })
                  }
                >
                  <InputSuffix>{t(AppraisalSingularWords.Appraisal_Months)}</InputSuffix>
                </NumberInput>
              </div>
              <div className="atlas-w-1/2">
                <NumberInput
                  testId="loanInterestRate"
                  id="loanInterestRate"
                  label={t(AppraisalFundingTranslations.AppraisalFunding_Loan_InterestRate)}
                  error={{
                    display: rootStore.currentLoanStore.validator.state["rate"] === false,
                    message: t(
                      AppraisalFundingTranslations.AppraisalFunding_Loan_InterestRate_ErrorMessage
                    )
                  }}
                  inputClassName="atlas-rounded-r-none atlas-w-full"
                  className="atlas-w-full atlas-rounded-r-none"
                  value={{ value: rootStore.currentLoanStore.currentLoan.rate } as ValueObject}
                  onChange={(value) =>
                    rootStore.currentLoanStore.updateCurrentLoan({ rate: value.value })
                  }
                  suffix="%"
                  showFalsyDefaultValue
                >
                  <Select
                    width="atlas-w-1/2"
                    id="loanRateIsMonthly"
                    label={t(AppraisalFundingTranslations.AppraisalFunding_Loan_InterestRateType)}
                    labelVisible={false}
                    selectClassName="atlas-bg-neutral-100"
                    className="atlas-mb-0 atlas-border-0 atlas-border-l-0 atlas-border-neutral-300 atlas-rounded-l-none focus-within:atlas-ring-offset-3 atlas-h-full"
                    value={String(rootStore.currentLoanStore.currentLoan.rateIsMonthly)}
                    onChange={(value) =>
                      rootStore.currentLoanStore.updateCurrentLoan({
                        rateIsMonthly: value === "true"
                      })
                    }
                    options={[
                      { option: "false", label: LoanInterestType.Annually },
                      { option: "true", label: LoanInterestType.Monthly }
                    ]}
                  />
                </NumberInput>
              </div>
            </div>
            <div className="atlas-w-full atlas-flex atlas-gap-3">
              <Select
                id="loanInterestCalculation"
                label={t(AppraisalFundingTranslations.AppraisalFunding_Loan_InterestCalculation)}
                tooltip={t(
                  AppraisalFundingTranslations.AppraisalFunding_Loan_InterestCalculation_Tooltip
                )}
                tooltipDescription={t(
                  AppraisalFundingTranslations.AppraisalFunding_Loan_InterestCalculation_TooltipDescription
                )}
                leftAlignTooltip={true}
                selectClassName="atlas-bg-white atlas-rounded"
                className="atlas-mb-4"
                value={String(rootStore.currentLoanStore.currentLoan.interestCalculation)}
                onChange={(value) => {
                  if (value) {
                    rootStore.currentLoanStore.updateCurrentLoan({
                      interestCalculation: value as InterestCalculationType
                    });
                  }
                }}
                options={mapEnum(InterestCalculationType, (calcKey) => ({
                  option: calcKey,
                  label: interestCalculationTypeDisplay(calcKey as InterestCalculationType)
                }))}
              />

              <Select
                id="loanInterestChargingType"
                label={t(AppraisalFundingTranslations.AppraisalFunding_Loan_InterestCharging)}
                selectClassName="atlas-bg-white atlas-rounded"
                tooltip={t(
                  AppraisalFundingTranslations.AppraisalFunding_Loan_InterestCharging_Tooltip
                )}
                tooltipDescription={t(
                  AppraisalFundingTranslations.AppraisalFunding_Loan_InterestCharging_TooltipDescription
                )}
                leftAlignTooltip={true}
                className="atlas-mb-4"
                value={String(rootStore.currentLoanStore.currentLoan.interestCharging)}
                onChange={(value) => {
                  if (value) {
                    rootStore.currentLoanStore.updateCurrentLoan({
                      interestCharging: value as InterestChargingType
                    });
                  }
                }}
                options={mapEnum(InterestChargingType, (calcKey) => ({
                  option: calcKey,
                  label: sentenceCase(calcKey)
                }))}
              />
            </div>
            <label htmlFor="loanArrangementFee">
              {t(AppraisalFundingTranslations.AppraisalFunding_Loan_ArrangementFee)}
            </label>
            <NumberInput
              id="loanArrangementFee"
              testId="loanArrangementFee"
              className="atlas-w-full atlas-mb-4"
              error={{
                display: rootStore.currentLoanStore.validator.state["arrangementFee"] === false,
                message: t(
                  AppraisalFundingTranslations.AppraisalFunding_Loan_ArrangementFee_ErrorMessage
                )
              }}
              value={rootStore.currentLoanStore.currentLoan.arrangementFee}
              onChange={(value) =>
                rootStore.currentLoanStore.updateCurrentLoan({
                  arrangementFee: value as ClientLoan["arrangementFee"]
                })
              }
              prefix="£"
              calculations={rootStore.currentLoanStore.feeCalculationOptions}
            />
            <label htmlFor="loanExitFee">
              {t(AppraisalFundingTranslations.AppraisalFunding_Loan_ExitFee)}
            </label>
            <NumberInput
              id="loanExitFee"
              testId="loanExitFee"
              className="atlas-w-full atlas-mb-4"
              error={{
                display: rootStore.currentLoanStore.validator.state["exitFee"] === false,
                message: t(AppraisalFundingTranslations.AppraisalFunding_Loan_ExitFee_ErrorMessage)
              }}
              value={rootStore.currentLoanStore.currentLoan.exitFee}
              onChange={(value) =>
                rootStore.currentLoanStore.updateCurrentLoan({
                  exitFee: value as ClientLoan["exitFee"]
                })
              }
              prefix="£"
              calculations={rootStore.currentLoanStore.feeCalculationOptions}
            />
          </div>
          <div className="atlas-mx-4 atlas-gap-6 atlas-flex atlas-border-0 atlas-border-t atlas-border-neutral-300 atlas-border-solid atlas-border-y-0  atlas-py-5">
            <div className="atlas-w-full ">
              <NumberOutput
                value={rootStore.currentLoanStore.interest}
                label={t(AppraisalSingularWords.Appraisal_Interest)}
                prefix="£"
              />
              <NumberOutput
                value={rootStore.currentLoanStore.loanFees}
                label={t(AppraisalSingularWords.Appraisal_Fees)}
                prefix="£"
              />
              <NumberOutput
                value={rootStore.currentLoanStore.loanCost}
                label={t(AppraisalFundingTranslations.AppraisalFunding_Loan_TotalCost)}
                prefix="£"
              />
            </div>
            <div className="atlas-w-full">
              <NumberOutput
                value={rootStore.currentLoanStore.currentLoanGrossAmount}
                label={t(AppraisalFundingTranslations.AppraisalFunding_Loan_GrossLoan)}
                prefix="£"
              />
              <NumberOutput
                value={rootStore.currentLoanStore.percentageOfFundableCosts}
                className="atlas-pl-2"
                label={t(AppraisalFundingTranslations.AppraisalFunding_Loan_CostPercent)}
                suffix="%"
              />
              <NumberOutput
                value={rootStore.currentLoanStore.percentageOfGdv}
                className="atlas-pl-2"
                label={t(AppraisalFundingTranslations.AppraisalFunding_Loan_GDVPercent)}
                suffix="%"
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
});

export { LoanModal };
