<template>
  <b-navbar
    type="light"
    variant="white"
    sticky
    fixed
    toggleable="sm"
    :class="isMobile ? `pb-2` : ``"
  >
    <b-navbar-brand to="/">
      <b-img class="logo" :src="logo" alt="LandFund Logo" v-if="!isMobile" />
      <b-img class="mobileLogo atlas-w-44" :src="mobileLogo" alt="LandFund Logo" v-if="isMobile" />
    </b-navbar-brand>
    <b-navbar-toggle target="nav-collapse" class="border-0 text-body mt-2" />
    <b-collapse id="nav-collapse" is-nav class="border-top mt-2 mt-sm-0 border-sm-0">
      <b-navbar-nav>
        <b-nav-item to="/developments">Developments</b-nav-item>
        <b-nav-item to="/templates">Templates</b-nav-item>
        <b-nav-item to="/funding" class="position-relative" data-intercom-target="lender_match_tab">
          Funding
        </b-nav-item>
      </b-navbar-nav>
      <!--Right Side-->
      <b-navbar-nav
        :class="[`ml-auto align-items-center mt-3 mt-sm-0 `, [isMobile ? `w-100` : ``]]"
      >
        <b-dropdown
          :block="isMobile"
          toggle-class="text-left pl-0 pl-sm-2"
          :class="isMobile ? `w-100 mb-2` : `ml-2`"
          variant="clear"
          text=""
          size="sm"
          v-b-tooltip.hover
          title="Help"
          menu-class="w-150"
          no-caret
          right
          data-intercom-target="help_menu"
        >
          <template #button-content>
            <b-icon
              icon="question-circle"
              style="width: 28px; height: 28px"
              @click="logEvent('Help Menu Clicked', { page: $route.path.split('/')[1] })"
              class="d-none d-sm-block"
            />
            <span v-show="isMobile">Help and Support</span>
          </template>
          <b-dropdown-item @click="openHelp">
            <b-icon icon="info-circle" class="mr-2" />Access our Help Centre
          </b-dropdown-item>
          <b-dropdown-item @click="showIntercom">
            <b-icon icon="chat-dots" class="mr-2" />Start a support chat
          </b-dropdown-item>
          <b-dropdown-item @click="openHelpVideos">
            <b-icon icon="collection-play" class="mr-2" />Access our video tutorials
          </b-dropdown-item>
        </b-dropdown>

        <AccountMenu :is-mobile="isMobile" />
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import EventLoggerMixin from "@/mixins/event_logger_mixin";
import AccountMenu from "./AccountMenu.vue";
import { Observer } from "mobx-vue";

@Observer
@Component({
  components: {
    AccountMenu
  }
})
export default class NavBar extends Mixins(EventLoggerMixin) {
  private windowWidth = 0;
  private logo = require("@/assets/img/landfund-logo-48x48@3x.svg");
  private mobileLogo = require("@/assets/img/landfund-logo-wide-blue.png");

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  }

  get isMobile() {
    return this.windowWidth < 768;
  }

  public onResize() {
    this.windowWidth = window.innerWidth;
  }

  public showIntercom() {
    window.Intercom("show");
    this.logEvent("Help Menu Chat Item Clicked", { page: this.$route.path.split("/")[1] });
  }

  public openHelp() {
    const url = "https://support.land.tech/en/collections/3106909-landfund-faqs";
    window.open(url, "_blank");
    this.logEvent("Help Menu Help Centre Item Clicked", { page: this.$route.path.split("/")[1] });
  }

  public openHelpVideos() {
    const url = "https://tutorials.land.tech/uk/landfund/";
    window.open(url, "_blank");
    this.logEvent("Help Menu Videos Item Clicked", { page: this.$route.path.split("/")[1] });
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/landtech-colors";

.navbar {
  box-shadow: 0px 2px 2px $landfund-box-shadow-colour;
  padding-bottom: 0;
  padding-top: 0;

  .navbar-nav {
    .nav-item {
      height: 3rem;

      .nav-link {
        padding-top: 0.75rem;
        color: $blue-900;
        border-bottom: 0.25rem solid $neutral-white;

        &:hover {
          border-color: $neutral-300;
        }
      }

      &.nav-link.active {
        color: $blue-800;
        font-weight: bold;
        border-color: $blue-accent-dark;
      }
    }

    .btn {
      height: 36px;
    }
  }
}

.dropdown-divider {
  margin: 0;
}

.logo {
  width: 2rem;
}
</style>

<style lang="scss">
.normal-font-weight .dropdown-item {
  font-weight: 300 !important;
}

.navbar-light {
  .navbar-toggler {
    transform: translateX(0.75rem);

    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    }
  }
}
</style>
