import React, { FC } from "react";
import {
  AppraisalAnnotationType,
  ClientAppraisalAnnotation
} from "@shared/types/appraisalAnnotation";
import { observer } from "mobx-react-lite";
import { useTranslation } from "@/react/lib/hooks";
import { AppraisalAnnotations } from "@/react/lib/i18n/locales";
import { AnnotationPresentation } from "./components/AnnotationPresentation";
import { rootStore } from "@/react/lib/persistence/root_store";
import { logEvent } from "@/react/utils";

interface AnnotationsProps {
  type: AppraisalAnnotationType;
}

const AnnotationObservable: FC<AnnotationsProps> = observer(({ type, ...props }) => {
  const { t } = useTranslation();
  const updateAppraisal = (val: string | undefined) => {
    rootStore.appraisalAnnotationStore.setAnnotation(type, {
      text: val
    } as ClientAppraisalAnnotation);
    logEvent("Appraisal Annotation Updated", { section: type });
  };

  return (
    <AnnotationPresentation
      initialValue={rootStore.appraisalAnnotationStore.annotations[type].text}
      updateFunction={updateAppraisal}
      type={type}
      {...props}
      label={t(AppraisalAnnotations.AppraisalAnnotations_AnnotationsPlaceholder)}
    />
  );
});

const Annotations: FC<AnnotationsProps> = (props) => <AnnotationObservable {...props} />;

export { Annotations };
