import { useState, useEffect, useMemo } from "react";

interface Size {
  width: number;
  height: number;
}

type WindowResizeHookType = [Size, boolean];

function useWindowResize(resizeCallback?: () => void): WindowResizeHookType;
function useWindowResize(minSize: number, resizeCallback?: () => void): WindowResizeHookType;
function useWindowResize(
  arg?: number | (() => void),
  resizeCallback?: () => void | undefined
): WindowResizeHookType {
  const min = typeof arg === "number" ? arg : 1200;
  const callback = typeof arg === "function" ? arg : resizeCallback;

  const [windowSizes, setWindowSizes] = useState<Size>({ width: 0, height: 0 });
  const aboveMinBreakpoint = useMemo(() => windowSizes.width >= min, [windowSizes.width]);

  const handleResize = () => {
    if (callback) {
      callback();
    }
    const { innerWidth, innerHeight } = window;
    setWindowSizes({
      width: innerWidth,
      height: innerHeight
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return [windowSizes, aboveMinBreakpoint];
}

export { useWindowResize };
