import {
  createLineItem,
  deleteLineItem,
  getCachedAppraisal,
  updateLineItem
} from "@/react/lib/persistence/apollo";
import { initLineItem } from "@/react/utils/lineItem";
import { ClientBuildPhase } from "@shared/types/buildPhase";
import {
  ClientCashflow,
  ClientLineItem,
  ClientLineItemExtended,
  CurveType,
  LineItemDescription,
  LineItemType,
  OverallBuildStage
} from "@shared/types/cashflow";
import { ClientCost } from "@shared/types/computable";
import { CostType } from "@shared/types/costs";
import { ClientUnitGroup } from "@shared/types/unitGroup";
import { Nullable } from "@shared/types/utils";
import { totalUnitGroupSalesFees } from "@shared/utils/unit_groups";
import ObjectID from "bson-objectid";
import { cloneDeep, debounce, findIndex, findLastIndex } from "lodash";
import { makeAutoObservable } from "mobx";
import {
  BUILD_PHASE_LINE_ITEM_DEFAULT_END,
  BUILD_PHASE_LINE_ITEM_DEFAULT_START,
  calculateCurve,
  columnTotal,
  contingencyForColumn,
  costTypeToLineType,
  exportCashflowToExcel,
  getDefaultCurveType,
  getDefaultIndexes,
  getExtendedBuildPhaseLineItems,
  getExtendedContingencyLineItems,
  getExtendedLandLineItems,
  getExtendedOtherCostLineItems,
  getExtendedProfFeesLineItems,
  getExtendedUnitGroupLineItems,
  isAutomatedCurveType,
  lineItemMonthsNotSet,
  salesCostColTotal,
  spreadUnitGroupLineItem
} from "./CashflowStoreUtil";
import { RootStore } from "../Root";

export class CashflowStore {
  readonly root: RootStore;
  lineItems: ClientLineItem[] = [];
  lineItemsToSpread: ClientLineItemExtended[] = [];

  debounceAutomatingLineItems = debounce(() => this.automateLineItems(), 200);

  constructor(rootStore: RootStore) {
    this.root = rootStore;
    makeAutoObservable(this, { root: false });
  }

  setupCashflow(cashflow?: ClientCashflow) {
    this.lineItems = cashflow?.lineItems ?? [];
  }

  async createLineItemFromCost(cost: ClientCost) {
    if (cost.type === CostType.Construction) {
      throw new Error("Cannot create lineItem for BuildPhase cost");
    }
    await this.addLineItem({
      ...initLineItem,
      _linkedId: cost._id,
      type: costTypeToLineType(cost.type)
    });
  }

  async createLineItemFromBuildPhase(buildPhase: ClientBuildPhase) {
    await this.addLineItem({
      ...initLineItem,
      _linkedId: buildPhase._id,
      type: LineItemType.BuildPhase
    });
  }

  async createLineItemFromUnitGroup(unitGroup: ClientUnitGroup) {
    await this.addLineItem({
      ...initLineItem,
      _linkedId: unitGroup._id,
      type: LineItemType.UnitGroup
    });
  }

  // This is only called for UnitGroup, OtherCost, ProfessionalFee, BuildPhase
  // Land and Contingency LineItems are there by default, Loans are handled in the CashflowFinanceStore
  async addLineItem(lineItem: ClientLineItem) {
    lineItem._id = new ObjectID().toHexString();
    lineItem.curveType = getDefaultCurveType(lineItem.type);
    this.lineItems.push(lineItem);
    const currentAppraisal = getCachedAppraisal(this.root.appraisalStore.appraisalId);
    let apolloUpdated = false;
    try {
      await createLineItem(this.root.appraisalStore.appraisalId, lineItem);
      apolloUpdated = true;
    } catch (e) {
      this.lineItems = currentAppraisal.cashflow.lineItems;
    }
    if (apolloUpdated) {
      const extendedLineItem = this.extendedLineItems.find((ln) => lineItem._id === ln._id);
      if (!extendedLineItem) {
        throw new Error("ExtendedLineItem cannot be found");
      }
      await this.spreadAndUpdateLineItem(extendedLineItem, true);
    }
  }

  async updateLineItem(lineItem: ClientLineItemExtended) {
    const index = this.lineItems.findIndex((item) => item._id === lineItem._id);
    this.lineItems[index] = lineItem;
    const currentAppraisal = getCachedAppraisal(this.root.appraisalStore.appraisalId);
    try {
      await updateLineItem(this.root.appraisalStore.appraisalId, lineItem);
    } catch (e) {
      this.lineItems = currentAppraisal.cashflow.lineItems;
    }
  }

  async deleteLineItem(linkedId: string) {
    const index = this.lineItems.findIndex((item) => item._linkedId === linkedId);
    this.lineItems.splice(index, 1);
    const currentAppraisal = getCachedAppraisal(this.root.appraisalStore.appraisalId);
    try {
      await deleteLineItem(this.root.appraisalStore.appraisalId, linkedId);
    } catch (e) {
      this.lineItems = currentAppraisal.cashflow.lineItems;
    }
  }

  async queueForAutomation(lineItem: ClientLineItemExtended) {
    const index = this.lineItemsToSpread.findIndex((lI) => lI._id === lineItem._id);
    if (index >= 0) {
      this.lineItemsToSpread[index] = lineItem;
    } else {
      this.lineItemsToSpread.push(lineItem);
    }

    await this.debounceAutomatingLineItems();
  }

  async setSpreadOrCustomUpdate(lineItem: ClientLineItemExtended) {
    if (isAutomatedCurveType(lineItem)) {
      await this.spreadAndUpdateLineItem(lineItem, false);
    } else {
      await this.updateLineItem(lineItem);
    }
  }

  async spreadAndUpdateLineItem(lineItem: ClientLineItemExtended, isAuto: boolean) {
    let lineItemClone = cloneDeep(lineItem);

    if (isAuto && lineItemMonthsNotSet(lineItemClone)) {
      lineItemClone = {
        ...lineItemClone,
        ...getDefaultIndexes(lineItem, this.currentOverallBuildStage)
      };
    }

    if (lineItem.type === LineItemType.UnitGroup) {
      spreadUnitGroupLineItem(lineItemClone, this.root.unitGroupStore, this.numMonths);
    } else {
      lineItemClone.cells = calculateCurve(lineItemClone, this.numMonths);
    }

    await this.updateLineItem(lineItemClone);
  }

  async automateLineItems() {
    const lineItemsClone = cloneDeep(this.lineItemsToSpread);
    this.lineItemsToSpread = [];
    await Promise.all(
      lineItemsClone.map((lineItem) => {
        return this.spreadAndUpdateLineItem(lineItem, true);
      })
    );
  }

  async resetLineItemsCells(lineItem: ClientLineItemExtended) {
    const length = lineItem.cells.length;
    const lineItemCopy = cloneDeep(lineItem);
    lineItemCopy.cells = new Array(length).fill(0);
    lineItemCopy.units = new Array(length).fill(0);
    lineItemCopy.curveType = CurveType.Custom;
    await this.updateLineItem(lineItemCopy);
  }

  async checkForAutomation(linkedItem: ClientCost | ClientUnitGroup) {
    let linkedLineItem: undefined | ClientLineItemExtended;
    const costType: undefined | string = (linkedItem as any).type;
    if (costType && costTypeToLineType(costType) === LineItemType.BuildPhase) {
      linkedLineItem = this.buildPhaseLineItems.find(
        (lineItem) => lineItem._linkedId === (linkedItem as ClientCost)._build_phase
      );
    } else {
      linkedLineItem = this.extendedLineItems.find(
        (lineItem) => lineItem._linkedId === linkedItem._id
      );
    }
    if (linkedLineItem && isAutomatedCurveType(linkedLineItem)) {
      await this.queueForAutomation(linkedLineItem);
    }
  }

  async checkLandNetPriceAutomation() {
    const netLineItem = this.landAcquisitionLineItems.find(
      (lineItem) => lineItem.description === LineItemDescription.NetPurchasePrice
    );
    if (netLineItem && isAutomatedCurveType(netLineItem)) {
      await this.queueForAutomation(netLineItem);
    }
  }

  async checkContingencyLineItemForAutomation() {
    if (this.contingencyLineItem && isAutomatedCurveType(this.contingencyLineItem)) {
      await this.queueForAutomation(this.contingencyLineItem);
    }
  }

  async exportCashflowToExcel() {
    await exportCashflowToExcel(this.root.cashflowFinanceStore, this.root.appraisalStore);
  }

  get extendedLineItems() {
    let extendedLineItems = [
      ...this.salesLineItems,
      ...this.buildPhaseLineItems,
      ...this.professionalFeeLineItems,
      ...this.otherCostLineItems,
      ...this.landAcquisitionLineItems
    ];
    if (this.contingencyLineItem) {
      extendedLineItems.push(this.contingencyLineItem);
    }
    return extendedLineItems;
  }

  get numMonths(): number {
    return Math.max(...[0, ...this.lineItems.map((line) => line.cells.length)]);
  }

  get salesLineItems(): ClientLineItemExtended[] {
    return getExtendedUnitGroupLineItems(this.lineItems, this.root.unitGroupStore, this.numMonths);
  }

  get buildPhaseLineItems(): ClientLineItemExtended[] {
    return getExtendedBuildPhaseLineItems(
      this.lineItems,
      this.root.costStore,
      this.root.buildPhaseStore,
      this.numMonths
    );
  }

  get contingencyLineItem(): Nullable<ClientLineItemExtended> {
    return (
      getExtendedContingencyLineItems(
        this.lineItems,
        this.root.buildPhaseStore,
        this.buildPhaseLineItems.length,
        this.numMonths
      )[0] ?? null
    );
  }

  get professionalFeeLineItems(): ClientLineItemExtended[] {
    return getExtendedProfFeesLineItems(this.lineItems, this.root.costStore, this.numMonths);
  }

  get otherCostLineItems(): ClientLineItemExtended[] {
    return getExtendedOtherCostLineItems(this.lineItems, this.root.costStore, this.numMonths);
  }

  get landAcquisitionLineItems(): ClientLineItemExtended[] {
    return getExtendedLandLineItems(
      this.lineItems,
      this.root.costStore,
      this.root.appraisalStore,
      this.numMonths
    );
  }

  get salesGrossRevenueRowTotal(): number {
    return this.salesLineItems.reduce((sum, row) => sum + row.value, 0);
  }

  get salesCostRowTotal(): number {
    return this.salesLineItems.reduce((sum, row) => {
      const unitGroup: ClientUnitGroup = this.root.unitGroupStore.unitGroups.find(
        (ug) => ug._id === row._linkedId
      ) as ClientUnitGroup;
      return sum + totalUnitGroupSalesFees(unitGroup);
    }, 0);
  }

  get salesNetRevenueRowTotal(): number {
    return this.salesGrossRevenueRowTotal - this.salesCostRowTotal;
  }

  get monthlyNetAggregatedTotal(): number {
    return this.salesGrossRevenueRowTotal - (this.root.costStore.totalCostExFunding ?? 0);
  }

  get isComplete() {
    return [
      ...this.salesLineItems,
      ...this.landAcquisitionLineItems,
      ...this.buildPhaseLineItems,
      ...this.professionalFeeLineItems,
      ...this.otherCostLineItems
    ].every((item) => item.valueRemaining === 0);
  }

  get salesGrossRevenueColTotals(): number[] {
    return [...Array(this.numMonths).keys()].map((index) =>
      columnTotal(index, this.salesLineItems)
    );
  }

  get salesCostColTotals(): number[] {
    return [...Array(this.numMonths).keys()].map((index) =>
      salesCostColTotal(index, this.salesLineItems, this.root.unitGroupStore)
    );
  }

  get salesNetRevenueColTotals(): number[] {
    return [...Array(this.numMonths).keys()].map(
      (index) => this.salesGrossRevenueColTotals[index] - this.salesCostColTotals[index]
    );
  }

  get landAcquisitionColTotals(): number[] {
    return [...Array(this.numMonths).keys()].map((index) =>
      columnTotal(index, this.landAcquisitionLineItems)
    );
  }

  get buildCostsColumnTotals(): number[] {
    return [...Array(this.numMonths).keys()].map(
      (index) =>
        columnTotal(index, this.buildPhaseLineItems) +
        contingencyForColumn(index, this.contingencyLineItem)
    );
  }

  get professionalFeesColumnTotals(): number[] {
    return [...Array(this.numMonths).keys()].map((index) =>
      columnTotal(index, this.professionalFeeLineItems)
    );
  }

  get otherCostsColumnTotals(): number[] {
    return [...Array(this.numMonths).keys()].map((index) =>
      columnTotal(index, this.otherCostLineItems)
    );
  }

  get totalCostsColTotals(): number[] {
    return [...Array(this.numMonths).keys()].map(
      (index) =>
        this.professionalFeesColumnTotals[index] +
        this.otherCostsColumnTotals[index] +
        this.buildCostsColumnTotals[index] +
        this.salesCostColTotals[index] +
        this.landAcquisitionColTotals[index]
    );
  }

  get monthlyNets(): number[] {
    return [...Array(this.numMonths).keys()].map(
      (index) => this.salesGrossRevenueColTotals[index] - this.totalCostsColTotals[index]
    );
  }

  get startIndexOfBuildPhases(): number {
    const buildPhaseFirstNonZeroMonths = this.buildPhaseLineItems
      .map((line) => findIndex(line.cells, (cell) => cell > 0))
      .filter((index) => index >= 0);
    return buildPhaseFirstNonZeroMonths.length
      ? Math.min(...buildPhaseFirstNonZeroMonths)
      : BUILD_PHASE_LINE_ITEM_DEFAULT_START;
  }

  get endIndexOfBuildPhases(): number {
    const buildPhaseLastNonZeroMonths = this.buildPhaseLineItems
      .map((line) => findLastIndex(line.cells, (cell) => cell > 0))
      .filter((index) => index >= 0);
    return buildPhaseLastNonZeroMonths.length
      ? Math.max(...buildPhaseLastNonZeroMonths)
      : BUILD_PHASE_LINE_ITEM_DEFAULT_END;
  }

  get currentOverallBuildStage(): OverallBuildStage {
    return {
      startIndex: this.startIndexOfBuildPhases,
      middleOfBuildPhaseIndex:
        this.startIndexOfBuildPhases +
        Math.floor((this.endIndexOfBuildPhases + 1 - this.startIndexOfBuildPhases) / 2),
      endIndex: this.endIndexOfBuildPhases
    };
  }
}
