import {
  Button,
  ButtonThemes,
  InfoTooltipContext,
  InfoTooltipContextType,
  Input,
  InputSuffix,
  Modal,
  NumberInput
} from "@/react/components";
import { ValueObject } from "@/react/components/Forms/NumberInput/types";
import { useTranslation } from "@/react/lib/hooks";
import {
  AppraisalEquityFundingTranslations,
  AppraisalSingularWords
} from "@/react/lib/i18n/locales";
import { rootStore } from "@/react/lib/persistence/root_store";
import { logEvent } from "@/react/utils/logging";
import { observer } from "mobx-react-lite";
import React, { FC } from "react";

const EquityModal: FC = () => {
  return <EquityModalObserved />;
};

const EquityModalObserved: FC = observer(() => {
  const { t } = useTranslation();
  const eventOpts = () => ({
    appraisal_id: rootStore.getCurrentAppraisal()._id,
    development_id: rootStore.currentDevelopmentStore.development?._id
  });
  const closeEventArgs = (): [string, any] => ["Equity Modal Closed", eventOpts()];
  function handleClose() {
    logEvent(...closeEventArgs());
    rootStore.currentEquityFundingStore.cancelEquityFundingUpdate();
  }
  function handleConfirm() {
    const equityFunding = rootStore.currentEquityFundingStore.equityFunding;
    const changeEventArgs: [string, any] = rootStore.currentEquityFundingStore.isEditing
      ? ["Equity Finance Edited", eventOpts()]
      : [
          "Equity Finance Added",
          {
            ...eventOpts(),
            equity_name: equityFunding.description,
            equity_amount: equityFunding.totalAmount,
            equity_profitshare: equityFunding.providerProfitShare,
            equity_rate: equityFunding.couponRate,
            equity_term: equityFunding.term
          }
        ];
    logEvent(...changeEventArgs);
    logEvent(...closeEventArgs());
    rootStore.currentEquityFundingStore.submitEquityFunding();
  }

  const title = () =>
    rootStore.currentEquityFundingStore.isEditing
      ? t(AppraisalEquityFundingTranslations.AppraisalEquityFunding_EditEquity)
      : t(AppraisalEquityFundingTranslations.AppraisalEquityFunding_AddEquity);

  const footerContent = () => (
    <>
      <Button theme={ButtonThemes.Secondary} className="atlas-mr-2" onClick={handleClose}>
        <span data-testid="cancelEquity">{t(AppraisalSingularWords.Appraisal_Cancel)}</span>
      </Button>
      <Button theme={ButtonThemes.Primary} onClick={handleConfirm}>
        <span data-testid="saveEquity">
          {rootStore.currentEquityFundingStore.isEditing
            ? t(AppraisalSingularWords.Appraisal_Save)
            : t(AppraisalSingularWords.Appraisal_Add)}
        </span>
      </Button>
    </>
  );

  return (
    <>
      {!!rootStore.utilityStore.equityModalOpen && (
        <Modal
          data-testid="EquityModal"
          defaultOpen={true}
          title={title()}
          onClose={handleClose}
          className="md:atlas-max-w-xl"
          footerClassNames="atlas-justify-end atlas-text-right"
          footerContent={footerContent()}
        >
          <div className="atlas-w-full atlas-p-4" data-testid="equityModal">
            {rootStore.currentEquityFundingStore.validator.state["name"] === false}
            <Input
              id="equityName"
              data-testid="equityName"
              label={t(AppraisalEquityFundingTranslations.AppraisalEquityFunding_EquityName)}
              className="atlas-w-full atlas-mb-4"
              error={{
                display:
                  rootStore.currentEquityFundingStore.validator.state["description"] === false,
                message: t(
                  AppraisalEquityFundingTranslations.AppraisalEquityFunding_Equity_NotBlank_ErrorMessage
                )
              }}
              value={rootStore.currentEquityFundingStore.equityFunding.description}
              onChange={(value) =>
                rootStore.currentEquityFundingStore.updateEquity({
                  description: String(value)
                })
              }
            />
            <div className="atlas-flex atlas-gap-3">
              <div className="atlas-w-1/2">
                <NumberInput
                  id="TotalAmount"
                  testId="totalAmount"
                  label={t(AppraisalEquityFundingTranslations.AppraisalEquityFunding_TotalAmount)}
                  className="atlas-w-full atlas-mb-4"
                  error={{
                    display:
                      rootStore.currentEquityFundingStore.validator.state["totalAmount"] === false,
                    message: t(
                      AppraisalEquityFundingTranslations.AppraisalEquityFunding_TotalAmount_ErrorMessage
                    )
                  }}
                  value={
                    {
                      value: rootStore.currentEquityFundingStore.equityFunding.totalAmount
                    } as ValueObject
                  }
                  onChange={(value) =>
                    rootStore.currentEquityFundingStore.updateEquity({
                      totalAmount: value.value
                    })
                  }
                  prefix="£"
                />
              </div>
              <div className="atlas-w-1/2">
                <InfoTooltipContext.Provider
                  value={
                    {
                      description: t(
                        AppraisalEquityFundingTranslations.AppraisalEquityFunding_ProviderProfitShare_Tooltip
                      )
                    } as InfoTooltipContextType
                  }
                >
                  <NumberInput
                    id="ProviderProfitShare"
                    testId="providerProfitShare"
                    label={t(
                      AppraisalEquityFundingTranslations.AppraisalEquityFunding_ProviderProfitShareLabel
                    )}
                    className="atlas-w-full atlas-mb-4"
                    error={{
                      display:
                        rootStore.currentEquityFundingStore.validator.state[
                          "providerProfitShare"
                        ] === false,
                      message: t(
                        AppraisalEquityFundingTranslations.AppraisalEquityFunding_ProviderProfitShare_ErrorMessage
                      )
                    }}
                    value={
                      {
                        value: rootStore.currentEquityFundingStore.equityFunding.providerProfitShare
                      } as ValueObject
                    }
                    onChange={(value) =>
                      rootStore.currentEquityFundingStore.updateEquity({
                        providerProfitShare: value.value
                      })
                    }
                    suffix="%"
                  />
                </InfoTooltipContext.Provider>
              </div>
            </div>
            <div className="atlas-flex atlas-gap-3">
              <div className="atlas-w-1/2">
                <InfoTooltipContext.Provider
                  value={
                    {
                      description: t(
                        AppraisalEquityFundingTranslations.AppraisalEquityFunding_CouponRate_Tooltip
                      )
                    } as InfoTooltipContextType
                  }
                >
                  <NumberInput
                    testId="couponRate"
                    id="couponRate"
                    label={t(AppraisalEquityFundingTranslations.AppraisalEquityFunding_CouponRate)}
                    error={{
                      display:
                        rootStore.currentEquityFundingStore.validator.state["couponRate"] === false,
                      message: t(
                        AppraisalEquityFundingTranslations.AppraisalEquityFunding_CouponRate_ErrorMessage
                      )
                    }}
                    inputClassName="atlas-rounded-r-none atlas-w-full"
                    className="atlas-w-full atlas-rounded-r-none"
                    value={
                      {
                        value: rootStore.currentEquityFundingStore.equityFunding.couponRate
                      } as ValueObject
                    }
                    onChange={(couponRateInput) =>
                      rootStore.currentEquityFundingStore.updateEquity({
                        couponRate: couponRateInput.value
                      })
                    }
                    suffix="%"
                  />
                </InfoTooltipContext.Provider>
              </div>
              <div className="atlas-w-1/2">
                <NumberInput
                  id="equityTerm"
                  testId="equityTerm"
                  label={t(AppraisalEquityFundingTranslations.AppraisalEquityFunding_EquityTerm)}
                  className="atlas-w-full atlas-mb-4"
                  error={{
                    display: rootStore.currentEquityFundingStore.validator.state["term"] === false,
                    message: t(
                      AppraisalEquityFundingTranslations.AppraisalEquityFunding_EquityTerm_ErrorMessage
                    )
                  }}
                  value={
                    { value: rootStore.currentEquityFundingStore.equityFunding.term } as ValueObject
                  }
                  disabled={!rootStore.currentEquityFundingStore.equityFunding.couponRate}
                  onChange={(value) =>
                    rootStore.currentEquityFundingStore.updateEquity({
                      term: value.value
                    })
                  }
                >
                  <InputSuffix>{t(AppraisalSingularWords.Appraisal_Months)}</InputSuffix>
                </NumberInput>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
});

export { EquityModal };
