import React, { PropsWithChildren, RefAttributes } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import clsx from "clsx";
import { useTranslation } from "@/react/lib/hooks";
import { AppraisalFundingTranslations } from "@/react/lib/i18n/locales";
import { Icon } from "../../Icons";

interface FooterProps {
  footerContent?: string | PropsWithChildren["children"];
  footerClassNames?: string;
}

export type ModalProps = PropsWithChildren<
  {
    title: string;
    "data-testid"?: string;
    onClose?: () => void;
    withClose?: boolean;
    className?: string;
  } & Dialog.DialogProps &
    FooterProps
>;

export function Modal({
  title,
  children,
  onClose,
  footerContent,
  footerClassNames,
  className = "md:atlas-w-1/2",
  withClose = true,
  ...rest
}: ModalProps) {
  const { t } = useTranslation();

  return (
    <Dialog.Root {...rest}>
      <Dialog.Overlay className="atlas-fixed atlas-left-0 atlas-right-0 atlas-z-max atlas-bg-neutral-900 atlas-inset-0 atlas-opacity-40" />
      <Dialog.Content
        data-testid={rest["data-testid"] ?? "modal-dialog"}
        className={clsx(
          "atlas-z-max atlas-bg-white atlas-rounded-lg focus:focus:atlas-outline-none atlas-flex atlas-flex-col atlas-max-h-90vh",
          "atlas-fixed atlas-left-4 atlas-right-4 atlas-top-12 md:atlas-left-1/2 md:atlas-top-1/2 atlas-min-w-max",
          "md:atlas-transform md:atlas--translate-x-1/2 md:atlas--translate-y-1/2",
          className
        )}
        onInteractOutside={onClose}
        onEscapeKeyDown={onClose}
      >
        <Dialog.Title className="atlas-my-0 atlas-py-3 atlas-px-4 atlas-bg-white atlas-rounded-t-lg atlas-border atlas-border-solid atlas-border-neutral-200 atlas-text-xl">
          {title}
        </Dialog.Title>
        <div
          className={clsx(
            "atlas-border-t atlas-border-l atlas-border-b atlas-border-r atlas-border-solid atlas-border-neutral-200 atlas-overflow-y-auto",
            { "atlas-rounded-b-lg": !footerContent }
          )}
        >
          {children}
        </div>
        {withClose && (
          <Dialog.Close
            data-testid="modal-close-button"
            aria-label={t(AppraisalFundingTranslations.AppraisalFunding_CloseModal)}
            className="atlas-absolute atlas-top-3 atlas-right-3 atlas-bg-transparent atlas-border-0 atlas-cursor-pointer atlas-text-neutral-700 hover:atlas-text-neutral-900"
            onClick={onClose}
          >
            <Icon.CloseLine />
          </Dialog.Close>
        )}
        {!!footerContent && (
          <div
            className={clsx(
              "atlas-py-3 atlas-px-4 atlas-bg-white atlas-rounded-b-lg atlas-border atlas-border-solid atlas-border-neutral-200",
              footerClassNames
            )}
          >
            {footerContent}
          </div>
        )}
      </Dialog.Content>
    </Dialog.Root>
  );
}

export type ModalDescriptionProps = PropsWithChildren<
  Dialog.DialogDescriptionProps & RefAttributes<HTMLParagraphElement>
>;
Modal.Description = ({ children, className, ...rest }: ModalDescriptionProps) => (
  <Dialog.Description className={clsx("atlas-p-4", className)} {...rest}>
    {children}
  </Dialog.Description>
);

export default Modal;
