import React, { PropsWithChildren } from "react";
import clsx from "clsx";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";

export interface DropdownMenuContentProps
  extends PropsWithChildren<DropdownMenuPrimitive.DropdownMenuContentProps> {
  sideOffset?: number;
  className?: string;
}

export function DropdownMenuContent({
  children,
  className,
  sideOffset,
  ...rest
}: DropdownMenuContentProps) {
  return (
    <DropdownMenuPrimitive.Content
      className={clsx(
        "atlas-box-border atlas-bg-white atlas-rounded atlas-shadow-md atlas-border atlas-border-solid atlas-border-neutral-400",
        className
      )}
      sideOffset={sideOffset ?? 10}
      {...rest}
    >
      {children}
    </DropdownMenuPrimitive.Content>
  );
}

export default DropdownMenuContent;
