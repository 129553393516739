<script>
/* global google:true*/
/*
  Overview:
  Inside a Vue template, use simply as:

    <map-polygon :data-layer="dataLayer" :geom="areaBoundary"></map-polygon>

  The geom prop can be any kind of geojson "geometry", and the "dataLayer"
  is one of the google maps things.

  You can also set (some) styles as properties.
*/

import EventUtil from "@/utils/event_util";
import { each, fromPairs, clone } from "lodash";
import MapUtil from "@/map/utils";

const FEATURE_EVENTS_TO_FORWARD = ["click", "mouseover", "mouseout"];

export default {
  name: "MapPolygon",
  render(createElement) {
    if (this.dataLayer !== this._oldDataLayer) {
      this.removeFeature();
      if (this.dataLayer) {
        this.generateFeature();
        this.dataLayer.add(this._feature);
      } else {
        return;
      }
    }
    this._oldDataLayer = this.dataLayer;

    this._feature.setGeometry(this.googlePoly);

    this.syncProperties();

    this.dataLayer.revertStyle(this._feature);
    this.dataLayer.overrideStyle(this._feature, this.polyStyle);
    return createElement("div");
  },
  props: {
    id: {
      type: String,
      required: true
    },
    dataLayer: {
      type: Object
    },
    geom: {
      type: Object,
      required: true
    },
    polyStyle: {
      type: Object,
      default() {
        return {};
      }
    },
    properties: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  mounted() {
    this.refreshEvents();
  },
  destroyed() {
    EventUtil.unregisterEvents(this);
    this.removeFeature();
    this._feature = null;
  },
  methods: {
    generateFeature() {
      if (!this._feature) {
        this._feature = new google.maps.Data.Feature({
          id: this.id,
          properties: this.properties
        });
      }
    },
    syncProperties() {
      this.generateFeature();

      const propertiesToSet = clone(this.properties);
      const propertiesToRemove = [];
      this._feature.forEachProperty((val, name) => {
        if (!this.properties[name]) {
          propertiesToRemove.push(name);
        } else if (val === this.properties[name]) {
          delete propertiesToSet[name];
        }
      });

      each(propertiesToSet, (val, name) => this._feature.setProperty(name, val));
      propertiesToRemove.forEach((name) => this._feature.removeProperty(name));
    },
    removeFeature() {
      if (this._feature) {
        this.dataLayer.revertStyle(this._feature);
        this.dataLayer.remove(this._feature);
      }
    },
    refreshEvents() {
      EventUtil.unregisterEvents(this);
      if (this.dataLayer) {
        const eventHandlers = fromPairs(
          FEATURE_EVENTS_TO_FORWARD.map((eventName) => {
            return [
              eventName,
              (event) => {
                if (event.feature === this._feature) {
                  this.$emit(`polygon-${eventName}`, event);
                  this.$emit(`polygon-event`, eventName, event);
                }
              }
            ];
          })
        );

        EventUtil.registerEvents(this, this.dataLayer, eventHandlers);
      }
    }
  },
  computed: {
    googlePoly() {
      return MapUtil.getGoogleDataGeometry(this.geom);
    }
  },
  watch: {
    dataLayer() {
      this.refreshEvents();
    }
  }
};
</script>
