<template>
  <div class="p-4" data-intercom-target="developments_page" data-testid="developments_page">
    <div class="d-flex flex-wrap align-items-center justify-content-between mb-3">
      <h1 class="developments-header h4 mb-0">Developments</h1>
      <VersionAlert class="mr-3" />

      <b-form-input
        v-if="developmentsStore.developments.length > 1"
        style="width: 380px"
        type="search"
        placeholder="Search by development name or site address"
        :value="developmentsStore.searchTerm"
        @input="(val) => updateSearchTerm(val)"
        trim
        class="mr-3"
      />
      <b-btn
        variant="primary"
        class="mr-3"
        @click="createDevelopmentManually"
        data-intercom-target="create_new_button"
      >
        Create Development
      </b-btn>
    </div>
    <div v-if="isLoading">
      <b-progress :value="100" variant="primary" :animated="true" class="mt-3" />
    </div>
    <div v-else class="flex-wrap align-items-center">
      <b-tabs content-class="mt-3" nav-class="developments-tabs">
        <b-tab
          v-for="developmentTab in developmentsStore.developmentTabs"
          :key="developmentTab.title"
          :title="`${developmentTab.title} (${developmentTab.developments.length})`"
          :active="developmentTab.title == 'Active'"
        >
          <div class="d-flex flex-wrap" v-if="developmentTab.developments.length">
            <div
              class="developments-card-row-item"
              v-for="development in developmentTab.developments"
              :key="development._id"
            >
              <div @click="showModal(development)">
                <DevelopmentCard
                  :development="development"
                  :mini-site="
                    developmentsStore.sites.find((site) => site._id === development._site)
                  "
                  :active="developmentTab.title === 'Active'"
                />
              </div>
            </div>
          </div>
          <div v-else class="empty-state">
            <h5>
              <b-icon icon="building" /> No
              <template v-if="developmentTab.title !== 'Active'">Archived</template> Developments
              Found
            </h5>
            <p v-show="developmentsStore.searchTerm">
              No <template v-if="developmentTab.title !== 'Active'">archived</template> development
              found for {{ developmentsStore.searchTerm }}. Try a different search term or create a
              new development
            </p>
            <b-button
              variant="primary"
              @click="createDevelopmentManually"
              v-if="developmentTab.title == 'Active'"
            >
              <div>Add new development</div>
              <b-spinner v-if="developmentCreateLoading" />
            </b-button>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <UrlParameterSyncer
      parameter-key="search"
      :value="developmentsStore.searchTerm"
      @input="(val) => val && developmentsStore.setSearchTerm(val)"
    />
    <DevelopmentModal
      v-if="utilityStore.developmentModalOpen"
      @close="hideModal"
      data-testid="development_modal"
    />
  </div>
</template>

<script lang="ts">
import DevelopmentCard from "@/components/DevelopmentCard.vue";
import DevelopmentModal from "@/components/DevelopmentModal.vue";
import UrlParameterSyncer from "@/components/UrlParameterSyncer.vue";
import VersionAlert from "@/components/VersionAlert.vue";
import EventLoggerMixin from "@/mixins/event_logger_mixin";
import { ClientMiniDevelopment } from "@shared/types/development";
import { Component, Mixins, Watch } from "vue-property-decorator";
import { rootStore } from "@/react/lib/persistence/root_store";
import { Observer } from "mobx-vue";
import { debounce } from "lodash";

@Observer
@Component({
  components: {
    VersionAlert,
    DevelopmentCard,
    UrlParameterSyncer,
    DevelopmentModal
  }
})
export default class DevelopmentsPage extends Mixins(EventLoggerMixin) {
  public developmentsStore = rootStore.developmentsStore;
  public utilityStore = rootStore.utilityStore;

  public updateSearchTerm = debounce(this.updateAndLogSearchTerm, 600);
  public isLoading = true;
  public developmentCreateLoading = false;

  async mounted() {
    this.logEvent("Developments Page Viewed");
    await this.developmentsStore.setupDevelopments();
    await this.resolveDevelopmentFromUrl();
    this.isLoading = false;
    rootStore.appraisalStore.resetAppraisalId();
    await Promise.all([
      this.developmentsStore.setupMiniSites(),
      rootStore.templateStore.setupTemplateStore()
    ]);
  }

  public async resolveDevelopmentFromUrl() {
    const developmentId = this.$route?.query.id as string;
    const siteId = this.$route?.query.site_id as string;
    const development = this.developmentsStore.getDevelopmentById(developmentId);
    if (developmentId && development) {
      rootStore.currentDevelopmentStore.showDevelopment(development);
    } else if (siteId) {
      const response = await this.developmentsStore.getOrCreateDevelopmentForSite(siteId);
      if (response) {
        rootStore.currentDevelopmentStore.showDevelopment(response.development);
        if (response.created) {
          this.logEvent("Development Created", {
            development_id: response.development._id,
            auto: true
          });
        }
      }
    } else {
      rootStore.currentDevelopmentStore.setDevelopment(null);
    }
  }

  public hideModal() {
    this.$router.push({ query: {} });
    rootStore.currentDevelopmentStore.closeModal();
  }

  public showModal(development: ClientMiniDevelopment) {
    rootStore.currentDevelopmentStore.showDevelopment(development);
    this.developmentsStore.setSearchTerm("");
    this.$router.push({ query: { id: development._id } });
  }

  public async createDevelopmentManually() {
    this.developmentCreateLoading = true;
    const newDevelopment = await this.developmentsStore.createDevelopment(false);
    this.logEvent("Development Created", {
      development_id: newDevelopment._id,
      auto: false
    });
    rootStore.currentDevelopmentStore.showDevelopment(newDevelopment);
    this.developmentCreateLoading = false;
    this.$router.push({ query: { id: rootStore.currentDevelopmentStore.development?._id } });
  }

  @Watch("$route")
  async onUrlChange() {
    const { id, site_id } = this.$route.query;
    if (
      (id && rootStore.currentDevelopmentStore.development?._id !== id) ||
      (!id && site_id && rootStore.currentDevelopmentStore.development?._site !== site_id)
    ) {
      await this.resolveDevelopmentFromUrl();
    }
  }

  private updateAndLogSearchTerm(value: string) {
    rootStore.developmentsStore.setSearchTerm(value);
    if (value) {
      this.logEvent("Development Search Updated");
    }
  }
}
</script>

<style lang="scss" scoped>
.developments-header {
  font-weight: bold;
  flex: 1 1 auto;
}

.developments-card-row-item {
  margin-right: 26px;
  width: 320px;
}
.empty-state {
  margin-left: 0px;
}
::v-deep .developments-tabs {
  border-bottom: 1px solid #dee2e6;
  .nav-link {
    background: #fff;
    border-radius: 0px;
    border: 0px none;
    border-bottom: 3px solid #fff;
    margin-right: 10px;
    margin-bottom: 0px;
    color: #012552;
    padding: 4px;

    &.active {
      &,
      &:hover {
        font-weight: bold;
        border-bottom-color: #54ccfa;
      }
    }
    &.active,
    &:hover {
      color: #012552;
    }
    &:hover {
      border-bottom-color: #dee2e6;
    }
  }
}
</style>
