import { datadogRum } from "@datadog/browser-rum";
import { ClientUser } from "@shared/types/user";

export function setupMonitoring({
  version,
  environment,
  apiURL,
  enabled = true
}: {
  version: string;
  environment: string;
  enabled?: boolean;
  apiURL: string;
  deviceSpecifications?: Record<string, string>;
}) {
  if (!enabled) {
    return;
  }

  datadogRum.init({
    applicationId: "34a9d6bf-b16a-4970-83c1-04d424a8f5d1",
    clientToken: "pub08bdb79d49e3be6efc2b64744dbb8418",
    site: "datadoghq.eu",
    service: "landfund",
    allowedTracingUrls: [
      new URL(apiURL || location.href).origin,
      (url) =>
        url.startsWith("https://app.land.tech") || url.startsWith("https://app.staging.land.tech")
    ],
    env: environment === "local" ? "dev" : environment,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackFrustrations: true,
    trackResources: true,
    trackLongTasks: true,
    version
  });
  datadogRum.startSessionReplayRecording();
}

export function assignUser(user?: ClientUser) {
  if (user) {
    datadogRum.setUser({
      id: user._id,
      email: user.email,
      plan: user.licence_summary
    });
    datadogRum.setGlobalContextProperty("licence-summary", user.licence_summary);
    (user.features || []).forEach((feature) =>
      datadogRum.setGlobalContextProperty(`feature-${feature}`, true)
    );
    (user.beta_features || []).forEach((betaFeature) =>
      datadogRum.setGlobalContextProperty(`beta-feature-${betaFeature}`, true)
    );
  }
}

export const captureException = (error: unknown, context?: object) =>
  datadogRum.addError(error, context);
