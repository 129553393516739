import { AppraisalMessages } from "./appraisals";
import { BuildCostMessages } from "./buildCost";
import { CashflowMessages } from "./cashflow";
import { CILMessages } from "./CIL";
import { baseComponentTranslations } from "./components";
import { DevelopmentTranslations } from "./development";
import { FundingPageMessages } from "./fundingPage";
import { IncomeSection } from "./income";

export enum AppraisalFinancialSummaryTranslations {
  AppraisalFinancialSummary_FinancialSummary = "Domains.Appraisals.FinancialSummary.FinancialSummary",
  AppraisalFinancialSummary_GDV = "Domains.Appraisals.FinancialSummary.GDV",
  AppraisalFinancialSummary_LandAcquisitionCost = "Domains.Appraisals.FinancialSummary.LandAcquisitionCost",
  AppraisalFinancialSummary_BuildCost = "Domains.Appraisals.FinancialSummary.BuildCost",
  AppraisalFinancialSummary_ProfessionalFees = "Domains.Appraisals.FinancialSummary.ProfessionalFees",
  AppraisalFinancialSummary_OtherCosts = "Domains.Appraisals.FinancialSummary.OtherCosts",
  AppraisalFinancialSummary_SalesFees = "Domains.Appraisals.FinancialSummary.SalesFees",
  AppraisalFinancialSummary_FundingCost = "Domains.Appraisals.FinancialSummary.FundingCost",
  AppraisalFinancialSummary_ClickToHideCosts = "Domains.Appraisals.FinancialSummary.ClickToHideCosts",
  AppraisalFinancialSummary_ClickToShowMoreCosts = "Domains.Appraisals.FinancialSummary.ClickToShowMoreCosts",
  AppraisalFinancialSummary_TotalCost = "Domains.Appraisals.FinancialSummary.TotalCost",
  AppraisalFinancialSummary_Profit = "Domains.Appraisals.FinancialSummary.Profit",
  AppraisalFinancialSummary_ProfitOnGDV = "Domains.Appraisals.FinancialSummary.ProfitOnGDV",
  AppraisalFinancialSummary_ProfitOnCost = "Domains.Appraisals.FinancialSummary.ProfitOnCost",
  AppraisalFinancialSummary_DeveloperProfit = "Domains.Appraisals.FinancialSummary.DeveloperProfit",
  AppraisalFinancialSummary_ROE = "Domains.Appraisals.FinancialSummary.ROE",
  AppraisalFinancialSummary_ROETooltip = "Domains.Appraisals.FinancialSummary.ROETooltip",
  AppraisalFinancialSummary_TotalNIA = "Domains.Appraisals.FinancialSummary.TotalNIA",
  AppraisalFinancialSummary_TotalGIA = "Domains.Appraisals.FinancialSummary.TotalGIA",
  AppraisalFinancialSummary_Sales = "Domains.Appraisals.FinancialSummary.Sales",
  AppraisalFinancialSummary_InclContingency = "Domains.Appraisals.FinancialSummary.InclContingency"
}

export enum ResidualLandValueTranslations {
  ResidualLandValue_ResidualLandValue = "Domains.Appraisals.ResidualLandValue.ResidualLandValue",
  ResidualLandValue_ResidualPurchasePrice = "Domains.Appraisals.ResidualLandValue.ResidualPurchasePrice",
  ResidualLandValue_Target = "Domains.Appraisals.ResidualLandValue.Target",
  ResidualLandValue_Metric = "Domains.Appraisals.ResidualLandValue.Metric",
  ResidualLandValue_TotalProfit = "Domains.Appraisals.ResidualLandValue.TotalProfit",
  ResidualLandValue_ResidualLandValueError = "Domains.Appraisals.ResidualLandValue.ResidualLandValueError"
}

export default {
  test_value: "GB Test value",
  [AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_FinancialSummary]:
    "Financial Summary",
  [AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_GDV]: "GDV",
  [AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_LandAcquisitionCost]:
    "Land Acquisition Cost",
  [AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_BuildCost]: "Build Cost",
  [AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_ProfessionalFees]:
    "Professional Fees",
  [AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_OtherCosts]: "Other Costs",
  [AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_SalesFees]: "Sales Fees",
  [AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_FundingCost]: "Funding Cost",
  [AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_ClickToHideCosts]:
    "Click to hide costs",
  [AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_ClickToShowMoreCosts]:
    "Click to show more costs",
  [AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_TotalCost]: "Total Cost",
  [AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_Profit]: "Profit",
  [AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_ProfitOnCost]: "Profit on Cost",
  [AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_ProfitOnGDV]: "Profit on GDV",
  [AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_DeveloperProfit]:
    "Developer's Profit",
  [AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_ROE]: "RoE",
  [AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_ROETooltip]:
    "RoE (Return on Equity) is calculated by dividing the Total Profit by the Developer Equity. This measures the return on the developer's investment in the project.",
  [AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_TotalNIA]: "Total NIA",
  [AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_TotalGIA]: "Total GIA",
  [AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_Sales]: "Sales",
  [AppraisalFinancialSummaryTranslations.AppraisalFinancialSummary_InclContingency]:
    "(incl. Contingency)",
  [ResidualLandValueTranslations.ResidualLandValue_ResidualLandValue]: "Residual Land Value",
  [ResidualLandValueTranslations.ResidualLandValue_ResidualPurchasePrice]:
    "Residual Purchase Price",
  [ResidualLandValueTranslations.ResidualLandValue_Target]: "Target",
  [ResidualLandValueTranslations.ResidualLandValue_Metric]: "Metric",
  [ResidualLandValueTranslations.ResidualLandValue_TotalProfit]: "Total Profit",
  [ResidualLandValueTranslations.ResidualLandValue_ResidualLandValueError]:
    "Your residual land value seems to be too negative to be calculated, try lowering the profit target, adding more revenue or reducing costs.",
  ...AppraisalMessages,
  ...BuildCostMessages,
  ...CashflowMessages,
  ...CILMessages,
  ...baseComponentTranslations,
  ...FundingPageMessages,
  ...DevelopmentTranslations,
  ...IncomeSection
};
