import { CashflowTimeFrameModalTranslations } from "@/react/lib/i18n/locales";
import { initLineItemExtended } from "@/react/utils/lineItem";
import { ClientLineItemExtended, CurveType, LineItemType } from "@shared/types/cashflow";
import { Nullable } from "@shared/types/utils";
import { MAX_MONTH_INPUT } from "@shared/utils/constants";
import { pluralise } from "@shared/utils/formatting";
import { isEmpty } from "lodash";
import { makeAutoObservable } from "mobx";
import { RootStore } from "../Root";

export class CurrentLineItemStore {
  readonly root: RootStore;

  currentLineItem: ClientLineItemExtended = initLineItemExtended;
  startMonthInput: number | null = 1;
  endMonthInput: number | null = 1;
  rateInput: number | null = 0;
  curveType: CurveType = this.currentLineItem.curveType;

  constructor(rootStore: RootStore) {
    this.root = rootStore;
    makeAutoObservable(this, { root: false });
  }

  setCurrentLineItem(value: ClientLineItemExtended) {
    this.currentLineItem = value;
  }

  updateCurrentLineItem(lineItemUpdate: Partial<ClientLineItemExtended>) {
    this.currentLineItem = { ...this.currentLineItem, ...lineItemUpdate };
  }

  setStartMonthInput(value: string) {
    this.startMonthInput = isEmpty(value) ? null : parseFloat(value);
  }

  setEndMonthInput(value: string) {
    this.endMonthInput = isEmpty(value) ? null : parseFloat(value);
  }

  setRateInput(value: string) {
    this.rateInput = isEmpty(value) ? null : parseFloat(value);
  }

  setCurveType(value: CurveType) {
    this.curveType = value;
  }

  get spreadModalTitle(): string {
    if (this.currentLineItem?.type === LineItemType.UnitGroup) {
      return `Spread ${this.currentLineItem.description} Sales`;
    } else {
      return `Spread ${this.currentLineItem?.description} as Curve`;
    }
  }

  get spreadIsComplete() {
    return (
      this.validateStartInput === null &&
      this.validateEndInput === null &&
      this.validateRateInput === null
    );
  }

  get validateStartInput() {
    if (this.startMonthInput && this.startMonthInput > MAX_MONTH_INPUT) {
      return false;
    }
    if (!this.startMonthInput || this.startMonthInput <= 0) {
      return false;
    }
    if (this.startMonthInput && this.endMonthInput && this.startMonthInput > this.endMonthInput) {
      return false;
    }
    return null;
  }

  get validateEndInput() {
    if (this.endMonthInput && (this.endMonthInput > MAX_MONTH_INPUT || this.endMonthInput < 0)) {
      return false;
    }
    if (this.endMonthInput && this.startMonthInput && this.endMonthInput < this.startMonthInput) {
      return false;
    }
    if (this.currentLineItem?.type !== LineItemType.UnitGroup && !this.endMonthInput) {
      return false;
    }
    if (
      this.endMonthInput &&
      this.currentLineItem?.type === LineItemType.UnitGroup &&
      this.rateInput &&
      this.rateInput > 0 &&
      this.endMonthInput > 0
    ) {
      return false;
    }
    return null;
  }

  get validateRateInput() {
    if (this.rateInput && this.rateInput < 0) {
      return false;
    }
    if (this.rateInput && this.rateInput > 0 && this.endMonthInput && this.endMonthInput > 0) {
      return false;
    }
    if (!this.rateInput && !this.endMonthInput) {
      return false;
    }
    return null;
  }

  get feedbackStartInput(): CashflowTimeFrameModalTranslations | null {
    if (!!this.startMonthInput && this.startMonthInput > MAX_MONTH_INPUT) {
      return CashflowTimeFrameModalTranslations.CashflowTimeFrameModal_StartError1;
    }
    if (this.endMonthInput && this.startMonthInput && this.startMonthInput > this.endMonthInput) {
      return CashflowTimeFrameModalTranslations.CashflowTimeFrameModal_StartError2;
    }
    if ((this.startMonthInput && this.startMonthInput <= 0) || !this.startMonthInput) {
      return CashflowTimeFrameModalTranslations.CashflowTimeFrameModal_StartError3;
    }
    return null;
  }

  get feedbackEndInput(): CashflowTimeFrameModalTranslations | null {
    if (this.endMonthInput && this.endMonthInput > MAX_MONTH_INPUT) {
      return CashflowTimeFrameModalTranslations.CashflowTimeFrameModal_EndError1;
    }

    if (
      this.currentLineItem?.type === LineItemType.UnitGroup &&
      this.endMonthInput &&
      this.startMonthInput &&
      this.startMonthInput >= this.endMonthInput &&
      this.rateInput === 0
    ) {
      return CashflowTimeFrameModalTranslations.CashflowTimeFrameModal_EndError2;
    }

    if (
      this.currentLineItem?.type === LineItemType.UnitGroup &&
      this.endMonthInput &&
      this.rateInput &&
      this.rateInput > 0
    ) {
      return CashflowTimeFrameModalTranslations.CashflowTimeFrameModal_EndError3;
    }

    if (
      (this.endMonthInput && this.endMonthInput < 0) ||
      (!this.endMonthInput && this.currentLineItem?.type !== LineItemType.UnitGroup)
    ) {
      return CashflowTimeFrameModalTranslations.CashflowTimeFrameModal_EndError4;
    }

    if (this.endMonthInput && this.startMonthInput && this.startMonthInput > this.endMonthInput) {
      return CashflowTimeFrameModalTranslations.CashflowTimeFrameModal_EndError5;
    }

    return null;
  }

  get feedbackRateInput(): CashflowTimeFrameModalTranslations | null {
    if (this.endMonthInput && this.rateInput && this.rateInput > 0) {
      return CashflowTimeFrameModalTranslations.CashflowTimeFrameModal_RateError1;
    }

    if (
      (!this.endMonthInput || this.endMonthInput === 0) &&
      (!this.rateInput || this.rateInput === 0)
    ) {
      return CashflowTimeFrameModalTranslations.CashflowTimeFrameModal_RateError2;
    }
    if (this.rateInput && this.rateInput < 0) {
      return CashflowTimeFrameModalTranslations.CashflowTimeFrameModal_RateError3;
    }
    return null;
  }

  get unitsCount() {
    const unitGroup = this.root.unitGroupStore.unitGroups.find(
      (ug) => ug._id === this.currentLineItem?._linkedId
    );
    return unitGroup?.units?.length || 0;
  }

  get spreadLength() {
    return (this.endMonthInput ?? 0) - ((this.startMonthInput ?? 0) - 1);
  }

  get hasSpreadTooLongForNumberOfUnits() {
    return (
      this.currentLineItem?.type === LineItemType.UnitGroup && this.spreadLength > this.unitsCount
    );
  }

  get unitSpreadWarningText() {
    if (this.hasSpreadTooLongForNumberOfUnits) {
      const endMonth = (this.startMonthInput ?? 0) - 1 + this.unitsCount;
      return (
        `Spread length (${pluralise("month", this.spreadLength)}) is greater than the number ` +
        `of units (${this.unitsCount}) - spread will end on month ${endMonth}`
      );
    } else {
      return "";
    }
  }

  public startEditTimeframe(lineItem: Nullable<ClientLineItemExtended>) {
    if (!lineItem) {
      return;
    }
    this.currentLineItem = { ...lineItem };
    this.startMonthInput = this.currentLineItem.startIndex + 1;
    if (this.currentLineItem.type === LineItemType.UnitGroup) {
      this.endMonthInput = this.currentLineItem.rate ? null : this.currentLineItem.endIndex + 1;
      this.rateInput = this.currentLineItem.rate;
    } else {
      this.endMonthInput = this.currentLineItem.endIndex + 1;
      this.rateInput = 0;
      this.curveType = this.currentLineItem.curveType;
    }
    this.root.utilityStore.openLineItemModal();
  }

  public resetLineItem() {
    this.currentLineItem = { ...initLineItemExtended };
    this.startMonthInput = 1;
    this.endMonthInput = 1;
    this.rateInput = 0;
    this.curveType = this.currentLineItem.curveType;
  }

  public resetAndClose() {
    this.root.utilityStore.closeLineItemModal();
    this.resetLineItem();
  }

  public async saveLineItemModal() {
    this.currentLineItem.curveType =
      this.currentLineItem.type === LineItemType.UnitGroup ? CurveType.Line : this.curveType;

    this.currentLineItem.startIndex = this.startMonthInput ? this.startMonthInput - 1 : -1;
    this.currentLineItem.endIndex = this.endMonthInput ? this.endMonthInput - 1 : -1;
    this.currentLineItem.rate = this.rateInput ? this.rateInput : 0;

    this.root.utilityStore.closeLineItemModal();
    await this.root.cashflowStore.setSpreadOrCustomUpdate(this.currentLineItem);
    this.resetLineItem();
  }
}
