import clsx from "clsx";
import React, { FC } from "react";
import { Icon } from "@/react/components";
import { uniqueId } from "lodash";
import { useTranslation } from "@/react/lib/hooks";
import { AppraisalFundingTranslations } from "@/react/lib/i18n/locales";

export const DragHandle: FC<{ isDragging?: boolean }> = ({ isDragging, ...props }) => {
  const dragHandleId = uniqueId("drag-handle-");
  const { t } = useTranslation();
  return (
    <div
      {...props}
      className={clsx("atlas-flex atlas-items-center atlas-mr-2 atlas-text-neutral-700", {
        "atlas-cursor-grabbing": isDragging,
        "atlas-cursor-grab": !isDragging
      })}
      role={props.hasOwnProperty("role") && !isDragging ? "button" : "none"}
      data-testid="drag-handle"
      aria-labelledby={dragHandleId}
    >
      <Icon.SixDots />
      <div id={dragHandleId} className="atlas-hidden">
        {t(AppraisalFundingTranslations.AppraisalFunding_DragHandle)}
      </div>
    </div>
  );
};
