<template>
  <div class="appraisal">
    <template v-if="!appraisalStore.isSetup || currentDevelopmentStore.isDevelopmentLoading">
      <b-progress :value="100" variant="primary" :animated="true" class="mt-3" />
    </template>
    <template v-else>
      <div class="appraisal-form flex-column">
        <VersionAlert class="mb-4" />

        <AppraisalDetails />

        <SiteMap v-if="hasLandInsightAccess(userStore.user)" />

        <IncomeSection class="section-wrapper" />

        <BuildSection />

        <ProfessionalFeesSection class="section-wrapper" />

        <OtherCostsSection class="section-wrapper" />

        <FundingSection class="section-wrapper" />

        <LandAcquisition />
        <CostModal />
      </div>

      <b-sidebar
        id="appraisal-sidebar"
        no-close-on-esc
        right
        z-index="1"
        class="appraisal-sidebar flex-column"
        shadow
        :visible="sidebarVisible"
        no-header
        bg-variant="white"
        width="540px"
      >
        <ResidualLandValue />

        <FinancialSummary />
      </b-sidebar>
      <CILSidebar />
    </template>
  </div>
</template>

<script lang="ts">
import AppraisalDetails from "@/components/AppraisalDetails.vue";
import BuildSection from "@/components/BuildSection/BuildSection.vue";
import FundingSection from "@/components/FundingSection/FundingSection.vue";
import LandAcquisition from "@/components/LandAcquisition.vue";
import SiteMap from "@/components/SiteMap.vue";
import VersionAlert from "@/components/VersionAlert.vue";
import ResizeMixin from "@/mixins/resize_mixin";
import { FinancialSummary, ResidualLandValue } from "@/react/domains/appraisal";
import { hasLandInsightAccess } from "@shared/utils/authorisation";
import { Observer } from "mobx-vue";
import { Component, Mixins } from "vue-property-decorator";
import { CostModal } from "@/react/domains/appraisal/Costs/CostModal/CostModal";
import { CostList } from "@/react/domains/appraisal/Costs/CostList/CostList";
import { CILSidebar } from "@/react/domains/appraisal/CIL/CILSidebar";
import { AppraisalSection } from "@/react/layouts/AppraisalPage/AppraisalSection";
import { ProfessionalFeesSection } from "@/react/layouts/AppraisalPage/ProfessionalFees/ProfessionalFeesSection";
import { OtherCostsSection } from "@/react/layouts/AppraisalPage/OtherCosts/OtherCostsSection";
import { IncomeSection } from "@/react/layouts/AppraisalPage/Income/IncomeSection";
import { rootStore } from "@/react/lib/persistence/root_store";

@Observer
@Component({
  components: {
    AppraisalSection: AppraisalSection as Vue.Component,
    CostList: CostList as Vue.Component,
    IncomeSection: IncomeSection as Vue.Component,
    BuildSection,
    FundingSection,
    AppraisalDetails,
    SiteMap,
    LandAcquisition,
    ProfessionalFeesSection: ProfessionalFeesSection as Vue.Component,
    OtherCostsSection: OtherCostsSection as Vue.Component,
    ResidualLandValue: ResidualLandValue as Vue.Component,
    FinancialSummary: FinancialSummary as Vue.Component,
    CILSidebar: CILSidebar as Vue.Component,
    CostModal: CostModal as Vue.Component,
    VersionAlert
  }
})
export default class AppraisalPage extends Mixins(ResizeMixin) {
  public hasLandInsightAccess = hasLandInsightAccess;
  public currentDevelopmentStore = rootStore.currentDevelopmentStore;
  public userStore = rootStore.userStore;
  public appraisalStore = rootStore.appraisalStore;

  mounted() {
    document.body.classList.add("atlas-overflow-hidden");
  }

  destroyed() {
    document.body.classList.remove("atlas-overflow-hidden");
  }
}
</script>

<style lang="scss">
.appraisal-sidebar {
  .b-sidebar {
    top: 98px;
    height: calc(100vh - 98px);
    padding: 0;

    .b-sidebar-body {
      padding: 1.5rem 1.25rem 0 1.25rem;
    }
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/scss/landtech-colors";

.appraisal {
  max-height: calc(100vh - 98px);
  overflow-y: auto;
  padding: 1.25rem;
  padding-top: 1.625rem;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.appraisal-form {
  padding: 0;
}

@media (min-width: 1200px) {
  .appraisal-form {
    padding-right: 540px;
  }
}
//overwrites bootstrap's card styling
.card,
// used for wrappers containing React components
.section-wrapper {
  width: 100%;
  max-width: 996px;
}
//overwrites bootstrap's card styling
.card {
  margin-bottom: 1.25rem;
}

.residual-metric-input {
  margin-right: 8px;
}
</style>
