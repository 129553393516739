import gql from "graphql-tag";
import { landFundApolloClient } from "../clients";
import { sanitiseInput } from "../utils";
import { SET_EQUITY_FUNDING_SOURCES_MUTATION } from "@shared/apollo/mutations";
import { ClientEquityFunding } from "@shared/types/equity";

export const setEquityFundingSources = async (
  appraisalId: string,
  equityFundingSources: ClientEquityFunding[]
) => {
  const { data } = await landFundApolloClient.mutate({
    mutation: gql`
      ${SET_EQUITY_FUNDING_SOURCES_MUTATION}
    `,
    variables: {
      appraisalId,
      equityFundingUpdate: sanitiseInput(equityFundingSources, false)
    }
  });
  return data?.setEquityFundingSources;
};
