import React, { FC, MouseEventHandler, ReactNode, useCallback, useMemo, useState } from "react";
import { Button, ButtonSizes, ButtonThemes, Icon, Input, ProgressBar } from "@/react/components";
import { FundingPageContactDetails } from "@shared/types/fundingPage";
import axios from "axios";
import { logEvent } from "@/react/utils";
import { useTranslation } from "@/react/lib/hooks";
import { FundingPageTranslations } from "@/react/lib/i18n/locales";
import { Interweave } from "interweave";
import { observer } from "mobx-react-lite";
import { rootStore } from "@/react/lib/persistence/root_store";

interface ContactItem {
  icon: string | ReactNode;
  text: string;
  href: string;
  onClick: MouseEventHandler<HTMLAnchorElement>;
}

interface FundingPageFormProps {
  page?: string;
}

const FundingPageForm: FC<FundingPageFormProps> = (props) => (
  <FundingPageFormObservable {...props} />
);

const FundingPageFormObservable: FC<FundingPageFormProps> = observer(({ page = "FundingPage" }) => {
  const { t } = useTranslation();
  const [name, setName] = useState(rootStore.userStore.userName);
  const [company, setCompany] = useState(rootStore.userStore.user?._organisation.name || "");
  const [phone, setPhone] = useState(rootStore.userStore.user?.telephone || "");
  const [email, setEmail] = useState(rootStore.userStore.user?.email || "");
  const [requestIsSending, setRequestIsSending] = useState(false);
  const [requestIsSent, setRequestIsSent] = useState(false);
  const numberValid = useMemo(() => {
    return phone.match(/[^a-wy-zA-WY-Z]/g)?.length === phone.length;
  }, [phone]);

  const emailValid = useMemo(() => {
    return email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) !== null;
  }, [email]);

  const formValid = useMemo(() => {
    return !!numberValid && !!emailValid && !!company.length && !!name.length;
  }, [numberValid, emailValid, company, name]);

  const getContactLogger = (desc: string) => logEvent(`FundingPage ${desc} Contact Clicked`);

  const contactDetails: ContactItem[] = [
    {
      icon: <Icon.PhoneLine className="atlas-text-white atlas-mr-4" />,
      text: "07590 665 731",
      href: "tel:+447590665731",
      onClick: () => getContactLogger("Phone")
    },
    {
      icon: <Icon.MailLine className="atlas-text-white atlas-fill-current atlas-mr-4" />,
      text: "funding@land.tech",
      href: "mailto:funding@land.tech",
      onClick: () => {
        getContactLogger("Email");
      }
    },
    {
      icon: <Icon.Chat1Line className="atlas-text-white atlas-mr-4" />,
      text: "chat with us",
      href: "#",
      onClick: (event) => {
        event.preventDefault();
        getContactLogger("Chat");
        window.Intercom("show");
      }
    }
  ];

  const handleRequest = useCallback(async () => {
    if (!formValid) {
      setRequestIsSending(false);
      setRequestIsSent(false);
      return;
    }

    window.Intercom("show");
    logEvent("FundingPage Callback Request Button Clicked", { page });
    const data: FundingPageContactDetails = {
      contactCompanyName: company,
      contactName: name,
      contactPhoneNumber: phone,
      contactEmailAddress: email
    };
    setRequestIsSending(true);
    await axios.post(`${LANDFUND_CONFIG.LANDFUND_API_URL}/funding/request-callback`, data);
    setRequestIsSending(false);
    setRequestIsSent(true);
  }, []);
  return (
    <div
      className={"atlas-bg-primary-800 atlas-p-10 sm:atlas-p-16 atlas-rounded-b atlas-min-h-full"}
      data-testid="lenderMatchForm"
    >
      <div className="atlas-flex atlas-justify-between atlas-items-end atlas-mb-6">
        <div>
          <h3 className={"atlas-text-secondary-900 atlas-font-bold atlas-text-2xl"}>
            {t(FundingPageTranslations.GetStarted)}
          </h3>
          <p className="atlas-text-white sm:atlas-pr-4">
            {t(FundingPageTranslations.LeaveDetails)}
          </p>
        </div>
        <img
          src={require("@/react/assets/img/Coins_£_light.png")}
          className="atlas-w-28 sm:atlas-w-40 atlas-max-w-full"
          aria-hidden="true"
        />
      </div>
      {!requestIsSending && !requestIsSent && (
        <div className="atlas-flex atlas-flex-col">
          <Input
            className="atlas-text-secondary-200 atlas-mb-4"
            label={t(FundingPageTranslations.Company)}
            id={"company"}
            error={{ message: t(FundingPageTranslations.BlankInput), display: !company.length }}
            value={company}
            onInput={(e) => setCompany(e.currentTarget.value)}
            testId="company-input"
          />
          <Input
            className="atlas-text-secondary-200 atlas-mb-4"
            label={t(FundingPageTranslations.Name)}
            id={"name"}
            error={{ message: t(FundingPageTranslations.BlankInput), display: !name.length }}
            value={name}
            onInput={(e) => setName(e.currentTarget.value)}
          />
          <Input
            className="atlas-text-secondary-200 atlas-mb-4"
            label={t(FundingPageTranslations.PhoneNumber)}
            id={"phone"}
            error={{
              message: !phone.length
                ? t(FundingPageTranslations.BlankInput)
                : t(FundingPageTranslations.NumberInvalid),
              display: !phone.length || !numberValid
            }}
            value={phone}
            onInput={(e) => setPhone(e.currentTarget.value)}
            testId="phone-input"
          />
          <Input
            className="atlas-text-secondary-200 atlas-mb-4"
            label={t(FundingPageTranslations.Email)}
            id={"email"}
            error={{
              message: t(FundingPageTranslations.EmailInvalid),
              display: !emailValid
            }}
            value={email}
            onInput={(e) => setEmail(e.currentTarget.value)}
            testId="email-input"
          />
          <Button
            onClick={handleRequest}
            className="atlas-mb-6 atlas-mt-4"
            size={ButtonSizes.LG}
            theme={ButtonThemes.Blue}
            disabled={!formValid}
          >
            {t(FundingPageTranslations.RequestCallback)}
          </Button>
          <p className="atlas-text-xs atlas-text-white atlas-text-center">
            <small>
              <Interweave content={t(FundingPageTranslations.TermsAndConditions)} />
            </small>
          </p>
        </div>
      )}
      {requestIsSending && <ProgressBar />}
      {requestIsSent && (
        <div className="text-white text-center">
          {t(FundingPageTranslations.FormSubmitted_ThankYou)}
        </div>
      )}
      <div className={"atlas-flex atlas-flex-col atlas-justify-center atlas-items-center"}>
        <h4 className="atlas-text-white atlas-text-center atlas-pt-5 atlas-mb-4 atlas-text-xl atlas-font-normal">
          {t(FundingPageTranslations.ContactDirectly)}
        </h4>
        <div className="atlas-flex atlas-flex-col atlas-items-start">
          {contactDetails.map((contact) => (
            <a
              href={contact.href}
              onClick={contact.onClick}
              key={contact.text.replace(" ", "")}
              className="atlas-text-left atlas-text-lg atlas-flex atlas-items-center atlas-mb-3 atlas-text-secondary-200 atlas-no-underline hover:atlas-underline hover:atlas-text-secondary-200"
            >
              {contact.icon}
              <span>{contact.text}</span>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
});

export { FundingPageForm };
