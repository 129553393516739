// Module copied from landinsight-app

// This is the "shell" Intercom object, which the Intercom script will look at once it's loaded
// Note that it will create a new object and assign back to window.Intercom.
window.Intercom = function () {
  window.Intercom.c(arguments);
};
window.Intercom.q = [];
window.Intercom.c = (args: any) => window.Intercom.q.push(args);

// We defer for a few seconds after page load as we want the page load itself to be snappy
export const bootIntercomDeferred = (settings: Intercom_.IntercomSettings, delayMs = 3000) => {
  window.Intercom("update", settings);

  setTimeout(() => {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.src = `https://widget.intercom.io/widget/${settings.app_id}`;
    const x = document.getElementsByTagName("script")[0];
    x.parentNode?.insertBefore(s, x);
  }, delayMs);
};

// it's handy to track the visible state
window.Intercom("onShow", () => (window.Intercom.visible = true));
window.Intercom("onHide", () => (window.Intercom.visible = false));
