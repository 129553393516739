import VueRouter from "vue-router";

const addToRelativeUrl = (initialUrl: string, params: Record<string, string>) => {
  // URL and searchParams are built into Node, but don't quite do what we want in terms of relative urls.
  // see discussion about this here - https://github.com/nodejs/node/issues/12682.
  // also the diagram here - https://nodejs.org/api/url.html#url_url_strings_and_url_objects.
  // .invalid is apparently guaranteed to be invalid as a TLD, though it shouldn't ever leak out from this function
  const url = new URL(initialUrl || "", "https://nothing.invalid");

  Object.entries(params).forEach(([key, value]) => url.searchParams.set(key, value));

  return url.pathname + url.search + url.hash;
};

export const redirectToLogin = (router: VueRouter) => {
  if (process.env.NODE_ENV !== "development") {
    window.location.href = addToRelativeUrl(LANDFUND_CONFIG.LOGIN_PATH, {
      redirect: router.currentRoute.fullPath
    });
  }
};
