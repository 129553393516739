import React from "react";
import { TableSection } from "../CashflowTableSection";
import { rootStore } from "@/react/lib/persistence/root_store";
import { LineItemRow } from "../../LineItemRow";
import { observer } from "mobx-react-lite";
import { FinanceRow } from "../../FinanceRow";
import { CashflowTableTranslations } from "@/react/lib/i18n/locales";
import { useTranslation } from "@/react/lib/hooks";

export const BuildSection = observer(() => {
  const { t } = useTranslation();
  const cashflowStore = rootStore.cashflowStore;
  const buildPhaseStore = rootStore.buildPhaseStore;
  return (
    <TableSection title="Build Phases" testId="build-section">
      {cashflowStore.buildPhaseLineItems.map((val) => (
        <LineItemRow testId="build-phase-line-item" lineItem={val} key={val._id} />
      ))}
      {cashflowStore.contingencyLineItem && (
        <LineItemRow testId="contingency-line-item" lineItem={cashflowStore.contingencyLineItem} />
      )}
      <FinanceRow
        bottomBorder
        totalRow
        testId="build-total-row"
        title={t(CashflowTableTranslations.CashflowTable_Total)}
        valueCell={buildPhaseStore.totalBuildCostWithContingency}
        monthCells={cashflowStore.buildCostsColumnTotals}
      />
    </TableSection>
  );
});
