import { makeAutoObservable } from "mobx";

export class UtilityStore {
  accountSidebarOpen: boolean = false;
  CILBarOpen: boolean = false;
  costModalOpen: boolean = false;
  equityModalOpen: boolean = false;
  lineItemModalOpen: boolean = false;
  unitGroupModalOpen: boolean = false;
  loanModalOpen: boolean = false;
  developmentModalOpen: boolean = false;
  spreadToolsHidden: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  openCILBar() {
    this.CILBarOpen = true;
  }

  closeCILBar() {
    this.CILBarOpen = false;
  }

  toggleCILBar() {
    this.CILBarOpen = !this.CILBarOpen;
  }

  toggleAccountSideBar() {
    this.accountSidebarOpen = !this.accountSidebarOpen;
  }

  openCostModal() {
    this.costModalOpen = true;
  }

  closeCostModal() {
    this.costModalOpen = false;
  }

  openEquityModal() {
    this.equityModalOpen = true;
  }

  closeEquityModal() {
    this.equityModalOpen = false;
  }

  openLineItemModal() {
    this.lineItemModalOpen = true;
  }

  closeLineItemModal() {
    this.lineItemModalOpen = false;
  }

  openUnitGroupModal() {
    this.unitGroupModalOpen = true;
  }

  closeUnitGroupModal() {
    this.unitGroupModalOpen = false;
  }

  openLoanModal() {
    this.loanModalOpen = true;
  }

  closeLoanModal() {
    this.loanModalOpen = false;
  }

  openDevelopmentModal() {
    this.developmentModalOpen = true;
  }

  closeDevelopmentModal() {
    this.developmentModalOpen = false;
  }

  toggleSpreadTools() {
    this.spreadToolsHidden = !this.spreadToolsHidden;
  }

  reset() {
    this.accountSidebarOpen = false;
    this.CILBarOpen = false;
    this.costModalOpen = false;
    this.developmentModalOpen = false;
    this.equityModalOpen = false;
    this.lineItemModalOpen = false;
    this.unitGroupModalOpen = false;
    this.spreadToolsHidden = false;
  }
}
