import React, { FC, PropsWithChildren } from "react";
import { Icon } from "../Icons";
import clsx from "clsx";

export enum AlertType {
  Info,
  Warning,
  Danger
}

export enum AlertSize {
  Small,
  Normal
}

export interface AlertProps {
  type: AlertType;
  size?: AlertSize;
  onAction?: () => void;
}

export const Alert: FC<PropsWithChildren<AlertProps>> = ({
  type,
  size = AlertSize.Normal,
  onAction,
  children
}) => {
  return (
    <div
      className={clsx(
        "atlas-rounded atlas-flex",
        { "atlas-px-2 atlas-py-1": size === AlertSize.Small },
        { "atlas-p-3": size === AlertSize.Normal },
        {
          "atlas-border atlas-border-solid atlas-bg-primary-25": type === AlertType.Info
        },
        { "atlas-bg-orange-100 atlas-text-orange-700": type === AlertType.Warning }
      )}
    >
      {type === AlertType.Info && (
        <Icon.InformationLine className="atlas-text-primary-600 atlas-mt-0.5 atlas-flex-shrink-0" />
      )}
      <div
        className={clsx(
          "atlas-mx-2 atlas-mt-1 atlas-w-full",
          { "atlas-text-sm": type === AlertType.Info },
          { "atlas-text-base": type === AlertType.Warning }
        )}
      >
        {children}
      </div>
      {!!onAction && (
        <a onClick={onAction} data-testid="close-button">
          <Icon.CloseLine className="atlas-cursor-pointer atlas-text-primary-900 hover:atlas-text-primary-500" />
        </a>
      )}
    </div>
  );
};
