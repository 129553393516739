import React, { Dispatch, FC, ReactNode } from "react";
import { Button, ButtonThemes } from "../../Button";
import { Modal } from "..";

interface ConfirmProps {
  onConfirm: Dispatch<boolean>;
  onCancel: Dispatch<boolean>;
  title: string;
  description: string | ReactNode;
  modalOpen: boolean;
  confirmButtonText?: string;
  confirmButtonTheme?: ButtonThemes;
}

const Confirm: FC<ConfirmProps> = ({
  title,
  description,
  onConfirm,
  onCancel,
  modalOpen = false,
  confirmButtonText = "Confirm",
  confirmButtonTheme = ButtonThemes.Danger
}) => {
  return (
    <div data-testid="confirm-component">
      {!!modalOpen && (
        <Modal
          defaultOpen={true}
          open={modalOpen}
          title={title}
          className="md:atlas-w-96"
          footerClassNames=" atlas-text-right"
          withClose={false}
          footerContent={
            <>
              <Button
                data-testid="confirm-component-cancel"
                className="atlas-mr-2"
                onClick={() => onCancel(true)}
                theme={ButtonThemes.Secondary}
              >
                Cancel
              </Button>
              <Button
                data-testid="confirm-component-confirm"
                onClick={() => onConfirm(true)}
                theme={confirmButtonTheme}
              >
                {confirmButtonText}
              </Button>
            </>
          }
        >
          <Modal.Description>{description}</Modal.Description>
        </Modal>
      )}
    </div>
  );
};

export { Confirm, ConfirmProps };
