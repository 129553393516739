export enum CashflowTimeFrameModalTranslations {
  CashflowTimeFrameModal_StartMonth = "Domains.Cashflow.TimeframeModal.StartMonth",
  CashflowTimeFrameModal_EndMonth = "Domains.Cashflow.TimeframeModal.EndMonth",
  CashflowTimeFrameModal_OrLabel = "Domains.Cashflow.TimeframeModal.OrLabel",
  CashflowTimeFrameModal_SpreadRate = "Domains.Cashflow.TimeframeModal.SpreadRate",
  CashflowTimeFrameModal_Save = "Domains.Cashflow.TimeframeModal.Save",
  CashflowTimeFrameModal_StartError1 = "Domains.Cashflow.TimeframeModal.StartError1",
  CashflowTimeFrameModal_StartError2 = "Domains.Cashflow.TimeframeModal.StartError2",
  CashflowTimeFrameModal_StartError3 = "Domains.Cashflow.TimeframeModal.StartError3",
  CashflowTimeFrameModal_EndError1 = "Domains.Cashflow.TimeframeModal.EndError1",
  CashflowTimeFrameModal_EndError2 = "Domains.Cashflow.TimeframeModal.EndError2",
  CashflowTimeFrameModal_EndError3 = "Domains.Cashflow.TimeframeModal.EndError3",
  CashflowTimeFrameModal_EndError4 = "Domains.Cashflow.TimeframeModal.EndError4",
  CashflowTimeFrameModal_EndError5 = "Domains.Cashflow.TimeframeModal.EndError5",
  CashflowTimeFrameModal_RateError1 = "Domains.Cashflow.TimeframeModal.RateError1",
  CashflowTimeFrameModal_RateError2 = "Domains.Cashflow.TimeframeModal.RateError2",
  CashflowTimeFrameModal_RateError3 = "Domains.Cashflow.TimeframeModal.RateError3"
}

export enum CashflowTableTranslations {
  CashflowTable_Total = "Domain.Cashflow.CashflowTable.Total",
  CashflowTable_Value = "Domain.Cashflow.CashflowTable.Value",
  CashflowTable_TimeFrame = "Domain.Cashflow.CashflowTable.TimeFrame",
  CashflowTable_Curve = "Domain.Cashflow.CashflowTable.Curve",
  CashflowTable_Month = "Domain.Cashflow.CashflowTable.Month",
  CashflowTable_Balance = "Domain.Cashflow.CashflowTable.Balance",
  CashflowTable_InterestAccrued = "Domain.Cashflow.CashflowTable.InterestAccrued",
  CashflowTable_InterestPaid = "Domain.Cashflow.CashflowTable.InterestPaid",
  CashflowTable_FeesAccrued = "Domain.Cashflow.CashflowTable.FeesAccrued",
  CashflowTable_FeesPaid = "Domain.Cashflow.CashflowTable.FeesPaid",
  CashflowTable_InterestPaidRate = "Domain.Cashflow.CashflowTable.InterestPaidRate",
  CashflowTable_PPS = "Domain.Cashflow.CashflowTable.PPS",
  CashflowTable_DeveloperEquity = "Domain.Cashflow.CashflowTable.DeveloperEquity",
  CashflowTable_LessServicedInterest = "Domain.Cashflow.CashflowTable.LessServicedInterest",
  CashflowTable_GrossRevenue = "Domain.Cashflow.CashflowTable.GrossRevenue",
  CashflowTable_CostOfSales = "Domain.Cashflow.CashflowTable.CostOfSales",
  CashflowTable_NetRevenue = "Domain.Cashflow.CashflowTable.NetRevenue",
  CashflowTable_TotalCosts = "Domain.Cashflow.CashflowTable.TotalCosts",
  CashflowTable_MonthlyNet = "Domain.Cashflow.CashflowTable.MonthlyNet",
  CashflowTable_Set = "Domain.Cashflow.CashflowTable.Set"
}
