import React from "react";
import { TableSection } from "../CashflowTableSection";
import { rootStore } from "@/react/lib/persistence/root_store";
import { LineItemRow } from "../../LineItemRow";
import { observer } from "mobx-react-lite";
import { FinanceRow } from "../../FinanceRow";
import { CashflowTableTranslations } from "@/react/lib/i18n/locales";
import { useTranslation } from "@/react/lib/hooks";

export const LandAcquisitionSection = observer(() => {
  const { t } = useTranslation();
  const cashflowStore = rootStore.cashflowStore;
  const costStore = rootStore.costStore;
  return (
    <TableSection title="Land Acquisition" testId="land-acquisition-section">
      {cashflowStore.landAcquisitionLineItems.map((val) => (
        <LineItemRow lineItem={val} key={val._id} testId="land-line-item" />
      ))}
      <FinanceRow
        testId="land-totals"
        totalRow
        bottomBorder
        title={t(CashflowTableTranslations.CashflowTable_Total)}
        valueCell={costStore.totalLandCosts}
        monthCells={cashflowStore.landAcquisitionColTotals}
      />
    </TableSection>
  );
});
