import React, { PropsWithChildren, AnchorHTMLAttributes, Dispatch } from "react";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import clsx from "clsx";

const baseClasses = "atlas-p-0 atlas-outline-none atlas-border atlas-border-transparent";

export type DropdownMenuItemProps = {
  header?: boolean;
} & DropdownMenuPrimitive.DropdownMenuItemProps;

export function DropdownMenuItem({
  children,
  disabled,
  className,
  ...rest
}: DropdownMenuItemProps) {
  return (
    <DropdownMenuPrimitive.DropdownMenuItem
      {...rest}
      className={clsx(
        baseClasses,
        "atlas-cursor-pointer hover:atlas-bg-neutral-100 focus:atlas-bg-neutral-100",
        disabled && "atlas-pointer-events-none",
        className
      )}
      disabled={disabled}
    >
      {children}
    </DropdownMenuPrimitive.DropdownMenuItem>
  );
}

function DropdownMenuItemText({ children, className }: PropsWithChildren<{ className?: string }>) {
  return (
    <DropdownMenuPrimitive.DropdownMenuLabel
      className={clsx(baseClasses, "atlas-cursor-default", className)}
    >
      {children}
    </DropdownMenuPrimitive.DropdownMenuLabel>
  );
}

export type DropdownMenuItemLinkProps = {
  disabled?: boolean;
  className?: string;
  onClick?: Dispatch<null>;
} & Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "onClick">;

function DropdownMenuItemLink({
  children,
  disabled,
  className,
  onClick = () => null,
  ...rest
}: PropsWithChildren<DropdownMenuItemLinkProps>) {
  return (
    <DropdownMenuItem
      disabled={disabled}
      className="focus-visible:atlas-ring-2 focus-visible:atlas-ring-offset-3 focus-visible:atlas-ring-primary-600 atlas-rounded-none"
      onKeyDown={(evt) => {
        if (evt.key === "Enter") {
          onClick(null);
        }
      }}
    >
      <a
        {...rest}
        className={clsx(
          "atlas-text-neutral-900  atlas-rounded-none atlas-p-2 atlas-text-base atlas-no-underline atlas-w-full atlas-flex atlas-items-center hover:atlas-no-underline  hover:atlas-text-neutral-900",
          className
        )}
        onClick={() => onClick(null)}
      >
        {children}
      </a>
    </DropdownMenuItem>
  );
}

DropdownMenuItem.Text = DropdownMenuItemText;
DropdownMenuItem.Link = DropdownMenuItemLink;

export default DropdownMenuItem;
