import { createNewClientCost, Validator } from "@/react/utils";
import { ClientCost } from "@shared/types/computable";
import { Nullable } from "@shared/types/utils";
import { makeAutoObservable } from "mobx";
import { CostType } from "@shared/types/costs";
import { ClientBuildPhase } from "@shared/types/buildPhase";
import { Calculations } from "@/react/components/Forms/NumberInput/types";
import { RootStore } from "../Root";

export class CurrentCostStore {
  cost: ClientCost = createNewClientCost();
  readonly root: RootStore;

  calculationOptions: Calculations[] = [];
  validator: Validator = new Validator([
    { name: "description", type: "string" },
    { name: "value", type: "number", min: 0 }
  ]);

  constructor(rootStore: RootStore) {
    this.root = rootStore;

    makeAutoObservable(this, { root: false });
  }

  validate() {
    this.validator.validate(this.cost);
  }

  resetValidity() {
    this.validator.resetAll();
  }

  setCalculationOptions(calculationOptions: Calculations[]) {
    this.calculationOptions = calculationOptions;
  }

  resetStore() {
    this.calculationOptions = [];
    this.cost = createNewClientCost();
  }

  startAddingCost(
    calculationOptions: Calculations[],
    costType: CostType,
    buildPhaseId: Nullable<ClientBuildPhase["_id"]> = null
  ) {
    if (costType === CostType.Construction && !buildPhaseId) {
      throw new Error("Construction cost's require a buildPhaseId");
    }

    this.setCalculationOptions(calculationOptions);

    this.cost = createNewClientCost({
      type: costType,
      _build_phase: buildPhaseId,
      calculationType: calculationOptions[0].type
    });

    if (buildPhaseId) {
      this.cost.position = this.root.costStore.costsForBuildPhase(buildPhaseId).length;
    } else {
      this.cost.position = this.root.costStore.costsByType(costType).length;
    }
    this.root.utilityStore.openCostModal();
    // We have to remove this synchronicity as the NumberInputs
    // all emit an update when swapping from Editing to Adding and throw off the
    // validity reset
    setTimeout(() => this.resetValidity(), 1);
  }

  updateCost(costUpdate: Partial<ClientCost>) {
    this.cost = { ...this.cost, ...costUpdate };
    this.validator.validate(costUpdate);
  }

  async submitCurrentCost() {
    this.validate();
    if (!this.validator.allValid) {
      return false;
    }
    await this.root.costStore.createCost(this.cost);
    this.root.utilityStore.closeCostModal();
    this.resetStore();
    return true;
  }

  cancelAddingCost() {
    this.root.utilityStore.closeCostModal();
    this.resetStore();
  }
}
