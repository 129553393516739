<template>
  <AppraisalSection sectionTitle="Land Acquisition" :annotationType="annotationType">
    <div class="flex-column align-items-start">
      <div class="font-weight-bold mb-2">Net Value</div>
      <div class="atlas-flex w-100 atlas-content-center atlas-justify-center atlas-gap-4">
        <DebouncedInput
          :value="AppraisalStore.purchasePrice"
          :onChange="(v) => AppraisalStore.setPurchasePrice(v)"
          prefix="£"
          label="Purchase price"
          className="atlas-w-full"
        />
        <div id="use-residual-button" class="atlas-h-9 atlas-mt-8">
          <b-button
            class="text-nowrap"
            variant="primary"
            @click="setPurchasePriceToResidual"
            :disabled="ResidualLandValueStore.hasResidualError"
          >
            Use Residual Value
          </b-button>
          <b-tooltip
            v-if="!ResidualLandValueStore.hasResidualError"
            target="use-residual-button"
            placement="bottom"
            delay="300"
            variant="primary"
          >
            <span>Update land purchase price according to residual land value</span>
          </b-tooltip>
          <b-tooltip
            v-else
            target="use-residual-button"
            placement="bottom"
            delay="300"
            variant="danger"
          >
            <span>Residual land value is currently too negative to be calculated</span>
          </b-tooltip>
        </div>

        <ComputableInput
          :computable="CostStore.deposit"
          :onChange="(v) => CostStore.updateCost(v)"
          :calculations="CalculationsStore.percentageOfLandCalculationOption"
          label="Deposit"
          :showCalculationAsLabel="true"
          class="atlas-pb-2"
        />

        <DebouncedInput
          class="atlas-pb-2"
          :value="AppraisalStore.netPurchasePrice"
          prefix="£"
          label="Net"
          :disabled="true"
        />
      </div>
      <div class="w-100 mt-3">
        <div class="font-weight-bold mb-2">Stamp Duty</div>
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex flex-wrap">
            <b-form-group class="stamp-duty-form-group">
              <b-form-radio-group
                class="w-100"
                buttons
                button-variant="radio"
                :checked="AppraisalStore.isResidential"
                @change="(v) => AppraisalStore.setIsResidential(v)"
                stacked
              >
                <b-form-radio class="sd-radio-btn" :value="true">Residential</b-form-radio>
                <b-form-radio class="sd-radio-btn" :value="false">Non-residential</b-form-radio>
              </b-form-radio-group>
            </b-form-group>

            <b-form-group v-if="!AppraisalStore.isResidential" class="stamp-duty-form-group">
              <b-form-radio-group
                class="w-100"
                buttons
                button-variant="radio"
                :checked="AppraisalStore.isFreehold"
                @change="(v) => AppraisalStore.setIsFreehold(v)"
                stacked
              >
                <b-form-radio class="sd-radio-btn" :value="true">Freehold</b-form-radio>
                <b-form-radio class="sd-radio-btn" :value="false">Leasehold</b-form-radio>
              </b-form-radio-group>
            </b-form-group>

            <b-form-group v-else class="stamp-duty-form-group">
              <b-form-radio-group
                class="w-100"
                buttons
                button-variant="radio"
                :checked="AppraisalStore.isIndividual"
                @change="(v) => AppraisalStore.setIsIndividual(v)"
                stacked
              >
                <b-form-radio class="sd-radio-btn" :value="false">Company</b-form-radio>
                <b-form-radio class="sd-radio-btn" :value="true">Individual</b-form-radio>
              </b-form-radio-group>
            </b-form-group>

            <b-form-group
              v-if="AppraisalStore.isResidential && AppraisalStore.isIndividual"
              class="stamp-duty-form-group"
            >
              <b-form-radio-group
                class="w-100"
                buttons
                button-variant="radio"
                :checked="AppraisalStore.isAdditional"
                @change="(v) => AppraisalStore.setIsAdditional(v)"
                stacked
              >
                <b-form-radio class="sd-radio-btn" :value="false">Main Property</b-form-radio>
                <b-form-radio class="sd-radio-btn" :value="true">Additional Property</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </div>
          <DebouncedInput
            :value="CostStore.stampDuty.value"
            :onChange="
              (value) => {
                if (CostStore.stampDuty) {
                  CostStore.updateCost({ ...CostStore.stampDuty, value, calculate: false });
                }
              }
            "
            prefix="£"
            label="Amount"
          />
        </div>
      </div>
    </div>
    <div class="font-weight-bold mt-3 mb-2">Land Fees</div>
    <CostList
      costType="land-fee"
      :calculationOptions="CalculationsStore.percentageOfLandCalculationOption"
      :costs="CostStore.landFees"
    />
  </AppraisalSection>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import AppraisalSection from "@/components/AppraisalSection.vue";
import EventLoggerMixin from "@/mixins/event_logger_mixin";
import { AppraisalAnnotationType } from "@shared/types/appraisalAnnotation";
import { Observer } from "mobx-vue";
import { CostList } from "@/react/domains/appraisal/Costs/CostList/CostList";
import { rootStore } from "@/react/lib/persistence/root_store";
import { ComputableInput, CostInput, DebouncedInput } from "@/react/components";

@Observer
@Component({
  components: {
    AppraisalSection,
    ComputableInput: ComputableInput as Vue.Component,
    CostList: CostList as Vue.Component,
    CostInput: CostInput as Vue.Component,
    DebouncedInput: DebouncedInput as Vue.Component
  }
})
export default class LandAcquisition extends Mixins(EventLoggerMixin) {
  public ResidualLandValueStore = rootStore.residualLandValueStore;
  public AppraisalStore = rootStore.appraisalStore;
  public CostStore = rootStore.costStore;
  public CalculationsStore = rootStore.calculationsStore;

  get annotationType(): string {
    return AppraisalAnnotationType[AppraisalAnnotationType.landAcquisition];
  }

  public setPurchasePriceToResidual() {
    this.AppraisalStore.setPurchasePrice(rootStore.residualLandValueStore.residualPurchasePrice);
    this.logEvent("Residual Land Value Used", {
      metric: this.ResidualLandValueStore.residualLandCalculation ?? undefined,
      target: this.ResidualLandValueStore.residualLandTarget
    });
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/landtech-colors";

.land-purchase {
  & > :nth-child(1) {
    flex: 1 1 350px;
    margin-right: 1rem;
  }
  & > :nth-child(2) {
    flex: 1 1 250px;
    margin-right: 1rem;
  }
  & > :nth-child(3) {
    flex: 0 1 150px;
  }
}

.stamp-duty-form-group {
  width: 180px;
  margin-right: 1rem;

  .sd-radio-btn {
    color: $neutral-800;
    background-color: $neutral-white;
    font-weight: normal;
    border: 1px solid rgba($black, 0.125);
    padding: 0.75rem;
    white-space: nowrap;

    &:hover {
      color: $neutral-800;
      border-color: $ui-interaction-hover;
    }

    &.active {
      color: $ui-interaction-primary;
      background-color: $info-2;
      border-color: $ui-interaction-primary;
      font-weight: bold;

      &:hover {
        border-color: $ui-interaction-hover;
      }
    }
  }
}
.stamp-duty-number-input {
  width: 180px;
}
</style>
