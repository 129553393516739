import React, { ButtonHTMLAttributes, PropsWithChildren, forwardRef } from "react";
import clsx from "clsx";
import "./Buttons.css";

export enum ButtonThemes {
  Primary = "primary",
  Secondary = "secondary",
  Blue = "blue",
  Danger = "danger",
  Borderless = "borderless",
  Outline = "outline",
  OutlinePrimary = "outline-primary"
}

export enum ButtonVariants {
  Default = "normal",
  SQ = "square",
  Circle = "circle"
}

export enum ButtonSizes {
  XXS = "xx-small",
  XS = "x-small",
  SM = "small",
  MD = "medium",
  LG = "large"
}

export type ButtonSize = ButtonSizes | undefined;

export type ButtonProps = PropsWithChildren<
  {
    size?: ButtonSize;
    className?: string;
    theme?: ButtonThemes;
    variant?: ButtonVariants;
    href?: string;
    target?: "_blank" | "_self" | "_parent" | "_top";
  } & ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement>
>;

const Base = forwardRef<any, ButtonProps>(
  (
    {
      href,
      children,
      className,
      size = ButtonSizes.MD,
      variant = ButtonVariants.Default,
      theme = ButtonThemes.Primary,
      ...rest
    },
    ref
  ) => {
    const classes = clsx(
      {
        "atlas-btn": variant !== ButtonVariants.Circle,
        "atlas-rounded-full": variant === ButtonVariants.Circle,
        ["atlas-btn-xs"]: size === "x-small",
        ["atlas-btn-sm"]: size === "small",
        ["atlas-btn-md"]: size === "medium",
        ["atlas-btn-lg"]: size === "large"
      },
      `atlas-btn-${theme}`,
      `atlas-btn-${variant}`,
      className
    );

    if (href) {
      return (
        <a ref={ref} href={href} className={classes} {...rest}>
          {children}
        </a>
      );
    }

    return (
      <button ref={ref} className={classes} {...rest}>
        {children}
      </button>
    );
  }
);

export const Button = Base;
