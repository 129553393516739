import ExcelJS, { RichText } from "exceljs";

export const exportToExcel = async (
  tableElement: HTMLTableElement,
  ungearedIRR: number,
  gearedIRR: number
): Promise<Blob> => {
  const workbook = await createExcelWorkbook(tableElement, ungearedIRR, gearedIRR);
  const data = await workbook.xlsx.writeBuffer();
  return new Blob([data], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  });
};

export const createExcelWorkbook = async (
  tableElement: HTMLTableElement,
  ungearedIRR: number,
  gearedIRR: number
) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("Cashflow", {
    properties: { defaultColWidth: 14 }
  });

  await addLogo(sheet);

  Array.from(tableElement.getElementsByTagName("tr")).forEach((rowElement, rowIndex) => {
    const excelRow = sheet.getRow(rowIndex + 3);
    setRowStyling(excelRow, rowElement);
    setRowCells(excelRow, rowElement);
  });

  sheet.getColumn(1).width = 40;
  sheet.getColumn(1).alignment = { horizontal: "left" };

  // If at least one of the IRRs is usable
  if (!isNaN(ungearedIRR) || !isNaN(gearedIRR)) {
    addIRRs(sheet, ungearedIRR, gearedIRR);
  }

  return workbook;
};

const addLogo = async (sheet: ExcelJS.Worksheet) => {
  const logo = require("@/assets/img/landfund-logo-wide-blue.png");
  if (logo) {
    const response = await fetch(logo);
    const blob = await response.blob();
    const base64Image = await toBase64(blob);
    const logoImageId = sheet.workbook.addImage({
      base64: base64Image as string,
      extension: "png"
    });
    sheet.getRow(1).height = 60;
    sheet.addImage(logoImageId, {
      tl: { col: 0.2, row: 0.4 },
      ext: { width: 250, height: 46 }
    });
  }
};

const addIRRs = (sheet: ExcelJS.Worksheet, ungearedIRR: number, gearedIRR: number) => {
  let richText: RichText[] = [];

  if (!isNaN(ungearedIRR)) {
    richText = [...generateRichText("Ungeared IRR: ", ungearedIRR)];
  }

  if (!isNaN(ungearedIRR) && !isNaN(gearedIRR)) {
    richText = [...richText, ...generateRichText("    ")];
  }

  if (!isNaN(gearedIRR)) {
    richText = [...richText, ...generateRichText("Geared IRR: ", gearedIRR)];
  }

  sheet.mergeCells("B1:E1");
  sheet.getCell("B1").value = { richText };
  sheet.getCell("B1").alignment = { horizontal: "center", vertical: "middle" };
};

const generateRichText = (text: string, value?: number) => {
  const richText = [];
  richText.push({
    text,
    font: { bold: true }
  });
  if (value !== undefined) {
    richText.push({
      text: `${value}%`,
      font: { bold: false }
    });
  }
  return richText;
};

const toBase64 = (blob: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const setRowStyling = (excelRow: ExcelJS.Row, rowElement: Element) => {
  const isSectionHeader = rowElement.classList.contains("heading");
  const isTotalRow = rowElement.classList.contains("total-row");

  excelRow.alignment = { horizontal: "right" };
  excelRow.font = {
    name: "Calibri",
    bold: isTotalRow || isSectionHeader
  };

  if (isSectionHeader) {
    excelRow.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "dbe9fa" }
    };
    excelRow.height = 24;
    excelRow.font.size = 12;
  }

  if (isTotalRow) {
    excelRow.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFF8F8F7" }
    };
  }
};

const setRowCells = (excelRow: ExcelJS.Row, rowElement: Element) => {
  const setBorder = ["cashflow-header", "heading", "total-row"].some((className) =>
    rowElement.classList.contains(className)
  );

  const selector = `th, td`;
  const columnsToSkip = [2, 3];
  Array.from(rowElement.querySelectorAll(selector))
    .filter((_, columnIndex) => !columnsToSkip.includes(columnIndex))
    .forEach((cellElement, columnIndex) => {
      const excelCell = excelRow.getCell(columnIndex + 1);

      if (setBorder) {
        excelCell.border = {
          top: { style: "thin", color: { argb: "00000000" } },
          bottom: { style: "thin", color: { argb: "00000000" } }
        };
      }

      setCellValueAndFormatting(excelCell, cellElement);
      setCellFont(excelCell, cellElement);
      setCellValidation(excelCell, cellElement);
    });
};

const setCellValueAndFormatting = (excelCell: ExcelJS.Cell, cellElement: Element) => {
  let value: string | number = "";
  const inputInCell = cellElement.querySelector("input");
  if (inputInCell) {
    value = inputInCell.value;
  } else {
    value = cellElement.textContent?.trim() || "";
  }
  value = value.replace(/[,]/g, "");

  let setCurrencyFormatting = false;
  if (value.startsWith("(") && value.endsWith(")")) {
    value = value.replace(/[£()]/g, "");
    value = parseFloat(value) * -1;
    setCurrencyFormatting = true;
  } else if ((inputInCell && value) || value.startsWith("£")) {
    value = value.replace(/[£()]/g, "");
    value = parseFloat(value);
    setCurrencyFormatting = true;
  }

  excelCell.value = value;

  if (setCurrencyFormatting) {
    excelCell.numFmt = "£* #,##0;(£* #,##0)";
  }
};

const setCellFont = (excelCell: ExcelJS.Cell, cellElement: Element) => {
  const isLoanOutput = cellElement.classList.contains("loan-output-cell");
  const excelRow = excelCell.worksheet.getRow(excelCell.fullAddress.row);
  if (isLoanOutput && excelCell.fullAddress.col > 2 && excelCell.value === 0) {
    excelCell.font = { ...excelRow.font, color: { argb: "FF808080" } };
  } else {
    excelCell.font = { ...excelRow.font, color: { argb: "FF000000" } };
  }
};

const setCellValidation = (excelCell: ExcelJS.Cell, cellElement: Element) => {
  if (cellElement.classList.contains("is-warning")) {
    excelCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFFDE9D9" }
    };
  } else if (cellElement.classList.contains("is-invalid")) {
    excelCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFF2DCDB" }
    };
  }
};
