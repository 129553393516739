<template>
  <b-modal
    modal-class="development-modal"
    :visible="utilityStore.developmentModalOpen"
    dialog-class="development-modal-dialog"
    content-class="development-modal-content"
    body-class="development-modal-body"
    hide-header
    hide-footer
    size="xxl"
    @hidden="closeModal"
  >
    <b-container class="development-modal-container" data-intercom-target="development_modal">
      <b-row no-gutters>
        <b-col md="8" sm="7" class="pr-3">
          <div class="p-3">
            <b-input-group
              class="mb-2 p-0 border-dashed hide-border"
              @mouseover="titleInputIsHover = true"
              @mouseleave="titleInputIsHover = false"
            >
              <b-input
                class="development-title-input border-0 bg-transparent"
                :value="currentDevelopmentStore.title"
                :placeholder="titleInputIsActive ? '' : 'Untitled Development'"
                @input="(value) => currentDevelopmentStore.setDevelopmentUpdate({ title: value })"
                @focus="titleInputIsActive = true"
                @blur="titleInputIsActive = false"
                ref="titleInput"
              />
              <b-input-group-append
                v-if="!titleInputIsActive && titleInputIsHover"
                @click="titleInput.focus()"
              >
                <b-input-group-text class="border-0 bg-transparent">
                  <b-icon icon="pencil" />
                </b-input-group-text>
              </b-input-group-append>
            </b-input-group>

            <h5>Description</h5>
            <b-textarea
              class="mb-3"
              :maxlength="200"
              placeholder="Add a description"
              :value="currentDevelopmentStore.description"
              rows="2"
              max-rows="8"
              @input="
                (value) => currentDevelopmentStore.setDevelopmentUpdate({ description: value })
              "
            />

            <div class="d-flex align-items-center justify-content-between mb-3 pt-3">
              <h5 class="mb-0">Appraisals</h5>
              <b-button
                variant="primary"
                size="sm"
                @click.stop="$bvModal.show('createNewDialog-appraisal')"
                data-intercom-target="add_appraisal_button"
              >
                <span>Add Appraisal</span>
              </b-button>
            </div>
            <b-tabs content-class="mt-3" nav-class="appraisal-tabs">
              <b-tab
                v-for="appraisalTab in miniAppraisalStore.appraisalTabs"
                :key="appraisalTab.title"
                :title="`${appraisalTab.title} (${appraisalTab.appraisals.length})`"
                :active="appraisalTab.title == 'Active'"
              >
                <div>
                  <b-table-simple
                    hover
                    primary-key="_id"
                    table-variant="white"
                    selected-variant="primary"
                    :class="[`shadow-sm`, { 'mb-0': appraisalTab.appraisals.length === 0 }]"
                    fixed
                    data-intercom-target="appraisals_table"
                  >
                    <b-thead head-variant="white">
                      <b-tr>
                        <b-th>Title</b-th>
                        <b-th colspan="2">Description</b-th>
                        <b-th></b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr
                        class="appraisal-row cursor-pointer"
                        v-for="appraisal in appraisalTab.appraisals"
                        :key="appraisal._id"
                        @click.prevent="gotoAppraisal(appraisal._id)"
                        data-intercom-target="appraisal_row"
                      >
                        <b-td v-if="appraisal.title">{{ appraisal.title }}</b-td>
                        <b-td v-else class="font-italic text-muted">Untitled Appraisal</b-td>
                        <b-td colspan="2">
                          <div class="text-overflow-ellipsis">
                            <div :id="'appraisal-desc-' + appraisal._id">
                              {{ appraisal.description }}
                            </div>
                            <b-tooltip
                              :target="'appraisal-desc-' + appraisal._id"
                              :title="appraisal.description"
                            />
                          </div>
                        </b-td>
                        <b-td>
                          <div class="text-right">
                            <OptionsMenu
                              v-if="appraisalTab.title === 'Active'"
                              class="pull-right"
                              :objectId="appraisal._id"
                              objectType="Appraisal"
                              @delete="
                                (id) => {
                                  miniAppraisalStore.deleteAppraisal(id);
                                  logEvent('Appraisal Deleted', { appraisal_id: id });
                                }
                              "
                              @duplicate="
                                (id) => {
                                  miniAppraisalStore.duplicateAppraisal(id);
                                  logEvent('Appraisal Duplicated', {
                                    old_appraisal_id: id,
                                    development_id: currentDevelopmentStore.development
                                      ? currentDevelopmentStore.development._id
                                      : ''
                                  });
                                }
                              "
                              @template="
                                (id) => {
                                  templateStore.templateAppraisal(id);
                                  logEvent('Appraisal Templated', {
                                    appraisal_id: id
                                  });
                                }
                              "
                              @archive="
                                (id) => {
                                  miniAppraisalStore.archiveAppraisal(id);
                                  logEvent('Appraisal Archived', { appraisal_id: id });
                                }
                              "
                              :setPopperOpts="false"
                              width="100px"
                            />
                            <OptionsMenu
                              class="pull-right"
                              :objectId="appraisal._id"
                              objectType="Appraisal"
                              @delete="
                                (id) => {
                                  miniAppraisalStore.deleteAppraisal(id);
                                  logEvent('Appraisal Deleted', { appraisal_id: id });
                                }
                              "
                              @duplicate="
                                (id) => {
                                  miniAppraisalStore.duplicateAppraisal(id);
                                  logEvent('Appraisal Duplicated', {
                                    old_appraisal_id: id,
                                    development_id: currentDevelopmentStore.development
                                      ? currentDevelopmentStore.development._id
                                      : ''
                                  });
                                }
                              "
                              @template="
                                (id) => {
                                  templateStore.templateAppraisal(id);
                                  logEvent('Appraisal Templated', {
                                    appraisal_id: id
                                  });
                                }
                              "
                              @restore="
                                (id) => {
                                  miniAppraisalStore.restoreAppraisal(id);
                                  logEvent('Appraisal Restored', { appraisal_id: id });
                                }
                              "
                              :setPopperOpts="false"
                              width="100px"
                              v-else
                            />
                          </div>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                  <div v-if="miniAppraisalStore.isLoading">
                    <b-progress :value="100" variant="primary" :animated="true" class="mt-3" />
                  </div>
                  <div
                    v-else-if="!appraisalTab.appraisals.length"
                    class="atlas-bg-white atlas-px-3 atlas-pb-2 shadow-sm"
                  >
                    {{ appraisalTab.appraisals.length }} {{ appraisalTab.title }} Appraisals
                  </div>
                </div>
              </b-tab>
            </b-tabs>

            <h5 class="mt-3 pt-3">Notes</h5>
            <b-textarea
              :maxlength="800"
              placeholder="Add a note"
              :value="currentDevelopmentStore.notes"
              rows="2"
              max-rows="8"
              @input="(value) => currentDevelopmentStore.setDevelopmentUpdate({ notes: value })"
            />
          </div>
        </b-col>
        <b-col class="development-modal-sidebar">
          <div class="d-flex align-items-start justify-content-between">
            <h5 class="mt-1 pt-4 pb-2 px-3">Site Details</h5>
            <b-icon icon="lt-close" @click="closeModal" class="close-btn" />
          </div>
          <div class="px-3 mb-3">
            <SiteLinkSection />
            <SiteDetailsSection
              v-if="
                hasLandInsightAccess(userStore.user) &&
                currentDevelopmentStore.development &&
                currentDevelopmentStore.site
              "
            />
          </div>
        </b-col>
      </b-row>
    </b-container>
    <CreateNewDialog @close="$bvModal.hide('createNewDialog-appraisal')" />
  </b-modal>
</template>

<script lang="ts">
import { Component, Mixins, Ref } from "vue-property-decorator";
import OptionsMenu from "@/components/OptionsMenu.vue";
import { hasLandInsightAccess } from "@shared/utils/authorisation";
import CreateNewDialog from "@/components/CreateNewDialog.vue";
import SiteLinkSection from "@/components/SiteLinkSection.vue";
import SiteDetailsSection from "@/components/SiteDetailsSection.vue";
import EventLoggerMixin from "@/mixins/event_logger_mixin";
import { BFormInput } from "bootstrap-vue";
import { rootStore } from "@/react/lib/persistence/root_store";
import { Observer } from "mobx-vue";

@Observer
@Component({
  components: {
    OptionsMenu,
    CreateNewDialog,
    SiteLinkSection,
    SiteDetailsSection
  }
})
export default class DevelopmentModal extends Mixins(EventLoggerMixin) {
  @Ref() readonly titleInput!: BFormInput;

  public hasLandInsightAccess = hasLandInsightAccess;
  public currentDevelopmentStore = rootStore.currentDevelopmentStore;
  public utilityStore = rootStore.utilityStore;
  public miniAppraisalStore = rootStore.miniAppraisalStore;
  public templateStore = rootStore.templateStore;
  public userStore = rootStore.userStore;

  public titleInputIsActive: boolean = false;
  public titleInputIsHover: boolean = false;

  public gotoAppraisal(appraisalId: string) {
    this.$router.push(
      `/developments/${this.currentDevelopmentStore.development?._id}/${appraisalId}`
    );
    this.currentDevelopmentStore.closeModal();
  }

  public closeModal() {
    this.$emit("close");
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/landtech-colors";

.development-modal {
  @media (min-width: 576px) {
    .development-modal-dialog {
      max-width: 750px;
    }
    .development-modal-container {
      max-width: none;
    }
    .development-modal-sidebar {
      width: 35% !important;
    }
  }
  @media (min-width: 768px) {
    .development-modal-dialog {
      max-width: 750px;
    }
    .development-modal-sidebar {
      width: 25% !important;
    }
  }
  @media (min-width: 992px) {
    .development-modal-dialog {
      max-width: 900px;
    }
  }
  @media (min-width: 1200px) {
    .development-modal-dialog {
      max-width: 1140px;
    }
  }

  .development-modal-content {
    background-color: $depth-L97;
  }
  .development-modal-body {
    padding: 0;
  }
  .development-modal-container {
    padding-right: 0;
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/scss/landtech-colors";
@import "@/assets/scss/bootstrap/bs-overrides";

h5 {
  font-weight: bold;
}

.development-title-input {
  font-size: 1.5rem;
  font-weight: bold;
}
.description {
  color: $neutral-700;
  font-size: 14px;
}
.sub-heading {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}
.development-modal-sidebar {
  right: 0;
  top: 0;
  bottom: 0;
  width: 25% !important;
  background-color: white;
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;

  .close-btn {
    cursor: pointer;
    font-size: 52px;
  }
}

.hide-border {
  border-color: $body-bg;
}

::v-deep .appraisal-tabs {
  border-bottom: 1px solid #dee2e6;
  .nav-link {
    background: #fff;
    border-radius: 0px;
    border: 0px none;
    border-bottom: 3px solid #fff;
    margin-right: 10px;
    margin-bottom: 0px;
    color: #012552;
    padding: 4px;

    &.active {
      &,
      &:hover {
        font-weight: bold;
        border-bottom-color: #54ccfa;
      }
    }
    &.active,
    &:hover {
      color: #012552;
    }
    &:hover {
      border-bottom-color: #dee2e6;
    }
  }
}
</style>
