import React, { FC } from "react";
import { AppraisalSection } from "../AppraisalSection";
import { AppraisalAnnotationType } from "@shared/types/appraisalAnnotation";
import { useTranslation } from "@/react/lib/hooks";
import { rootStore } from "@/react/lib/persistence/root_store";
import { observer } from "mobx-react-lite";
import { ClientUnitGroup } from "@shared/types/unitGroup";
import { Button, ButtonSizes, Draggable } from "@/react/components";
import { SortableContext } from "@dnd-kit/sortable";
import { reorderDraggable } from "@/react/utils/dragging";
import { Accordion } from "@/react/components/Accordion";
import { IncomeAccordionItem, UnitGroupModal } from "@/react/domains/appraisal";
import { IncomeTranslations } from "@/react/lib/i18n/locales";

const IncomeSection: FC = () => <IncomeSectionObserved />;

const IncomeSectionObserved: FC = observer(() => {
  const { t } = useTranslation();
  const unitGroups = rootStore.unitGroupStore.orderedUnitGroups;
  return (
    <>
      <AppraisalSection
        type={AppraisalAnnotationType.income}
        title={t(IncomeTranslations.Income)}
        testId="income-section"
      >
        {!!unitGroups.length && (
          <Draggable
            onDragEnd={reorderDraggable(unitGroups, (val: ClientUnitGroup[]) =>
              rootStore.unitGroupStore.reorderUnitGroups(val)
            )}
          >
            <SortableContext items={unitGroups.map((unitGroup) => unitGroup._id)}>
              <Accordion className="atlas-pb-2">
                {unitGroups.map((unitGroup) => (
                  <IncomeAccordionItem unitGroup={unitGroup} key={unitGroup._id} />
                ))}
              </Accordion>
            </SortableContext>
          </Draggable>
        )}
        {!unitGroups.length && (
          <div>
            <p>{t(IncomeTranslations.NoUnitGroups)}</p>
          </div>
        )}
        <div className="atlas-mt-3 atlas-flex atlas-align-center atlas-justify-center">
          <Button
            size={ButtonSizes.LG}
            onClick={() => rootStore.currentUnitGroupStore.startAddingUnitGroup()}
          >
            {t(IncomeTranslations.AddUnitGroup)}
          </Button>
        </div>
      </AppraisalSection>
      <UnitGroupModal />
    </>
  );
});

export { IncomeSection };
