import React, { FC, PropsWithChildren } from "react";
import * as TabsPrimitive from "@radix-ui/react-tabs";

interface TabsContentProps {
  value: string;
  className?: string;
}

const TabsContent: FC<PropsWithChildren<TabsContentProps>> = ({ value, className = "", children }) => {
  return (
    <TabsPrimitive.Content
      className={className}
      value={value}
      data-testid={"tabs-content"}
    >
      {children}
    </TabsPrimitive.Content>
  );
};

export { TabsContent };
