<template>
  <div class="bg-white">
    <b-skeleton-wrapper :loading="currentDevelopmentStore.isSiteLoading" class="row no-gutters">
      <template #loading>
        <div class="w-100 mt-1">
          <b-skeleton height="21px" class="my-2" v-for="x in 7" :key="x" />
          <div class="w-100 mt-3 pt-1 d-sm-none d-lg-block">
            <b-skeleton-img no-aspect height="212px" width="100%" />
          </div>
          <div class="w-100 mt-3 pt-1 d-sm-block d-lg-none">
            <b-skeleton-img no-aspect height="423px" width="100%" />
          </div>
        </div>
      </template>

      <div
        v-if="currentDevelopmentStore.site && !currentDevelopmentStore.isSiteLoading"
        class="my-3"
      >
        <div class="site-details">
          <NumberOutput
            class="mt-2 text-output"
            label="Area"
            :value="currentDevelopmentStore.siteArea"
            :suffix="userStore.majorAreaUnitDisplay"
            :decimals="2"
          />
          <div class="text-output">
            <label>Local Authority</label>
            <label>{{ currentDevelopmentStore.site.local_authority }}</label>
          </div>
          <div class="text-output">
            <label>Current Use Classes</label>
            <label>{{ (currentDevelopmentStore.site.use_classes || []).join(", ") }}</label>
          </div>
          <div class="text-output">
            <label>Use Classes Until August 2020</label>
            <label>{{
              (currentDevelopmentStore.site.use_classes_sep_2020 || []).join(", ")
            }}</label>
          </div>

          <template v-if="siteConstraintStore.siteConstraints != null">
            <div class="text-output">
              <label>Protected Land</label>
              <label :class="siteConstraintStore.hasProtectedLandRisk ? 'high-risk' : 'no-risk'">
                {{ siteConstraintStore.hasProtectedLandRisk ? "Risk" : "No risk" }}
              </label>
            </div>
            <div class="text-output">
              <label>Flood Area</label>
              <label :class="siteConstraintStore.floodRisk.class">{{
                siteConstraintStore.floodRisk.title
              }}</label>
            </div>
            <div class="text-output">
              <label>Nearby Listed Buildings</label>
              <label
                :class="siteConstraintStore.hasNearbyListedBuildings ? 'high-risk' : 'no-risk'"
              >
                {{ siteConstraintStore.hasNearbyListedBuildings ? "Yes" : "No" }}
              </label>
            </div>
          </template>
        </div>
        <div class="flex-row p-0 mt-3 col-12">
          <google-map
            class="m-auto w-100"
            :style="siteConstraintStore.mapDimensionStyle"
            :zoom="currentDevelopmentStore.siteZoomLevel"
            :center="currentDevelopmentStore.siteCentroid"
          >
            <mapped-sites
              v-if="currentDevelopmentStore.sitesGeo"
              slot-scope="mapProps"
              :map="mapProps.map"
              :sites-geo="currentDevelopmentStore.sitesGeo"
              :clickable="false"
            />
          </google-map>
        </div>
      </div>
    </b-skeleton-wrapper>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import GoogleMap from "@/map/google_map.vue";
import MappedSites from "@/map/sites.vue";
import NumberOutput from "@/components/NumberOutput.vue";
import { rootStore } from "@/react/lib/persistence/root_store";
import { Observer } from "mobx-vue";

@Observer
@Component({
  components: {
    NumberOutput,
    GoogleMap,
    MappedSites
  }
})
export default class SiteDetailsSection extends Vue {
  public currentDevelopmentStore = rootStore.currentDevelopmentStore;
  public userStore = rootStore.userStore;
  public siteConstraintStore = rootStore.siteConstraintStore;
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/landtech-colors";
.site-details {
  font-size: 14px;
}
.text-output {
  display: flex;
  justify-content: space-between;
}
.no-risk {
  color: $landfund-no-risk-text;
}
.medium-risk {
  color: $landfund-medium-risk-text;
}
.high-risk {
  color: $landfund-high-risk-text;
}
</style>
