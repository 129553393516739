import { Draggable } from "@/react/components";
import { DragEndEvent, DragStartEvent } from "@dnd-kit/core";
import { SortableContext, arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable";
import clsx from "clsx";
import React, { Dispatch, useMemo, useRef, useState } from "react";
import { TableOptions, useTable } from "react-table";
import { DraggableTableRow, StaticTableRow } from "./components";

export function Table<Opts extends { _id: string; draggable: boolean }>({
  columns,
  data,
  setData
}: { setData: Dispatch<(d: Opts[]) => Opts[]> } & TableOptions<Opts>) {
  const [activeId, setActiveId] = useState<null | number | string>();
  const items = useMemo<string[]>(() => data?.map(({ _id }) => _id), [data]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, footerGroups } =
    useTable({
      columns,
      data
    });

  const tableRowRef = useRef(null);

  function handleDragStart(event: DragStartEvent) {
    setActiveId(event.active.id);
  }
  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (active.id !== over?.id) {
      setData((d: Opts[]) => {
        const oldIndex = items.indexOf(String(active.id));
        const newIndex = items.indexOf(String(over?.id));
        return arrayMove(d, oldIndex, newIndex);
      });
    }
    setActiveId(null);
  }

  function handleDragCancel() {
    setActiveId(null);
  }

  const selectedRow = useMemo(() => {
    if (!activeId) {
      return null;
    }
    const row = rows.find(({ original }) => original._id === activeId);
    if (row) {
      prepareRow(row);
    }
    return row;
  }, [activeId, rows, prepareRow]);

  const tdClasses = "atlas-px-3 atlas-py-2";

  function alignmentClasses(index: number, headersLength: number): Array<Record<string, boolean>> {
    return [
      { "atlas-pl-9": index === 0 },
      { "atlas-text-left": index > 0 && index < headersLength - 2 },
      { "atlas-text-right": index >= headersLength - 2 }
    ];
  }
  function isTheLastColumn(index: number, headersLength: number) {
    return (index === 4 && headersLength === 5) || (index === 5 && headersLength === 6);
  }

  function isThePpsColumn(index: number, headersLength: number) {
    return index === 3 && headersLength === 6;
  }

  return (
    <div className="atlas-w-full atlas-overflow-x-auto">
      <Draggable
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
        onDragStart={handleDragStart}
      >
        <table {...getTableProps()} className="atlas-w-full atlas-border-collapse">
          <thead>
            {headerGroups.map(
              (headerGroup) =>
                headerGroup.headers && (
                  <tr {...headerGroup.getHeaderGroupProps()} ref={tableRowRef}>
                    {headerGroup.headers.map((column, index) => (
                      <th
                        {...column.getHeaderProps()}
                        className={clsx(
                          "atlas-bg-neutral-100 atlas-text-neutral-8300 atlas-border-0 atlas-border-b-2 atlas-border-solid atlas-border-neutral-300",
                          "empty:atlas-hidden",
                          {
                            "atlas-w-4/12": index === 0 && headerGroup.headers.length < 6
                          },
                          { "atlas-w-2/12": index === 1 && headerGroup.headers.length >= 6 },
                          { "atlas-w-1/10": isThePpsColumn(index, headerGroup.headers.length) },
                          { "atlas-w-16": isTheLastColumn(index, headerGroup.headers.length) },
                          tdClasses,
                          ...alignmentClasses(index, headerGroup.headers.length)
                        )}
                        role="columnheader"
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                )
            )}
          </thead>
          <tbody {...getTableBodyProps()}>
            <SortableContext items={items} strategy={verticalListSortingStrategy}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <DraggableTableRow<Opts>
                    key={row.original._id}
                    row={row}
                    tdClassName={tdClasses}
                  />
                );
              })}
            </SortableContext>
          </tbody>
          <tfoot>
            {!!footerGroups &&
              footerGroups.map((group) => (
                <tr {...group.getFooterGroupProps()}>
                  {group.headers.map((column, index) => (
                    <td
                      {...column.getFooterProps()}
                      className={clsx(
                        tdClasses,
                        "atlas-border-0 atlas-border-t-2 atlas-border-solid atlas-border-neutral-300",
                        "empty:atlas-hidden",
                        ...alignmentClasses(index, group.headers.length)
                      )}
                    >
                      <>{column.render("Footer")} </>
                    </td>
                  ))}
                </tr>
              ))}
          </tfoot>
        </table>
        <Draggable.Overlay>
          {activeId && (
            <StaticTableRow row={selectedRow} tdClassName={tdClasses} parent={tableRowRef} />
          )}
        </Draggable.Overlay>
      </Draggable>
    </div>
  );
}
