<template>
  <div class="number-input">
    <b-form-group :invalid-feedback="invalidFeedback" label-for="label" :state="state">
      <template #label>
        <b-form-input
          v-if="labelEditable"
          ref="label"
          v-model="labelValue"
          :required="!!required"
          class="input-box border-dashed"
          :disabled="disabled"
        />
        <div v-else>{{ label }}</div>
      </template>
      <b-input-group :prepend="prefix" :disabled="disabled" :append="suffix">
        <template #prepend v-if="prefix">
          <b-input-group-text :class="inputDisabledClass">{{ prefix }}</b-input-group-text>
        </template>
        <b-form-input
          ref="input"
          v-model="inputValue"
          :required="!!required"
          :disabled="inputDisabled"
          @focus="valueInputIsActive = true"
          @blur="valueInputIsActive = false"
          class="input-box"
          :state="state"
          :class="{ 'border-left-0': prefix, 'border-right-0': suffix }"
          :formatter="formatInput"
        />
        <template #append v-if="suffix">
          <b-input-group-text :class="inputDisabledClass">{{ suffix }}</b-input-group-text>
        </template>
      </b-input-group>
    </b-form-group>

    <template v-if="calculationOptions.length && useCalculationBase">
      <b-form-group
        class="calculation-base-field"
        :label="currentCalculation.label"
        label-class="text-nowrap"
      >
        <b-input-group :prepend="currentCalculation.prefix" :append="currentCalculation.suffix">
          <b-form-input
            v-model="calculationBaseInputValue"
            @focus="calculationBaseInputIsActive = true"
            @blur="calculationBaseInputIsActive = false"
            data-testid="calculation_options_input"
            class="input-box"
            :disabled="disabled"
            :class="{
              'border-left-0': currentCalculation.prefix,
              'border-right-0': currentCalculation.suffix || calculationOptions.length > 1
            }"
            :formatter="formatCalcInput"
          />
          <template #append>
            <b-input-group-text
              v-if="currentCalculation.suffix"
              :class="{ disabled, 'border-right-0': calculationOptions.length > 1 }"
            >
              {{ currentCalculation.suffix }}
            </b-input-group-text>
            <b-dropdown
              v-if="calculationOptions.length > 1"
              variant="white"
              class="calculation-dropdown"
              data-testid="CalculationOptionsSelect"
            >
              <b-dropdown-item
                v-for="option in calculationOptions"
                :key="option.type"
                :value="option.type"
                :disabled="disabled"
                @click="calculationType = option.type"
                data-testid="CalculationOptionsItem"
              >
                {{ option.label }}
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-input-group>
      </b-form-group>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch, Ref } from "vue-property-decorator";
import { formatNumber } from "@shared/utils/formatting";
import { isEqual, isFunction, isEmpty } from "lodash";
import { Nullable } from "@shared/types/utils";
import { ClientCost, ComputableCalculationType } from "@shared/types/computable";

@Component({})
export default class NumberInput extends Vue {
  @Prop() readonly field!: Partial<ClientCost> & { value: number };
  @Prop() readonly label!: string;
  @Prop({ default: 0 }) readonly decimals!: number;
  @Prop() readonly prefix!: string;
  @Prop() readonly suffix!: string;
  @Prop({ default: false, type: Boolean }) readonly error!: boolean;
  @Prop() readonly invalidFeedback!: string;
  @Prop() readonly state!: Nullable<boolean>;
  @Prop(Boolean) readonly focus!: boolean;
  @Prop(Boolean) readonly required!: boolean;
  @Prop({ default: false, type: Boolean }) readonly disabled!: boolean;
  @Prop({ default: true, type: Boolean }) readonly useCalculationBase!: boolean;
  @Prop({ default: () => [] }) readonly calculationOptions!: any[];
  @Prop({ default: false, type: Boolean }) readonly labelEditable!: boolean;

  @Ref() readonly input!: Vue;

  private inputValue: string = "";
  private labelValue: string = "";
  private calculationBaseInputValue: any = 0;
  private valueInputIsActive: boolean = false;
  private calculationBaseInputIsActive: boolean = false;
  private calculationType: Nullable<string> = null;

  public formatValue(value: number) {
    return formatNumber({ value, maxDecimals: this.decimals });
  }

  public setFocus() {
    let el = this.input.$el as HTMLElement;
    el.focus();
  }

  public formatInput(value: string) {
    //Allow negative symbol and decimal point
    if (value === "-" || (value.slice(0, -1).includes(".") === false && value.endsWith("."))) {
      return value;
    }

    const valNoCommas = parseFloat(value.replace(/\,/g, ""));
    return this.formatValue(valNoCommas);
  }

  public formatCalcInput(value: string) {
    //Allow negative symbol and decimal point
    if (value === "-" || (value.slice(0, -1).includes(".") === false && value.endsWith("."))) {
      return value;
    }

    const valNoCommas = parseFloat(value.replace(/\,/g, ""));
    return formatNumber({ value: valNoCommas, maxDecimals: 2 });
  }

  public updateField(update: Partial<ClientCost>) {
    const fieldToUpdate = {
      ...this.field,
      ...update,
      calculationType: this.calculationType
    };
    if (this.calculationIsReady) {
      if (this.updateRequiresCalculationBaseRecalculation(update, fieldToUpdate)) {
        this.setCalculationBaseFromValue(fieldToUpdate, update);
      } else if (this.updateRequiresValueRecalculation(update, fieldToUpdate)) {
        this.setValueFromCalculationBase(fieldToUpdate, update);
      }
    } else if (!!this.calculationOptions.length) {
      // There are calculation options but the current calculation is not yet ready
      // We can therefore assume that this update is invalid
      return;
    }
    this.$emit("update", fieldToUpdate);
  }

  public getValueFromCalculation(base: number) {
    const { calculateValueFromBase } = this.currentCalculation;
    const result = isFunction(calculateValueFromBase)
      ? parseFloat(calculateValueFromBase(base).toFixed())
      : NaN;
    return result !== Infinity ? result : 0;
  }

  public getCalculationBaseFromValue(value: number) {
    const { calculateBaseFromValue } = this.currentCalculation;
    const result = isFunction(calculateBaseFromValue)
      ? parseFloat(calculateBaseFromValue(value).toFixed(2))
      : NaN;
    return result !== Infinity ? result : 0;
  }

  public updateRequiresCalculationBaseRecalculation(
    update: Partial<ClientCost>,
    fieldToUpdate: any
  ): boolean {
    return (
      (update.hasOwnProperty("value") || update.hasOwnProperty("calculationType")) &&
      !fieldToUpdate.calculate &&
      this.useCalculationBase
    );
  }

  public updateRequiresValueRecalculation(
    update: Partial<ClientCost>,
    fieldToUpdate: any
  ): boolean {
    return (
      (update.hasOwnProperty("calculationBase") || update.hasOwnProperty("calculationType")) &&
      fieldToUpdate.calculate
    );
  }

  public setCalculationBaseFromValue(fieldToUpdate: any, update: Partial<ClientCost>) {
    // If value has been updated, use that for recalculation, otherwise use the existing value
    // A lack of value in an update implies the calculationType has been updated
    const value = update.value ?? this.field.value;
    fieldToUpdate.calculationBase = this.getCalculationBaseFromValue(value || 0);
    this.calculationBaseInputValue = this.formatCalcInput(fieldToUpdate.calculationBase.toString());
  }

  public setValueFromCalculationBase(fieldToUpdate: any, update: Partial<ClientCost>) {
    // If calculationBase has been updated, use that for recalculation, otherwise use the existing calculationBase
    // A lack of calculationBase in an update implies the calculationType has been updated
    const calculationBase = update.calculationBase ?? this.field.calculationBase;
    fieldToUpdate.value = this.getValueFromCalculation(calculationBase || 0);
    this.inputValue = this.formatValue(fieldToUpdate.value);
    if (this.labelEditable) {
      fieldToUpdate.description = this.labelValue;
    }
  }

  get valueFromCalculation() {
    return this.getValueFromCalculation(this.field.calculationBase || 0);
  }

  get calculationBaseFromValue() {
    return this.getCalculationBaseFromValue(this.field.value || 0);
  }

  get currentCalculation() {
    return this.calculationOptions.find((x) => x.type === this.calculationType) || {};
  }

  get calculationIsReady() {
    return !isEmpty(this.currentCalculation);
  }

  get inputDisabledClass() {
    return { disabled: this.inputDisabled };
  }

  get inputDisabled() {
    return this.$listeners.update === undefined || this.disabled;
  }

  @Watch("field", { immediate: true, deep: true })
  fieldHandler(newValue: any, oldValue: any) {
    if (!isEqual(newValue, oldValue)) {
      const { value, calculationBase, calculationType, description } = newValue;
      this.inputValue = value ? this.formatValue(value) : "";
      if (this.labelEditable) {
        this.labelValue = description;
      }

      this.calculationBaseInputValue = calculationBase
        ? this.formatCalcInput(calculationBase.toFixed(2))
        : "";
      if (!this.calculationType) {
        const defaultCalculationType = this.calculationOptions[0]?.type || null;
        this.calculationType = calculationType || defaultCalculationType;
      }
    }
  }

  @Watch("calculationType")
  calculationTypeWatch(v: ComputableCalculationType) {
    this.updateField({
      calculationType: v
    });
  }

  @Watch("labelValue")
  labelValueWatch(v: string) {
    if (this.labelEditable) {
      this.labelValue = v;
      this.updateField({
        description: v
      });
    }
  }

  @Watch("inputValue")
  inputValueWatch(v: string) {
    const newValue = parseFloat(v.replace(/,/g, ""));
    if (isNaN(newValue)) {
      !v &&
        this.updateField({
          value: 0,
          calculate: !this.valueInputIsActive
        });
      return;
    }
    const formattedValue = this.formatValue(newValue);
    if (formattedValue !== v) {
      this.inputValue = formattedValue;
      return;
    }
    if (!this.calculationBaseInputIsActive && newValue !== this.field.value) {
      this.updateField({
        value: newValue,
        calculate: !this.valueInputIsActive
      });
    }
  }

  @Watch("calculationBaseInputValue")
  calculationBaseInputValueWatch(v: string) {
    if (!this.calculationBaseInputIsActive) {
      return;
    }
    let newValue = parseFloat(v.toString().replace(/,/g, ""));
    if (!isNaN(newValue) && newValue !== this.field.calculationBase) {
      this.updateField({
        calculate: true,
        calculationBase: newValue
      });
    } else if (isNaN(newValue)) {
      this.updateField({
        calculate: true,
        calculationBase: 0
      });
    }
  }

  @Watch("valueFromCalculation", { immediate: true })
  valueFromCalculationHandler(v: number) {
    if (this.field.calculate) {
      this.inputValue = v ? this.formatValue(v) : "";
    }
  }

  @Watch("calculationBaseFromValue", { immediate: true })
  calculationBaseFromValueHandler(v: number) {
    if (!this.field.calculate) {
      this.calculationBaseInputValue =
        v && parseFloat(v.toFixed(2)) !== Infinity ? this.formatCalcInput(v.toFixed(2)) : "";
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/landtech-colors";

.number-input {
  display: flex;
  flex-direction: row;
  width: 100%;

  .calculation-base-field {
    margin-left: 0.75rem;

    .col-form-label {
      height: 32px;
    }
  }

  label {
    white-space: nowrap;
  }

  .border-dashed {
    height: 24px;
  }

  .input-field {
    margin: 0;
    padding: 0;
    min-height: unset;

    &.input-box[disabled],
    .input-box[disabled] + .b-suffix {
      background: $neutral-50;

      color: $neutral-500 !important;
      -webkit-text-fill-color: $neutral-500 !important;
    }
  }

  .calculation-dropdown {
    height: 38px;
    border: 1px solid $gray-400;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .form-group.is-invalid {
    .input-group-prepend .input-group-text,
    .input-group-append .input-group-text {
      border-color: $error-1;
      background-color: $landfund-invalid-input-background;
    }
  }
}
</style>
