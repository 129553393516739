<template>
  <b-modal
    ref="createNewModal"
    title="Create Appraisal"
    id="createNewDialog-appraisal"
    hide-footer
    @hidden="close"
    size="md"
    centered
  >
    <div>
      <div class="button-options" v-if="!isCreatingFromTemplate">
        <b-button
          variant="primary"
          class="mr-2"
          @click="createFromTemplate()"
          :disabled="!currentDevelopmentStore.development"
        >
          <b-icon icon="grid-1x2-fill" rotate="90"></b-icon><span>Create from template</span>
        </b-button>
        <b-button
          variant="primary"
          class="ml-2"
          @click="createAppraisal"
          :disabled="!currentDevelopmentStore.development"
        >
          <b-icon icon="plus-square"></b-icon><span>Create new</span>
        </b-button>
      </div>

      <div class="template" v-if="isCreatingFromTemplate">
        <label for="templates">Select template</label>
        <b-form-select v-model="templateSelected" name="templates" class="mb-3" id="templates">
          <b-form-select-option :value="null" disabled>Select</b-form-select-option>
          <b-form-select-option
            v-for="template in templateStore.templates"
            :key="template._id"
            :value="{ ...template }"
          >
            {{ template.title }}
          </b-form-select-option>
        </b-form-select>

        <b-button
          class="m-auto"
          variant="primary"
          @click="createAppraisal"
          :disabled="!templateSelected"
        >
          Create from Template
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script lang="ts">
import { Component, Vue, Ref } from "vue-property-decorator";
import { Nullable } from "@shared/types/utils";
import { ClientMiniTemplate } from "@shared/types/appraisal";
import { BvModal } from "bootstrap-vue";
import { rootStore } from "@/react/lib/persistence/root_store";
import { Observer } from "mobx-vue";
import { logEvent } from "@/react/utils";

@Observer
@Component({})
export default class CreateNewDialog extends Vue {
  @Ref() readonly createNewModal!: BvModal;

  private templateSelected: Nullable<ClientMiniTemplate> = null;
  private isCreatingFromTemplate: boolean = false;
  private currentDevelopmentStore = rootStore.currentDevelopmentStore;
  private miniAppraisalStore = rootStore.miniAppraisalStore;
  private templateStore = rootStore.templateStore;
  private developmentsStore = rootStore.developmentsStore;

  public async createAppraisal() {
    const newAppraisal = await this.miniAppraisalStore.createAppraisal(this.templateSelected);
    logEvent("Appraisal Created", {
      development_id: this.currentDevelopmentStore.development?._id,
      from_template: !!this.templateSelected,
      linked_to_site: !!this.currentDevelopmentStore.site,
      page: "developments"
    });
    this.close();
    if (newAppraisal) {
      this.$router.push(`/developments/${newAppraisal._development}/${newAppraisal._id}`);
    }
  }

  public createFromTemplate() {
    this.isCreatingFromTemplate = true;
  }

  public close() {
    this.isCreatingFromTemplate = false;
    this.templateSelected = null;
    this.createNewModal.hide("createNewDialog-appraisal");
    this.$emit("close");
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/landtech-colors";

.button-options {
  display: flex;
  align-items: normal;
  justify-content: center;
  margin-top: 0;

  .btn {
    width: 180px;
    height: 205px;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: $neutral-white !important;
    color: $ui-interaction-primary !important;
    border: 1px solid $neutral-300;
    box-sizing: border-box;
    border-radius: 3px;
    text-transform: none;
    box-shadow: none;
    transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
      color 0.2s cubic-bezier(0.4, 0, 0.2, 1);

    .b-icon {
      font-size: 72px !important;
      color: $ui-interaction-primary !important;
      transition: color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
      height: 72px;
      width: 72px;
    }

    span {
      font-size: 14px;
      font-weight: 700;
    }
  }

  .btn:not(.disabled):hover,
  .btn:not(.disabled):active,
  .btn:not(.disabled):focus,
  .btn:not(.disabled):active:focus,
  .btn:not(.disabled):active:hover {
    box-shadow: none;
    background-color: $ui-interaction-primary !important;
    color: $neutral-white !important;

    .b-icon {
      color: $neutral-white !important;
    }
  }
}

.template {
  display: flex;
  flex-direction: column;
}
</style>
