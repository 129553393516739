import { DragEndEvent } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";

export const reorderDraggable = (
  arrayToBeReordered: Array<Partial<{ _id: string }>>,
  reorderFunction: (reorderedArray: any[]) => Promise<void>
): ((event: DragEndEvent) => Promise<void>) => {
  const handleDragEndFunction = async (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const oldIndex = arrayToBeReordered.findIndex((item) => item._id === active.id);
      const newIndex = arrayToBeReordered.findIndex((item) => item._id === over?.id);
      await reorderFunction(arrayMove(arrayToBeReordered, oldIndex, newIndex));
    }
  };
  return handleDragEndFunction;
};
