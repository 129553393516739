export default class AspectRatio {
  private ratios: { [key: string]: number };

  constructor(limit = 16) {
    const ratiosT = [];
    this.ratios = {};

    for (let ratioW = 1; ratioW <= limit; ++ratioW) {
      for (let ratioH = 1; ratioH <= limit; ++ratioH) {
        const ratioX = (ratioW * 100) / (ratioH * 100);

        if (!ratiosT[ratioX]) {
          ratiosT[ratioX] = true;

          this.ratios[ratioW + ":" + ratioH] = ratioX;
        }
      }
    }
  }

  calculateNearestRatio(width: number, height: number) {
    const ratio: number = (width * 100) / (height * 100);
    let match: string | undefined;

    for (const key in this.ratios) {
      if (!match || Math.abs(ratio - this.ratios[key]) < Math.abs(ratio - this.ratios[match])) {
        match = key;
      }
    }
    return match;
  }
}
