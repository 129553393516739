import React, { FC, PropsWithChildren, ReactNode } from "react";
import * as AccordionComponent from "@radix-ui/react-accordion";
import { Icon } from "@/react/components/Icons/Icons";
import "./Accordion.css";

export interface Props {
  className?: string;
}

export interface AccordionProps extends Props {
  onChange?: any;
  defaultValue?: string;
}

export interface AccordionHeaderProps {
  action?: ReactNode;
  handle?: ReactNode;
}

export interface AccordionItemProps extends Props {
  value: string;
}

export const AccordionBase: FC<PropsWithChildren<AccordionProps>> = ({
  className,
  children,
  defaultValue,
  onChange = () => {}
}) => {
  return (
    <AccordionComponent.Root
      className={className}
      type="single"
      defaultValue={defaultValue}
      collapsible
      onValueChange={onChange}
    >
      {children}
    </AccordionComponent.Root>
  );
};

const AccordionHeader: FC<PropsWithChildren<AccordionHeaderProps>> = ({
  children,
  action,
  handle
}) => (
  <AccordionComponent.Header className="atlas-text-base atlas-mb-0 atlas-flex atlas-align-middle ">
    {handle}
    <AccordionComponent.Trigger
      className="atlas-w-full accordion-trigger atlas-bg-white atlas-border-none atlas-mx-0 atlas-px-0"
      data-testid={"accordion-button"}
    >
      {children}
      {!children && (
        <div aria-hidden>
          <span className="accordion-arrow-down">
            <Icon.ArrowDown />
          </span>
          <span className="accordion-arrow-up">
            <Icon.ArrowUp />
          </span>
        </div>
      )}
    </AccordionComponent.Trigger>
    {action}
  </AccordionComponent.Header>
);

const AccordionBody: FC<PropsWithChildren<Props>> = ({ children }) => (
  <AccordionComponent.Content className={"accordion-content"} data-testid={"accordion-content"}>
    <div data-testid={"accordion-content-body"}>{children}</div>
  </AccordionComponent.Content>
);

const AccordionItem: FC<PropsWithChildren<AccordionItemProps>> = (props) => (
  <AccordionComponent.Item {...props} />
);

export const Accordion = Object.assign(AccordionBase, {
  Item: AccordionItem,
  Header: AccordionHeader,
  Body: AccordionBody
});
