import { InputOnChangeValueType } from "@/react/components";
import { useTranslation } from "@/react/lib/hooks";
import { AppraisalDetailsTranslations } from "@/react/lib/i18n/locales";
import { rootStore } from "@/react/lib/persistence/root_store";
import { observer } from "mobx-react-lite";
import React, { FC, useMemo } from "react";
import { AppraisalDetailsForm } from "./AppraisalDetailsForm";

const AppraisalDetails = () => <ObseveredAppraisalDetails />;

const ObseveredAppraisalDetails: FC = observer(() => {
  const { t } = useTranslation();
  const { mainTitle, untitledTitle } = useMemo(() => {
    if (rootStore.appraisalStore.isTemplate) {
      return {
        mainTitle: t(AppraisalDetailsTranslations.AppraisalDetails_TemplateDetails),
        untitledTitle: t(AppraisalDetailsTranslations.AppraisalDetails_UntitledTemplate)
      };
    } else {
      return {
        mainTitle: t(AppraisalDetailsTranslations.AppraisalDetails_AppraisalDetails),
        untitledTitle: t(AppraisalDetailsTranslations.AppraisalDetails_UntitledAppraisal)
      };
    }
  }, [rootStore.appraisalStore.isTemplate]);

  function updateTitle(val: InputOnChangeValueType) {
    return rootStore.appraisalStore.setTitle(String(val));
  }
  function updateDescription(val: string | undefined) {
    return rootStore.appraisalStore.setDescription(val);
  }

  return (
    <AppraisalDetailsForm
      title={rootStore.appraisalStore.title}
      description={rootStore.appraisalStore.description}
      updateTitle={updateTitle}
      updateDescription={updateDescription}
      displayTitle={mainTitle}
      untitledTitle={untitledTitle}
      titleLabel={t(AppraisalDetailsTranslations.AppraisalDetails_Title)}
      descriptionLabel={t(AppraisalDetailsTranslations.AppraisalDetails_Description)}
    />
  );
});

export { AppraisalDetails };
