import { IBaseComputable } from "@shared/types/computable";
import { ClientLoan, InterestCalculationType } from "@shared/types/loan";
import { ClientType } from "@shared/types/utils";
import { calculateGrossLoan } from "@shared/utils/loan";
import { CashflowLoanOutput } from "@shared/types/cashflow";

const parseCalculationObject = <T extends ClientType<IBaseComputable>>(obj: T) => {
  return {
    ...obj,
    value: Number(obj.value),
    calculationBase: Number(obj.calculationBase)
  };
};

const ensureFloatsAreParsed = (loans: ClientLoan[]): ClientLoan[] => {
  return loans.map((l) => ({
    ...l,
    rate: Number(l.rate),
    term: Number(l.term),
    amount: parseCalculationObject(l.amount),
    arrangementFee: parseCalculationObject(l.arrangementFee),
    exitFee: parseCalculationObject(l.exitFee)
  }));
};

const recalculateLoanFees = (loan: ClientLoan, loanOutputs: CashflowLoanOutput[]) => {
  if (loan.arrangementFee?.calculate) {
    loan.arrangementFee.value =
      (loan.arrangementFee.calculationBase / 100) * calculateGrossLoan(loan, loanOutputs);
  }
  if (loan.exitFee?.calculate) {
    loan.exitFee.value =
      (loan.exitFee.calculationBase / 100) * calculateGrossLoan(loan, loanOutputs);
  }
};

export const hasCashflowInterestLoan = (loans: ClientLoan[]) => {
  return loans.some(
    (loan) => loan.interestCalculation === InterestCalculationType.DrawnBalanceCashflow
  );
};

export { ensureFloatsAreParsed, recalculateLoanFees };
