import { landFundApolloClient } from "@/react/lib/persistence/apollo/clients";
import { logError } from "@/react/utils";
import { UPDATE_USER_PREFERENCES_MUTATION } from "@shared/apollo/mutations";
import { IUserPreferences, IUserPreferencesUpdate } from "@shared/types/user";
import gql from "graphql-tag";

export const updateUserPreferences = async (
  preferences: IUserPreferencesUpdate
): Promise<IUserPreferences> => {
  const { data, errors } = await landFundApolloClient.mutate({
    mutation: gql`
      ${UPDATE_USER_PREFERENCES_MUTATION}
    `,
    variables: {
      preferences
    }
  });

  if (errors) {
    for (let err of errors) {
      logError(err.message);
    }
    throw new Error(`Error updating user preferences`);
  }

  return data.updateUserPreferences;
};
