<template>
  <div class="appraisal-tabs">
    <b-tabs v-model="currentTab" lazy nav-class="appraisal-nav">
      <template #tabs-start>
        <b-link
          :to="`/developments?id=${developmentId}`"
          class="development-link"
          title="Back to development"
        >
          <b-icon icon="arrow-left-circle" class="back-icon" />
        </b-link>
      </template>
      <b-tab
        id="tab-appraisal"
        title="Appraisal"
        :title-link-attributes="{ 'data-intercom-target': 'appraisal_tab' }"
      >
        <Appraisal />
      </b-tab>
      <b-tab
        id="tab-sensitivity"
        title="Sensitivity"
        :title-link-attributes="{ 'data-intercom-target': 'sensitivity_tab' }"
      >
        <Sensitivity />
      </b-tab>
      <b-tab
        id="tab-report"
        title="Report"
        :title-link-attributes="{ 'data-intercom-target': 'report_tab' }"
      >
        <AppraisalReport />
      </b-tab>
      <b-tab
        id="tab-cashflow"
        title="Cashflow"
        :title-link-attributes="{ 'data-intercom-target': 'cashflow_tab' }"
      >
        <Cashflow />
      </b-tab>
      <template #tabs-end>
        <UnitSettingToggle class="ml-auto my-auto" />
        <b-button
          variant="outline-primary"
          class="ml-2 my-auto"
          size="sm"
          v-if="!sidebarVisible && currentTab === 0"
          v-b-toggle.appraisal-sidebar
          @click="sidebarActive = !sidebarActive"
        >
          <b-icon :icon="!sidebarActive ? 'chevron-double-left' : 'chevron-double-right'" />
        </b-button>
      </template>
    </b-tabs>
  </div>
</template>

<script lang="ts">
import { Component, Mixins, Watch } from "vue-property-decorator";
import Appraisal from "@/pages/AppraisalPage.vue";
import AppraisalReport from "@/components/AppraisalReport.vue";
import Sensitivity from "@/pages/SensitivityPage.vue";
import Cashflow from "@/pages/Cashflow.vue";
import EventLoggerMixin from "@/mixins/event_logger_mixin";
import ResizeMixin from "@/mixins/resize_mixin";
import { UnitSettingToggle } from "@/react/components/Toggles";
import { rootStore } from "@/react/lib/persistence/root_store";
import { Observer } from "mobx-vue";

@Observer
@Component({
  components: {
    Appraisal,
    Sensitivity,
    AppraisalReport,
    Cashflow,
    UnitSettingToggle: UnitSettingToggle as Vue.Component
  }
})
export default class AppraisalTabs extends Mixins(EventLoggerMixin, ResizeMixin) {
  private currentTab: number = 0;
  private sidebarActive: boolean = false;

  async mounted() {
    await rootStore.currentDevelopmentStore.setupCurrentDevelopmentStore(this.developmentId);
    await rootStore.loadAppraisal(this.appraisalId);
    this.logEvent("Appraisal Viewed", { appraisal_id: this.appraisalId });
  }

  get appraisalId() {
    return this.$route.params.id;
  }

  get developmentId() {
    return this.$route.params.developmentId;
  }

  @Watch("currentTab")
  currentTabWatch(v: number) {
    if (v === 0) {
      this.logEvent("Appraisal Tab Selected", { appraisal_id: this.appraisalId });
    } else if (v === 1) {
      this.logEvent("Sensitivity Tab Selected", { appraisal_id: this.appraisalId });
    } else if (v === 2) {
      this.logEvent("Appraisal Report Tab Selected", { appraisal_id: this.appraisalId });
    } else if (v === 3) {
      this.logEvent("Cashflow Tab Selected", { appraisal_id: this.appraisalId });
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/landtech-colors";

.appraisal-nav {
  box-shadow: 0px 2px 2px $landfund-box-shadow-colour;
  background-color: $neutral-white;
  padding-right: 1rem;

  .nav-item {
    border-bottom: 1px solid $neutral-300;

    .nav-link {
      padding-top: 0.75rem;
      color: $blue-800;
      border-bottom: 0.25rem solid $neutral-white;

      &.active {
        font-weight: bold;
        background-color: $neutral-white !important;
        border-color: $neutral-white;
        border-bottom: 0.25rem solid $blue-accent-dark !important;
      }

      &.disabled {
        border-color: $neutral-white;
      }
    }
  }
}

.development-link {
  padding: 0.75rem 1.25rem 0;

  & > .back-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}
</style>
