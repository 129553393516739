import { LOCALES, messages } from "@/react/lib/i18n";
import { updateUserPreferences } from "@/react/lib/persistence/apollo/mutations";
import { getUser, getUserPreferences } from "@/react/lib/persistence/apollo/queries";
import { assignUser } from "@/utils/monitoring";
import { ClientUser, ClientUserPreferences, IUserPreferencesUpdate } from "@shared/types/user";
import { Nullable } from "@shared/types/utils";
import { areaUtil } from "@shared/utils/area";
import { UserFeatures } from "@shared/utils/authorisation";
import { makeAutoObservable } from "mobx";
import { IntlCache, IntlShape, createIntl, createIntlCache } from "react-intl";
import { RootStore } from "../Root";

type NumberFormatType = (v: number | string, decimals?: number) => string;

export class UserStore {
  readonly root: RootStore;
  intl: IntlShape;
  locale: LOCALES = LOCALES.enGB;
  user: Nullable<ClientUser> = null;
  userPreferences: Nullable<ClientUserPreferences> = null;
  isLoading = true;

  private intlCache: IntlCache;

  constructor(rootStore: RootStore) {
    this.root = rootStore;
    this.intlCache = createIntlCache();
    this.intl = this.createIntl();
    makeAutoObservable(this, { intl: false, root: false });
  }

  async initialise() {
    this.setIsLoading(true);
    this.setUser(await getUser());
    this.setUserPreferences(await getUserPreferences());
    this.setIsLoading(false);
  }

  async updateUserPreferencesAndRefreshReport(update: IUserPreferencesUpdate) {
    if (!this.userPreferences) {
      throw new Error("Can't update user preferences before initialised");
    }
    const oldUserPreferences = { ...this.userPreferences };
    try {
      this.userPreferences = { ...this.userPreferences, ...update };
      await updateUserPreferences(update);
      this.root.reportStore.refreshReport();
    } catch (e) {
      this.userPreferences = oldUserPreferences;
      throw e;
    }
  }

  setLocale(locale: LOCALES) {
    this.locale = locale;
    this.intl = this.createIntl();
  }

  setUser(value: ClientUser) {
    this.user = value;
    assignUser(value);
  }

  setUserPreferences(value: ClientUserPreferences) {
    this.userPreferences = value;
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  get currentLocale() {
    return this.locale;
  }

  get i18n() {
    return this.intl;
  }

  get areaUnit() {
    return this.userPreferences?.unitSetting || "metric";
  }

  get useLogo() {
    return !!this.userPreferences?.use_logo;
  }

  get userInitials(): string {
    return [this.user?.firstname[0], this.user?.surname[0]].join("").toUpperCase();
  }

  get avatarColourVariant() {
    const colours = [
      "green",
      "lime",
      "yellow",
      "orange",
      "red",
      "pink",
      "magenta",
      "purple",
      "blue",
      "aqua",
      "cyan"
    ];
    const charIndex = this.userInitials.charCodeAt(0) - 64;
    const colourIndex = charIndex % colours.length;
    return colours[colourIndex];
  }

  get userName() {
    return [this.user?.firstname, this.user?.surname].join(" ");
  }

  get userEmail() {
    return this.user?.email || "";
  }

  get hasLandTechEmail() {
    return (
      !!this.userEmail &&
      ["land.tech", "landinsight.io", "landenhance.io", "landtech.co"].some((frag) =>
        this.user?.email.includes(frag)
      )
    );
  }

  hasFeature(feature: UserFeatures) {
    return this.user?.features?.includes(feature);
  }

  get minorAreaUnitDisplay() {
    return areaUtil.getSmallAreaString(this.areaUnit);
  }

  get majorAreaUnitDisplay() {
    return areaUtil.getBigAreaString(this.areaUnit, true);
  }

  get numberFormat(): NumberFormatType {
    const locale = this.currentLocale;
    return (value: number | string, maximumFractionDigits = 2): string => {
      const opts = { minimumFractionDigits: 0, maximumFractionDigits };
      let formattingValue = typeof value === "string" ? parseInt(value, 10) : value;
      if (value.toString().includes(".")) {
        opts.minimumFractionDigits = maximumFractionDigits;
      }

      return new Intl.NumberFormat(locale, opts).format(formattingValue);
    };
  }

  private createIntl() {
    return createIntl({ locale: this.locale, messages: messages[this.locale] }, this.intlCache);
  }
}
