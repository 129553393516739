import { Nullable } from "@shared/types/utils";

export const inputStateString = (value: string) => {
  return value.length > 0 ? null : false;
};

export const inputStateNumber = (
  value: number,
  target: number,
  canBeEqual = false,
  isGreaterThan = true
) => {
  let result: Nullable<boolean> = null;
  if (isGreaterThan) {
    if (canBeEqual) {
      result = value >= target;
    } else {
      result = value > target;
    }
  } else {
    if (canBeEqual) {
      result = value <= target;
    } else {
      result = value < target;
    }
  }

  return result ? null : false;
};
