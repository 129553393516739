<template>
  <div
    class="flex-row align-items-center justify-content-between font-weight-bold"
    v-b-toggle="'accordion-' + buildPhaseId"
  >
    <div
      class="handle d-flex align-items-center justify-content-between"
      style="height: 38px width:30px;"
    >
      <b-icon icon="lt-six-dots" />
    </div>
    <b-form-group class="mr-auto mb-0" data-testid="build_phase_description_title">
      <div
        v-if="showFakeInput"
        class="fake-input border-dashed font-weight-bold rounded position-relative"
        data-testid="build_phase_description_fake_input"
        @mouseover="titleInputIsHover = true"
        @mouseleave="titleInputIsHover = false"
      >
        <div v-if="description" :id="'appraisal-desc-' + buildPhaseId">
          {{ description }}
        </div>
        <div v-else :id="'appraisal-desc-' + buildPhaseId" class="fake-placeholder">
          {{ buildPhaseNamePlaceholder }}
        </div>
        <div
          v-if="showFakeInput && titleInputIsHover"
          @click.stop="startEditDescription"
          class="input-edit-button position-absolute"
          data-testid="build_phase_edit_title_button"
        >
          <div class="input-edit-icon">
            <b-icon icon="pencil" />
          </div>
        </div>
      </div>

      <b-input
        v-else
        ref="headerTitleInput"
        :placeholder="buildPhaseNamePlaceholder"
        class="border-dashed font-weight-bold"
        :value="description"
        @input="(description) => BuildPhaseStore.updateBuildPhase(buildPhaseId, { description })"
        @blur="showFakeInput = true"
        :autofocus="buildPhaseHasBeenAdded"
        data-testid="build_phase_description_input"
        @click.stop
        @keydown.stop
      />
    </b-form-group>
    <div
      class="text-right text-nowrap ml-3 mr-2"
      style="width: 150px"
      data-testid="build_phase_total_cost_output"
    >
      {{ formatAmount(CostStore.buildPhaseTotalCost(buildPhaseId)) }}
    </div>
    <b-button
      title="Delete Build Phase"
      data-testid="delete_build_phase_button"
      variant="clear"
      @click.stop="isDeletingBuildPhase = true"
      v-b-tooltip.hover
    >
      <b-icon icon="dash-circle" />
    </b-button>
  </div>
</template>

<script lang="ts">
import EventLoggerMixin from "@/mixins/event_logger_mixin";
import { rootStore } from "@/react/lib/persistence/root_store";
import DialogUtil from "@/utils/dialog_utils";
import { ClientBuildPhase } from "@shared/types/buildPhase";
import { formatAmount } from "@shared/utils/formatting";
import { Observer } from "mobx-vue";
import { Component, Mixins, Prop, Ref, Watch } from "vue-property-decorator";

@Observer
@Component({})
export default class BuildPhaseAccordionHeader extends Mixins(EventLoggerMixin) {
  @Prop({ required: true }) readonly buildPhaseId!: ClientBuildPhase["_id"];
  @Prop({ required: true }) readonly description!: string;
  @Prop({ required: true }) readonly buildPhaseHasBeenAdded!: boolean;

  @Ref() readonly headerTitleInput!: HTMLInputElement;

  private isDeletingBuildPhase: boolean = false;
  private showFakeInput: boolean = true;
  private titleInputIsHover: boolean = false;
  private buildPhaseNamePlaceholder: string = "Build Phase Name";
  private CostStore = rootStore.costStore;
  private AppraisalStore = rootStore.appraisalStore;
  private formatAmount = formatAmount;
  private BuildPhaseStore = rootStore.buildPhaseStore;

  public async confirmDelete() {
    if (this.isDeletingBuildPhase) {
      rootStore.buildPhaseStore.deleteBuildPhase(this.buildPhaseId);
      this.isDeletingBuildPhase = false;
      this.logEvent("Build Phase Deleted", { is_template: this.AppraisalStore.isTemplate });
    }
  }

  get deleteDialogTitle() {
    return `Delete build phase "${this.description}"`;
  }

  public cancelDelete() {
    this.isDeletingBuildPhase = false;
  }

  public startEditDescription() {
    this.showFakeInput = false;
    this.titleInputIsHover = false;
  }

  @Watch("showFakeInput")
  public setFocusOnInput() {
    this.$nextTick(function () {
      if (this.headerTitleInput) {
        this.headerTitleInput.focus();
      }
    });
  }

  @Watch("isDeletingBuildPhase")
  async isDeletingBuildPhaseWatch(v: boolean) {
    if (v) {
      DialogUtil.showDeleteDialog(
        this.$bvModal,
        this.deleteDialogTitle,
        this.confirmDelete,
        this.cancelDelete
      );
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/landtech-colors";
$edit-button-width: 40px;

.fake-input {
  padding: 0.375rem 0.75rem;
  padding-right: $edit-button-width;
  border-width: 1px;
}
.fake-input:hover {
  border-color: $gray-400;
}
.fake-placeholder {
  color: $gray-600;
}
.input-edit-button {
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: $edit-button-width;
}
.input-edit-icon {
  font-size: 1.25rem;
  color: $gray-500;
}
.input-edit-button:hover .input-edit-icon {
  color: $gray-700;
}
</style>
