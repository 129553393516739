import { logError, logWarning } from "@/react/utils";
import generateRouter from "@/router";
import { redirectToLogin } from "@/utils/login_redirect";
import { AUTHENTICATION_ERROR_CODE } from "@shared/apollo/errors";
import { InMemoryCache } from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import { ApolloLink } from "apollo-link";
import { BatchHttpLink } from "apollo-link-batch-http";
import { onError } from "apollo-link-error";

export const cache = new InMemoryCache({ addTypename: true });

export const router = generateRouter();

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      const error = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
      logError(error, {
        message,
        locations,
        path,
        extensions
      });
      if (extensions?.code === AUTHENTICATION_ERROR_CODE) {
        cache.reset();
        redirectToLogin(router);
      }
    });
  }

  if (networkError) {
    logError(`[Network error]: ${networkError}`);
  }
});

const updateServerVersionLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext();
    const {
      response: { headers }
    } = context;
    const serverVersion = headers.get("Api-Version");

    if (serverVersion) {
      LANDFUND_CONFIG.SERVER_VERSION = serverVersion;
    } else {
      logWarning("[HTTP Header Warning]: 'Api-Version' header is missing");
    }

    return response;
  });
});

const landFundHttpLink = new BatchHttpLink({
  uri: `${LANDFUND_CONFIG.LANDFUND_API_URL}/graphql`
});

const landInsightHttpLink = new BatchHttpLink({
  uri: `${LANDFUND_CONFIG.LANDINSIGHT_API_URL}/graphql`,
  batchMax: 1
});

const accountsServiceHttpLink = new BatchHttpLink({
  uri: `${LANDFUND_CONFIG.ACCOUNTS_SERVICE_PRIVATE_API_URL}/graphql`
});

export const landFundApolloClient = new ApolloClient({
  cache,
  link: errorLink.concat(updateServerVersionLink).concat(landFundHttpLink),
  connectToDevTools: true
});

export const accountsServiceClient = new ApolloClient({
  cache,
  link: errorLink.concat(accountsServiceHttpLink),
  connectToDevTools: true
});

export const landInsightApolloClient = new ApolloClient({
  cache,
  link: errorLink.concat(landInsightHttpLink)
});
