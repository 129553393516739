import { FULL_APPRAISAL_BY_ID } from "@shared/apollo/queries";
import { ClientAppraisal } from "@shared/types/appraisal";
import gql from "graphql-tag";
import { cloneDeep } from "lodash";

export const sanitiseInput = (obj: any, removeIds = true) => {
  const clone = cloneDeep(obj);
  const propsToDelete = ["__typename"];
  if (removeIds) {
    propsToDelete.push("_id");
  }
  for (let prop in clone) {
    if (propsToDelete.includes(prop)) {
      delete clone[prop];
    } else if (typeof clone[prop] === "object") {
      clone[prop] = sanitiseInput(clone[prop], removeIds);
    }
  }
  return clone;
};

export const updateAppraisalCache = (store: any, appraisalById: ClientAppraisal) => {
  const query = gql`
    ${FULL_APPRAISAL_BY_ID}
  `;
  const variables = { appraisalId: appraisalById._id };
  store.writeQuery({ query, data: { appraisalById }, variables });
};
