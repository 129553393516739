export const mapEnum = <T extends {}>(
  input: T,
  callback: (
    value: keyof T,
    index: number,
    array: Array<keyof T>
  ) => { option: string; label: string }
) => {
  return (Object.keys(input) as Array<keyof T>).map(callback);
};
