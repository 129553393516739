import React, { FC } from "react";
import DropdownMenu from "./DropdownMenu";
import { Icon } from "../Icons";
import { Tooltip } from "../Tooltip";

interface OptionsMenuProps {
  options: Array<{
    label: string;
    actionHandler: () => void;
  }>;
  _id: string;
  tooltip: string;
  className?: string;
}

const OptionsMenu: FC<OptionsMenuProps> = ({ options, _id, tooltip, className = "" }) => {
  return (
    <DropdownMenu>
      <DropdownMenu.Trigger
        className="atlas-bg-transparent atlas-outline-none atlas-border-none"
        aria-label="Open"
      >
        <Tooltip asChild={true} content={tooltip}>
          <span data-testid="options-menu" role="tooltip">
            <Icon.More2Fill className={className} />
          </span>
        </Tooltip>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content className="atlas-relative atlas-z-max" align="end">
        {options.map(
          (option) =>
            option.label && (
              <DropdownMenu.Item
                className={
                  "atlas-bg-white atlas-py-2 atlas-px-3 atlas-pr-12 hover:atlas-bg-blue-25 focus:atlas-bg-blue-25 atlas-text-left atlas-text-md atlas-font-bold"
                }
                onClick={option.actionHandler}
                data-testid="select-option"
                key={`${_id}${option.label}`}
              >
                {option.label}
              </DropdownMenu.Item>
            )
        )}
      </DropdownMenu.Content>
    </DropdownMenu>
  );
};

export default OptionsMenu;
