import { rootStore } from "@/react/lib/persistence/root_store";
import { logEvent } from "@/react/utils";
import { BuildQuality } from "@shared/types/buildCost";
import { areaUtil } from "@shared/utils/area";
import { UserFeatures } from "@shared/utils/authorisation";
import { formatAmount, formatNumber } from "@shared/utils/formatting";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useRef } from "react";
import { BuildCostPresentation } from "./components";

export interface BuildCostProps {
  buildPhaseStore?: typeof rootStore.buildPhaseStore;
  currentDevelopmentStore?: typeof rootStore.currentDevelopmentStore;
  unitGroupStore?: typeof rootStore.unitGroupStore;
  userStore?: typeof rootStore.userStore;
  buildCostStore?: typeof rootStore.buildCostStore;
  eventLogger?: typeof logEvent;
  appraisalStore?: typeof rootStore.appraisalStore;
}

const BuildCostObservable = observer(
  ({
    buildCostStore = rootStore.buildCostStore,
    buildPhaseStore = rootStore.buildPhaseStore,
    currentDevelopmentStore = rootStore.currentDevelopmentStore,
    unitGroupStore = rootStore.unitGroupStore,
    userStore = rootStore.userStore,
    appraisalStore = rootStore.appraisalStore,
    eventLogger = logEvent
  }: BuildCostProps) => {
    const initialRender = useRef(true);

    const logAction = useCallback(
      (event: string, fire: boolean) => {
        if (fire) {
          eventLogger(event, {
            postcode: currentDevelopmentStore.currentPostcode ?? "",
            isSite: !!currentDevelopmentStore.site,
            manual: !!currentDevelopmentStore.sitePostcode,
            buildCostPerAreaUnit: buildCostStore.costPerAreaUnit,
            buildCostTotal: buildCostStore.totalCost,
            GIA: unitGroupStore.metricTotalGIA,
            initialRender: initialRender.current,
            buildQuality: appraisalStore.quality
          });
        }
      },
      [
        currentDevelopmentStore.site,
        currentDevelopmentStore.currentPostcode,
        buildCostStore.costPerAreaUnit,
        buildCostStore.totalCost,
        initialRender
      ]
    );

    const onPostcodeUpdate = (postcode: string) => {
      currentDevelopmentStore.updatePostcode(postcode);
      buildCostStore.debounceGetBuildCost();
    };

    useEffect(() => {
      buildCostStore.debounceGetBuildCost();
      logAction("Build Cost Postcode Updated", !!currentDevelopmentStore.currentPostcode);
    }, [currentDevelopmentStore.currentPostcode]);

    useEffect(() => {
      logAction(
        "Build Cost Rendered",
        !!buildCostStore.buildCost && buildCostStore.hasFetchedBuildCosts
      );
    }, [buildCostStore.buildCost]);

    useEffect(() => {
      initialRender.current = false;
    });

    return (
      <div className="atlas-mt-4">
        <div className="atlas-flex atlas-justify-end atlas-gap-4 atlas-font-bold atlas-mb-4 atlas-mr-16">
          <div>Total Build Cost</div>
          <div>
            {formatNumber({
              value: buildPhaseStore.buildCostWithoutContingencyPerAreaUnit
            })}
            {` £/${areaUtil.getSmallAreaString(userStore.areaUnit)}`}
          </div>
          <div>{formatAmount(rootStore.costStore.totalBuildCostWithoutContingency)}</div>
        </div>
        {userStore.hasFeature(UserFeatures.LANDFUND_BUILD_COST_DATA) && (
          <BuildCostPresentation
            onPostcodeUpdate={onPostcodeUpdate}
            buildCost={buildCostStore.costPerAreaUnit}
            isSite={!!currentDevelopmentStore.sitePostcode}
            postcode={currentDevelopmentStore.currentPostcode ?? ""}
            postcodeValid={currentDevelopmentStore.postcodeValid}
            postcodePartial={currentDevelopmentStore.postcodePartial}
            totalGIA={unitGroupStore.metricTotalGIA ?? 0}
            unit={userStore.areaUnit}
            quality={appraisalStore.quality}
            setBuildQuality={(qual: BuildQuality) => appraisalStore.setQuality(qual)}
          />
        )}
      </div>
    );
  }
);

export const BuildCost = (props: BuildCostProps) => <BuildCostObservable {...props} />;
