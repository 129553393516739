<template>
  <div data-testid="specs"></div>
</template>

<script lang="ts">
import { Component, Mixins, Watch } from "vue-property-decorator";
import EventLoggerMixin from "@/mixins/event_logger_mixin";
import debounce from "debounce";
import AspectRatio from "@/utils/aspect_ratio";

interface IAspectRatio {
  value: number;
  approx: string | undefined;
}

interface ISpecConfiguration {
  window: {
    width: number;
    height: number;
    aspect_ratio: IAspectRatio;
  } | null;
  browser: { name: string; version: string } | null;
  screen:
    | {
        width: number;
        height: number;
        aspect_ratio: IAspectRatio;
      }
    | { unavailable: boolean }
    | null;
}

@Component({})
export default class SpecsLogger extends Mixins(EventLoggerMixin) {
  public handleResize = debounce(this.inspectWindow, 2000);

  private aspectRatioUtil = new AspectRatio();
  private configuration: ISpecConfiguration = {
    window: null,
    browser: null,
    screen: null
  };

  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.inspectConfiguration();
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  }

  get cleanedConfiguration() {
    return JSON.parse(JSON.stringify(this.configuration));
  }
  get windowChangedEventProperties() {
    return Object.assign({}, this.cleanedConfiguration);
  }

  public getWindow(): Window {
    return window;
  }

  public inspectConfiguration() {
    this.inspectBrowser();
    this.inspectScreen();
    this.inspectWindow();
  }

  public inspectBrowser() {
    this.configuration.browser = {
      name: this.$browserDetect.meta.name,
      version: this.$browserDetect.meta.version
    };
  }

  public inspectWindow() {
    const width = this.getWindow().innerWidth;
    const height = this.getWindow().innerHeight;
    this.configuration.window = {
      width,
      height,
      aspect_ratio: {
        value: Math.round((width / height) * 1000) / 1000,
        approx: this.aspectRatioUtil.calculateNearestRatio(width, height)
      }
    };
  }

  public inspectScreen() {
    if (!this.getWindow().screen) {
      this.configuration.screen = {
        unavailable: true
      };
      return;
    }
    const width = this.getWindow().screen.width;
    const height = this.getWindow().screen.height;
    this.configuration.screen = {
      width,
      height,
      aspect_ratio: {
        value: Math.round((width / height) * 1000) / 1000,
        approx: this.aspectRatioUtil.calculateNearestRatio(width, height)
      }
    };
  }

  @Watch("configuration", { deep: true })
  configurationWatch() {
    this.logEvent("Window Changed", this.windowChangedEventProperties);
  }
}
</script>
