import React, { FC } from "react";
import { observer } from "mobx-react-lite";
import * as RadioGroup from "@radix-ui/react-radio-group";
import "./UnitSettingToggle.css";
import { rootStore } from "@/react/lib/persistence/root_store";
import { UnitSetting } from "@shared/types/user";
import { logEvent } from "@/react/utils";

export const UnitSettingToggle: FC = () => <UnitSettingToggleObserver />;

const UnitSettingToggleObserver: FC = observer(() => {
  const changeUnitSetting = async (unitSetting: UnitSetting) => {
    if (rootStore.userStore.areaUnit !== unitSetting) {
      await rootStore.userStore.updateUserPreferencesAndRefreshReport({ unitSetting });
      logEvent("Unit Setting Updated", {
        unitSetting,
        page: window.location.pathname.split("/")[3]
      });
    }
  };

  return (
    <form data-testid="unitSettingToggle">
      {!rootStore.userStore.isLoading && (
        <RadioGroup.Root
          className="atlas-flex atlas-items-center atlas-bg-neutral-300 atlas-p-1 atlas-rounded"
          value={rootStore.userStore.areaUnit}
          aria-label="user unit setting"
        >
          <RadioGroup.Item
            className="aria-checked:atlas-bg-white toggle-option atlas-px-2 atlas-pt-1 atlas-text-xs atlas-text-primary-900 atlas-cursor-pointer hover:atlas-bg-neutral-100 atlas-border-none atlas-bg-neutral-300"
            value="imperial"
            data-testid="unitSettingImperial"
            onClick={() => changeUnitSetting("imperial")}
          >
            <label className="atlas-m-0 atlas-cursor-pointer">ft²</label>
          </RadioGroup.Item>
          <RadioGroup.Item
            className="aria-checked:atlas-bg-white toggle-option atlas-px-2 atlas-pt-1 atlas-text-xs atlas-text-primary-900 atlas-cursor-pointer hover:atlas-bg-neutral-100 atlas-border-none atlas-bg-neutral-300"
            value={"metric"}
            data-testid="unitSettingMetric"
            onClick={() => changeUnitSetting("metric")}
          >
            <label className="atlas-m-0 atlas-cursor-pointer">m²</label>
          </RadioGroup.Item>
        </RadioGroup.Root>
      )}
    </form>
  );
});
