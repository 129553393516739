<template>
  <div class="d-flex w-100 align-items-center justify-content-center flex-column logo-display">
    <b-spinner v-show="imageLoading" variant="secondary" data-testid="logo_display_loader" />

    <b-img
      v-show="!imageLoading"
      :src="fileSrc"
      class="logo-display-image border my-2 align-self-start"
      data-testid="logo_display_img"
      @error="handleLoadError"
      @load="imageLoading = false"
    />
    <b-btn
      v-show="!imageLoading"
      @click="deleteLogo"
      variant="light"
      data-testid="logo_display_delete_logo_button"
      class="delete-button pl-1 pr-2 align-self-start"
    >
      <span
        v-if="!deleting"
        class="d-flex align-items-center"
        data-testid="logo_display_delete_logo_button_text"
      >
        <b-icon-x class="delete-button-x" />Delete
      </span>
      <b-spinner
        v-else
        variant="secondary"
        class="delete-button-spinner spinner-grey"
        data-testid="logo_display_delete_logo_button_loader"
      />
    </b-btn>
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import axios from "axios";
import { AccountErrorText } from "@shared/types/account";
import EventLoggerMixin from "@/mixins/event_logger_mixin";
import { rootStore } from "@/react/lib/persistence/root_store";
import { Observer } from "mobx-vue";

@Observer
@Component({})
export default class LogoDisplay extends Mixins(EventLoggerMixin) {
  public imageLoading: boolean = true;
  public deleting: boolean = false;

  get fileSrc(): string {
    return `${process.env.VUE_APP_LANDFUND_API_URL}/image`;
  }

  public async deleteLogo(): Promise<void> {
    this.clearMessages();
    this.deleting = true;
    try {
      await axios.delete(`${LANDFUND_CONFIG.LANDFUND_API_URL}/image`);
      this.$emit("imageDeleted");
      rootStore.reportStore.refreshReport();
      this.logEvent("Image Deleted");
    } catch (e) {
      this.$emit("error", AccountErrorText.FileFailedToDelete);
    } finally {
      this.deleting = false;
    }
  }

  public handleLoadError(): void {
    this.$emit("noImage");
    this.imageLoading = false;
  }

  private clearMessages(): void {
    this.$emit("clearErrors");
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/landtech-colors";
.logo-display {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .logo-display-image {
    max-height: 100px;
    max-width: 100%;
  }

  .delete-button {
    color: $black;
    font-size: 14px;
    line-height: 16px;
    font-weight: 300;
    width: 80px;

    .delete-button-x {
      height: 14px;
      width: 14px;
    }

    .delete-button-spinner {
      height: 13px;
      width: 13px;
    }
  }
}
</style>
