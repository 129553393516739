import { ClientMiniTemplate } from "@shared/types/appraisal";
import { makeAutoObservable } from "mobx";
import {
  createTemplate,
  deleteTemplate,
  duplicateTemplate,
  getMiniTemplates,
  templateAppraisal
} from "@/react/lib/persistence/apollo";
import ObjectID from "bson-objectid";
import { RootStore } from "../Root";

const defaultMiniTemplate: Omit<ClientMiniTemplate, "_id"> = {
  title: "",
  isTemplate: true,
  description: ""
};

export class TemplateStore {
  templates: ClientMiniTemplate[] = [];
  root: RootStore;

  constructor(value: RootStore) {
    this.root = value;
    makeAutoObservable(this, { root: false });
  }

  async setupTemplateStore() {
    const templates = await getMiniTemplates();
    this.setTemplates(templates);
  }

  setTemplates(value: ClientMiniTemplate[]) {
    this.templates = value;
  }

  async createTemplate() {
    const newTemplate = { ...defaultMiniTemplate, _id: new ObjectID().toHexString() };
    const templatesBeforeChange = [...this.templates];
    this.templates.push(newTemplate);
    try {
      await createTemplate(newTemplate);
    } catch {
      this.setTemplates(templatesBeforeChange);
    }
  }

  public async templateAppraisal(id: string) {
    const appraisal = this.root.miniAppraisalStore.appraisals.find((a) => a._id === id);
    if (!appraisal) {
      return new Error("Can't find appraisal id!");
    }
    const newTemplate = {
      ...appraisal,
      _id: new ObjectID().toHexString(),
      template: true,
      title: "Template of " + appraisal.title
    };
    this.templates.push(newTemplate);
    await templateAppraisal(id, newTemplate._id, newTemplate.title);
  }

  async deleteTemplate(id: ClientMiniTemplate["_id"]) {
    if (!this.templates.some((template) => template._id === id)) {
      return new Error("Template id doesn't exists");
    }
    const templatesBeforeChange = [...this.templates];
    this.templates = this.templates.filter((template) => template._id !== id);
    try {
      await deleteTemplate(id);
    } catch {
      this.setTemplates(templatesBeforeChange);
    }
  }

  async duplicateTemplate(id: ClientMiniTemplate["_id"]) {
    const oldTemplate = this.templates.find((template) => template._id === id);
    if (!oldTemplate) {
      return new Error("Template id doesn't exists");
    }
    const newId = new ObjectID().toHexString();
    const newTitle = oldTemplate.title + " COPY";
    const templatesBeforeChange = [...this.templates];
    this.templates.push({ ...oldTemplate, _id: newId, title: newTitle });
    try {
      await duplicateTemplate(oldTemplate, newId, newTitle);
    } catch {
      this.setTemplates(templatesBeforeChange);
    }
  }
}
