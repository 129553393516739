<template>
  <div class="flex-column mt-3">
    <b-button variant="primary" @click="addBuildPhase">
      <span>Add Build Phase</span>
    </b-button>
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import EventLoggerMixin from "@/mixins/event_logger_mixin";
import { rootStore } from "@/react/lib/persistence/root_store";
import { Observer } from "mobx-vue";

@Observer
@Component({})
export default class AddBuildPhaseButton extends Mixins(EventLoggerMixin) {
  private buildPhaseStore = rootStore.buildPhaseStore;
  private appraisalStore = rootStore.appraisalStore;

  addBuildPhase() {
    this.buildPhaseStore.addBuildPhase();
    this.logEvent("Build Phase Created", { is_template: this.appraisalStore.isTemplate });
  }
}
</script>
