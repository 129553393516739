import React from "react";

import { Button, ButtonSizes, ButtonThemes, Icon } from "@/react/components";
import { rootStore } from "@/react/lib/persistence/root_store";
import { logEvent } from "@/react/utils";
import { ClientLineItemExtended, CurveType, LineItemType } from "@shared/types/cashflow";

export interface CurveCellProps {
  lineItem: ClientLineItemExtended;
  className?: string;
}

export const CurveCell = ({ lineItem, className = "" }: CurveCellProps) => {
  const cashflowStore = rootStore.cashflowStore;
  const appraisalStore = rootStore.appraisalStore;
  const switchCurveType = async () => {
    const curveTypes = Object.values(CurveType);
    const currentCurveTypeIndex = curveTypes.indexOf(lineItem.curveType);
    const nextCurveType = curveTypes[(currentCurveTypeIndex + 1) % curveTypes.length];

    cashflowStore.setSpreadOrCustomUpdate({ ...lineItem, curveType: nextCurveType });
    logEvent("Cashflow Line Item Updated", {
      appraisal_id: appraisalStore.appraisalId,
      development_id: appraisalStore.developmentId,
      line_item_type: lineItem.type,
      line_item_curve: lineItem.curveType,
      completed: cashflowStore.isComplete,
      is_auto: false,
      sales_rate_spread: !!lineItem.rate && lineItem.curveType === CurveType.Line
    });
  };

  return (
    <div
      className={`${className} atlas-flex atlas-justify-center`}
      data-testid="line-item-curve-cell"
    >
      {lineItem.type !== LineItemType.UnitGroup && (
        <Button
          size={ButtonSizes.XS}
          theme={ButtonThemes.Borderless}
          onClick={switchCurveType}
          title={lineItem.curveType}
          data-testid={`line-item-curve-button-${lineItem.curveType}`}
        >
          <span>
            {lineItem.curveType === CurveType.Custom && (
              <Icon.LineItemCustom className="atlas-text-neutral-700 hover:atlas-text-primary-500" />
            )}
            {lineItem.curveType === CurveType.Line && (
              <Icon.LineItemLine className="atlas-text-neutral-700 hover:atlas-text-primary-500" />
            )}
            {lineItem.curveType === CurveType.SCurve && (
              <Icon.LineItemSCurve className="atlas-text-neutral-700 hover:atlas-text-primary-500" />
            )}
          </span>
        </Button>
      )}
      {lineItem.type === LineItemType.UnitGroup && (
        <Button
          size={ButtonSizes.XS}
          theme={ButtonThemes.Outline}
          onClick={() => cashflowStore.resetLineItemsCells(lineItem)}
          data-testid={`line-item-clear`}
        >
          Clear
        </Button>
      )}
    </div>
  );
};
