"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppraisalAnnotationType = void 0;
var AppraisalAnnotationType;
(function (AppraisalAnnotationType) {
    AppraisalAnnotationType["income"] = "income";
    AppraisalAnnotationType["build"] = "build";
    AppraisalAnnotationType["professionalFees"] = "professionalFees";
    AppraisalAnnotationType["otherCosts"] = "otherCosts";
    AppraisalAnnotationType["funding"] = "funding";
    AppraisalAnnotationType["landAcquisition"] = "landAcquisition";
})(AppraisalAnnotationType = exports.AppraisalAnnotationType || (exports.AppraisalAnnotationType = {}));
