import axios from "axios";
import { makeAutoObservable } from "mobx";
import { RootStore } from "../Root";
import { convertStoresToAppraisalCalculations } from "@/react/utils/appraisalCalculations";
import { Nullable } from "@shared/types/utils";

const PDF_ENDPOINT_URL = `${LANDFUND_CONFIG.LANDFUND_API_URL}/appraisal-report-pdf`;

export class ReportStore {
  root: RootStore;
  latestRequestTimestamp: number = 0;
  localUrl: Nullable<string> = null;

  constructor(root: RootStore) {
    this.root = root;
    makeAutoObservable(this, { root: false });
  }

  setLocalURL(value: string) {
    this.localUrl = value;
  }

  async fetchReport() {
    this.resetLocalUrl();
    const currentRequestTimestamp = Date.now();
    this.latestRequestTimestamp = currentRequestTimestamp;
    const response = await axios.post(
      PDF_ENDPOINT_URL,
      {
        appraisalId: this.root.appraisalStore.appraisalId,
        unitSetting: this.root.userStore.areaUnit,
        appraisalCalculations: convertStoresToAppraisalCalculations(this.root),
        loanOutputs: this.root.cashflowFinanceStore.financeOutputs.loanOutputs
      },
      { responseType: "blob" }
    );

    if (currentRequestTimestamp === this.latestRequestTimestamp) {
      // Only load report if response is from the latest request as this request might happen multiple times
      this.setLocalURL(
        window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }))
      );
    }
  }

  async refreshReport() {
    if (this.localUrl) {
      await this.fetchReport();
    }
  }

  resetLocalUrl() {
    if (this.localUrl) {
      window.URL.revokeObjectURL(this.localUrl);
      this.localUrl = "";
    }
  }
}
