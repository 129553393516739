import React, { FC } from "react";
import { AppraisalSection } from "../AppraisalSection";
import { AppraisalAnnotationType } from "@shared/types/appraisalAnnotation";
import { useTranslation } from "@/react/lib/hooks";
import { AppraisalSectionsTranslations } from "@/react/lib/i18n/locales";
import { rootStore } from "@/react/lib/persistence/root_store";
import { CostList } from "@/react/domains/appraisal/Costs/CostList/CostList";
import { CostType } from "@shared/types/costs";
import { observer } from "mobx-react-lite";

const ProfessionalFeesSection: FC = () => <ProfessionalFeesSectionObserved />;

const ProfessionalFeesSectionObserved: FC = observer(() => {
  const { t } = useTranslation();
  return (
    <AppraisalSection
      type={AppraisalAnnotationType.professionalFees}
      title={t(AppraisalSectionsTranslations.AppraisalSections_ProfesionalFeesTitle)}
      testId="professional-fees-section"
    >
      <CostList
        costType={CostType.ProfessionalFee}
        calculationOptions={rootStore.calculationsStore.percentageCalculationOptions}
        costs={rootStore.costStore.professionalFees}
      />
    </AppraisalSection>
  );
});

export { ProfessionalFeesSection };
