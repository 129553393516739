import { Confirm, Table, InfoTooltip, RowActions, Tooltip } from "@/react/components";
import { useTranslation } from "@/react/lib/hooks";
import {
  AppraisalEquityFundingTranslations,
  AppraisalFundingTranslations
} from "@/react/lib/i18n/locales";
import { rootStore } from "@/react/lib/persistence/root_store";
import { logEvent } from "@/react/utils";
import { ClientEquityFunding } from "@shared/types/equity";
import { formatAmount } from "@shared/utils/formatting";
import { observer } from "mobx-react-lite";
import React, { Dispatch, useMemo, useState } from "react";
import { Column } from "react-table";
import { EquityFundingTableDataObject } from "../types";

const EquityFundingTable = () => <EquityFundingTableObservable />;

const EquityFundingTableObservable = observer(() => {
  const { t } = useTranslation();

  const [isConfirmDeleteOpened, updateIsConfirmDeleteOpened] = useState(false);

  const equityFundingColumns: Array<Column<EquityFundingTableDataObject>> = useMemo(
    () => [
      {
        id: "equityName",
        Header: t(AppraisalEquityFundingTranslations.AppraisalEquityFunding_TableEquityName),
        accessor: "description",
        Footer: (
          <>
            <strong className="atlas-font-bold">
              {t(AppraisalEquityFundingTranslations.AppraisalEquityFunding_TableDeveloperEquity)}
            </strong>
            <InfoTooltip
              content={t(
                AppraisalEquityFundingTranslations.AppraisalEquityFunding_TableDeveloperEquityTooltip
              )}
              side="bottom"
            />
          </>
        )
      },
      {
        id: "totalAmount",
        Header: t(AppraisalEquityFundingTranslations.AppraisalEquityFunding_TableTotalAmount),
        accessor: "totalAmountFormatted",
        Footer: (
          <strong className="atlas-font-bold" data-testid="developerEquityContribution">
            {formatAmount(rootStore.equityFundingStore.developerEquity)}
          </strong>
        )
      },
      {
        id: "couponRate",
        Header: t(AppraisalEquityFundingTranslations.AppraisalEquityFunding_TableCouponRate),
        accessor: "couponRateFormatted",
        Footer: ""
      },
      {
        id: "ProviderProfitShare",
        Header: t(AppraisalEquityFundingTranslations.AppraisalEquityFunding_ProviderProfitShare),
        accessor: "providerProfitShareFormatted",
        Footer: ""
      },
      {
        id: "term",
        Header: t(AppraisalEquityFundingTranslations.AppraisalEquityFunding_TableTerm),
        accessor: "termFormatted",
        Footer: ""
      },
      {
        id: "action",
        Header: (
          <span
            aria-label={t(AppraisalFundingTranslations.AppraisalFunding_TableActions)}
            role="toolbar"
            data-testid="actions-column"
          />
        ),
        accessor: "action",
        Footer: ""
      }
    ],
    [rootStore.equityFundingStore.developerEquity]
  );

  const data = useMemo(() => {
    const equityFundings = rootStore.equityFundingStore.orderedEquityFundingSources.map(
      (equityFunding) => {
        return {
          ...equityFunding,
          draggable: true,
          totalAmountFormatted: formatAmount(equityFunding.totalAmount),
          couponRateFormatted: equityFunding.couponRate + " %",
          providerProfitShareFormatted: (
            <Tooltip
              content={formatAmount(
                (rootStore.costStore.profit * equityFunding.providerProfitShare) / 100
              )}
              className="atlas-leading-none atlas-h-6 atlas-mx-2"
              side="bottom"
            >
              {equityFunding.providerProfitShare + " %"}
            </Tooltip>
          ),
          termFormatted: equityFunding.term + " months",
          action: (
            <RowActions
              editTitle={t(AppraisalEquityFundingTranslations.AppraisalEquityFunding_EditEquity)}
              deleteTitle={t(
                AppraisalEquityFundingTranslations.AppraisalEquityFunding_DeleteEquity
              )}
              onDeleteClick={() => {
                rootStore.equityFundingStore.setEquityToDeleteId(equityFunding._id);
                updateIsConfirmDeleteOpened(true);
              }}
              onEditClick={() => {
                rootStore.currentEquityFundingStore.startEditingEquity(equityFunding);
              }}
            />
          )
        };
      }
    );

    equityFundings.push({
      _id: "calculationRow",
      __typename: "calculationRow",
      totalDeveloperEquity: (
        <strong className="atlas-font-bold">
          {formatAmount(rootStore.equityFundingStore.totalEquityAmount)}
        </strong>
      ),
      draggable: false
    } as any);

    return equityFundings;
  }, [
    rootStore.equityFundingStore.orderedEquityFundingSources,
    rootStore.cashflowFinanceStore.financeOutputs.loanOutputs
  ]);

  const updateStore: Dispatch<
    (d: EquityFundingTableDataObject[]) => EquityFundingTableDataObject[]
  > = (value) => {
    const output = value(data)
      .filter((d) => d._id !== "calculationRow")
      .map((d) => {
        const outputObj = d as Partial<typeof d>;
        delete outputObj.draggable;
        delete outputObj.action;
        delete outputObj.totalAmountFormatted;
        delete outputObj.couponRateFormatted;
        delete outputObj.providerProfitShareFormatted;
        delete outputObj.termFormatted;

        return outputObj as ClientEquityFunding;
      });
    rootStore.equityFundingStore.reorderEquityFundings(output);
  };

  return (
    <>
      <Table<EquityFundingTableDataObject>
        columns={equityFundingColumns}
        data={data}
        setData={updateStore}
      />
      <Confirm
        onConfirm={() => {
          logEvent("Equity Finance Removed", {
            appraisal_id: rootStore.getCurrentAppraisal()._id,
            development_id: rootStore.currentDevelopmentStore.development?._id
          });
          rootStore.equityFundingStore.deleteEquityFunding();
          updateIsConfirmDeleteOpened(false);
        }}
        onCancel={() => {
          rootStore.equityFundingStore.clearEquityToDeleteId();
          updateIsConfirmDeleteOpened(false);
        }}
        title={t(AppraisalFundingTranslations.AppraisalFunding_PleaseConfirm)}
        description={t(
          AppraisalEquityFundingTranslations.AppraisalEquityFunding_DeleteEquityConfirm
        )}
        modalOpen={isConfirmDeleteOpened}
        confirmButtonText={t(AppraisalFundingTranslations.AppraisalFunding_Delete)}
      />
    </>
  );
});

export { EquityFundingTable };
