// @ts-nocheck
import { flatten } from "lodash";

const Utils = {
  SITE_SATELLITE_COLOUR: "#FFFF00",
  SITE_MAP_COLOUR: "#FF0000",
  getPolygonColour(map, { satelliteColour, mapColour }) {
    const colourMap = {
      [google.maps.MapTypeId.SATELLITE]: satelliteColour,
      [google.maps.MapTypeId.HYBRID]: satelliteColour
    };
    return colourMap[map.mapTypeId] || mapColour;
  },
  getSiteColour(
    map,
    { satelliteColour = this.SITE_SATELLITE_COLOUR, mapColour = this.SITE_MAP_COLOUR } = {}
  ) {
    return this.getPolygonColour(map, {
      satelliteColour,
      mapColour
    });
  },
  geoJsonPolyToGoogleBounds(geoJsonPoly, bounds = new google.maps.LatLngBounds()) {
    let points: any[] = flatten(geoJsonPoly.coordinates);
    if (geoJsonPoly.type === "MultiPolygon") {
      points = flatten(points);
    }
    points.forEach((p) => bounds.extend({ lat: p[1], lng: p[0] }));
    return bounds;
  },
  getLatLngCoordinatesFromGeoJSONPolygon(geoJson) {
    return geoJson.coordinates.map((line) => {
      const latLngs = line.map((point) => {
        return new google.maps.LatLng(point[1], point[0]);
      });
      latLngs.pop();
      return latLngs;
    });
  },
  getGoogleDataGeometry(geoJSON) {
    let isMulti;
    if (geoJSON.type === "Polygon") {
      isMulti = false;
    } else if (geoJSON.type === "MultiPolygon") {
      isMulti = true;
    } else {
      throw new Error("Unsupported geometry type. Currently only does polygon and multi polygon");
    }
    let coords = geoJSON.coordinates;
    if (!isMulti) {
      coords = [coords];
    }
    const arr = coords.map((poly) =>
      this.getLatLngCoordinatesFromGeoJSONPolygon({
        coordinates: poly
      })
    );

    let googleGeom;
    if (!isMulti) {
      googleGeom = new google.maps.Data.Polygon(arr[0]);
    } else {
      googleGeom = new google.maps.Data.MultiPolygon(arr);
    }

    return googleGeom;
  }
};

export default Utils;
