<template>
  <div
    class="file-drop-area d-flex flex-column align-items-center justify-content-center p-3"
    @dragenter="draggedOver = true"
    @dragover.prevent="draggedOver = true"
    @drop.prevent="onDroppedFile"
    @dragleave="draggedOver = false"
  >
    <b-icon
      v-if="draggedOver && !uploading"
      icon="lt-upload-cloud"
      data-testid="logo_upload_cloud_upload_icon"
      class="mb-2 no-pointer-events"
    />
    <b-spinner
      v-else-if="uploading"
      variant="secondary"
      data-testid="logo_upload_loading_spinner"
    />
    <template v-else>
      <b-icon
        icon="lt-image-add"
        data-testid="logo_upload_image_add_icon"
        class="mb-2 no-pointer-events"
      />
      <p class="drag-area-heading mt-2 mb-0">
        Drag your logo here, or
        <label for="logo-upload-input">
          <strong class="text-primary upload-browse-button">browse</strong>
        </label>
      </p>
      <p class="drag-area-info m-0">PNG or JPG <small>(max 150KB)</small></p>
      <input
        type="file"
        id="logo-upload-input"
        :accept="allowedMimeTypes.join(', ')"
        data-testid="logo_upload_input"
        hidden
        @change="processInputFile"
        ref="logoUploadInput"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Ref, Mixins } from "vue-property-decorator";
import Vue from "vue";
import axios from "axios";
import { AccountErrorText, UploadConfig } from "@shared/types/account";
import {
  isAllowedFileExtension,
  isAllowedMimeType,
  allowedMimeTypes
} from "@shared/utils/image_uploading_common";
import { isValidFileTypeClient } from "@shared/utils/image_uploading_client";
import { Nullable } from "@shared/types/utils";
import EventLoggerMixin from "@/mixins/event_logger_mixin";
import { rootStore } from "@/react/lib/persistence/root_store";
import { Observer } from "mobx-vue";

@Observer
@Component({})
export default class LogoUploader extends Mixins(EventLoggerMixin) {
  public image: Nullable<File> = null;
  public draggedOver: boolean = false;
  public uploading: boolean = false;
  public allowedMimeTypes: string[] = allowedMimeTypes;

  @Ref() readonly logoUploadInput!: HTMLInputElement;

  public onDroppedFile(e: DragEvent) {
    this.processDraggedFile(e);
    this.draggedOver = false;
  }

  public async processDraggedFile(e: DragEvent): Promise<void> {
    if (!e.dataTransfer) {
      return;
    }
    const droppedFile = this.retrieveFile(e.dataTransfer);
    await this.checkAndUploadFile(droppedFile);
  }

  public async processInputFile(): Promise<void> {
    const uploadedFile = this.retrieveFile(this.logoUploadInput);
    await this.checkAndUploadFile(uploadedFile);
  }

  private readyForUploading(): boolean {
    return !this.uploading;
  }

  private async validFileForUpload(file: File): Promise<boolean> {
    if (!file) {
      await this.emitErrorAndClearInput(AccountErrorText.FileFailedToUpload);
      return false;
    }
    if (file.size > UploadConfig.UploadFileSize) {
      await this.emitErrorAndClearInput(AccountErrorText.FileFailedToUpload);
      return false;
    }
    if (
      !isAllowedFileExtension(file.name) ||
      !isAllowedMimeType(file.type) ||
      !(await isValidFileTypeClient(file))
    ) {
      await this.emitErrorAndClearInput(AccountErrorText.FileFailedToUpload);
      return false;
    }
    return true;
  }

  private async emitErrorAndClearInput(error: AccountErrorText) {
    this.$emit("error", error);
    await Vue.nextTick();
    this.logoUploadInput.value = "";
  }

  private async checkAndUploadFile(uploadedFile: Nullable<File>): Promise<void> {
    if (!this.readyForUploading || !uploadedFile) {
      return;
    }

    this.clearMessages();

    const fileIsValid = await this.validFileForUpload(uploadedFile);
    if (fileIsValid) {
      this.image = uploadedFile;
      this.uploadImage(this.image);
    }
  }

  private retrieveFile(filesParent: DataTransfer | HTMLInputElement): Nullable<File> {
    const fileList: Nullable<FileList> = filesParent.files;
    if (!fileList) {
      return null;
    }
    const file: Nullable<File> = fileList[0];
    if (!file) {
      return null;
    }
    return file;
  }

  private async uploadImage(image: File): Promise<void> {
    this.uploading = true;
    try {
      const url = `${LANDFUND_CONFIG.LANDFUND_API_URL}/image`;
      const formData = new FormData();
      formData.append("uploaded_file", image);
      const config = {
        headers: {
          "content-type": "multipart/form-data"
        }
      };
      await axios.post(url, formData, config);
      this.$emit("imageUploaded");
      rootStore.reportStore.refreshReport();
      this.logEvent("Image Uploaded", { mime_type: image.type });
    } catch (e) {
      this.$emit("error", AccountErrorText.FileFailedToUpload);
    } finally {
      this.image = null;
      this.uploading = false;
    }
  }

  private clearMessages(): void {
    this.$emit("clearErrors");
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/landtech-colors";
.file-drop-area {
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: 0;
  right: 0;
  background: $landfund-draggable-area-background;
  border: 1px dashed $landfund-draggable-area-border;
  box-sizing: border-box;
  border-radius: 8px;
  color: $landfund-draggable-area-text;

  .upload-browse-button {
    cursor: pointer;
  }

  .no-pointer-events {
    pointer-events: none;
  }

  .drag-area-heading {
    font-size: 14px;
  }

  .drag-area-info {
    font-size: 12px;
  }

  .error-message {
    color: $black;
  }
}
</style>
