import { ValidationOptions } from "@/react/utils";
import { UnitGroupType, UnitGroupExitType } from "@/utils/unit_groups";
import { ClientBaseComputable, ClientBaseCost } from "@shared/types/computable";
import { MAX_NO_UNITS } from "@shared/utils/constants";
import { pick } from "lodash";

export interface CurrentUnitGroupFields {
  description: string;
  percentGIA: number;
  unitCount: number;
  area: number;
  beds: number;
  investmentYield: number;
  voidPeriod: number;
  rentFreePeriod: number;
  annualRentIncome: ClientBaseComputable;
  lettingFee: ClientBaseComputable;
  salesValue: ClientBaseComputable;
  salesFee: ClientBaseCost;
  legalFees: ClientBaseCost;
}

export const validations: Record<keyof CurrentUnitGroupFields, ValidationOptions> = {
  description: { name: "description", type: "string" },
  unitCount: { name: "unitCount", type: "number", min: 0, minIncl: false, max: MAX_NO_UNITS },
  percentGIA: { name: "percentGIA", type: "number", min: 0, minIncl: false },
  area: { name: "area", type: "number", min: 0, minIncl: false },
  beds: { name: "beds", type: "number", min: 0, minIncl: true },
  investmentYield: { name: "investmentYield", type: "number", min: 0, minIncl: false },
  voidPeriod: { name: "voidPeriod", type: "number", min: 0, minIncl: true },
  rentFreePeriod: { name: "rentFreePeriod", type: "number", min: 0, minIncl: true },
  salesValue: { name: "salesValue", type: "computable", min: 0, minIncl: false },
  salesFee: { name: "salesFee", type: "computable", min: 0, minIncl: true },
  annualRentIncome: { name: "annualRentIncome", type: "computable", min: 0, minIncl: false },
  legalFees: { name: "legalFees", type: "computable", min: 0, minIncl: true },
  lettingFee: { name: "lettingFee", type: "computable" }
};

export const getAddingUnitGroupValidationOptions = (
  unitType: UnitGroupType,
  exitType: UnitGroupExitType
): ValidationOptions[] => {
  let fields: Array<keyof CurrentUnitGroupFields> = [];
  const commonOptions: Array<keyof CurrentUnitGroupFields> = ["description", "unitCount"];
  if (unitType === UnitGroupType.OTHER_INCOME) {
    fields = commonOptions;
  }
  const commonResiCommercialValidations: Array<keyof CurrentUnitGroupFields> = [
    ...commonOptions,
    "percentGIA",
    "area"
  ];

  const otherIncomeOptions: Array<keyof CurrentUnitGroupFields> = [...commonOptions, "salesValue"];

  const isRented = exitType === UnitGroupExitType.RENT;

  const resiForSaleOptions: Array<keyof CurrentUnitGroupFields> = [
    ...commonResiCommercialValidations,
    "beds",
    "salesFee",
    "legalFees",
    "salesValue"
  ];
  const resiRentedOptions: Array<keyof CurrentUnitGroupFields> = [
    ...commonResiCommercialValidations,
    "beds",
    "annualRentIncome",
    "investmentYield"
  ];
  const commercialUnitOptions: Array<keyof CurrentUnitGroupFields> = [
    ...commonResiCommercialValidations,
    "annualRentIncome",
    "investmentYield",
    "voidPeriod",
    "rentFreePeriod",
    "legalFees"
  ];

  switch (unitType) {
    case UnitGroupType.OTHER_INCOME:
      fields = otherIncomeOptions;
      break;
    case UnitGroupType.FLAT:
    case UnitGroupType.HOUSE:
      fields = isRented ? resiRentedOptions : resiForSaleOptions;
      break;
    case UnitGroupType.COMMERCIAL_UNIT:
      fields = commercialUnitOptions;
      break;
  }

  return Object.values(pick(validations, fields));
};

export const getUnitValidationFields = (
  unitType: UnitGroupType,
  exitType: UnitGroupExitType
): Array<keyof CurrentUnitGroupFields> => {
  switch (unitType) {
    case UnitGroupType.FLAT:
    case UnitGroupType.HOUSE:
      if (exitType === UnitGroupExitType.SALE) {
        return ["area", "beds", "salesValue", "salesFee", "legalFees"];
      }
      return ["area", "beds", "annualRentIncome", "investmentYield"];
    case UnitGroupType.OTHER_INCOME:
      return ["salesValue"];
    case UnitGroupType.COMMERCIAL_UNIT:
      return [
        "area",
        "annualRentIncome",
        "investmentYield",
        "voidPeriod",
        "rentFreePeriod",
        "legalFees"
      ];
  }
};
