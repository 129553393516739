<template>
  <div v-if="hasMounted">
    <map-polygon
      v-for="site in sites"
      :key="site.id"
      :data-layer="dataLayer"
      :id="site.id"
      :geom="site.geometry"
      :properties="site.properties"
      @polygon-event="onPolygonEvent"
    />
  </div>
  <div v-else></div>
</template>

<script lang="ts">
/*  global google */
// See docs at :
// https://docs.google.com/a/landtech.co/document/d/15AShDZH_mHY8QQpsjDMUuHl_X1BHvPjRyk2zz-ZGHGI/edit?usp=sharing

import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import MapUtil from "@/map/utils";
import MapPolygon from "@/map/polygon.vue";

@Component({
  components: {
    MapPolygon
  }
})
export default class Sites extends Vue {
  @Prop() readonly map!: object;
  @Prop({ type: Object, required: true }) readonly sitesGeo!: any;
  @Prop({ type: Boolean, default: true }) readonly clickable!: any;

  public hasMounted = false;
  public dataLayer: any = null;

  mounted() {
    this.dataLayer = new google.maps.Data({
      map: this.map
    });

    this.hasMounted = true;
    this.setBaseStyle();
  }

  destroyed() {
    this.dataLayer.setMap(null);
  }

  public onPolygonEvent(eventName: string, event: any) {
    this.$emit(`site-${eventName}`, event);
  }

  public setBaseStyle() {
    this.dataLayer.setStyle(this.sitesStyle);
  }

  get sites() {
    return this.sitesGeo.features;
  }

  get sitesStyle() {
    let colour = "#000";
    if (this.map) {
      colour = MapUtil.getSiteColour(this.map);
    }
    return {
      fillColor: colour,
      fillOpacity: 0,
      strokeColor: colour,
      strokeOpacity: 1,
      strokeWeight: 3,
      clickable: this.clickable
    };
  }

  @Watch("map")
  mapWatch() {
    this.dataLayer.setMap(this.map);
  }

  @Watch("sitesStyle")
  sitesStyleWatch() {
    this.setBaseStyle();
  }
}
</script>
