import clsx from "clsx";
import { Interweave } from "interweave";
import React, { FC, PropsWithChildren } from "react";

import * as TooltipComponent from "@radix-ui/react-tooltip";

enum TooltipThemes {
  DEFAULT = "default",
  DARK = "dark",
  WARNING = "warning",
  DANGER = "danger"
}

type TooltipProps = PropsWithChildren<
  {
    content: string;
    tooltipDescription?: string;
    side?: "top" | "right" | "left" | "bottom";
    leftAlignText?: boolean;
    theme?: TooltipThemes;
  } & TooltipComponent.TooltipProviderProps &
    TooltipComponent.TooltipContentProps &
    TooltipComponent.TooltipTriggerProps &
    TooltipComponent.TooltipProps
>;

const Tooltip = ({
  children,
  content,
  tooltipDescription = content,
  delayDuration = 0,
  side = "top",
  sideOffset = 0,
  onClick = () => null,
  onOpenChange = () => null,
  asChild = false,
  className,
  leftAlignText = false,
  open,
  theme = TooltipThemes.DEFAULT
}: TooltipProps) => {
  let tooltipClasses = "atlas-bg-white atlas-border-neutral-400 atlas-border-neutral-400";

  switch (theme) {
    case TooltipThemes.DARK:
      tooltipClasses = "atlas-bg-neutral-900 atlas-text-white atlas-border-neutral-900";
      break;
    case TooltipThemes.WARNING:
      tooltipClasses = "atlas-bg-orange-700 atlas-text-white atlas-border-orange-700";
      break;
    case TooltipThemes.DANGER:
      tooltipClasses = "atlas-bg-red-800 atlas-text-white atlas-border-red-800";
      break;
  }

  return (
    <TooltipComponent.Provider delayDuration={delayDuration}>
      <TooltipComponent.Root open={open} onOpenChange={(open) => onOpenChange(open)}>
        <TooltipComponent.Trigger
          aria-label={tooltipDescription}
          asChild={asChild}
          className={clsx(
            "atlas-border-0 atlas-appearance-none atlas-font-normal atlas-text-base atlas-p-0 atlas-text-current",
            { "atlas-bg-transparent": theme === TooltipThemes.DEFAULT },
            className
          )}
          onClick={(evt) => onClick(evt)}
        >
          {children}
        </TooltipComponent.Trigger>
        <TooltipComponent.Portal>
          <TooltipComponent.Content
            side={side}
            forceMount={true}
            sideOffset={sideOffset}
            className={clsx(
              [leftAlignText ? "atlas-text-left" : "atlas-text-center"],
              { "atlas-hidden": content === "" },
              "atlas-mb-2 atlas-px-3 atlas-py-2 atlas-rounded atlas-filter atlas-drop-shadow-lg",
              "atlas-border atlas-text-sm atlas-border-solid atlas-top-10 atlas-z-max atlas-max-w-xs atlas-font-sans atlas-block",
              tooltipClasses
            )}
          >
            <span className="atlas-relative atlas-z-10">
              <Interweave content={content} />
            </span>
            <TooltipArrow className={clsx(tooltipClasses)} />
          </TooltipComponent.Content>
        </TooltipComponent.Portal>
      </TooltipComponent.Root>
    </TooltipComponent.Provider>
  );
};

const TooltipArrow: FC<{ className: string }> = ({ className }) => {
  const tooltipArrowBorder = "atlas-border-r atlas-border-t-0 atlas-border-l-0";

  return (
    <TooltipComponent.Arrow
      height={6}
      className="atlas-fill-current atlas-text-white"
      asChild={true}
    >
      <span
        className={clsx(
          "atlas-block atlas-bg-white atlas-h-3 atlas-w-3 atlas-rotate-45 atlas-transform atlas-top-1/2",
          "atlas-border-b atlas-border-solid atlas-border-neutral-400 atlas--mt-5px",
          tooltipArrowBorder,
          className
        )}
      ></span>
    </TooltipComponent.Arrow>
  );
};

export { Tooltip, TooltipProps, TooltipThemes };
