"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UPDATE_USER_PREFERENCES_MUTATION = exports.DUPLICATE_TEMPLATE_MUTATION = exports.DELETE_TEMPLATE_MUTATION = exports.CREATE_TEMPLATE_MUTATION = exports.SET_EQUITY_FUNDING_SOURCES_MUTATION = exports.DELETE_LINE_ITEM_MUTATION = exports.CREATE_LINE_ITEM_MUTATION = exports.UPDATE_LINE_ITEM_MUTATION = exports.UPDATE_APPRAISAL_ANNOTATION_MUTATION = exports.UPDATE_LOANS_MUTATION = exports.getUpdateAppraisalMutation = exports.DELETE_APPRAISAL_MUTATION = exports.CREATE_TEMPLATE_FROM_APPRAISAL_MUTATION = exports.DUPLICATE_APPRAISAL_MUTATION = exports.CREATE_APPRAISAL_MUTATION = void 0;
const fragments_1 = require("./fragments");
exports.CREATE_APPRAISAL_MUTATION = `
  mutation ($appraisalId: ObjectID!, $developmentId: ObjectID!, $description: String!, $templateId: ObjectID) {
    createAppraisal(
      appraisalId: $appraisalId
      developmentId: $developmentId
      description: $description
      templateId: $templateId
    ) {
      ...MiniAppraisal
    }
  }
  ${fragments_1.MiniAppraisalFragment}
`;
exports.DUPLICATE_APPRAISAL_MUTATION = `
mutation ($oldId: ObjectID!, $newId: ObjectID!, $title: String!) {
  duplicateAppraisal(oldId: $oldId, newId: $newId, title: $title) {
    ...MiniAppraisal
  }
}
${fragments_1.MiniAppraisalFragment}
`;
exports.CREATE_TEMPLATE_FROM_APPRAISAL_MUTATION = `
  mutation ($appraisalId: ObjectID!, $newTemplateId: ObjectID!, $newTemplateTitle: String!) {
    createTemplateFromAppraisal(appraisalId: $appraisalId, newTemplateId: $newTemplateId, newTemplateTitle: $newTemplateTitle) {
      ...MiniTemplate
    }
  }
  ${fragments_1.MiniTemplateFragment}
`;
exports.DELETE_APPRAISAL_MUTATION = `
  mutation ($id: ObjectID!) {
    deleteAppraisal(id: $id)
  }
`;
function getUpdateAppraisalMutation(returnKeys) {
    return `
    mutation($appraisalId: ObjectID!, $appraisalUpdate: UpdateAppraisalInput!) {
      updateAppraisal(id: $appraisalId, appraisalUpdate: $appraisalUpdate) {
        _id
        ${returnKeys.join("\n")}
      }
    }
`;
}
exports.getUpdateAppraisalMutation = getUpdateAppraisalMutation;
exports.UPDATE_LOANS_MUTATION = `
  mutation ($appraisalId: ObjectID!, $appraisalUpdate: UpdateAppraisalInput!) {
    updateAppraisal(id: $appraisalId, appraisalUpdate: $appraisalUpdate) {
      _id
      loans {
        ...Loan
      }
    }
  }
  ${fragments_1.LoanFragment}
`;
exports.UPDATE_APPRAISAL_ANNOTATION_MUTATION = `
  mutation ($appraisalId: ObjectID!, $appraisalAnnotationInput: AppraisalAnnotationInput!) {
    updateAppraisalAnnotation(
      appraisalId: $appraisalId
      appraisalAnnotationInput: $appraisalAnnotationInput
    ) {
      ...AppraisalAnnotation
    }
  }
  ${fragments_1.AppraisalAnnotationFragment}
`;
exports.UPDATE_LINE_ITEM_MUTATION = `
  mutation ($appraisalId: ObjectID!, $lineItemUpdate: LineItemInput!) {
    updateLineItem(appraisalId: $appraisalId, lineItemUpdate: $lineItemUpdate) {
      ...LineItem
    }
  }
  ${fragments_1.CashflowLineItemFragment}
`;
exports.CREATE_LINE_ITEM_MUTATION = `
  mutation ($appraisalId: ObjectID!, $lineItem: LineItemInput!) {
    createLineItem(appraisalId: $appraisalId, lineItem: $lineItem) {
      ...LineItem
    }
  }
  ${fragments_1.CashflowLineItemFragment}
`;
exports.DELETE_LINE_ITEM_MUTATION = `
  mutation ($appraisalId: ObjectID!, $linkedId: ObjectID!) {
    deleteLineItem(appraisalId: $appraisalId, linkedId: $linkedId)
  }
`;
exports.SET_EQUITY_FUNDING_SOURCES_MUTATION = `
  mutation ($appraisalId: ObjectID!, $equityFundingUpdate: [EquityFundingInput!]!) {
    setEquityFundingSources(appraisalId: $appraisalId, update: $equityFundingUpdate) {
      ...EquityFunding  
    }
  }
  ${fragments_1.EquityFundingFragment}
`;
exports.CREATE_TEMPLATE_MUTATION = `
  mutation($id:ObjectID!) {
    createTemplate(id: $id) {
      ...MiniTemplate
    }
  }
  ${fragments_1.MiniTemplateFragment}
`;
exports.DELETE_TEMPLATE_MUTATION = `
  mutation ($id: ObjectID!) {
    deleteAppraisal(id: $id)
  }
`;
exports.DUPLICATE_TEMPLATE_MUTATION = `
  mutation ($oldId: ObjectID!, $newId: ObjectID!, $title: String!) {
    duplicateAppraisal(oldId: $oldId, newId: $newId, title: $title) {
      ...MiniTemplate
    }
  }
  ${fragments_1.MiniTemplateFragment}
`;
exports.UPDATE_USER_PREFERENCES_MUTATION = `
  mutation ($preferences: UserPreferencesUpdate!) {
    updateUserPreferences(preferences: $preferences) {
      ...UserPreferences
    }
  }
  ${fragments_1.UserPreferencesFragment}
`;
