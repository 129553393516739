"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CostAction = exports.CostType = void 0;
var CostType;
(function (CostType) {
    CostType["ProfessionalFee"] = "professional-fee";
    CostType["OtherCost"] = "other-cost";
    CostType["Deposit"] = "deposit";
    CostType["StampDuty"] = "stamp-duty";
    CostType["LandFee"] = "land-fee";
    CostType["Construction"] = "construction";
})(CostType = exports.CostType || (exports.CostType = {}));
var CostAction;
(function (CostAction) {
    CostAction["Create"] = "Cost Created";
    CostAction["Update"] = "Cost Updated";
    CostAction["Delete"] = "Cost Deleted";
    CostAction["Reorder"] = "Costs Reordered";
})(CostAction = exports.CostAction || (exports.CostAction = {}));
