import { irr, convertRate } from "node-irr";

export const calculateMonthlyIRR = (monthlyNets: number[]): number => {
  return calculateIRR(monthlyNets);
};

export const calculateAnnualIRR = (monthlyNets: number[]): number => {
  return calculateIRR(monthlyNets, true);
};

const calculateIRR = (monthlyNets: number[], isAnnual: boolean = false): number => {
  let result = irr(monthlyNets);
  if (isAnnual) {
    result = convertRate(result, 12);
  }
  result *= 100;
  result = roundTo2dp(result);
  return result;
};

const roundTo2dp = (x: number): number => {
  // round to 2DP accounting for rounding errors (mostly!)
  return Math.round((x + Number.EPSILON) * 100) / 100;
};
