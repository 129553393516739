"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MiniDevelopmentFragment = exports.UserPreferencesFragment = exports.UserFragment = exports.OwnershipSearchResultConnectionFragment = exports.PlanningFragment = exports.CILFragment = exports.AuthorityFragment = exports.SiteConstraintsFragment = exports.FullSiteFragment = exports.MiniSiteFragment = exports.FullAppraisalFragment = exports.MiniAppraisalFragment = exports.MiniTemplateFragment = exports.AppraisalAnnotationsFragment = exports.AppraisalAnnotationFragment = exports.CashflowFragment = exports.CashflowLineItemFragment = exports.BuildPhaseInputFragment = exports.BuildPhaseFragment = exports.EquityFundingFragment = exports.LoanFragment = exports.UnitGroupFragment = exports.UnitFragment = exports.BaseComputableFragment = exports.BaseCostFragment = exports.CostFragment = exports.ComputableFragment = void 0;
const appraisalAnnotation_1 = require("../types/appraisalAnnotation");
const BaseComputableProps = `
    __typename
    value
    calculate
    calculationType
    calculationBase
`;
exports.ComputableFragment = `
  fragment Computable on Computable {
    _id
    ${BaseComputableProps}
  }
`;
exports.CostFragment = `
  fragment Cost on Cost {
    _id
    _build_phase
    type
    description
    position
    ${BaseComputableProps}
  }
`;
exports.BaseCostFragment = `
  fragment BaseCost on BaseCost {
    position
    ${BaseComputableProps}  }
`;
exports.BaseComputableFragment = `
  fragment BaseComputable on BaseComputable {
    ${BaseComputableProps}
  }
`;
exports.UnitFragment = `
  fragment Unit on Unit {
    __typename
    _id
    area
    beds
    salesValue {
      ...BaseComputable
    }
    investmentYield
    annualRentIncome {
      ...BaseComputable
    }
    salesFee {
      ...BaseCost
    }
    legalFees {
      ...BaseCost
    }
    voidPeriod
    rentFreePeriod
    lettingFee {
      ...BaseComputable
    }
  }
  ${exports.BaseCostFragment}
  ${exports.BaseComputableFragment}
`;
exports.UnitGroupFragment = `
  fragment UnitGroup on UnitGroup {
    __typename
    _id
    position
    description
    unitType
    percentGIA
    exitType
    units {
      ...Unit
    }
  }
  ${exports.UnitFragment}
`;
exports.LoanFragment = `
  fragment Loan on Loan {
    __typename
    _id
    position
    name
    amount {
      ...BaseComputable
    }
    term
    rate
    rateIsMonthly
    arrangementFee {
      ...BaseCost
    }
    exitFee {
      ...BaseCost
    }
    interestCalculation
    interestCharging
  }
  ${exports.BaseCostFragment}
  ${exports.BaseComputableFragment}
`;
exports.EquityFundingFragment = `
  fragment EquityFunding on EquityFunding {
    _id
    position
    description
    totalAmount
    couponRate
    providerProfitShare
    term  
  }
`;
exports.BuildPhaseFragment = `
  fragment BuildPhase on BuildPhase {
    __typename
    _id
    position
    description
    assignedNIA
    assignedGIA {
      ...BaseComputable
    }
    assignedUnitGroups
    isCustom
    numberOfUnits
  }
  ${exports.BaseComputableFragment}
`;
exports.BuildPhaseInputFragment = `
  fragment BuildPhaseInput on BuildPhase {
    __typename
    _id
    position
    description
    assignedNIA
    assignedGIA {
      ...BaseComputable
    }
    assignedUnitGroups
    isCustom
    numberOfUnits
  }
  ${exports.BaseComputableFragment}
`;
exports.CashflowLineItemFragment = `
  fragment LineItem on LineItem {
    __typename
    _id
    _linkedId
    curveType
    cells
    type
    units
  }
`;
exports.CashflowFragment = `
  fragment Cashflow on Cashflow {
    __typename
    lineItems {
      ...LineItem
    }
  }
  ${exports.CashflowLineItemFragment}
`;
exports.AppraisalAnnotationFragment = `
  fragment AppraisalAnnotation on AppraisalAnnotation {
    __typename
    _id
    text
  }
`;
exports.AppraisalAnnotationsFragment = `
  fragment AppraisalAnnotations on AppraisalAnnotations {
    __typename
    ${Object.keys(appraisalAnnotation_1.AppraisalAnnotationType).reduce((final, type) => final + `${type} \n { \n ...AppraisalAnnotation \n}`, "")}
  }
  ${exports.AppraisalAnnotationFragment}
  `;
exports.MiniTemplateFragment = `
  fragment MiniTemplate on Appraisal {
    _id
    _development
    isTemplate
    title
    description
  }
`;
exports.MiniAppraisalFragment = `
  fragment MiniAppraisal on Appraisal {
    _id
    _development
    isTemplate
    title
    description
    quality
    archived
  }
`;
exports.FullAppraisalFragment = `
  fragment FullAppraisal on Appraisal {
    __typename
    _id
    _development
    _site
    _team
    _user
    isTemplate
    title
    description
    unitGroups {
      ...UnitGroup
    }
    buildPhases {
      ...BuildPhase
    }
    loans {
      ...Loan
    }
    equityFundingSources {
      ...EquityFunding
    }
    purchasePrice
    isResidential
    isIndividual
    isAdditional
    isFreehold
    residualLandCalculation
    residualLandTarget
    contingency
    appraisalAnnotations {
      ...AppraisalAnnotations
    }
    costs {
      ...Cost
    }
    cashflow {
      ...Cashflow
    }
    quality
    archived
    version
  }
  ${exports.UnitGroupFragment}
  ${exports.BuildPhaseFragment}
  ${exports.LoanFragment}
  ${exports.CostFragment}
  ${exports.CashflowFragment}
  ${exports.AppraisalAnnotationsFragment}
  ${exports.EquityFundingFragment}
`;
exports.MiniSiteFragment = `
  fragment MiniSite on Site {
    _id
    title
    address
    geometry {
      type
      coordinates
    }
  }
`;
exports.FullSiteFragment = `
  fragment FullSite on Site {
    _id
    title
    geometry {
      type
      coordinates
    }
    address
    local_authority
    use_classes
    use_classes_sep_2020
    cached_location {
      type
      coordinates
    }
  }
`;
exports.SiteConstraintsFragment = `
  fragment SiteConstraints on SiteConstraints {
    greenbelt
    aonb
    common_land
    flood_risk 
    listed_buildings
  }
`;
exports.AuthorityFragment = `
  fragment Authority on Authority {
    name
    gss_code
  }
`;
exports.CILFragment = `
  fragment CIL on CIL {
    status
    date
    zones
    residential_charges
    retail_commercial_charges
    others
    url
    mayoral_cil_zone
    mayoral_cil_rates
  }
`;
exports.PlanningFragment = `
  fragment Planning on Planning {
    address
    title
    type
    decision
    applicant_name
    date_received
    status_derived
    ref
  }
`;
exports.OwnershipSearchResultConnectionFragment = `
  fragment OwnershipSearchResultConnection on OwnershipSearchResultConnection {
    totalCount
    nodes {
      result_id
      result_item {
        title {
          title_no
          tenure
        }
        company {
          name
          company_no
          proprietorship
          addresses
        }
        person {
          name
          address
          email
        }
      }
    }
  }
`;
exports.UserFragment = `
  fragment User on User {
    _id
    email
    firstname
    surname
    telephone
    licence_summary
    features
    beta_features
    intercom_hash
    _organisation {
      name
    }
  }
`;
exports.UserPreferencesFragment = `
  fragment UserPreferences on UserPreferences {
    _id
    _user
    use_logo
    unitSetting
  }
`;
exports.MiniDevelopmentFragment = `
  fragment MiniDevelopment on Development {
    _id
    _site
    title
    description
    notes
    appraisalCount
    archived
  }
`;
