import { totalUnitGroupGIA } from "@/react/utils/unitGroup";
import {
  totalAreaFromUnitGroups,
  totalNumberOfUnits,
  unitGroupsForBuildPhase
} from "@/utils/unit_groups";
import { ClientBuildPhase } from "@shared/types/buildPhase";
import { ClientBaseComputable, ComputableCalculationType } from "@shared/types/computable";
import { ClientUnitGroup } from "@shared/types/unitGroup";
import ObjectID from "bson-objectid";

export const createDefaultBuildPhase = (position: number): ClientBuildPhase => {
  return {
    _id: new ObjectID().toHexString(),
    __typename: "BuildPhase",
    position,
    description: "",
    assignedNIA: 0,
    assignedGIA: {
      __typename: "BaseComputable",
      value: 0,
      calculate: false,
      calculationType: ComputableCalculationType.PERCENTAGE_OF_NIA,
      calculationBase: 0
    },
    assignedUnitGroups: [],
    isCustom: false,
    numberOfUnits: 0
  };
};

export const rebuildAutoBuildPhase = (
  buildPhase: ClientBuildPhase,
  unitGroups: ClientUnitGroup[],
  newAssignedUnitGroups?: string[]
): ClientBuildPhase => {
  let newBuildPhase = { ...buildPhase };
  newBuildPhase.assignedUnitGroups = newAssignedUnitGroups ?? newBuildPhase.assignedUnitGroups;
  const numberOfUnits = buildPhaseNumberOfUnits(newBuildPhase, unitGroups);
  newBuildPhase = { ...newBuildPhase, numberOfUnits };
  const assignedNIA = buildPhaseAutoAssignedNIA(newBuildPhase, unitGroups);
  newBuildPhase = { ...newBuildPhase, assignedNIA };
  const assignedGIA = buildPhaseAutoAssignedGIA(newBuildPhase, unitGroups);
  return { ...newBuildPhase, assignedGIA };
};

export const buildPhaseAutoAssignedGIA = (
  buildPhase: ClientBuildPhase,
  unitGroups: ClientUnitGroup[]
): ClientBaseComputable => {
  const newGIA = { ...buildPhase.assignedGIA };
  const buildPhaseAssignedUnitGroups = unitGroupsForBuildPhase(buildPhase, unitGroups);

  let buildPhaseGIA = 0;
  buildPhaseAssignedUnitGroups.forEach((unitGroup: ClientUnitGroup) => {
    buildPhaseGIA += totalUnitGroupGIA(unitGroup);
  });

  newGIA.calculationBase = isNaN((buildPhaseGIA / buildPhase.assignedNIA) * 100)
    ? 0
    : (buildPhaseGIA / buildPhase.assignedNIA) * 100;
  newGIA.value = buildPhaseGIA;

  return newGIA;
};

export const buildPhaseAutoAssignedNIA = (
  buildPhase: ClientBuildPhase,
  unitGroups: ClientUnitGroup[]
): number =>
  totalAreaFromUnitGroups(
    unitGroups.filter((unitGroup) => buildPhase.assignedUnitGroups.includes(unitGroup._id))
  );

export const buildPhasesUsedNIA = (
  buildPhases: ClientBuildPhase[],
  unitGroups: ClientUnitGroup[]
) => {
  const nonCustomBuildPhaseNIA =
    totalAreaFromUnitGroups(uniqueUnitGroupsAcrossBuildPhases(buildPhases, unitGroups)) ?? 0;

  const customBuildPhaseNIA = buildPhases
    .filter((buildPhase: ClientBuildPhase) => buildPhase.isCustom)
    .reduce((sum: number, buildPhase: ClientBuildPhase) => sum + buildPhase.assignedNIA, 0);

  return customBuildPhaseNIA + nonCustomBuildPhaseNIA;
};

export const uniqueUnitGroupsAcrossBuildPhases = (
  buildPhases: ClientBuildPhase[],
  unitGroups: ClientUnitGroup[]
) => {
  const unitGroupIdsUsed = buildPhases.reduce(
    (uniqueIds: string[], buildPhase: ClientBuildPhase) => {
      return [...uniqueIds, ...buildPhase.assignedUnitGroups];
    },
    []
  );
  return unitGroups.filter((unitGroup) => unitGroupIdsUsed.includes(unitGroup._id));
};

export const buildPhaseNumberOfUnits = (
  buildPhase: ClientBuildPhase,
  unitGroups: ClientUnitGroup[]
): number => {
  if (buildPhase.isCustom) {
    return buildPhase.numberOfUnits;
  } else {
    return totalNumberOfUnits(
      unitGroups.filter((group) => buildPhase.assignedUnitGroups.includes(group._id))
    );
  }
};
