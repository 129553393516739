import { Icon, Tooltip } from "@/react/components";
import React, { FC } from "react";
import "./RowActions.css";

interface RowActionsProps {
  editTitle: string;
  onEditClick: (event: React.MouseEvent<HTMLElement>) => void;
  duplicateTitle?: string;
  onDuplicateClick?: (event: React.MouseEvent<HTMLElement>) => void;
  canDuplicate?: boolean;
  deleteTitle: string;
  onDeleteClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const RowActions: FC<RowActionsProps> = ({
  editTitle,
  deleteTitle,
  canDuplicate = true,
  duplicateTitle,
  onDeleteClick,
  onEditClick,
  onDuplicateClick
}) => (
  <span className="atlas-flex atlas-justify-end atlas-items-center">
    <span className="atlas-w-16 atlas-block atlas-h-6">
      <span
        className="atlas-hidden group-hover:atlas-block atlas-pointer-events-none group-hover:atlas-pointer-events-auto"
        data-testid="table-row-actions"
      >
        {duplicateTitle && onDuplicateClick && canDuplicate && (
          <span className="atlas-mr-2 atlas-content-to-toggle" data-testid="duplicate-row">
            <Tooltip
              content={duplicateTitle}
              side="top"
              onClick={onDuplicateClick}
              className="atlas-appearance-none atlas-border-none atlas-bg-transparent atlas-rounded-full atlas-p-0 atlas-line-clamp-none atlas-leading-none"
              aria-label={duplicateTitle}
            >
              <Icon.FileCopyLine className="atlas-h-6 atlas-w-6 atlas-text-neutral-800" />
            </Tooltip>
          </span>
        )}
        <span className="atlas-mr-2 atlas-content-to-toggle" data-testid="edit-row">
          <Tooltip
            content={editTitle}
            side="top"
            onClick={onEditClick}
            className="atlas-appearance-none atlas-border-none atlas-bg-transparent atlas-rounded-full atlas-p-0 atlas-line-clamp-none atlas-leading-none"
            aria-label={editTitle}
          >
            <Icon.PencilLine className="atlas-h-6 atlas-w-6 atlas-text-neutral-800" />
          </Tooltip>
        </span>
      </span>
    </span>

    <span className="atlas-mr-2 atlas-content-to-toggle" data-testid="delete-row">
      <Tooltip
        content={deleteTitle}
        side="top"
        className="atlas-appearance-none atlas-border-none atlas-bg-transparent atlas-rounded-full atlas-p-0 atlas-line-clamp-none atlas-leading-none"
        onClick={onDeleteClick}
        aria-label={deleteTitle}
      >
        <Icon.IndeterminateCircleLine className="atlas-h-6 atlas-w-6 atlas-text-neutral-800" />
      </Tooltip>
    </span>
  </span>
);

export { RowActions };
