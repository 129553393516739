import { buildPhasesUsedNIA } from "@/react/utils/build_phase";
import { totalUnitGroupGIA } from "@/react/utils/unitGroup";
import { totalAreaFromUnitGroups } from "@/utils/unit_groups";
import { ClientUnit, IUnit } from "@shared/types/unit";
import { ClientUnitGroup, IUnitGroup } from "@shared/types/unitGroup";
import { totalUnitGroupSalesFees } from "@shared/utils/unit_groups";
import { areaUtil } from "@shared/utils/area";
import { MAX_NO_UNITS } from "@shared/utils/constants";
import ObjectID from "bson-objectid";
import { debounce, orderBy } from "lodash";
import { RootStore } from "../Root";
import {
  UnitGroupOperation,
  generateUnits,
  totalValueFromUnitGroups,
  unitGroupMutation
} from "./UnitGroupStoreUtil";
import { makeAutoObservable } from "mobx";

export class UnitGroupStore {
  readonly root: RootStore;

  unitGroups: ClientUnitGroup[] = [];
  unitGroupDebounceQueue: Array<{ unitGroup: ClientUnitGroup; operation: UnitGroupOperation }> = [];
  debounceUpdate = debounce(() => this.updateApollo(), 600);

  constructor(rootStore: RootStore) {
    this.root = rootStore;

    makeAutoObservable(this, { root: false });
  }

  setUnitGroups(unitGroups?: ClientUnitGroup[]) {
    this.unitGroups = unitGroups ?? [];
  }

  async addUnits(unit: ClientUnit, unitGroupId: string, quantity: number) {
    const index = this.unitGroups.findIndex((u) => u._id === unitGroupId);
    if (index < 0) {
      return new Error(`Failed to find UnitGroup with _id ${unitGroupId}`);
    }
    if (quantity <= 0) {
      return new Error("Unit quantity must be above zero!");
    }
    const unitGroup = this.unitGroups[index];
    if (quantity + (unitGroup.units?.length || 0) > MAX_NO_UNITS) {
      return new Error("Trying to add too many units");
    }
    const newUnits = generateUnits(unit, quantity);
    const updatedUnitGroup = { ...unitGroup, units: [...(unitGroup.units ?? []), ...newUnits] };
    this.unitGroups.splice(index, 1, updatedUnitGroup);
    await this.addUnitGroupToQueue(updatedUnitGroup, UnitGroupOperation.UPDATE);
    this.debounceUpdate();
  }

  async updateUnits(
    unitGroupId: ClientUnitGroup["_id"],
    unitIds: Array<ClientUnit["_id"]>,
    unitUpdate: Partial<ClientUnit>
  ) {
    const index = this.unitGroups.findIndex((u) => u._id === unitGroupId);
    if (index < 0) {
      return new Error(`Failed to find UnitGroup with _id ${unitGroupId}`);
    }
    delete unitUpdate._id;
    const unitGroup = this.unitGroups[index];
    const updatedUnitGroup = {
      ...unitGroup,
      units: unitGroup.units?.map((unit) => {
        if (unitIds.includes(unit._id)) {
          return { ...unit, ...unitUpdate };
        }
        return unit;
      })
    };
    this.unitGroups.splice(index, 1, updatedUnitGroup);
    await this.addUnitGroupToQueue(updatedUnitGroup, UnitGroupOperation.UPDATE);
    this.debounceUpdate();
  }

  async duplicateUnit(unitGroupId: string, unitId: string) {
    const unitGroup = this.unitGroups.find((u) => u._id === unitGroupId);
    if (unitGroup === undefined) {
      return new Error(`Failed to find unit group with id ${unitGroupId}`);
    }
    if (!unitGroup.units || unitGroup.units.length === 0) {
      return new Error("The unit group has no units");
    }
    if (unitGroup.units.length === MAX_NO_UNITS) {
      return new Error("Can not duplicate unit, the unit group already has maximum units");
    }
    const unit = unitGroup.units.find((u) => u._id === unitId);
    if (unit === undefined) {
      return new Error(`Failed to find unit with id ${unitId}`);
    }
    const addition = generateUnits(unit, 1)[0];
    unitGroup.units.push(addition);
    await this.addUnitGroupToQueue(unitGroup, UnitGroupOperation.UPDATE);
    this.debounceUpdate();
  }

  async deleteUnits(unitGroupId: string, ids: string[]) {
    const unitGroup = this.unitGroups.find((u) => u._id === unitGroupId);
    if (unitGroup === undefined) {
      return new Error(`Failed to find UnitGroup with _id ${unitGroupId}`);
    }
    if (!unitGroup.units || unitGroup.units.length === 0) {
      return new Error("The unit group has no units");
    }
    const unitIds = unitGroup.units.map((u) => u._id);
    const areIdsInvalid = ids.some((id) => !unitIds.includes(id));
    if (areIdsInvalid) {
      return new Error("Failed to find units with the given IDs");
    }

    unitGroup.units = unitGroup.units.filter((unit) => !ids.includes(unit._id));
    await this.addUnitGroupToQueue(unitGroup, UnitGroupOperation.UPDATE);
    this.debounceUpdate();
  }

  async addUnitGroup(unitGroup: ClientUnitGroup, unit: ClientUnit, unitQuantity: number = 1) {
    if (unitQuantity <= 0) {
      return new Error("Unit quantity must be above zero!");
    }
    const newUnits = generateUnits(unit, unitQuantity);
    const newUnitGroup = {
      ...unitGroup,
      units: newUnits,
      _id: new ObjectID().toHexString(),
      position: this.unitGroups.length
    };
    this.unitGroups.push(newUnitGroup);
    await this.root.cashflowStore.createLineItemFromUnitGroup(newUnitGroup);
    await this.addUnitGroupToQueue(newUnitGroup, UnitGroupOperation.ADD);
    this.debounceUpdate();
  }

  async updateUnitGroup(
    unitGroupUpdate: Partial<ClientUnitGroup>,
    unitGroupId: ClientUnitGroup["_id"],
    isReordering = false
  ) {
    delete unitGroupUpdate._id;
    const index = this.unitGroups.findIndex((ug) => ug._id === unitGroupId);
    if (index === -1) {
      return new Error(`Failed to find UnitGroup with _id ${unitGroupId}`);
    }
    const updatedUnitGroup = { ...this.unitGroups[index], ...unitGroupUpdate };
    this.unitGroups.splice(index, 1, updatedUnitGroup);
    await this.addUnitGroupToQueue(updatedUnitGroup, UnitGroupOperation.UPDATE, isReordering);
    this.debounceUpdate();
  }

  async deleteUnitGroup(unitGroupId: ClientUnitGroup["_id"]) {
    const index = this.unitGroups.findIndex((ug) => ug._id === unitGroupId);
    if (index === -1) {
      return new Error(`Failed to find UnitGroup with _id ${unitGroupId}`);
    }
    const unitGroupToDelete = this.unitGroups[index];
    this.unitGroups.splice(index, 1);
    await this.root.cashflowStore.deleteLineItem(unitGroupToDelete._id);
    await this.addUnitGroupToQueue(unitGroupToDelete, UnitGroupOperation.DELETE);
    this.debounceUpdate();
  }

  async addUnitGroupToQueue(
    unitGroup: ClientUnitGroup,
    operation: UnitGroupOperation,
    isReordering = false
  ) {
    const index = this.unitGroupDebounceQueue.findIndex(
      (queueItem) => queueItem.unitGroup._id === unitGroup._id
    );
    if (index >= 0) {
      this.unitGroupDebounceQueue[index] = { unitGroup, operation };
    } else {
      this.unitGroupDebounceQueue.push({ unitGroup, operation });
    }
    if (isReordering) {
      return;
    }
    switch (operation) {
      case UnitGroupOperation.UPDATE:
        await this.root.cashflowStore.checkForAutomation(unitGroup);
        this.root.buildPhaseStore.rebuildBuildPhaseAfterUnitGroupChange(unitGroup._id);
        break;
      case UnitGroupOperation.DELETE:
        this.root.buildPhaseStore.removeAssignedUnitGroupFromBuildPhase(unitGroup._id);
        break;
    }
  }

  async updateApollo() {
    const { unitGroups, _id } = this.root.getCurrentAppraisal();
    try {
      await Promise.all(
        this.unitGroupDebounceQueue.map(
          ({ unitGroup, operation }): Promise<any> => unitGroupMutation(operation, unitGroup)(_id)
        )
      );
    } catch (err) {
      this.setUnitGroups(unitGroups);
    } finally {
      this.unitGroupDebounceQueue = [];
    }
  }

  async reorderUnitGroups(reorderedUnitGroups: ClientUnitGroup[]) {
    const updates: Array<Promise<undefined | Error>> = [];
    reorderedUnitGroups.forEach((unitGroup, index) => {
      const newPosition = index;
      let hasChanged = unitGroup.position !== newPosition;
      if (hasChanged) {
        updates.push(this.updateUnitGroup({ position: newPosition }, unitGroup._id, true));
      }
    });
    await Promise.all(updates);
  }

  get unitsByUnitGroupId(): Map<string, ClientUnit[]> {
    const mappedUnits = new Map<string, ClientUnit[]>();
    this.unitGroups.forEach((unitGroup) => {
      mappedUnits.set(unitGroup._id, unitGroup.units ?? []);
    });
    return mappedUnits;
  }

  get unitsCountByUnitGroupId(): Map<string, number> {
    const mappedUnitsCount = new Map<string, number>();
    this.unitGroups.forEach((unitGroup) => {
      mappedUnitsCount.set(unitGroup._id, unitGroup.units?.length ?? 0);
    });
    return mappedUnitsCount;
  }

  get orderedUnitGroups() {
    return orderBy(this.unitGroups, "position");
  }

  get totalSales(): number {
    return totalValueFromUnitGroups(this.unitGroups);
  }

  get salesPerAreaUnit(): number {
    const totalSales = this.totalSales;
    const convertedTotalNIA = this.convertedTotalNIA;
    return totalSales && convertedTotalNIA ? Math.round(totalSales / convertedTotalNIA) : 0;
  }

  get totalSalesFees(): number {
    return this.unitGroups
      .filter((unitGroup) => unitGroup.exitType === "Sale")
      .reduce((sum: number, unitGroup) => sum + totalUnitGroupSalesFees(unitGroup), 0);
  }

  get metricTotalNIA(): number {
    return this.unitGroups.reduce(
      (sum: number, unitGroup: IUnitGroup | ClientUnitGroup) =>
        sum +
        (unitGroup?.units as any[]).reduce(
          (unitSum: number, unit: IUnit | ClientUnit) => unitSum + (unit.area || 0),
          0
        ),
      0
    );
  }

  get convertedTotalNIA(): number {
    return areaUtil.convertSmallArea(this.metricTotalNIA, this.root.userStore.areaUnit);
  }

  get metricTotalGIA(): number {
    let total = 0;
    const unitGroupIDsCounted: string[] = [];

    this.root.buildPhaseStore.buildPhases.forEach((buildPhase) => {
      if (!buildPhase.assignedUnitGroups.length) {
        total += buildPhase.assignedGIA.value;
      } else {
        buildPhase.assignedUnitGroups.forEach((unitGroupID) => {
          const unitGroup = this.unitGroups.find((ug) => ug._id === unitGroupID);

          if (unitGroup && !unitGroupIDsCounted.includes(unitGroupID)) {
            total += totalUnitGroupGIA(unitGroup);
            unitGroupIDsCounted.push(unitGroupID);
          }
        });
      }
    });
    return total;
  }

  get convertedTotalGIA(): number {
    return areaUtil.convertSmallArea(this.metricTotalGIA, this.root.userStore.areaUnit);
  }

  get imperialTotalGIA(): number {
    return areaUtil.convertSmallArea(this.metricTotalGIA, "imperial");
  }

  get hasTooLowGIA() {
    return Math.round(this.metricTotalGIA) < Math.round(this.metricTotalNIA);
  }

  get landToSales(): number {
    return this.totalSales
      ? Math.round((this.root.costStore.totalLandCosts / this.totalSales) * 100)
      : 0;
  }

  get netDevelopmentValue() {
    return this.totalSales - this.totalSalesFees;
  }

  get commercialNIA() {
    return this.unitGroups
      .filter((unitGroup) => unitGroup.unitType === "Commercial Unit")
      .reduce(
        (sum: number, unitGroup) =>
          sum +
          (unitGroup.units || []).reduce((unitSum: number, unit) => unitSum + (unit.area || 0), 0),
        0
      );
  }

  get residentialGIA() {
    // This assumes that commercial GIA = commercial NIA, which might not be true in some cases
    return this.metricTotalGIA - this.commercialNIA;
  }

  get convertedRemainingNIA() {
    return areaUtil.convertSmallArea(
      Math.max(0, this.metricRemainingNIADifference),
      this.root.userStore.areaUnit
    );
  }

  get metricRemainingNIADifference() {
    return (
      totalAreaFromUnitGroups(this.orderedUnitGroups) -
      buildPhasesUsedNIA(this.root.buildPhaseStore.buildPhases, this.orderedUnitGroups)
    );
  }

  get hasOverAssignedNIA() {
    return this.metricRemainingNIADifference < 0;
  }

  get totalUnitsCount() {
    return this.unitGroups.reduce((totalUnitCount, unitGroup) => {
      return totalUnitCount + (unitGroup.units?.length ?? 0);
    }, 0);
  }
}
