import { Icon, Input, Select, Tooltip, TooltipThemes } from "@/react/components";
import { useTranslation } from "@/react/lib/hooks";
import { BuildCost } from "@/react/lib/i18n/locales/enums/buildCost";
import { PROPERTY_DATA_MIN_GIA } from "@shared/utils/constants";
import { BuildQuality, BuildQualityArg, BuildQualityDisplay } from "@shared/types/buildCost";
import { areaUtil } from "@shared/utils/area";
import { formatNumber } from "@shared/utils/formatting";
import clsx from "clsx";
import { toUpper } from "lodash";
import React, { Dispatch, useEffect, useRef, useState } from "react";
import { Interweave } from "interweave";

export interface BuildCostPresentationProps {
  postcode: string;
  isSite: boolean;
  onPostcodeUpdate: Dispatch<string>;
  buildCost?: number;
  totalGIA: number;
  postcodeValid?: boolean | null;
  postcodePartial?: boolean;
  unit?: "metric" | "imperial";
  quality?: BuildQuality;
  setBuildQuality?: Dispatch<BuildQuality>;
}

const TotalGIA = ({
  totalGIA,
  unit = "imperial",
  content
}: {
  totalGIA: number;
  unit: BuildCostPresentationProps["unit"];
  content: string;
}) => {
  const total = formatNumber({ value: areaUtil.convertSmallArea(totalGIA, unit) });
  const areaOutput = areaUtil.getSmallAreaString(unit);
  const { t } = useTranslation();
  const largeEnoughArea = areaUtil.convertSmallArea(totalGIA, "imperial") >= PROPERTY_DATA_MIN_GIA;
  const areaContent = content.replace(
    "{totalArea}",
    `<span class="${!largeEnoughArea ? "atlas-text-red-700" : ""}">${total} ${areaOutput}</span>`
  );
  if (largeEnoughArea) {
    return <Interweave content={areaContent} />;
  } else {
    return (
      <Tooltip
        content={t(BuildCost.Min_GIA).replace(
          "{minArea}",
          `${Math.round(
            areaUtil.convertSmallArea(PROPERTY_DATA_MIN_GIA / 10.764, unit)
          )} ${areaOutput}`
        )}
        theme={TooltipThemes.DANGER}
        asChild={true}
        className="atlas-mr-0 atlas-pr-1"
      >
        <span>
          <Interweave content={areaContent} />
        </span>
      </Tooltip>
    );
  }
};

const QualitySelect = ({
  beforeSelect,
  selectQuality = BuildQuality.MEDIUM,
  onChange
}: {
  beforeSelect: string;
  selectQuality?: BuildQuality;
  onChange: Dispatch<BuildQuality>;
}) => {
  return (
    <>
      {beforeSelect}
      <Select
        id="selectQuality"
        width="atlas-w-auto atlas-mx-2"
        value={toUpper(selectQuality)}
        selectClassName="atlas-bg-transparent"
        onChange={(value) => onChange(toUpper(value ?? BuildQuality.MEDIUM) as BuildQuality)}
        options={Object.values(BuildQuality)
          .reverse()
          .map((val) => ({
            label: BuildQualityDisplay[toUpper(val) as BuildQualityArg],
            option: toUpper(val)
          }))}
      />
    </>
  );
};

export const BuildCostPresentation = ({
  isSite,
  totalGIA,
  postcode,
  onPostcodeUpdate,
  buildCost,
  postcodeValid,
  postcodePartial,
  unit = "imperial",
  quality = BuildQuality.MEDIUM,
  setBuildQuality = () => null
}: BuildCostPresentationProps) => {
  const [editingPostcode, toggleEditingPostcode] = useState(false);
  const [currentPostcode, updateCurrentPostcode] = useState(postcode);
  const { t } = useTranslation();
  const postcodeInput = useRef<HTMLInputElement>(null);
  const isEditable = !editingPostcode || (isSite && editingPostcode);
  const handleUpdate = () => {
    toggleEditingPostcode(false);
    onPostcodeUpdate(currentPostcode);
  };
  useEffect(() => {
    if (postcodeInput.current && editingPostcode) {
      postcodeInput.current.focus();
    }
  }, [editingPostcode]);
  return (
    <div className=" atlas-text-base atlas-text-neutral-800 md:atlas-flex atlas-justify-between atlas-bg-blue-25 atlas-border atlas-border-solid atlas-border-blue-50 atlas-rounded-sm atlas-py-1 atlas-px-3 atlas-items-center">
      <div className="md:atlas-flex atlas-items-center atlas-flex-grow atlas-w-full atlas-flex-wrap">
        <QualitySelect
          selectQuality={quality}
          beforeSelect={postcode ? t(BuildCost.Average) : t(BuildCost.Check_Average)}
          onChange={(qual) => {
            setBuildQuality(qual);
          }}
        />
        <TotalGIA totalGIA={totalGIA} unit={unit} content={t(BuildCost.Cost_Average)} />
        {postcode && isEditable && (
          <>
            <button
              onClick={() => {
                if (!isSite) {
                  toggleEditingPostcode((prev) => !prev);
                }
              }}
              className={clsx(
                "atlas-mx-2px",
                "atlas-px-1 atlas-text-neutral-800 atlas-bg-transparent atlas-border-solid atlas-border-transparent atlas-rounded atlas-text-base",
                {
                  "hover:atlas-border-dashed hover:atlas-border-neutral-500 atlas-cursor-pointer":
                    !isSite
                },
                { "atlas-pointer-events-none": isSite }
              )}
            >
              {postcode}
            </button>
            is
            <strong className="atlas-text-bold atlas-ml-1">
              {buildCost ? formatNumber({ value: buildCost ?? 0 }) : 0} £/
              {areaUtil.getSmallAreaString(unit)}
            </strong>
          </>
        )}
        {((editingPostcode && !isSite) || !postcode) && (
          <div className="atlas-w-40 atlas-inline-block atlas-ml-2">
            <Input
              ref={postcodeInput}
              id="postcode-input"
              placeholder="Add a postcode"
              inputClassName="atlas-w-auto atlas-bg-transparent"
              error={{
                display: postcodeValid === false,
                message: postcodePartial
                  ? t(BuildCost.Input_Error_Partial)
                  : t(BuildCost.Input_Error),
                tooltip: true
              }}
              onChange={(evt) => {
                updateCurrentPostcode(toUpper((evt as string) ?? ""));
              }}
              onBlur={handleUpdate}
              onKeyDown={(evt) => {
                if (evt.code === "Enter") {
                  evt.preventDefault();
                  handleUpdate();
                }
              }}
              value={currentPostcode}
            />
          </div>
        )}
        <Tooltip content={t(BuildCost.Info_Tooltip)}>
          <Icon.QuestionLine className="atlas-w-5 atlas-h-5 atlas-text-neutral-800 atlas-ml-2" />
        </Tooltip>
      </div>
    </div>
  );
};
