import clsx from "clsx";
import React, { FC, PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

interface Props {
  className?: string;
  testId?: string;
}

export const SectionBase: FC<PropsWithChildren<Props>> = ({
  className,
  children,
  testId,
  ...args
}) => {
  return (
    <div
      className={twMerge(
        clsx(
          "atlas-flex atlas-flex-col atlas-bg-white atlas-rounded atlas-shadow-small active:atlas-bg-gray-50 atlas-border atlas-border-solid atlas-border-neutral-300",
          className
        )
      )}
      data-testid={testId}
      {...args}
    >
      {children}
    </div>
  );
};

const SectionHeader: FC<PropsWithChildren<Props>> = ({ children, className, testId }) => (
  <div
    className={clsx(
      "atlas-justify-between atlas-items-center atlas-flex atlas-flex-row atlas-w-auto atlas-px-5 atlas-py-3 atlas-border-b atlas-border-t-0 atlas-border-r-0 atlas-border-l-0 atlas-border-neutral-300 atlas-border-solid",
      className
    )}
    data-testid={testId}
  >
    {children}
  </div>
);

const SectionBody: FC<PropsWithChildren<Props>> = ({ children, className, testId }) => (
  <div
    className={clsx("atlas-flex atlas-flex-row atlas-w-auto atlas-px-5 atlas-py-3", className)}
    data-testid={testId}
  >
    {children}
  </div>
);

const SectionFooter: FC<PropsWithChildren<Props>> = ({ children, className, testId }) => (
  <div
    className={clsx(
      "atlas-flex atlas-flex-row atlas-w-auto atlas-px-3 atlas-py-3 atlas-border-0 atlas-justify-center",
      className
    )}
    data-testid={testId}
  >
    {children}
  </div>
);

export const Section = Object.assign(SectionBase, {
  Header: SectionHeader,
  Body: SectionBody,
  Footer: SectionFooter
});
