<template>
  <div class="appraisal-report" data-intercom-target="appraisal_report">
    <VersionAlert />
    <div class="flex-row justify-content-between mb-3">
      <div class="d-flex align-items-center">
        <b-button
          variant="primary"
          :href="reportStore.localUrl"
          :download="`Report - ${appraisalStore.title}.pdf`"
          :disabled="!reportStore.localUrl && !utilityStore.accountSidebarOpen"
          @click="
            logEvent('Appraisal Report Downloaded', {
              appraisal_id: appraisalStore.appraisalId,
              includes_equity: equityFundingStore.equityFundingLength > 0
            })
          "
        >
          <span v-if="reportStore.localUrl && !utilityStore.accountSidebarOpen"
            >Download Report</span
          >
          <div v-else>
            <b-spinner small />
            <span> Generating Report</span>
          </div>
        </b-button>
      </div>
      <b-button
        v-if="hasReportBranding(userStore.user)"
        @click="openLogoFAQ"
        size="sm"
        variant="link"
        class="pull-right text-nowrap"
        data-intercom-target="logo_faq_link"
      >
        <span>How do I add my logo?</span>
        <b-icon icon="box-arrow-up-right" class="ml-1" />
      </b-button>
    </div>
    <div class="pdf-preview">
      <object
        v-if="reportStore.localUrl && !utilityStore.accountSidebarOpen"
        :data="reportStore.localUrl"
      >
        PDF Preview
      </object>
      <b-skeleton-img v-else />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import VersionAlert from "@/components/VersionAlert.vue";
import EventLoggerMixin from "@/mixins/event_logger_mixin";
import { hasReportBranding } from "@shared/utils/authorisation";
import { rootStore } from "@/react/lib/persistence/root_store";
import { Observer } from "mobx-vue";

const HELP_CENTRE_URL = `https://support.landinsight.io/en`;

@Observer
@Component({
  components: {
    VersionAlert
  }
})
export default class AppraisalReport extends Mixins(EventLoggerMixin) {
  reportStore = rootStore.reportStore;
  utilityStore = rootStore.utilityStore;
  equityFundingStore = rootStore.equityFundingStore;
  userStore = rootStore.userStore;
  appraisalStore = rootStore.appraisalStore;

  hasReportBranding = hasReportBranding;

  mounted() {
    rootStore.reportStore.fetchReport();
  }

  public openLogoFAQ() {
    const url = `${HELP_CENTRE_URL}/articles/6068297-how-to-add-your-logo-to-appraisal-reports`;
    window.open(url, "_blank");
  }
}
</script>

<style lang="scss">
.appraisal-report {
  padding: 1.625rem 1.25rem 0 1.25rem;
  height: 100%;

  .pdf-preview {
    height: calc(100vh - 1.625rem - 38px - 2rem - 98px);
    > * {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
