import { partition, map } from "lodash";

const eventTypeFactories = [
  {
    type: "vue",
    isType(obj: { $on: any; $emit: any }) {
      return !!obj.$on && !!obj.$emit;
    },
    register(
      obj: { $on: (arg0: any, arg1: any) => void; $off: (arg0: any, arg1: any) => void },
      handler: any,
      name: any
    ) {
      obj.$on(name, handler);
      return {
        remove() {
          obj.$off(name, handler);
        }
      };
    }
  },
  {
    type: "google",
    isType(obj: { addListener: any }) {
      return !!obj.addListener;
    },
    register(obj: { addListener: (arg0: any, arg1: any) => any }, handler: any, name: string) {
      return obj.addListener(name, handler);
    }
  }
];

export default {
  registerEvents(container: { _evListeners: any[] }, target: any, events: any, context: any) {
    context = context || container;
    const evListeners = map(events, (handler, name) => {
      const factory = eventTypeFactories.find((f) => {
        return f.isType(target);
      });
      if (!factory) {
        throw new Error(
          "Could not find correct event registration factory for given object. Ensure object can emit events or you've implemented a factory for this type."
        );
      }
      const result = factory.register(target, handler.bind(context), name);
      result.target = target;
      return result;
    });
    container._evListeners = container._evListeners || [];
    container._evListeners = container._evListeners.concat(evListeners);
  },
  unregisterEvents(container: { _evListeners: any[] }, target: null) {
    const [targetListeners, otherListeners] = partition(container._evListeners, (listener) => {
      return target == null || listener.target === target;
    });
    targetListeners.forEach((listener) => {
      listener.remove();
    });
    container._evListeners = otherListeners;
  }
};
