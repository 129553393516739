import { ClientMiniDevelopment } from "@shared/types/development";
import { ClientMiniSite } from "@shared/types/site";

export const hasSiteTitleThatMatchesSearchTerm = (
  development: ClientMiniDevelopment,
  sites: ClientMiniSite[],
  searchTerm: string
) => {
  return sites.some(
    (site) =>
      site._id === development._site && hasTitleThatMatchesSearchTerm(site.title, searchTerm)
  );
};

export const hasTitleThatMatchesSearchTerm = (title: string, searchTerm: string) => {
  return title.toLowerCase().includes(searchTerm.toLowerCase());
};
