import { DebouncedInput } from "@/react/components/Forms/DebouncedInput/DebouncedInput";
import { CalculationsSelect } from "@/react/components/Forms/NumberInput/components/CalculationSelect";
import { ClientBaseComputable, ComputableCalculationType } from "@shared/types/computable";
import React, { Dispatch, ReactNode } from "react";
import clsx from "clsx";

interface Calculation {
  type: string;
  label: string;
  prefix?: string;
  suffix?: string;
  calculateValueFromBase: (base: number) => number;
  calculateBaseFromValue: (value: number) => number;
}

export interface ComputableInputProps {
  id: string;
  computable: ClientBaseComputable;
  calculations: Calculation[];
  disabled?: boolean;
  className?: string;
  inputClassName?: string;
  calculationsSelectClassName?: string;
  label: ReactNode | string;
  showCalculationAsLabel?: boolean;
  testId?: string;
  onChange: Dispatch<ClientBaseComputable>;
}

export const ComputableInput = ({
  id,
  computable,
  disabled,
  calculations,
  className,
  inputClassName,
  calculationsSelectClassName,
  showCalculationAsLabel = false,
  label,
  testId = "computable-input",
  onChange
}: ComputableInputProps) => {
  if (showCalculationAsLabel && calculations.length > 1) {
    throw new TypeError("Cannot show the calculation as a label with multiple calculations");
  }
  const currentCalculation =
    !!calculations && calculations.length > 0
      ? calculations.find((c) => c.type === computable.calculationType) ?? calculations[0]
      : null;

  const calculationOptions = calculations?.map(({ label: calcLabel, type }) => {
    return {
      label: calcLabel,
      option: type
    };
  });

  const updateValue = async (newValue: number) => {
    newValue = isNaN(newValue) ? 0 : newValue;

    let calculationBaseFromValue = calculations.find(
      (calculation) => calculation.type === computable.calculationType
    )?.calculateBaseFromValue;

    if (!calculationBaseFromValue) {
      calculationBaseFromValue = calculations[0].calculateBaseFromValue;
    }

    onChange({
      ...computable,
      value: newValue,
      calculationBase: calculationBaseFromValue(newValue ?? 0),
      calculate: false
    });
  };

  const updateCalculationBase = (newCalculationBase: number) => {
    newCalculationBase = isNaN(newCalculationBase) ? 0 : newCalculationBase;

    let calculateValueFromCalculationBase = calculations.find(
      (calculation) => calculation.type === computable.calculationType
    )?.calculateValueFromBase;

    if (!calculateValueFromCalculationBase) {
      calculateValueFromCalculationBase = calculations[0].calculateValueFromBase;
    }

    onChange({
      ...computable,
      value: calculateValueFromCalculationBase(newCalculationBase ?? 0),
      calculationBase: newCalculationBase ?? 0,
      calculate: true
    });
  };

  const updateCalculationType = (newCalcType: ComputableCalculationType) => {
    const newCalculation = calculations.find((calc) => calc.type === newCalcType);

    if (!newCalculation) {
      throw new Error("Calculation mismatch: calculation type update has no matching calculation");
    }

    let update: Partial<ClientBaseComputable>;
    if (computable.calculate) {
      update = {
        value: newCalculation.calculateValueFromBase(computable.calculationBase)
      };
    } else {
      update = {
        calculationBase: newCalculation.calculateBaseFromValue(computable.value)
      };
    }

    onChange({
      ...computable,
      ...update,
      calculationType: newCalcType
    });
  };

  return (
    <div className={clsx("atlas-flex-col", className)}>
      <div className="sm:atlas-flex atlas-gap-4 atlas-w-full">
        <div className="atlas-w-full">
          {label && typeof label !== "string" && label}
          <DebouncedInput
            id={`${id}-value`}
            value={computable.value}
            onChange={(v) => updateValue(v)}
            label={label && typeof label === "string" ? label : ""}
            prefix="£"
            data-testid={`${testId}-value`}
            disabled={disabled}
            className={clsx("sm:atlas-w-full", {
              "atlas-mt-9": !label
            })}
            inputClassName={inputClassName}
          />
        </div>
        <div className="atlas-w-full">
          <DebouncedInput
            id={`${id}-calculation`}
            label={showCalculationAsLabel ? calculationOptions[0].label : ""}
            data-testid={`${testId}-calc-base`}
            type="text"
            value={computable.calculationBase}
            onChange={(v) => updateCalculationBase(v)}
            prefix={currentCalculation?.prefix}
            suffix={currentCalculation?.suffix}
            aria-label={currentCalculation?.label}
            disabled={disabled}
            className={clsx("sm:atlas-w-full", {
              "atlas-mt-9": !showCalculationAsLabel
            })}
            inputClassName={inputClassName}
          >
            {!showCalculationAsLabel && (
              <CalculationsSelect
                options={calculationOptions}
                calculationsSelectClassName={calculationsSelectClassName}
                onSelect={(val) => updateCalculationType(val as ComputableCalculationType)}
                activeItem={computable.calculationType}
                hasSuffix={true}
              />
            )}
          </DebouncedInput>
        </div>
      </div>
    </div>
  );
};
