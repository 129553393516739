import {
  ClientLineItem,
  ClientLineItemExtended,
  CurveType,
  LineItemType
} from "@shared/types/cashflow";
import ObjectID from "bson-objectid";

export const initLineItem: ClientLineItem = {
  _id: new ObjectID().toHexString(),
  curveType: CurveType.Line,
  cells: [],
  type: LineItemType.UnitGroup,
  units: []
};

export const initLineItemExtended: ClientLineItemExtended = {
  ...initLineItem,
  description: "",
  value: 0,
  valueRemaining: 0,
  startIndex: -1,
  endIndex: -1,
  rate: 0,
  position: 0
};

export const calculateStartEndIndexes = (lineItem: ClientLineItemExtended) => {
  lineItem.startIndex = findStartIndex(lineItem.cells);
  lineItem.endIndex = findEndIndex(lineItem.cells);
};

export const findStartIndex = (array: number[]) => {
  return array.findIndex((cell) => cell > 0);
};

export const findEndIndex = (array: number[]) => {
  let index = array
    .slice()
    .reverse()
    .findIndex((cell) => cell > 0);
  let count = array.length - 1;
  return index >= 0 ? count - index : index;
};

export const rowTotalRemaining = (lineItem: ClientLineItemExtended) => {
  return Math.round(lineItem.value - rowTotal(lineItem.cells));
};

export const rowTotal = (cells: number[]) => {
  return cells.reduce((sum, val) => sum + val, 0);
};
