import { Input, InputOnChangeValueType } from "@/react/components";
import { useTranslation } from "@/react/lib/hooks";
import { BaseComponentTranslations } from "@/react/lib/i18n/locales";
import clsx from "clsx";
import React, { FC, useEffect, useState } from "react";
import { CalculationFieldProps } from "../../types";
import { CalculationsSelect } from "../CalculationSelect";
import { ComputableCalculationType } from "@shared/types/computable";
import { Nullable } from "@shared/types/utils";

const NumberInputCalculation: FC<CalculationFieldProps> = ({
  id,
  calculations,
  hasCalculationLabelAsSuffix = true,
  showActiveOption = true,
  value,
  updateInputValue,
  onFocus,
  onBlur,
  editingCalculationField,
  formatter = (input) => input,
  clearFormatting = (input) => input,
  ...rest
}) => {
  const [activeCalculation, updateActiveCalculation] = useState<
    Nullable<ComputableCalculationType>
  >(value.calculationType);
  const currentCalculation =
    !!calculations && calculations.length > 0
      ? calculations.find((c) => c.type === activeCalculation) ?? calculations[0]
      : null;

  const { t } = useTranslation();

  const ensureValidInput = (val: InputOnChangeValueType, fallback: string | number = 0) => {
    return !val || (val && typeof val === "number" && isNaN(val)) || val === Infinity
      ? fallback
      : val;
  };

  const parseNumber = (val: InputOnChangeValueType) => {
    return parseFloat(String(val));
  };

  const calculateValues = (val: InputOnChangeValueType) => {
    let valueOutput: InputOnChangeValueType = ensureValidInput(value.value);
    const inputValue =
      typeof val === "string" ? clearFormatting(val.replace(/,/, "")) : Number(val);
    let totalOutput: number | string = ensureValidInput(
      currentCalculation?.calculateBaseFromValue(parseNumber(valueOutput)),
      ensureValidInput(value.calculationBase)
    );

    if (editingCalculationField || value.calculate) {
      valueOutput = currentCalculation?.calculateValueFromBase(parseNumber(inputValue) ?? 0);
      totalOutput = inputValue ?? 0;
    }

    updateInputValue({
      ...value,
      calculationBase: totalOutput,
      calculationType: activeCalculation as ComputableCalculationType,
      value: ensureValidInput(valueOutput, valueOutput)
    });
  };

  useEffect(() => {
    calculateValues(value.calculationBase);
  }, [
    activeCalculation,
    currentCalculation?.calculateBaseFromValue(value.value ?? 0),
    currentCalculation?.calculateValueFromBase(parseNumber(value.calculationBase))
  ]);
  return (
    <>
      {calculations && calculations.length > 0 && (
        <div className={clsx("atlas-w-full sm:atlas-w-1/2")} data-testid="total-number-input">
          <Input
            id={id}
            data-testid="number-input-total-input"
            label={
              !hasCalculationLabelAsSuffix || !showActiveOption ? currentCalculation?.label : false
            }
            aria-label={
              currentCalculation?.label ?? t(BaseComponentTranslations.NumberInput_CalculationType)
            }
            trackCursor={true}
            type="text"
            value={formatter(value.calculationBase)}
            onChange={(val) => calculateValues(val)}
            onFocus={onFocus}
            onBlur={onBlur}
            className="atlas-w-full"
            prefix={currentCalculation?.prefix}
            {...rest}
            suffix={currentCalculation?.suffix}
          >
            {(calculations.length > 1 || hasCalculationLabelAsSuffix) && (
              <CalculationsSelect
                options={calculations?.map(({ label, type }) => ({ label, option: type }))}
                hasSuffix={hasCalculationLabelAsSuffix}
                onSelect={(val) => updateActiveCalculation(val as ComputableCalculationType)}
                activeItem={activeCalculation}
                showActiveOption={showActiveOption}
              />
            )}
          </Input>
        </div>
      )}
    </>
  );
};

export { NumberInputCalculation };
