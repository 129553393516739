"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BuildQualityDisplay = exports.BuildQuality = void 0;
var BuildQuality;
(function (BuildQuality) {
    BuildQuality["PREMIUM"] = "premium";
    BuildQuality["MEDIUM"] = "medium";
    BuildQuality["BASIC"] = "basic";
})(BuildQuality = exports.BuildQuality || (exports.BuildQuality = {}));
var BuildQualityDisplay;
(function (BuildQualityDisplay) {
    BuildQualityDisplay["PREMIUM"] = "premium";
    BuildQualityDisplay["MEDIUM"] = "medium";
    BuildQualityDisplay["BASIC"] = "economy";
})(BuildQualityDisplay = exports.BuildQualityDisplay || (exports.BuildQualityDisplay = {}));
