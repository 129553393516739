"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FloodRisk = void 0;
var FloodRisk;
(function (FloodRisk) {
    FloodRisk["NONE"] = "No risk";
    FloodRisk["VERY_LOW"] = "Very low risk";
    FloodRisk["LOW"] = "Low risk";
    FloodRisk["MEDIUM"] = "Medium risk";
    FloodRisk["HIGH"] = "High risk";
})(FloodRisk = exports.FloodRisk || (exports.FloodRisk = {}));
