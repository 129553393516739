import { DEVELOPMENTS } from "@shared/apollo/queries";
import { ClientDevelopment, ClientMiniDevelopment } from "@shared/types/development";
import gql from "graphql-tag";
import { landFundApolloClient } from "../clients";
import { CREATE_DEVELOPMENT_MUTATION, DELETE_DEVELOPMENT_MUTATION } from "../gql";
import { sanitiseInput } from "../utils";

export const createDevelopment = async (developmentInput?: Partial<ClientDevelopment>) => {
  const { data: response } = await landFundApolloClient.mutate({
    mutation: gql`
      ${CREATE_DEVELOPMENT_MUTATION}
    `,
    variables: {
      developmentInput: sanitiseInput(developmentInput, false)
    },
    update: (store, { data }) => {
      const query = gql`
        ${DEVELOPMENTS}
      `;
      const storeData: any = store.readQuery({ query });
      storeData.developments.push(data.createDevelopment);
      store.writeQuery({ query, data: storeData });
    }
  });
  return response.createDevelopment;
};

export const deleteDevelopment = async (id: string) => {
  const variables = { id };
  await landFundApolloClient.mutate({
    mutation: gql`
      ${DELETE_DEVELOPMENT_MUTATION}
    `,
    variables,
    update: (store, { data: response }) => {
      const deletedDevelopment = response?.deleteDevelopment;
      const query = gql`
        ${DEVELOPMENTS}
      `;
      const data: any = store.readQuery({ query });
      const deletedId = deletedDevelopment;
      data.developments = data.developments.filter(
        (a: ClientMiniDevelopment) => a._id !== deletedId
      );
      store.writeQuery({ query, data });
    }
  });
};

export const updateDevelopment = async (
  update: Partial<ClientDevelopment>,
  developmentId: string
) => {
  const developmentUpdate = sanitiseInput(update);
  const returnKeys = Object.keys(update);
  landFundApolloClient.mutate({
    mutation: gql`
        mutation($id: ObjectID!, $developmentUpdate: DevelopmentUpdateInput!) {
          updateDevelopment(id: $id, developmentUpdate: $developmentUpdate) {
            _id
            ${returnKeys.join("\n")}
          }
        }
      `,
    variables: {
      developmentUpdate,
      id: developmentId
    }
  });
};
