import {
  closestCenter,
  DndContext,
  DragCancelEvent,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import { restrictToVerticalAxis, restrictToParentElement } from "@dnd-kit/modifiers";
import React, { Dispatch, FC, PropsWithChildren } from "react";
import { DragHandle } from "./components";

export type DraggableProps = PropsWithChildren<{
  onDragEnd: Dispatch<DragEndEvent>;
  onDragStart?: Dispatch<DragStartEvent>;
  onDragCancel?: Dispatch<DragCancelEvent>;
}>;

export const DraggableComponent: FC<DraggableProps> = ({
  children,
  onDragEnd,
  onDragCancel,
  onDragStart
}) => {
  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
      onDragCancel={onDragCancel}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      data-testid="draggable"
    >
      {children}
    </DndContext>
  );
};

const DraggableOverlay: FC<PropsWithChildren> = ({ children }) => (
  <DragOverlay className="atlas-bg-neutral-300 atlas-cursor-grabbing">{children}</DragOverlay>
);

export const Draggable = Object.assign(DraggableComponent, {
  Overlay: DraggableOverlay,
  Handle: DragHandle
});
