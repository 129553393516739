import { Draggable } from "@/react/components";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import clsx from "clsx";
import React from "react";
import { Row } from "react-table";

export const DraggableTableRow = <Opts extends { _id: string; draggable: boolean }>({
  row,
  tdClassName
}: {
  row: Row<Opts> | undefined | null;
  tdClassName: string;
}) => {
  const { attributes, listeners, transform, transition, setNodeRef, isDragging } = useSortable({
    id: row?.original._id!
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };
  return (
    <tr
      ref={setNodeRef}
      style={style}
      {...row?.getRowProps()}
      className={clsx({ "atlas-group": row?.original.draggable })}
    >
      {isDragging ? (
        <td colSpan={row?.cells.length} className={clsx(tdClassName)}>
          &nbsp;
        </td>
      ) : (
        row?.cells.map((cell, i: number) => {
          if (i === 0) {
            return (
              <td
                {...cell.getCellProps()}
                className={clsx("atlas-w-auto", "group-hover:atlas-bg-neutral-100", tdClassName)}
              >
                <span className="atlas-w-auto atlas-flex atlas-items-center">
                  {row.original.draggable && <Draggable.Handle {...attributes} {...listeners} />}
                  <span>{cell.render("Cell")}</span>
                </span>
              </td>
            );
          }
          return (
            <td
              {...cell.getCellProps()}
              className={clsx(
                "group-hover:atlas-bg-neutral-100 atlas-text-left",
                { "atlas-text-left": i < row?.cells.length - 2 },
                { "atlas-text-right": i >= row?.cells.length - 2 },
                tdClassName
              )}
            >
              {cell.render("Cell")}
            </td>
          );
        })
      )}
    </tr>
  );
};
