"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validatePostcode = void 0;
const validatePostcode = (postcode) => {
    const outwardCode = `(([A-Z][0-9]{1,2})|(([A-Z][A-HJ-Y][0-9]{1,2})|(([A-Z][0-9][A-Z])|([A-Z][A-HJ-Y][0-9]?[A-Z]))))`;
    const inwardCode = `[0-9][A-Z]{2}`;
    const partialPostcodeValidation = new RegExp(`^${outwardCode}$`);
    const postcodeValidation = new RegExp(`^${outwardCode}${inwardCode}$`, "g");
    postcode = postcode === null || postcode === void 0 ? void 0 : postcode.replace(/\s/, "");
    if (postcode && postcodeValidation.test(postcode)) {
        return { status: true, partial: false };
    }
    return { status: false, partial: postcode ? partialPostcodeValidation.test(postcode) : false };
};
exports.validatePostcode = validatePostcode;
