import { MAX_MONTH_INPUT } from "@shared/utils/constants";
import { CashflowTableTranslations, CashflowTimeFrameModalTranslations } from "../enums";

export const CashflowMessages = {
  [CashflowTimeFrameModalTranslations.CashflowTimeFrameModal_StartMonth]: "Month Start",
  [CashflowTimeFrameModalTranslations.CashflowTimeFrameModal_EndMonth]: "Month End",
  [CashflowTimeFrameModalTranslations.CashflowTimeFrameModal_OrLabel]: "Or",
  [CashflowTimeFrameModalTranslations.CashflowTimeFrameModal_SpreadRate]: "Rate",
  [CashflowTimeFrameModalTranslations.CashflowTimeFrameModal_Save]: "Save",
  [CashflowTimeFrameModalTranslations.CashflowTimeFrameModal_StartError1]: `Start month must be less than or equal to ${MAX_MONTH_INPUT}`,
  [CashflowTimeFrameModalTranslations.CashflowTimeFrameModal_StartError2]:
    "Start month must be less than or equal to end month",
  [CashflowTimeFrameModalTranslations.CashflowTimeFrameModal_StartError3]:
    "Start month must be greater than 0",
  [CashflowTimeFrameModalTranslations.CashflowTimeFrameModal_EndError1]: `End month must be less than or equal to ${MAX_MONTH_INPUT}`,
  [CashflowTimeFrameModalTranslations.CashflowTimeFrameModal_EndError2]:
    "End month must be greater than or equal to start month",
  [CashflowTimeFrameModalTranslations.CashflowTimeFrameModal_EndError3]:
    "End month must be 0 if rate is used",
  [CashflowTimeFrameModalTranslations.CashflowTimeFrameModal_EndError4]:
    "End month must be greater than 0",
  [CashflowTimeFrameModalTranslations.CashflowTimeFrameModal_EndError5]:
    "End month must be greater than or equal to start month",
  [CashflowTimeFrameModalTranslations.CashflowTimeFrameModal_RateError1]:
    "Rate must be 0 if end month is used",
  [CashflowTimeFrameModalTranslations.CashflowTimeFrameModal_RateError2]:
    "If end month is 0, rate must be used",
  [CashflowTimeFrameModalTranslations.CashflowTimeFrameModal_RateError3]:
    "Rate must be more than 0",
  [CashflowTableTranslations.CashflowTable_Total]: "Total",
  [CashflowTableTranslations.CashflowTable_Value]: "Value",
  [CashflowTableTranslations.CashflowTable_TimeFrame]: "Time Frame",
  [CashflowTableTranslations.CashflowTable_Curve]: "Curve",
  [CashflowTableTranslations.CashflowTable_Month]: "Month",
  [CashflowTableTranslations.CashflowTable_Balance]: "Balance",
  [CashflowTableTranslations.CashflowTable_InterestAccrued]: "Interest Accrued",
  [CashflowTableTranslations.CashflowTable_InterestPaid]: "Interest Paid",
  [CashflowTableTranslations.CashflowTable_FeesAccrued]: "Fees Accrued",
  [CashflowTableTranslations.CashflowTable_FeesPaid]: "Fees Paid",
  [CashflowTableTranslations.CashflowTable_InterestPaidRate]: "Interest Paid (Rate:",
  [CashflowTableTranslations.CashflowTable_PPS]: "Provider's Profit Share (",
  [CashflowTableTranslations.CashflowTable_DeveloperEquity]: "Developer Equity",
  [CashflowTableTranslations.CashflowTable_LessServicedInterest]: "Less Serviced Interest",
  [CashflowTableTranslations.CashflowTable_GrossRevenue]: "Gross Revenue",
  [CashflowTableTranslations.CashflowTable_CostOfSales]: "Cost of Sales",
  [CashflowTableTranslations.CashflowTable_NetRevenue]: "Net Revenue",
  [CashflowTableTranslations.CashflowTable_TotalCosts]: "Total Costs",
  [CashflowTableTranslations.CashflowTable_MonthlyNet]: "Monthly Net",
  [CashflowTableTranslations.CashflowTable_Set]: "Set"
};
