import { Confirm, Table, RowActions } from "@/react/components";
import { useTranslation } from "@/react/lib/hooks";
import { AppraisalFundingTranslations } from "@/react/lib/i18n/locales";
import { rootStore } from "@/react/lib/persistence/root_store";
import { ClientLoan, LoanAction } from "@shared/types/loan";
import { formatAmount } from "@shared/utils/formatting";
import { calculateGrossLoan, calculateLoanTerm, loanCost } from "@shared/utils/loan";
import { observer } from "mobx-react-lite";
import React, { Dispatch, useMemo, useState } from "react";
import { Column } from "react-table";
import { LoanTableDataObject } from "./types";
import { logEvent } from "@/react/utils";

const Funding = () => <FundingObservable />;

const FundingObservable = observer(() => {
  const { t } = useTranslation();

  const [showConfirmDelete, updateShowConfirmDelete] = useState(false);

  const columns: Array<Column<LoanTableDataObject>> = useMemo(
    () => [
      {
        id: "loanName",
        Header: t(AppraisalFundingTranslations.AppraisalFunding_LoanName),
        accessor: "name",
        Footer: (
          <strong className="atlas-font-bold">
            {t(AppraisalFundingTranslations.AppraisalFunding_TableTotalEquity)}
          </strong>
        )
      },
      {
        id: "grossAmount",
        Header: t(AppraisalFundingTranslations.AppraisalFunding_TableGrossAmount),
        accessor: "grossAmount",
        Footer: (
          <strong className="atlas-font-bold" data-testid="equityContribution">
            {formatAmount(rootStore.loanStore.equityContribution)}
          </strong>
        )
      },
      {
        id: "term",
        Header: t(AppraisalFundingTranslations.AppraisalFunding_Term),
        accessor: "termAmount",
        Footer: ""
      },
      {
        id: "cost",
        Header: t(AppraisalFundingTranslations.AppraisalFunding_TableCost),
        accessor: "loanCost",
        Footer: ""
      },
      {
        id: "action",
        Header: (
          <span
            aria-label={t(AppraisalFundingTranslations.AppraisalFunding_TableActions)}
            role="toolbar"
            data-testid="actions-column"
          />
        ),
        accessor: "action",
        Footer: ""
      }
    ],
    [rootStore.loanStore.equityContribution]
  );

  const data = useMemo(() => {
    const loans = rootStore.loanStore.orderedLoans.map((loan) => {
      const loanTerm = calculateLoanTerm(
        loan,
        rootStore.cashflowFinanceStore.financeOutputs.loanOutputs
      );
      const grossLoan = calculateGrossLoan(
        loan,
        rootStore.cashflowFinanceStore.financeOutputs.loanOutputs
      );
      return {
        ...loan,
        draggable: true,
        termAmount: `${loanTerm} months`,
        grossAmount: formatAmount(grossLoan),
        loanCost: formatAmount(
          loanCost(loan, rootStore.cashflowFinanceStore.financeOutputs.loanOutputs)
        ),
        action: (
          <RowActions
            editTitle={t(AppraisalFundingTranslations.AppraisalFunding_EditLoan)}
            deleteTitle={t(AppraisalFundingTranslations.AppraisalFunding_DeleteLoan)}
            onDeleteClick={() => {
              rootStore.loanStore.setLoanToDeleteId(loan._id);
              updateShowConfirmDelete(!showConfirmDelete);
            }}
            onEditClick={() => {
              rootStore.currentLoanStore.startEditingLoan(loan);
            }}
          />
        )
      };
    });

    loans.push({
      _id: "calculationRow",
      __typename: "calculationRow",
      termAmount: "",
      grossAmount: (
        <strong className="atlas-font-bold">
          {formatAmount(rootStore.loanStore.totalGrossLoan)}
        </strong>
      ),
      loanCost: (
        <strong className="atlas-font-bold">
          {formatAmount(rootStore.loanStore.totalFundingCosts)}
        </strong>
      ),
      draggable: false
    } as any);

    return loans;
  }, [rootStore.loanStore.orderedLoans, rootStore.cashflowFinanceStore.financeOutputs.loanOutputs]);

  const updateStore: Dispatch<(d: LoanTableDataObject[]) => LoanTableDataObject[]> = (value) => {
    const output = value(data)
      .filter((d) => d._id !== "calculationRow")
      .map((d) => {
        const outputObj = d as Partial<typeof d>;
        delete outputObj.draggable;
        delete outputObj.termAmount;
        delete outputObj.grossAmount;
        delete outputObj.loanCost;
        delete outputObj.action;

        return outputObj as ClientLoan;
      });
    rootStore.loanStore.reorderLoans(output);
    logEvent(LoanAction.Reordered);
  };

  return (
    <>
      <Table<LoanTableDataObject> columns={columns} data={data} setData={updateStore} />
      <Confirm
        onConfirm={() => {
          rootStore.loanStore.deleteLoan();
          logEvent(LoanAction.Deleted, {
            is_template: rootStore.appraisalStore.isTemplate
          });
          updateShowConfirmDelete(false);
        }}
        onCancel={() => {
          rootStore.loanStore.clearLoanToDeleteId();
          updateShowConfirmDelete(!showConfirmDelete);
        }}
        title={t(AppraisalFundingTranslations.AppraisalFunding_PleaseConfirm)}
        description={t(AppraisalFundingTranslations.AppraisalFunding_DeleteLoanConfirm)}
        modalOpen={showConfirmDelete}
        confirmButtonText={t(AppraisalFundingTranslations.AppraisalFunding_Delete)}
      />
    </>
  );
});

export { Funding };
