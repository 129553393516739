import React, { FC, Dispatch } from "react";
import { Section } from "@/react/components/Section";
import { ClientAppraisal } from "@shared/types/appraisal";
import { Input, InputOnChangeValueType, Textarea } from "@/react/components";

interface AppraisalDetailsFormProps {
  titleLabel?: string;
  descriptionLabel?: string;
  title: ClientAppraisal["title"];
  updateTitle?: Dispatch<InputOnChangeValueType>;
  description: ClientAppraisal["description"];
  updateDescription?: Dispatch<string | undefined>;
  displayTitle: string;
  untitledTitle: string;
}

export const AppraisalDetailsForm: FC<AppraisalDetailsFormProps> = ({
  title,
  description,
  updateTitle = () => null,
  updateDescription = () => null,
  displayTitle,
  untitledTitle,
  titleLabel = "Title",
  descriptionLabel = "Description"
}) => (
  <Section className="atlas-mb-5 atlas-w-full">
    <Section.Header>
      <h2 className="atlas-text-2xl atlas-my-0 atlas-font-extrabold">{displayTitle}</h2>
    </Section.Header>
    <Section.Body className="atlas-py-5">
      <div className={"atlas-w-full "}>
        <div className="atlas-mb-1">
          <Input
            className="atlas-w-full"
            label={titleLabel}
            id={"title"}
            placeholder={`${untitledTitle}`}
            value={title}
            onChange={updateTitle}
            data-testid={"title"}
          />
        </div>
        <div className="atlas-mb-1">
          <Textarea
            className="atlas-w-full"
            label={descriptionLabel}
            id={"description"}
            value={description}
            onChange={updateDescription}
            data-testid={"description"}
          />
        </div>
      </div>
    </Section.Body>
  </Section>
);
