import Vue from "vue";
import VueRouter from "vue-router";
import AppraisalTabs from "@/pages/AppraisalTabs.vue";
import Developments from "@/pages/DevelopmentsPage.vue";
import DashboardLayout from "@/pages/Layout/DashboardLayout.vue";
import Templates from "@/pages/TemplatesPage.vue";
import Funding from "@/pages/FundingPage.vue";

Vue.use(VueRouter);

export default function () {
  const routes = [
    {
      path: "*",
      redirect: "/developments"
    },
    {
      path: "/",
      component: DashboardLayout,
      redirect: "/developments",
      children: [
        {
          path: "/developments",
          name: "Developments",
          component: Developments
        },
        {
          path: "/developments/:developmentId/:id",
          name: "Appraisal",
          component: AppraisalTabs
        },
        {
          path: "/templates",
          name: "Templates",
          component: Templates
        },
        {
          path: "/funding",
          name: "Lending",
          component: Funding
        },
        {
          path: "/lender-match",
          name: "Funding",
          redirect: "/funding"
        }
      ]
    }
  ];

  return new VueRouter({
    routes,
    mode: "history",
    linkActiveClass: "nav-item active",
    base: process.env.BASE_URL,
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else if (to.hash) {
        return { selector: to.hash };
      } else {
        return { x: 0, y: 0 };
      }
    }
  });
}
