<template>
  <div
    style="height: 63px; font-size: 1rem"
    class="d-flex align-items-center justify-content-between pl-3 pr-5"
  >
    <h6 class="mb-0 font-weight-bold">Contingency</h6>
    <div
      style="margin-right: 20px; width: 216px"
      class="d-flex align-items-center justify-content-between"
    >
      <b-input-group style="max-width: 93px">
        <b-form-input
          :value="AppraisalStore.contingency"
          @input="(val) => AppraisalStore.setContingency(val)"
          min="0"
          :formatter="restrictContingency"
          type="number"
          class="border-right-0 text-right"
          style="padding-right: 6px"
        />
        <template #append>
          <b-input-group-text style="padding-left: 6px"> % </b-input-group-text>
        </template>
      </b-input-group>
      <p
        id="contingency-value"
        class="m-0 font-weight-bold text-nowrap ellipsis-overflow"
        style="max-width: 110px"
      >
        {{ BuildPhaseStore.formattedTotalBuildContingency }}
      </p>
      <b-tooltip
        target="contingency-value"
        :title="BuildPhaseStore.formattedTotalBuildContingency"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { rootStore } from "@/react/lib/persistence/root_store";
import { formatNumber } from "@shared/utils/formatting";
import { Observer } from "mobx-vue";
import { Component, Vue } from "vue-property-decorator";

@Observer
@Component({})
export default class Contingency extends Vue {
  private BuildPhaseStore = rootStore.buildPhaseStore;
  private AppraisalStore = rootStore.appraisalStore;

  restrictContingency(val: string) {
    return parseFloat(val) > 100 ? "100" : this.formattedContingency(val);
  }

  formattedContingency(val: string): string {
    // This allows a user to add 0.01 as a value.
    // Otherwise 0.0 would be formatted to 0 and it would be impossible to type 0.01
    return val === "0.0"
      ? "0.0"
      : formatNumber({ value: parseFloat(val), maxDecimals: 2 }).replace(/,/g, "");
  }
}
</script>

<style scoped>
.ellipsis-overflow {
  text-overflow: ellipsis !important;
  overflow: hidden;
}

/* Remove stepper on number input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
