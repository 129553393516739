import { makeAutoObservable } from "mobx";
import { RootStore } from "../Root";
import { ClientEquityFunding } from "@shared/types/equity";
import { calculateEquityInterest } from "./EquityFundingStoreUtil";
import { debounce, orderBy } from "lodash";
import ObjectID from "bson-objectid";
import { setEquityFundingSources } from "@/react/lib/persistence/apollo";

export class EquityFundingStore {
  readonly root: RootStore;
  equityFundingSources: ClientEquityFunding[] = [];
  equityToDeleteId: string = "";

  debouncedEquityFundingUpdate = debounce(() => {
    this.updateApollo();
  }, 200);

  constructor(rootStore: RootStore) {
    this.root = rootStore;
    makeAutoObservable(this, { root: false });
  }

  setupEquity(equityFundingSources?: ClientEquityFunding[]) {
    this.equityFundingSources = equityFundingSources ?? [];
  }

  addEquityFunding(equityFunding: ClientEquityFunding) {
    this.equityFundingSources.push(equityFunding);
    this.debouncedEquityFundingUpdate();
  }

  updateEquityFunding(
    equityFundingId: ClientEquityFunding["_id"],
    equityFundingUpdate: Partial<ClientEquityFunding>
  ) {
    const index = this.equityFundingSources.findIndex(
      (equityFunding) => equityFunding._id === equityFundingId
    );
    if (index === -1) {
      throw new Error("equityFunding not found");
    }
    const updatedEquityFunding = { ...this.equityFundingSources[index], ...equityFundingUpdate };
    this.equityFundingSources.splice(index, 1, updatedEquityFunding);
    this.debouncedEquityFundingUpdate();
  }

  reorderEquityFundings(equityFundings: ClientEquityFunding[]) {
    this.equityFundingSources = equityFundings.map((equityFunding, index) => {
      return { ...equityFunding, position: index };
    });
    this.updateApollo();
  }

  setEquityToDeleteId(value: string) {
    this.equityToDeleteId = value;
  }

  clearEquityToDeleteId() {
    this.equityToDeleteId = "";
  }

  deleteEquityFunding() {
    this.equityFundingSources = this.equityFundingSources.filter(
      (equityFunding) => equityFunding._id !== this.equityToDeleteId
    );
    this.debouncedEquityFundingUpdate();
  }

  async updateApollo() {
    try {
      await setEquityFundingSources(
        this.root.appraisalStore.appraisalId,
        this.equityFundingSources
      );
    } catch {
      const appraisal = this.root.getCurrentAppraisal();
      this.equityFundingSources = appraisal.equityFundingSources ?? [];
    }
  }

  get orderedEquityFundingSources() {
    return orderBy(this.equityFundingSources, "position");
  }

  get totalEquityInterest() {
    return this.equityFundingSources.reduce(
      (sum, equity) => sum + calculateEquityInterest(equity),
      0
    );
  }

  get totalEquityAmount() {
    return this.equityFundingSources.reduce((sum, equity) => sum + equity.totalAmount, 0);
  }

  get equityFundingLength() {
    return this.equityFundingSources.length;
  }

  get totalProfitShared() {
    return this.equityFundingSources.reduce(
      (sum, equityFunding) => sum + equityFunding.providerProfitShare,
      0
    );
  }

  // totalProfit - couponRate then split using PPS
  get developerProfit() {
    const grossDeveloperProfit = this.root.costStore.profit;
    if (grossDeveloperProfit > 0) {
      return (grossDeveloperProfit * (100 - this.totalProfitShared)) / 100;
    } else {
      return grossDeveloperProfit;
    }
  }

  get developerEquity() {
    return Math.max(0, this.root.loanStore.equityContribution - this.totalEquityAmount);
  }
}
