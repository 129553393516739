"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isAllowedMimeType = exports.isAllowedFileExtension = exports.allowedFileExtensions = exports.allowedMimeTypes = void 0;
const account_1 = require("../types/account");
exports.allowedMimeTypes = Object.values(account_1.AllowedMimeTypes);
exports.allowedFileExtensions = Object.values(account_1.AllowedFileExtensions);
const isAllowedFileExtension = (filename) => {
    var _a;
    const fileExtension = (_a = filename.split(".").pop()) === null || _a === void 0 ? void 0 : _a.toLowerCase();
    return !!fileExtension && exports.allowedFileExtensions.includes(fileExtension);
};
exports.isAllowedFileExtension = isAllowedFileExtension;
const isAllowedMimeType = (mimeType) => {
    return exports.allowedMimeTypes.includes(mimeType);
};
exports.isAllowedMimeType = isAllowedMimeType;
