import { DEVELOPMENTS } from "@shared/apollo/queries";
import gql from "graphql-tag";
import { landFundApolloClient } from "../clients";
import { ClientDevelopment, ClientMiniDevelopment } from "@shared/types/development";
import { logError } from "@/react/utils";
import { MINI_DEVELOPMENT_BY_ID_QUERY } from "../gql/developments";

export const getDevelopments = async (): Promise<ClientMiniDevelopment[]> => {
  const { data, errors } = await landFundApolloClient.query({
    query: gql`
      ${DEVELOPMENTS}
    `
  });
  if (errors) {
    for (let err of errors) {
      logError(err.message);
    }
    throw new Error(`Error retrieving developments`);
  }
  return data.developments;
};

export const getDevelopment = async (
  id: ClientDevelopment["_id"]
): Promise<ClientMiniDevelopment> => {
  const { data, errors } = await landFundApolloClient.query({
    query: gql`
      ${MINI_DEVELOPMENT_BY_ID_QUERY}
    `,
    variables: {
      id
    }
  });
  if (errors) {
    for (let err of errors) {
      logError(err.message);
    }
    throw new Error(`Error retrieving mini development`);
  }
  return data.developmentById;
};
