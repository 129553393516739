import { ClientType } from "@shared/types/utils";
import { UPDATE_APPRAISAL_ANNOTATION_MUTATION } from "@shared/apollo/mutations";
import { IAppraisalAnnotationInput } from "@shared/types/appraisalAnnotation";
import gql from "graphql-tag";
import { sanitiseInput } from "../utils";
import { landFundApolloClient } from "../clients";

export const updateAppraisalAnnotation = async (
  appraisalId: string,
  appraisalAnnotationInput: ClientType<IAppraisalAnnotationInput>
) => {
  return landFundApolloClient.mutate({
    mutation: gql`
      ${UPDATE_APPRAISAL_ANNOTATION_MUTATION}
    `,
    variables: {
      appraisalId,
      appraisalAnnotationInput: sanitiseInput(appraisalAnnotationInput, false)
    }
  });
};
