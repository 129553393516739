import Vue from "vue";
import App from "@/App.vue";
import { forEach } from "lodash";
import BrowserDetect from "vue-browser-detect-plugin";
import "@/react/assets/css/index.css";
//BootstrapVue
import "./assets/scss/lf-theme.scss";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
//@ts-ignore
import { LandTechIconsPlugin } from "./assets/icons/landtech-icons-plugin.js";
import { enableLogging } from "mobx-logger";
import { logError } from "@/react/utils";
import { router } from "@/react/lib/persistence/apollo";
import { VuePlugin as Vuera } from "@landtechnologies/vuera";
import { setupMonitoring } from "./utils/monitoring";
import { rootStore } from "./react/lib/persistence/root_store";

Vue.use(Vuera);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(LandTechIconsPlugin);

Vue.use(BrowserDetect);

Vue.config.productionTip = false;

forEach(LANDFUND_CONFIG, (val, key) => {
  if (val === "") {
    const errorMessage = `LANDFUND_CONFIG key ${key} is not set`;
    if (process.env.NODE_ENV === "development") {
      logError(new Error(errorMessage));
    } else {
      throw new Error(errorMessage);
    }
  }
});

if (process.env.NODE_ENV === "development") {
  enableLogging({
    predicate: () => true,
    action: true,
    reaction: false,
    transaction: true,
    compute: true
  });
}

Vue.config.errorHandler = (err, vm, info) => logError(err);

setupMonitoring({
  apiURL: `${location.protocol}//${location.host}${process.env.VUE_APP_LANDFUND_API_URL}`,
  version: process.env.VUE_APP_VERSION ?? "",
  environment:
    process.env.VUE_APP_ENV === "staging" && /preview/.test(process.env.VUE_APP_BASEPATH || "")
      ? "pr"
      : process.env.VUE_APP_ENV ?? "staging",
  enabled: process.env.VUE_APP_DATADOG_ENABLED !== "false"
});

// While not strictly necessary, we are setting up reactions here so that we know that the RootStore
// is definitely instantiated before Vue is created.
rootStore.setupReactions();

new Vue({
  router,
  render: (h) => h(App)
}).$mount("#app");
