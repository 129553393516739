import locales from "@/react/lib/i18n/locales";

export enum LOCALES {
  enGB = "en-GB",
  enUS = "en-US"
}

export const messages: Record<string, Record<string, string>> = {
  [LOCALES.enGB]: locales.enGB,
  [LOCALES.enUS]: locales.enUS
};
