import React, { Dispatch, FC } from "react";
import { Textarea } from "@/react/components/Forms/Textarea";
import { AppraisalAnnotationType } from "@shared/types/appraisalAnnotation";

interface AnnotationsPresentationProps {
  label: string;
  initialValue: string | undefined;
  updateFunction?: Dispatch<string | undefined>;
  type: AppraisalAnnotationType;
}

const AnnotationPresentation: FC<AnnotationsPresentationProps> = ({
  type,
  label,
  initialValue,
  updateFunction = () => null
}) => {
  return (
    <div className="atlas-w-full atlas-mb-4" data-testid="annotations-presentation">
      <Textarea
        id={type}
        labelVisible={false}
        label={label}
        className="atlas-block"
        value={initialValue}
        onChange={updateFunction}
        rows={3}
        maxLength={500}
        placeholder={label}
      />
    </div>
  );
};

export { AnnotationPresentation, AnnotationsPresentationProps };
