<template>
  <div v-if="hasLandInsightAccess(userStore.user)">
    <b-skeleton-wrapper
      v-if="currentDevelopmentStore.development"
      :loading="currentDevelopmentStore.isSiteLoading"
      class="row no-gutters"
    >
      <template #loading>
        <div class="my-2 w-100">
          <b-skeleton height="24px" v-for="x in 2" :key="x" />
        </div>
        <b-skeleton width="50%" height="19px" />
      </template>

      <div v-show="currentDevelopmentStore.development._site && currentDevelopmentStore.site">
        <div class="site-title d-flex align-items-center justify-content-between my-2">
          <span v-if="currentDevelopmentStore.site">{{ currentDevelopmentStore.site.title }}</span>
          <div class="unlink-site-container px-1" title="Unlink site">
            <b-icon
              icon="lt-close"
              class="unlink-site-icon"
              @click="() => currentDevelopmentStore.unlinkSite()"
            />
          </div>
        </div>
        <div>
          <SiteLink
            v-if="currentDevelopmentStore.site"
            :siteId="currentDevelopmentStore.site._id"
          />
        </div>
      </div>

      <div v-show="!(currentDevelopmentStore.development._site && currentDevelopmentStore.site)">
        <b-input-group class="site-search">
          <b-input-group-prepend>
            <b-input-group-text class="text-muted">
              <b-icon icon="search" />
            </b-input-group-text>
          </b-input-group-prepend>
          <b-input
            :value="siteSuggestionStore.siteSearchString"
            @input="(val) => siteSuggestionStore.updateSiteSearchString(val)"
            placeholder="Search your saved sites"
            list="land-insight-suggestions"
            data-testid="site_input"
          />
        </b-input-group>

        <datalist id="land-insight-suggestions">
          <option
            v-for="site in siteSuggestionStore.siteSuggestions"
            :key="site._id"
            :value="site.title"
            @click="(val) => siteSuggestionStore.updateSiteSearchString(val)"
          />
        </datalist>

        <b-form-text class="mt-3">
          Add a site from your
          <b-link :href="landInsightHref" target="_blank">LandInsight</b-link>
          pipeline to show its key details here.
        </b-form-text>
      </div>
    </b-skeleton-wrapper>
  </div>
  <div v-else>
    <p>Get full site details in seconds by syncing a LandInsight account.</p>
    <p>
      <b-link
        href="https://pages.land.tech/landfund-and-landinsight"
        target="_blank"
        class="text-nowrap"
      >
        <span>Find out more</span>
        <b-icon icon="box-arrow-up-right" class="ml-1" />
      </b-link>
    </p>
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import { hasLandInsightAccess } from "@shared/utils/authorisation";
import SiteLink from "@/components/SiteLink.vue";
import EventLoggerMixin from "@/mixins/event_logger_mixin";
import { rootStore } from "@/react/lib/persistence/root_store";
import { Observer } from "mobx-vue";

const LANDINSIGHT_APP_URL = LANDFUND_CONFIG.LANDINSIGHT_APP_URL;

@Observer
@Component({
  components: {
    SiteLink
  }
})
export default class SiteLinkSection extends Mixins(EventLoggerMixin) {
  public userStore = rootStore.userStore;
  public hasLandInsightAccess = hasLandInsightAccess;
  public currentDevelopmentStore = rootStore.currentDevelopmentStore;
  public siteSuggestionStore = rootStore.siteSuggestionStore;

  get landInsightHref() {
    return `${LANDINSIGHT_APP_URL}/sites`;
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/landtech-colors";
.site-link-section {
  padding: 1rem;
  margin-bottom: 1rem;
}
.site-search {
  border: 1px solid $gray-400;
  border-radius: 4px;
}
.unlink-site-icon {
  cursor: pointer;
  font-size: 2rem;
  color: transparent;
}
.site-title:hover {
  .unlink-site-icon {
    color: inherit;
  }
}
</style>
