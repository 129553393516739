import React, { FC } from "react";
import "./ProgressBar.css";
import clsx from "clsx";

interface ProgressBarProps {
  className?: string;
  size?: "xs" | "md" | "lg";
}

const ProgressBar: FC<ProgressBarProps> = ({ className = "", size = "md" }) => {
  const classes = clsx(
    "atlas-progress atlas-progress-striped",
    {
      ["atlas-progress-xs"]: size === "xs",
      ["atlas-progress-md"]: size === "md",
      ["atlas-progress-lg"]: size === "lg"
    },
    className
  );

  return (
    <div className={classes} data-testid="progress-bar">
      <div role="progressbar progress-striped" className="atlas-progress-bar"></div>
    </div>
  );
};

export { ProgressBar };
