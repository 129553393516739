import React, { Dispatch } from "react";
import clsx from "clsx";
import { InputError } from "../../types";
import { Icon, Tooltip } from "@/react/components";
import { CustomSelect, SelectProps as CustomSelectProps } from "./components/CustomSelect";
import { twMerge } from "tailwind-merge";

export type SelectProps = {
  value: string | null | undefined;
  label?: string;
  showActiveItem?: boolean;
  onChange?: Dispatch<string | undefined>;
  error?: InputError;
  labelVisible?: boolean;
  disabled?: boolean;
  id?: string;
  width?: string;
  className?: string;
  selectClassName?: string;
  tooltip?: string;
  tooltipDescription?: string;
  placeHolderText?: string;
  open?: boolean;
  testId?: string;
  leftAlignTooltip?: boolean;
  fixedHeight?: boolean;
} & Omit<CustomSelectProps, "onSelect" | "activeItem">;

const Select = ({
  id,
  onChange = () => null,
  label,
  showActiveItem = true,
  value,
  labelVisible = true,
  error,
  className,
  tooltip,
  tooltipDescription = tooltip,
  placeHolderText,
  leftAlignTooltip = false,
  options,
  width = "atlas-w-full",
  selectClassName,
  open = false,
  fixedHeight = false,
  testId = "select-input",
  ...props
}: SelectProps) => {
  return (
    <label
      htmlFor={id}
      className={clsx("atlas-relative", "atlas-mb-0", width)}
      data-testid={testId}
    >
      {(!!label || !!tooltip) && (
        <span className="atlas-flex">
          <span className={clsx({ "atlas-sr-only": !labelVisible }, "atlas-mb-2 atlas-block")}>
            {label}
          </span>
          {!!tooltip && (
            <Tooltip
              content={tooltip}
              tooltipDescription={tooltipDescription}
              className="atlas-leading-none atlas-h-6"
              leftAlignText={leftAlignTooltip}
            >
              <Icon.InformationLine className="atlas-w-5 atlas-h-5 atlas-mx-2" />
            </Tooltip>
          )}
        </span>
      )}
      <div
        className={twMerge(
          clsx(
            "atlas-flex atlas-border atlas-leading-snug atlas-rounded atlas-w-auto atlas-border-solid atlas-border-neutral-400 focus-within:atlas-ring-2 focus-within:atlas-ring-offset-3 focus-within:atlas-ring-primary-600 atlas-items-center atlas-relative",
            [
              !!error?.display
                ? "atlas-border-red-600 atlas-text-red-600 atlas-bg-red-50"
                : "atlas-text-neutral-800"
            ],
            [props.disabled ? "disabled:atlas-bg-neutral-100" : "atlas-bg-white"]
          ),
          className
        )}
      >
        <CustomSelect
          className={clsx(selectClassName, "atlas-text-neutral-800")}
          options={options}
          activeItem={value}
          onSelect={(val) => onChange(val)}
          showActiveItem={showActiveItem}
          placeHolderText={placeHolderText}
          open={open}
          fixedHeight={fixedHeight}
        />
        {!!error?.display && (
          <div className={clsx("atlas-pl-0 atlas-absolute atlas-right-2 atlas-top-2")}>
            <Icon.InformationLine className="atlas-w-4" />
          </div>
        )}
      </div>
      {!!error?.display && (
        <div className="atlas-text-xs atlas-mt-2 atlas-text-red-600" data-testid="input-error">
          {error.message}
        </div>
      )}
    </label>
  );
};

export { Select };
