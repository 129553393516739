"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MINI_TEMPLATES = exports.CIL = exports.AUTHORITIES = exports.SITE_CONSTRAINTS = exports.FULL_SITE = exports.MINI_SITES = exports.MINI_SITE = exports.DEVELOPMENTS = exports.MINI_APPRAISAL_BY_ID = exports.FULL_APPRAISAL_BY_ID = exports.USER_PREFERENCES = exports.USER = void 0;
const fragments_1 = require("./fragments");
exports.USER = `
  query {
    user: getSelf {
      ...User
    }
  }
  ${fragments_1.UserFragment}
`;
exports.USER_PREFERENCES = `
  query {
    userPreferences {
      ...UserPreferences
    }
  }
  ${fragments_1.UserPreferencesFragment}
`;
exports.FULL_APPRAISAL_BY_ID = `
  query ($appraisalId: ObjectID!) {
    appraisalById(id: $appraisalId) {
      ...FullAppraisal
    }
  }
  ${fragments_1.FullAppraisalFragment}
`;
exports.MINI_APPRAISAL_BY_ID = `
  query ($appraisalId: ObjectID!) {
    appraisalById(id: $appraisalId) {
      ...MiniAppraisal
    }
  }
  ${fragments_1.MiniAppraisalFragment}
`;
exports.DEVELOPMENTS = `
  query {
    developments {
      ...MiniDevelopment
    }
  }
  ${fragments_1.MiniDevelopmentFragment}
`;
exports.MINI_SITE = `
  query ($_id: String!) {
    site(_id: $_id) {
      ...MiniSite
    }
  }
  ${fragments_1.MiniSiteFragment}
`;
exports.MINI_SITES = `
  query sites($ids: [String!]!) {
    sitesByIds(ids: $ids) {
      ...MiniSite
    }
  }
  ${fragments_1.MiniSiteFragment}
`;
exports.FULL_SITE = `
  query ($_id: String!) {
    site(_id: $_id) {
      ...FullSite
    }
  }
  ${fragments_1.FullSiteFragment}
`;
exports.SITE_CONSTRAINTS = `
  query siteConstraints($geometry: GeometryInput!) {
    siteConstraints(geometry: $geometry) {
      ...SiteConstraints
    }
  }
  ${fragments_1.SiteConstraintsFragment}
`;
exports.AUTHORITIES = `
  query authorities {
    authorities {
      ...Authority
    }
  }
  ${fragments_1.AuthorityFragment}
`;
exports.CIL = `
  query cil($gss_code: String!) {
    cil(gss_code: $gss_code) {
      ...CIL
    }
  }
  ${fragments_1.CILFragment}
`;
exports.MINI_TEMPLATES = `
  query {
    templates {
      ...MiniTemplate
    }
  }
  ${fragments_1.MiniTemplateFragment}
`;
