<!-- 

Example usage of two-way syncing:

  <UrlParameterSyncer parameter-key="site" v-model="siteId" />

Note that you may need to use a computed getter/setter to (de)serialize your object appropriately.

Example usage of read-only usage:
  
  <UrlParameterSyncer parameter-key="site" @input="siteId=$event" />

If you want to clear the param from the url after input event fire, then:

  <UrlParameterSyncer parameter-key="site" @input="siteId=$event" clear-after-input/>

-->

<template>
  <div></div>
</template>

<script>
/* globals history, window */
import Vue from "vue";
import { mapValues, values } from "lodash";
import queryString from "query-string";
import { overwriteQueryValues } from "@/utils/url_params";

//A bit of a workaround
//We monkey patch replace state &
//push state so that we can know when the url changes!!
let eventBus = new Vue();

let pushStateFunc = window.history.pushState;
let replaceStateFunc = window.history.replaceState;
let stateChangeFunctions = {
  pushState() {
    let retVal = pushStateFunc.apply(history, arguments);
    eventBus.$emit("state-change");
    return retVal;
  },
  replaceState() {
    let retVal = replaceStateFunc.apply(history, arguments);
    eventBus.$emit("state-change");
    return retVal;
  }
};
Object.assign(window.history, stateChangeFunctions);

//The below is a global handler for the parameter syncer components
//It batches query string changes in intervals of 16ms
//This is so that push state changes that happen at the same time can be treated
//as one history value

//Objects with parameter name as key and vm, parameterValue & whether to push state or not as values
let urlChanges = {};
function executeUrlChanges() {
  let urlChangeValues = values(urlChanges);
  if (urlChangeValues.length) {
    let paramValsToSet = mapValues(urlChanges, (c) => c.parameterValue);

    let url = overwriteQueryValues(paramValsToSet);

    if (url !== `${window.location.pathname}${window.location.search}${window.location.hash}`) {
      let shouldPushState = urlChangeValues.some((c) => c.pushState);
      if (shouldPushState) {
        history.pushState({}, "", url);
      } else {
        history.replaceState({}, "", url);
      }
      urlChangeValues.forEach((c) => (c.vm.latestQueryString = window.location.search));
    }
    urlChanges = {};
  }
}

let urlChangeTimeout;
function registerUrlChange(vm, parameterName, parameterValue, pushState) {
  urlChanges[parameterName] = {
    vm,
    parameterValue,
    pushState
  };
  if (!urlChangeTimeout) {
    urlChangeTimeout = setTimeout(() => {
      executeUrlChanges();
      urlChangeTimeout = null;
    }, 16);
  }
}

export default {
  props: {
    parameterKey: {
      type: String,
      required: true
    },
    value: [String, Object, Number],
    clearOnDestroy: {
      type: Boolean,
      default: true
    },
    pushState: {
      type: Boolean,
      default: false
    },
    clearAfterInput: {
      type: Boolean,
      default: false
    },
    asJSON: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      latestQueryString: window.location.search,
      hasClearedAfterFirstInput: false
    };
  },
  mounted() {
    this.latestQueryString = window.location.search;

    // We take the qs as the source of truth initially
    if (this.parsedValue) {
      this.setFromQs();
    } else {
      this.setFromModel();
    }

    this.updateQueryString = () => {
      this.latestQueryString = window.location.search;
    };
    eventBus.$on("state-change", this.updateQueryString);
    window.addEventListener("popstate", this.updateQueryString);
  },
  destroyed() {
    if (this.clearOnDestroy) {
      this.setQsWithValue(null);
    }
    eventBus.$off("state-change", this.updateQueryString);
    window.removeEventListener("popstate", this.updateQueryString);
  },
  methods: {
    setFromQs() {
      this.latestQueryString = window.location.search;
      if (!this.valuesInSync && !this._ignoreUpdate) {
        this.$emit("input", this.parsedValue);
        if (this.clearAfterInput) {
          this._ignoreUpdate = true;
          this.setQsWithValue(null);
          this._ignoreUpdate = false;
        }
      }
    },
    setFromModel() {
      this.latestQueryString = window.location.search;
      if (!this.valuesInSync) {
        this.setQsWithValue(this.value);
      }
    },
    setQsWithValue(val, pushState = this.pushState) {
      val = this.asJSON && val ? JSON.stringify(val) : val;
      registerUrlChange(this, this.parameterKey, val, pushState);
    },
    clear() {
      // this method is public, and is useful if you just want to listen for @input
      this.setQsWithValue(null);
    }
  },
  computed: {
    parsedValue() {
      const qs = queryString.parse(this.latestQueryString);
      try {
        return this.asJSON ? JSON.parse(qs[this.parameterKey]) : qs[this.parameterKey];
      } catch (e) {}
    },
    valuesInSync() {
      return this.parsedValue === this.value;
    }
  },
  watch: {
    value() {
      this.setFromModel();
    },
    latestQueryString() {
      this.setFromQs();
    }
  }
};
</script>
