import { UPDATE_LOANS_MUTATION } from "@shared/apollo/mutations";
import { ClientLoan } from "@shared/types/loan";
import gql from "graphql-tag";
import { landFundApolloClient } from "../clients";
import { sanitiseInput } from "../utils";

export const updateLoans = async (appraisalId: string, loansInput: ClientLoan[]) => {
  const appraisalUpdate = {
    loans: loansInput.map((loan) => sanitiseInput(loan, false))
  };
  return landFundApolloClient.mutate({
    mutation: gql`
      ${UPDATE_LOANS_MUTATION}
    `,
    variables: {
      appraisalUpdate,
      appraisalId
    }
  });
};
