import { Alert, AlertType, Button, ButtonThemes, Input, Modal, Select } from "@/react/components";
import { useTranslation } from "@/react/lib/hooks";
import { CashflowTimeFrameModalTranslations } from "@/react/lib/i18n/locales";
import { rootStore } from "@/react/lib/persistence/root_store";
import { mapEnum } from "@/react/utils/enums";
import { CurveType, LineItemType } from "@shared/types/cashflow";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { FC } from "react";

const LineItemModal: FC = () => {
  return <LineItemModalObserved />;
};

const LineItemModalObserved: FC = observer(() => {
  const { t } = useTranslation();
  const currentLineItemStore = rootStore.currentLineItemStore;
  const hasRate = currentLineItemStore.currentLineItem.type === LineItemType.UnitGroup;
  const hasCurveType = currentLineItemStore.currentLineItem.type !== LineItemType.UnitGroup;

  function handleClose() {
    currentLineItemStore.resetAndClose();
  }

  function handleConfirm() {
    currentLineItemStore.saveLineItemModal();
  }

  const footerContent = (
    <>
      <Button
        theme={ButtonThemes.Primary}
        className="atlas-mr-2"
        onClick={handleConfirm}
        disabled={!currentLineItemStore.spreadIsComplete}
      >
        <span data-testid="saveTimeframe">
          {t(CashflowTimeFrameModalTranslations.CashflowTimeFrameModal_Save)}
        </span>
      </Button>
    </>
  );

  return (
    <>
      {rootStore.utilityStore.lineItemModalOpen && (
        <Modal
          data-testid="LineItemModal"
          defaultOpen={true}
          title={currentLineItemStore.spreadModalTitle}
          onClose={handleClose}
          className="md:atlas-max-w-lg"
          footerClassNames="atlas-justify-end atlas-text-right"
          footerContent={footerContent}
        >
          <div className="atlas-w-full atlas-p-4">
            <div className="atlas-w-full atlas-flex">
              <div
                className={clsx(
                  "atlas-flex atlas-gap-3",
                  { "atlas-w-4/6": hasRate },
                  { "atlas-w-full": !hasRate }
                )}
              >
                <Input
                  id="startMonth"
                  data-testid="startMonth"
                  label={t(CashflowTimeFrameModalTranslations.CashflowTimeFrameModal_StartMonth)}
                  className="atlas-mb-4 atlas-w-1/2"
                  value={currentLineItemStore.startMonthInput ?? ""}
                  type="number"
                  error={{
                    display: currentLineItemStore.validateStartInput === false,
                    message: currentLineItemStore.feedbackStartInput
                      ? t(currentLineItemStore.feedbackStartInput)
                      : ""
                  }}
                  onChange={(value) => currentLineItemStore.setStartMonthInput(value)}
                />
                <Input
                  id="endMonth"
                  data-testid="endMonth"
                  label={t(CashflowTimeFrameModalTranslations.CashflowTimeFrameModal_EndMonth)}
                  className="atlas-mb-4 atlas-w-1/2"
                  value={currentLineItemStore.endMonthInput ?? ""}
                  type="number"
                  error={{
                    display: currentLineItemStore.validateEndInput === false,
                    message: currentLineItemStore.feedbackEndInput
                      ? t(currentLineItemStore.feedbackEndInput)
                      : ""
                  }}
                  onChange={(value) => currentLineItemStore.setEndMonthInput(value)}
                />
              </div>
              {hasRate && (
                <div className="atlas-mb-4 atlas-flex atlas-w-2/6">
                  <div className="atlas-mb-4 atlas-w-1/3 atlas-text-center atlas-capitalize">
                    {t(CashflowTimeFrameModalTranslations.CashflowTimeFrameModal_OrLabel)}
                  </div>
                  <Input
                    id="spreadRate"
                    data-testid="spreadRate"
                    label={t(CashflowTimeFrameModalTranslations.CashflowTimeFrameModal_SpreadRate)}
                    className="atlas-mb-4 atlas-w-2/3"
                    value={currentLineItemStore.rateInput ?? ""}
                    type="number"
                    error={{
                      display: currentLineItemStore.validateRateInput === false,
                      message: currentLineItemStore.feedbackRateInput
                        ? t(currentLineItemStore.feedbackRateInput)
                        : ""
                    }}
                    onChange={(value) => currentLineItemStore.setRateInput(value)}
                  />
                </div>
              )}
            </div>
            {hasCurveType && (
              <div className="atlas-w-full atlas-flex atlas-gap-3">
                <Select
                  id="loanInterestChargingType"
                  label={"Curve Type"}
                  selectClassName="atlas-bg-white atlas-rounded"
                  className="atlas-mb-4"
                  value={String(currentLineItemStore.curveType)}
                  onChange={(value) => {
                    if (value) {
                      currentLineItemStore.setCurveType(value as CurveType);
                    }
                  }}
                  options={mapEnum(CurveType, (curveKey) => ({
                    option: curveKey,
                    label: curveKey
                  }))}
                />
              </div>
            )}
            {currentLineItemStore.hasSpreadTooLongForNumberOfUnits && (
              <Alert type={AlertType.Warning}>
                <div>{currentLineItemStore.unitSpreadWarningText}</div>
              </Alert>
            )}
          </div>
        </Modal>
      )}
    </>
  );
});

export { LineItemModal };
