import { landFundApolloClient } from "../clients";
import gql from "graphql-tag";
import { ClientMiniTemplate } from "@shared/types/appraisal";
import { MINI_TEMPLATES } from "@shared/apollo/queries";
import {
  CREATE_TEMPLATE_MUTATION,
  DELETE_TEMPLATE_MUTATION,
  DUPLICATE_TEMPLATE_MUTATION
} from "@shared/apollo/mutations";

export const createTemplate = async (miniTemplate: ClientMiniTemplate) => {
  return await landFundApolloClient.mutate({
    mutation: gql`
      ${CREATE_TEMPLATE_MUTATION}
    `,
    variables: { id: miniTemplate._id },
    update: (store, { data: response }) => {
      const createdTemplate = response?.createTemplate;
      const query = gql`
        ${MINI_TEMPLATES}
      `;
      const data: any = store.readQuery({ query });
      data.templates.push(createdTemplate);
      store.writeQuery({ query, data });
    }
  });
};

export const deleteTemplate = async (id: ClientMiniTemplate["_id"]) => {
  return await landFundApolloClient.mutate({
    mutation: gql`
      ${DELETE_TEMPLATE_MUTATION}
    `,
    variables: { id },
    update: (store, { data: response }) => {
      const deleteAppraisal = response?.deleteAppraisal;
      const query = gql`
        ${MINI_TEMPLATES}
      `;
      const data: any = store.readQuery({ query });
      const deletedId = deleteAppraisal;
      data.templates = data.templates.filter((a: { _id: any }) => a._id !== deletedId);
      store.writeQuery({ query, data });
    }
  });
};

export const duplicateTemplate = async (
  oldTemplate: ClientMiniTemplate,
  newId: ClientMiniTemplate["_id"],
  title: string
) => {
  return await landFundApolloClient.mutate({
    mutation: gql`
      ${DUPLICATE_TEMPLATE_MUTATION}
    `,
    variables: {
      oldId: oldTemplate._id,
      newId,
      title
    },
    update: (store, { data: response }) => {
      const duplicateAppraisal = response?.duplicateAppraisal;
      const query = gql`
        ${MINI_TEMPLATES}
      `;
      const data: any = store.readQuery({ query });
      data.templates.push(duplicateAppraisal);
      store.writeQuery({ query, data });
    }
  });
};
